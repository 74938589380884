import * as React from 'react';
import * as Images from 'assets/images/index';

export const TransactionCol =({isBuyer, currency, date, time, add, cryptoAmountToBuyer, fiatTradeAmount, cryptoAmountFromSeller})=>{
    return(
        <div className="flex items-center">
        <img src = {isBuyer?Images.receiveArrowIcon:Images.sendArrowRed} className="h-7 w-5"/>
        <div className="ml-2">
          <TradeAmount cryptoAmountFromSeller={cryptoAmountFromSeller} add={add} fiatTradeAmount={fiatTradeAmount} isBuyer={isBuyer} cryptoAmountToBuyer={cryptoAmountToBuyer} currency={currency} />
          <p className="text-secondaryGray text-xxs md:text-xs lg:text-xs xl:text-xs font-regular">{date}, {time}</p>
        </div>
      </div>
    );
}

const TradeAmount = ({ currency, cryptoAmountToBuyer, cryptoAmountFromSeller, isBuyer, add, fiatTradeAmount }) => {
  const [isLocal, setLocal] = React.useState(false)

  const handleClick = (e) =>{
    e.stopPropagation()
    setLocal(!isLocal)
  }
  return (
    <div className="flex items-center">
      {!isLocal&&<button onClick={handleClick} className="focus:outline-none text-white text-xs md:text-sm lg:text-sm xl:text-sm font-regular">
      {Number(isBuyer?cryptoAmountToBuyer:cryptoAmountFromSeller).toFixed(7)} {currency}
      </button>}
      {isLocal&&<button onClick={handleClick} className="focus:outline-none text-white text-xs md:text-sm lg:text-sm xl:text-sm font-regular">
      {fiatTradeAmount?.toFixed(2)} {add.currency}
      </button>}
      <text className="text-secondaryGray text-xs ml-1">({`${add.type==='buy'?add.sellerUSDRate:add.buyerUSDRate} ${add.currency}/${add.assetCode==="usdt"?"":add.assetCode}USD`})</text>
    </div>
  )
}