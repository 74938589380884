import * as React from 'react';
import { Card } from 'components/molecular/markets/card';
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import { commaSeparated } from 'utils/AppConstants';
import { MarketInfo, MarketSimplePair } from 'store/models/MarketInfo';
import { useHistory } from 'react-router-dom';

interface IProps {
  pairs: MarketSimplePair[]
}
export const DataCard  = () => {
  const { markets } = useSelector((state:RootState) => state.market);
  const init:MarketInfo[] = []
  const [data, setData] = React.useState(init)
  const history = useHistory()
  
  const getFps = (string) => {
    let temp = parseInt(""+string)
    if(temp.toString().length > 3){
      return Number(string).toFixed(2)
    }else{
      return Number(string).toFixed(4)
    }
  }

  React.useEffect(()=>{
    let pairs:MarketInfo[] = []
    markets.forEach((market)=>{
      market.data.forEach((marketData)=>{
        if(marketData.showOnTop===1 && !pairs.includes(marketData)){
          pairs.push(marketData)
        }
      })
    })
    
    const sortedPairs = pairs.sort((a, b)=> a.orderId - b.orderId)
    setData(sortedPairs)
  },[markets])
  const handleNav = (base, counter) => history.push({ pathname: "/exchange", state: { base, counter }})

    return (
        <div className="block xl:flex">
          {data.map((item, index)=>{
          if(index > 3) return
          return <Card
            key={index}
            cryptoCurrency={item.counter==="-"?"0":item.counter}
            cryptoAlias={item.base==="-"?"0":item.base}
            amountCrypto={item.close==="-"?"0":item.close}
            amountDollar={"00"}
            currency="$"
            percent={item.change==="-"?"0":item.change}
            volume={item.volume==="-"?"0":item.volume}
            profit={parseFloat(item.change)>0}
            getFps={getFps}
            onClick={()=>handleNav(item.base, item.counter)}
          />})}
          
        </div>
    );
}
