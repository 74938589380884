import { useEffect, useState } from "react"
import axios from 'axios'
import { BASE_URL } from "utils/AppConstants"

const useReferrals = (id) => {
    let [state, setState] = useState({ loading: true, data: [] })

    let fetchReferrals = (id) => {
        axios.get(`${BASE_URL}/referral/getAll?id=${id}`).then(data => {
            if (data.status === 200) {
                setState(prev => ({ loading: false, data: data.data.filter(item=>item.referralLevelId<4) }));
            }
        }).catch(e => {
            setState(prev => ({ ...prev, loading: false }));
        })
    }

    useEffect(() => {
        fetchReferrals(id);
    }, [])
    return { ...state }
}
export default useReferrals