import * as React from 'react';
import * as Images from 'assets/images/index';
import { Button } from 'components/global';
import useUpload from 'screens/ProfileScreen tabs/hooks/useUpload';
import Upload from 'components/atomic/Upload';
import { countries } from 'utils/countries';
import { docTypes } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import CustomInput from 'components/global/CustomInput';
import { colors } from 'styles/theme';
import CustomPicker2 from 'components/atomic/CustomPicker2';
import { DropdownGray } from 'components/atomic/DropdownGray';
import { BottomNav } from 'screens/ProfileScreen tabs/KYCProfileTab';

export const UploadDocumentsForm = ({ tab, setTab, status }) => {
  let {
    docType,
    country,
    issueDate,
    expDate,
    realName,
    realNameVal,
    number,
    docTypeVal,
    issueDateVal,
    expDateVal,
    numberVal,
    frontUrlVal,
    backUrlVal,
    submit,
    handleInputChange,
    saveUrl,
    saveDates,
    submitting,
    proofImageVal,
    proofCode,
  } = useUpload(tab, setTab, false);

  return (
    <div className="flex flex-col">
      <form onSubmit={submit} className="mt-10 p-5 w-full m-auto">
        <div>
          <h4 className="text-white mt-8 mb-2 font-regular text-sm">
            Identity document
          </h4>

          <div className="lg:flex mb-2">
            <DropdownGray
              label="Identity Verification Country"
              textMd={true}
              value={country}
              name="country"
              validation={''}
              onChange={handleInputChange}
              data={countries}
            />
            <div className="mx-1" />
            <DropdownGray
              label="Document Type"
              textMd={true}
              value={docType}
              name="docType"
              validation={docTypeVal}
              onChange={handleInputChange}
              data={docTypes}
            />
          </div>

          <div className="lg:flex mb-2">
            <div className="flex-1 lg:mr-1">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="Real Name on Document"
                name="realName"
                value={realName}
                onChange={handleInputChange}
                valMsg={realNameVal}
                marginVertical="2px"
                padding={'12px'}
                placeholder="Enter your Real Name on Document here"
                maxLength={50}
              />
            </div>
            <div className="flex-1 lg:ml-1">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="Document Number"
                name="number"
                value={number}
                onChange={handleInputChange}
                valMsg={numberVal}
                marginVertical="2px"
                padding={'12px'}
                placeholder="Enter your Document Number here"
              />
            </div>
          </div>

          <div className="lg:flex">
            <div className="lg:mr-1 w-full">
              <CustomPicker2
                label="Issue Date"
                left={true}
                validation={issueDateVal}
                startDate={issueDate}
                setStartDate={value =>
                  handleInputChange({ target: { name: 'issueDate', value } })
                }
              />
            </div>
            <div className="lg:ml-1 w-full">
              <CustomPicker2
                label="Expiry Date"
                left={false}
                validation={expDateVal}
                startDate={expDate}
                setStartDate={value =>
                  handleInputChange({ target: { name: 'expDate', value } })
                }
              />
            </div>
          </div>
          <h4 className="text-white mt-8 mb-2 font-regular text-sm">
            Upload Documents
          </h4>
        </div>

        <div className="lg:flex">
          <div className="w-full">
            <div
              className="p-5 mb-4 lg:mr-1"
              style={{ backgroundColor: 'rgba(255,159,0,0.15)' }}
            >
              <p className="font-regular text-white text-xs mb-2">
                Uploaded documents must meet the below requirements.
              </p>
              <ol className="list-inside list-alpha">
                <li className="font-regular text-white text-xs">
                  No modification is made on the uploaded documents
                </li>
                <li className="font-regular text-white text-xs">
                  Documents are readable and not blurred
                </li>
                <li className="font-regular text-white text-xs">
                  Kindly control the light reflection while capturing
                </li>
              </ol>

              <p className="mt-2 font-regular text-white text-xs">
                Unsatisfactory documents will be refused for KYC.
              </p>
            </div>
                <div>
                  <img src="/assets/holding.png" className="ml-12" />
                </div>
          </div>

          <div className="w-full lg:ml-1">
            <div className="flex-1">
              <Upload
                label={'ID Document - Front Side'}
                onSuccess={url => saveUrl({ name: 'frontUrl', value: url })}
                text={'Upload front side of id document'}
                id="documentFront"
                validation={frontUrlVal}
              />
            </div>
            <div className="flex-1">
              <Upload
                label={'ID Document - Back Side'}
                onSuccess={url => saveUrl({ name: 'backUrl', value: url })}
                text={'Upload back side of id document'}
                id="documentBack"
                validation={backUrlVal}
              />
            </div>
            <div className="flex-1">
              <Upload
                label={'Upload verification Image'}
                onSuccess={url => saveUrl({ name: 'proofImage', value: url })}
                text={'Upload verification Image'}
                id="proofImage"
                validation={proofImageVal}
              />
            </div>
            <div className="p-5 mb-4 lg:mr-1">
              <p className="font-regular text-white text-xs mb-2">
                Uploaded image must meet the below requirements.
              </p>
              <ol className="list-inside list-alpha">
                <li className="font-regular text-white text-xs">
                  You should be holding your identity card in your hand.
                </li>
                <li className="font-regular text-white text-xs">
                  You should be holding a paper with this verification code "
                  {proofCode}" written on it.
                </li>
                <li className="font-regular text-white text-xs">
                  The paper should also have current date written on it.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div></div>
      </form>
      <BottomNav
        tab={tab}
        setTab={setTab}
        submit={submit}
        loading={submitting}
        status={status}
        edit={false}
      />
    </div>
  );
};
