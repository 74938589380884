import { MarketInfo, CoinMarket } from "store/models/MarketInfo";

export const FETCH_MARKET_PENDING = "FETCH_MARKET_PENDING";
export const FETCH_MARKET_SUCCESS = "FETCH_MARKET_SUCCESS";
export const FETCH_MARKET_ERROR = "FETCH_MARKET_ERROR";
export const SELECT_MARKET_COIN = "SELECT_MARKET_COIN";
export const ADD_TO_FAVOURITES = "ADD_TO_FAVOURITES";
export const REMOVE_FROM_FAVOURITES = "REMOVE_FROM_FAVOURITES";
export const FETCH_TOP_BAR_PENDING = "FETCH_TOP_BAR_PENDING";
export const FETCH_TOP_BAR_SUCCESS = "FETCH_TOP_BAR_SUCCESS";
export const SEARCH_MARKET_DATA = "SEARCH_MARKET_DATA";
export const REFRESH_MARKET_DATA = "REFRESH_MARKET_DATA";
export const UPDATE_CLOSE_PRICE = "UPDATE_CLOSE_PRICE";

interface FetchMarketSuccess {
  type: typeof FETCH_MARKET_SUCCESS;
  currency: string;
  payload: [];
}
export interface FetchMarketPanding {
  type: typeof FETCH_MARKET_PENDING;
  isFetching: boolean;
}
export interface UpdateClosePrice {
  type: typeof UPDATE_CLOSE_PRICE;
  price: string;
  base: string;
  counter: string;
}
export interface SelectMarketCoin {
  type: typeof SELECT_MARKET_COIN;
  selected: CoinMarket;
}
export interface AddToFavourites {
  type: typeof ADD_TO_FAVOURITES;
  data: MarketInfo;
}
export interface RemoveFromFavourites {
  type: typeof REMOVE_FROM_FAVOURITES;
  data: MarketInfo;
  coin: string;
}
export interface FetchTopBarPending {
  type: typeof FETCH_TOP_BAR_PENDING;
  isFetching: boolean;
}
export interface FetchTopBarSuccess {
  type: typeof FETCH_TOP_BAR_SUCCESS;
  data: [];
  currency: string;
}
export interface SearchMarketData {
  type: typeof SEARCH_MARKET_DATA;
  data: string;
}
export interface RefreshMarketData {
  type: typeof REFRESH_MARKET_DATA;
  market: CoinMarket[]
  reload: boolean
}

export type GetMarketInfoType =
  | FetchMarketSuccess
  | FetchMarketPanding
  | SelectMarketCoin
  | AddToFavourites
  | RemoveFromFavourites
  | FetchTopBarPending
  | FetchTopBarSuccess
  | UpdateClosePrice
  | SearchMarketData
  | RefreshMarketData;
