import * as React from "react";
import { DataCard } from "components/organisms/markets/DataCard";
import { MarketTopNavigation } from "components/molecular/markets/MarketTopNavigation";
import { FavTable } from "components/organisms/markets/FavTable";
import Footer from "components/molecular/footer";

export const MarketScreen = () => {

  return (
    <React.Fragment>
    <div className="mx-auto">
      <div className="w-5/6 md:mt-4 md:w-5/6 lg:w-5/6 xl:w-5/6 mx-auto">
        <DataCard />
        <div className="ml-2 mr-2">
          <MarketTopNavigation />
          <FavTable />
        </div>
      </div>
    </div>
    <Footer marginTop="300px" />
    </React.Fragment>
  );
};
