import * as React from "react";
import CustomInput from "components/global/CustomInput";
import { colors } from "styles";
import CustomPicker2 from "components/atomic/CustomPicker2";
import { SelfieBlock } from "components/molecular/ProfileScreen/SelfieBlock";

export const ProfileInfoForm = ({
  handleInputChange,
  showAlert,
  status,
  setPhoto,
  firstName,
  lastName,
  otherName,
  dob,
  file,
  firstNameVal,
  lastNameVal,
  otherNameVal,
  dobVal,
  fileVal,
}) => {
  return (
    <div className="mt-4 mb-4">
      <p className="text-white font-regular mb-2 text-xs md:text-lg lg:text-lg xl:text-lg">
        Personal Info
      </p>
      <div className="items-center justify-between">
        <div className="lg:flex">
          <div className="lg:mr-1 w-full">
            <div className="w-full md:flex">
            <div className="w-full md:mr-2">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
                valMsg={firstNameVal}
                marginVertical="0.30rem"
                padding={"12px"}
                placeholder="Enter your first name"
                maxLength={30}
              />
            </div>

            <CustomInput
              labelColor={colors.secondaryGray}
              label="Last Name"
              name="lastName"
              value={lastName}
              onChange={handleInputChange}
              valMsg={lastNameVal}
              marginVertical="0.30rem"
              padding={"12px"}
              placeholder="Enter your last name"
              maxLength={30}
            />
            </div>
            {(firstName || lastName) && (firstName !== "" || lastName !== "") && (
          <p className="text-xs text-red italic" style={{marginTop: "-10px"}}>
            {firstName} {lastName} will be used as your bank holder's
            account title, please make sure this is correct and according to your bank accounts detail.
          </p>
        )}
          </div>
          <div className="lg:ml-1 w-full">
            <CustomInput
              labelColor={colors.secondaryGray}
              label="Other Name"
              name="otherName"
              value={otherName}
              onChange={handleInputChange}
              valMsg={otherNameVal}
              marginVertical="0.30rem"
              padding={"12px"}
              placeholder="Enter your name on document"
              maxLength={50}
            />
          </div>
        </div>
        
        <div className="md:flex">
          <div className="md:mr-1 w-full">
            <CustomPicker2
              label="Date Of Birth"
              left={false}
              validation={dobVal}
              startDate={dob}
              setStartDate={(value) =>
                handleInputChange({ target: { name: "dob", value } })
              }
            />
          </div>
          <div className="md:ml-1 w-full">
            <SelfieBlock
              onUpload={() => {}}
              label="Profile picture(Max size 1MB)"
              valMsg={fileVal}
              showAlert={showAlert}
              onSelect={setPhoto}
              status={status}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
