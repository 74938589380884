import React, { useState } from "react";
import { Button } from "components";
import * as Images from "assets/images/index";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";

export const WalletCreated = ({ callback, privateKey, myKey }) => {

  let [inputs, setInputs] = useState({
    first: false,
    second: false,
    third: false,
  });
  let [copied, setCopied]=useState(false)
  const alert = useAlert();
  let [message, setMessage]=useState("");

  let copyT = () => {
    copy(privateKey);
    setCopied(true)
  };
console.log(myKey)
  let proceed = () => {
    if (inputs.first && inputs.second && inputs.third) {
      // history.replace({
      //   pathname: '/walletScreen',
      //   state:  history.location.state,
      // });
      callback()
    }else{
      alert.removeAll();
      alert.show('Please agree terms & conditions.', {type: 'error'})
      setMessage("You need to agree to our terms and conditions to proceed!")
    }
  };

  return (
    <div className="lg:w-4/6 mx-auto">
      <p className=" text-sm md:text-h1 lg:text-h1 xl:text-h1 text-center text-accent font-regular mt-10 ">
        Congratulations!
      </p>
      <div className="mt-6">
        <p className="text-xxxs md:text-sm lg:text-sm xl:text-sm font-regular text-secondaryGray text-center">
          Your Secret Key has been {myKey?'imported':'generated'}.
        </p>
        <p className="text-xxxs md:text-sm lg:text-sm xl:text-sm font-regular text-secondaryGray text-center">
          Your secret key starts with capital S, and is 56 characters long.
        </p>
      </div>

      <div className="bg-primary items-center mt-5 p-5 md:p-8 lg:p-8 xl:p-8 w-4/5 sm:w-4/6 mx-auto">
        <p className="text-xxxs text-white text-center text-center font-regular md:text-sm lg:text-sm xl:text-sm">
          {privateKey}
        </p>
        <button
          style={{ backgroundColor: "rgba(228,141,15,0.15)" }}
          onClick={copyT}
          className="flex text-white mt-5 w-18 md:w-25 lg:w-1/6 xl:w-1/6 self-center mx-auto rounded-full text-xs py-2 px-4 items-center"
        >
          <img
            src={Images.copyIcon}
            className="h-2 w-2 mr-2 md:h-4 md:w-4 md:mr-2 lg:h-4 lg:w-4 lg:mr-2 xl:h-4 xl:w-4 xl:mr-2"
          />
          <p className="text-white text-center text-xxxs md:text-sm lg:text-sm xl:text-sm">
            Copy
          </p>
        </button>
      </div>
      {copied&&<div className="w-20 mx-auto">
        <div className="flex items-center mt-4">
          <img src={Images.checkedIcon} className="h-3 w-3 mr-2" />
          <p className="text-lightGreen text-center text-xxxs md:text-sm lg:text-sm xl:text-sm">
            Copied
          </p>
        </div>
      </div>}

      <div className="mt-10 flex w-4/5 sm:w-4/6 mx-auto">
        <input
          className="mt-1"
          type="checkbox"
          checked={inputs.first}
          onClick={() => setInputs((p) => ({ ...p, first: !p.first }))}
        ></input>
        <button onClick={() => setInputs((p) => ({ ...p, first: !p.first }))}
         className="text-left focus:outline-none text-secondaryGray text-xxxs md:text-sm lg:text-sm xl:text-sm ml-4">
          I understand that if I lose this secret key, I will lose access to any
          crypto tokens held in the wallet address linked to this Secret Key.{" "}
        </button>
      </div>
      <div className="mt-10 flex w-4/5 sm:w-4/6 mx-auto">
        <input
          className="mt-1"
          type="checkbox"
          checked={inputs.second}
          onChange={() => setInputs((p) => ({ ...p, second: !p.second }))}
        ></input>
        <button onClick={() => setInputs((p) => ({ ...p, second: !p.second }))}
         className="text-left focus:outline-none text-secondaryGray text-xxxs md:text-sm lg:text-sm xl:text-sm ml-4">
          {" "}
          I understand that I should not share this Secret Key with anyone else.
        </button>
      </div>
      <div className="mt-10 flex w-4/5 sm:w-4/6 mx-auto">
        <input
          className="mt-1"
          type="checkbox"
          checked={inputs.third}
          onChange={() => setInputs((p) => ({ ...p, third: !p.third }))}
        ></input>
        <button onClick={() => setInputs((p) => ({ ...p, third: !p.third }))}
         className="text-left focus:outline-none text-secondaryGray text-xxxs md:text-sm lg:text-sm xl:text-sm ml-4">
          I have written down or copied this Secret Key and saved in a secure
          location. (Click on the Secret Key above to copy to your clipboard)
        </button>
      </div>
      <div className="w-4/5 sm:w-4/6 mx-auto mb-12">
      <small className="text-red italic">{message}</small>
        <div style={{ display: "block", width: "100%" }}>
          <Button
            styles={["bg-accent font-bold text-sm py-4 mt-8 w-full uppercase"]}
            onClick={proceed}
          >
            Go To Wallet
          </Button>
        </div>
      </div>
    </div>
  );
};
