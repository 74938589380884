import { AssetsState } from "store/models/Assets";
import { GetAssetsInfoType, FETCH_ASSETS_PENDING, FETCH_ASSETS_SUCCESS, FETCH_ASSETS_ERROR, APPEND_ASSETS_SUCCESS, APPEND_CUSTOM_ASSETS_SUCCESS } from "store/actions/AssetsActionsTypes";


const initialState: AssetsState = {
  assets: [],
  loadedNames: [],
  loading: true,
  error: ''
};

export function assetsReducer(
  state = initialState,
  action: GetAssetsInfoType
): AssetsState {
  switch (action.type) {
    case FETCH_ASSETS_PENDING:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ASSETS_SUCCESS:
      return {
        ...state,
        assets: action.payload,
        loadedNames: [action.currency],
        loading: false,
      };
    case APPEND_ASSETS_SUCCESS:
      let loadedNew: any[] = [];
      if (state.loadedNames.includes(action.currency)) {
        loadedNew = state.loadedNames;
      } else {
        loadedNew = [...state.loadedNames, action.currency];
      }
      return {
        ...state,
        assets: action.payload,
        loadedNames: loadedNew,
        loading: false,
      };
    case FETCH_ASSETS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case APPEND_CUSTOM_ASSETS_SUCCESS:
      let assets: any[] = [];
      return {
        ...state,
        assets: assets
      };
    default:
      return state;
  }
}
