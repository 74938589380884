import React, { Fragment } from "react";
import AddNewCoin from "./AddNewCoin";
import { WalletDataRow } from "./WalletDataRow";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCoin } from "store/actions/TransactionActions";
import Loader from "react-loader-spinner";
import { RootState } from "../../../store/reducers";

function WalletTab({ transaction, setAddAsset , coinTableData}) {
  const { loadingTable } = useSelector((state: RootState) => state.generic);
  return (
    <Fragment>
      {/* {!transaction.locked &&
        !transaction.lowXlm &&
        !transaction.loading &&
        transaction.coins.length > 0 && (
          <RenderTable data={transaction.coins} />
        )} */}
      {loadingTable ? (
        <div className="flex justify-center mt-16">
          <Loader type="Bars" color="#FFFF00" height={30} width={30} />
        </div>
      ) : (
        <>
          {!transaction.locked &&
            !transaction.lowXlm &&
            !transaction.loading &&
            coinTableData.length > 0 && (
              <RenderTable data={coinTableData} />
            )}
        </>
      )}

      {!transaction.locked && !transaction.lowXlm && transaction.loading && (
        <div className="flex justify-center mt-16">
          <Loader type="Bars" color="#FFFF00" height={30} width={30} />
        </div>
      )}

      {!transaction.locked &&
        !transaction.lowXlm &&
        !transaction.loading &&
        coinTableData.length > 0 &&
        !loadingTable && <AddNewCoin onClick={() => setAddAsset(true)} />}

      {!transaction.locked &&
        !transaction.lowXlm &&
        !transaction.loading &&
        coinTableData.length < 1 &&
        !loadingTable && (
          <div className="flex justify-center mt-16">
            <p className="text-white font-semibold text-xl">
              {transaction.error}
            </p>
          </div>
        )}
    </Fragment>
  );
}

export default WalletTab;

const RenderTable = ({ data }) => {
  let history = useHistory();
  let dispatch = useDispatch();
  const onNav = (screen, item, index) => {
    dispatch(selectCoin(index));
    history.push({
      pathname: "/walletDetailScreen",
      state: {
        screen: screen,
        coin: item.short,
        asset: item,
      },
    });
  };
  return (
    <table
      style={{ tableLayout: "fixed", width: "100%" }}
      cellPadding="2"
      className="separated-row"
    >
      <thead>
        <tr className="text-left">
          <th className="pb-4">
            <text className="DataTopRow DataTopRow1 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Coin
            </text>
          </th>
          <th className="pb-4 hidden md:block lg:block xl:block">
            <text className="DataTopRow DataTopRow2 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Total Balance
            </text>
          </th>
          <th className="pb-4">
            <text className="DataTopRow DataTopRow3 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Available Balance
            </text>
          </th>
          <th className="pb-4 hidden md:block lg:block xl:block">
            <text className="DataTopRow DataTopRow2 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Currency Balance
            </text>
          </th>
          <th className="pb-4">
            <text className="DataTopRow DataTopRow5 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              BTC Value
            </text>
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <WalletDataRow
            key={index}
            fullName={item.fullName}
            profit={item.profit}
            coinIcon={item.coinIcon}
            short={item.short}
            balance={item.balance}
            availableBalance={item.availableBalance}
            btcValue={item.btcValue}
            percent={item.percent}
            index={index}
            currentRate={item.currentRate ? item.currentRate : 0 }
            displayColor={item.displayColor}
            totalBalance={item.totalBalance}
            onNav={(screen) => onNav(screen, item, index)}
          />
        ))}
      </tbody>
    </table>
  );
};
