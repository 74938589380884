import React, { useEffect, useState } from "react";
import { changeMyAddStatus, getAddsByAccount } from "services/p2pServives";
import Loader from "react-loader-spinner";
import { AdCard } from "./markets/P2P/AdCard";
import { RootState } from "store/reducers";
import { useSelector } from "react-redux";
import { profileStatus } from "utils/constants";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { getSelectedKeys } from "services/trannsactionServices";

function P2pProfileAds({account}) {
  const {auth, profile:{user}} = useSelector((state:RootState)=>state)
  const [state, setState] = useState({
    loading: true,
    buyAds: [],
    sellAds: [],
    isMyProfile: false,
  });
  const history = useHistory()
  const alert = useAlert()
  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const buyData = await getAddsByAccount(null, 1, "buy", account);
      const sellData = await getAddsByAccount(null, 1, "sell", account);

      setState((prev) => ({
        ...prev,
        loading: false,
        buyAds: buyData.data.data,
        sellAds: sellData.data.data,
        isMyProfile: account === getSelectedKeys().public
      }));
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };
  const onAction = (item) => {
    if(!auth.password || user.status !== profileStatus.approved){
      if(!auth.password){
        alert.show("You are not logged in.")
      }else{
        alert.show("Your KYC is not approved.")
      }
      return
    }
    if(item.account === getSelectedKeys().public){
      changeAddStatus(item)
      return
    }
    history.push({
      pathname:
        item.type == "sell"
          ? "/confirmationScreenSell"
          : "/confirmationScreenBuy",
      state: {
        add: item,
      },
    });
  };
  const changeAddStatus = ({id, status, type}) => {
    changeMyAddStatus({id, status: status === 'Active' ? "Paused" : "Active"})
      .then((data) => {
          let ads:any = [...state[type === 'buy'?'buyAds':'sellAds']]
          const index = ads.findIndex(add=>add.id === id)
          ads[index].status = status==='Active'?'Paused':'Active';
          setState(prev=>({...prev, [type === 'buy'?'buyAds':'sellAds']: ads}))
      })
      .catch((e) => {
        //console.log(e?.response);
      });
  };
  const renderLoading = () => (
    <div className="flex justify-center items-center w-full border border-primary py-8">
      <Loader type="Bars" color="#FFFF00" height={20} width={20} />
    </div>
  );
  const renderRows = (data) =>
    data.map((item, index) => (
      <div key={index} className="mt-2">
        <AdCard
          type={item.type}
          buy={item.type === "buy"}
          title={parseFloat(""+item.unitPrice).toFixed(2) + " " + item.currency+" / "+item?.assetCode?.toUpperCase()}
          maxamount={item.unitPriceMax}
          currency={item.currency}
          bank={item.bank_name}
          advertiser={"Alfred Curry"}
          onAction={()=>{}}
          inProfile={true}
          isMyProfile={state.isMyProfile}
          status={item.status}
          item={item}
        />
      </div>
    ));

  if (state.loading) return renderLoading();
  return (
    <div className="flex flex-col w-full">
      <div className="h-full border border-primary mb-2">
        <h1 className="text-white border-b border-primary text-sm p-1">
          Buy Ads
        </h1>
        <div className="h-full overflow-y-auto">
            {renderRows(state.buyAds)}
          </div>
      </div>
      <div className="h-full border border-primary">
        <h1 className="text-white border-b border-primary text-sm p-1">
          Sell Ads
        </h1>
        <div className="h-full overflow-y-auto">
            {renderRows(state.sellAds)}
        </div>
      </div>
    </div>
  );
}

export default P2pProfileAds;
