
export const theme = {
    color:{
        primary: '#232321',
        secondaryGray:'#7D7D7C',
        accent:'#E48D0F',
        purple:'#6236FF',
        green:'#00C053',
        red:'#E02020',
        blue:'#0091FF',
        white:'#FFFFFF',
        pink:'#E23E47',
        background:'#0D0E09',
    },
    fonts:{
        type:{
            muliBlack:'Muli Black',
            bold:'Muli-Bold',
            semiBold:'Muli-SemiBold',
            regular:'Muli-Regular'
        },
        transform:{
            upperCase:'uppercase',
            none:'none'
        },
        size:{
            title:'31px',
            secondaryTitle: '24px',
            h1:'21px',
            h2:'18px',
            h3:'16px',
            h4:'14px',
        }
    }
}
export const spacing = {
    micro:'5px',
    small:'8px'
}

export const colors = {
        primary: '#232321',
        secondaryGray:'#7D7D7C',
        accent:'#E48D0F',
        gray4:'#D8D8D8D8',
        purple:'#6236FF',
        lightGreen:'#6DD400',
        green:'#00C053',
        red:'#E02020',
        blue:'#0091FF',
        white:'#FFFFFF',
        pink:'#E23E47',
        background:'#0D0E09',
}
// module.exports = colors

export const sizes = {
    getWidth : (width:number) => window.innerWidth * width / 100,
    getHeight : (height:number) => window.innerHeight * height / 100,
    borderRadius: (window.innerHeight + window.innerWidth ) * 0.002
}