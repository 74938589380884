import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { getPreferences, setPrefrences } from "services/storageServices";
import { useAlert } from "react-alert";
import { fetchAssetsByCurrencyAction, fetchAssetsAction } from "store/actions/AssetsActions";
import { BASE_URL } from "utils/AppConstants";
import { fetchCurrenciesSuccess } from "store/actions/GenericActions";
import { useLocation } from "react-router-dom";
import { setCoinsAction, updateCoinsAction } from "store/actions/TransactionActions";


let usePrefrences = (callback)=>{
    let alert = useAlert();
    let dispatch = useDispatch();
    let [inputs, setInputs] =useState({language:0, currency:0});
    let generic = useSelector((state: RootState) => state.generic);

    let getPrefrences = () => {
        let { CURRENCY, LANGUAGE } = getPreferences();

        setInputs(prev=>({...prev, currency: CURRENCY, language: LANGUAGE}));
      };
  
      let setCurrency = () => {
        let prefrences = getPreferences();
        let newPrefrences = { ...prefrences, CURRENCY: inputs.currency, LANGUAGE: inputs.language };
        setPrefrences(newPrefrences);

        dispatch(updateCoinsAction())
      };

      let getCurrencies = () => {
        let names: string[] = [];
        generic.currencies.forEach((item) => {
          names.push(item.code);
        });
        return names;
      };

      let onOpen = ()=>{
        getPrefrences();
      }

      let handleInputChange = (e)=>{
        let { value, name } = e.target;
        setInputs(prev=>({...prev, [name]: value}))
      }

      let submit = (e)=>{
          e.preventDefault();
          setCurrency();
          callback(inputs);
      }

    return {...inputs, submit, getCurrencies, handleInputChange, onOpen}
}

export default usePrefrences