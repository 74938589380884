import React, { useState, Fragment } from "react";


export function SellPriceTable({ data, loading, floatingPoints, onSelect }) {

  return (
    <Fragment>
      
        {!loading && data.map((item: any, index: number) => {
          return <SellOfferRow onSelect={onSelect} floatingPoints={floatingPoints} key={index} item={item} />;
        })}
        {/* {!loading && data.length < 1 && (
          <div className="flex justify-center h-full items-center">
            <small className="text-gray4 text-xs">No Offers</small>
          </div>
        )} */}
    </Fragment>
  );
}
{/* <div className="flex w-full items-center justify-start pl-2 mt-1 mb-1">
        <p className="text-accent text-xxs">0.0000047 BCH</p>
      </div> */}
export function SellOfferRow({ item, onSelect, floatingPoints }) {
  const setFps = (value) => {
    if(value && value !== "" && value !== "-"){
      return Number(value).toFixed(Number(value)>999?2:7)
    } else { 
      return value
    }  
  }
  return (
    <tr
    className={item.price !== "-" ?"cursor-pointer":""}
    onClick={()=>onSelect({...item, buy: false})}
    style={{background: `linear-gradient(to left, #E0202040 ${item.depth}%, transparent ${item.depth}%)`}}
  >
    <td className="pl-4">
      <p className="text-red text-xs">{setFps(item.price)}</p>
    </td>
    <td>
      <p className="text-gray4 text-xs">{setFps(item.amount)}</p>
    </td>
    <td className="text-right pr-4">
      <p className="text-gray4 text-xs">{setFps(item.total)}</p>
    </td>
  </tr>
  );
}