import * as React from 'react';
import { DropDown } from 'components/molecular/P2P/CreateAd/DropDown';
import CustomInput from 'components/global/CustomInput';
import { colors } from 'styles';
import { FormDataRow } from 'components/molecular/P2P/CreateAd/FormDataRow';
import { BankInformation } from './BankInformation';
import { paymentMethodsEnum } from 'screens/hooks/P2P/useAdd';
import { PayoneerInformation } from './Payoneer';
import { SkrillPayment } from './Skrill';
import { DropdownGray } from 'components/atomic/DropdownGray';

export const PaymentInformationForm = ({
  bankDetail,
  email,
  emailVal,
  payment_method,
  paymentMethods,
  handleInputChange,
}) => {
  return (
    <div>
      <p className="text-white text-lg mt-4 mb-4 font-regular">
        Payment details
      </p>
      <div className="xl:w-3/6 text-white">
      <div className="flex-1 mt-2">
        <p className="text-gray4 mb-1 font-regular text-xs">
          Select Payment Method
        </p>

        <div className={`relative`}>
            <select onChange={handleInputChange} name="payment_method" className="block appearance-none w-full font-regular text-sm bg-primary text-secondaryGray border-none focus:outline-none" style={{padding: '10px'}}>
                {paymentMethods.map((item, index) => (<option key={index} selected={payment_method === item.value} value={item.value}>{item.text}</option>))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 h-10 text-white">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
            </div>
        </div>
          
      </div>
        
      </div>
      {payment_method === paymentMethodsEnum.Local_Bank && (
        <BankInformation
          bankDetail={bankDetail}
          handleInputChange={handleInputChange}
        />
      )}
      {payment_method === paymentMethodsEnum.Payoneer && !bankDetail.buy && (
        <PayoneerInformation email={email} handleInputChange={handleInputChange} emailVal={emailVal} />
      )}
      {payment_method === paymentMethodsEnum.Skrill && !bankDetail.buy && (
        <SkrillPayment email={email} handleInputChange={handleInputChange} emailVal={emailVal} />
      )}
      <div className="md:flex w-full items-center">
        <div className="w-3/6">
          <FormDataRow textleft="Payment Window" textRight="15 minutes" />
        </div>
        <p className="text-secondaryGray text-xxs text-right md:ml-16 md:mt-8 xl:text-xs font-regular">
          If buyer does not pay within the payment time, the trade will be
          cancelled automatically
        </p>
      </div>
      {/* <div className="mt-4 flex w-4/3">
        <input
          className="mt-1"
          type="checkbox"
          checked={bankDetail.bankConfirmed}
          onClick={bankDetail.bankInfo}
        ></input>
        <button
          onClick={bankDetail.bankInfo}
          className="text-left focus:outline-none text-secondaryGray text-xxxs md:text-sm lg:text-sm xl:text-sm ml-4"
        >
          I confirm that i have added correct payment information.
        </button>
      </div> */}
    </div>
  );
};
