import axios from "axios"

export const getData = async (data: any) => {
    return await axios.get(`https://stagingapi.gpay.tech/ordered-transactions/get-checkout/${data}`);
}

export const updateTransaction = async (params: any, data: any) => {
    return await axios.patch(`https://stagingapi.gpay.tech/ordered-transactions/update-checkout/${params?.memo}`, data);
}

export const getCurrencyExchange = async (params: any) => {
    return await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${params}&vs_currencies=usd`)
}