import React from "react";
import Modal from "react-modal";
import { closeButton } from "assets/images";
import usePrefrences from "./hooks/usePrefrences";
import { languages } from "utils/constants";
import { SelectOption } from "./SelectOption";

function LangCurrencySet({ isOpen, onClose }) {
  let {
    language,
    currency,
    submit,
    getCurrencies,
    handleInputChange,
    onOpen,
  } = usePrefrences((data)=>{onClose()});

  return (
    <Modal isOpen={isOpen} style={customStyle} onAfterOpen={onOpen}>
      <form onSubmit={submit} className="flex flex-col">
        <div className="flex justify-between border-b border-gray-600 pb-2">
          <h2 className="text-lg font-semibold text-gray-400">Preferences</h2>
          <button onClick={onClose}>
            <img src={closeButton} className="w-6 h-6" />
          </button>
        </div>
        <div className="flex flex-between">
            <div className="w-full mr-1">
          <SelectOption
            border="none"
            label="Language"
            name="language"
            value={language}
            onChange={handleInputChange}
            data={languages}
            background="bg-background"
          />
        </div>
        <div className="w-full ml-1">
          <SelectOption
            border="none"
            label="Currency"
            name="currency"
            value={currency}
            onChange={handleInputChange}
            data={getCurrencies()}
            background="bg-background"
          />
          </div>
        </div>
        <button
          type="submit"
          className="focus:outline-none flex bg-accent p-2 justify-center rounded text-white mt-8"
        >
          Save Changes
        </button>
      </form>
    </Modal>
  );
}

export default LangCurrencySet;

const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    zIndex: 100
  },
  content: {
    top: "10%",
    left: "35%",
    right: "auto",
    bottom: "auto",
    width: "30%",
    margin: "0 auto",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
