import React, { Component, ComponentProps, FunctionComponent, ClassicComponentClass } from 'react'
import {LoginWrapper,MainWrapper,LoginWrapperAlignment} from 'styles'

const Wrapper = (props:any) => {
    const {style=[''],container,loginContainer,Center,Fh,Fw, Middle,Border,isBtn} = props
    const switcher = [ 
        container && MainWrapper,
        loginContainer && LoginWrapper,
        Center && LoginWrapperAlignment.xAlignment('center'),
        Middle && LoginWrapperAlignment.yAlignment('center'),
        Fh && LoginWrapperAlignment.heightFull(),
        Fw && LoginWrapperAlignment.widthFull(),
        // Border && LoginWrapperAlignment.setborder(),
    ]
    
    const styles = [
        `${switcher} ${style}`
    ]
    
    type customElement ={
        setClass : string
    }

const CustomWrapper:FunctionComponent<customElement> = ({ children, setClass}) => {
    return(
        isBtn ? 
        <button className={`focus:outline-none `+setClass}>
            {children}
        </button>
        :
        <div className={setClass}>
            {children}
        </div>
    )
}
    return(
        // <div className={`${styles}`}>
        //     {props.children}
        // </div>
        <CustomWrapper setClass={`${styles}`}>
            {props.children}
        </CustomWrapper>
    )
}


export default Wrapper