import Adds from "components/organisms/markets/P2P/Adds";
import { getSelectedKeys } from "services/trannsactionServices";
import {
  GetP2pActionsType,
  FETCH_ADDS_PENDING,
  FETCH_ADDS_SUCCESS,
  FETCH_ADDS_ERROR,
  SELECT_COIN_NAME,
  INIT_P2P,
  SET_ACTIVE_TAB,
  APPEND_ADD,
  FETCH_TRADES_SUCCESS,
  FETCH_MY_ADDS_SUCCESS,
  APPEND_TRADE,
  UPDATE_TRADE,
  FETCH_PRICES_SUCCESS,
  CHANGE_ADD_STATUS,
  UPDATE_ADD,
  RE_INIT_P2P,
  REMOVE_ADD,
  INIT_P2P_ERROR,
  SELECT_COIN_NAME_PENDING,
  SELECT_COIN_NAME_ERROR,
  REMOVE_ADD_NEW_STATUS,
  FETCH_MY_ADDS_PENDING,
  FETCH_MY_NEXT_ADDS,
  FETCH_TRADES_PENDING,
  FETCH_MY_ADDS_PENDING_WITH_TYPE,
  SET_COIN_ACTION,
  SEARCHING_ADDS,
  SAVE_USD_RATES,
  FETCH_ACTIVE_TRADES_PENDING,
  FETCH_ACTIVE_TRADES_SUCCESS,
} from "store/actions/P2pActionsTypes";
import { P2pState, CionP2p } from "store/models/P2pState";
import { tradeStatusEnum } from "utils/AppConstants";

const coin: CionP2p = {
  name: "XLM",
  buyAdds: [],
  buyAddsBk: [],
  sellAddsBk: [],
  sellAdds: [],
  buyPage: 1,
  sellPage: 1,
  myBuyAdds: [],
  mySellAdds: [],
  priceBuy: "0",
  priceSell: "0",
  myBuyAddsPage: 1,
  mySellAddsPage: 1,
  loadingTrades: false,
  amount: 0,
  loading: false,
  loadingMyBuyAdds: false,
  loadingMySellAdds: false,
  trades: [],
  tradePagination: {
    current: 1,
    totalPages: 0,
  },
};
const initialState: P2pState = {
  coins: [],
  active: 0,
  loading: true,
  activeTab: "buy",
  searching: false,
  tradeUpdate: false,
  usdRates: [],
  activeTrades: [],
  loadingActiveTrades: false,
  activeTradesPagination: {
    current: 1,
    totalPages: 0,
  },
};

export function p2pReducer(
  state = initialState,
  action: GetP2pActionsType
): P2pState {
  switch (action.type) {
    case FETCH_ADDS_SUCCESS:
      let coins = [...state.coins];
      coins[state.active] = action.coin;
      return {
        ...state,
        coins: coins,
        loading: false,
        searching: false,
      };
    case FETCH_ADDS_PENDING:
      let coins2 = [...state.coins];
      coins2[action.active].loading = true;
      return {
        ...state,
        coins: coins2,
      };
    case FETCH_TRADES_PENDING:
      let freshCoins = [...state.coins];
      freshCoins[action.active].loadingTrades = true;
      return {
        ...state,
        coins: freshCoins,
      };
    case FETCH_ACTIVE_TRADES_PENDING:
      return {
        ...state,
        loadingActiveTrades: true,
      };
    case FETCH_MY_ADDS_PENDING:
      let coins4 = [...state.coins];
      coins4[action.active].loadingMyBuyAdds = true;
      coins4[action.active].loadingMySellAdds = true;
      return {
        ...state,
        coins: coins4,
      };
    case FETCH_MY_ADDS_PENDING_WITH_TYPE:
      let coinsFresh = [...state.coins];
      if (action.category === "buy") {
        coinsFresh[action.active].loadingMyBuyAdds = true;
      } else {
        coinsFresh[action.active].loadingMySellAdds = true;
      }
      return {
        ...state,
        coins: coinsFresh,
      };
    case FETCH_ADDS_ERROR:
      let coins3 = [...state.coins];
      coins3[action.active].loading = false;
      coins3[action.active].loadingMyBuyAdds = false;
      coins3[action.active].loadingMySellAdds = false;
      return {
        ...state,
        loading: false,
        coins: coins3,
        searching: false,
        activeTrades: [],
        loadingActiveTrades: false,
        activeTradesPagination: { current: 1, totalPages: 0 },
      };
    case FETCH_MY_ADDS_SUCCESS:
      let coinsAll = [...state.coins];
      coinsAll[state.active].myBuyAdds = action.buyAdds;
      coinsAll[state.active].mySellAdds = action.sellAdds;
      coinsAll[state.active].loadingMyBuyAdds = false;
      coinsAll[state.active].loadingMySellAdds = false;
      return {
        ...state,
        coins: coinsAll,
      };
    case SAVE_USD_RATES:
      return {
        ...state,
        usdRates: action.rates,
      };
    case FETCH_MY_NEXT_ADDS:
      let coinsAll2: any = [...state.coins];
      const addsType = action.category === "buy" ? "myBuyAdds" : "mySellAdds";
      const loadingType =
        action.category === "buy" ? "loadingMyBuyAdds" : "loadingMySellAdds";
      const pageType =
        action.category === "buy" ? "myBuyAddsPage" : "mySellAddsPage";
      coinsAll2[state.active][addsType] = [
        ...coinsAll2[state.active][addsType],
        ...action.adds,
      ];
      coinsAll2[state.active][loadingType] = false;
      coinsAll2[state.active][pageType] = action.page;
      return {
        ...state,
        coins: coinsAll2,
      };
    case INIT_P2P:
      return {
        ...state,
        coins: action.coins,
        loading: false,
      };
    case INIT_P2P_ERROR:
      return {
        ...state,
        coins: action.coins,
        loading: false,
        loadingActiveTrades: false,
        activeTrades: [],
        activeTradesPagination: {
          current: 1,
          totalPages: 0,
        },
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab,
      };
    case APPEND_ADD:
      const index1 = state.coins.findIndex(
        (item) => item.name === action.code.toUpperCase()
      );
      let newCoins = [...state.coins];
      newCoins[index1][action.side] =[action.add, ...newCoins[index1][action.side]]
      return {
        ...state,
        coins: newCoins,
      };
    case APPEND_TRADE:
      const activeTrade = state.activeTrades.find((trade) => trade?.id === action.trade?.id)

      return {
        ...state,
        activeTrades: activeTrade?[...state.activeTrades]: [action.trade, ...state.activeTrades],
      };
    case UPDATE_TRADE:
      const inHistory =
        action.trade.status === tradeStatusEnum.Completed ||
        action.trade.status === tradeStatusEnum.Cancelled ||
        action.trade.status === tradeStatusEnum.Cancelled_With_Holded_Funds;

      const index3 = state.coins.findIndex(
        (item) => item.name === action.trade?.p2pads?.assetCode?.toUpperCase()
      );
      let newCoins3 = [...state.coins];
      let newActiveTrades = [...state.activeTrades];
      let trades = [...newCoins3[index3].trades];
      const tradeIndex = trades.findIndex(
        (trade) => trade.id === action.trade.id
      );
      const tradeActiveIndex = newActiveTrades.findIndex(
        (trade) => trade.id === action.trade.id
      );
        if(tradeIndex !== -1){
          newCoins3[index3].trades[tradeIndex] = action.trade;
        }
        if(tradeActiveIndex !== -1){
          if(inHistory){
            newCoins3[index3].trades = [action.trade, ...newCoins3[index3].trades]
            newActiveTrades.splice(tradeActiveIndex, 1)
          }else{
            newActiveTrades[tradeActiveIndex] = action.trade
          }
        }
      return {
        ...state,
        coins: newCoins3,
        tradeUpdate: !state.tradeUpdate,
        activeTrades: newActiveTrades
      };
    case SELECT_COIN_NAME:
      let newCoins4 = [...state.coins];
      const index = newCoins4.findIndex((item) => item.name === action.name);
      newCoins4[index] = action.coin;
      return {
        ...state,
        active: index,
        coins: newCoins4,
        activeTab: "buy",
      };
    case SET_COIN_ACTION:
      let newCoins11 = [...state.coins];
      const index11 = newCoins11.findIndex((item) => item.name === action.name);
      newCoins11[index11] = action.coin;
      return {
        ...state,
        coins: newCoins11,
      };
    case SELECT_COIN_NAME_PENDING:
      let newCoins5 = [...state.coins];
      const index4 = newCoins5.findIndex((item) => item.name === action.name);
      newCoins5[index4].loading = true;
      return {
        ...state,
        active: index4,
        coins: newCoins5,
        activeTab: "buy",
      };
    case SELECT_COIN_NAME_ERROR:
      let newCoins6 = [...state.coins];
      const index5 = newCoins6.findIndex((item) => item.name === action.name);
      newCoins6[index5].loading = false;
      return {
        ...state,
        active: index5,
        coins: newCoins6,
        activeTab: "buy",
      };
    case FETCH_TRADES_SUCCESS:
      let coins5 = [...state.coins];
      coins5[state.active].trades = [
        ...coins5[state.active].trades,
        ...action.trades,
      ];
      coins5[state.active].tradePagination = {
        totalPages: action.totalPages,
        current: action.current,
      };
      coins5[state.active].loadingTrades = false;
      return {
        ...state,
        coins: coins5,
      };
    case FETCH_ACTIVE_TRADES_SUCCESS:
      return {
        ...state,
        activeTrades: [...state.activeTrades, ...action.trades],
        activeTradesPagination: {
          totalPages: action.totalPages,
          current: action.current,
        },
        loadingActiveTrades: false,
      };
    case FETCH_PRICES_SUCCESS:
      let coins6 = [...state.coins];
      coins6[state.active].priceBuy = action.buying;
      coins6[state.active].priceSell = action.selling;
      return {
        ...state,
        coins: coins6,
      };
    case CHANGE_ADD_STATUS:
      let coins7: any = [...state.coins];
      const selected = coins7.findIndex((coin) => coin.name === action.coin);
      let buyAddIndex = coins7[selected][action.side].findIndex(
        (add: any) => add.id === action.id
      );
      let add: any = coins7[selected][action.side][buyAddIndex];
      add.status = add.status === "Active" ? "Paused" : "Active";
      coins7[selected][action.side][buyAddIndex] = add;
      return {
        ...state,
        coins: coins7,
      };
    case UPDATE_ADD:
      let coins8: any = [...state.coins];
      const coinIndex = coins8.findIndex(
        (coin) => coin.name === action.add.assetCode.toUpperCase()
      );
      const type =
        action.add.account === getSelectedKeys().public
          ? action.add.type === "buy"
            ? "myBuyAdds"
            : "mySellAdds"
          : action.add.type === "buy"
          ? "buyAdds"
          : "sellAdds";
      const addIndex = coins8[coinIndex][type].findIndex(
        (add) => add.id === action.add.id
      );
      coins8[coinIndex][type][addIndex] = action.add;

      return {
        ...state,
        coins: coins8,
      };
    case RE_INIT_P2P:
      return {
        coins: [],
        active: 0,
        loading: true,
        activeTab: "buy",
        tradeUpdate: false,
        searching: false,
        usdRates: [],
        activeTrades: [],
        activeTradesPagination: {
          current: 1,
          totalPages: 0,
        },
        loadingActiveTrades: false,
      };
    case SEARCHING_ADDS:
      return {
        ...state,
        searching: true,
      };
    case REMOVE_ADD:
      let coins9: any = [...state.coins];
      const coinIndex1 = coins9.findIndex(
        (coin) => coin.name === action.add.assetCode.toUpperCase()
      );
      const type1 =
        action.add.account === getSelectedKeys().public
          ? action.add.type === "buy"
            ? "myBuyAdds"
            : "mySellAdds"
          : action.add.type === "buy"
          ? "buyAdds"
          : "sellAdds";
      const addIndex1 = coins9[coinIndex1][type1].findIndex(
        (add) => add.id === action.add.id
      );
      if (addIndex1 >= 0) coins9[coinIndex1][type1].splice(addIndex1, 1);
      return {
        ...state,
        coins: coins9,
      };
    case REMOVE_ADD_NEW_STATUS:
      let coins10: any = [...state.coins];
      const coinIndex2 = coins10.findIndex(
        (coin) => coin.name === action.add.assetCode.toUpperCase()
      );
      const type2 = action.add.type === "buy" ? "buyAdds" : "sellAdds";
      const addIndex2 = coins10[coinIndex2][type2].findIndex(
        (add) => add.id === action.add.id
      );
      if (addIndex2 >= 0) coins10[coinIndex2][type2].splice(addIndex2, 1);
      return {
        ...state,
        coins: coins10,
      };
    default:
      return state;
  }
}
