import React, { useEffect, useState } from "react";
import ChartHeader from "./ChartHeader";
import CSChart from "./CSChart";
import EJ2Chart from "./ej2Chart";
import { generateData } from "./utils";
import ChartBlock from "screens/test";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import Chart from "react-apexcharts";
import useAboutChart from "screens/hooks/useAboutChart";

function CandleStickChart({ state }) {

  const { time, price } = useAboutChart(state.coin?.toUpperCase(),state.asset.issuer);
  return (
    <div className="border border-primary mt-2 lg:mt-0 xl:mt-0">
      {/* <ChartHeader
        duration={duration}
        setDuration={setDuration}
        type={type}
        setType={setType}
      />
      <ChartBlock state={state} /> 
       <CSChart data={data} width={700} height={320}/> */}

      <div style={{ height: "450px" }} className="relative">
        <div className="w-full h-full" id="myAssetChart"></div>
        {price !== 0 && (
          <div
            className="absolute z-10 text-secondaryGray fond-medium border border-secondaryGray px-2 py-1"
            style={{ top: "10px", left: "10px" }}
          >
            <p>{time}</p>
            <p>{price}: XLM</p>
          </div>
        )}
        {/* {series[0].data.length < 3 && (
          <div
            className="flex justify-center items-center text-secondaryGray text-sm"
            style={{ height: "450px" }}
          >
            <text>Not enough data for this asset</text>
          </div>
        )} */}
        {/* <TradingViewWidget symbol={state.coin === "USDT"?"USDT/USD":(state.coin+"/USDT")} theme={Themes.DARK} autosize /> */}
      </div>
    </div>
  );
}

export default CandleStickChart;
