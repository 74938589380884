import * as React from "react";
import { TransactionsDataRow } from "components/molecular/markets/Transactions/TransactionsDataRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { RootState } from "store/reducers";
import { useSelector } from "react-redux";
import {
  fetchTransactions,
  getStellarTransactions,
} from "services/httpServices";
import { Transaction, TransactionResponse } from "store/models/Transaction";
import { getSelectedKeys } from "services/trannsactionServices";
import { getTransactions } from "services/storageServices";
import Loader from "react-loader-spinner";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";
import { getTrades } from "services/exchange";
import { getTransactionType } from "utils/helpers";

const rows = (data, coin, copyToClip) =>
  data.map((item: Transaction, index) => {
    return (
      <div className="mt-2" key={index}>
        <TransactionsDataRow
          status={item.status}
          kind={capitalize(item.kind)}
          currency={coin?.toUpperCase()}
          amount={item.finalAmount ?? 0.0}
          cryptocurrency={""}
          date={moment(item.started_at).format("LL")}
          time={moment(item.started_at).format("LTS")}
          stTrId={item.stellar_transaction_id}
          onCopy={() => copyToClip(item.stellar_transaction_id)}
        />
      </div>
    );
  });

export const TransactionScreen = () => {
  let initialState: any = [];
  let [transactions, setTrans] = React.useState(initialState);
  let [loading, setLoading] = React.useState(true);
  let [loadingNext, setLoadingNext] = React.useState(false);
  let { transaction, assets } = useSelector((state: RootState) => state);
  let initPaging: any = { stellarNextPage: false, apiPage: 1 };
  let [paging, setPaging] = React.useState(initPaging);
  let alert = useAlert();

  let copyToClip = (text) => {
    copy(text);
    alert.removeAll();
    alert.show("Copied to clipboard!", { type: "success" });
  };

  let filterRecords = (records) => {
    let coin = transaction.coins[transaction.selected].short;
    if (coin == "XLM") coin = "native";
    let asset = assets.assets.find(
      (item) => item.short.toUpperCase() == coin.toUpperCase()
    );
    let wallet = getSelectedKeys().public;

    let trx: any = [];
    records.forEach((record) => {
      if (
        (record.from != asset?.distributionPublic &&
          record.to != asset?.distributionPublic) ||
        record.type == "create_account"
      ) {
        if (
          record.asset_code == coin.toUpperCase() ||
          record.asset_type == coin ||
          (coin == "native" && record.type == "create_account")
        ) {
          let data = {
            kind: getTransactionType(record, wallet),
            status: "completed",
            finalAmount: record.amount ?? record.starting_balance,
            started_at: record.created_at,
            wallet,
            coin,
            stellar_transaction_id: record.transaction_hash,
          };
          trx.push(data);
        }
      }
    });
    return trx;
  };

  let initialize = async (stellarNext, apiPage) => {
    let coin = transaction.coins[transaction.selected].short;
    if (stellarNext) {
      setLoadingNext(true);
    } else {
      setLoading(true);
    }
    let transactions: Transaction[] = [];
    let trx = [];
    let tradeHistory = []
    let next = "";
    try {
      let dataSdk = await getStellarTransactions(stellarNext, 200);
      next = dataSdk.data._links.next.href;
      trx = filterRecords(dataSdk.data._embedded.records);
      tradeHistory = await getTrades(getSelectedKeys().public).then(data => data.records)
      console.log("records",tradeHistory)
      let data: TransactionResponse = await fetchTransactions(
        coin,
        "",
        apiPage
      );
      transactions = data.transactions;
    } catch (e) {
      //console.log(e.response);
    } finally {
      let newTrans = [...trx, ...transactions];
      let sortedTrans = newTrans.sort((a, b) => {
        return (
          new Date(b.started_at).valueOf() - new Date(a.started_at).valueOf()
        );
      });
      if (stellarNext) {
        setTrans((prev) => [...prev, ...sortedTrans]);
      } else {
        setTrans(sortedTrans);
      }
      setLoading(false);
      setLoadingNext(false);
      setPaging((prev) => ({ ...prev, stellarNextPage: next }));
    }
  };

  let initXlm = async (next) => {
    try {
      let dataSdk = await getStellarTransactions(next, 100);
      let trx: [] = filterRecords(dataSdk.data._embedded.records);
      let sortedTrans = trx.sort((a: any, b: any) => {
        return (
          new Date(b.started_at).valueOf() - new Date(a.started_at).valueOf()
        );
      });
      if(next){
        setTrans(prev=>[...prev, ...sortedTrans]);
      }else{
        setTrans(sortedTrans);
      }
      setPaging((prev) => ({
        ...prev,
        stellarNextPage: dataSdk.data._links.next.href,
      }));
    } catch (e) {
      //console.log(e.response);
    } finally {
      setLoading(false);
      setLoadingNext(false)
    }
  };

  React.useEffect(() => {
    if (transaction.coins[transaction.selected].short === "XLM") {
      initXlm(paging.stellarNextPage);
    } else {
      initialize(paging.stellarNextPage, paging.apiPage);
    }
  }, []);

  let renderNext = () => (
    <div className="flex justify-center my-6">
      <button
        onClick={()=>{
          transaction.coins[transaction.selected].short === "XLM" ? handleXlmNext() :handleNext()
        }}
        className="focus:outline-none py-2 px-3 border border-gray4 text-gray4"
      >
        Show more
      </button>
    </div>
  );

  let handleNext = () => {
    setLoadingNext(true);
    initialize(paging.stellarNextPage, paging.apiPage + 1);
    setPaging((prev) => ({ ...prev, apiPage: prev.apiPage + 1 }));
  };
  let handleXlmNext = () => {
    setLoadingNext(true);
    initXlm(paging.stellarNextPage);
  };

  return (
    <div className="font-regular overflow-hidden border border-primary md:block lg:block lg:w-4/6 xl:w-4/6 h-screen p-5">
      <text className={styles.headingStyle}>Transactions</text>
      <div className="h-full overflow-y-auto nobar">
        {!loading &&
          rows(
            transactions,
            transaction.coins[transaction.selected].short,
            copyToClip
          )}
        {!loading && !loadingNext && transactions.length > 4 && renderNext()}
        {loading && (
          <div className="text-white flex justify-center pt-5">
            <Loader type="Bars" color="#FFFF00" height={30} width={30} />
          </div>
        )}
        {loadingNext && (
          <div className="text-white flex justify-center pt-5">
            <Loader type="Bars" color="#FFFF00" height={30} width={30} />
          </div>
        )}
        {!loading && transactions.length < 1 && (
          <div className="text-white flex justify-center pt-5 text-white">
            <h3>No History found.</h3>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  headingStyle:
    "text-regular text-secondaryGray text-xs md:text-xl lg:text-h1 xl:text-h1",
};

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
