import * as React from 'react';
import * as Images from 'assets/images/index';
import StarRatingComponent from 'react-star-rating-component';
import { RootState } from "store/reducers";
import { useSelector } from "react-redux";
import EditPicture from 'components/atomic/EditPicture';
export const PersonInfoBlock = (props: any) => {

  let {
    transaction: { selectedWallet },
    generic,
  } = useSelector((state: RootState) => state);

  return (
    // <div className="w-full">
    <div className="flex w-full">
      <EditPicture profileImage={props.profileImage} />
      <div className="ml-2">
        <p className="text-white font-regular">{props.personName ? props.personName: selectedWallet?.name ? (selectedWallet.name.substring(0, 4) + "..." + selectedWallet.name.substring(selectedWallet.name.length - 4, selectedWallet.name.length)) : "" || ""}</p>
        <div className="flex items-center" onClick={props.ratingClicked}>
          <StarRatingComponent name="" value={props.rating}/>
          <p className="text-white ml-1 text-xs font-regular cursor-pointer">({props.reviews} reviews)</p>
        </div>
        <div className="mt-3">
              <p className="text-secondaryGray text-xxs font-regular">
              {props.contact}
              </p>
               <p className="text-secondaryGray text-xxs font-regular">
              {props.email}
              </p> 
            </div>
      </div>
    </div>
    //   </div>
  );
}