import { useState, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { getPreferences } from 'services/storageServices';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import {
  withdrawRequest,
  fetchTransactions,
  fetchTotalFee,
} from 'services/httpServices';
import {
  getSelectedKeys,
  sendNonNativeBalance,
  getSelectedSecret,
} from 'services/trannsactionServices';
import { TransactionResponse } from 'store/models/Transaction';
import copy from 'copy-to-clipboard';
import { transactionsHistoryAction } from 'store/actions/TransactionActions';
import { addressLength } from 'utils/constants';
import { chainTypeEnum } from 'utils/AppConstants';

let useWithdrawal = () => {
  let { transaction, assets } = useSelector((state: RootState) => state);
  let alert = useAlert();
  let dispatch = useDispatch();
  let initialState: any = [];
  let [transactions, setTrans] = useState(initialState);
  let [loading, setLoading] = useState(true);
  let [paging, setPaging] = useState({ page: 1, nextLoading: false });
  let [inputs, setInputs] = useState({
    amount: 0,
    address: '',
    amountVal: '',
    addressVal: "Please Enter Recipient's Address",
  });
  let [state, setState] = useState({
    submitting: false,
    isConfirmOpen: false,
    balance: 0,
    index: 0,
    coinName: '',
    percent: 10,
    issuer: '',
    distAccount: '',
    memo: '',
    fee: 0,
    chainType: chainTypeEnum.ERC,
  });

  const [showDropdown, isErc20, isTrc20] = useMemo(()=>{
    const coin = transaction.coins[transaction.selected].short;
    const active = assets.assets.find((item)=>item.short.toUpperCase() === coin)
    const shouldShow = !!active?.isErc20 && !!active?.isTrc20
    return [shouldShow, active?.isErc20, active?.isTrc20]
  },[assets])

  useEffect(() => {
    listenChainType();
  }, [inputs.address]);

  const listenChainType = () => {
    if (!inputs.address) {
      return;
    }

    if (inputs.address.startsWith('T') && inputs.address.length === 34) {
      setState(prev => ({ ...prev, chainType: chainTypeEnum.TRC }));
    } else if (
      inputs.address.startsWith('0x') &&
      inputs.address.length === 42
    ) {
      setState(prev => ({ ...prev, chainType: chainTypeEnum.ERC }));
    }
  };
  const setChainType = (type) => setState((prev)=>({...prev, chainType: type}))

  let handleSubmit = e => {
    e.preventDefault();
    if (!validated()) {
      return;
    }

    sessionCheck();
  };

  let sessionCheck = () => {
    let prefrences = getPreferences();
    if (prefrences.PASSWORD === 'Every transaction') {
      setState(prev => ({ ...prev, isConfirmOpen: true }));
    } else {
      withdraw();
    }
  };

  let withdraw = () => {
    setState(prev => ({ ...prev, submitting: true }));
    let account = getSelectedKeys().public;
    let amount = parseFloat(inputs.amount.toString());

    withdrawRequest({
      asset_code: state.coinName,
      account: account,
      amount: amount,
      dest: inputs.address,
      isTrc20: state.chainType===chainTypeEnum.TRC
    })
      .then(res => {
        if (res.status == 200) {
          setState(prev => ({
            ...prev,
            index: 1,
            distAccount: res.data.account_id,
            memo: res.data.memo,
            fee: 0,
          }));
        } else {
          throw 'Non success response.';
        }
      })
      .catch(e => {
        alert.show(
          e.response
            ? e.response.data.error
            : 'error occured while sending request',
        );
      })
      .finally(() => {
        setState(prev => ({ ...prev, submitting: false }));
      });
  };

  let sendBalance = () => {
    if (state.submitting) {
      return;
    }
    setState(prev => ({ ...prev, submitting: true }));
    let asset = assets.assets.filter(
      item => item.short == state.coinName.toLowerCase(),
    );

    sendNonNativeBalance(
      getSelectedSecret(),
      state.distAccount,
      (Number(inputs.amount) + Number(state.fee)).toFixed(7).toString(),
      state.memo,
      state.coinName,
      asset[0].issuer,
    )
      .then(data => {
        alert.show('Balance sent successfully.', { type: 'success' });
        setState(prev => ({ ...prev, index: 0 }));
        setInputs(prev => ({ ...prev, amount: 0, address: '' }));
        fetchHistory(paging.page);
        dispatch(transactionsHistoryAction(false));
      })
      .catch(e => {
        console.log(e.response, e);
        alert.show('Error occured while sending balance.', { type: 'error' });
      })
      .finally(() => {
        setState(prev => ({ ...prev, submitting: false }));
      });
  };

  useEffect(() => {
    initialize();
    fetchHistory(paging.page);
  }, []);

  let getTotalFee = () => {
    fetchTotalFee(state.coinName, inputs.amount)
      .then(result => {
        setState(prev => ({ ...prev, fee: result.data.fee }));
      })
      .catch(e => {
        //console.log(e);
      });
  };

  let initialize = () => {
    let coin = transaction.coins[transaction.selected];

    setState(prev => ({
      ...prev,
      balance: coin.balance,
      coinName: coin.short,
    }));
    setInputs(prev => ({
      ...prev,
      amount: parseFloat((10 * (coin.balance / 100)).toFixed(5)),
    }));
  };

  let getFee = (fixedFee, percentFee) => {
    let result = inputs.amount - (inputs.amount / 100) * percentFee - fixedFee;
    return result;
  };

  let fetchHistory = page => {
    let coin = transaction.coins[transaction.selected].short;
    fetchTransactions(coin, 'withdraw', page)
      .then((data: TransactionResponse) => {
        setTrans(prev => {
          let sortedTrans = data.transactions.sort((a, b) => {
            return (
              new Date(b.started_at).valueOf() -
              new Date(a.started_at).valueOf()
            );
          });
          return sortedTrans;
        });
      })
      .catch(e => {
        //console.log(e.response);
      })
      .finally(() => {
        setLoading(false);
        setPaging(prev => ({ ...prev, nextLoading: false }));
      });
  };

  let nextPage = () => {
    fetchHistory(paging.page + 1);
    setPaging({ nextLoading: true, page: paging.page + 1 });
  };

  let validated = () => {
    let res = true;

    if (!inputs.amount) {
      res = false;
      setInputs(prev => ({ ...prev, amountVal: "Amount can't be Empty." }));
    }
    if (parseFloat('' + inputs.amount) > parseFloat('' + state.balance)) {
      res = false;
      setInputs(prev => ({
        ...prev,
        amountVal: "Amount can't be greater than your balance.",
      }));
    }
    if (inputs.amount <= 0) {
      res = false;
      setInputs(prev => ({
        ...prev,
        amountVal: 'Amount should be greater than 0.',
      }));
    }
    if (isAddressInvalid(inputs.address)) {
      res = false;
      setInputs(prev => ({
        ...prev,
        addressVal: `Please enter a valid ${state.coinName} Address`,
      }));
    }

    if (inputs.address == '') {
      res = false;
      setInputs(prev => ({
        ...prev,
        addressVal: "Please Enter Recipient's Address",
      }));
    }

    if (state.submitting) {
      res = false;
    }
    return res;
  };

  const isAddressInvalid = (address) => {
    let chain = state.coinName
    if(isErc20) {
      chain = chainTypeEnum.ERC
    }
    if(isTrc20) {
      chain = chainTypeEnum.TRC
    }
    if(showDropdown) {
      if(state.chainType === chainTypeEnum.ERC){
        chain = chainTypeEnum.ERC
      } else {
        chain = chainTypeEnum.TRC
      }
    }
    if(!addressLength[chain]){
      return false
    }
    return address.length !== addressLength[chain]
  }

  let setPercentAmount = percent => {
    let amount = parseFloat((state.balance * (percent / 100)).toFixed(5));
    setInputs(prev => ({
      ...prev,
      amount: percent == 100 ? parseFloat(state.balance.toString()) : amount,
    }));
    setState(prev => ({ ...prev, percent: percent }));
  };

  let setIndex = index => {
    setState(prev => ({ ...prev, index }));
  };

  let setMemo = memo => {
    setState(prev => ({ ...prev, memo: memo, index: 2 }));
  };

  let closeModal = () => setState(prev => ({ ...prev, isConfirmOpen: false }));

  let handleInputChange = e => {
    let { name, value } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: value,
      [name + 'Val']: '',
    }));
  };

  let onCopy = text => {
    copy(text);
    alert.removeAll();
    alert.show('Hash copied!', { type: 'success' });
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
    state,
    withdraw,
    closeModal,
    setIndex,
    setPercentAmount,
    transactions,
    loading,
    sendBalance,
    onCopy,
    setMemo,
    nextPage,
    nextLoading: paging.nextLoading,
    setChainType,
    showDropdown
  };
};

export default useWithdrawal;
