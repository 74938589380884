import * as React from "react";
import { TradeHistoryTable } from "components/molecular/Exchange/TradeHistoryTable";
import IframeChart from "components/organisms/IframeChart";
import WalletCardMin from "components/molecular/Exchange/WalletCardMin";
import BuySellPriceTable from "components/organisms/Exchange/BuySellPriceTable";
import MakeOffer from "components/organisms/Exchange/MakeOffer";
import { Helmet } from "react-helmet";

export const ExchangeScreen = (props: any) => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Trade using our unique anchors on Stellar Decentralized Exchange."
        />
      </Helmet>
      <div className="mt-2 px-2">
        <WalletCardMin />
        <div className="">
          <div className="w-full xl:flex lg:flex mt-2">
            <IframeChart />
            <BuySellPriceTable />
          </div>
          <div className="w-full xl:flex lg:flex mb-2">
            <TradeHistoryTable />
            <MakeOffer />
          </div>
        </div>
      </div>
    </>
  );
};
