import Axios from "axios";
import { getJWT } from "./httpServices";
import { BASE_URL, tradeStatusEnum } from "utils/AppConstants";
import socketIOClient from "socket.io-client";
import { store } from "App";
import {
  appendAdd,
  appendTrade,
  getMyAddsInfoAction,
  refreshExchangeRates,
  removeAdd,
  removeAddNewStatus,
  setNewAdd,
  updateAdd,
  updateTrade,
} from "store/actions/P2pActions";
import { getSelectedKeys } from "./trannsactionServices";
import {
  appendNotification,
  appendNotificationsAction,
  pushP2pNotification,
  setSocketStatus,
} from "store/actions/GenericActions";
import { paymentMethodsTextEnum } from "screens/hooks/P2P/useAdd";

export let socket = socketIOClient(BASE_URL, {});

socket.on("connect", function () {
  socket.on("onNewAd", listenAdds);
  socket.on("onUpdateAd", listenUpdateAdd);
});

export const initWithToken = async () => {
  const token = await getJWT();
  if (token?.data?.token) {
    socket.removeAllListeners();
  } else {
    return;
  }
  socket = socketIOClient(BASE_URL, {
    query: `token=${token.data.token}`,
  });
  socket.on("connect", onConnect);
  socket.on('disconnect', onDisconnect)
  socket.on("onNewAd", listenAdds);
  socket.on("onUpdateAd", listenUpdateAdd);
  socket.on("onaddTrade", listenTrades);
  socket.on("onTradeStatusChange", listenUpdateTrade);
  socket.on("onAdStatusUpdate", onAddStatusUpdate);
};
const onConnect = () => {
  store.dispatch(setSocketStatus(true))
}
const onDisconnect = (reason) => {
  store.dispatch(setSocketStatus(false))
}
const listenUpdateTrade = (data) => {
  let { tradeId } = data;
  
  getTradeById(tradeId)
    .then((data) => {
      console.log('updated trade', data)
      store.dispatch(updateTrade(data.data.trade));
      startNotification(data.data.trade);
    })
    .catch((e) => {
       console.log(e);
    });
};
export const refreshTradeStatus = (tradeId) => {
  
  getTradeById(tradeId)
    .then((data) => {
      console.log('refresh trade', data)
      store.dispatch(updateTrade(data.data.trade));
    })
    .catch((e) => {
       console.log(e);
    });
};

const onAddStatusUpdate = (data) => {
  let { adId, message } = data;
  getAddById(adId)
    .then((data) => {
      const { status, id, assetCode, account } = data.data?.ad;
      if (account !== getSelectedKeys().public) {
        if (status === "Active") {
          const name = data.data?.ad?.type === "buy" ? "buyAdds" : "sellAdds";
          store.dispatch(
            appendAdd(data.data.ad, assetCode?.toUpperCase(), name)
          );
        } else {
          store.dispatch(removeAddNewStatus(data.data?.ad));
        }
      }
    })
    .catch((e) => {
      // console.log(e);
    });
};

const listenAdds = (data) => {
  let { adId, code, message } = data;
  getAddById(adId)
    .then((data) => {
      console.log('ad detail', data)
      const add = data.data?.ad
      //@ts-ignore
      store.dispatch(setNewAdd(add, code?.toUpperCase()))
      // const name = add?.type==="buy"?"sellAdds":"buyAdds"
      // refreshExchangeRates(code, add?.location, name)
    })
    .catch((e) => {
      // console.log(e.response);
    });
};

const listenUpdateAdd = (data) => {
  let { adId, message } = data;
  getAddById(adId)
    .then((data) => {
      const { available_amount, id, minQty } = data.data?.ad;
      if (
        Number(available_amount) > 0 &&
        Number(available_amount) >= Number(minQty)
      ) {
        store.dispatch(updateAdd(data.data?.ad));
      } else {
        store.dispatch(removeAdd(data.data?.ad));
      }
    })
    .catch((e) => {
      // console.log(e);
    });
};

const listenTrades = (data) => {
  let { tradeId, code } = data;
  getTradeById(tradeId)
    .then((data) => {
      store.dispatch(appendTrade(data.data.trade, code));
      startNotification(data.data.trade);
    })
    .catch((e) => {
      // console.log(e);
    });
};

const getTradeById = async (id) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p/getTradeById?tradeId=" + id;
  const response = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const getAddsByAccount = async (asset, page, type, account) => {
  const token = await getJWT();
  const url = `${BASE_URL}/p2p/getAdsByAssetAndAccountId?no_older_than=2020-07-22&paging_id=${page}&type=${type}&account=${account}`;
  const response = await Axios.get(url+(asset?`&asset_code=${asset}`:""), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

export const getDisputeMessages = async ({ tradeId, take, skip }) => {
  const token = await getJWT();
  const url = `${BASE_URL}/p2p-chat/chat?tradeId=${tradeId}&take=${take}&skip=${skip}`;
  const response = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

export const sendDisputMessage = async (body) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p-chat/send";
  const response = await Axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

export const uploadP2pDisputeFile = async (file) => {
  const token = await getJWT();
  const data = new FormData();
  data.append("file", file);
  const url = BASE_URL + "/p2ptemp-files/upload";
  const response = await Axios.post(url, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const changeMyAddStatus = async ({ id, status }) => {
  const token = await getJWT();
  const url = `${BASE_URL}/p2p/ChangeAdStatus?adId=${id}&status=${status}`;
  const response = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

export const fetchP2pFee = async (asset, amount, type) => {
  const token = await getJWT();
  const url = `${BASE_URL}/feesetting-levels/${type}?asset_code=${asset}&amount=${amount}`;
  const response = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

export const fetchP2pAddFee = async (asset) => {
  const url = `${BASE_URL}/feesetting-levels/p2pPriceSpread?asset_code=${asset}&account=""`;
  const response = await Axios.get(url);
  return response;
};

const claimTransaction = async (tradeId) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p/claimtransactionSignedBySeller";
  const response = await Axios.post(
    url,
    { tradeId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  
};

const getExchangeRates = async (asset, location) => {
  const url = `${BASE_URL}/p2p/exchangeRate?asset_code=${asset}&location=${location}`;
  const response = await Axios.get(url);
  return response;
};

export const getP2pTradeReviews = async (id) => {
  const token = await getJWT();
  const url = `${BASE_URL}/p2p/reviews?tradeId=${id}&accountId=${getSelectedKeys().public}`;
  const response = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};
export const resolveDispute = async (disputeId) => {

  const url = `${BASE_URL}/p2p/changeDisputeStatusToResolve`;
  const response = await Axios.post(url, {
    disputeId: disputeId,
    winBy: "buyer",
  });
  return response;
};

export const getP2pProfile = async (account) => {
  const url = `${BASE_URL}/p2p/getProfile?account=${account}`;
  const response = await Axios.get(url);
  return response;
};

export const getP2pProfileReview = async () => {
  const token = await getJWT();
  const url = `${BASE_URL}/p2p/getReviews`;
  const response = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const claimAmountTransferByBuyer = async (tradeId) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p/claimAmountTransferByBuyer";
  const response = await Axios.post(
    url,
    { tradeId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};

const cancelTrade = async (tradeId, type) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p/cancleTradeBy" + type;
  const response = await Axios.post(
    url,
    { tradeId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );

  return response;
};

const claimAmountRecived = async (tradeId) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p/claimAmountRecivedBySeller";
  const response = await Axios.post(
    url,
    { tradeId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};

export const addTradeReview = async (body) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p/addOrUpdateComment";
  const response = await Axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const disputeAmountNotRecived = async (tradeId) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p/disputeAmountNotRecivedBySeller";
  const response = await Axios.post(
    url,
    { tradeId },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};
export const disputeTradeExpired = async (tradeId, actionBy) => {
  const token = await getJWT();
  const url = BASE_URL + "/p2p/disputeTradeExpired";
  const response = await Axios.post(
    url,
    { tradeId, actionBy },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};

const startNotification = (trade) => {
  const myAccount = getSelectedKeys().public;
  let kycOpposite = trade.account === myAccount ? trade.p2pads.kyc : trade.kyc
  const oppositeName = kycOpposite.isCompanyKyc?kycOpposite.companyName:kycOpposite.firstName
  if (trade.status === tradeStatusEnum.pending_user_transfer) {
    if (trade.p2pads.account === myAccount) {
      playAudio();
      store.dispatch(
        pushP2pNotification(`${oppositeName} has started ${trade?.p2pads?.assetCode} trade via ${paymentMethodsTextEnum[trade.p2pads.payment_method]}.`)
      );
      appendNotificationsAction(
        `${trade?.p2pads?.assetCode} Trade#${trade.id}`,
        `${oppositeName} has started a trade via ${paymentMethodsTextEnum[trade.p2pads.payment_method]}.`,
        '/p2p',
        trade.id
      );
    }
  }
  if (trade.status === tradeStatusEnum.Started) {
    if (trade.type === "buy" && trade.account === myAccount) {
      playAudio();
      store.dispatch(
        pushP2pNotification(`Seller ${oppositeName} has accepted your trade.`)
      );
      appendNotificationsAction(
        `${trade?.p2pads?.assetCode?.toUpperCase()} Trade#${trade.id}`,
        `Seller ${oppositeName} has accepted your trade.`,
        '/p2p',
        trade.id
      );
    }
  }
  if (trade.status === tradeStatusEnum.FundsSent) {
    if (
      (trade.type === "buy" ? trade.p2pads.account : trade.account) ===
      myAccount
    ) {
      playAudio();
      store.dispatch(pushP2pNotification(`Buyer has paid the amount via ${paymentMethodsTextEnum[trade.p2pads.payment_method]}.`));
      appendNotificationsAction(`${trade?.p2pads?.assetCode?.toUpperCase()} Trade#${trade.id}`, `Buyer has paid the amount via ${paymentMethodsTextEnum[trade.p2pads.payment_method]}.`, '/p2p',trade.id);
    }
  }
  if (trade.status === tradeStatusEnum.Cancelled) {
    playAudio();
    store.dispatch(pushP2pNotification("Your trade has been cancelled."));
    appendNotificationsAction(
      `${trade?.p2pads?.assetCode?.toUpperCase()} Trade#${trade.id}`,
      "Your trade has been cancelled.",
      '/p2p',
      trade.id
    );
  }
  if (trade.status === tradeStatusEnum.Completed) {
    if (
      (trade.type === "buy" ? trade.account : trade.p2pads.account) ===
      myAccount
    ) {
      playAudio();
      store.dispatch(pushP2pNotification("Seller has received the amount in his account."));
      appendNotificationsAction(`${trade?.p2pads?.assetCode?.toUpperCase()} Trade#${trade.id}`, "Seller has received the amount in his account.", '/p2p',trade.id);
    }
  }
  if (trade.status === tradeStatusEnum.Disputed) {
    if (
      (trade.type === "buy" ? trade.p2pads.account : trade.account) ===
      myAccount
    ) {
      store.dispatch(pushP2pNotification("Your trade has been disputed."));
      appendNotificationsAction(
        `${trade?.p2pads?.assetCode?.toUpperCase()} Trade#${trade.id}`,
        "Your trade has been disputed.",
        '/p2p',
        trade.id
      );
      playAudio();
    }
  }
};
let sound = new Audio();
sound.src = "sounds/swiftly.mp3";
const playAudio = () => {
  sound.play();
};

const postP2pBank = async (body) => {
  const token = await getJWT();
  const response = await Axios.post(
    BASE_URL + "/p2p/addorupdatebankaccount",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};

const fetchP2pBank = async () => {
  const token = await getJWT();
  const response = await Axios.post(
    BASE_URL + "/p2p/getBankDetail",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};

const addBuyAdd = async (body) => {
  const token = await getJWT();

  const response = await Axios.post(BASE_URL + "/p2p/addBuyAd", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const addSellAdd = async (body) => {
  const token = await getJWT();
  
  const response = await Axios.post(BASE_URL + "/p2p/addSellAd", body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const fetchAdds = async ({ assetCode, type, onOlderThan, page, amount }) => {
  const token = await getJWT();
  const url = `/p2p/searchAdsByAssetCodeAndAmount?asset_code=${assetCode}&type=${type}&no_older_than=${onOlderThan}&paging_id=${page}&amount=${amount}`;
  const response = await Axios.get(BASE_URL + url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

export const confirmEscrowPayment = async (transaction_hash) => {
  const token = await getJWT();
  const url = `/p2p/confirmEscrowPayment?transaction_hash=${transaction_hash}`;
  const response = await Axios.get(BASE_URL + url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const fetchInitAdds = async ({
  assetCode,
  type,
  noOlderThan,
  page,
  location,
}) => {
  const token = await getJWT();
  const url = `/p2p/getadsByAsset?asset_code=${assetCode}&type=${type}&no_older_than=${noOlderThan}&paging_id=${page}&location=${location}`;
  const response = await Axios.get(BASE_URL + url, {
    headers: {
      "Content-Type": "application/json",
      [token?.data?.token !== ""
        ? "Authorization"
        : "Auth"]: `Bearer ${token?.data?.token}`,
    },
  });
  return response;
};

const searchAdds = async ({ assetCode, type, noOlderThan, page, amount, location }) => {
  const token = await getJWT();
  const url = `/p2p/searchAdsByAssetCodeAndAmount?asset_code=${assetCode}&type=${type}&no_older_than=${noOlderThan}&paging_id=${page}&amount=${amount}&location=${location}`;
  const response = await Axios.get(BASE_URL + url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const fetchTrades = async ({ assetCode, limit, offset }) => {
  const token = await getJWT();
  const url = `/p2p/getTradeByAccountId?asset_code=${assetCode}&take=${limit}&skip=${offset}`;
  const response = await Axios.get(BASE_URL + url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};
export const fetchActiveTrades = async ({ limit, offset }) => {
  const token = await getJWT();
  const url = `/p2p/getActiveTradeByAccountId?take=${limit}&skip=${offset}`;
  const response = await Axios.get(BASE_URL + url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

const addBuySellTrade = async (body, type) => {
  const token = await getJWT();
  const url = `/p2p/${type}`;
  const response = await Axios.post(BASE_URL + url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

export const calcAmountWSpread = (add) => {
  const { type, available_amount, priceSpread } = add;
  const amountForSell =
    Number(available_amount) - (priceSpread / 100) * Number(available_amount);
  const amountForBuy =
    Number(available_amount) + (priceSpread / 100) * Number(available_amount);
  const temp = type === "sell" ? amountForSell : amountForBuy;
  return temp;
};

const getAddById = async (id) => {
  const token = await getJWT();
  const url = `/p2p/getAdById?id=${id}`;
  const response = await Axios.get(BASE_URL + url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });
  return response;
};

export {
  postP2pBank,
  addBuyAdd,
  addSellAdd,
  fetchAdds,
  fetchInitAdds,
  fetchP2pBank,
  searchAdds,
  fetchTrades,
  addBuySellTrade,
  getAddById,
  cancelTrade,
  claimAmountTransferByBuyer,
  getTradeById,
  getAddsByAccount,
  claimAmountRecived,
  disputeAmountNotRecived,
  getExchangeRates,
  changeMyAddStatus,
};
