import React from "react";
import Modal from "react-modal";
import { closeButton } from "assets/images";

function TermsAndConditions({ onClose, isOpen }) {
  return (
    <Modal isOpen={isOpen} style={customStyle}>
      <button onClick={onClose} className="float-right">
        <img src={closeButton} className="w-6 h-6" />
      </button>
      <div className="flex flex-col items-center mt-8">
        <h2 className="text-xl text-orange-500 font-semibold mb-8">Terms & Conditions</h2>
        <div className="mx-4 mb-8 bg-sparkleGray text-gray-400 p-2 rounded">
          <p style={termsHeight} className="overflow-y-auto nobar">
              <ul>
              {/* <p> These Terms and Conditions ("Terms", "Terms and Conditions") govern your relationship with https://gwallet.tech website (the "Service") operated by Gwallet.Tech ("us", "we", or "our"). </p><br></br> */}
              <p>Please read these Terms and Conditions carefully before using the Service.</p><br/>
              <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
              <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service. This Terms and Conditions agreement is managed with the Terms and Conditions Generator.</p>
              <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>
              <p>You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.</p>
              <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
              <p>The Service and its original content, features and functionality are and will remain the exclusive property of gwallet.tech and its licensors. The Service is protected by copyright, trademark, and other laws of both the and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of gwallet.tech.</p>
              <p>Gwallet.tech has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Interstellar.Exchange shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
              <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          </ul>
          </p>
        </div>
        <div className="flex justify-center">
          {/* <button
            onClick={() => {}}
            className="bg-green font-regular text-xs text-white py-3 px-20 mt-2 mb-10 mr-3 focus:outline-none"
          >
            Accept
          </button>
          <button
            onClick={onClose}
            className="bg-accent font-regular text-xs text-white py-3 px-20 mt-2 mb-10 focus:outline-none"
          >
            Cancel
          </button> */}
        </div>
      </div>
    </Modal>
  );
}

export default TermsAndConditions;

const termsHeight = {height: "450px"};
const customStyle = {
  overlay: {
    backgroundColor: "#00000090",
  },
  content: {
    top: "10%",
    left: "25%",
    right: "auto",
    bottom: "auto",
    width: "54.4%",
    margin: "0 auto",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
