import * as React from "react";
import CustomInput from "components/global/CustomInput";
import { DropDown } from "components/molecular/P2P/CreateAd/DropDown";
import { colors } from "styles";
import { FormDataRow } from "components/molecular/P2P/CreateAd/FormDataRow";

export const LimitationsFom = ({
  buy,
  minQty,
  maxQty,
  total_amount,
  total_amountVal,
  minQtyVal,
  maxQtyVal,
  handleInputChange,
  location,
  locationVal,
  assetCode,
  balanceVal
}) => {
  return (
    <div>
      <p className="text-white text-lg mt-4 mb-4 font-regular">Limitations</p>
      <div className="mt-4 md:flex w-full">
        <div className="flex w-full">
          <CustomInput
            labelColor={colors.gray4}
            marginVertical="0.45rem"
            padding={"12px"}
            label={`Minimum ${assetCode} Amount`}
            placeholder={`Minimum amount you are willing to ${buy?'buy':'sell'}`}
            name="minQty"
            type="number"
            value={minQty}
            onChange={handleInputChange}
            valMsg={minQtyVal}
          />
        </div>
        <p className="text-secondaryGray italic md:ml-16 text-xxs w-full text-left xl:text-xs md:mt-10 mb-4 font-regular">
          The price has been accounted with trading fee (seller pay the fee).
        </p>
      </div>
      <div className="mt-4 md:flex w-full">
        <div className="flex w-full">
          <CustomInput
            labelColor={colors.gray4}
            marginVertical="0.45rem"
            padding={"12px"}
            label={`Maximum ${assetCode} Amount`}
            placeholder={`Maximum amount you are willing to ${buy?'buy':'sell'}`}
            name="maxQty"
            type="number"
            value={maxQty}
            onChange={handleInputChange}
            valMsg={maxQtyVal}
          />
        </div>
        <p className="text-secondaryGray italic md:ml-16 text-xxs w-full text-left xl:text-xs md:mt-10 mb-4 font-regular">
          Maximum {assetCode} amount in one trade
        </p>
      </div>
      <div className="mt-4 w-full md:flex">
        <div className="flex w-full">
          <CustomInput
            labelColor={colors.gray4}
            marginVertical="0.45rem"
            padding={"12px"}
            label={`Total ${assetCode} Amount`}
            placeholder="Total amount in your add"
            name="total_amount"
            value={total_amount}
            type="number"
            onChange={handleInputChange}
            valMsg={total_amountVal}
          />
        </div>
        <p className="text-secondaryGray text-xxs md:ml-16 italic w-full text-left xl:text-xs md:mt-10 mb-4 font-regular">
          Total amount of {assetCode} that you are going to {buy?'buy':'sell'}.
        </p>
      </div>
      <p className="text-red text-sm italic">{balanceVal}</p>
      <div className="mt-4">
      <div className="xl:w-3/6">
        <FormDataRow textleft="Location" textRight={location} />
      </div>
      </div>
      {buy ? null : (
        <div className="flex justify-between items-center">
          <div className="w-3/6">
            <FormDataRow textleft="Reject unverified Buyer" textRight="YES" />
          </div>
          <p className="text-secondaryGray text-xxs w-2/6 mt-4 text-left xl:text-xs mb-4 font-regular">
            Accepting only verified buyer to make your trades much safer
          </p>
        </div>
      )}
    </div>
  );
};
