import * as React from "react";
import * as Images from "assets/images/index";
import { Button } from "components/global";
import useUpload from "screens/ProfileScreen tabs/hooks/useUpload";
import Upload from "components/atomic/Upload";
import { countries } from "utils/countries";
import { docTypes, docTypesCompany } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import CustomInput from "components/global/CustomInput";
import { colors } from "styles/theme";
import CustomPicker2 from "components/atomic/CustomPicker2";
import { DropdownGray } from "components/atomic/DropdownGray";
import { BottomNav } from "screens/ProfileScreen tabs/KYCProfileTab";

export const CompanyDocumentsForm = ({ tab, setTab, status }) => {
  let {
    docType,
    country,
    issueDate,
    realName,
    realNameVal,
    number,
    docTypeVal,
    issueDateVal,
    numberVal,
    companyDocumentVal,
    countryVal,
    submit,
    handleInputChange,
    saveUrl,
    submitting,
    companyDocWithNameVal
  } = useUpload(tab, setTab, true);

  return (
    <div className="flex flex-col">
      <form onSubmit={submit} className="mt-10 p-5 w-full m-auto">
        <div>
          <h4 className="text-white mt-8 mb-2 font-regular text-sm">
            Identity Verification
          </h4>

          <div className="lg:flex mb-2 items-center">
            <DropdownGray
              label="Business Registration Country"
              textMd={true}
              value={country}
              name="country"
              validation={countryVal}
              onChange={handleInputChange}
              data={countries}
            />
            <div className="mx-1" />

            <CustomPicker2
                label="Registration Date"
                left={true}
                validation={issueDateVal}
                startDate={issueDate}
                setStartDate={(value) =>
                  handleInputChange({ target: { name: "issueDate", value } })
                }
              />
          </div>

          <div className="lg:flex mb-2">
            <div className="flex-1 lg:mr-1">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="Business Name on Document"
                name="realName"
                value={realName}
                onChange={handleInputChange}
                valMsg={realNameVal}
                marginVertical="2px"
                padding={"12px"}
                placeholder="Enter your Real Name on Document here"
                maxLength={50}
              />
            </div>
            <div className="flex-1 ml-1">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="Document Number"
                name="number"
                value={number}
                type="number"
                onChange={handleInputChange}
                valMsg={numberVal}
                marginVertical="2px"
                padding={"12px"}
                placeholder="Enter your Document Number here"
              />
            </div>
          </div>

          
          <h4 className="text-white mt-8 mb-2 font-regular text-sm">
            Upload Documents
          </h4>
          <p className="font-regular text-white text-xs mb-2">
              Upload your verification document below.
              </p>
        </div>

        <div className="lg:flex">
          <div
            style={{ backgroundColor: "rgba(255,159,0,0.15)" }}
            className="w-full p-5 mb-4 lg:mr-1"
          >
            <div>
              <ul className="list-inside list-alpha">
              <p className="font-regular text-white text-xs mb-1">
              The verification picture needs to satisfy these conditions:
              </p>
                <li className="font-regular text-white text-xs">
                  No modification is made on the uploaded documents
                </li>
                <li className="font-regular text-white text-xs">
                  Documents are readable and not blurred
                </li>
              </ul>
              <p className="mt-2 font-regular text-white text-xs">
              Picture that does not satisfy any of the above will not be considered
              </p>
            </div>
          </div>
          <div className="w-full lg:ml-1">
            <div className="flex-1">
              <Upload
                label="Company registration certificate"
                onSuccess={(url) => saveUrl({ name: "companyDocument", value: url })}
                text="Upload picture of company document"
                id="documentFront"
                validation={companyDocumentVal}
              />
            </div>
            <div className="flex-1">
              <Upload
                label="Kindly upload the certificate that shows your name as the owner or one of the owners"
                onSuccess={(url) => saveUrl({ name: "companyDocWithName", value: url })}
                text="Document with your name as the owner"
                id="documentWithName"
                validation={companyDocWithNameVal}
              />
            </div>
            <div/>
          </div>
        </div>
        <div></div>
      </form>
      <BottomNav
        tab={tab}
        setTab={setTab}
        submit={submit}
        loading={submitting}
        status={status}
        edit={false}
      />
    </div>
  );
};
