import { CoinPair, PairBalance, PairMarket, Offer, PairPersist } from "store/models/Exchange";

export const GET_SELECTED_COIN_PAIR = "GET_SELECTED_COIN_PAIR";
export const SET_SELECTED_COIN_PAIR = "SET_SELECTED_COIN_PAIR";
export const UPDATE_BALANCE = "UPDATE_BALANCE";
export const UPDATE_BOTH_BALANCES = "UPDATE_BOTH_BALANCES";
export const UPDATE_Market = "UPDATE_Market";
export const SELECT_OFFER = "SELECT_OFFER";
export const INCREMENT_ADD = "INCREMENT_ADD";
export const REFRESH_MY_OFFERS = "REFRESH_MY_OFFERS";
export const UPDATE_AVAILABLE_BALANCE = "UPDATE_AVAILABLE_BALANCE";
export const SET_EXCHANGE_PRICES = "SET_EXCHANGE_PRICES";
export const RESET_SELECTED_PAIR = "RESET_SELECTED_PAIR";
export const PERSIST_SELECTED_PAIR = "PERSIST_SELECTED_PAIR";

interface SetExchangePrices {
    type: typeof SET_EXCHANGE_PRICES;
    sellPrice: number,
    buyPrice: number,
    bidsLength: number,
    asksLength: number
}
interface GetSelectedCoinPair {
    type: typeof GET_SELECTED_COIN_PAIR;
}

interface ResetSelectedPair {
    type: typeof RESET_SELECTED_PAIR;
}

interface PersistSelectedPair {
    type: typeof PERSIST_SELECTED_PAIR;
    payload: PairPersist
}

interface UpdateAailableBalance {
    type: typeof UPDATE_AVAILABLE_BALANCE;
    pair: PairBalance
}
interface SetSelectedCoinPair {
    type: typeof SET_SELECTED_COIN_PAIR;
    pair: CoinPair,
    refresh: boolean
}
interface UpdateBalance {
    type: typeof UPDATE_BALANCE;
    asset: string,
    balance: number,
}
interface UpdateBothBalances {
    type: typeof UPDATE_BOTH_BALANCES;
    baseBalance: number,
    counterBalance: number,
}
interface UpdateMarket {
    type: typeof UPDATE_Market;
    market: PairMarket
}
interface RefreshMyOffers {
    type: typeof REFRESH_MY_OFFERS;
}
interface SelectOffer {
    type: typeof SELECT_OFFER;
    offer: Offer
}
interface IncrementAdd {
    type: typeof INCREMENT_ADD;
    offer: any
}

export type ExchangeTypes =
    | GetSelectedCoinPair
    | SetSelectedCoinPair
    | UpdateBalance
    | SetExchangePrices
    | UpdateBothBalances
    | UpdateMarket
    | RefreshMyOffers
    | SelectOffer
    | IncrementAdd
    | ResetSelectedPair
    | UpdateAailableBalance
    | PersistSelectedPair