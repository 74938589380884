import * as React from 'react';
import CustomInput from 'components/global/CustomInput';
import { colors } from 'styles';

export const SkrillPayment = ({
email,
emailVal,
handleInputChange
}) => {
  return (
      <div className="xl:w-3/6">
        <CustomInput
          labelColor={colors.gray4}
          marginVertical="0.45rem"
          padding={'12px'}
          label="Skrill Email Address"
          placeholder="Enter your email address"
          name="email"
          value={email}
          type="text"
          onChange={handleInputChange}
          valMsg={emailVal}
          maxLength={80}
        />
      </div>
  );
};
