import { getSelectedKeys, getSelectedSecret } from "./trannsactionServices";
import { addAsset } from "./dbServices";
import { Asset } from "store/models/Assets";
import { STELLAR_URL, stellarNetworkPhrase, isTestNet } from "utils/AppConstants";
import TOML from 'toml'
import { tomlSampleTestNet } from "utils/tomlTestnet";

const StellarSdk = require("stellar-sdk");
const server = new StellarSdk.Server(STELLAR_URL);

const checkPath = async ({ source, destination, amount }) => {
  const sourceAsset = new StellarSdk.Asset(source.code, source.issuer);
  const destAsset = new StellarSdk.Asset(destination.code, destination.issuer);

  const paths = await server
    .strictSendPaths(
      sourceAsset,
      amount,
      getSelectedKeys().public,
    ).call()

  return paths
}


export const readToml = async (asset_issuer, alert) => {
  try {
    const issuerAccount = await server.loadAccount(asset_issuer)

    const homeDomain = issuerAccount.home_domain
    if (!homeDomain) {
      alert.show("Couldn't find a home_domain on the assets issuer");
      throw "Couldn't find a home_domain on the assets issuer"
    }

    let tomlURL: any = "https://" + homeDomain + "/";
    tomlURL = tomlURL + '.well-known/stellar.toml'
    const tomlText = await fetch(tomlURL).then((r) => r.text())
    const toml = TOML.parse(tomlText)

    return toml
  } catch (e) {
    return "error"
  }
}

export const getHomeDomain = async (address) => {
  const account = await server.loadAccount(address)
  return account?.home_domain || ""
}
async function issueAsset(name, short) {
  // Keys for accounts to issue and receive the new asset.
  const issuingKeypair = StellarSdk.Keypair.random();
  const keys = getSelectedKeys();
  const distributionKeypair = StellarSdk.Keypair.fromSecret(keys.private);
  const availableCurrency = "1000";
  const distributedCurrency = "100";
  const astroDollar = new StellarSdk.Asset(name, issuingKeypair.publicKey());
  return new Promise(async (resolve, reject) => {
    try {
      await fetch(
        `https://friendbot.stellar.org?addr=${issuingKeypair.publicKey()}`
      );
    } catch (e) {
      console.error("Failed to fund demo account! Please try again later.");
      return;
    }
    try {
      const [
        {
          max_fee: { mode: fee },
        },
        distributionAccount,
      ] = await Promise.all([
        server.feeStats(),
        server.loadAccount(distributionKeypair.publicKey()),
      ]);

      const changeTrustTx = new StellarSdk.TransactionBuilder(
        distributionAccount,
        {
          fee,
          networkPassphrase: stellarNetworkPhrase,
        }
      )
        .addOperation(
          StellarSdk.Operation.changeTrust({
            asset: astroDollar,
            limit: availableCurrency,
          })
        )
        .addOperation(
          StellarSdk.Operation.payment({
            destination: distributionKeypair.publicKey(),
            asset: astroDollar,
            amount: "1000",
            source: issuingKeypair.publicKey(),
          })
        )
        .setTimeout(100)
        .build();
      changeTrustTx.sign(distributionKeypair);
      changeTrustTx.sign(issuingKeypair);

      const txResult = await server.submitTransaction(changeTrustTx);

      recoupLumens(issuingKeypair.secret(), issuingKeypair.publicKey());

      //saving in localstorage

      resolve("Success!");

    } catch (e) {
      console.error("Oh no! Something went wrong.");

      console.error(e.response.data.detail);
      console.error(e.response.data.extras.result_codes);
      console.error(e.response.data.type);
      recoupLumens(issuingKeypair.secret(), issuingKeypair.publicKey());
      reject(e.response.data);
    }
  });
}

const recoupLumens = async (secret, destination) => {
  const StellarSdk = require("stellar-sdk");
  const server = new StellarSdk.Server(STELLAR_URL);

  const keypair = StellarSdk.Keypair.fromSecret(secret);
  const [
    {
      max_fee: { mode: fee },
    },
    account,
  ] = await Promise.all([
    server.feeStats(),
    server.loadAccount(keypair.publicKey()),
  ]);

  const tx = new StellarSdk.TransactionBuilder(account, {
    fee,
    networkPassphrase: stellarNetworkPhrase,
  })
    .addOperation(
      StellarSdk.Operation.accountMerge({
        destination: destination,
      })
    )
    .setTimeout(100)
    .build();
  tx.sign(keypair);
  await server.submitTransaction(tx).catch(() => { });
};

const addTrust = async (asset) => {
  return new Promise(async (resolve, reject) => {
    let test = asset.issuer;

    try {

      // Keys for accounts to issue and receive the new asset

      var receivingKeys = StellarSdk.Keypair
        .fromSecret(getSelectedSecret()); //Who want to trust

      // Create an object to represent the new asset
      var astroDollar = new StellarSdk.Asset(asset.code.toUpperCase(), asset.issuer);

      // First, the receiving account must trust the asset
      server.loadAccount(receivingKeys.publicKey())
        .then(function (receiver) {
          var transaction = new StellarSdk.TransactionBuilder(receiver, {
            fee: '500',
            networkPassphrase: stellarNetworkPhrase
          })
            // The `changeTrust` operation creates (or alters) a trustline
            .addOperation(StellarSdk.Operation.changeTrust({
              asset: astroDollar
            }))
            // setTimeout is required for a transaction
            .setTimeout(100)
            .build();
          transaction.sign(receivingKeys);
          return server.submitTransaction(transaction);
        })
        .then(console.log)
        // Second, the issuing account actually sends a payment using the asset

        .then(data => {
          resolve('Success')
        })
        .catch(function (error) {
          // console.log(error.response)
          console.error('Error!', error);
          reject(error)
        });

    } catch (err) {
      // console.log(err.response)
      reject(err)
    }
  })
}

const getUsdBalance = (asset, item) =>
  asset.currentRate && item.balance ? item.balance * asset.currentRate : 0.0;

const getUsdValue = (currentRate, value) => value * currentRate;

const getBtcBalance = (asset, item) => {
  if (!Boolean(asset.btcRate)) {
    return 0
  }
  return item.balance / asset.btcRate;
};

export const getLiquidityData = async (liquidityAssetId) => {
  const data = await server
    .liquidityPools()
    .liquidityPoolId(liquidityAssetId)
    .call()
    .then(function (resp) {
      return resp;
    })
    .catch(function (err) {
      console.error(err);
    });
  return data;
}

export { issueAsset, getUsdBalance, getBtcBalance, addTrust, getUsdValue, checkPath };
