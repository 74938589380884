import * as React from "react";
import { GenericBtnStyle } from "styles";

const Button = (props:any) =>{
  const { styles = [" "] } = props;
    return (
      <button onClick={props.onClick} type="submit" className={`${GenericBtnStyle}` + " " + styles}>
        {props.children}
      </button>
      ) 
}

export default Button
