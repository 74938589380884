import React from "react";
import bgImg from "../../../assets/images/Mask_Group_3.svg";
import Logo from "../../../assets/images/Logo.svg";
import CardBottom from "../../../assets/images/card-bot.svg";
import Warning from "../../../assets/images/warning.svg";

const HomeBanner = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${bgImg})`,
      }}
    >
      <div className=" px-4 py-4 mx-auto ">
        <img className="mx-auto" src={Logo} alt="logo" />
        <p
          style={{ color: "#E48D0F", fontSize: "35px" }}
          className="text-[#E48D0F] my-4 text-center text-[30px] md:text-[40px] font-medium"
        >
          OUR OPERATIONAL STATEMENT
        </p>
        <div className="sm:flex gap-x-8">
          <div
            style={{ background: "#1E1E1E" }}
            className="bg-[#1E1E1E] relative min-h-[260px] sm:min-h-[180px] md:min-h-[220px] lg:min-h-[180px] relative mx-auto mt-5 py-3 w-full md:w-1/2  pb-0"
          >
            <div className="px-4">
              <span className="text-white">
                <label
                  style={{ color: "#E48D0F" }}
                  className="text-[#E48D0F] text-[18px] p-0"
                >
                  GWALLET
                </label>{" "}
                is an interface that can be used to access the Stellar
                Decentralized Exchange (DEX). Both GWALLET and the developer are
                not involved with the operation of the Stellar network.
              </span>
            </div>
            <img className="w-full mt-5" src={CardBottom} alt="card" />
          </div>
          <div
            style={{ background: "#1E1E1E" }}
            className="bg-[#1E1E1E] relative min-h-[260px] sm:max-h-[180px] md:min-h-[220px] lg:min-h-[180px] lg:bg-[#fff] mx-auto mt-5 py-3 w-full md:w-1/2  pb-0"
          >
            <div className="px-4">
              <span className="text-white">
                <label
                  style={{ color: "#E48D0F" }}
                  className="text-[#E48D0F] text-[18px] pb-3"
                >
                  GWALLET
                </label>{" "}
                is not the custodian of your digital assets. We do not store
                tokens, digital assets or private keys on your behalf
              </span>
            </div>
            <img
              className="w-full mt-5 sm:absolute bottom-0"
              src={CardBottom}
              alt="card"
            />
          </div>
        </div>
        <p
          style={{ color: "#E48D0F", fontSize: "35px" }}
          className="text-[#E48D0F] my-4 text-center text-[30px] md:text-[40px] font-medium"
        >
          Where are Digital Assets Stored?
        </p>
        <div className="sm:flex gap-x-8">
          <div
            style={{ background: "#1E1E1E" }}
            className="bg-[#1E1E1E] min-h-[260px] sm:min-h-[180px] md:min-h-[220px] lg:min-h-[180px] relative mx-auto mt-5 py-3 w-full md:w-1/2  pb-0"
          >
            <div className="px-4">
              <span className="text-white">
                In the Stellar network, funds are on the network by reading
                public addresses and can only be handled by anyone with a secret
                key. Therefore, a secret key is VERY IMPORTANT, and anyone who
                can access it can operate the account. However, digital assets
                are NOT actual "inside" the GWALLET.
              </span>
            </div>
            <img className="w-full mt-5" src={CardBottom} alt="card" />
          </div>
          <div
            style={{ background: "#1E1E1E" }}
            className="bg-[#1E1E1E] relative sm:max-h-[260px]  sm:min-h-[180px] md:min-h-[220px] lg:min-h-[180px] relative mx-auto mt-5 py-3 w-full md:w-1/2  pb-0"
          >
            <div className="px-4">
              <span className="text-white">
                <label
                  style={{ color: "#E48D0F" }}
                  className="text-[#E48D0F] text-[18px] p-0"
                >
                  GWALLET
                </label>{" "}
                is simply the tool of your choice that helps you use your secret
                key in operating Stellar network private wallets.
              </span>
            </div>
            <img
              className="w-full sm:absolute bottom-0 mt-5"
              src={CardBottom}
              alt="card"
            />
          </div>
        </div>
        <div
          style={{ backgroundColor: "#E23737" }}
          className="w-full bg-[#E23737] py-3 mt-8"
        >
          <div className="flex justify-center">
            <img src={Warning} alt="warning" />
            <p
              style={{ fontSize: "30px" }}
              className="text-white text-[30px] ml-2 text-center my-1"
            >
              WARNING
            </p>
          </div>
          <p className="text-white text-[19px] md:text-[24px] text-center font-semibold mt-0">
            Never share your secret key with anyone
          </p>
        </div>
      </div>
    </div>
  );
};
export default HomeBanner;
