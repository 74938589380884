export interface KeyCoin {
  id: string;
  name: string;
  public: string;
  secret: string;
  walletId: string;
}

export interface JWTRes {
  data: ResData;
  status: number;
  statusText: string;
}

export interface ResData {
  network_passphrase: string;
  transaction: string;
}

export enum zakatHistoryStatus {
  unpaid = "unpaid",
  paid = "paid",
}

export interface ZakatEntry {
  account: string;
  distAccount: string;
  totalAmount: number;
  zakatAmount: number;
  zakatPercentage: number;
  stellarTxId: string;
  memo: string;
  year: number;
  coinCode: string;
  status: zakatHistoryStatus;
  new: boolean;
}
