import * as React from "react";
import {colors} from 'styles/theme';

const StateAwareInput = (props: any) => {
    const [isFocused, setIsFocused] = React.useState(false);

    return(
        <div className="flex items-end" style={{ borderBottomWidth: 1, borderBottomColor: isFocused ? props.activeColor : colors.secondaryGray}}>
        <small className="text-gray4 mb-2 ml-2">{props.placeholder||""}</small>
        <input
            className="appearance-none text-right bg-transparent text-xs font-regular text-white w-full mt-3 py-2 leading-tight placeholder-gray4 focus:outline-none"
            type={props.type||"number"}
            onFocus={()=>{setIsFocused(true)}}
            onBlur={()=>{setIsFocused(false)}}
            aria-label={props.label||""}
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            disabled={props.disabled}
            autoComplete="off"
        />
        <small className="text-gray4 mb-2 ml-2">{props.trialing}</small>
        </div>
    );
};

export default StateAwareInput;