import React, { useEffect, useMemo, useState } from "react";
import { checkedIcon, copyIcon, downloadIcon } from "assets/images";
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-loader-spinner";
import copy from "copy-to-clipboard";
import {
  faCopy,
  faExternalLinkAlt,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { fetchDeposit, fetchTransactions } from "services/httpServices";
import { RootState } from "store/reducers";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { TransactionResponse } from "store/models/Transaction";
import moment from "moment";
import { capitalize } from "utils/AppConstants";
import { TransactionsDataRow } from "components/molecular/markets/Transactions/TransactionsDataRow";
import GenericTab from "components/atomic/GenericTab";
const type = ["ERC", "TRC"]
const chainTypes = ["ERC20", "TRC20"]

function Deposite() {
  let [key, setKey] = useState("");
  let [errorMessage, setError] = useState("");
  let initialState: any = [];
  let [transactions, setTrans] = useState(initialState);
  let [loading, setLoading] = useState(true);
  let [loadingNext, setLoadingNext] = useState(false);
  const [feeFixed, setFeeFixed] = useState(0);
  const [feePercent, setFeePercent] = useState(0);
  const [depositMin, setDeposit] = useState(0);
  let [page, setPage] = useState(1);
  const [typeIndex, setType] = useState(0);
  let alert = useAlert();
  let {transaction, assets:{assets}} = useSelector((state: RootState) => state);
  const activeAsset = transaction.coins[transaction.selected]

  let CopyToClip = () => {
    copy(key);
    alert.removeAll();
    alert.show("Copied to clipboard!", { type: "success" });
  };
  let onCopy = (text) => {
    copy(text);
    alert.removeAll();
    alert.show("Hash copied!", { type: "success" });
  };

  const initialize = () => {
    setLoading(true);
    setError("")
    
    fetchDeposit(activeAsset.short, type[activeAsset.short==="TRX"?1:typeIndex])
      .then(([depositRes, historyRes]) => {
        if (depositRes.status === 200) {
          setKey(depositRes.data.how);
          setFeeFixed(depositRes.data.fee_fixed)
          setFeePercent(depositRes.data.fee_percent)
          setDeposit(depositRes.data.deposit_min)
        }
        if (historyRes.status == 200) {
          setTrans(historyRes.data.transactions);
        }
      })
      .catch((error) => {
        if (error.response) {
          //alert.show(error.response.data.error)
          setError(
            error.response.data.error ?? "Error while getting deposite address."
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchNext = (pageNo) => {
    
    setLoadingNext(true);
    fetchTransactions(activeAsset.short, "deposit", pageNo)
      .then((data) => {
        
        setTrans(prev=>[...prev, ...data.transactions]);
        setLoadingNext(false);
      })
      .catch((e) => {
        setLoadingNext(false);
      });
  };

  const nextPage = () => {
    fetchNext(page + 1);
    setPage(page + 1);
  };

  useEffect(() => {
    initialize();
  }, [typeIndex]);

  const [showTabs, showWarning, isErc20] = useMemo(()=>{
    const coin = activeAsset.short;
    const active = assets.find((item)=>item.short.toUpperCase() === coin)
    const tabTemp = active?.isErc20 && active?.isTrc20
    const warningTemp = (active?.isErc20 || active?.isTrc20 ) && (active.short?.toUpperCase() != "TRX") && (active.short?.toUpperCase() != "ETH")
    return [tabTemp, warningTemp, active?.isErc20]
  },[assets])

  const renderNext = () => (
    <div className="flex justify-center my-6">
      <button
        onClick={nextPage}
        className="focus:outline-none py-2 px-3 border border-gray4 text-gray4"
      >
        Show more
      </button>
    </div>
  );

  return (
    <div className="w-full">
      <div className="border border-primary mb-4 p-5 w-full">
        {showTabs && <GenericTab tabs={["ERC20", "TRC20"]} index={typeIndex} onSelect={setType} />}
        <div
          className="flex justify-center items-center"
          style={{ height: key === ""||loading ? "400px" : "0px" }}
        >
          {loading && (
            <div className="flex flex-col items-center">
              <Loader type="Bars" color="#FFFF00" height={30} width={30} />
            </div>
          )}
          {errorMessage && errorMessage != "" && (
            <p className="text-xs md:text-sm lg:text-lg xl:text-lg text-red italics text-center border-dashed border-2 border-primary p-2">
              {errorMessage}
            </p>
          )}
        </div>
        {!loading && key != "" && (
          <div className="w-4/5 sm:w-4/6 mx-auto mt-10">
            {/*<img src={Images.qrCodeIcon} className="h-2/6 w-2/6 mx-auto" />*/}
            <div
              className="h-2/6 mx-auto p-2 bg-white"
              style={{ width: "270px" }}
            >
              <QRCode value={key} />
            </div>
            <p className={styles.textStyle}>
              Use the address below to deposit{" "}
              {activeAsset.fullName}s in your
              wallet.
            </p>
            <p className="text-xxxs md:text-sm lg:text-lg xl:text-lg text-secondaryGray py-2 border-dashed border-2 border-primary text-center ">
              {key}
            </p>
            {/*<div className="w-1/6 mx-auto">
            <div className="flex items-center self-center mt-2">
              <img src={checkedIcon} className="h-3 w-4 mr-2" />
              <p className={styles.textStyle2}>Copied</p>
            </div>
          </div>*/}
            <div className="flex justify-center mt-4">
              <button
                onClick={CopyToClip}
                className="flex text-white rounded mr-3 border border-secondaryGray text-xs py-2 px-4 items-center"
              >
                <img
                  src={copyIcon}
                  className="h-2 w-2 mr-2 md:h-4 md:w-4 md:mr-2 lg:h-4 lg:w-4 lg:mr-2  xl:h-4 xl:w-4 xl:mr-2"
                />
                <p className={styles.textStyle3}>Copy Address</p>
              </button>
              {/*<button className="flex text-white rounded border border-secondaryGray text-xs py-2 px-4 items-center">
                <img
                  src={downloadIcon}
                  className="h-2 w-2 mr-2 md:h-4 md:w-4 md:mr-2 lg:h-4 lg:w-4 lg:mr-2  xl:h-4 xl:w-4 xl:mr-2"
                />
                <p className={styles.textStyle3}>Download QR</p>
              </button>*/}
            </div>
            <p className="text-xxxs md:text-sm lg:text-md text-secondaryGray mt-6">Send only {showWarning ? chainTypes[typeIndex]:""} {activeAsset.short} to this deposit address</p>
            <p className="text-xxxs md:text-sm lg:text-md text-secondaryGray">Sending coin or token other than {showWarning ? chainTypes[typeIndex]:""} {activeAsset.short} to this address may result in loss of your funds.</p>
            {isErc20 && <p className="text-xxxs md:text-sm lg:text-md text-secondaryGray">{feeFixed} {activeAsset.short} + {feePercent}% of the deposited amount will charged as fee.</p>}
            {Boolean(depositMin) && <p className="text-xxxs md:text-sm lg:text-md text-secondaryGray">Deposit amount should not be smaller than {depositMin}{activeAsset.short} otherwise you will lose your funds.</p>}
          </div>
        )}
      </div>
      {key !== "" && (
        <div className="border border-primary p-4 w-full">
          <h2 className="text-gray-600 text-xl font-bold mb-4">
            Recent Deposit
          </h2>
          <div>
            {transactions.map((item, index) => (
              <TransactionsDataRow
                key={index}
                status={item.status}
                kind={capitalize(item.kind)}
                currency={activeAsset.short}
                amount={item.finalAmount ?? 0.0}
                cryptocurrency={""}
                date={moment(item.started_at).format("LL")}
                time={moment(item.started_at).format("LTS")}
                stTrId={item.stellar_transaction_id}
                onCopy={() => onCopy(item.stellar_transaction_id)}
              />
            ))}
          </div>
          {!loading && transactions.length < 1 && (
            <div className="flex justify-center text-white p-16">
              <h3>No History found.</h3>
            </div>
          )}
          {loadingNext && (
            <div className="flex justify-center p-16">
              <Loader type="Bars" color="#FFFF00" height={30} width={30} />
            </div>
          )}
          {!loadingNext && transactions.length > 0 && renderNext()}
        </div>
      )}
    </div>
  );
}

export default Deposite;

function Chip({ data, coinName, onCopy }) {

  return (
    <li>
      <div className="flex justify-between py-2 w-full border-b border-gray-700">
        <div>
          <h3 className="text-gray-400 text-md font-semibold">
            {coinName} Deposit
          </h3>
          <p className="text-gray-700 text-xs">
            {moment(data.started_at).format("LL")},{" "}
            {moment(data.started_at).format("LTS")}
          </p>
        </div>
        <div className="flex items-center">
          {/*<p className="text-gray-600 mr-16">0 USD</p>*/}
          <p className="text-accent mr-16">
            {data.finalAmount ?? 0} {coinName}
          </p>
          <div className="text-gray-500 flex items-center">
            {data.stellar_transaction_id && (
              <button
                onClick={() => onCopy(data.stellar_transaction_id)}
                className="mr-3 ml-8 focus:outline-none"
              >
                <FontAwesomeIcon icon={faCopy} color="#ffffff" />
              </button>
            )}
            {data.stellar_transaction_id && (
              <a
                href={`https://stellar.expert/explorer/testnet/tx/${data.stellar_transaction_id}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} color="#ffffff" />
              </a>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

const styles = {
  textStyle:
    "mt-4 mb-2 text-accent text-center text-xxxs md:text-sm lg:text-lg xl:text-lg",
  textStyle2:
    "text-lightGreen text-center text-xxxs md:text-sm lg:text-sm xl:text-sm",
  textStyle3:
    "text-white text-center text-xxxs md:text-sm lg:text-sm xl:text-sm",
};
