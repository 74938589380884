import { getJWT } from "services/httpServices";
import axios from "axios";
import { BASE_URL } from "./AppConstants";
import {
  getSelectedSecret,
  sendNonNativeBalance,
} from "services/trannsactionServices";
import { store } from "App";
import { RootState } from "store/reducers";

class ZakatManager {
  static async checkZakat() {
    try {
      const state: RootState = store.getState();
      const assets = state.assets.assets;
      let token: any = await getJWT();

      let res = await axios.get(
        `${BASE_URL}/zakat-history/getHistoryByAccountId?take=100&skip=0`,
        {
          headers: {
            Authorization: `Bearer ${token.data.token}`,
          },
        }
      );
      
      if (res.data?.data && res.data.data.length > 0) {
        const { data } = res.data;
        const secret = getSelectedSecret()
        data.forEach((element) => {
          if (element.status === "unpaid") {
            const asset = assets.find(
              (asset) => asset.short.toUpperCase() === element.coinCode
            );
            
            if (asset) {
              sendNonNativeBalance(
                secret,
                element.distAccount,
                element.zakatAmount,
                element.memo,
                element.coinCode,
                asset.issuer
              ).catch(console.log);
            }
          }
        });
      }
    } catch (error) {
      // console.log(error);
    }
  }
}

export default ZakatManager;
/*
account: "GBBJNXP6X7QCXD3E6SSORHSOJHZWWO5PDIS2I2WZFGGTRCQH6EU3IRLK"
coinCode: "BTC"
createdAt: "2020-10-06T15:17:18.000Z"
distAccount: "GAGQBNSK3ON24SXULHWJI4NRYMZK5RYMZITQSRJ6ZOSOFS2AKUWD4OL6"
id: 1
memo: "LMxebQb3tj2wSudxfX5ULaLTSp2J"
status: "unpaid"
stellarTxId: null
totalAmount: 25.1990532
updatedAt: "2020-10-06T15:17:18.000Z"
year: 2020
zakatAmount: 0.6299763
zakatPercentage: 2.5
 */
