import P2pBasicUserInfo from "components/organisms/P2pBasicUserInfo";
import P2pProfileAds from "components/organisms/P2pProfileAds";
import P2pProfileReview from "components/organisms/P2pProfileReview";
import React from "react";
import { useLocation } from "react-router-dom";

function P2pUserProfile() {
  const location = useLocation()
  const {account}:any = location.state;
  return (
    <div className="sm:flex py-6 px-10">
      <P2pBasicUserInfo hasImage={true} account={account} />

      <P2pProfileReview me={false} />

      <P2pProfileAds account={account} />
    </div>
  );
}

export default P2pUserProfile;
