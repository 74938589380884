import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { viewNotificationsAction } from "store/actions/GenericActions";
import { RootState } from "store/reducers";

function NotificationDrop() {
  const {
    generic: { notifications },
    p2p: { activeTrades },
  } = useSelector((state: RootState) => state);
  const [show, setShow] = useState(false);
  const history = useHistory();

  useEffect(() => {
    registerEvent();
  }, []);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        viewNotificationsAction();
      }, 2000);
    }
  }, [show]);

  const registerEvent = () => {
    window.onclick = function (event) {
      let btn = document.getElementsByClassName("notificationBtn")[0];
      let cntnt = document.getElementsByClassName("drop-notifications")[0];
      if (!btn?.contains(event.target) && !cntnt?.contains(event.target)) {
        setShow(false);
      }
    };
  };
  const renderNewNotif = () => {
    let response = false;
    notifications.forEach((notification) => {
      if (notification.new) {
        response = true;
      }
    });
    return response;
  };
  const fresh = renderNewNotif();
  const handleOpen = () => setShow(!show);
  const handleClick = (url, objId) => {
    if (url && url === "/p2p") {
      const trade = activeTrades.find((item) => item.id === objId);
      setShow(false);
      if (trade) {
        history.push({
          pathname: "/transactionProgressScreen",
          state: { trade: trade },
        });
      } else {
        history.push("/p2p");
      }
    }
  };
  return (
    <div className="relative ml-6">
      <button
        onClick={handleOpen}
        className={`focus:outline-none notificationBtn ${
          !fresh && "mt-1 ml-1"
        }`}
      >
        <img src={`assets/${fresh ? "notifyActive" : "notifyInactive"}.svg`} />
      </button>
      <div
        className="absolute bg-gray3 drop-notifications rounded py-2 px-3 text-xs"
        style={{ left: "-225px", display: show ? "block" : "none" }}
      >
        <h4 className="text-secondaryGray font-bold">Notifications</h4>
        <div
          className="overflow-y-auto nobar"
          style={{ width: "250px", height: "350px" }}
        >
          {notifications.map((item, index) => (
            <div
              key={index}
              onClick={() => handleClick(item.path, item.objectId)}
              className={`py-2 border-b border-primary cursor-pointer ${
                item.new ? "text-white" : "text-gray4"
              }`}
            >
              <div className="mb-1 flex justify-between">
                <h5>{item.title}</h5>
                <h5>{moment(item.date).fromNow()}</h5>
              </div>
              <p>{item.notification}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NotificationDrop;
