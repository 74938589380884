import React, { useState, useEffect } from "react";
import {
  getAllOrders,
  getAllTrades,
  lookForOrders,
  lookForTrades,
} from "services/exchange";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import {
  resetSelectedPair,
  selectOffer,
  setExchangePrices,
} from "store/actions/ExchangeActions";
import BigNumber from "bignumber.js";

function useOrderBook() {
  let { selectedPair, refresh } = useSelector(
    (state: RootState) => state.exchange
  );
  const dispatch = useDispatch();
  let init: any = [];
  let [state, setState] = useState({
    ordersLoading: true,
    buyOrders: [],
    sellOrders: [],
    trades: init,
    tradesLoading: false,
    avgVal: 0,
    prevAvgVal: 0,
    avgInUsd: 0,
    tab: 0,
    floatingPoints: 7,
    maxDepth: 0,
  });

  const fetchAllOrders = () => {
    const asset1 = {
      code: selectedPair.marketCoinCode.toUpperCase(),
      issuer: selectedPair.marketCoinIssuer,
    };
    const asset2 = {
      code: selectedPair.toCoinCode.toUpperCase(),
      issuer: selectedPair.toCoinIssuer,
    };
    setState((prev) => ({
      ...prev,
      ordersLoading: true,
      buyOrders: [],
      sellOrders: [],
    }));
    getAllOrders(asset1, asset2)
      .then((response: any) => {
        const maxDepth: any = getMaxDepth(response);
        let bids: any = populateSell(response.bids, 9, maxDepth);
        let asks: any = populateBuy(response.asks, 9, maxDepth);
        const asksFilled = fillData(asks);

        let average: any = calculateAvg(asks, bids);
        if (response.bids.length === 0 || response.asks.length === 0) {
          average = selectedPair.lastPrice;
        } else {
          let maxBuyPrice = getMaxPrice(bids);
          let minSellPrice = getMinPrice(asks);
          dispatch(
            setExchangePrices(
              maxBuyPrice,
              minSellPrice,
              response.bids.length,
              response.asks.length
            )
          );
        }
        //let overAll: any = populateOverall(response.asks, response.bids);

        setState((prev) => ({
          ...prev,
          ordersLoading: false,
          buyOrders: bids,
          sellOrders: asksFilled,
          avgVal: average,
          prevAvgVal: prev.avgVal,
        }));
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, ordersLoading: false }));
      });
  };
  const getCoinTrades = async () => {
    let asset1 = {
      code: selectedPair.marketCoinCode.toUpperCase(),
      issuer: selectedPair.marketCoinIssuer,
    };
    let asset2 = {
      code: selectedPair.toCoinCode.toUpperCase(),
      issuer: selectedPair.toCoinIssuer,
    };
    try {
      setState((prev) => ({ ...prev, tradesLoading: true }));
      const allTradesResp = await getAllTrades(asset1, asset2, 20);
      let result: any = [];

      for (let i = 0; i < 23; i++) {
        const element = allTradesResp.records[i];
        if (element) {
          let pr: any = (1 / (element.price.n / element.price.d)).toFixed(8);
          let object = {
            amount: element.counter_amount,
            price: pr,
            total: (parseFloat(element.counter_amount) * pr).toFixed(8),
            buy: element.base_is_seller ? false : true,
          };
          result.push(object);
        } else {
          result.push({
            amount: "-",
            price: "-",
            total: "-",
            buy: true,
          });
        }
      }
      let sortedTrades = result.sort((a, b) => {
        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
      });
      setState((prev) => ({
        ...prev,
        trades: sortedTrades,
        tradesLoading: false,
      }));
    } catch (e) {
      setState((prev) => ({ ...prev, tradesLoading: false }));
    }
  };
  const listenTrades = () => {
    let asset1 = {
      code: selectedPair.marketCoinCode.toUpperCase(),
      issuer: selectedPair.marketCoinIssuer,
    };
    let asset2 = {
      code: selectedPair.toCoinCode.toUpperCase(),
      issuer: selectedPair.toCoinIssuer,
    };

    setState((prev) => ({ ...prev, allTrades: [] }));
    lookForTrades(asset1, asset2, (element) => {
      let pr: any = (1 / (element.price.n / element.price.d)).toFixed(8);
      let object = {
        amount: element.counter_amount,
        price: pr,
        total: (parseFloat(element.counter_amount) * pr).toFixed(8),
        buy: element.base_is_seller ? false : true,
      };
      setState((prev) => {
        const newArray = [...prev.trades].splice(-1, 1);
        return { ...prev, trades: [object, ...newArray] };
      });
    });
  };
  const fillData = (data) => {
    let temp: any = [];
    if (data.length < 9) {
      const length = 9 - data.length;
      for (let i = 0; i < length; i++) {
        temp.push({
          amount: "-",
          price: "-",
          total: "-",
          depth: 0,
        });
      }
      return [...temp, ...data];
    }
    return data;
  };

  const getMaxDepth = (data) => {
    const { asks, bids } = data;

    const cappedDepthAsks = asks.reduce((acc, ask) => {
      if (Number(ask.price) / Number(asks[0].price) < 1.2) {
        return Number(acc) + Number(ask.amount) * Number(ask.price);
      }
      return acc;
    }, 0);

    const cappedDepthBids = bids.reduce((acc, bid) => {
      if (Number(bids[0].price) / Number(bid.price) < 1.2) {
        return Number(acc) + Number(bid.amount);
      }
      return acc;
    }, 0);

    return BigNumber.max(
      cappedDepthAsks.toFixed(7),
      cappedDepthBids.toFixed(7)
    );
  };

  const calculateAvg = (sellPrices, buyPrices) => {
    let maxBuyPrice = getMaxPrice(buyPrices);
    let minSellPrice = getMinPrice(sellPrices);

    let average = (maxBuyPrice + minSellPrice) / 2;
    return average.toFixed(7);
  };

  const getMaxPrice = (data: any) => {
    let maxVal = 0;
    data.forEach((item) => {
      if (Number(item.price) > maxVal) {
        maxVal = item.price;
      }
    });
    return maxVal;
  };
  const getMinPrice = (data: any) => {
    let minVal = Infinity;
    data.forEach((item) => {
      if (Number(item.price) < minVal) {
        minVal = item.price;
      }
    });
    return minVal === Infinity ? 0 : minVal;
  };

  const populateSell = (data, length, maxDepth) => {
    let temp: any = [];
    let depth = 0;
    for (let i = 0; i < length; i++) {
      const element = data[i];
      if (element) {
        depth += Number(element.amount);
        const depthPercentage = Math.min(
          100,
          Number(Number((depth / maxDepth) * 100).toFixed(1))
        );
        let pr: any = Number(element.price);
        let infinity = isFinite(Number(element.amount) / pr);

        temp.push({
          ...element,
          price: pr,
          total: Number(element.amount),
          amount: !infinity ? 0 : Number(element.amount) / pr,
          depth: depthPercentage,
        });
      } else {
        temp.push({
          amount: "-",
          price: "-",
          total: "-",
          depth: 0,
        });
      }
    }
    return temp;
  };
  const populateBuy = (data, length, maxDepth) => {
    let temp: any = [];
    let depth = 0;

    let sortedData = data.sort((a, b) => Number(b.price) - Number(a.price));
    for (let i = 0; i < length; i++) {
      const element = sortedData[i];
      if (element) {
        let pr: any = Number(element.price);
        depth += Number(element.amount) * Number(element.price);
        const depthPercentage = Math.min(
          100,
          Number(Number((depth / maxDepth) * 100).toFixed(1))
        );
        temp.push({
          ...element,
          price: pr,
          total: Number(element.amount) * pr,
          depth: depthPercentage,
        });
      }
    }

    return temp;
  };

  const listenOrders = () => {
    const asset1 = {
      code: selectedPair.marketCoinCode.toUpperCase(),
      issuer: selectedPair.marketCoinIssuer,
    };
    const asset2 = {
      code: selectedPair.toCoinCode.toUpperCase(),
      issuer: selectedPair.toCoinIssuer,
    };

    lookForOrders(asset1, asset2, (trade) => {
      setState((prev) => {
        let bids: any = populateSell(trade.bids, 9, 10);
        let asks: any = populateBuy(trade.asks, 9, 10);
        const asksFilled = fillData(asks);
        let average: any = calculateAvg(asks, bids);
        //let overAll: any = populateOverall(trade.asks, trade.bids);
        if (trade.bids.length === 0 || trade.asks.length === 0) {
          average = selectedPair.lastPrice;
        } else {
          let maxBuyPrice = getMaxPrice(bids);
          let minSellPrice = getMinPrice(asks);
          dispatch(
            setExchangePrices(
              maxBuyPrice,
              minSellPrice,
              trade.bids.length,
              trade.asks.length
            )
          );
        }
        return {
          ...prev,
          ordersLoading: false,
          sellOrders: asksFilled,
          buyOrders: bids,
          avgVal: average,
          prevAvgVal: prev.avgVal,
        };
      });
    });
  };

  const purifyData = (data) => {
    let temp: any = [];
    data.forEach((item) => {
      if (item.price !== "-") {
        temp.push(item);
      }
    });
    return temp;
  };

  const setFractions = (record) => {
    let fps = record + 5;

    setState((prev) => ({
      ...prev,
      floatingPoints: fps,
    }));
  };

  const populateOverall = (asksP, bidsP) => {
    let asks: any = [];
    let bids: any = [];
    asksP.forEach((element) => {
      let pr: any = Number(element.price);
      asks.push({
        ...element,
        price: pr,
        total: Number(element.amount) * pr,
      });
    });
    bidsP.forEach((element) => {
      let pr: any = Number(element.price);
      bids.push({
        ...element,
        price: pr,
        total: Number(element.amount),
        amount: Number(element.amount) / Number(pr),
        buy: true,
      });
    });

    let temp: any = [...asks, ...bids];
    for (let i = 0; i < 24; i++) {
      const element = temp[i];
      if (!element) {
        temp.push({
          amount: "-",
          price: "-",
          total: "-",
        });
      }
    }
    return temp;
  };
  const handleSelect = (item) => {
    if (item.price !== "-") {
      dispatch(selectOffer(item));
    }
  };

  useEffect(() => {
    if (selectedPair.marketCoinCode === "" || selectedPair.toCoinCode === "") {
      return;
    }

    fetchAllOrders();
    listenOrders();
    getCoinTrades();
    listenTrades();
  }, [refresh]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedPair());
    };
  }, []);

  const setTab = (tab) => {
    setState((prev) => ({ ...prev, tab }));
  };

  return { ...state, selectedPair, setTab, setFractions, handleSelect };
}

export default useOrderBook;
