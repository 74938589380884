import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { sendIconWhite, sendIconGray, receiveIconGray, receiveIconWhite } from "assets/images";
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

const ListItem = (props: any) => {
  return(
    <div>
      <li onClick={props.onClickSend || props.onClick} className={`${styles.linkStyle} ${props.isActive && "bg-accent"}`}>
        <a className={`${styles.textStyle} ${props.isActive ? "text-white" : "text-secondaryGray"}`}>
          {props.title}
          {
            props.inActiveIcon
              &&
                <img className="ml-2 w-1 h-2 md:w-2 md:h-3 lg:w-3 lg:h-4 xl:w-3 xl:h-4" src={props.isActive ? props.activeIcon : props.inActiveIcon} />
          }
        </a>
      </li>
    </div>
  )
}

export const SendScreenMenu = (props:any) =>{
  let assets = useSelector((state:RootState)=>state.assets)
  const location = useLocation()
  const {state}:any = location
  let isNotXlm = props.coin.short !== "XLM"
  let isAnchored = React.useMemo(() => {
    let asset = assets.assets.filter(item=>item.short == props.coin.short.toLowerCase() && item.issuer == props.coin.issuer)
    let isAnchored = asset[0] ? asset[0].isGWalletAnchor : 0;
    return isAnchored == 1
  }, [assets])
  
    return(
      <div className="w-full border border-primary md:w-2/6 lg:w-2/6 xl:w-2/6 mr-4 mb-2">
      <ul className="flex md:block lg:block xl:h-screen xl:block w-full">
        <ListItem
          onClick={()=>props.onClick(0)}
          title="About"
          isActive={props.screen === 0 ? true : false}
        />

        <ListItem
          title="Send"
          onClickSend={()=>{if(!state.new)props.onClickSend(1)}}
          activeIcon={sendIconWhite}
          inActiveIcon={sendIconGray}
          isActive={props.screen === 1 ? true : false}
        />

        <ListItem
          onClickSend={()=>{if(!state.new)props.onClickSend(2)}}
          title="Receive"
          activeIcon={receiveIconWhite}
          inActiveIcon={receiveIconGray}
          isActive={props.screen === 2 ? true : false}
        />

        <ListItem
          onClickSend={()=>{if(!state.new)props.onClickSend(3)}}
          title="Transactions"
          isActive={props.screen === 3 ? true : false}
        />

        {isNotXlm && isAnchored && <ListItem
          onClickSend={()=>{if(!state.new)props.onClickSend(4)}}
          title="Deposit"
          isActive={props.screen === 4 ? true : false}
        />}

        {isNotXlm && isAnchored && <ListItem
          onClickSend={()=>{if(!state.new)props.onClickSend(5)}}
          title="Withdraw"
          isActive={props.screen === 5 ? true : false}
        />}

      </ul>
    </div>
    );
}
const styles = {
    textStyle: "flex items-center text-center ml-4 text-xxxs md:text-sm lg:text-lg xl:text-lg",
    linkStyle: "cursor-pointer p-2 md:p-5 lg:p-5 xl:p-5"
  };