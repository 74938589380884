import React, { Fragment } from "react";
import Modal from "react-modal";
import { closeButton } from "assets/images";
import { logo } from "assets/images/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

function Multisig({isOpen, onClose}) {
  return (
    <Modal isOpen={isOpen} style={customStyle}>
      <button onClick={onClose} className="float-right">
        <img src={closeButton} className="w-6 h-6" />
      </button>
      <div className="flex flex-col items-center mt-6 px-8 pb-8">
        <div className="flex items-center">
          <img className="w-6 h-8" src={logo} />
          <text className="text-white font-bold ml-2 md:text-md lg:text-md xl:text-md  ">
            G WALLET
          </text>
        </div>
        <button className="underline text-white mt-4">Multisig</button>
        <div style={{ backgroundColor: "rgba(255,159,0,0.15)" }} className="p-2 rounded text-gray-400 text-sm mt-4">
          <p>
            Multisig is a great way to secure your wallet. <br/>If set up properly,
            multisig can protect your wallet from theft, loss and even from
            death. <br />The way it works, you will need multiple signatures before
            any transaction you initiate is validated. Only use wallet addresses
            (public keys) with secret keys that belong to you or decision makers
            within your organization. <br />For individuals, we recommend setting up a
            2 of 3 multisig wallet account. You can store one key on your phone,
            another key on your tablet, and a last key on your PC. <br />For
            organizations, we recommend setting up a multisig wallet account
            with the public keys of all decision makers, and require half of the
            decision makers to sign before validating a transaction.<br />
  {/*Do you need more help?*/}<FontAwesomeIcon icon={faCopy} className="ml-2" />
          </p>
        </div>
        <div className="flex w-full mt-6">
            <div className="p-2 border w-full border-gray-700 mr-6">
                <h2 className="text-white text-lg mb-3">Signatories</h2>
                <div className="rounded bg-background">
                    <div className="flex justify-between items-center border-b border-gray-600 p-3"><h3 className="text-white">Signer</h3><div className="flex items-center"><p className="text-gray-600 mr-2">dshtsttttsestsyff</p><FontAwesomeIcon icon={faCopy} color="#D8D8D8D8" /></div></div>
                    <div className="flex justify-between text-white p-3"><h3>Weight</h3><h3>0</h3></div>
                </div>
                <div className="flex justify-between border-b border-gray-600 text-white p-2 mt-2"><h3>Threshold</h3><h3>Weight</h3></div>
                <div className="flex justify-between text-sm text-gray-600 p-2"><h3>Low</h3><h3>0</h3></div>
                <div className="flex justify-between text-sm text-gray-600 p-2"><h3>Medium</h3><h3>0</h3></div>
                <div className="flex justify-between text-sm text-gray-600 p-2"><h3>Hight</h3><h3>0</h3></div>
            </div>
            <div className="p-2 border w-full border-gray-700">
                <h2 className="text-white text-lg mb-3">Set Up M of N Signature</h2>
                <div className="rounded bg-background p-3 text-gray-600 mb-3 text-sm">
                    <p>Number of Required Signatures</p>
                </div>
                <div className="rounded bg-background p-3 text-gray-600 text-sm">
                    <p>Number of Available Signatures</p>
                </div>
            </div>
        </div>
      </div>
    </Modal>
  );
}

export default Multisig;


const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
  },
  content: {
    top: "10%",
    left: "20%",
    right: "auto",
    bottom: "auto",
    width: "60%",
    margin: "0 auto",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};

