import React, { useEffect, useState } from "react";
import "./styles/App.css";
import { MainAppNav } from "navigation/MainAppNav";
import { createStore, applyMiddleware } from 'redux'
import { rootReducer } from './store/reducers/index'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from "components/atomic/AlertTemplate";
import { getData, saveData } from "services/storageServices";
import Splash from "screens/Splash";
import MarketStream from "utils/MarketStream";
import { initNotificationsAction } from "store/actions/GenericActions";
import ReactGA from 'react-ga';


export const store = createStore(rootReducer, applyMiddleware(thunk));
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '10px',
  transition: transitions.SCALE
}

const App = () => {
  let [splash, setSplash] = useState(true);
  const initializeMarket = () => {
    if(!MarketStream.isListening()){
      MarketStream.startListening()
    }
  };

  useEffect(() => {
    let id = getData('repeaterId') || "";
    if (id !== "") {
      let newId = setInterval(() => {  }, 1000 * 1800);
      saveData({ repeaterId: newId })
    }
    initializeMarket()
    initNotificationsAction()
    const TRACKING_ID = "UA-203351781-1";
    ReactGA.initialize(TRACKING_ID);
  }, [])

  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        {splash ? <Splash onClose={() => setSplash(false)} /> : <MainAppNav />}
      </AlertProvider>
    </Provider>
  );
};

export default App;
