import * as React from "react";
import * as Images from 'assets/images/index';

export const NoteRow = (props: any) => {
  return (
    <div className="flex items-center px-5">
      <img src={Images.noteArrowIcon} className="ml-1 h-2 w-3" />
      <p className="font-regular ml-5 text-xxxs md:text-sm lg:text-sm xl:text-sm text-secondaryGray">
        {props.note}
      </p>
    </div>
  );
};
