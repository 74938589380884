import * as React from "react";
import { pulse } from "react-animations";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";

const BouncyDiv = styled.div`
  animation: 2s ${keyframes`${pulse}`} infinite;
`;

export const FloatingWidget = (props: any) => {
  const history = useHistory();
  const navigateToCheckOut = () => {
    history.push({ pathname: `/checkout/${props.memo}` });
  };

  return (
    <div
      className="z-10"
      style={{ position: "absolute", right: "15px", top: "100px" }}
      onClick={() => navigateToCheckOut()}
    >
      <BouncyDiv>
        <div className="flex cursor-pointer px-3 py-1 rounded-lg text-xs font-medium text-black bg-yellow-600 shadow-lg border-2">
          You still have un-paid transaction{" "}
          <p className="text-red font-bold ml-2">Click here!</p>
        </div>
      </BouncyDiv>
    </div>
  );
};
