import * as React from 'react';
import * as Images from 'assets/images/index';
import StarRatingComponent from 'react-star-rating-component';
export const SellerDetails = (props:any) =>{
    return(
        <div className="">
        <img src={props.image} className="h-20 w-20 m-auto rounded-lg"/>
        <p className="text-white font-regular text-center mt-2">{props.sellername}</p>
        <div className="w-20 mx-auto">
          <StarRatingComponent name="" value={props.rating}/>
        </div>
      </div>
    );
}