import * as React from "react";
import { paymentTypeIconsEnum } from "utils/constants";


export const AdsCol = (props: any) => {
  return (
      <div className="ml-2 mr-2 flex flex-col items-start">
        <div className="mb-2 bg-pink px-2 py-1 rounded">
          <img src={`/assets/${paymentTypeIconsEnum[props.item.payment_method]}.svg`} />
        </div>
          <AddTitle item={props.item} usdRate={props.usdRate} />
        
        <p className="flex text-secondaryGray text-xxs md:text-xs lg:text-xs xl:text-xs font-regular">
          <p className="text-red text-xxs md:text-xs lg:text-xs xl:text-xs font-regular mr-1">Maximum:</p>{Number(props.maxamount).toFixed(7)}{props.assetCode?.toUpperCase()}
        </p>
      </div>
  );
};


const AddTitle = ({item, usdRate}) => {
  const [displayType, setType] = React.useState(0)
  //const getFirstRate = item.type==="sell"? Number(item.sellerUSDRate)+((item.priceSpread/100)*Number(item.sellerUSDRate)):Number(item.sellerUSDRate)-((item.priceSpread/100)*Number(item.sellerUSDRate))
  const getFirstRate = item.type==="sell"? Number(item.buyerUSDRate):Number(item.sellerUSDRate)
  const getFirstType = () => " "+ item.currency +"/"+(item?.assetCode==="usdt"?"":item?.assetCode)+"USD"
  const getSecondType = () => " "+ item.currency +" / "+item?.assetCode?.toUpperCase()
  const getSecondRate = () => (getFirstRate*usdRate)?.toFixed(4)
  const toggleType = (e)=>{
    e.stopPropagation()
    setType(displayType===0?1:0)
  }
  return(
    <button onClick={toggleType} className="text-white focus:outline-none text-xs md:text-sm lg:text-sm xl:text-sm font-regular">
      {displayType===0 && getFirstRate.toFixed(5)+getFirstType()}
      {displayType===1 && getSecondRate()+getSecondType()}
    </button>
  )
}