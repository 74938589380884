import * as React from "react";
import CustomInput from "components/global/CustomInput";
import Loader from "react-loader-spinner";
import { TransactionInfoBlock } from "./TransactionInfoBlock";
import { NoteRow } from "./NoteRow";
import { Button } from "components/global";
import { colors } from "styles/theme";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "store/reducers";
import { useAlert } from "react-alert";
import { convertAsset } from "utils/constants";

export const SwapForm = ({
  amount,
  amountVal,
  finding,
  setInput,
  fee,
  path,
  submit,
  paying,
  createPath,
  sellingCoin,
  getAssetCode,
  errorMessage,
  handleCancel,
  sendTransaction,
  swapFrom,
  swapTo,
}) => {
  let { transaction } = useSelector((state: RootState) => state);
  let [from, setFrom]: any = React.useState();
  let [to, setTo]: any = React.useState();
  const alert = useAlert();
  let history = useHistory();

  const renderFirstStep = () => (
    <div className="w-full py-2">
      <p className="text-accent text-sm mb-2">{errorMessage}</p>
      <button
        onClick={() =>
          swapFrom?.short?.toLowerCase() == swapTo?.short?.toLowerCase() &&
          swapFrom?.issuer == swapTo?.issuer
            ? alert.error("Cannot select same assets")
            : to == true || from == true
            ? Alert()
            : submit()
        }
        className="focus:outline-none py-2 px-6 bg-accent rounded text-white"
      >
        Track Path
      </button>
    </div>
  );
  const renderLoading = () => (
    <div className="w-full p-5 flex justify-center items-center">
      <Loader type="Bars" color="#FFFF00" height={30} width={30} />
    </div>
  );

  const Alert = () => {
    if (from && to) {
      alert.error("Please Trust Both Assets");
    } else if (from) {
      alert.error("Please Trust This Asset First");
      history.push({
        pathname: "/walletDetailScreen",
        state: {
          screen: 0,
          coin: swapFrom.code,
          new: true,
          asset: convertAsset(swapFrom),
        },
      });
    } else if (to) {
      alert.error("Please Trust This Asset First");
      history.push({
        pathname: "/walletDetailScreen",
        state: {
          screen: 0,
          coin: swapTo.code,
          new: true,
          asset: convertAsset(swapTo),
        },
      });
    }
  };

  React.useEffect(() => {
    if (transaction.coins) {
      let from = transaction.coins.find(
        (val) =>
          val.short.toLowerCase() == swapFrom.short.toLowerCase() &&
          val.issuer == swapFrom.issuer
      );
      let to = transaction.coins.find(
        (val) =>
          val.short.toLowerCase() == swapTo.short.toLowerCase() &&
          val.issuer == swapTo.issuer
      );

      if (!from) {
        setFrom(true);
      } else {
        setFrom(false);
      }
      if (!to) {
        setTo(true);
      } else {
        setTo(false);
      }
    }
  }, [swapTo, swapFrom]);

  return (
    <div>
      <CustomInput
        inputStyle={"mt-5 font-regular"}
        labelColor={colors.secondaryGray}
        marginVertical={10}
        type="number"
        label={`Amount to Send ${sellingCoin}`}
        placeholder="Please enter amount"
        value={amount}
        name="amount"
        onChange={setInput}
        valMsg={amountVal}
      />
      {!path && !finding && renderFirstStep()}
      {!path && finding && renderLoading()}
      {path && (
        <div className="border border-primary">
          <div className="bg-primary pl-5 py-3">
            <p className="text-sm font-regular text-smokyWhite">
              GTracker Search Results
            </p>
          </div>

          <div className="flex justify-between border-b py-2 px-5 border-primary">
            <TransactionInfoBlock
              amount={
                path?.source_amount +
                " " +
                getAssetCode(path.source_asset_type, path.source_asset_code)
              }
              transType="Send"
            />
            <TransactionInfoBlock
              amount={
                path?.destination_amount +
                " " +
                getAssetCode(
                  path.destination_asset_type,
                  path.destination_asset_code
                )
              }
              transType="Receive"
            />
          </div>
          <div className="mt-4 px-5">
            <TransactionInfoBlock
              bestPath={createPath()}
              transType="Best Path"
            />
          </div>
          <div className="flex">
            <p className="text-xs md:text-sm lg:text-sm xl:text-sm text-white px-5 mt-4 font-regular">
              Fee:
            </p>
            <p className="text-secondaryGray text-sm mt-4">
              {fee + " " + sellingCoin}
            </p>
          </div>
          <div className="flex">
            <p className="text-xs md:text-sm lg:text-sm xl:text-sm text-white px-5 mt-1 font-regular">
              Total:
            </p>
            <p className="text-secondaryGray text-sm mt-1">
              {Number(amount) + fee + " " + sellingCoin}
            </p>
          </div>
          <p className="text-xs md:text-sm lg:text-sm xl:text-sm text-white px-5 mt-4 mb-2 font-regular">
            Notes
          </p>
          <NoteRow note="Swaps usually take less than 30 seconds." />
          <NoteRow note="The amount you wish to swap will be sent to a dedicated address, where the swap will occur." />
          <NoteRow note="If for any reason there was an error in the swap, your remaining funds will be returned to you in 5 minutes or less." />
          <div className="flex px-5 mb-5">
            <Button
              onClick={sendTransaction}
              styles={[
                "font-regular flex justify-center bg-accent w-full mr-2 text-xs md:text-sm py-2 lg:text-sm xl:text-sm md:py-4 lg:py-4 xl:py-4 mt-8",
              ]}
            >
              {paying ? (
                <Loader type="Bars" color="#ffffff" height={20} width={20} />
              ) : (
                "Swap"
              )}
            </Button>
            <Button
              styles={[
                "font-regular bg-primary w-full ml-2 text-xs md:text-sm py-2 lg:text-sm xl:text-sm md:py-4 lg:py-4 xl:py-4 mt-8",
              ]}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
