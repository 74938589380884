import { GenericState } from "store/models/GenericState";
import {
  GetGenericActionsType,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_PENDING,
  TOGGLE_CONFIRM_MODAL, SET_MY_COUNTRY, SET_ACTIVE_COUNTRY, PUSH_P2P_NOTIFICATION, SET_NOTIFICATIONS, APPEND_NOTIFICATION, SET_SOCKET_STATUS, SET_MEMO, SET_LOADING_TABLE
} from "store/actions/GenericActionsTypes";

const initialState: GenericState = {
  currencies: [],
  loading: true,
  activeCountries: [],
  error: "",
  confirmOpen: false,
  myCountry: { code: "", name: "" },
  notification: "",
  notifications: [],
  connected: false,
  memo: "",
  loadingTable: false
};

export function genericReducer(
  state = initialState,
  action: GetGenericActionsType
): GenericState {
  switch (action.type) {
    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload,
        loading: false,
      };
    case FETCH_CURRENCIES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case SET_SOCKET_STATUS:
      return {
        ...state,
        connected: action.connected
      };
    case TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        confirmOpen: !state.confirmOpen,
      };
    case SET_MY_COUNTRY:
      return {
        ...state,
        myCountry: action.country
      }
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications
      }
    case SET_ACTIVE_COUNTRY:
      return {
        ...state,
        activeCountries: action.countries
      }
    case PUSH_P2P_NOTIFICATION:
      return {
        ...state,
        notification: action.message
      }
    case SET_MEMO:
      return {
        ...state,
        memo: action.memo
      }
    case SET_LOADING_TABLE:
      return {
        ...state,
        loadingTable: action.setLoading
      }
    default:
      return state;
  }
}
