import React, { useEffect, useMemo, useState } from "react";
import { menuDropdownIcon } from "assets/images";
import { coinsFromShort, p2pDisabledCoins } from "utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

function SelectCoin({ short, onSelect, outline }) {
  const coinsAll = useSelector((state: RootState) => state.assets.assets);
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(!show);
  const coins = useMemo(() => {
    let coinData = coinsAll.filter(
      (item) => item.isAssetAnchored != 0 || item.short == "xlm"
    );
    const ANCHOR_COINS = ['BTC', 'LTC', 'DOGE', 'DASH', 'ETH', 'TRX', 'KISHU', 'SHIB', 'SHIBA' , 'USDT', 'BOTH', 'AQUA'];
    coinData = coinData.filter((el) => !ANCHOR_COINS.includes(el.code));
    return coinData
      .filter((item) => !p2pDisabledCoins.includes(item.short.toUpperCase()))
      .map((item) => item.short.toUpperCase());
  }, [coinsAll]);

  useEffect(() => {
    window.onclick = function (event) {
      let btn = document.getElementsByClassName("dropbtn")[0];
      let cntnt = document.getElementsByClassName("drop-content")[0];
      if (!btn?.contains(event.target) && !cntnt?.contains(event.target)) {
        setShow(false);
      }
    };
  }, []);

  const renderRow = (item, index) => (
    <li key={index}>
      <div
        className="flex items-center text-white hover:bg-primary cursor-pointer text-sm border-b border-primary px-2 py-3"
        onClick={() => handleSelect(item)}
      >
        <img src={coinsFromShort[item]?.icon} className="w-6 mr-3" />
        <h2>
          {coinsFromShort[item]?.name} ({item})
        </h2>
      </div>
    </li>
  );

  const handleSelect = (item) => {
    setShow(false);
    onSelect(item);
  };

  return (
    <div className="w-full relative">
      <button
        onClick={handleOpen}
        className={`focus:outline-none flex items-center w-full justify-between p-4 ${
          outline ? "border border-primary" : "bg-primary"
        } dropbtn`}
      >
        <div className="flex items-center">
          <img
            src={coinsFromShort[short]?.icon}
            className="h-5 w-5 md:h-10 md:w-10 lg:w-10 lg:h-10 xl:h-10 xl:w-10"
          />
          <h3 className="text-white font-semibold ml-3">
            {coinsFromShort[short]?.name} ({short})
          </h3>
        </div>

        <div
          className="w-5 h-5 flex justify-center items-center rounded-full"
          style={weakGray}
        >
          <img src={menuDropdownIcon} className="w-2" />
        </div>
      </button>
      {show && (
        <div className="drop-content bg-gray3 w-full z-20 absolute p-5">
          <ul>{coins.map(renderRow)}</ul>
        </div>
      )}
    </div>
  );
}

export default SelectCoin;

let weakGray = { backgroundColor: "rgba(151,151,151,0.2)" };
