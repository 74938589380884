import * as React from "react";
import CustomInput from "components/global/CustomInput";
import { Button } from "components/global";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import Loader from "react-loader-spinner";
import ReactTooltip from 'react-tooltip';
import { paymentMethodsEnum } from "screens/hooks/P2P/useAdd";

export const PurchaseForm = (props: any) => {
  const { coins, active } = useSelector((state:RootState) => state.p2p)
  const { name } = coins[active]
  
  return (
    <div>
      <div className="w-4/6 mx-auto">
        <p className="text-white font-regular text-center">
          You are {props.buy ? "Selling" : "Buying"}
        </p>
        <p className="text-accent font-regular text-center mt-2">
          {props.amount===""?0.00000:props.amount}
          <span className="text-white ml-1">
            {props?.add?.assetCode?.toUpperCase()} {props.buy ? " to" : " from"}{" "}
          </span>
          <button onClick={props.onProfile} className="cursor-pointer underline">{props?.add?.first_name?props?.add?.first_name+" "+props?.add?.last_name:props?.add?.companyName}</button>
        </p>
        <div className="flex mt-4">
          <CustomInput
            label={`Amount (${props.add?.assetCode?.toUpperCase()})`}
            placeholder={`Enter ${props.add?.assetCode?.toUpperCase()} amount`}
            name="amount"
            value={props.amount}
            onChange={props.handleInputChange}
            inputStyle="mr-4"
            valMsg={props.amountVal}
          />
          
          <CustomInput
            label={`Amount (${props.add?.currency})`}
            placeholder={`${props.add?.currency} amount`}
            name="amountInCurrency"
            value={props.amountInCurrency}
            onChange={props.handleInputChange}
            valMsg={props.amountInCurrencyVal}
            disabled={true}
          />
        </div>

        {props.showBankInfo && <div className="flex">
          <CustomInput
            label="Bank name"
            placeholder="Bank name"
            name="bank_name"
            value={props.bank_name}
            onChange={props.handleInputChange}
            inputStyle="mr-4"
            valMsg={props.bank_nameVal}
          />
          <div className="relative w-full">
          <CustomInput
            label="Account holder's name"
            placeholder="Account holder's name"
            name="bank_account_title"
            value={props.bank_account_title}
            onChange={props.handleInputChange}
            disabled={true}
            valMsg={props.bank_account_titleVal}
          />
          <a data-tip="Account holder's name should be the same as your KYC name">
            <img src ="assets/cancelled.svg" className="index-10 absolute" style={{top: '10px', left: '150px'}} />
          </a>
          <ReactTooltip />
          </div>
        </div>}
        {props.showBankInfo && <div className="flex">
          <CustomInput
            label="Bank account number"
            placeholder="Bank account number"
            name="bank_account_number"
            value={props.bank_account_number}
            onChange={props.handleInputChange}
            inputStyle=""
            valMsg={props.bank_account_numberVal}
          />
        </div>}
        {props.showEmail && <div className="flex">
          <CustomInput
            label={`${props.add.payment_method} Email Address`}
            placeholder={`${props.add.payment_method} Email Address`}
            name="email"
            value={props.email}
            onChange={props.handleInputChange}
            inputStyle=""
            valMsg={props.emailVal}
          />
        </div>}

        {props.showBankInfo && <div className="mt-4 flex w-4/3">
        <input
          className="mt-1"
          type="checkbox"
          checked={props.bankConfirmed}
          onClick={props.setBankConfirmed}
        ></input>
        <button
          onClick={props.setBankConfirmed}
          className="text-left focus:outline-none text-secondaryGray text-xxxs md:text-sm lg:text-sm xl:text-sm ml-4"
        >
          I confirm that I have added correct payment information.
        </button>
      </div>}
        {!props.buy&&<div className="flex">
          <h3 className="text-secondaryGray text-xs">You will get:</h3>
        {!props.feeLoading&&<h3 className="text-white text-xs ml-1">{props.fee} {props.add.assetCode?.toUpperCase()}</h3>}
        {props.feeLoading && <div className="ml-1 mt-1"><Loader type="Bars" color="#FFFF00" height={13} width={13} /></div>}
        </div>}
          <Button
            styles={
              props.buy
                ?["text-sm bg-pink py-4 mt-3 w-full font-regular relative"]
                : ["text-sm bg-green py-4 mt-3 w-full font-regular relative"]
            }
            onClick={props.submit}
          >
            {!props.submitting && (props.buy ? "SELL " : "BUY ")+name}
            {props.submitting && <div className="flex justify-center items-center"><Loader type="Bars" color="#ffffff" height={20} width={20} /></div>}
            {!props.bankConfirmed&&props.showBankInfo&&<div className="absolute inset-0 rounded bg-white cursor-not-allowed" style={{opacity: 0.3}}></div>}
          </Button>
      </div>
    </div>
  );
};
