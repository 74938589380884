import * as React from "react";
import * as Images from "assets/images/index";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";
import QRCode from "react-qr-code";
import { getSelectedKeys } from "services/trannsactionServices";

export const ReceiveScreen = () => {
  let alert = useAlert();
  let [key, setKey] = React.useState(getSelectedKeys().public);

  let CopyToClip = ()=>{
    copy(key)
    alert.removeAll();
    alert.show('Copied to clipboard!', {type: 'success'})
  }

  return (
    <div className="border border-primary md:block lg:block lg:w-4/6 xl:w-4/6 h-screen p-5">
      <div className="w-4/5 sm:w-4/6 mx-auto mt-10">
        {/*<img src={Images.qrCodeIcon} className="h-2/6 w-2/6 mx-auto" />*/}
        <div className="h-2/6 mx-auto p-2 bg-white" style={{width: '270px'}}><QRCode value={key} /></div>
        <p className={styles.textStyle}>
        Copy and paste the address or scan the QR code to get the address.
        </p>
        <p className="text-xxxs md:text-sm lg:text-lg xl:text-lg text-secondaryGray py-2 border-dashed border-2 border-primary text-center ">
          {key.substring(0, 40)+'...'}
        </p>
        {/*<div className="w-1/6 mx-auto">
          <div className="flex items-center self-center mt-2">
            <img src={Images.checkedIcon} className="h-3 w-4 mr-2" />
            <p className={styles.textStyle2}>Copied</p>
          </div>
  </div>*/}
        <div className="flex justify-center mt-4">
          <button onClick={CopyToClip} className="flex text-white rounded mr-3 border border-secondaryGray text-xs py-2 px-4 items-center">
            <img
              src={Images.copyIcon}
              className="h-2 w-2 mr-2 md:h-4 md:w-4 md:mr-2 lg:h-4 lg:w-4 lg:mr-2  xl:h-4 xl:w-4 xl:mr-2"
            />
            <p className={styles.textStyle3}>Copy Address</p>
          </button>
          {/*<button className="flex text-white rounded border border-secondaryGray text-xs py-2 px-4 items-center">
            <img
              src={Images.downloadIcon}
              className="h-2 w-2 mr-2 md:h-4 md:w-4 md:mr-2 lg:h-4 lg:w-4 lg:mr-2  xl:h-4 xl:w-4 xl:mr-2"
            />
            <p className={styles.textStyle3}>Download QR</p>
          </button>*/}
        </div>
        <div className="mt-10">
          <p className={styles.textStyle4}>
            Kindly make sure only XLM or Stellar based address assets are sent to this address.
          </p>
          <p className={styles.textStyle4}>
            You will lose your fund by ignoring it.
          </p>
        </div>
      </div>
    </div>
  );
};
const styles = {
  textStyle:
    "mt-4 mb-2 text-accent text-center text-xxxs md:text-sm lg:text-lg xl:text-lg",
  textStyle2:
    "text-lightGreen text-center text-xxxs md:text-sm lg:text-sm xl:text-sm",
  textStyle3:
    "text-white text-center text-xxxs md:text-sm lg:text-sm xl:text-sm",
  textStyle4:
    "text-secondaryGray text-center text-xxxs md:text-xs mt-2 mb-2 lg:text-xs mt-2 mb-2 xl:text-xs mt-2 mb-2"
};
