import * as React from "react";
import { useHistory } from "react-router-dom";
import { ReceiveScreen } from "screens/ReceiveScreen";
import { TransactionScreen } from "screens/TransactionsScreen";
import { colors } from "styles/theme";
import { SendScreenMenu } from "components/molecular/SendScreenMenu";
import { CryptoTopRow } from "components/molecular/P2P/CryptoTopRow";
import { SendScreen } from "./SendScreen";
import { AboutScreen } from "./AboutScreen";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { coinsFromShort } from "utils/constants";
import Withdraw from "./Withdraw";
import Deposite from "./Deposite";

export const WalletDetailScreen = (props: any) => {
  const [insights, setInsights] = React.useState(false);
  let history = useHistory();
  let {state}:any = history.location;

  
  let [screen, setScreen] = React.useState(state?.screen || 0);
  let transaction = useSelector((state: RootState) => state.transaction);
return (
    <div className="mt-5">
      <CryptoTopRow
        bgColor={colors.primary}
        btnColor={"#404040"}
        icon={state.asset.coinIcon}
        dollarPrice={state.new?0:transaction.coins[transaction.selected].availableBalance}
        btcPrice={`${state.new?0:transaction.coins[transaction.selected].balance} ${state.asset.short}`}
        isLoss={Number(state.asset.percent) < 0}
        percentValue={state.asset.percent}
        showInsights={insights}
      />
      <div className="block w-full md:flex lg:flex xl:flex mt-5">
        <SendScreenMenu
          onClick={(screen) => {
            setInsights(false);
            setScreen(screen);
          }}
          onClickSend={(screen) => {
            setInsights(true);
            setScreen(screen);
          }}
          screen={screen}
          coin={state.asset}
        />
        {screen === 0 && <AboutScreen />}
        {screen === 1 && <SendScreen />}
        {screen === 2 && <ReceiveScreen />}
        {screen === 3 && <TransactionScreen />}
        {screen === 4 && <Deposite />}
        {screen === 5 && <Withdraw />}
      </div>
    </div>
  );
};

const styles = {
  textStyle: "ml-4 text-xxxs md:text-sm lg:text-lg xl:text-lg",
  linkStyle:
    "text-secondaryGray p-2 md:p-5 lg:p-5 xl:p-5 hover:bg-accent hover:text-white",
};
