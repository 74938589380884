import { Country, Currency, Notification } from "store/models/GenericState";
export const FETCH_CURRENCIES_PENDING = "FETCH_CURRENCIES_PENDING";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";
export const FETCH_CURRENCIES_ERROR = "FETCH_CURRENCIES_ERROR";
export const TOGGLE_CONFIRM_MODAL = "TOGGLE_CONFIRM_MODAL";
export const FETCH_INITIAL_DATA = "FETCH_INITIAL_DATA";
export const SET_MY_COUNTRY = "SET_MY_COUNTRY";
export const SET_ACTIVE_COUNTRY = "SET_ACTIVE_COUNTRY";
export const PUSH_P2P_NOTIFICATION = "PUSH_P2P_NOTIFICATION";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const APPEND_NOTIFICATION = "APPEND_NOTIFICATION";
export const SET_SOCKET_STATUS = "SET_SOCKET_STATUS";
export const SET_MEMO = "SET_MEMO";
export const SET_LOADING_TABLE = "SET_LOADING_TABLE";

interface FetchCurrenciesSuccess {
  type: typeof FETCH_CURRENCIES_SUCCESS;
  payload: Currency[];
}
export interface FetchCurrenciesPanding {
  type: typeof FETCH_CURRENCIES_PENDING;
  isFetching: boolean;
}
export interface SetSocketConnected {
  type: typeof SET_SOCKET_STATUS;
  connected: boolean;
}
export interface SetNotifications {
  type: typeof SET_NOTIFICATIONS;
  notifications: Notification[];
}
export interface AppendNotification {
  type: typeof APPEND_NOTIFICATION;
  notification: Notification;
}
export interface ToggleConfirmModal {
  type: typeof TOGGLE_CONFIRM_MODAL;
  visible: boolean;
}
export interface PushP2pNotification {
  type: typeof PUSH_P2P_NOTIFICATION;
  message: string
}
export interface FetchInitialData {
  type: typeof FETCH_INITIAL_DATA;
  data: {};
}
export interface SetMyCountry {
  type: typeof SET_MY_COUNTRY;
  country: any
}
export interface SetActiveCountry {
  type: typeof SET_ACTIVE_COUNTRY;
  countries: Country[]
}

export interface SetMemo {
  type: typeof SET_MEMO;
  memo: string
}

export interface SetLoading {
  type: typeof SET_LOADING_TABLE;
  setLoading: boolean;
}


export type GetGenericActionsType =
  | FetchCurrenciesSuccess
  | FetchCurrenciesPanding
  | ToggleConfirmModal
  | SetMyCountry
  | SetNotifications
  | AppendNotification
  | PushP2pNotification
  | SetActiveCountry
  | SetSocketConnected
  | FetchInitialData
  | SetMemo
  | SetLoading;
