import * as React from "react";
//import { navigationButtonStyle, logoImageStyle } from 'styles/ButtonStyles';
import {
  BrowserRouter as Router,
  NavLink,
  useLocation,
} from "react-router-dom";
import { logo } from "assets/images/images";
import { theme } from "styles/theme";

const MainNavButtons = ({ auth }) => {
  let location = useLocation();
  return (
    <div className="hidden ml-10 sm:hidden lg:flex">
      <NavLink to="/market">
        <div
          className={
            location.pathname === "/market"
              ? "border-b border-accent"
              : "border-b border-primary hover:border-accent hover:border-b"
          }
        >
          <button
            className={
              location.pathname === "/market"
                ? styles.navigationActiveButtonStyle
                : styles.navigationButtonStyle
            }
          >
            {" "}
            Markets{" "}
          </button>
        </div>
      </NavLink>
      <NavLink to="/exchange">
        <div
          className={
            location.pathname === "/exchange"
              ? "border-b border-accent"
              : "border-b border-primary hover:border-accent hover:border-b"
          }
        >
          <button
            className={
              location.pathname === "/exchange"
                ? styles.navigationActiveButtonStyle
                : styles.navigationButtonStyle
            }
          >
            {" "}
            Exchange{" "}
          </button>
        </div>
      </NavLink>
      <NavLink to="/p2p">
        <div
          className={
            location.pathname === "/p2p"
              ? "border-b border-accent"
              : "border-b border-primary hover:border-accent hover:border-b"
          }
        >
          <button
            className={
              location.pathname === "/p2p"
                ? styles.navigationActiveButtonStyle
                : styles.navigationButtonStyle
            }
          >
            {" "}
            P2P{" "}
          </button>
        </div>
      </NavLink>

      <NavLink to="/swap">
        <div
          className={
            location.pathname === "/swap"
              ? "border-b border-accent"
              : "border-b border-primary hover:border-accent hover:border-b"
          }
        >
          <button
            className={
              location.pathname === "/swap"
                ? styles.navigationActiveButtonStyle
                : styles.navigationButtonStyle
            }
          >
            Swap{" "}
          </button>
        </div>
      </NavLink>
    </div>
  );
};
const styles = {
  navigationButtonStyle:
    "focus:outline-none w-30 m-4 pt-1 pb-1 text-white hover:text-accent text-md font-regular",
  navigationActiveButtonStyle:
    "focus:outline-none w-30 m-4 pt-1 pb-1 text-accent text-md font-regular",
};

export default MainNavButtons;
