import {
  FETCH_MARKET_PENDING,
  FETCH_MARKET_SUCCESS,
  GetMarketInfoType,
  SELECT_MARKET_COIN,
  ADD_TO_FAVOURITES,
  REMOVE_FROM_FAVOURITES,
  FETCH_TOP_BAR_PENDING,
  FETCH_TOP_BAR_SUCCESS,
  SEARCH_MARKET_DATA,
  REFRESH_MARKET_DATA,
  UPDATE_CLOSE_PRICE,
} from "./MarketTypes";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { MarketInfo, CoinMarket } from "store/models/MarketInfo";


export function getMarketSuccess(
  data: [],
  currency: string
): GetMarketInfoType {
  return {
    type: FETCH_MARKET_SUCCESS,
    currency: currency,
    payload: data,
  };
}
export const getMarketPending = (isFetching: boolean): GetMarketInfoType => {
  return { type: FETCH_MARKET_PENDING, isFetching };
};
export const updateClosePrice = (base: string, counter: string, price: string): GetMarketInfoType => {
  return { type: UPDATE_CLOSE_PRICE, price, base, counter };
};
export const selectMarketCoin = (selected: CoinMarket): GetMarketInfoType => {
  return { type: SELECT_MARKET_COIN, selected: selected };
};
export const addToFavourites = (data: MarketInfo): GetMarketInfoType => {
  return { type: ADD_TO_FAVOURITES, data: data };
};
export const removeFromFavourites = (data: MarketInfo, coin: string): GetMarketInfoType => {
  return { type: REMOVE_FROM_FAVOURITES, data, coin };
};
export const getTopBarPending = (isFetching: boolean): GetMarketInfoType => {
  return { type: FETCH_TOP_BAR_PENDING, isFetching };
};
export const getTopBarSuccess = (
  data: [],
  currency: string
): GetMarketInfoType => {
  return { type: FETCH_TOP_BAR_SUCCESS, data, currency };
};
export const searchMarket = (data: string): GetMarketInfoType => {
  return { type: SEARCH_MARKET_DATA, data };
};
export const refreshMarketData = (market: CoinMarket[], reload: boolean): GetMarketInfoType => {
  return { type: REFRESH_MARKET_DATA, market, reload };
};

export const getMarketInfoAction = (
  currency: string,
  length: number
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch) => {
  if (length > 0 || currency === "favorite") {
    //dispatch(selectMarketCoin(currency));
    return;
  }
  dispatch(getMarketPending(true));
  try {
    let url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${currency}&ids=bitcoin,litecoin&order=market_cap_desc&page=1&sparkline=false`;
    let data = await fetch(url).then((res) => res.json());
    dispatch(getMarketSuccess(data, currency));
  } catch (error) {
    // console.log(error);
  }
};
export const getTopBarInfoAction = (): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => async (dispatch) => {
  dispatch(getTopBarPending(true));
  try {
    let url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=${"usd"}&ids=bitcoin,litecoin,ethereum,dogecoin&order=market_cap_desc&page=1&sparkline=false`;
    let data = await fetch(url).then((res) => res.json());

    dispatch(getTopBarSuccess(data, "usd"));
  } catch (error) {
    // console.log(error);
  }
};



