import P2pBasicUserInfo from 'components/organisms/P2pBasicUserInfo';
import P2pProfileAds from 'components/organisms/P2pProfileAds';
import P2pProfileReview from 'components/organisms/P2pProfileReview';
import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import { getSelectedKeys } from 'services/trannsactionServices';


function P2pDetail() {
    const account = getSelectedKeys().public
    return(
        <div className="border border-primary p-5 w-full">
            <h1 className="text-white text-lg">P2P Profile</h1>
            <div className="lg:flex">
                <div className="w-full">
                <P2pBasicUserInfo hasImage={false} account={account} />

                <P2pProfileReview me={true} />
                </div>
                <div className="w-full lg:pl-2 mt-4 lg:mt-0">
                    <P2pProfileAds account={account} />
                </div>
            </div>
        </div>
    )
}

export default P2pDetail