import React from 'react';


function TabMenu({tab, onClick, disabled}) {
    return(
        <div className="border-b border-primary my-6">
            <button onClick={()=>onClick(0)} disabled={disabled} className={`px-4 focus:outline-none ${tab==0?"border-b-2 border-accent text-white":"text-secondaryGray"} py-3 font-semibold text-lg`}>
                My Account
            </button>
            <button onClick={()=>onClick(1)} disabled={disabled} className={`px-4 focus:outline-none ${tab==1?"border-b-2 border-accent text-white":"text-secondaryGray"} py-3 font-semibold text-lg`}>
                Liquidity
            </button>
            <button onClick={()=>onClick(2)} disabled={disabled} className={`px-4 focus:outline-none ${tab==2?"border-b-2 border-accent text-white":"text-secondaryGray"} py-3 font-semibold text-lg`}>
                History
            </button>
        </div>
    )
}

export default TabMenu