import React, { useMemo } from "react";
import { AdCard } from "./AdCard";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import { getPaymentText, profileStatus } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useAlert } from "react-alert";
import { getAddsInfoAction } from "store/actions/P2pActions";

function Adds({ data, loading, activeTab, coinName, page, usdRates }) {
  const {
    auth,
    profile: { user },
    transaction: { coins },
    p2p: { active, searching },
  } = useSelector((state: RootState) => state);
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch()

  const dataUnique = useMemo(() => {
    return data.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
  }, [data])

  const isTrusted = (assetCode) => {
    let coin = coins.find((coin) => coin.short.toUpperCase() === assetCode);
    if (coin) {
      return true;
    } else {
      return false;
    }
  };

  const onAction = (item) => {
    if (!auth.password || user.status !== profileStatus.approved) {
      if (!auth.password) {
        alert.show("You are not logged in.");
      } else {
        alert.show("Your KYC is not approved.");
      }
      return;
    }
    history.push({
      pathname:
        item.type == "sell"
          ? "/confirmationScreenSell"
          : "/confirmationScreenBuy",
      state: {
        add: item,
      },
    });
  };
  const onProfile = (e, account) => {
    e.stopPropagation();
    history.push({ pathname: "/p2pProfile", state: { account: account } });
  };
  const handleLoadMore = () => {
    dispatch(getAddsInfoAction(activeTab, coinName, page+1))
  }
  const getAssetRate = (asset) => {
    const assetRates = usdRates.find(
      (item) => item.short.toUpperCase() === asset.toUpperCase()
    );
    return assetRates?.currentRate||0;
  }
  
  const renderRows = () =>
  dataUnique.map((item, index) => (
      <div className="mt-2" key={index}>
        <AdCard
          type={item.type}
          buy={item.type === "buy"}
          maxamount={item.maxQty}
          currency={item.currency}
          assetCode={item.assetCode}
          advertiser={item.first_name?item.first_name + " " + item.last_name:item.companyName}
          onAction={() => onAction(item)}
          inProfile={false}
          rating={item.rating}
          onProfile={(e) => onProfile(e, item.account)}
          item={item}
          usdRate={getAssetRate(item.assetCode)}
        />
      </div>
    ));
  const renderLoading = () => (
    <div className="flex justify-center items-center my-4 h-full">
      <Loader type="Bars" color="#FFFF00" height={30} width={30} />
    </div>
  );
  const renderLoadMore = () => (
    <div className="flex justify-center">
      <button
        onClick={handleLoadMore}
        className="focus:outline-none border border-secondaryGray p-2 text-secondaryGray my-4"
      >
        Load More
      </button>
    </div>
  );
  if(searching){
    return <div style={{marginTop: "100px"}}>{renderLoading()}</div>
  }
  if(!searching && !loading && data.length ===0){
    return <div style={{marginTop: "100px"}} className="text-secondaryGray flex justify-center">No ads to show</div>
  }
  return (
    <div>
      <p className="text-white font-regular text-lg mb-4">
        You want to {activeTab === "sell" ? "buy" : "sell"} {coinName}?
      </p>
      {data.length > 0 && renderRows()}
      {loading && renderLoading()}
      {data.length > 0 && !loading && renderLoadMore()}
    </div>
  );
}

export default Adds;
