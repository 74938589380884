import * as React from "react";
import CustomInput from "components/global/CustomInput";
import * as Images from "assets/images/index";
import { FormDataRow } from "components/molecular/P2P/CreateAd/FormDataRow";
import { HistoryListRow } from "components/molecular/ProfileScreen/HistoryListRow";
import { ReferListRow } from "components/molecular/ProfileScreen/ReferListRow";
import { getSelectedKeys } from "services/trannsactionServices";

import useReferrals from "./hooks/useReferrals";
import useReferralHistory from "./hooks/useReferralHistory";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";

// const HistoryList = ReferralHistory.map((item) => (
//   <HistoryListRow
//     date={item.date}
//     time={item.time}
//     friendEmail={item.friendEmail}
//     commission={item.commission}
//     currency={item.currency}
//   />
// ));

const Referrals = (data) =>
  data.map((item, index) => (
    <ReferListRow
      key={index}
      date={moment(item.createdAt).format("L")}
      time={moment(item.createdAt).format("LT")}
      friendEmail={item.referredTo}
      level={item.referralLevelId}
    />
  ));

const HistoryList = (data) =>
  data.map((item, index) => (
    <HistoryListRow
    key={index}
    date={moment(item.createdAt).format("L")}
    time={moment(item.createdAt).format("LT")}
    friendEmail={item.referralAccount}
      commission={item.amount}
      currency={item.coinCode}
    />
  ));


export const ReferFriendTab = () => {
  let alert = useAlert();

  const [displayHistory, setDisplayHistory] = React.useState("none");
  const [displayReferrals, setDisplayReferrals] = React.useState("block");
  let { loading, data } = useReferrals(getSelectedKeys().public);
  let { historyData } = useReferralHistory();
  
  const copyToClip = () => {
    copy(getSelectedKeys().public);
    alert.show("Copied!", { type: "success" });
  };

  return (
    <div className="border border-primary mb-6 p-5 w-full">
      <p className="text-white font-regular text-lg">Refer A Friend</p>
      <div className="mt-4">
        <p className="text-secondaryGray text-xs font-regular">
        You will get incentives of successful purchases by your referrals on P2P market.
        </p>
        <div className="md:flex lg:flex xl:flex w-full items-center mt-2">
          <div className="mt-2 mb-2 md:w-4/6 lg:w-4/6 xl:w-4/6">
            <CustomInput
              // iconName={Images.copyIcon}
              disabled={true}
              // showIcon
              value={getSelectedKeys().public}
              // placeholder="GAM6VYDAAVINDY75JLRMEH6ZJNWN4A3NDPWOBZ4TPUVL74FGO7"
            />
          </div>
          <button
             onClick={copyToClip}
            style={{ height: "2.9rem" }}
            className="text-center text-white text-xs bg-accent md:ml-4 lg:ml-4 xl:ml-4 px-4 font-regular p-1"
          >
            Copy
          </button>
        </div>
        {/* <div className="flex items-center">
          <img src={Images.checkedIcon} className="h-2 w-3" />
          <p className="text-lightGreen font-regular ml-2 text-xs">Copied</p>
        </div> */}
      </div>

      <div className="mt-5">
        <div className="flex items-center">
          <button
            onClick={() => {
              setDisplayHistory("none");
              setDisplayReferrals("block");
            }}
            className={
              displayReferrals == "block"
                ? "focus:outline-none text-white mx-3 text-sm py-1 font-regular border-b border-accent"
                : "focus:outline-none text-white mx-3 text-sm font-regular"
            }
          >
            My Referral
          </button>
          <button
            onClick={() => {
              setDisplayHistory("block");
              setDisplayReferrals("none");
            }}
            className={
              displayHistory == "block"
                ? "focus:outline-none ml-4 mx-3 text-white text-sm py-1 font-regular border-b border-accent"
                : "focus:outline-none ml-4 mx-3 text-white text-sm font-regular"
            }
          >
            History
          </button>
        </div>
        <div style={{ display: displayReferrals }}>
          <table
            style={{ tableLayout: "fixed", width: "100%" }}
            cellPadding="9"
            className="separated-row"
          >
            <thead>
              <tr>
                <th className="text-secondaryGray md:text-xs lg:text-xs xl:text-xs text-xxxs font-regular text-left">
                 Account
                </th>
                <th className="text-secondaryGray md:text-xs lg:text-xs xl:text-xs text-xxxs font-regular w-3/6 text-center">
                  Level
                </th>
                <th className="text-secondaryGray md:text-xs lg:text-xs xl:text-xs text-xxxs font-regular text-right">
                  Date & Time
                </th>
              </tr>
            </thead>
            <tbody>{!loading && Referrals(data)}</tbody>
          </table>
          {loading && <div className="flex justify-center mt-4"><FontAwesomeIcon icon={faSync} spin color="#ffffff" /> </div>}
        </div>
        <div style={{ display: displayHistory }}>
          <table
            style={{ tableLayout: "fixed", width: "100%" }}
            cellPadding="9"
            className="separated-row"
          >
            <thead>
              <tr>
                <th className="text-secondaryGray md:text-xs lg:text-xs xl:text-xs text-xxxs font-regular text-left">
                  Account
                </th>
                <th className="text-secondaryGray md:text-xs lg:text-xs xl:text-xs text-xxxs font-regular w-3/6 text-center">
                  Commissions Earned
                </th>
                <th className="text-secondaryGray md:text-xs lg:text-xs xl:text-xs text-xxxs font-regular text-right">
                  Date & Time
                </th>
              </tr>
            </thead>
            {/* <tbody>{HistoryList}</tbody> */}
            <tbody>{HistoryList(historyData)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
