import { useState } from "react";
import {useAlert} from "react-alert";
import { confirmPassword } from "services/authServces";

let useConfirmPassword = (callback) => {
  let [inputs, setInputs] = useState({
    password: "",
    passwordValidation: ""
  });
  let [submitting, setSubmitting] = useState(false)
  let alert = useAlert();

  let handleInputChange = (e) => {
    let { value } = e.target;
    setInputs((prev) => ({ password: value, passwordValidation: "" }));
  };
  
  let handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    setSubmitting(true);
    let res = confirmPassword(inputs.password);
    if(res){
        callback();
        setInputs({password: "", passwordValidation:""});
    }else{
      alert.removeAll();
        alert.show("Wrong password please try again.",{type: "error"});
    }
    setSubmitting(false)
  };

  let isValidated = () => {
    let response = true;
    if (!inputs.password) {
      response = false;
      setInputs((prev) => ({ ...prev, passwordValidation: "Password is required." }));
    }

    if(submitting){
      response = false;
    }

    return response;
  };
  
  return { ...inputs, handleInputChange, handleSubmit, submitting };
};

export default useConfirmPassword;


