import React, { useState } from "react";
import CandleStickChart from "components/organisms/CandleStickChart/index";
import { AssetDataContainer } from "components/molecular/AssetDataContainer";
import { assetDetails } from "utils/AppConstants";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { getSelectedKeys } from "services/trannsactionServices";
import { useAlert } from "react-alert";
import { addTrust } from "services/AssetServices";
import { useHistory } from "react-router-dom";
import { addTrustEnum, coinsFromShort } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { colors } from "styles";
import { setCoinsAction } from "store/actions/TransactionActions";
import ConfirmPassword from "./ConfirmPassword";
import { getAssetByName } from "services/dbServices";
import { Asset } from "store/models/Assets";
import useAbout from "./hooks/useAbout";
import Loader from "react-loader-spinner";

export function AboutScreen(props: any) {
  let { transaction, assets } = useSelector((state: RootState) => state);
  let [currentAsset, setIssuer] = useState({
    issuer: "",
    code: "",
    loading: true,
  });
  let [creating, setCreating] = useState(false);
  let [confirmOpen, setOpen] = useState(false);
  let alert = useAlert();
  let history = useHistory();
  let dispatch = useDispatch();
  let { initialize } = useAbout();
  let { state }: any = history.location;

  let isAdded = () => {
    let name = state && state.coin;
    let res = transaction.coins.filter(
      (item) => item.short === name && item.issuer == state?.asset?.issuer
    );
    return res.length > 0;
  };

  const trustAssetClickHandler = () => {
    let name = state && state["coin"];
    if (!name) return;
    if (currentAsset.loading) {
      alert.show("Loading information please wait.");
      return;
    }
    setCreating(true);
    addTrust(currentAsset)
      .then((data) => {
        setCreating(false);
        alert.removeAll();
        alert.show("Trust created successfully.", { type: "success" });
        history.goBack();
        dispatch(setCoinsAction());
      })
      .catch((e) => {
        console.log(e);
        alert.removeAll();
        alert.show(getTrustMessage(e, "Unable to trust this asset."), {
          type: "error",
        });
        setCreating(false);
      });
  };
  const getTrustMessage = (e, defMsg) => {
    let message;
    try {
      message =
        addTrustEnum[e?.response?.data?.extras?.result_codes?.operations[0]] ||
        defMsg;
    } catch (err) {
      message = defMsg;
    }
    return message;
  };

  return (
    <div style={{ flex: 1 }}>
      <CandleStickChart state={state} />

      <div className="flex" style={{ justifyContent: "flex-end" }}>
        {!isAdded() && (
          <button
            onClick={() => setOpen(true)}
            style={{ backgroundColor: colors.green }}
            className="focus:outline-none text-white text-xs md:text-sm lg:text-sm xl:text-sm px-4 py-2 rounded mt-5 mb-5 w-full"
          >
            {creating ? (
              <div>
                <FontAwesomeIcon icon={faSyncAlt} color="#ffffff" spin /> Please
                Wait...
              </div>
            ) : currentAsset.loading ? (
              "Loading"
            ) : (
              "Trust Asset"
            )}
          </button>
        )}
        {isAdded() && (
          <div className="my-8"></div>
          //<button
          //  style={{ backgroundColor: colors.red }}
          //  className="focus:outline-none text-white text-xs md:text-sm lg:text-sm xl:text-sm px-4 py-2 rounded mt-5 mb-5 w-full"
          //>
          //  {creating ? "Please Wait..." : "Remove Asset"}
          //</button>
        )}
      </div>
      <AssetDataContainer state={state} setIssuer={setIssuer} assets={assets} />
      <ConfirmPassword
        isOpen={confirmOpen}
        onClose={() => setOpen(false)}
        onSuccess={trustAssetClickHandler}
      />
    </div>
  );
}
