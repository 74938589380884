import React, { useEffect } from "react";
import { TransactionsDataRow } from "../markets/Transactions/TransactionsDataRow";
import moment from "moment";
import { Transaction } from "store/models/Transaction";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import {
  transactionsHistoryAction,
  transactionsNextAction,
} from "store/actions/TransactionActions";
import { getSelectedKeys } from "services/trannsactionServices";
import { useAlert } from "react-alert";
import copy from "copy-to-clipboard";
import Loader from "react-loader-spinner";

function HistoryTab() {
  let {
    transactions,
    loadingTrans,
    loadingNextTrans,
    stellarTransNextPage,
    apiTransPage,
  } = useSelector((state: RootState) => state.transaction);
  let alert = useAlert();
  let dispatch = useDispatch();

  let copyToClip = (text) => {
    copy(text);
    alert.removeAll();
    alert.show("Copied to clipboard!", { type: "success" });
  };

  useEffect(() => {
    if(transactions.length < 1){
    dispatch(transactionsHistoryAction(true));
    }else{
      dispatch(transactionsHistoryAction(false));
    }
  }, []);

  let renderRows = () => {
    return transactions.map((item: Transaction, index) => {
      return (
        <div className="mt-2" key={index}>
          <TransactionsDataRow
            status={item.status}
            kind={capitalize(item.kind)}
            currency={item.assetcode ?? item.coin}
            amount={item.finalAmount ?? 0.0}
            cryptocurrency={""}
            stTrId={item.stellar_transaction_id}
            onCopy={() => copyToClip(item.stellar_transaction_id)}
            date={moment(item.started_at).format("LL")}
            time={moment(item.started_at).format("LTS")}
          />
        </div>
      );
    });
  };

  let handleNext = () => {
    dispatch(transactionsNextAction(stellarTransNextPage, apiTransPage+1));
  };

  let renderNext = () => (
    <div className="flex justify-center my-6">
      <button
        onClick={handleNext}
        className="focus:outline-none py-2 px-3 border border-gray4 text-gray4"
      >
        Show more
      </button>
    </div>
  );

  return (
    <div>
      {!loadingTrans && transactions.length > 0 && renderRows()}

      {!loadingNextTrans && transactions.length > 9 && !loadingTrans && renderNext()}

      {loadingNextTrans && (
        <div className="flex justify-center my-6">
          <Loader type="Bars" color="#FFFF00" height={30} width={30} />
        </div>
      )}

      {loadingTrans && (
        <div className="flex justify-center mt-16">
          <Loader type="Bars" color="#FFFF00" height={30} width={30} />
        </div>
      )}

      {!loadingTrans && transactions.length == 0 && (
        <div className="flex justify-center mt-16">
          <p className="text-white font-semibold text-xl">
            No Transactions found!
          </p>
        </div>
      )}
    </div>
  );
}

export default HistoryTab;

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
