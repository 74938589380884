import {
  FETCH_PROFILE_PENDING,
  GetProfileInfoType,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  ADD_DOCUMENT_SUCCESS,
  CLEAR_PROFILE,
  ADD_USER_DATA,
  EMAIL_VERIFIED,
  PHONE_VERIFIED,
  SET_INPUT_PROPERTY,
  DELETE_KYC_PENDING,
  SET_PROFILE_PICTURE,
} from "./ProfileActionsTypes";
import { User, Document } from "store/models/User";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { BASE_URL, getInitUsers } from "utils/AppConstants";
import { getSelectedKeys } from "services/trannsactionServices";
import { getJWT } from "services/httpServices";
import Axios from "axios";
import { getCountryFromCode } from "utils/countries";
import { RootState } from "store/reducers";

export const getProfilePending = (isFetching: boolean): GetProfileInfoType => {
  return { type: FETCH_PROFILE_PENDING, isFetching };
};

export const getProfileSuccess = (
  user: User,
  document: Document
): GetProfileInfoType => {
  return { type: FETCH_PROFILE_SUCCESS, user: user, document: document };
};
export const getProfileFailure = (error: boolean): GetProfileInfoType => {
  return { type: FETCH_PROFILE_FAILURE, error };
};

export const setProfilePicture = (photo: string): GetProfileInfoType => {
  return { type: SET_PROFILE_PICTURE, photo };
};

export const addDocumentSuccess = (data: Document): GetProfileInfoType => {
  return { type: ADD_DOCUMENT_SUCCESS, payload: data };
};

export const clearProfileAction = (): GetProfileInfoType => {
  return { type: CLEAR_PROFILE };
};

export const deleteKycPending = (): GetProfileInfoType => {
  return { type: DELETE_KYC_PENDING };
};
export const addUserActon = (user: any): GetProfileInfoType => {
  return { type: ADD_USER_DATA, payload: user };
};
export const setInputProperty = (
  key: string,
  value: string
): GetProfileInfoType => {
  return { type: SET_INPUT_PROPERTY, key, value };
};
export const verifyEmailAction = (email: string): GetProfileInfoType => {
  return { type: EMAIL_VERIFIED, email };
};

export const verifyPhoneAction = (
  phone: string,
  country: string,
  dial: string,
  isCompany: boolean
): GetProfileInfoType => {
  return { type: PHONE_VERIFIED, phone, country, dial, isCompany };
};

export const getProfileInfoAction = (): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => async (dispatch, getState) => {
  const {transaction:{coins}}:any = getState()
  try {
    dispatch(getProfilePending(true));
    let token = await getJWT();

    const account = getSelectedKeys().public;
    let res = await Axios.get(BASE_URL + `/kyc/info?accountId=${account}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token?.data?.token}`,
      },
    });
    
    const response = await Axios.get(
      BASE_URL + `/p2p/getProfile?account=${account}`
    );

    if (res.data.code === "NO_USER") {
      const { user, document } = getInitUsers();
      dispatch(getProfileSuccess(user, document));
    } else if (res.status === 200) {
      const { user, document } = getObject(res, response);
      dispatch(getProfileSuccess(user, document));
    }
  } catch (error) {
    dispatch(getProfileFailure(coins.length>0));
  }
};

export const addProfileAction = (
  data: any,
  callback
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch) => {
  try {
    let token: any = await getJWT();

    let res = await Axios.post(BASE_URL + "/kyc/update", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token.data.token}`,
      },
    });

    if (res.statusText === "Created") {
      callback(res.data);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
};

export const addDocumentAction = (
  body,
  data,
  callback
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch) => {
  try {
    let token: any = await getJWT();
    let res = await Axios.post(BASE_URL + "/kyc/submitDocument", body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    });

    if (res.status === 200) {
      dispatch(addDocumentSuccess(data));
    }
    callback(true);
  } catch (error) {
    if (error.response) {
      // console.log(error.response.data);
    }
    callback(false);
  }
};

export const deleteKycAction = (callback): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => async (dispatch) => {
  try {
    let token: any = await getJWT();
    dispatch(deleteKycPending());
    let res = await Axios.delete(BASE_URL + "/kyc", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    });
    callback(true)
    dispatch(clearProfileAction());
  } catch (error) {
    callback(false)
    if (error.response) {
      // console.log(error.response.data);
    }
  }
};

export const getObject = (res, response) => {
  let dateOfBirth = new Date(
    res.data.birthDate && res.data.birthDate != "0000-00-00"
      ? res.data.birthDate
      : "2015-03-25"
  );
  let expDate = new Date(
    res.data.idExpirationDate && res.data.idExpirationDate != "0000-00-00"
      ? res.data.idExpirationDate
      : "2015-03-25"
  );
  let issueDate = new Date(
    res.data.idIssueDate && res.data.idIssueDate != "0000-00-00"
      ? res.data.idIssueDate
      : "2015-03-25"
  );
  const country = getCountryFromCode(res.data.addressCountryCode);
  const dialCode = country?.dial_code || "";
  const phone = (res.data.mobileNumber || "").replace(dialCode, "");
  const document: Document = {
    country: res.data.idCountryCode,
    expDate: expDate,
    issueDate: issueDate,
    number: res.data.idNumber,
    realName: res.data.realName,
    docType: res.data.idType,
    backUrl: res.data.photoIdBack,
    frontUrl: res.data.photoIdFront,
    proofCode: res.data.proofCode,
    proofImage: res.data.proofImage,
  };

  const user: User = {
    id: res.data.id,
    address: res.data.address,
    countryCode: res.data.addressCountryCode,
    city: res.data.city,
    email: res.data.emailAddress,
    phone: phone,
    postalCode: res.data.postalCode,
    state: res.data.stateOrProvince,
    status: res.data.status,
    isEmailVerified: res.data.isEmailVerified || false,
    isPhoneVerified: res.data.isPhoneVerified || false,
    countryDial: dialCode,
    adminNotes: res.data.adminNotes,
    firstName: res.data.firstName,
    lastName: res.data.lastName,
    otherName: res.data.additionalName,
    dob: dateOfBirth,
    file: res.data.photo,

    companyDocument: res.data.companyDocument,
    companyName: res.data.companyName,
    companyWebsite: res.data.companyWebsite,
    businessName: res.data.businessName,
    isCompanyKyc: res.data.isCompanyKyc,
    reviews: response.data.profile.total_reviews || 0,
    rating: response.data.profile.rating || 0,
  };
  return { user, document };
};
