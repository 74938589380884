import React from "react";
import { BuyRows } from "./BuySellPriceTable";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import MyOffers from "components/molecular/Exchange/MyOffers";

function OverallTrades({ data, loading }) {
  return (
    <div
      className="border border-primary w-full p-4 hidden xl:block lg:block md:block"
      style={{ height: "450px" }}
    >
      <MyOffers
        data={data}
        showHeader={false}
        my={false}
        loading={loading}
        height="430px"
        getOffers={() => {}}
        onOfferDelete={() => {}}
        deleteAllOffers={()=>{}}
      />
    </div>
  );
}

export default OverallTrades;
