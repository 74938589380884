import React, { Fragment } from "react";
import { LiquidityDataRow } from "./LiquidityDataRow";
import Loader from "react-loader-spinner";

function LiquidityTab({ transaction, setAddAsset }) {
  return (
    <Fragment>
      {!transaction.locked &&
        !transaction.lowXlm &&
        !transaction.loading &&
        transaction.coins.length > 0 && (
          <RenderTable data={transaction.liquidityData} />
        )}

      {!transaction.locked && !transaction.lowXlm && transaction.loading && (
        <div className="flex justify-center mt-16">
          <Loader type="Bars" color="#FFFF00" height={30} width={30} />
        </div>
      )}

      {!transaction?.liquidityData[0] && (
        <div className="flex justify-center mt-16 text-white">
          No Data Found
        </div>
      )}

      {!transaction.locked &&
        !transaction.lowXlm &&
        !transaction.loading &&
        transaction.coins.length < 1 && (
          <div className="flex justify-center mt-16">
            <p className="text-white font-semibold text-xl">
              {transaction.error}
            </p>
          </div>
        )}
    </Fragment>
  );
}

export default LiquidityTab;

const RenderTable = ({ data }) => {
  return (
    <table
      style={{ tableLayout: "fixed", width: "100%" }}
      cellPadding="10"
      className="separated-row"
    >
      <thead>
        <tr className="text-left">
          <th className="pb-4">
            <text className="DataTopRow DataTopRow1 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Pair
            </text>
          </th>
          <th className="pb-4">
            <text className="DataTopRow DataTopRow2 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Total Coin's (A)
            </text>
          </th>
          <th className="pb-4">
            <text className="DataTopRow DataTopRow3 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Total Coin's (B)
            </text>
          </th>
          <th className="pb-4">
            <text className="DataTopRow DataTopRow2 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Total Shares
            </text>
          </th>
          <th className="pb-4">
            <text className="DataTopRow DataTopRow5 text-secondaryGray font-regular text-xxxs md:text-xs lg:text-sm xl:text-sm">
              Participants
            </text>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <LiquidityDataRow
            key={index}
            participants={item.participants}
            totalShares={item.totalShares}
            asset1_amount={item.asset1_amount}
            asset2_amount={item.asset2_amount}
            short1={item.short1}
            short2={item.short2}
            fullName1={item.fullName1}
            fullName2={item.fullName2}
            coinIcon1={item.coinIcon1}
            coinIcon2={item.coinIcon2}
          />
        ))}
      </tbody>
    </table>
  );
};
