import { useEffect, useState } from "react"
import axios from 'axios'
import { BASE_URL } from "utils/AppConstants"
import { getJWT } from "services/httpServices";

const useReferralsHistory = () => {
    // const useReferralsHistory = () => {
    let [state, setState] = useState({ historyData: [] })

    let fetchReferralHistory = async () => {
        try {
            let token: any = await getJWT();

            let res = await axios.post(`${BASE_URL}/referral/getReferralFeeHistory`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token.data.token}`,
                },
            });

            if (res.status === 200) {
                setState(prev => ({ historyData: res.data.history }));
            }

        } catch (error) {
            setState(prev => ({ ...prev, historyData: [] }));
            // console.log(error);
        }

    }

    useEffect(() => {
        fetchReferralHistory();
    }, [])
    return { ...state }
}
export default useReferralsHistory;
