export const LoginWrapperAlignment = {
  xAlignment:(whereToJustify: any) =>  " justify-"+whereToJustify + " ",
  yAlignment : (whereToJustify:any) => " content-"+whereToJustify + " ",
  heightFull: () => " h-full ",
  widthFull: () => " w-full ",
  setborder:() =>  ' border ',
};

const sm = "w-4/6 ";
const md = "w-2/6 ";
const lg = "w-2/6 ";

export const LoginWrapper = `sm:${sm} md:${md} lg:${lg} flex flex-wrap ${LoginWrapperAlignment.heightFull} ${LoginWrapperAlignment.xAlignment} ${LoginWrapperAlignment.yAlignment} ${LoginWrapperAlignment.widthFull}`;


export const MainWrapper = ` flex-wrap flex  ${LoginWrapperAlignment.xAlignment}${LoginWrapperAlignment.yAlignment}`
