import React, { useEffect } from "react";
import { logo2 } from "assets/images";
import { useHistory } from "react-router-dom";
import {
  fetchInitialDataAction,
  loadCacheAction,
  setActiveCountries,
  setMyCountry,
} from "store/actions/GenericActions";
import { useDispatch } from "react-redux";
import { saveData } from "services/storageServices";
import { getActiveCountries, getMyCountry } from "services/httpServices";

function Splash({ onClose }) {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchInitialDataAction(async (response) => {
        try {
          const activeCountries = await getActiveCountries();
          dispatch(setActiveCountries(activeCountries.data.currency));
          let myCountry = await getMyCountry();
          dispatch(
            setMyCountry({
              code: myCountry.data.countryCode,
              name: myCountry.data.country
            })
          );
          if (response === "SUCCESS") {
            //console.log("loaded data");
            onClose();
          } else if (response === "ERROR") {
            dispatch(
              loadCacheAction((res) => {
                if (res === "SUCCESS") {
                  //console.log("loaded cache");
                  onClose();
                } else if (res === "NO_DATA") {
                  //console.log("No cache found!");
                } else if ("ERROR") {
                  //console.log("Error while loading cache");
                }
              })
            );
          } else if (response === "NEW_USER") {
            //history.replace("/register");
            onClose();
          }
        } catch (e) {
          console.log(e);
        }
      })
    );
  }, []);

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="flex flex-col items-center mt-20">
        <img src={logo2} style={{ height: "300px" }} />
      </div>
    </div>
  );
}

export default Splash;
