import React from "react";
import "styles/App.css";
import Header from "components/molecular/header";
import { SwapScreen } from "screens/SwapScreen";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login, NewSessionPassword, NewWallet } from "screens";
import { MarketScreen } from "screens/MarketScreen";
import { WalletDetailScreen } from "screens/WalletDetailScreen";
import { WalletScreen } from "screens/WalletScreen";
import { P2PScreen } from "screens/P2PScreen";
import { TransactionConfirmationScreen } from "screens/TransactionConfirmationScreen";
import { TransactionProgressScreen } from "screens/TransactionProgressScreen";
import { ReviewScreen } from "screens/ReviewScreen";
import { CreateAdScreen } from "screens/CreateAdScreen";
import { ProfileScreen } from "screens/ProfileScreen";
import { ExchangeScreen } from "screens/ExchangeScreen";
import { ComingSoonScreen } from "screens/ComingSoonScreen";
import { Wrapper } from "components";
import { CheckoutScreen } from "../screens/CheckoutScreen";
import PrivateRoute from "./PrivateRoute";
import ResetPassword from "screens/resetPassword";
import ImportWallet from "screens/NewWallet/importWallet";
import ConfirmGlobal from "screens/ConfirmGlobal";
import PublicRoute from "./PublicRoute";
import LandingPage from "screens/LandingPage";
import PrivacyPolicy from "screens/PrivacyPolicy";
import { isExchangeVisible } from "utils/AppConstants";
import P2pUserProfile from "screens/P2pUserProfile";
import TermOfUse from "screens/TermOfUse";
import Disclaimer from "screens/Disclaimer";
import Support from "screens/Support";
import Faqscreen from "screens/Faqscreen";
import NoticeAlert from "components/atomic/NoticeAlert";
import Fees from "screens/Fees";

export const MainAppNav = () => {
  const [isAuth, setAuth] = React.useState(false);
  return (
    <div>
      <Router>
        <NoticeAlert />
        <Header isAuthenticated={isAuth} />
        <PublicRoute
          exact
          path="/login"
          component={() => (
            <div className="mt-10 w-4/6 mx-auto lg:w-1/3 xl:w-1/3">
              <Login
                onClick={() => {
                  setAuth(true);
                }}
              />
            </div>
          )}
        />
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route path="/p2pProfile">
          <P2pUserProfile />
        </Route>
        <Route path="/disclaimer">
          <Disclaimer />
        </Route>
        <Route path="/faqs">
          <Faqscreen />
        </Route>
        <Route path="/fees">
          <Fees />
        </Route>
        <Route path="/support">
          <Support />
        </Route>
        <Route path="/register">
          <div className="mt-10 w-4/6 mx-auto md:w-full lg:w-full xl:w-full">
            <Wrapper Fh Center Middle container>
              <Wrapper Middle style={["pb-40"]} Center loginContainer>
                <NewSessionPassword onClick={() => setAuth(true)} />
              </Wrapper>
            </Wrapper>
          </div>
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/terms-of-use">
          <TermOfUse />
        </Route>
        {/* <PrivateRoute path="/market" component={isExchangeVisible?MarketScreen:ComingSoonScreen} />
        <PrivateRoute path="/exchange" component={isExchangeVisible?ExchangeScreen:ComingSoonScreen} /> */}

        <Route path="/exchange">
          {isExchangeVisible ? <ExchangeScreen /> : <ComingSoonScreen />}
        </Route>

        <Route path="/market">
          {isExchangeVisible ? <MarketScreen /> : <ComingSoonScreen />}
        </Route>

        <Route path="/ComingSoonScreen">
          <ComingSoonScreen />
        </Route>

        <Route path="/forgotPassword">
          <ResetPassword />
        </Route>
        <Route path="/importWallet">
          <ImportWallet />
        </Route>
        <Route path="/p2p">
          {isExchangeVisible ? <P2PScreen /> : <ComingSoonScreen />}
        </Route>
        <Route path="/swap">
          {isExchangeVisible ? <SwapScreen /> : <ComingSoonScreen />}
        </Route>
        <Route path="/checkout/:memo">
          <CheckoutScreen/>
        </Route>

        {/* <PrivateRoute path="/p2p" component={P2PScreen} /> */}

        <PrivateRoute path="/newWallet" component={NewWallet} />
        <PrivateRoute path="/createAdScreen" component={CreateAdScreen} />
        <PrivateRoute
          path="/confirmationScreenBuy"
          component={() => <TransactionConfirmationScreen buy={true} />}
        />
        <PrivateRoute
          path="/confirmationScreenSell"
          component={() => <TransactionConfirmationScreen />}
        />
        <PrivateRoute
          path="/walletScreen"
          component={() => <WalletScreen isActivated={false} />}
        />
        <PrivateRoute
          path="/transactionProgressScreen"
          component={() => <TransactionProgressScreen buy />}
        />
        <PrivateRoute
          path="/walletDetailScreen"
          component={() => (
            <div className="w-5/6 mx-auto">
              <WalletDetailScreen />
            </div>
          )}
        />

        <PrivateRoute path="/reviewScreen" component={ReviewScreen} />

        <PrivateRoute path="/profileScreen" component={ProfileScreen} />
      </Router>
      <ConfirmGlobal />
    </div>
  );
};
