import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function AddNewCoin({onClick}) {
  return (
    <button onClick={onClick} className="border border-dashed border-gray-600 p-4 mt-2 text-gray-500 flex justify-center items-center w-full focus:outline-none hover:border-gray-400">
      <FontAwesomeIcon icon={faPlus} /> <h3 className="ml-2">Add Token</h3>
    </button>
  );
}

export default AddNewCoin;
