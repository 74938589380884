import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

function NetworkType({ onSelect, selected }) {
  const [visible, setVisible] = React.useState(false);
  const handleSelect = (type)=>{
      setVisible(false)
      onSelect(type)
  }
  return (
    <div
      className="relative"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className="text-white font-md text-md bg-primary px-4 py-3">
        {selected}
         <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
      </div>
      {visible && (
        <div className="absolute w-full cursor-pointer shadow bg-primaryLight p-1">
          {['ERC20', 'TRC20'].map(item => (
            <div onClick={()=>handleSelect(item)} className={`p-2 ${item===selected?'bg-primary':'bg-primaryLight'} hover:bg-primary`}>{item}</div>
          ))}
        </div>
      )}
    </div>
  );
}

export default NetworkType;
