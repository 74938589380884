import {
  STELLAR_URL,
  stellarNetworkPhrase,
  BASE_URL,
} from "utils/AppConstants";
import { getSelectedKeys, getSelectedSecret } from "./trannsactionServices";
import Axios from "axios";
import { getBtcPrice, getExchangeMessage, getPairCurrentPrice, getPairPrice } from "./genericServices";
import { getJWT } from "./httpServices";
import { SwapHistory } from "store/models/Swap";
import { store } from "App";
import { updateClosePrice } from "store/actions/MarketActions";
import moment from "moment";

const StellarSdk = require("stellar-sdk");
const server = new StellarSdk.Server(STELLAR_URL);

var orderBookStream = () => { };
var closePrevStream = () => { };
var closePrevGlobalStream = () => { };
var aggregateStream = () => { };
var myOffersStream = () => { };

const convertAsset = (asset) => {
  if (asset.code === "XLM") {
    return StellarSdk.Asset.native();
  } else {
    return new StellarSdk.Asset(asset.code, asset.issuer);
  }
};

const lookForTrades = (asset1, asset2, callback) => {
  closePrevStream();
  closePrevStream = server
    .trades()
    .forAssetPair(convertAsset(asset1), convertAsset(asset2))
    .cursor("now")
    .stream({
      onmessage: callback,
    });
};
export const lookForAllTrades = (asset1, asset2) => {
  closePrevGlobalStream();
  closePrevGlobalStream = server
    .trades()
    .forAssetPair(convertAsset(asset1), convertAsset(asset2))
    .cursor("now")
    .stream({
      onmessage: (element) => {
        let pr: any = (1 / (element.price.n / element.price.d)).toFixed(8);
        const base =
          element.base_asset_type === "native"
            ? "XLM"
            : element.base_asset_code;
        const counter =
          element.counter_asset_type === "native"
            ? "XLM"
            : element.counter_asset_code;
        store.dispatch(updateClosePrice(base, counter, pr));
      },
    });
};

const lookForOrders = (asset1, asset2, callback) => {
  orderBookStream();
  orderBookStream = server
    .orderbook(convertAsset(asset2), convertAsset(asset1))
    .cursor("now")
    .limit(9)
    .stream({
      onmessage: callback,
    });
};

export const lookForMyOffers = (callback) => {
  myOffersStream();
  myOffersStream = server
    .offers()
    .forAccount(getSelectedKeys().public)
    .cursor("now")
    .stream({
      onmessage: callback,
    });
};

const lookForAggregate = ({
  base,
  counter,
  startTime,
  endTime,
  resolution,
  offset,
  callback,
}) => {
  const baseAsset = convertAsset({ code: base.code, issuer: base.issuer });
  const counterAsset = convertAsset({
    code: counter.code,
    issuer: counter.issuer,
  });

  aggregateStream();
  aggregateStream = server
    .tradeAggregation(
      baseAsset,
      counterAsset,
      startTime,
      endTime,
      resolution,
      offset
    )
    .stream({ onmessage: callback });
};

const lookForSellOffers = ({ code, issuer }, callback) => {
  var es = server
    .offers()
    .selling(convertAsset({ code, issuer }))
    .cursor("now")
    .stream({
      onmessage: callback,
    });
};

const lookForBuyOffers = ({ code, issuer }, callback) => {
  var es = server
    .offers()
    .buying(convertAsset({ code, issuer }))
    .cursor("now")
    .stream({
      onmessage: callback,
    });
};

const getOffers = async (asset1, asset2) => {
  let offers = await server
    .offers()
    .forAccount(getSelectedKeys().public)
    .selling(convertAsset(asset1))
    .buying(convertAsset(asset1))
    .limit(200)
    .call();

  return offers.records;
};

const addBuyOffer = async ({
  asset1,
  asset2,
  amount,
  price,
  offerId,
  fee,
  feeAccount,
  memo,
}) => {
  return new Promise((resolve, reject) => {
    let myKeypairs = StellarSdk.Keypair.fromSecret(getSelectedSecret());
    let myAsset = convertAsset(asset1);
    let targetAsset = convertAsset(asset2);
    const convertedFee = fee / Number(price);
    const buyAmount = (Number(amount) - convertedFee).toFixed(7);

    server
      .loadAccount(myKeypairs.publicKey())
      .then((account) => {
        let transaction;
        if (fee > 0) {
          transaction = new StellarSdk.TransactionBuilder(account, {
            fee: "500",
            networkPassphrase: stellarNetworkPhrase,
          })
            .addOperation(
              StellarSdk.Operation.manageBuyOffer({
                selling: myAsset,
                buying: targetAsset,
                buyAmount: buyAmount,
                price: parseFloat(price),
                offerId: offerId,
              })
            )
            .addOperation(
              StellarSdk.Operation.payment({
                destination: feeAccount,
                asset: myAsset,
                amount: fee.toFixed(7),
              })
            )
            .setTimeout(100)
            .addMemo(StellarSdk.Memo.text(memo))
            .build();
        } else {
          transaction = new StellarSdk.TransactionBuilder(account, {
            fee: "500",
            networkPassphrase: stellarNetworkPhrase,
          })
            .addOperation(
              StellarSdk.Operation.manageBuyOffer({
                selling: myAsset,
                buying: targetAsset,
                buyAmount: amount,
                price: parseFloat(price),
                offerId: offerId,
              })
            )
            .setTimeout(100)
            .build();
        }

        transaction.sign(myKeypairs);
        return server.submitTransaction(transaction);
      })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e, e.response);
        reject(getExchangeMessage(e, "Error adding offer."));
      });
  });
};

// const getTradesAndVolumesGraphData = async (
//   asset1,
//   asset2,
//   startTime,
//   endTime,
//   resolution,
//   offset
// ) => {
//   const base = convertAsset(asset1);
//   const counter = convertAsset(asset2);

//   try {
//     const limit = 100;
//     const START_TIME = 1514764800; // 01/01/2018
//     const END_TIME = Date.now() + 86400000; // Current time + 1 day
//     const timeframe = startTime;
//     const response = await server
//       .tradeAggregation(base, counter, START_TIME, END_TIME, resolution, offset)
//       .limit(limit)
//       .order('desc')
//       .call();

//     const convertedTrades = converterOHLC.aggregationToOhlc([...response.records], timeframe);
//     // const convertedVolume = converterOHLC.getVolumeData(convertedTrades, data); // data is orderbook data

//     const tradesAndVolumes = {
//       [timeframe]: {
//         trades: convertedTrades,
//         // volumes: convertedVolume,
//         // nextTrades: response.next,
//       }
//     };
//     console.log('------------TRADES AND VOLUES----------', JSON.stringify(tradesAndVolumes))
//     return tradesAndVolumes;
//   } catch (error) {
//     console.log('---------------TRADES AND VOLUMES ERROR---------', error)
//     //console.log(error.response);
//     return { records: [] };
//   }
// };

const addSellOffer = async ({
  asset1,
  asset2,
  amount,
  price,
  offerId,
  fee,
  feeAccount,
  memo,
}) => {
  return new Promise((resolve, reject) => {
    var myKeypairs = StellarSdk.Keypair.fromSecret(getSelectedSecret());
    let myAsset = convertAsset(asset1);
    let targetAsset = convertAsset(asset2);
    const amountWithFee = (Number(amount) - fee).toFixed(7);
    server
      .loadAccount(myKeypairs.publicKey())
      .then((account) => {
        let transaction;
        if (fee > 0) {
          transaction = new StellarSdk.TransactionBuilder(account, {
            fee: "500",
            networkPassphrase: stellarNetworkPhrase,
          })
            .addOperation(
              StellarSdk.Operation.manageSellOffer({
                selling: targetAsset,
                buying: myAsset,
                amount: amountWithFee,
                price: parseFloat(price),
                offerId: offerId,
              })
            )
            .addOperation(
              StellarSdk.Operation.payment({
                destination: feeAccount,
                asset: targetAsset,
                amount: fee.toFixed(7),
              })
            )
            .setTimeout(100)
            .addMemo(StellarSdk.Memo.text(memo))
            .build();
        } else {
          transaction = new StellarSdk.TransactionBuilder(account, {
            fee: 100,
            networkPassphrase: stellarNetworkPhrase,
          })
            .addOperation(
              StellarSdk.Operation.manageSellOffer({
                selling: targetAsset,
                buying: myAsset,
                amount: amountWithFee,
                price: parseFloat(price),
                offerId: offerId,
              })
            )
            .setTimeout(100)
            .build();
        }

        transaction.sign(myKeypairs);
        return server.submitTransaction(transaction);
      })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        console.log(e.response);
        reject(getExchangeMessage(e, "Error adding offer."));
      });
  });
};

const getTrades = async (account) => {
  const trades = await server
    .trades()
    //.forAssetPair(convertAsset(asset1), convertAsset(asset2))
    .forAccount(account)
    .limit(200)
    .order("desc")
    .call();
  return trades;
};

export const testTrades = async (account, asset1, asset2) => {
  const offers = await server
    .offers()
    .forAccount(account)
    .selling(convertAsset(asset1))
    .buying(convertAsset(asset1))
    .limit(300)
    .call();
  console.log("my offers", offers);
};

const getAllOrders = async (asset1, asset2) => {
  let data = await server
    .orderbook(convertAsset(asset2), convertAsset(asset1))
    .limit(9)
    .call();
  return data;
};

const getAllTrades = async (asset1, asset2, limit) => {
  try {
    const data = await server
      .trades()
      .forAssetPair(convertAsset(asset1), convertAsset(asset2))
      .order("desc")
      .limit(limit)
      .call();
    return data;
  } catch (e) {
    console.log(e);
    return { records: [] };
  }
};

const convertAssetSwap = (asset) => {
  if (asset.asset_type === "native") {
    return StellarSdk.Asset.native();
  } else {
    return new StellarSdk.Asset(asset.asset_code, asset.asset_issuer);
  }
};

const sendStrictPathPayment = async ({
  sendAsset,
  sendAmount,
  destAsset,
  destMin,
  path,
  fee,
  feeAccount,
}) => {
  let pathNew: any = [];
  path.forEach((item) => {
    pathNew.push(convertAssetSwap(item));
  });

  const sending = convertAsset(sendAsset);
  const receiving = convertAsset(destAsset);
  const sourceKeypair = StellarSdk.Keypair.fromSecret(getSelectedSecret());
  const account = await server.loadAccount(sourceKeypair.publicKey());

  let transaction;
  if (fee > 0) {
    transaction = new StellarSdk.TransactionBuilder(account, {
      fee: "500",
      networkPassphrase: stellarNetworkPhrase,
    })
      .addOperation(
        StellarSdk.Operation.pathPaymentStrictSend({
          sendAsset: sending,
          sendAmount,
          destination: sourceKeypair.publicKey(),
          destAsset: receiving,
          destMin,
          path: pathNew,
        })
      )
      .addOperation(
        StellarSdk.Operation.payment({
          destination: feeAccount,
          asset: sending,
          amount: Number(fee).toFixed(7),
        })
      )
      .setTimeout(180)
      .build();
  } else {
    transaction = new StellarSdk.TransactionBuilder(account, {
      fee: "500",
      networkPassphrase: stellarNetworkPhrase,
    })
      .addOperation(
        StellarSdk.Operation.pathPaymentStrictSend({
          sendAsset: sending,
          sendAmount,
          destination: sourceKeypair.publicKey(),
          destAsset: receiving,
          destMin,
          path: pathNew,
        })
      )
      .setTimeout(180)
      .build();
  }

  transaction.sign(sourceKeypair);
  return server.submitTransaction(transaction);
};

const getMarketData = async (
  asset1,
  asset2,
  startTime,
  endTime,
  resolution,
  offset,
  limit,
  order
) => {
  const base = convertAsset(asset1);
  const counter = convertAsset(asset2);
  try {
    const response = await server
      .tradeAggregation(base, counter, startTime, endTime, resolution, offset)
      .limit(limit)
      .order(order)
      .call();
    return response;
  } catch (error) {
    return { records: [] };
  }
};
export const addFeeEntry = async (body) => {
  const token = await getJWT();
  const response = await Axios.post(
    BASE_URL + "/tradefeehistory/createNew",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};

export const setOfferId = async (body) => {
  const token = await getJWT();
  const response = await Axios.patch(
    BASE_URL + "/tradefeehistory/setOfferId",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};

export const refundExchangeFee = async (body) => {
  const token = await getJWT();
  const response = await Axios.post(
    BASE_URL + "/tradefeehistory/refundMyTradeFee",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};
export const saveSwapHistory = async (body: SwapHistory) => {
  const token = await getJWT();
  const response = await Axios.post(
    BASE_URL + "/swap/create",
    body,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return response;
};

export const getOfferById = (id) => {
  return server.offers().offer(id).call();
};

const issueAssets = async (stellerAsset: string, issuerAccount: string) => {
  // Keys for accounts to issue and receive the new asset
  // let issuingKeys = StellarSdk.Keypair.fromSecret(
  //   "SCECRJOKLXTM4EAK3J3N5UZ6YBS3U6XVPQN7ZS7NIDQZH7KRKBDVMTNH",
  // );
  let asset: any = null;
  let myAccount = StellarSdk.Keypair.fromSecret(getSelectedSecret());
  try {
    asset = await new StellarSdk.Asset(stellerAsset, issuerAccount);
  } catch (error) {
    return false;
  }
  // First, the receiving account must trust the asset
  return server
    .loadAccount(myAccount.publicKey())
    .then(function (receiver) {
      var transaction = new StellarSdk.TransactionBuilder(receiver, {
        fee: 500,
        networkPassphrase: stellarNetworkPhrase,
      })
        // The `changeTrust` operation creates (or alters) a trustline
        // The `limit` parameter below is optional
        .addOperation(
          StellarSdk.Operation.changeTrust({
            asset: asset,
          })
        )
        // setTimeout is required for a transaction
        .setTimeout(180)
        .build();
      transaction.sign(myAccount);
      return server.submitTransaction(transaction);
    })
    .then((res) => {
      return true;
    })
    .catch(function (error) {
      console.error("Error!", error);
      return false;
    });
};
export const getRateChange = (closePrev, closeNow) => {
  return ((closeNow - closePrev) / (closePrev)) * 100;
};

export const getCoinMarketPrice = async (nativeAsset, newAsset) => {
  let response = await getMarketData(
    newAsset,
    { code: "XLM" },
    moment().subtract(40, "days").unix() * 1000,
    Math.round(new Date().getTime()),
    86400000,
    0,
    50,
    "desc"
  );

  if (response.records && response.records[0] && nativeAsset) {
    const [{ close, open, high, low, counter_volume, avg }] = response.records;
    let btcRate: any = null;

    const currencyRate = getPairCurrentPrice({
      marketCoinPrice: nativeAsset.currentRate,
      toCoinPrice: Number(close),
    });
    newAsset.code == 'BTC' && newAsset.issuer == "GDSJL3S3ADQ33P6HGKSXIV3JRB7R7IFNLCKCNNIPSPSIULAHJBBPJWWE" ? btcRate = 1 :
      btcRate = getBtcPrice({
        toCoinPrice: nativeAsset.btcRate,
        marketCoinPrice: Number(close),
      });

    const highConverted = getPairPrice({
      marketCoinPrice: nativeAsset.high24h,
      toCoinPrice: Number(high),
    });

    const lowConverted = getPairPrice({
      marketCoinPrice: nativeAsset.low24h,
      toCoinPrice: Number(low),
    });

    // const openRate: any = getPairPrice({
    //   marketCoinPrice: nativeAsset.currentRate,
    //   toCoinPrice: Number(open),
    // });

    return {
      currencyRate,
      btcRate,
      volume: counter_volume,
      highConverted,
      lowConverted,
      change: Number(close) - Number(open),
      changePercent: getRateChange(Number(open), Number(close)),
    };
  }
  return null;
};

export {
  lookForSellOffers,
  lookForBuyOffers,
  lookForTrades,
  addBuyOffer,
  addSellOffer,
  getOffers,
  getTrades,
  getAllOrders,
  getAllTrades,
  lookForOrders,
  lookForAggregate,
  getMarketData,
  convertAsset,
  sendStrictPathPayment,
  issueAssets,
};
