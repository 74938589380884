import * as React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import HomeBanner from "./HomeBanner";

function OfferPopup() {
  const [isOpen, setOpen] = React.useState(true);

  const close = () => setOpen(false);

  return (
    <Modal isOpen={isOpen} style={customStyle}>
      <div
        className="rounded-full py-1 px-3 opacity-50 text-white absolute top-5 bg-black cursor-pointer"
        onClick={close}
        style={{ right: "10px", top: "15px" }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
      {/* <img src="assets/operational.jpg" style={{objectFit: 'fill', height: '100%'}} /> */}
      <HomeBanner />
    </Modal>
  );
}

export default OfferPopup;

const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    zIndex: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    padding: "0px",
    borderRadius: "15px",
    backgroundColor: "transparent",
    height: "90%",
    maxWidth: "540px",
    position: "absolute",
    border: "0 px",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    margin: "auto",
  },
};
