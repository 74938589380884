import { useState, useEffect } from "react";
import {
  sendBalance,
  sendAssets,
  isAccountFunded,
  sendBalanceToUnFundedAccount,
  getSelectedSecret,
} from "services/trannsactionServices";
import { useAlert } from "react-alert";
import { getPreferences } from "services/storageServices";
import { useDispatch } from "react-redux";
import {
  setCoinsAction,
  transactionsHistoryAction,
} from "store/actions/TransactionActions";
import { fetchFeeWithLevel } from "services/httpServices";
import { sendBalanceErrors } from "utils/constants";

let useSendPayment = (balance, asset) => {
  // debugger
  let alert = useAlert();
  let dispatch = useDispatch();
  let [inputs, setInputs] = useState({
    amount: "",
    address: "",
    memo: "",
    amountVal: "",
    memoVal: "",
    addressVal: "Please Enter Recipient's Address",
    fundVal: "",
    creating: false,
    transactionResponse: null,
    transactionFee: 0.00,
  });
  let [state, setState] = useState({
    submitting: false,
    isConfirmOpen: false,
    isTargetFunded: true,
    isUserAgreed: true,
    feeFixed: 0,
    feePercent: 0,
    feeAccount: "",
    memoIndex: 0,
  });

  let handleSubmit = (e) => {
    e.preventDefault();
    if (!validated()) {
      return;
    }

    sessionCheck();
  };

  const setMemoIndex = (index) =>
    setState((prev) => ({ ...prev, memoIndex: index }));

  let sessionCheck = () => {
    let prefrences = getPreferences();
    if (prefrences.PASSWORD === "Every transaction") {
      setState((prev) => ({ ...prev, isConfirmOpen: true }));
    } else {
      sendPayment();
    }
  };

  let sendPayment = () => {
    let isAnchored = asset[0] ? asset[0].isAssetAnchored : 0;
    if (isAnchored == 1) {
      sendAsset();
    } else {
      setState((prev) => ({ ...prev, submitting: true }));
      isAccountFunded(inputs.address).then((isFunded) => {
        if (!isFunded) {
          if (state.isTargetFunded) {
            setState((prev) => ({
              ...prev,
              isTargetFunded: false,
              isUserAgreed: false,
              submitting: false,
            }));
          } else if (state.isUserAgreed || isFunded) {
            sendLumensWithFundAccount();
          }
        } else {
          sendAsset();
        }
      });
    }
  };

  const sendLumensWithFundAccount = () => {
    setState((prev) => ({ ...prev, submitting: true }));
    const amount = Number(inputs.amount) - calculateFee();
    sendBalanceToUnFundedAccount({
      ...inputs,
      amount: amount,
      fee: calculateFee(),
      feeAccount: state.feeAccount,
      memoIndex: state.memoIndex,
      memo: inputs.memo,
    })
      .then((result) => {
        alert.removeAll();
        alert.show("Balance sent successfully!", { type: "success" });
        dispatch(setCoinsAction());
        setInputs((prev) => ({
          ...prev,
          amount: "",
          address: "",
          amountVal: "",
          addressVal: "",
        }));
        dispatch(transactionsHistoryAction(false));
      })
      .catch((e) => {
        alert.removeAll();
        alert.show(getSendMessage(e, "Error while sending balance."));
      })
      .finally(() => {
        setState((prev) => ({
          ...prev,
          submitting: false,
          isTargetFunded: true,
        }));
      });
  };

  const sendAsset = () => {
    setState((prev) => ({ ...prev, submitting: true }));
    const amount = inputs.transactionFee > 0 ? Number(inputs.amount) + calculateFee() : Number(inputs.amount) - calculateFee();
    // this check is used if there is transaction fee its true else its false
    let check = inputs.transactionFee > 0 ? true : false;
    sendAssets(
      getSelectedSecret(),
      inputs.address,
      amount.toFixed(6),
      asset[0],
      calculateFee() + inputs.transactionFee,
      state.feeAccount,
      state.memoIndex,
      inputs.memo,
      check
    )
      .then((result) => {
        alert.show("Balance sent successfully!", { type: "success" });
        dispatch(setCoinsAction());
        dispatch(transactionsHistoryAction(false));
        setInputs((prev) => ({
          ...prev,
          amount: "",
          address: "",
          amountVal: "",
          addressVal: "",
          creating: false,
          transactionResponse: result,
        }));
      })
      .catch((e) => {
        console.log(e.response);
        alert.show(getSendMessage(e, "Error while sending balance."), {
          type: "error",
        });
        setInputs((prev) => ({
          ...prev,
          creating: false,
        }));
      })
      .finally(() => {
        setState((prev) => ({ ...prev, submitting: false }));
      });
  };

  const getSendMessage = (e, defMsg) => {
    let message;
    if (e.customMessage) {
      return e.customMessage;
    }
    if (e.response?.status === 504) {
      return "Request timeout please try again";
    }
    try {
      message =
        sendBalanceErrors[
        e?.response?.data?.extras?.result_codes?.operations[0]
        ] || defMsg;
    } catch (err) {
      message = defMsg;
    }
    return message;
  };
  const getFee = () => {
    fetchFeeWithLevel(asset[0]?.short?.toUpperCase())
      .then((data) => {
        const { sendBalanceFeeFixed, sendBalanceFeePercent } =
          data.data.levelfee;

        setState((prev) => ({
          ...prev,
          feeAccount: data.data.assetFeeCollectionAddress,
          feeFixed: sendBalanceFeeFixed,
          feePercent: sendBalanceFeePercent,
        }));
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, loading: false }));
      });
  };
  useEffect(() => {
    getFee();
  }, []);

  const agree = () => {
    setState((prev) => ({ ...prev, isUserAgreed: !prev.isUserAgreed }));
    setInputs((prev) => ({ ...prev, fundVal: "" }));
  };

  const validated = () => {
    let res = true;
    let amount = parseFloat(inputs.amount);
    balance = parseFloat(balance);
    if (!amount) {
      res = false;
      setInputs((prev) => ({ ...prev, amountVal: "Amount can't be Empty." }));
    }
    if (amount > balance) {
      res = false;
      setInputs((prev) => ({
        ...prev,
        amountVal: "Amount can't be greater than your balance.",
      }));
    }
    if (amount <= 0) {
      res = false;
      setInputs((prev) => ({
        ...prev,
        amountVal: "Amount should be greater than 0.",
      }));
    }

    if (inputs.address == "") {
      res = false;
      setInputs((prev) => ({
        ...prev,
        addressVal: "Please Enter Recipient's Address",
      }));
    }
    if (state.memoIndex !== 0 && inputs.memo == "") {
      res = false;
      setInputs((prev) => ({
        ...prev,
        memoVal: "This field is required",
      }));
    }
    if (state.memoIndex === 1 && inputs.memo.length > 28) {
      res = false;
      setInputs((prev) => ({
        ...prev,
        memoVal: "Text can't be greater than 28 bytes",
      }));
    }
    const test = Number(inputs.memo);
    if (state.memoIndex === 2 && Object.is(test, NaN)) {
      res = false;
      setInputs((prev) => ({
        ...prev,
        memoVal: "Int64 as string is required",
      }));
    }
    if (state.memoIndex === 3 && inputs.memo.length > 32) {
      res = false;
      setInputs((prev) => ({
        ...prev,
        memoVal: "32 bytes hash value or hex encoded string is required",
      }));
    }

    if (state.submitting) {
      res = false;
    }
    if (!state.isUserAgreed) {
      res = false;
      setInputs((prev) => ({
        ...prev,
        fundVal: "You need to agree to proceed with sending balance.",
      }));
    }
    return res;
  };

  let closeModal = () =>
    setState((prev) => ({ ...prev, isConfirmOpen: false }));

  let handleInputChange = (e) => {
    let { name, value } = e.target;
    setInputs((prev) => ({
      ...prev,
      [name]:
        name === "address" || !value || name === "memo"
          ? value
          : parseFloat(value),
      [name + "Val"]: "",
    }));
  };

  const calculateFee = () => {
    if (inputs.amount === "" || inputs.amount == "0") return 0;
    const percent = (state.feePercent / 100) * Number(inputs.amount);
    return state.feeFixed + percent;
  };

  return {
    handleSubmit,
    handleInputChange,
    inputs,
    state,
    sendPayment,
    closeModal,
    agree,
    calculateFee,
    setMemoIndex,
    setInputs,
  };
};

export default useSendPayment;
