import * as React from 'react';
import { Button } from 'components/global';
import useFederation from './hooks/useFederation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { WalletCard } from 'components/molecular/Wallet/WalletCard';

export const WalletSettingsTab = () => {
  let { fedVal, domainVal, submit, handleInputChange, wallet, loading, loadingData } = useFederation();

  const [addWalletOpen, setAddWallet] = React.useState(false);
  return (
    <div className="w-full">

      <form onSubmit={submit} className="border border-primary mb-6 p-5 md:w-5/6 lg:w-5/6 xl:w-5/6 w-full">
      <WalletCard removeButtons={true} onCreateWallet={() => setAddWallet(true)} />
        <p className="text-white font-regular text-lg" style={{marginTop:'15px'}}>Wallet Settings</p>
        <div className="md:flex lg:flex xl:flex justify-between items-start">
          <div className="md:w-3/6 lg:w-3/6 xl:w-3/6 mr-1 mt-3">
            <text className="mt-4 text-xs text-gray-400">Wallet Name</text>
            <input type="text" placeholder="Edit your wallet name" value={wallet.name} onChange={handleInputChange} name="name" className="block appearance-none w-full font-regular text-xs bg-primary text-gray4 py-3 px-3 focus:outline-none mt-2" />
            <text className="mt-2 text-red">{fedVal}</text>
          </div>
          <div className="md:w-3/6 lg:w-3/6 xl:w-3/6 mr-1 mt-3">
            <text className="mt-4 text-xs text-gray-400">Home Domain</text>
            <input type="text" placeholder="Add home domain here" value={wallet.domain} onChange={handleInputChange} name="domain" className="block appearance-none w-full font-regular text-xs bg-primary text-gray4 py-3 px-3 focus:outline-none mt-2" />
            <text className="mt-2 text-red">{domainVal}</text>
          </div>
        </div>
        <div className="sm:w-3/6 mr-1 pb-6">
          <text className="mt-6 text-gray-400">New Federation Username</text>
          <input type="text" placeholder="Please Enter your Name" value={wallet.federationName} onChange={handleInputChange} name="federationName" className="block appearance-none w-full font-regular text-xs bg-primary text-gray4 py-3 px-3 focus:outline-none mt-2" />
          <text className="mt-2 text-secondaryGray text-xs">Federation Name: <text className="text-white">{`${wallet.federationName}*${wallet.domain}`}</text></text>
        </div>
        <div className="mt-5">
          <p className="text-secondaryGray text-xs font-regular">You can set federation names to make your public key more unique. </p>
          <p className="text-secondaryGray text-xs font-regular mt-2">Your federation names yourchoice*gwallet.tech can be used in receiving lumens or any anchored assets.</p>

          <p className="text-secondaryGray text-xs font-regular">You may find this function as a very helpful tool in certain transactions.</p>
        </div>
        <Button styles={["bg-accent text-xs p-4 mt-6 font-regular "]}>
          {loading ? <div><FontAwesomeIcon icon={faSyncAlt} color="#ffffff" spin /> Saving Changes...</div> : 'Save Changes'}
        </Button>
      </form>
    </div>
  );
}