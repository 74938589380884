
// Title
const smTitle = "text-2xl "
const mdTitle = "text-2xl"
const xlTitle = "text-3xl"
// =======SubTitle
const smSubTitle = "text-xs "
const mdSubTitle = "text-md "
const xlSubTitle = "text-md "


export const CustomAlign ={
    title : ` sm:${smTitle} md:${mdTitle} xl:${xlTitle} text-xl `,
    subTitle :  ` sm:${smSubTitle} md:${mdSubTitle} xl:${xlSubTitle} text-xxs `,
    color : (setColor:string) => ' text-'+setColor,
    textAlign : (textCenter:string) => ' text-'+textCenter+" ",
    
}