import React, { Fragment } from "react";
import { BuyPriceTable } from "./BuyPriceTable";
import { SellPriceTable } from "./SellPriceTable";
import useOrderBook from "screens/hooks/exchange/useOrderBook";
import Loader from "react-loader-spinner";
import OverallTrades from "./OverallTrades";
import DropdownExc from "components/atomic/DropdownExc";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

function BuySellPriceTable() {
  let {
    buyOrders,
    sellOrders,
    trades,
    ordersLoading,
    tradesLoading,
    tab,
    setTab,
    avgVal,
    prevAvgVal,
    selectedPair,
    setFractions,
    floatingPoints,
    handleSelect,
  } = useOrderBook();

  return (
    <div className="flex mb-2 w-full xl:w-4/5 lg:w-4/5">
      <div
        className="border border-primary w-full mr-2"
        style={{ height: "450px" }}
      >
        <div className="flex items-center pl-2 pr-2 pt-2 pb-2 bg-primary justify-between">
          <div className="flex">
            <button className="focus:outline-none" onClick={() => setTab(0)}>
              <img src="assets/Buy-Sell.svg" className="mr-1" />
            </button>
            <button className="focus:outline-none" onClick={() => setTab(1)}>
              <img src="assets/Buy.svg" className="mr-1" />
            </button>
            <button className="focus:outline-none" onClick={() => setTab(2)}>
              <img src="assets/Sell.svg" />
            </button>
          </div>
          {/* <DropdownExc onSelect={setFractions} value={floatingPoints} /> */}
        </div>

        {!ordersLoading && (
          <table style={tabStyle}>
            <thead>
              <tr className="text-white text-secondaryGray text-xs">
                <td className="pl-4">
                  Price ({selectedPair.marketCoinCode.toUpperCase()})
                </td>
                <td>Amount ({selectedPair.toCoinCode.toUpperCase()})</td>
                <td className="text-right pr-4">Total</td>
              </tr>
            </thead>
            {tab === 0 && (
              <BuyAndSellRows
                buyOrders={buyOrders}
                sellOrders={sellOrders}
                ordersLoading={ordersLoading}
                floatingPoints={floatingPoints}
                avg={avgVal}
                prevAvg={prevAvgVal}
                onSelect={handleSelect}
              />
            )}
            {tab === 1 && (
              <BuyRows
                buyOrders={buyOrders}
                floatingPoints={floatingPoints}
                ordersLoading={ordersLoading}
                onSelect={handleSelect}
              />
            )}
            {tab === 2 && (
              <SellRows
                sellOrders={sellOrders}
                floatingPoints={floatingPoints}
                ordersLoading={ordersLoading}
                onSelect={handleSelect}
              />
            )}
          </table>
        )}
        {ordersLoading && (
          <div className="flex justify-center items-center h-full">
            <Loader type="Bars" color="#FFFF00" height={30} width={30} />
          </div>
        )}
      </div>
      <OverallTrades data={trades} loading={tradesLoading} />
    </div>
  );
}

export default BuySellPriceTable;

function BuyAndSellRows({
  buyOrders,
  sellOrders,
  ordersLoading,
  floatingPoints,
  avg,
  prevAvg,
  onSelect,
}) {
  const { close, closePrev, lastPrice } = useSelector(
    (state: RootState) => state.exchange.selectedPair
  );
  const price = isNaN(lastPrice) ? "0.00000" : lastPrice;

  const convertVal = (value) => {
    if (value === "-") {
      return 0;
    }
    return value;
  };
  let incresed = convertVal(close) > convertVal(closePrev);
  let color = close === closePrev ? "white" : incresed ? "green" : "red";
  return (
    <Fragment>
      <SellPriceTable
        data={sellOrders}
        loading={ordersLoading}
        floatingPoints={floatingPoints}
        onSelect={onSelect}
      />
      <tr>
        <td colSpan={3}>
          <div className="w-full h-8 flex justify-center items-center border border-primary border-l-0 border-r-0">
            <div className={`flex items-center text-${color}`}>
              <h3 className="font-semibold text-base mr-2">
                {convertVal(close) === 0 ? price : convertVal(close)}
              </h3>
              {color !== "white" && (
                <img
                  src={`assets/${incresed ? "Arrowgreen" : "Arrowred"}.svg`}
                  className="mr-2 h-3"
                />
              )}
              {/* <h6 className="text-white mr-2">≈</h6>
              <h3 className="font-semibold text-base">$0</h3> */}
            </div>
          </div>
        </td>
      </tr>
      <BuyPriceTable
        data={buyOrders}
        loading={ordersLoading}
        floatingPoints={floatingPoints}
        onSelect={onSelect}
      />
    </Fragment>
  );
}

export function BuyRows({
  buyOrders,
  ordersLoading,
  floatingPoints,
  onSelect,
}) {
  return (
    <Fragment>
      <BuyPriceTable
        data={buyOrders}
        floatingPoints={floatingPoints}
        loading={ordersLoading}
        onSelect={onSelect}
      />
    </Fragment>
  );
}

function SellRows({ sellOrders, ordersLoading, floatingPoints, onSelect }) {
  return (
    <Fragment>
      <SellPriceTable
        data={sellOrders}
        floatingPoints={floatingPoints}
        loading={ordersLoading}
        onSelect={onSelect}
      />
    </Fragment>
  );
}

const tabStyle = { width: "100%" };
