import * as React from "react";
import { SellerDetails } from "components/molecular/P2P/AdvertisementForm/TransactionInitializationScreen/SellerDetails";
import { InProgressCard } from "components/molecular/P2P/AdvertisementForm/TransactionInitializationScreen/InProgressCard";
import Loader from "react-loader-spinner";
import useTradesProgress from "./hooks/P2P/useTradesProgress";
import { BASE_URL, tradeStatusEnum } from "utils/AppConstants";
import DisputeChat from "components/organisms/markets/P2P/DisputeChat";
import { getSelectedKeys } from "services/trannsactionServices";
import ConfirmDispute from "components/molecular/P2P/ConfirmDispute";
import { personProfile } from "assets/images/index";
import NetworkError from "components/atomic/p2p/NetworkError";
import TradeTabs from "components/atomic/p2p/TradeTabs";
import { paymentMethodsEnum } from "./hooks/P2P/useAdd";
import { TradeInfoChip } from "components/atomic/p2p/TradeInfoChip";
import { paymentTypeIconsEnum } from "utils/constants";

export const TransactionProgressScreen = (props: any) => {
  const {
    claimPaid,
    cancelling,
    claiming,
    loading,
    sendingBalance,
    cancelMyTrade,
    holdBalance,
    rating,
    disabledActions,
    trade: {
      p2pads,
      id,
      cryptoAmountFromSeller,
      cryptoAmountToBuyer,
      type,
      startTime,
      status,
      kyc,
      account,
      bank_name,
      bank_account_number,
      p2pdispute,
      sellerUSDRate,
      buyerUSDRate,
      bank_account_title,
      fiatTradeAmount,
      payoneer_email,
      skrill_email,
    },
    action,
    cancel,
    message,
    claimAmountNotRecivedSeller,
    claimAmountRecivedSeller,
    claimingReceived,
    disputingNotRec,
    onProfile,
    isConfirmOpen,
    closeDisputeConfirm,
    claimTradeExpiredSeller,
    disputingExpSeller,
    setTradeScreenType,
    tradeScreenType,
    setMessages,
    chatMessages,
    claimTradeExpiredBuyer,
    disputingExpBuyer,
    selectedAction,
    onUserAffirm,
  } = useTradesProgress();
  let kycOpposite = account === getSelectedKeys().public ? p2pads.kyc : kyc;
  const showEmail = p2pads.payment_method === paymentMethodsEnum.Payoneer || 
    p2pads.payment_method === paymentMethodsEnum.Skrill

  const getProgressCard = () => (
    <InProgressCard
      startTime={startTime}
      onCancel={cancelMyTrade}
      claimPaid={claimPaid}
      timeFrame={p2pads.timeFrame}
      cancelling={cancelling}
      claiming={claiming}
      sendingBalance={sendingBalance}
      holdBalance={holdBalance}
      status={status}
      action={action}
      claimAmountRecivedSeller={claimAmountRecivedSeller}
      claimAmountNotRecivedSeller={claimAmountNotRecivedSeller}
      claimingReceived={claimingReceived}
      cancel={cancel}
      message={message}
      disabledActions={disabledActions}
      claimTradeExpiredSeller={claimTradeExpiredSeller}
      disputingExpSeller={disputingExpSeller}
      claimTradeExpiredBuyer={claimTradeExpiredBuyer}
      disputingExpBuyer={disputingExpBuyer}
    />
  );
  const isBuyer =
    (type === "buy" ? account : p2pads.account) === getSelectedKeys().public
      ? true
      : false;

  if (loading) {
    return (
      <div
        className="flex justify-center items-center"
        style={{ height: "80vh" }}
      >
        <Loader type="Bars" color="#FFFF00" height={30} width={30} />
      </div>
    );
  }

  return (
    <div>
      {/* <NetworkError /> */}
      <div className="w-4/5 sm:w-4/6 mt-5 mb-5 mx-auto text-center">
        <p className="text-white font-regular text-center">
          You are {isBuyer ? "Buying" : "Selling"}
        </p>
        <p className="text-accent font-regular text-center mt-2">
          {Number(isBuyer ? cryptoAmountToBuyer : cryptoAmountFromSeller).toFixed(7)}
          <span className="ml-2">
            {p2pads.assetCode.toUpperCase()} {isBuyer ? " from" : " to"}{" "}
          </span>
        </p>

        <div className="border mb-8 mt-8 border-primary lg:flex w-full md:w-4/6 md:mx-auto lg:w-4/6 ld:mx-auto xl:w-4/6 xl:mx-auto">
          <div className="border-r border-primary py-5 px-12">
            <button onClick={onProfile} className="focus:outline-none">
              <SellerDetails
                sellername={
                  kycOpposite.isCompanyKyc
                    ? kycOpposite.companyName
                    : kycOpposite.firstName + " " + kycOpposite.lastName
                }
                image={
                  kycOpposite.photo
                    ? BASE_URL + "/files/" + kycOpposite.photo :personProfile
                }
                rating={rating}
              />
            </button>
          </div>
          {!showEmail && <div className="w-full">
            <TradeInfoChip 
              heading={`${isBuyer ? "Seller" : "Your"} Bank Name`} 
              value={type === "buy" ? p2pads.bank_name : bank_name} 
            />
            <TradeInfoChip 
              heading={`${isBuyer ? "Seller" : "Your"} Bank Account`} 
              value={type === "buy" ? p2pads.bank_account_number : bank_account_number} 
            />
            <TradeInfoChip 
              heading="Name of Bank Account's Holder"
              value={type === "buy" ? p2pads.bank_account_title : bank_account_title}
            />
            <TradeInfoChip 
              heading={`Total amount: (${Number(isBuyer ? buyerUSDRate : sellerUSDRate)?.toFixed(2)} ${p2pads.currency}/${p2pads.assetCode + "USD"})`}
              value={p2pads.currency + " " + Number(fiatTradeAmount)?.toFixed(2)}
            />
            
            <div className="w-full px-2 py-1 text-left">
              <label className="text-xs text-secondaryGray">
                Trade reference number
              </label>
              <h5 className="text-gray4 text-sm">{id}</h5>
            </div>
          </div>}
          {showEmail && <div className="w-full">
            <TradeInfoChip 
              heading="Payment Method"
              value={<img src={`/assets/${paymentTypeIconsEnum[p2pads.payment_method]}.svg`} className="w-16" />} 
            />
            <TradeInfoChip 
              heading={`${isBuyer ? "Seller" : "Your"} Email Address`} 
              value={p2pads.payment_method===paymentMethodsEnum.Payoneer?payoneer_email:skrill_email} 
            />
            <TradeInfoChip 
              heading={`Total amount: (${Number(isBuyer ? buyerUSDRate : sellerUSDRate)?.toFixed(2)} ${p2pads.currency}/${p2pads.assetCode + "USD"})`}
              value={p2pads.currency + " " + Number(fiatTradeAmount)?.toFixed(2)}
            />
            
            <div className="w-full px-2 py-1 text-left">
              <label className="text-xs text-secondaryGray">
                Trade reference number
              </label>
              <h5 className="text-gray4 text-sm">{id}</h5>
            </div>
          </div>}
        </div>
        <TradeTabs
          tabs={["Progress", "Chat"]}
          onSelect={setTradeScreenType}
          selected={tradeScreenType}
        />
        {status !== tradeStatusEnum.Completed &&
          tradeScreenType === 0 &&
          getProgressCard()}
        {tradeScreenType === 1 && (
          <DisputeChat
            tradeId={id}
            addAccount={p2pads?.account}
            tradeType={type}
            tradeAccount={account}
            claimAmountRecivedSeller={claimAmountRecivedSeller}
            claiming={claimingReceived}
            setMessages={setMessages}
            chatMessages={chatMessages}
            status={status}
          />
        )}
      </div>
      <ConfirmDispute
        isOpen={isConfirmOpen}
        close={closeDisputeConfirm}
        onSelect={onUserAffirm}
        action={selectedAction}
      />
    </div>
  );
};
