import { transactionSuccessfullImg } from 'assets/images';
import { SellerDetails } from 'components/molecular/P2P/AdvertisementForm/TransactionInitializationScreen/SellerDetails';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import StarRatingComponent from 'react-star-rating-component';
import { getSelectedKeys } from 'services/trannsactionServices';
import { theme } from 'styles/theme';
import { BASE_URL } from 'utils/AppConstants';
import { paymentMethodsEnum } from './hooks/P2P/useAdd';

function Reviewed({
  commentByBuyer,
  commentBySeller,
  ratingByBuyer,
  ratingBySeller,
}) {
  const location: any = useLocation();
  const tradeInfo = location.state?.tradeInfo;
  const isBuyer = location.state.type === 'Buyer';
  const kycOpposite =
    tradeInfo.account === getSelectedKeys().public
      ? tradeInfo.p2pads.kyc
      : tradeInfo.kyc;
  const kycMine =
    tradeInfo.account === getSelectedKeys().public
      ? tradeInfo.kyc
      : tradeInfo.p2pads.kyc;

  const [pHeadLeft, pValLeft, pHeadRight, pValRight] = useMemo(() => {
    if (tradeInfo.p2pads.payment_method === paymentMethodsEnum.Payoneer) {
      return [
        'Payment Method',
        paymentMethodsEnum.Payoneer,
        `${isBuyer ? 'Seller' : 'Your'} Email Address`,
        tradeInfo.payoneer_email,
      ];
    }
    if (tradeInfo.p2pads.payment_method === paymentMethodsEnum.Skrill) {
      return [
        'Payment Method',
        paymentMethodsEnum.Skrill,
        `${isBuyer ? 'Seller' : 'Your'} Email Address`,
        tradeInfo.skrill_email,
      ];
    }
    return [
      (isBuyer ? 'Seller' : 'Your') + ' Bank Name',
      tradeInfo.type === 'buy'
        ? tradeInfo.p2pads.bank_name
        : tradeInfo.bank_name,
      (isBuyer ? 'Seller' : 'Your') + ' Bank Account',
      tradeInfo.type === 'buy'
        ? tradeInfo.p2pads.bank_account_number
        : tradeInfo.bank_account_number,
    ];
  }, [tradeInfo]);

  const bankHolderName = useMemo(() => {
    if (
      tradeInfo.p2pads.payment_method === paymentMethodsEnum.Payoneer ||
      tradeInfo.p2pads.payment_method === paymentMethodsEnum.Skrill
    ) {
      return { heading: 'Location', value: 'International' };
    }
    return {
      heading: "Name of Bank Account's Holder",
      value:
        tradeInfo.type === 'buy'
          ? tradeInfo.p2pads.bank_account_title
          : tradeInfo.bank_account_title,
    };
  }, [tradeInfo]);

  return (
    <div className="w-4/5 sm:w-4/6 mt-5 mb-5 mx-auto">
      <p className="text-green text-h1 font-regular text-center">
        Successful Trading!
      </p>
      <img src={transactionSuccessfullImg} className="h-20 w-20 mt-4 m-auto" />

      <div className="sm:flex mt-12 justify-center">
        {ratingBySeller && (
          <div
            className="border border-primary p-5 mr-2"
            style={{ width: '300px' }}
          >
            <h2 className="text-white font-semibold">Seller Review</h2>
            <StarRatingComponent
              starColor={theme.color.accent}
              name=""
              value={ratingBySeller}
            />
            <p className="text-secondaryGray text-xs">{commentBySeller}</p>
          </div>
        )}
        {ratingByBuyer && (
          <div
            className="border border-primary p-5 sm:ml-2 "
            style={{ width: '300px' }}
          >
            <h2 className="text-white font-semibold">Buyer Review</h2>
            <StarRatingComponent
              starColor={theme.color.accent}
              name=""
              value={ratingByBuyer}
            />
            <p className="text-secondaryGray text-xs">{commentByBuyer}</p>
          </div>
        )}
      </div>

      <div className="mt-8 mb-5 mx-auto text-center">
        <p className="text-white font-regular text-center">Trade Summary</p>

        {/* sellername={kycOpposite.isCompanyKyc?kycOpposite.companyName:(kycOpposite.firstName+" "+kycOpposite.lastName)} */}
        <div className="border w-4/5 mb-8 mt-8 border-primary w-full sm:w-4/6 md:mx-auto ld:mx-auto xl:mx-auto">
          <DataRow
            leftHeading="Buyer"
            leftValue={
              isBuyer
                ? kycMine.isCompanyKyc
                  ? kycMine.companyName
                  : kycMine.firstName + ' ' + kycMine.lastName
                : kycOpposite.isCompanyKyc
                ? kycOpposite.companyName
                : kycOpposite.firstName + ' ' + kycOpposite.lastName
            }
            rightHeading="Seller"
            rightValue={
              isBuyer
                ? kycOpposite.isCompanyKyc
                  ? kycOpposite.companyName
                  : kycOpposite.firstName + ' ' + kycOpposite.lastName
                : kycMine.isCompanyKyc
                ? kycMine.companyName
                : kycMine.firstName + ' ' + kycMine.lastName
            }
          />
          <DataRow
            leftHeading={(isBuyer ? 'Sold' : 'Bought') + ' Amount'}
            leftValue={
              (isBuyer
                ? tradeInfo.cryptoAmountToBuyer
                : tradeInfo.cryptoAmountFromSeller) +
              ' ' +
              tradeInfo.p2pads.assetCode?.toUpperCase()
            }
            rightHeading={
              'Total amount: (' +
              Number(
                isBuyer ? tradeInfo.buyerUSDRate : tradeInfo.sellerUSDRate,
              )?.toFixed(2) +
              ' ' +
              tradeInfo.p2pads.currency +
              '/' +
              tradeInfo.p2pads.assetCode +
              'USD)'
            }
            rightValue={
              tradeInfo.p2pads.currency +
              ' ' +
              Number(tradeInfo.fiatTradeAmount)?.toFixed(2)
            }
          />
          <DataRow
            leftHeading={pHeadLeft}
            leftValue={pValLeft}
            rightHeading={pHeadRight}
            rightValue={pValRight}
          />
          <DataRow
            leftHeading={bankHolderName.heading}
            leftValue={bankHolderName.value}
            rightHeading="Trade reference number"
            rightValue={tradeInfo.id}
          />
        </div>
      </div>
    </div>
  );
}

export default Reviewed;

const DataRow = ({ leftHeading, leftValue, rightHeading, rightValue }) => {
  return (
    <div className="flex">
      <div className="w-full border-b border-primary px-2 py-1 text-left">
        <label className="text-xs text-secondaryGray">{leftHeading}</label>
        <h5 className="text-gray4 text-sm">{leftValue}</h5>
      </div>
      <div className="w-full px-2 border-b border-l border-primary py-1 text-left">
        <label className="text-xs text-secondaryGray">{rightHeading}</label>
        <h5 className="text-gray4 text-sm">{rightValue}</h5>
      </div>
    </div>
  );
};
