import React, { useState, useEffect } from "react";
import {
  addBuyAdd,
  addSellAdd,
  fetchP2pAddFee,
  fetchP2pBank,
  fetchP2pFee,
} from "services/p2pServives";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useHistory, useLocation } from "react-router-dom";
import { profileStatus, validateEmail } from "utils/constants";
import { getMyCountry } from "services/httpServices";
import { BASE_URL, isTestNet, MIN_BALANCE_COIN } from "utils/AppConstants";
import Axios from "axios";
export enum paymentMethodsEnum {
  Local_Bank = "Local_Bank",
  Payoneer = 'Payoneer',
  Skrill = 'Skrill',
}
export const paymentMethodsTextEnum = {
  "Bank transfer": "Local Bank Transfer",
  "Bank Transfer": "Local Bank Transfer",
  "Local_Bank": "Local Bank Transfer",
  "Payoneer": 'Payoneer',
  "Skrill": 'Skrill',
}

function useAdd(buy) {
  const location = useLocation();
  const history = useHistory();
  const banks = ["United Bank Limited", "Swiss Bank"];
  const paymentMethods = [
    {text:'Local Bank Transfer', value:paymentMethodsEnum.Local_Bank},
    {text:'Payoneer', value: paymentMethodsEnum.Payoneer},
    {text:'Skrill',value:paymentMethodsEnum.Skrill}
  ];
  const { asset }: any = location.state;
  const {
    profile: { user },
    auth,
    transaction: { coins },
    generic: { myCountry, activeCountries },
    assets: { assets },
  } = useSelector((state: RootState) => state);

  const [state, setState] = useState({
    assetCode: asset,
    assetRate: 0,
    priceInCurrency: 0,
    priceWithFee: 0,
    currency: "",
    typeIndex: 0,
    unitPrice: "",
    lowerLimit: 0,
    upperLimit: 0,
    unitPriceMinMax: 0,
    total_amount: "",
    minQty: "",
    maxQty: "",
    minLimit: 0,
    balance: 0,
    maxLimit: 0,
    timeFrame: 15,
    location: myCountry.code || "US",
    accountTitle: (user.isCompanyKyc?user.companyName:user.firstName+" "+user.lastName),
    payment_method: paymentMethodsEnum.Local_Bank,
    bank_name: "",
    bank_account_number: "",
    bankConfirmed: true,
    bankConfirmVal: "",
    bitStampRate: 0,
    loadingRates: true,
    minBatteryAmount: 0,
    phone_number: "",
    submitting: false,
    fee: 0,
    email: "",
    emailVal: "",
    unitPriceVal: "",
    unitPriceMinMaxVal: "",
    minQtyVal: "",
    maxQtyVal: "",
    currencyVal: "",
    balanceVal: "",
    accountTitleVal: "",
    total_amountVal: "",
    locationVal: "",
    bank_nameVal: "",
    bank_account_numberVal: "",
    phone_numberVAl: "",
  });

  const alert = useAlert();
  const setAddType = (index) =>
    setState((prev) => ({ ...prev, typeIndex: index }));
  const bankInfo = () =>
    setState((prev) => ({ ...prev, bankConfirmed: !prev.bankConfirmed }));

  const submit = () => {
    if (!validated()) return;
    if (buy) {
      addAddBuy();
    } else {
      addAddSell();
    }
  };

  const getP2pFee = async () => {
    setState((prev) => ({ ...prev, loadingRates: true }));
    try {
      const data = await fetchP2pAddFee(state.assetCode);
      const currentRate = await getCurrencyRates(state.assetCode);
      const balance = coins.find(
        (item) => item.short.toUpperCase() === state.assetCode?.toUpperCase()
      );
      if (data.status === 200) {
        const feePercent = data.data.fee;
        let totalAmount = 50 / currentRate.currentRate;
        let minAmount = 10 / currentRate.currentRate;
        if (totalAmount > Number(balance?.balance || 0) && !buy) {
          totalAmount = Number(balance?.balance || 0);
        }
        if (minAmount > totalAmount && !buy) {
          minAmount = totalAmount;
        }
        
        const countryCode = state.payment_method===paymentMethodsEnum.Payoneer ||
          state.payment_method===paymentMethodsEnum.Skrill? "US" : myCountry.code
        const country = activeCountries.find(
          (country) => country.code === countryCode
        );

        if (country) {
          const totalPrice = Number(
            (Number(currentRate.currentRate) * Number(country.Price)).toFixed(2)
          );

          const fee = calculateFee(feePercent, totalPrice);
          const priceWithFee = Number(
            (buy ? totalPrice - fee : totalPrice + fee).toFixed(5)
          );
          const percent = Number(country?.Price) * (8 / 100);
          const minVal = Number(country?.Price) - percent;
          const maxVal = Number(country?.Price) + percent;

          setState((prev) => {
            return {
              ...prev,
              currency: country?.currency,
              unitPrice: country?.Price.toFixed(2),
              lowerLimit: minVal,
              upperLimit: maxVal,
              priceInCurrency: totalPrice,
              assetRate: currentRate.currentRate,
              priceWithFee: priceWithFee,
              unitPriceMinMax: priceWithFee,
              loadingRates: false,
              fee: fee,
              bitStampRate: currentRate.currentRate,
              total_amount: totalAmount.toFixed(7),
              maxQty: totalAmount.toFixed(7),
              minQty: minAmount.toFixed(7),
              minLimit: 10 / currentRate.currentRate,
              maxLimit: 50 / currentRate.currentRate,
              balance: Number(balance?.balance) || 0,
              balanceVal: "",
            };
          });
        }
      }
    } catch (e) {
      // console.log(e?.response);
    }
  };
  const calculateFee = (feePercent, price) => {
    const percent = (feePercent / 100) * Number(price);
    return percent;
  };

  useEffect(() => {
    getP2pFee();
  }, [state.assetCode, state.payment_method]);

  useEffect(() => {
    if(state.bitStampRate === 0) return
    setState((prev) => {
      const priceWithFee = Number(
        (buy
          ? prev.priceInCurrency - prev.fee
          : prev.priceInCurrency + prev.fee
        ).toFixed(5)
      );
      const balance = coins.find(
        (item) => item.short.toUpperCase() === state.assetCode?.toUpperCase()
      );

      let totalAmount = 50 / state.bitStampRate;
      let minAmount = 10 / state.bitStampRate;
      if (totalAmount > Number(balance?.balance || 0) && !buy) {
        totalAmount = Number(balance?.balance || 0);
      }
      if (minAmount > totalAmount && !buy) {
        minAmount = totalAmount;
      }
      return {
        ...prev,
        priceWithFee: priceWithFee,
        total_amount: totalAmount.toFixed(7),
        maxQty: totalAmount.toFixed(7),
        minQty: minAmount.toFixed(7),
      };
    });
  }, [buy]);

  useEffect(() => {
    //initialize();
    getCurrencyRates(MIN_BALANCE_COIN)
      .then((data) => {
        const balance = coins.find(
          (item) => item.short.toUpperCase() === MIN_BALANCE_COIN
        );
        const result = data?.currentRate * Number(balance?.balance || 0);
        setState((prev) => ({ ...prev, minBatteryAmount: result }));
      })
      .catch((e) => {
        // console.log(e);
      });
  }, []);

  const getCurrencyRates = async (asset) => {
    const response = await Axios.get(
      BASE_URL + "/coins/getAssets?currencyCode=USD"
    );

    const assetRates = response.data.find(
      (item) => item.short.toUpperCase() === asset
    );
    return assetRates;
  };

  //for fetching bank info if not manually
  // const initialize = () => {
  //   fetchP2pBank()
  //     .then((data) => {

  //       const { bankName } = data.data;
  //       if (bankName) {
  //         setState((prev) => ({
  //           ...prev,
  //           bank_name: bankName,
  //           bank_account_number: data.data.accountNumber,
  //           accountTitle: data.data.accountTitle,
  //         }));
  //       }
  //     })
  //     .catch((e) => {

  //       console.log(e);
  //     });
  // };

  const addAddBuy = () => {
    setState((prev) => ({ ...prev, submitting: true }));

    addBuyAdd({
      assetCode: state.assetCode,
      currency: state.currency,
      unitPrice: state.priceWithFee,
      total_amount: parseFloat("" + state.total_amount),
      minQty: parseFloat("" + state.minQty),
      maxQty: parseFloat("" + state.maxQty),
      timeFrame: parseFloat("" + state.timeFrame),
      location: state.location,
      payment_method: state.payment_method,
      bank_name: state.bank_name,
      buyerUSDRate: Number(state.unitPrice),
      isLocal: state.payment_method===paymentMethodsEnum.Local_Bank,
      payoneer_email: state.payment_method===paymentMethodsEnum.Payoneer?state.email:"",
      skrill_email: state.payment_method===paymentMethodsEnum.Skrill?state.email:"",
    })
      .then((data) => {
        console.log(data);
        alert.show("Success", { type: "success" });
        history.replace("/p2p");
      })
      .catch((e) => {
        console.log(e.response);
        alert.show(e.response?.data?.Error || "Error", { type: "error" });
      })
      .finally(() => {
        setState((prev) => ({ ...prev, submitting: false }));
      });
  };

  const addAddSell = () => {
    setState((prev) => ({ ...prev, submitting: true }));

    addSellAdd({
      assetCode: state.assetCode,
      currency: state.currency,
      unitPrice: state.unitPrice,
      total_amount: Number(state.total_amount),
      minQty: Number(state.minQty),
      maxQty: Number(state.maxQty),
      timeFrame: Number(state.timeFrame),
      location: state.location,
      payment_method: state.payment_method,
      bank_name: state.bank_name,
      bank_account_number: state.bank_account_number,
      //accountTitle: state.accountTitle,
      bank_account_title: state.accountTitle,
      sellerUSDRate: Number(state.unitPrice),
      isLocal: state.payment_method===paymentMethodsEnum.Local_Bank,
      payoneer_email: state.payment_method===paymentMethodsEnum.Payoneer?state.email:"",
      skrill_email: state.payment_method===paymentMethodsEnum.Skrill?state.email:"",
    })
      .then((data) => {
        alert.show("Success", { type: "success" });
        history.replace("/p2p");
      })
      .catch((e) => {
        console.log(e.response);
        alert.show(e.response?.data?.Error || "Error", { type: "error" });
      })
      .finally(() => {
        setState((prev) => ({ ...prev, submitting: false }));
      });
  };

  const isTrusted = () => {
    let coin = coins.find(
      (coin) => coin.short.toUpperCase() === state.assetCode
    );
    if (coin) {
      return true;
    } else {
      return false;
    }
  };
  const validated = () => {
    let response = true;
    if(state.submitting){
      return false
    }
    if (state.unitPrice === "") {
      setState((prev) => ({ ...prev, unitPriceVal: "This field is required" }));
      response = false;
    }
    if (
      Number(state.unitPrice) > state.upperLimit ||
      Number(state.unitPrice) < state.lowerLimit
    ) {
      setState((prev) => ({
        ...prev,
        unitPriceVal: `Price must be between ${state.lowerLimit?.toFixed(
          2
        )} and ${state.upperLimit?.toFixed(2)}`,
      }));
      response = false;
    }
    if (state.minQty === "" && state.typeIndex === 1) {
      setState((prev) => ({ ...prev, minQtyVal: "This field is required" }));
      response = false;
    }
    if (state.maxQty === "" && state.typeIndex === 1) {
      setState((prev) => ({ ...prev, maxQtyVal: "This field is required" }));
      response = false;
    }
    if (state.location === "" && state.typeIndex === 1) {
      setState((prev) => ({ ...prev, locationVal: "This field is required" }));
      response = false;
    }

    if (!buy && state.balance === 0) {
      setState((prev) => ({
        ...prev,
        balanceVal: "You do not have enough balance.",
      }));
      response = false;
    }
    // if (!state.bankConfirmed) {
    //   setState((prev) => ({
    //     ...prev,
    //     bankConfirmVal: "You need to check this field",
    //   }));
    //   response = false;
    // }
    if (Number(state.minQty) > Number(state.maxQty)) {
      setState((prev) => ({
        ...prev,
        maxQtyVal: "Maximum amount can't be smaller than minimum amount",
        minQtyVal: "Minimum amount can't be greater than maximum amount",
      }));
      response = false;
    }
    if (Number(state.total_amount) < Number(state.maxQty)) {
      setState((prev) => ({
        ...prev,
        total_amountVal: "Total amount can't be smaller than maximum amount",
      }));
      response = false;
    }

    if(state.payment_method === paymentMethodsEnum.Local_Bank){
      if (state.bank_name === "") {
        setState((prev) => ({
          ...prev,
          bank_nameVal: "Bank name is required",
        }));
        response = false;
      }
      if (!buy && state.bank_account_number === "") {
        setState((prev) => ({
          ...prev,
          bank_account_numberVal: "Bank account number is required",
        }));
        response = false;
      }
      if (!buy && state.accountTitle === "") {
        setState((prev) => ({
          ...prev,
          accountTitleVal: "Bank account title is required",
        }));
        response = false;
      }
    } else {
      if(!buy) {
        if(!validateEmail(state.email)){
          setState((prev) => ({
            ...prev,
            emailVal: "Please enter a valid email address",
          }));
          response = false;
        }
        if (state.email === "") {
          setState((prev) => ({
            ...prev,
            emailVal: "Please enter your email address",
          }));
          response = false;
        }
      }
    }
    
    if(!isTestNet){
    if (!state.minBatteryAmount || state.minBatteryAmount < 5) {
      response = false;
      alert.show(
        "You need to have USD5 value of LEVELG token as the battery. Your LEVELG will not be deducted for any reason."
      );
    }
  }

    if (!buy && Number(state.minQty) < Number(state.minLimit.toFixed(7))) {
      response = false;
      alert.show(
        `Min amount should be at least 10 USD worth of ${state.assetCode} to create add.`
      );
    }
    if (
      user.status !== profileStatus.approved ||
      !auth.password
    ) {
      response = false;
      if (!auth.password) {
        alert.show("You are not logged in.");
      } else {
        alert.show("Kindly submit your KYC application.");
      }
    } else if (!isTrusted()) {
      response = false;
      alert.show("You need to trust this asset.");
    }
    return response;
  };

  const SelectCoinName = (name) => {
    setState((prev) => ({ ...prev, assetCode: name }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if(name==="accountTitle") return
    if (name === "unitPrice") {
      setState((prev) => {
        const priceWithFee = buy
          ? Number((prev.assetRate * value - prev.fee).toFixed(2))
          : Number((prev.assetRate * value + prev.fee).toFixed(2));

        const notVal =
          Number(value) > prev.upperLimit || Number(value) < prev.lowerLimit;
        return {
          ...prev,
          unitPrice: value,
          [name + "Val"]: "",
          priceInCurrency: Number((prev.assetRate * value).toFixed(5)),
          priceWithFee: priceWithFee,
          unitPriceMinMax: priceWithFee,
          unitPriceVal: notVal
            ? `Price must be between ${prev.lowerLimit?.toFixed(
                2
              )} and ${prev.upperLimit?.toFixed(2)}`
            : "",
        };
      });
      return;
    }
    setState((prev) => ({ ...prev, [name]: value, [name + "Val"]: "" }));
  };

  return {
    ...state,
    submit,
    handleInputChange,
    banks,
    paymentMethods,
    SelectCoinName,
    setAddType,
    bankInfo,
  };
}

export default useAdd;
