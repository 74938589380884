import { useEffect, useState } from "react";
import { getPreferences, setPrefrences } from "services/storageServices";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import {useAlert} from 'react-alert'
import { updateCoinsAction } from "store/actions/TransactionActions";

const useSettings = () => {
  let [feeValue, setFeeValue] = useState("Low");
  let [selectedCurrency, setSelectedCurrency] = useState();
  let [sound, setSound] = useState(false);
  let [showMemo, setShowMemo] = useState(false);
  let generic = useSelector((state: RootState) => state.generic);
  let dispatch = useDispatch();
  let alert = useAlert();

  let getPrefrences = () => {
    let prefrences = getPreferences();
    if (prefrences.FEE) {
      setFeeValue(prefrences.FEE);
    }
    if (prefrences.CURRENCY) {
      setSelectedCurrency(prefrences.CURRENCY);
    }
    if (prefrences.NOTIFICATION_SOUND) {
      setSound(prefrences.NOTIFICATION_SOUND);
    }
    if (prefrences.SHOW_MEMO) {
        setShowMemo(prefrences.SHOW_MEMO);
    }
  };

  useEffect(() => {
    getPrefrences();
  }, []);

  let setFee = (e) => {
    let { value } = e.target;
    let prefrences = getPreferences();
    let newPrefrences = { ...prefrences, FEE: value };
    setFeeValue(value);
    setPrefrences(newPrefrences);
  };

  let setCurrency = (e) => {
    let { value } = e.target;
    let prefrences = getPreferences();
    let newPrefrences = { ...prefrences, CURRENCY: value };
    setPrefrences(newPrefrences);
    dispatch(updateCoinsAction());
  };

  let setMemo = (e) => {
    let prefrences = getPreferences();
    let newPrefrences = { ...prefrences, SHOW_MEMO: !prefrences.SHOW_MEMO };
    setShowMemo(!showMemo);
    setPrefrences(newPrefrences);
  };

  let getCurrencies = () => {
    let names: string[] = [];
    generic.currencies.forEach((item) => {
      names.push(item.code);
    });
    return names;
  };

  let saveSound = (e) => {
    let { value } = e.target;

    let prefrences = getPreferences();
    let newPrefrences = {
      ...prefrences,
      NOTIFICATION_SOUND: !prefrences.NOTIFICATION_SOUND,
    };
    setSound(!sound);
    setPrefrences(newPrefrences);
  };

  let submit =()=>{
    alert.removeAll();
    alert.show("Saved changes", {type: 'success'});
  }

  return {
    feeValue,
    setFee,
    setCurrency,
    getCurrencies,
    selectedCurrency,
    sound,
    saveSound,
    showMemo,
    setMemo,
    submit
  };
};

export default useSettings;
