import React, { useEffect } from "react";
import { BuyOfferRow } from "components/organisms/Exchange/BuyPriceTable";
import { RootState } from "store/reducers";
import { useSelector } from "react-redux";
import { SellOfferRow } from "components/organisms/Exchange/SellPriceTable";
import Loader from "react-loader-spinner";
import DeleteOffer from "components/atomic/Exchange/DeleteOffer";

function MyOffers({
  data,
  getOffers,
  loading,
  height,
  my,
  showHeader,
  onOfferDelete,
  deleteAllOffers
}) {
  let exchange = useSelector((state: RootState) => state.exchange);
  useEffect(() => {
    getOffers(data.length > 0?false:true);
  }, []);

  const setFps = (value) => {
    if(value && value !== "" && value !== "-"){
      return Number(value).toFixed(Number(value)>999?2:7)
    } else{ 
      return value
    }  
  }

  return (
    <div>
      {showHeader && (
        <div className="flex items-center pl-2 pr-2 pt-2 pb-2 text-left border-b border-primary justify-between">
          <p className="text-gray4 text-xs w-full">
            Price {"("+exchange.selectedPair.marketCoinCode.toUpperCase()+")"}
          </p>
          <p className="text-gray4 text-xs w-full">
            Amount {"("+exchange.selectedPair.toCoinCode.toUpperCase()+")"}
          </p>
          <p className="text-gray4 text-xs w-full">Total</p>
          {my && <button onClick={deleteAllOffers} className="text-gray4 text-xs w-full text-right">Cancel</button>}
        </div>
      )}
      <div className="nobar" style={{ height: height, overflowY: "auto" }}>
        {!loading&&data.map((item: any, index) => {
          return item.buy ? (
            <div key={index} className="flex justify-between relative items-center pl-2 pr-2 text-left">
              <p className="text-green text-xs w-full">{setFps(item.price)}</p>

              <p className="text-gray4 text-xs w-full">{setFps(item.amount)}</p>

              <p className="text-gray4 text-xs w-full">{setFps(item.total)}</p>
              {my && <DeleteOffer onOfferDelete={onOfferDelete} selectedOffer={item} />}
            </div>
          ) : (
            <div key={index} className="flex justify-between items-center relative pl-2 pr-2 text-left">
              <p className="text-red text-xs w-full">{setFps(item.price)}</p>

              <p className="text-gray4 text-xs w-full">{setFps(item.amount)}</p>

              <p className="text-gray4 text-xs w-full">{setFps(item.total)}</p>
              {my && <DeleteOffer onOfferDelete={onOfferDelete} selectedOffer={item} />}
            </div>
          );
        })}
        {loading && (
          <div className="flex justify-center items-center h-full">
            <Loader type="Bars" color="#FFFF00" height={30} width={30} />
          </div>
        )}
        {!loading && data.length < 1 && (
          <div className="flex justify-center items-center h-full">
            <small className="text-gray4 text-xs">No Trades</small>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyOffers;

let background = { backgroundColor: "rgba(0, 0, 0, 0.5)" };
