import { createChart } from "lightweight-charts";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMarketData } from "services/exchange";
import { RootState } from "store/reducers";
import { exchangeChart } from "utils/exchangeChartData";

const useAboutChart = (coin, issuer) => {
  const { assets: { assets }, market: { markets } } = useSelector((state: RootState) => state);

  const [state, setState] = useState({
    time: "",
    price: 0,
    data: []
  });

  useEffect(() => {
    loadMarketData();
  }, []);
  useEffect(() => {
    if (state.data.length > 0) {
      let newData: any = [...state.data]

      if (markets.length) {
        const baseIndex = markets.findIndex(item => item.name === 'USDT')
        if (baseIndex !== -1) {
          const counterIndex = markets[baseIndex].data.findIndex(item => item.counter === coin.toUpperCase() && item.counterIssuer == issuer)
          if (counterIndex !== -1) {
            newData[newData.length - 1].value = markets[baseIndex].data[counterIndex].close
          }
        }
      }

      drawGraph(newData)
    }
  }, [state.data, markets])

  const drawGraph = (graphData) => {
    const chartDiv: any = document.getElementById("myAssetChart") || ""
    if (chartDiv) chartDiv.innerHTML = "";
    var chart = createChart(chartDiv, {

      rightPriceScale: {
        scaleMargins: {
          top: 0.3,
          bottom: 0.25,
        },
        borderVisible: false,
      },
      layout: {
        backgroundColor: '#131722',
        textColor: '#d1d4dc',
      },
      grid: {
        vertLines: {
          color: 'rgba(42, 46, 57, 0)',
        },
        horzLines: {
          color: 'rgba(42, 46, 57, 0.6)',
        },
      },
    });
    chart.timeScale().fitContent();
    var areaSeries = chart.addAreaSeries({
      topColor: 'rgba(38,198,218, 0.56)',
      bottomColor: 'rgba(38,198,218, 0.04)',
      lineColor: 'rgba(38,198,218, 1)',
      lineWidth: 2,
    });

    areaSeries.setData(graphData);
    function businessDayToString(businessDay) {
      return businessDay.year + '-' + businessDay.month + '-' + businessDay.day;
    }

    var toolTip = document.createElement('div');
    toolTip.className = 'floating-tooltip-2';
    chartDiv.appendChild(toolTip);

    // update tooltip
    chart.subscribeCrosshairMove(function (param) {
      if (param.point === undefined || !param.time || param.point.x < 0 || param.point.y < 0) { } else {
        const dateStr = businessDayToString(param.time);
        const price: any = param.seriesPrices.get(areaSeries);
        setState(prev => ({ ...prev, time: dateStr, price: price }))
      }
    });
  }

  const loadMarketData = () => {
    const base = assets.find((asset) => asset.short.toUpperCase() === "USDT");
    const counter = assets.find(
      (asset: any) => asset.short.toUpperCase() === coin?.toUpperCase() &&
        asset?.issuer == issuer
    );
    if (!base || !counter) return;
    let baseAsset = {
      code: "XLM",
      issuer: base.issuer,
    };
    let counterAsset = {
      code: counter.short.toUpperCase(),
      issuer: counter.issuer,
    };

    getMarketData(
      counterAsset,
      baseAsset,
      moment().subtract(30, "days").unix() * 1000,
      Math.round(new Date().getTime()),
      /*3600000*/ 86400000,
      0,
      100,
      'asc'
    )
      .then((data) => {
        let graphData: any = [];

        data.records.forEach((item) => {
          graphData.push({ time: moment(new Date(Number(item.timestamp))).format('YYYY-MM-DD'), value: Number(item.close) })
        });

        setState(prev => ({ ...prev, data: graphData }))
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  return { ...state };
};

export default useAboutChart;
