import React from "react";

export const SelectOption = (props: any) => {
  const data = props.data;
  const opt = data.map((item, index) => (
    <option selected={props.value === item} key={index} value={item}>
      {item}
    </option>
  ));
  return (
    <div>
      <p className="text-gray4 mt-4 mb-2 font-regular text-xs">{props.label}</p>

      <div className="relative">
        <select
          onChange={props.onChange}
          name={props.name}
          className={`block appearance-none focus:outline-none w-full font-regular text-xs ${props.background?props.background:"bg-primary"} text-gray4 py-3 px-3 focus:outline-none`}
          style={{textIndent: "10px"}}
        >
          {/* <option>{props.data}</option> */}
          {opt}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 py-4 text-white">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
};