import React, { Fragment, useEffect, useState } from "react";
import { TradeForm } from "components/molecular/Exchange/TradeForm";
import NoAuthOverlay from "components/atomic/NoAuthOverlay";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { closeButton } from "assets/images";

function MakeOffer() {
  const [tab, setTab] = useState(0);
  const { auth } = useSelector((state: RootState) => state);
  const [state, setState] = useState({
    isMobile: window.innerWidth < 601,
    isModalOpen: false,
    isBuy: true,
  });

  useEffect(() => {
    const handler = (e) =>
      setState((prev) => ({ ...prev, isMobile: !e.matches }));
    window.matchMedia("(min-width: 600px)").addListener(handler);
  }, []);

  const renderTab = ({ tabVal, text }) => (
    <div
      className={`${
        tab === tabVal
          ? "border-b border-accent text-white"
          : "text-secondaryGray"
      } cursor-pointer text-center px-6 py-3`}
      onClick={() => setTab(tabVal)}
    >
      <p className="text-xs md:text-sm">{text}</p>
    </div>
  );
  
  if (state.isMobile) {
    return (
      <Fragment>
        <div className="fixed bottom-0 bg-primary left-0 right-0 h-16 flex items-center z-10">
          <button
            onClick={() =>
              setState((prev) => ({ ...prev, isModalOpen: true, isBuy: true }))
            }
            className="focus:outline-none text-white w-full text-xs bg-green md:text-sm lg:text-sm xl:text-sm mx-3 py-2 rounded mt-2 mb-2"
          >
            Buy
          </button>
          <button
            onClick={() =>
              setState((prev) => ({ ...prev, isModalOpen: true, isBuy: false }))
            }
            className="focus:outline-none text-white w-full text-xs bg-pink md:text-sm lg:text-sm xl:text-sm mx-3 py-2 rounded mt-2 mb-2"
          >
            Sell
          </button>
        </div>
        {state.isModalOpen && (
          <div className="fixed bottom-0 left-0 right-0 z-20 bg-primary p-4 rounded">
            <div className="flex justify-between items-center text-secondaryGray mb-2">
              <text>Place order</text>
              <button
                onClick={() =>
                  setState((prev) => ({ ...prev, isModalOpen: false }))
                }
                className="focus:outline-none z-20"
              >
                <img src={closeButton} className="w-6 h-6" />
              </button>
            </div>
            <TradeForm buy={state.isBuy} market={false} />
            {!auth.password && <NoAuthOverlay />}
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <div className="flex flex-col border border-primary w-full xl:w-4/5 lg:w-4/5 px-4 pb-2 mt-2 relative">
      <div className="flex w-full border-b border-primary">
        {renderTab({ tabVal: 0, text: "Limit" })}
        {renderTab({ tabVal: 1, text: "Market" })}
      </div>
      <div className="sm:flex">
        <div className="w-full mt-2">
          <TradeForm buy={false} market={tab === 1} />
        </div>
        <div className="sm:mx-4 sm:border-secondaryGray sm:border-r" />
        <div className="w-full mt-2">
          <TradeForm buy={true} market={tab === 1} />
        </div>
      </div>
      {!auth.password && <NoAuthOverlay />}
    </div>
  );
}

export default MakeOffer;
