import * as React from "react";
import { favoriteIcon, notFavIcon } from "assets/images/images";
import { colors } from "styles/theme";

export const TableRow = ({ handleSelect, item, onFav, tab }) => {
  let handleFavourite = (e) => {
    e.stopPropagation();
    if (!item.is_fav) {
      onFav("SET_FAV");
    } else {
      onFav("UNSET_FAV");
    }
  };

  let cell = "w-full text-gray4 text-xxs md:text-xs";
  
  const render = (value) => {
    if (value === "-") {
      return value;
    } else if (value == "Infinity") {
      return 0 + "%";
    }
    return value + "%";
  };

  return (
    <div
      onClick={() => {
        handleSelect(item);
      }}
      className="overflow-auto flex cursor-pointer py-1 pr-2 pl-2 w-full justify-between items-center"
    >
      <img
        onClick={handleFavourite}
        src={item.is_fav ? favoriteIcon : notFavIcon}
        className="h-3 w-3 mr-2 md:h-3 md:w-3 lg:h-3 lg:w-3 xl:w-3 xl:h-3"
      />
      <div className="flex w-full justify-between text-left">
        <text className={cell}>{item.counter + "/" + item.base}</text>
        <text className={cell}>{item.close}</text>
        <text
          className={cell}
          style={{ color: item.change > 0 ? colors.green : colors.pink }}
        >
          {render(item.change)}
        </text>
        <text className={cell}>{item.volume}</text>
      </div>
    </div>
  );
};
