import { paymentMethodsEnum } from "screens/hooks/P2P/useAdd";
import { getPreferences } from "services/storageServices";
import { MarketSimplePair } from "store/models/MarketInfo";

const coinsFromShort = {
  XLM: { name: "Lumens", icon: "coins/XLM.png" },
  BTC: { name: "Bitcoin", icon: "coins/BTC.png" },
  LTC: { name: "Litecoin", icon: "coins/LTC.png" },
  DOGE: { name: "DogeCoin", icon: "coins/DOGE.png" },
  DASH: { name: "Dash", icon: "coins/DASH.png" },
  ETH: { name: "Ethereum", icon: "coins/ETH.png" },
  USDT: { name: "Tether", icon: "coins/USDT.png" },
  SHIBA: { name: "Shiba Inu", icon: "coins/SHIB.png" },
  SHIB: { name: "Shiba Inu", icon: "coins/SHIB.png" },
  KISHU: { name: "Kishu Inu", icon: "coins/KISHU.png" },
  LEVELG: { name: "LevelG", icon: "coins/LEVELG.png" },
  WEENUS: { name: "Weenus", icon: "coins/LEVELG.png" },
  JUTT: { name: "Juttcoin", icon: "coins/USDT.png" },
  TRX: { name: "Tron", icon: "coins/TRX.png" },
  BOTH: { name: "BOTH", icon: "coins/BOTH.png" },
  AQUA: { name: "AQUA", icon: "coins/AQUA.png" },
};

const fullNameFromShort = {
  XLM: { name: "lumens" },
  BTC: { name: "bitcoin" },
  LTC: { name: "litecoin" },
  DOGE: { name: "dogecoin" },
  DASH: { name: "dash" },
  ETH: { name: "ethereum" },
  USDT: { name: "tether" },
  SHIB: { name: "Shiba Inu" },
  SHIBA: { name: "Shiba Inu" },
  KISHU: { name: "Kishu Inu" },
  LEVELG: { name: "levelg" },
  WEENUS: { name: "weenus" },
  TRX: { name: "tron" },
  BOTH: { name: "BOTH" },
  AQUA: { name: "AQUA" },
};

export const p2pDisabledCoins = [
  "LEVELG", "BOTH", "AQUA"
]

let scrollableStyle = {
  height: "60vh",
  overflow: "auto",
  scrollbarWidth: "0",
  zIndex: "100",
};

let languages = ["English"];
let currencies = ["USD", "EUR"];

const fees = {
  fee_max: "Maximum fee",
  fee_avg: "Average fee",
  fee_min: "Minimum fee",
};

let docTypes = [
  { name: "Select Type", code: "" },
  { name: "National ID", code: "ID" },
  { name: "Driving License", code: "drivinglicense" },
  { name: "Passport", code: "passport" },
];
export const docTypesCompany = [
  { name: "Select Type", code: "" },
  { name: "Noc", code: "noc" },
];
export const homePageMarketData: MarketSimplePair[] = [
  { base: 'USDT', counter: "BTC" },
  { base: 'USDT', counter: "ETH" },
  { base: 'USDT', counter: "LEVELG" },
  { base: 'USDT', counter: "XLM" },
]
export const marketPageData: MarketSimplePair[] = [
  { base: 'BTC', counter: "USDT" },
  { base: 'LEVELG', counter: "USDT" },
  { base: 'LEVELG', counter: "XLM" },
  { base: 'XLM', counter: "USDT" },
]

const currenciesEmun = {
  USD: "$",
  EUR: "€",
  MYR: "RM",
  CNY: "¥",
  KRW: "₩",
  THB: "฿",
  GBP: "£",
  AUD: "A$",
  INR: "₹",
  VND: "₫",
  TRY: "₺",
  AED: "د.إ",
  IDR: "Rp",
};

const addressLength = {
  BTC: 42,
  DOGE: 34,
  LTC: 34,
  DASH: 34,
  ERC20: 42,
  TRC20: 34,
}

const exchangeErrors = {
  op_cross_self: "You can't fulfill your own offer.",
  op_underfunded: "You don't have enough balance",
  op_malformed: "Invalid information provided"
}

export const sendBalanceErrors = {
  op_underfunded: "You don't have enough balance",
  op_no_trust: "Recipient need to trust this asset",
}
export const addTrustEnum = {
  op_low_reserve: "You don't have enough balance",
}

const swapErrors = {
  op_under_dest_min: "You will receive less balance than your lower limit.",
  op_cross_self: "You can't fulfill your own offer.",
  op_underfunded: "You don't have enough balance.",
  op_too_few_offers: "Not enough offers."
}

let getSymbol = () => {
  let { CURRENCY } = getPreferences();
  return currenciesEmun[CURRENCY]
}
export const getSymbolConditional = (usdBalance) => {
  if (usdBalance === "Loading...") {
    return
  }
  let { CURRENCY } = getPreferences();
  return currenciesEmun[CURRENCY]
}
export const memoTypes = {
  0: 'none',
  1: "text",
  2: "id",
  3: "hash"
}

export const selectPairActions = {
  FROM_MARKET: "FROM_MARKET",
  REFRESH_DATA: "REFRESH_DATA",
  DEFAULT: "DEFAULT",
  SELECT_PAIR: "SELECT_PAIR"
}

export const convertAsset = (asset) => {
  return {
    availableBalance: "0",
    balance: "0",
    btcValue: "0",
    coinIcon: asset?.image ? asset?.image : coinsFromShort[asset.code]?.icon,
    coingeckoId: asset?.coingeckoId,
    currentRate: asset?.currentRate,
    displayColor: "#f2a900",
    fullName: asset?.name,
    issuer: asset?.issuer,
    percent: asset?.rateChangePercent,
    profit: true,
    short: asset.short.toUpperCase(),
    totalBalance: "0",
  }
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const profileStatus = {
  approved: "approved",
  rejected: "rejected",
  draft: "draft",
  pending: "pending"
}

export const getPaymentText = ({ payment_method, bank_name }) => {
  if (payment_method === paymentMethodsEnum.Skrill ||
    payment_method === paymentMethodsEnum.Payoneer) {
    return payment_method;
  }

  return bank_name.substring(0, 15)
}

export const p2pTradeMessagePaymentName = {
  "Bank Transfer": "bank account",
  "Local_Bank": "bank account",
  "Payoneer": 'Payoneer account',
  "Skrill": 'Skrill account',
}

export enum paymentTypeIconsEnum {
  "Bank transfer" = "bank",
  "Bank Transfer" = "bank",
  Local_Bank = "bank",
  Payoneer = 'payoneer',
  Skrill = 'skill',
}

export const getDropWidth = () => {
  const width = window.innerWidth

  if (width > 1300) {
    return 54.4
  }
  if (width > 1000 && width < 1300) {
    return 54
  }
  if (width < 1000 && width > 800) {
    return 50
  }
  if (width < 800 && width > 600) {
    return 60
  }
  if (width < 600 && width > 500) {
    return 70
  }
  if (width <= 500) {
    return 85
  }
}

const profileIndex = {
  approved: 3,
  rejected: 3,
  draft: 2,
  pending: 3
}
const profileStDetail = {
  approved: "Verified",
  rejected: "Rejected",
  draft: "In-complete",
  pending: "Under Review"
}
const profileStatusDesc = {
  approved: "Your KYC is approved.",
  rejected: "Your Application is rejected due to following reasons.",
  draft: "Your KYC is incomplete.",
  pending: "Your KYC is Under Review"
}

export {
  coinsFromShort,
  scrollableStyle,
  languages,
  currencies,
  docTypes,
  fees,
  currenciesEmun,
  fullNameFromShort,
  exchangeErrors,
  getSymbol,
  profileStatus,
  profileStatusDesc,
  profileIndex,
  profileStDetail,
  swapErrors,
  addressLength
};
