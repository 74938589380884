import {
  ExchangeTypes,
  SET_SELECTED_COIN_PAIR,
  UPDATE_BALANCE,
  UPDATE_Market,
  SELECT_OFFER,
  REFRESH_MY_OFFERS,
  UPDATE_BOTH_BALANCES,
  SET_EXCHANGE_PRICES,
  RESET_SELECTED_PAIR,
  PERSIST_SELECTED_PAIR,
} from "../actions/ExchangeActionsTypes";
import { ExchangeState, CoinPair, PairMarket, PairPersist } from "store/models/Exchange";

const pair: CoinPair = {
  balance: 0.0,
  marketCoinCode: "",
  toCoinCode: "",
  lastPrice: 0,
  showOnTop: 0,
  orderId: 0,
  change: 0,
  open: "",
  close: "",
  closePrev: "",
  high: "",
  low: "",
  volume: "",
  marketCoinIssuer: "",
  toCoinIssuer: "",
  toCoinBalance: 0,
  buyPrice: 0,
  sellPrice: 0,
  bidsLength: 0,
  asksLength: 0
};


const pairPersist: any = {
  base: "",
  change: 0,
  close: "",
  closePrev: "",
  counter: "",
  high: "",
  is_fav: false,
  low: "",
  open: "",
  orderId: 0,
  showOnTop: 0,
  volume: ""
};
const initialState: ExchangeState = {
  pairPersist: pairPersist,
  selectedPair: pair,
  refresh: 0,
  refreshMyOffers: 0,
  selectedOffer: {
    total: "",
    amount: "",
    price: "",
    buy: false
  },
};

export function exchangeReducer(
  state = initialState,
  action: ExchangeTypes
): ExchangeState {
  switch (action.type) {
    case SET_SELECTED_COIN_PAIR:
      return {
        ...state,
        selectedPair: action.pair,
        refresh: (action.refresh || state.refresh === 0) ? state.refresh + 1 : state.refresh,
        refreshMyOffers: state.refreshMyOffers + 1
      };
    case RESET_SELECTED_PAIR:
      return {
        ...state,
        selectedPair: pair
      }
    case UPDATE_BALANCE:
      let { asset, balance } = action;
      return {
        ...state,
        selectedPair: {
          ...state.selectedPair,
          [asset]: balance,
        },
      };
    case SET_EXCHANGE_PRICES:
      return {
        ...state,
        selectedPair: {
          ...state.selectedPair,
          sellPrice: action.sellPrice,
          buyPrice: action.buyPrice,
          bidsLength: action.bidsLength,
          asksLength: action.asksLength
        }
      }
    case UPDATE_BOTH_BALANCES:
      return {
        ...state,
        selectedPair: {
          ...state.selectedPair,
          toCoinBalance: action.counterBalance,
          balance: action.baseBalance
        }
      }

    case UPDATE_Market:
      return {
        ...state,
        //pairMarket: action.market,
      };
    case REFRESH_MY_OFFERS:
      return {
        ...state,
        refreshMyOffers: state.refreshMyOffers + 1
      }
    case SELECT_OFFER:
      return {
        ...state,
        selectedOffer: action.offer,
      };
    case PERSIST_SELECTED_PAIR:
      return {
        ...state,
        pairPersist: action.payload
      }
    default:
      return state;
  }
}
