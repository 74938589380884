import React, { useState, CSSProperties } from "react";
import Modal from "react-modal";
import { closeButton, btcIcon, ethIcon, ltcIcon } from "assets/images/index";
import { useAlert } from "react-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import copy from "copy-to-clipboard";
import { saveData } from "services/storageServices";
import { useHistory } from "react-router-dom";
import { getWallets } from "services/dbServices";
import { Wallet } from "store/models/TransactionState";
import { encryptText } from "utils/helpers";
import {setLoading} from "../../../store/actions/GenericActions"
import { useDispatch } from "react-redux";

function AddWalletModal({ isOpen, onClose, onSelect }) {
  let initial: Wallet[] = [];
  let [wallets, setWallets] = useState(initial);

  let alert = useAlert();
  let dispatch = useDispatch();
  let history = useHistory();

  let copyToClip = (key) => {
    copy(key);
    alert.removeAll();
    alert.show("Copied!", { type: "success" });
  };

  let selectWallet = (item, index) => {
    dispatch(setLoading(true))
    saveData({
      selectedWallet: JSON.stringify({private: item.private, public: item.public, walletId: item.public }),
    });

    onSelect();
    onClose();
  };

  let onOpen = () => {
    getWallets().then((wallets: any)=>{
      setWallets(wallets);
    });
  };

  let renderGrid = () => {
    return wallets.map((item, index) => (
      <div key={index} className="flex justify-between items-center p-4 w-full">
        <h3
          onClick={() => selectWallet(item, index)}
          className="text-white text-xl font-semibold cursor-pointer"
        >
          {item.name + "  " + item.public.substring(0, 5) + "..."}
        </h3>
        <button onClick={() => copyToClip(item.public)}>
          <FontAwesomeIcon icon={faCopy} className="text-xl" color="#ffffff" />
        </button>
      </div>
    ));
  };

  return (
    <div className="nobar">
      <Modal
        isOpen={isOpen}
        contentLabel="Minimal Modal Example"
        style={customStyle}
        onAfterOpen={onOpen}
      >
        <button onClick={onClose} className="float-right">
          <img src={closeButton} className="w-6 h-6" />
        </button>
        <div className="flex flex-col items-center">
          <h2 className="mx-auto text-lg text-white font-semibold mt-8">
            Select Wallet
          </h2>

          <div className="w-full my-4">{renderGrid()}</div>
          <button
            className={btnStyle}
            onClick={() => {
              history.push("/newWallet");
            }}
          >
            Create New Wallet
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default AddWalletModal;


const getWidth = () => {
  const width = window.innerWidth

  if(width > 1300){
    return 54.4
  }
  if(width > 1000&& width<1300){
    return 54
  }
  if(width < 1000 && width > 800){
    return 50
  }
  if(width < 800 && width > 600){
    return 60
  }
  if(width < 600 && width > 400){
    return 70
  }
  if(width < 400){
    return 80
  }
}
const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    zIndex: 100,
  },
  content: {
    top: "10%",
    left: ((100-Number(getWidth()||0))/2)+"%",
    right: "auto",
    bottom: "auto",
    scrollbarWidth: 'none',
    width: (getWidth()+"%"),
    height: "80%",
    zIndex: "1000",
    margin: "0 auto",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};

let btnStyle =
  "bg-yellow-700 hover:bg-yellow-600 rounded mr-6 text-white py-1 px-2 md:py-1 md:px-4 lg:py-1 lg:px-4 xl:py-2 xl:px-4 whitespace-no-wrap focus:outline-none text-xxxs xl:text-xs";
