import { personImage2 } from "assets/images";
import React, { useEffect, useState } from "react";
import StarRatingComponent from "react-star-rating-component";
import { getP2pProfileReview } from "services/p2pServives";
import Loader from "react-loader-spinner";


function P2pProfileReview({me}) {
  const init: any = [];
  const [state, setState] = useState({ loading: true, data: init });
  useEffect(() => {
    initialize();
  }, []);
  const initialize = () => {
    getP2pProfileReview()
      .then((data) => {
        let reviews:any = [];
        data.data.data.forEach((item)=>{
          if(item.review){
            reviews.push(item)
          }
        })
        setState((prev) => ({
          ...prev,
          loading: false,
          data: reviews,
        }));
      })
      .catch((e) => {
        //console.log(e.response);
        setState((prev) => ({ ...prev, loading: false }));
      });
  };
  const renderLoading = () => (
    <div className="flex justify-center items-center w-full border border-primary py-8">
      <Loader type="Bars" color="#FFFF00" height={20} width={20} />
    </div>
  );

  if (state.loading) return renderLoading();

  return (
    <div className={`border border-primary p-3 w-full ${me?'mt-4':'sm:mx-6'}`}>
      <h2 className="text-white text-sm">Feedback</h2>
      {state.data.map((review, index) => (
        <div key={index} className="mt-2">
          <StarRatingComponent name="" value={review.rating} />
          <h5 className="text-gray4 text-xs" style={{marginTop: '-6px'}}>By {review.first_name}</h5>
          <p className="text-gray4 text-xs">
            {review.review}
          </p>
        </div>
      ))}
    </div>
  );
}

export default P2pProfileReview;
