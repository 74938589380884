import * as React from "react";
import * as Images from "assets/images/index";
import CustomInput from "components/global/CustomInput";
import { Button } from "components/global";
import Modal from "react-modal";
import { removeAccount } from "services/authServces";
import { useHistory } from "react-router-dom";
import { getSelectedKeys } from "services/trannsactionServices";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { clearProfileAction } from "store/actions/ProfileActions";

export const RemoveAccountForm = (props: any) => {
  let [publicKey, setPublicKey] =React.useState('');
  let [validation, setValidation] =React.useState('');
  let history = useHistory();
  let alert = useAlert();
  const dispatch = useDispatch()

  let handleSubmit = () => {
    if(!validated()) return;

    removeAccount(publicKey, (response)=>{
      if(response == "ERROR"){
        alert.removeAll();
        alert.show("Unable to Remove account make sure account id is correct.", {type: "error"});
        return;
      }
      props.onClose();
      alert.removeAll();
      alert.show('Successfully deleted.',{type:"success"})
      dispatch(clearProfileAction());
      if(response === "WALLETS"){
        history.replace({
          pathname: "/walletScreen",
          state: {
            addWallet: true,
          },
        });
      }
      if(response === "CREATE_WALLET"){
        history.replace('/newWallet');
      }
    })
  }

  let validated = ()=>{
    let res = true;
    if(publicKey === ""){
      setValidation("Please enter a public key.");
      res=false;
    }

    return res;
  }

  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel="Minimal Modal Example"
      style={customStyle}
    >
      <div className="mt-2 p-5">
        <button onClick={props.onClose} className="absolute right-0 top-0 p-4">
          <img src={Images.closeButton} className="w-8 h-8" />
        </button>
        <p className="text-lg mt-8 md:text-h1 lg:text-h1 xl:text-h1 font-regular text-white text text-center">
          Delete Your Account!
        </p>

        <div
          style={{ backgroundColor: "rgba(255,159,0,0.15)" }}
          className="w-full md:w-4/6 lg:w-4/6 xl:w-4/6 mx-auto p-5 mt-4 mb-4"
        >
          <div>
            <img src={Images.informationIcon} className="h-10 w-10 mx-auto" />
            <div className="mt-5">
              <p className="font-regular text-gray4 text-center  text-xs mb-2">
                Make sure you’ve saved the secret key for this account before
                deleting.
              </p>

              <p className="font-regular  text-center text-gray4 text-xs">
                You can always re-import your account if you know its secret
                key.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-3/6 lg:w-3/6 xl:w-3/6 mx-auto">
          <p className="font-regular  text-center text-gray4 text-sm mb-5">
            Enter Public Key of Account to Delete
          </p>
          <CustomInput bottom={true} border value={publicKey} valMsg={validation} onChange={(e)=>{setPublicKey(e.target.value)}} placeholder="sda5f66S5A4SD6FDSAFASD65F4S65D4F" />
          <div className="w-full md:w-3/6 lg:w-3/6 xl:w-3/6 mt-3 md:mt-10 lg:mt-10 xl:mt-10 mx-auto">
            <Button onClick={handleSubmit} styles={["bg-pink text-sm px-2 py-3 w-full"]}>
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
    alignItems: "center",
  },
  content: {
    top: "150",
    left: "auto",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    marginLeft: "20px",
    marginRight: "20px",
    maxWidth: "790px",
    width: '100%',
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
