import React, { useEffect, useState } from "react";
import { TableHeading } from "components/molecular/Exchange/TableHeading";
import { TableRow } from "components/molecular/Exchange/TableRow";
import useCoinPair from "screens/hooks/exchange/useCoinPair";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { getDropWidth } from "utils/constants";
import useSwap from "../../../screens/hooks/useSwap";
import { ExchangeCard } from "components/molecular/Exchange/ExchangeCard";
import AddAssetModal from "components/molecular/Wallet/AddAssetModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { convertAsset } from "utils/constants";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { selectCoinNamePending } from "store/actions/P2pActions";
import { useLocation } from "react-router-dom";
import { selectPairActions } from "utils/constants";
import { setSelectedPairAction } from "store/actions/ExchangeActions";

export function PairPriceTable() {
  let {
    state: { isOpen, type, sellling, buying },
    onClose,
    openSell,
    openBuy,
    onSuccess,
  } = useSwap();

  const {
    list,
    search,
    tab,
    headerStart,
    headerLast,
    setTab,
    handleFav,
    handleSelect,
    handlePredefinedSelect,
    handelChange,
    show,
    setShow,
    loader,
    setLoader,
  } = useCoinPair();

  const { transaction } = useSelector((state: RootState) => state);
  const location: any = useLocation();
  const dispatch = useDispatch();

  const {
    exchange: { selectedPair, pairPersist },
    auth,
  }: RootState = useSelector((state: RootState) => state);

  const toggleDropdown = () => {
    setShow(!show);
  };
  useEffect(() => {
    window.onclick = function (event) {
      let btn = document.getElementsByClassName("dropbtn")[0];
      let cntnt = document.getElementsByClassName("drop-content")[0];
      let modal = document.getElementsByClassName("asset-modal")[0];
      if (
        !btn?.contains(event.target) &&
        !cntnt?.contains(event.target) &&
        modal?.contains(event.target)
      ) {
        setShow(false);
      }
    };
  }, []);

  useEffect(() => {
    if (pairPersist?.base) {
      dispatch(
        setSelectedPairAction(
          pairPersist,
          location.state,
          selectPairActions.SELECT_PAIR
        )
      );
    }
  }, []);

  ///// Used This function for setting tab loader false
  // useEffect(() => {
  //   if (tab) {
  //     setLoader(false);
  //   }
  // }, [tab]);

  return (
    <div className="px-4 border border-primary border-t-0 border-b-0 flex items-center">
      <button
        onClick={toggleDropdown}
        className="dropbtn focus:outline-none flex items-center pr-4"
      >
        <span className="text-white text-xl">
          {selectedPair.toCoinCode.toUpperCase()}
        </span>
        <span className="text-secondaryGray text-base">
          /{selectedPair.marketCoinCode.toUpperCase()}
        </span>
        <img src="assets/Dropdown.svg" className="w-6 h-6 ml-4" />
      </button>
      {show && (
        <div
          className="Table md:w-full drop-content bg-gray3 z-20 absolute p-4"
          style={{
            height: "350px",
            width: getDropWidth() + "%",
            left: auth.password ? "10vw" : "1vw",
            top: 130,
          }}
        >
          <PairHeader
            tab={tab}
            search={search}
            handelChange={handelChange}
            setTab={setTab}
            headerStart={headerStart}
            headerLast={headerLast}
          />
          {tab == "Custom" ? null : (
            <TableHeading
              headings={["Pair", "Last Price", "24h Change", "24h Volume"]}
            />
          )}

          {list.map((item: any, index) => {
            return (
              <TableRow
                key={index}
                item={item}
                tab={tab}
                onFav={(a) => handleFav(a, item)}
                handleSelect={handlePredefinedSelect}
              />
            );
          })}
          {tab == "Custom" && (
            <CustomTrade
              swapFrom={sellling}
              swapTo={buying}
              setSell={openSell}
              setBuy={openBuy}
              isOpen={isOpen}
              onClose={onClose}
              type={type}
              onSuccess={onSuccess}
              handleSelect={handleSelect}
              transaction={transaction}
              loader={loader}
              setLoader={setLoader}
            />
          )}
        </div>
      )}
    </div>
  );
}

function PairHeader({
  setTab,
  tab,
  headerLast,
  headerStart,
  handelChange,
  search,
}) {
  const [customSelect, setCustomSelect] = useState(false);
  const tabRender = (index, marketCoinCode) => {
    return (
      <div
        key={index}
        className={`${
          tab === marketCoinCode
            ? "bg-secondaryGray rounded-full text-white"
            : "border border-secondaryGray rounded-full text-white"
        } mr-2 h-6 px-2 flex justify-center items-center text-xs cursor-pointer`}
        onClick={() => {
          setTab({ marketCoinCode: marketCoinCode });
          setCustomSelect(false);
        }}
      >
        {marketCoinCode}
      </div>
    );
  };
  return (
    <div className="flex justify-between">
      <div className="flex">
        {headerStart.map((item, index) => tabRender(index, item))}
        {headerLast.length > 0 && (
          <div
            className={
              customSelect
                ? "flex border border h-6 bg-secondaryGray cursor-pointer items-center px-1 border-secondaryGray rounded-full"
                : "flex border border h-6 cursor-pointer items-center px-1 border-secondaryGray rounded-full"
            }
          >
            <div
              className={
                customSelect
                  ? "focus:outline-none bg-secondaryGray rounded-full text-white text-xs px-2"
                  : "focus:outline-none text-white text-xs rounded-full px-2"
              }
              onClick={(item) => {
                setTab({ marketCoinCode: "Custom" });
                setCustomSelect(true);
              }}
            >
              {headerLast.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          </div>
        )}
      </div>
      {tab == "Custom" ? null : (
        <div className="border border-secondaryGray flex items-center px-1">
          <input
            type="text"
            placeholder="Search"
            className="bg-transparent text-secondaryGray w-full focus:outline-none text-xs"
            onChange={handelChange}
            value={search}
          />
          <button
            onClick={() => {
              if (search !== "") {
                handelChange({ target: { value: "" } });
              }
            }}
            className="focus:outline-none"
          >
            <FontAwesomeIcon
              icon={search === "" ? faSearch : faTimes}
              className="text-gray-500 text-xs"
            />
          </button>
        </div>
      )}
    </div>
  );
}

function CustomTrade({
  swapFrom,
  swapTo,
  setSell,
  setBuy,
  isOpen,
  onClose,
  type,
  onSuccess,
  handleSelect,
  transaction,
  loader,
  setLoader,
}) {
  let [from, setFrom]: any = React.useState(false);
  let [to, setTo]: any = React.useState(false);
  const alert = useAlert();
  let history = useHistory();

  const Alert = () => {
    if (from && to) {
      alert.error("Please Trust Both Assets");
    } else if (from) {
      alert.error("Please Trust this Asset first");
      history.push({
        pathname: "/walletDetailScreen",
        state: {
          screen: 0,
          coin: swapFrom.code,
          new: true,
          asset: convertAsset(swapFrom),
        },
      });
    } else if (to) {
      alert.error("Please Trust this Asset first");
      history.push({
        pathname: "/walletDetailScreen",
        state: {
          screen: 0,
          coin: swapTo.code,
          new: true,
          asset: convertAsset(swapTo),
        },
      });
    }
  };

  React.useEffect(() => {
    if (transaction.coins.length > 0) {
      let from = transaction.coins.find(
        (val) => val.short.toLowerCase() == swapFrom.short.toLowerCase()
      );
      let to = transaction.coins.find(
        (val) => val.short.toLowerCase() == swapTo.short.toLowerCase()
      );

      if (!from) {
        setFrom(true);
      } else {
        setFrom(false);
      }
      if (!to) {
        setTo(true);
      } else {
        setTo(false);
      }
    }
  }, [swapTo, swapFrom]);

  let renderBtn = () => {
    return (
      <div>
        <FontAwesomeIcon icon={faSyncAlt} spin /> Please Wait...
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full asset-modal">
      <ExchangeCard
        swapFrom={swapFrom}
        swapTo={swapTo}
        setSell={setSell}
        setBuy={setBuy}
      />

      <AddAssetModal
        isOpen={isOpen}
        onClose={onClose}
        type={type}
        onSuccess={onSuccess}
      />
      <div className="flex justify-center items-center">
        <button
          onClick={() => {
            swapTo?.short?.toLowerCase() == swapFrom?.short?.toLowerCase() &&
            swapFrom?.issuer == swapTo?.issuer
              ? alert.error("Cannot select same assets")
              : to == true || from == true
              ? Alert()
              : transaction.coins.length <= 0
              ? alert.error("Please login First!")
              : handleSelect(swapTo, swapFrom);
          }}
          className="focus:outline-none py-2 px-6 bg-accent rounded text-white w-40"
        >
          {loader ? renderBtn() : "GO"}
        </button>
      </div>
    </div>
  );
}
