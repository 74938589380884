import * as React from "react";
import {
  lossGraphCard,
  sendArrowIcon,
  receiveArrowIcon,
  profitGraphCard,
} from "assets/images/images";
import { colors } from "styles";
import { getPreferences } from "services/storageServices";

export const WalletDataRow = (props: any) => {
  let { CURRENCY } = getPreferences();
  return (
    <tr
      className="text-left bg-primary cursor-pointer"
      onClick={() => props.onNav(0)}
    >
      <td>
        <div className="flex ml-2 items-center">
          <img src={props.coinIcon} className={styles.iconStyle} />
          <div className="flex flex-col ml-2">
            <text
              style={{ color: "white" }}
              className={styles.textStyle + " " + "font-bold"}
            >
              {props.short}
            </text>
            <small className="text-xxs" style={{ color: props.displayColor }}>
              {props.fullName}
            </small>
          </div>
        </div>
      </td>
      <td>
        <div className={styles.textStyle + " flex items-center"}>
          <text className="mr-1">{props.totalBalance}</text>
          {props.short}
        </div>
      </td>
      <td>
        <div className={styles.textStyle + " flex items-center"}>
          <text className="mr-1">{props.balance}</text>
          {props.short}
        </div>
      </td>
      <td className="hidden md:block lg:block xl:block">
        <div className="flex flex-col mt-2">
          <text className={styles.textStyle}>{props.availableBalance}</text>
          <small className="text-xxs" style={{ color: props.displayColor }}>
            @ 1 {props.short} ={" "}
            {props.short == "KISHU"
              ? Number(props.currentRate).toFixed(12)
              : props.currentRate}{" "}
            {CURRENCY}
          </small>
        </div>
      </td>
      <td>
        <text className={styles.textStyle + " flex items-center"}>
          {props.btcValue} BTC
        </text>
      </td>
      <td>
        <div className={styles.buttonsContainer}>
          <button
            className={styles.sendBtn}
            onClick={(event) => {
              event.stopPropagation();
              props.onNav(1);
            }}
          >
            Send
            <img src={sendArrowIcon} className={styles.btnIconStyle} />
          </button>

          <button
            className={styles.receiveBtn}
            onClick={(event) => {
              event.stopPropagation();
              props.onNav(2);
            }}
          >
            Receive
            <img src={receiveArrowIcon} className={styles.btnIconStyle} />
          </button>
        </div>
      </td>
      <td>
        <div className="hidden md:flex lg:flex xl:flex justify-around items-center w-full">
          <text
            style={{ color: props.profit ? colors.green : colors.pink }}
            className={styles.percentStyle}
          >
            {props.profit && "+"}
            {props.percent}
          </text>
          <img
            src={props.profit ? profitGraphCard : lossGraphCard}
            className={styles.graphStyle}
          />
        </div>
      </td>
    </tr>
  );
  /*(
      <div className="bg-primary flex text-white items-center justify-between py-3 px-5 overflow-auto">
        <div className="flex items-center">
          <img src = {props.coinIcon} className={styles.iconStyle}/>
          <text style={{color:'white'}} className={styles.textStyle+" "+'font-bold'}>{props.coinName}</text>
        </div>
        <text className={styles.coinNameStyle}>{props.fullName}</text>
        <div className="">
          <text className={styles.textStyle}>{props.balance}{props.coinName}</text>
        </div>
        <div>
          <text className={styles.textStyle}>{props.availableBalance}</text>
        </div>
        <text className={styles.textStyle}>{props.btcValue} BTC</text>
        <div className={styles.buttonsContainer}>
          <button className={styles.sendBtn} onClick={()=>{
            dispatch(selectCoin(props.index))
            history.push({
              pathname: '/walletDetailScreen',
              state: {
                screen:1,
                coin: props.short
              }
            })
          }}>
            <a href="BASE_URL+/aboutscreen">Send</a>
            <img src={sendArrowIcon} className={styles.btnIconStyle}/>
          </button>

          <button className={styles.receiveBtn} onClick={()=>{
            dispatch(selectCoin(props.index))
            history.push({
              pathname: '/walletDetailScreen',
              state: {
                screen: 2,
                coin: props.short
              }
            })
          }}>
            Receive
            <img src={receiveArrowIcon} className={styles.btnIconStyle}/>
          </button>
          
        </div>
        <text style={{color:props.profit? colors.green :colors.pink}} className={styles.percentStyle}>{props.profit&& "+"}{props.percent}</text>
        <img src = {props.profit?profitGraphCard: lossGraphCard} className={styles.graphStyle}/>
      </div>
  );*/
};

const styles = {
  textStyle: "text-xxxs text-secondaryGray md:text-xs lg:text-sm xl:text-sm",
  coinNameStyle:
    "w-10 text-left text-xxxs text-secondaryGray md:text-sm lg:text-sm xl:text-sm",
  iconStyle: "h-4 w-4 md:h-6 md:w-6 lg:h-6 lg:w-6 xl:h-6 xl:w-6",
  buttonsContainer: "flex items-center my-2",
  btnIconStyle: "ml-1 h-2 w-1 lg:ml-2 lg:h-4 lg:w-2 xl:h-4 xl:ml-2 xl:w-2 ",
  sendBtn:
    "flex items-center border border-accent text-secondaryGray rounded py-1 px-2 md:py-1 md:px-4 lg:py-1 lg:px-4 xl:py-1 xl:px-4 mr-2 xl:mr-3 lg:mr-3 focus:outline-none text-xxxs lg:text-base xl:text-base",
  receiveBtn:
    "flex text-secondaryGray items-center border border-green rounded py-1 px-2 md:py-1 md:px-3 lg:py-1 lg:px-3 xl:py-1 xl:px-3 focus:outline-none text-xxxs lg:text-base xl:text-base",
  percentStyle: "ml-4 text-xxxs text-white md:text-xs lg:text-xs xl:text-xs",
  graphStyle:
    "ml-4 md:ml-4 xl:ml-0 lg:ml-0 h-8 w-8 md:h-10 md:w-15 lg:h-10 lg:w-15 xl:h-8 xl:w-15",
};
