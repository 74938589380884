import * as React from "react";
import { sizes } from "styles/theme";
import { Text } from "components";
export class Block extends React.Component<any> {
  render() {
    const {
      top,
      middle,
      bottom,
      center,
      left,
      right,
      height,
      width,
      bcColor,
    } = this.props;
    return (
      <div
        style={{
          // display:this.props.display,
          margin: this.props.margin || "0 auto",
          marginTop: this.props.marginTop || this.props.marginVertical,
          marginLeft: this.props.marginLeft || this.props.marginHorizontal,
          marginRight: this.props.marginRight || this.props.marginHorizontal,
          marginBottom: this.props.marginBottom || this.props.marginVertical,
          padding: this.props.padding,
          flexWrap: this.props.flexWrap || "nowrap",
          paddingTop: this.props.paddingTop || this.props.paddingVertical,
          paddingRight: this.props.paddingRight || this.props.paddingHorizontal,
          paddingBottom: this.props.paddingBottom || this.props.paddingVertical,
          paddingLeft: this.props.paddingLeft || this.props.paddingHorizontal,
          // ==================
          height: sizes.getHeight(height) || sizes.getHeight(100),
          width: sizes.getWidth(width) || sizes.getWidth(100),
          backgroundColor: bcColor || "gray",
          display: "flex",
          alignItems: (middle && "center") || (top && "flex-start") || bottom && 'flex-end',
          justifyContent:
            (center && "center") ||
            (left && "flex-start") ||
            (right && "flex-end")
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
