import { Asset } from "store/models/Assets";

export const FETCH_ASSETS_PENDING = "FETCH_ASSETS_PENDING";
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS";
export const FETCH_ASSETS_BY_CURRENCY = "FETCH_ASSETS_BY_CURRENCY";
export const FETCH_ASSETS_ERROR = "FETCH_ASSETS_ERROR";
export const APPEND_ASSETS_SUCCESS = "APPEND_ASSETS_SUCCESS";
export const APPEND_CUSTOM_ASSETS_SUCCESS = "APPEND_CUSTOM_ASSETS_SUCCESS";

interface FetchAssetsPending {
  type: typeof FETCH_ASSETS_PENDING;
  payload: boolean;
}
interface FetchAssetesSuccess {
  type: typeof FETCH_ASSETS_SUCCESS;
  payload: Asset[];
  currency: string;
}
interface AppendAssetesSuccess {
  type: typeof APPEND_ASSETS_SUCCESS;
  payload: Asset[];
  currency: string;
}
interface FetchAssetsError {
  type: typeof FETCH_ASSETS_ERROR;
  payload: string;
}
interface FetchAssetsByCurrency {
  type: typeof FETCH_ASSETS_BY_CURRENCY;
  payload: string;
}

interface AppendCustomAssetesSuccess {
  type: typeof APPEND_CUSTOM_ASSETS_SUCCESS;
  payload: object | any;
}

export type GetAssetsInfoType =
  | FetchAssetesSuccess
  | FetchAssetsError
  | FetchAssetsPending
  | FetchAssetsByCurrency
  | AppendAssetesSuccess
  | AppendCustomAssetesSuccess;
