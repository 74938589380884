import { useState, useEffect } from "react";
import {
  getOffers,
  getTrades,
  lookForTrades,
  getAllTrades,
  addBuyOffer,
  lookForMyOffers,
  testTrades,
} from "services/exchange";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { useAlert } from "react-alert";
import { setCoinsAction } from "store/actions/TransactionActions";
import { useHistory } from "react-router-dom";
import { getSelectedKeys } from "services/trannsactionServices";

let allTradesResp: any = {};
let myTradesResp: any = {};
const useTradeHistory = () => {
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();
  const alert = useAlert();
  const initTrades: any = [];

  const [state, setState] = useState({
    offers: initTrades,
    allTrades: initTrades,
    myTrades: initTrades,
    loading: false,
    deleteConfirm: false,
    removing: false,
  });
  let {
    exchange: { selectedPair, refresh, refreshMyOffers },
    auth,
  } = useSelector((state: RootState) => state);
  let headings = [
    `Price(${selectedPair.marketCoinCode.toUpperCase()})`,
    `Amount(${selectedPair.toCoinCode.toUpperCase()})`,
    "Date",
  ];

  const listenTrades = () => {
    let asset1 = {
      code: selectedPair.marketCoinCode.toUpperCase(),
      issuer: selectedPair.marketCoinIssuer,
    };
    let asset2 = {
      code: selectedPair.toCoinCode.toUpperCase(),
      issuer: selectedPair.toCoinIssuer,
    };
    const account = getSelectedKeys().public;
    setState((prev) => ({ ...prev, allTrades: [], myTrades:[] }));
    lookForTrades(asset1, asset2, (element) => {
      let pr: any = (1 / (element.price.n / element.price.d)).toFixed(8);
      const base =
        element.base_asset_type === "native" ? "XLM" : element.base_asset_code;
      const counter =
        element.counter_asset_type === "native"
          ? "XLM"
          : element.counter_asset_code;
      let object = {
        pair: counter + "/" + base,
        amount: element.counter_amount,
        profit: true,
        price: pr,
        date: element.ledger_close_time,
        total: (parseFloat(element.counter_amount) * pr).toFixed(8),
        type: getTradeType(element, account),
      };
      setState((prev) => ({ ...prev, allTrades: [object, ...prev.allTrades] }));
      dispatch(setCoinsAction());
      
    });
  };

  let closeConfirm = () =>
    setState((prev) => ({ ...prev, deleteConfirm: false }));
  let openConfirm = () =>
    setState((prev) => ({ ...prev, deleteConfirm: true }));

  const nextAllTrades = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    allTradesResp = await allTradesResp.next();
    let result: any = [];
    const account = getSelectedKeys().public;
    allTradesResp.records?.forEach((element) => {
      let pr: any = (1 / (element.price.n / element.price.d)).toFixed(8);
      const base =
        element.base_asset_type === "native" ? "XLM" : element.base_asset_code;
      const counter =
        element.counter_asset_type === "native"
          ? "XLM"
          : element.counter_asset_code;
      let object = {
        pair: counter + "/" + base,
        amount: element.counter_amount,
        profit: true,
        price: pr,
        date: element.ledger_close_time,
        total: (parseFloat(element.counter_amount) * pr).toFixed(8),
        type: getTradeType(element, account),
      };
      result.push(object);
    });
    let sortedTrades = result.sort((a, b) => {
      return new Date(b.date).valueOf() - new Date(a.date).valueOf();
    });
    setState((prev) => ({
      ...prev,
      allTrades: [...prev.allTrades, ...sortedTrades],
      loading: false,
    }));
  };

  const getCoinTrades = async () => {
    let asset1 = {
      code: selectedPair.marketCoinCode.toUpperCase(),
      issuer: selectedPair.marketCoinIssuer,
    };
    let asset2 = {
      code: selectedPair.toCoinCode.toUpperCase(),
      issuer: selectedPair.toCoinIssuer,
    };
    try {
      const account = getSelectedKeys().public;
      allTradesResp = await getAllTrades(asset1, asset2, 10);
      let result: any = [];
      console.log("allTradesResp", allTradesResp)
      allTradesResp.records?.forEach((element) => {
        let pr: any = (1 / (element.price.n / element.price.d)).toFixed(8);
        const [base, counter] = getTradeAssetsNames(element)
        let object = {
          pair: counter + "/" + base,
          amount: element.counter_amount,
          profit: true,
          price: pr,
          date: element.ledger_close_time,
          total: (parseFloat(element.counter_amount) * pr).toFixed(8),
          type: getTradeType(element, account),
        };
        result.push(object);
      });
      let sortedTrades = result.sort((a, b) => {
        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
      });
      setState((prev) => ({
        ...prev,
        allTrades: sortedTrades,
        loading: false,
      }));
    } catch (e) {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const getTradeType = (trade, account) => {
    if (trade.base_is_seller) {
      return "SELL";
    } else {
      return "BUY";
    }
  };
  const getMyTradeType = (trade, account) => {
    const seller = trade.base_is_seller? trade.base_account:trade.counter_account

    if (seller === account) {
      return "SELL";
    }
    return "BUY"
  };

  useEffect(() => {
    if (selectedPair.marketCoinCode === "" || selectedPair.toCoinCode === "") {
      return;
    }
    setTab(0)
    listenTrades();
    getCoinTrades();
  }, [refresh]);

  useEffect(() => {
    getMyOffers(false);
  }, [refreshMyOffers]);

  const listenForMyOffers = () => {
    lookForMyOffers((offers) => {});
  };

  let getMyOffers = (loader) => {
    setState((prev) => ({ ...prev, loading: loader }));
    let asset1 = {
      code: selectedPair.marketCoinCode.toUpperCase(),
      issuer: selectedPair.marketCoinIssuer,
    };
    let asset2 = {
      code: selectedPair.toCoinCode.toUpperCase(),
      issuer: selectedPair.toCoinIssuer,
    };
    getOffers(asset1, asset2)
      .then((offers) => {
        let result: any = [];
        offers.forEach((element) => {
          const buying =
            element.buying.asset_type == "native"
              ? "XLM"
              : element.buying.asset_code;
          const selling =
            element.selling.asset_type == "native"
              ? "XLM"
              : element.selling.asset_code;
          if (
            (buying === selectedPair.marketCoinCode ||
              buying === selectedPair.toCoinCode) &&
            (selling === selectedPair.marketCoinCode ||
              selling === selectedPair.toCoinCode)
          ) {
            let buy = buying === selectedPair.toCoinCode.toUpperCase();
            let pr: any = (buy
              ? 1 / parseFloat(element.price)
              : parseFloat(element.price)
            ).toFixed(8);
            let total: any = (buy
              ? parseFloat(element.amount)
              : parseFloat(element.amount) * pr
            ).toFixed(8);
            let amount: any = (buy
              ? parseFloat(element.amount) / pr
              : parseFloat(element.amount)
            ).toFixed(8);
            let object = {
              id: element.id,
              amount: amount,
              price: pr,
              buy: buy,
              total: total,
              myOffer: true,
              selling: element.selling,
              buying: element.buying,
            };
            result.push(object);
          }
        });
        setState((prev) => ({ ...prev, offers: result, loading: false }));
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, loading: false }));
      });
  };

  const getMyTrades = async (updating) => {
    const account = getSelectedKeys().public;

    if(!updating){
      setState((prev) => ({ ...prev, loading: true }));
    }
    
    try {
      myTradesResp = await getTrades(account);
      console.log('my trades', myTradesResp)
      const sortedTrades = processMyTrades(myTradesResp.records, account)
      
      setState((prev) => ({ ...prev, myTrades: sortedTrades, loading: false }));
    } catch (e) {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };
  const getTradeAssetsNames = ({base_asset_type, base_asset_code, counter_asset_type, counter_asset_code}) => {
    const base = base_asset_type === "native" ? "XLM" : base_asset_code;
    const counter = counter_asset_type === "native" ? "XLM" : counter_asset_code;
    return [base, counter]
  }
  const processMyTrades = (trades, account) => {
    const baseAsset = selectedPair.marketCoinCode.toUpperCase();
    const counterAsset =  selectedPair.toCoinCode.toUpperCase();

    const result:any = [];
    trades.forEach((element) => {
      const [base, counter] = getTradeAssetsNames(element)
      const isTrade = base === baseAsset && counter === counterAsset
      const isReverseTrade = base === counterAsset && counter === baseAsset
      if(isTrade || isReverseTrade) {
        let pr: number|string = element.price.n / element.price.d
        pr = isReverseTrade ? pr.toFixed(8) : (1/pr).toFixed(8);
        const amount = isReverseTrade ? element.base_amount : element.counter_amount;
        let object = {
          pair: isReverseTrade?(base + "/" + counter): (counter + "/" + base),
          amount: amount,
          profit: true,
          price: pr,
          date: element.ledger_close_time,
          total: (Number(amount) * Number(pr)).toFixed(8),
          type: getMyTradeType(element, account),
        };
        result.push(object);
      }
    });
    return result.sort((a, b) => {
      return new Date(b.date).valueOf() - new Date(a.date).valueOf();
    });
  }
  const nextMyTrades = async () => {
    setState((prev) => ({ ...prev, loading: true }));
    myTradesResp = await myTradesResp.next();

    const account = getSelectedKeys().public;
    
    const sortedTrades = processMyTrades(myTradesResp.records, account)
    setState((prev) => ({
      ...prev,
      myTrades: [...prev.myTrades, ...sortedTrades],
      loading: false,
    }));
  };
  const onOfferDelete = (selectedOffer, success) => {
    if (success) {
      alert.show("Offer deleted.", { type: "success" });
      let newOffers = state.offers.filter(
        (offer) => offer.id != selectedOffer.id
      );
      setState((prev) => ({ ...prev, offers: newOffers }));

      dispatch(setCoinsAction());
    } else {
      alert.show("Error deleting offer.", { type: "error" });
    }
  };

  const deleteAllOffers = async () => {
    for (let i = 0; i < state.offers.length; i++) {
      const element: any = state.offers[i];
      try {
        const asset1 = getAssetObject(element.selling);
        const asset2 = getAssetObject(element.buying);
        // await addBuyOffer({
        //   asset1,
        //   asset2,
        //   amount: "0",
        //   price: element.price,
        //   offerId: element.id,
        // });
      } catch (e) {
        // console.log(e?.response);
      }
    }
    // console.log("Deleted all offers!");
  };
  const getAssetObject = (asset) => {
    if (asset.asset_type === "native") {
      return { code: "XLM" };
    } else {
      return { code: asset.asset_code, issuer: asset.asset_issuer };
    }
  };

  return {
    ...state,
    tab,
    setTab,
    getOffers,
    getMyOffers,
    getMyTrades,
    headings,
    onOfferDelete,
    closeConfirm,
    openConfirm,
    nextAllTrades,
    deleteAllOffers,
    auth,
    nextMyTrades,
  };
};

export default useTradeHistory;
