import { useState, useEffect } from "react";
import { BASE_URL, postOptions } from "utils/AppConstants";
import {useAlert} from "react-alert";
import { saveData } from "services/storageServices";
import { incryptPassword } from "services/authServces";
import { useHistory } from "react-router-dom";
import {useDispatch} from 'react-redux'
import { savePassword } from "store/actions/AuthActions";

let useRegister = () => {
  let [agree, setAgree] = useState({isAgree: false, notAgreed: false});
  let [inputs, setInputs] = useState({
    password: "",
    confirm: "",
    referral: "",
    passwordValidation: '',
    confirmValidation: ''
  });
  let [state, setState] = useState({submitting: false})
  let alert = useAlert();
  let history = useHistory();
  const dispatch = useDispatch();
  
  let handleInputChange = (e) => {
    let { name, value } = e.target;
    
    setInputs((prev) => ({ ...prev, [name]: value, [name === "password"?'passwordValidation':'confirmValidation']: '' }));
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    setState(prev=>({...prev, submitting: true}));
    //alert.show('Account created Successfully.',{type: 'success'})
    dispatch(savePassword(inputs.password))
    saveData({ bcrypt_hash: incryptPassword(inputs.password)});
    history.replace({
      pathname: '/newWallet',
      state: inputs.referral
    })
  };
  
  let isValidated = () => {
    let response = true;
    if (inputs.password.length > 16) {
      response = false;
      setInputs((prev) => ({ ...prev, passwordValidation: "Password can't be greater then 16." }));
    }
    if (inputs.confirm.length > 16) {
      response = false;
      setInputs((prev) => ({ ...prev, confirmValidation: "Confirm password can't be greater than 16." }));
    }
    if (inputs.password.length < 8) {
      response = false;
      setInputs((prev) => ({ ...prev, passwordValidation: "Password can't be smaller then 8." }));
    }
    if (inputs.confirm.length < 8) {
      response = false;
      setInputs((prev) => ({ ...prev, confirmValidation: "Confirm password can't be smaller than 8." }));
    }
    if (!inputs.password) {
      response = false;
      setInputs((prev) => ({ ...prev, passwordValidation: "Password Field can't be empty." }));
    }
    if (!inputs.confirm) {
      response = false;
      setInputs((prev) => ({ ...prev, confirmValidation: "Confirm password can't be empty." }));
    }
    if (inputs.password !== "" && inputs.confirm !== "" && inputs.password !== inputs.confirm) {
      response = false;
      setInputs((prev) => ({ ...prev, confirmValidation: "Passwords do not match." }));
    }
    if (!agree.isAgree) {
      response = false;
      setAgree(prev=>({...prev, notAgreed: true}))
    }
    if(state.submitting){
      response = false;
    }

    return response;
  };
  
  return { ...agree, setAgree, ...inputs, handleInputChange, handleSubmit, ...state };
};

export default useRegister;


