import React from "react";
import Modal from "react-modal";
import { closeButton } from "assets/images";
import Loader from "react-loader-spinner";
import useManualZakat from "./hooks/useManualZakat";

function ManualZakat({ onClose, isOpen, setPercentage }) {
  const { assets, submitting, payZakat, setAmount, handleClose } = useManualZakat(onClose, setPercentage);

  const renderLoading = () => (
    <div className="flex justify-center items-center">
      <Loader type="Bars" color="#ffffff" height={20} width={20} />
    </div>
  );

  return (
    <Modal isOpen={isOpen} style={customStyle}>
      <button onClick={handleClose} className="float-right">
        <img src={closeButton} className="w-6 h-6" />
      </button>
      <div className="flex flex-col items-center mt-8 px-6 sm:px-12">
        <h2 className="text-xl text-orange-500 font-semibold mb-8">
          Pay Zakat
        </h2>

        <div className="w-full py-3 text-secondaryGray">
          <table
            className="separated-row"
            style={{ width: "100%" }}
          >
            <tr className="border-b border-secondaryGray">
              <td>Asset</td>
              <td>Balance</td>
              <td className="text-right">Zakat</td>
            </tr>
            {assets.map((asset, index) => (
              <tr key={index} className="bg-gray3">
                <td>
                  <div className="flex text-white items-center p-2 my-2">
                    <img src={asset.image} className="w-6" />
                    <text className="ml-1">{asset.short}</text>
                  </div>
                </td>
                <td>{asset.balance}</td>
                <td className="text-right">
                  <input
                    value={asset.zakat}
                    type="number"
                    onChange={(e) => setAmount(e, index)}
                    className="focus:outline-none bg-transparent w-full border-b text-secondaryGray border-secondaryGray text-right pr-1 mr-1"
                    placeholder="Enter zakat amount"
                  />
                </td>
              </tr>
            ))}
            {assets.length === 0 && (
              <tr className="bg-gray3">
                <td
                  colSpan={3}
                  rowSpan={3}
                  className="text-center text-secondaryGray py-8"
                >
                  No zakat to pay
                </td>
              </tr>
            )}
          </table>
          {/* <button onClick={()=>{}} className="border border-dashed border-gray-600 py-2 mt-2 text-gray-500 flex justify-center items-center w-full focus:outline-none hover:border-gray-400">
      <FontAwesomeIcon icon={faPlus} /> <h3 className="ml-2">Select Asset</h3>
    </button> */}
        </div>
        <div className="flex justify-between w-full mb-10">
          <button
            onClick={payZakat}
            className="bg-green font-regular text-xs text-white w-full py-3 flex justify-center mt-2 mr-3 focus:outline-none"
          >
            {!submitting && "Pay"}
            {submitting && renderLoading()}
          </button>
          <button
            onClick={handleClose}
            className="bg-accent font-regular text-xs text-white w-full py-3 flex justify-center mt-2 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ManualZakat;

const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
    alignItems: "center",
  },
  content: {
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    marginLeft: "20px",
    marginRight: "20px",
    maxWidth: "600px",
    width: "100%",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
