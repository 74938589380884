import * as React from 'react';

export const MenuButton = (props:any)=>{
    return(
        <button
          className={props.isActive? styles.activeBtn:styles.inActiveBtn}onClick={props.onClick}>
          <img src={props.isActive?props.activeImage:props.inActiveImage} className="h-5 w-5"/>
          <p className="ml-2">{props.menuName}</p>
        </button>
    );
}
const styles={
    activeBtn:"bg-accent flex text-white font-regular text-xs items-center  p-4 w-full focus:outline-none",
    inActiveBtn:"bg-transparent flex text-gray4 font-regular text-xs items-center  p-4 w-full focus:outline-none"
  }