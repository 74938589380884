import * as React from "react";
import { NavLink } from "react-router-dom";

const SmallNavButton = ({to, title, onNav}) => {
  return (
    <NavLink
      to={to}
      onClick={onNav}
      className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-accent text-lg"
    >
      {title}
    </NavLink>
  );
};

export default SmallNavButton;
