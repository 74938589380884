import { stringify } from "querystring";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

export function TradeSummary() {
  const { selectedPair } = useSelector((state: RootState) => state.exchange);

  const renderValue = (value) => {
    if (value === "-") {
      return 0;
    }
    return value;
  };
  const renderChange = (value) => {
    if (value === "-") {
      return 0 + "%";
    } else if (value == "Infinity") {
      return 0 + "%";
    }
    return value + "%";
  };
  const getChangePercent = () => {
    let price;
    if (selectedPair.bidsLength === 0 || selectedPair.asksLength === 0) {
      price = selectedPair.lastPrice;
    } else {
      price =
        (Number(selectedPair.buyPrice) + Number(selectedPair.sellPrice)) / 2;
    }

    const close =
      selectedPair.close !== "-" && Number(selectedPair.close) !== 0
        ? Number(selectedPair.close)
        : 0;
    const change = price - close;
    return price === 0 || close === 0
      ? 0
      : ((change / price) * 100)?.toFixed(2);
  };

  return (
    <div className="flex w-full justify-around items-center">
      <div className="justify-center">
        <p className={styles.textHeading}>Change(24h)</p>
        <p
          className={
            styles.textContent +
            (Number(selectedPair.change) > 0 ? " text-green" : " text-red")
          }
        >
          {JSON.stringify(selectedPair.change) == "Infinity"
            ? 0
            : renderChange(selectedPair.change)}
        </p>
      </div>
      <div className="hidden sm:block">
        <p className={styles.textHeading}>Vol(24h)</p>
        <p className={styles.textContent}>
          {renderValue(selectedPair.volume)}{" "}
          {selectedPair.marketCoinCode.toUpperCase()}
        </p>
      </div>
      <div>
        <p className={styles.textHeading}>Low(24h)</p>
        <p className={styles.textContent}>{renderValue(selectedPair.low)}</p>
      </div>
      <div>
        <p className={styles.textHeading}>High(24h)</p>
        <p className={styles.textContent}>{renderValue(selectedPair.high)}</p>
      </div>
      <div className="hidden sm:block">
        <p className={styles.textHeading}>Open(24h)</p>
        <p className={styles.textContent}>{renderValue(selectedPair.open)}</p>
      </div>
      <div>
        <p className={styles.textHeading}>Close(24h)</p>
        <p className={styles.textContent}>{renderValue(selectedPair.close)}</p>
      </div>
    </div>
  );
}

const styles = {
  textHeading: "text-gray4 text-xs xl:text-xs",
  textContent:
    "text-white text-xs md:text-md lg:text-md xl:text-sm font-semibold",
};
