import * as React from 'react';
import { DropDown } from 'components/molecular/P2P/CreateAd/DropDown';
import CustomInput from 'components/global/CustomInput';
import { colors } from 'styles';
import { FormDataRow } from 'components/molecular/P2P/CreateAd/FormDataRow';

export const BankInformation = ({
  bankDetail: {
    buy,
    bank_name,
    bank_account_number,
    accountTitle,
    accountTitleVal,
    bank_account_numberVal,
    bank_nameVal,
  },
  handleInputChange
}) => {
  return (
    <>
      <div className="xl:w-3/6">
        <CustomInput
          labelColor={colors.gray4}
          marginVertical="0.45rem"
          padding={'12px'}
          label="Bank name"
          placeholder="Enter your bank name"
          name="bank_name"
          value={bank_name}
          type="text"
          onChange={handleInputChange}
          valMsg={bank_nameVal}
          maxLength={50}
        />
      </div>
      {buy ? null : (
        <div className="mt-4">
          <div className="xl:w-3/6">
            <CustomInput
              labelColor={colors.gray4}
              marginVertical="0.45rem"
              padding={'12px'}
              label="Account Holder's name"
              placeholder="Enter account holder's name"
              name="accountTitle"
              value={accountTitle}
              type="text"
              onChange={handleInputChange}
              valMsg={accountTitleVal}
              disabled={true}
              maxLength={50}
            />
          </div>
          <div className="xl:w-3/6">
            <CustomInput
              labelColor={colors.gray4}
              marginVertical="0.45rem"
              padding={'12px'}
              label="Bank account number"
              placeholder="Enter your bank account number"
              name="bank_account_number"
              value={bank_account_number}
              type="number"
              onChange={handleInputChange}
              valMsg={bank_account_numberVal}
              maxLength={35}
            />
          </div>
        </div>
      )}

      {/* <div className="text-red italic text-xs">{bankConfirmVal}</div> */}
    </>
  );
};
