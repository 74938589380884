import * as React from "react";
import * as Images from "assets/images/index";
import StarRatingComponent from "react-star-rating-component";
import { Button } from "components/global";
import { theme } from "styles/theme";
import { addTradeReview, getP2pTradeReviews } from "services/p2pServives";
import { useHistory, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import Loader from "react-loader-spinner";
import Reviewed from "./Reviewed";

export const ReviewScreen = (props: any) => {
  const location: any = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const [state, setState] = React.useState({
    reviewing: false,
    tradeId: location?.state?.trade || 0,
    type: location?.state?.type,
    review: "",
    reviewVal: "",
    rating: 0,
    loading: true,
    commentByBuyer: "",
    commentBySeller: "",
    ratingByBuyer: null,
    ratingBySeller: null,
  });
  React.useEffect(() => {
    initialize();
  }, []);
  const initialize = () => {
    const { trade }: any = location.state;
    getP2pTradeReviews(trade)
      .then((data) => {

        const {
          commentByBuyer,
          commentBySeller,
          ratingByBuyer,
          ratingBySeller,
        } = data.data;
        setState((prev) => ({
          ...prev,
          commentByBuyer,
          commentBySeller,
          ratingByBuyer,
          ratingBySeller,
          loading: false
        }));
      })
      .catch((e) => {
        setState((prev)=>({ ...prev, loading: false }))
      });
  };
  const addReview = (type) => {
    setState((prev) => ({ ...prev, reviewing: true }));

    addTradeReview({
      tradeId: state.tradeId,
      ["commentBy" + type]: state.review,
      ["ratingBy" + type]: state.rating,
    })
      .then((data) => {
        setState((prev) => ({ ...prev, reviewing: false }));
        alert.show("Success!", { type: "success" });
        history.replace("/p2p");
      })
      .catch((e) => {
        alert.show(e.response.data.Error || "Error", { type: "error" });
        setState((prev) => ({ ...prev, reviewing: false }));
      });
  };
  const handleSubmit = () => {
    if (state.review == "") {
      setState((prev) => ({ ...prev, reviewVal: "This field id required." }));
      return;
    }
    addReview(state.type);
  };
  const renderLoading = () => (
    <div className="flex justify-center items-center h-full">
      <Loader type="Bars" color="#ffffff" height={20} width={20} />
    </div>
  );
  const renderDataLoading = () => (
    <div className="flex justify-center items-center h-full" style={{height: '60vh'}}>
      <Loader type="Bars" color="#FFFF00" height={30} width={30} />
    </div>
  );

  if(state.loading){
    return renderDataLoading()
  }
  if (
    (state.type === "Seller" && state.ratingBySeller) ||
    (state.type === "Buyer" && state.ratingByBuyer)
  ) {
    return (
      <Reviewed
        ratingByBuyer={state.ratingByBuyer}
        ratingBySeller={state.ratingBySeller}
        commentByBuyer={state.commentByBuyer}
        commentBySeller={state.commentBySeller}
      />
    );
  }
  return (
    <div className="w-4/5 sm:w-4/6 mt-5 mb-5 mx-auto">
      <p className="text-green text-h1 font-regular text-center">
      Successful Trading!
      </p>
      <img
        src={Images.transactionSuccessfullImg}
        className="h-40 w-40 mt-4 m-auto"
      />
      <p className="text-white font-regular text-center text-lg mt-6">
        How was the experience?
      </p>
      <div className="mt-4">
        <p className="text-secondaryGray font-regular text-center">
          Rate this {location.state?.type==="Seller"?"Buyer":"Seller"}
        </p>
        <div
          style={{ letterSpacing: "1vh" }}
          className="text-center mx-auto xl:text-2xl"
        >
          <StarRatingComponent
            starColor={theme.color.accent}
            onStarClick={(rating) =>
              setState((prev) => ({ ...prev, rating: rating }))
            }
            name=""
            value={state.rating}
          />
        </div>
      </div>
      <div className=" sm:w-4/6 mx-auto">
        <textarea
          className="block placeholder-secondaryGray w-full text-secondaryGray self-center bg-primary h-40 resize-none p-5 focus:outline-none"
          placeholder="Type your review"
          onChange={({ target }) =>
            setState((prev) => ({ ...prev, review: target.value }))
          }
        />
        <small className="text-red italic">{state.reviewVal}</small>
      </div>
      <div className="sm:w-4/6 mx-auto">
        <Button
          onClick={handleSubmit}
          styles={["text-sm bg-accent w-full py-4 mt-4 font-regular"]}
        >
          {!state.reviewing && "Submit"}
          {state.reviewing && renderLoading()}
        </Button>
      </div>
    </div>
  );
};
