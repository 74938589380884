import * as React from "react";
import { favoriteIcon } from "assets/images";

export const TableHeading = (props: any) => {
  return (
    <div style={{paddingLeft: 28}} className="overflow-auto flex mt-1 pr-2 mb-1 justify-between w-full items-center">

      {
        props.headings.map((heading: any, index) => {
          return(
            <text key={index} className="text-secondaryGray pr-2 table-cell text-xs w-full text-left md:text-sm">{heading}</text>
          );
        })
      }
    </div>
  );
};
