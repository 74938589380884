import { CREATE_ACCOUNT_PENDING, CREATE_ACCOUNT_SUCCESS, GetAuthActionsType, SAVE_PASSWORD, LOGOUT_USER } from "./AuthActionType";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { BASE_URL, postOptions } from "utils/AppConstants";



export function createAccountSuccess(data: []): GetAuthActionsType {
    return {
      type: CREATE_ACCOUNT_SUCCESS,
      payload: data,
    };
  }
  export function savePassword(password: string): GetAuthActionsType {
    return {
      type: SAVE_PASSWORD,
      password: password,
    };
  }
  export const createAccountPending = (isFetching: boolean): GetAuthActionsType => {
    return { type: CREATE_ACCOUNT_PENDING, isFetching };
  };
  export const logoutUserAction = (): GetAuthActionsType => {
    return { type: LOGOUT_USER, payload: false };
  };

  export const createAccountAction = (
    password: string, referralCode: string, accounts: string,
  ): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch) => {
    
    dispatch(createAccountPending(true));
    try {
      let url = BASE_URL + "/account/newAccount";
      let data = await fetch(url, postOptions({password, accounts, referralCode})).then(res =>res.json());
      
      dispatch(createAccountSuccess(data));
    } catch (error) {
      // console.log(error);
    }
  };