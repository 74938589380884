import { personImage2 } from "assets/images";
import React from "react";
import { BASE_URL } from "utils/AppConstants";

function DisputeMessage({ name, role, message, files, openImage, photo, index }) {
  const me = role === "me";
  return (
    <div
      key={index}
      className={`flex flex-col justify-start ${me ? "items-end" : "items-start"}`}
    >
        {ImagesWidget({files, me, openImage})}
      <div className={`flex items-start ${me ? "justify-end" : ""}`}>
        {!me && (
          <div className="flex flex-col">
            <img src={`${BASE_URL}/files/${photo}`} className="w-10 h-10 rounded-lg" />
            <small className="text-white text-xs">{name}</small>
            {/* <small className="text-gray4">{role}</small> */}
          </div>
        )}
        {message!==""&&<div
          className="px-4 py-2 text-white text-xs bg-primary ml-4 mt-1"
          style={{
            borderRadius: "13px",
            borderBottomLeftRadius: me ? "13px" : "0px",
            borderBottomRightRadius: me ? "0px" : "13px",
          }}
        >
          {message}
        </div>}
      </div>
    </div>
  );
}

export default DisputeMessage;

function ImagesWidget({files, me, openImage}) {
  if(files === "" || !files){
     return
  }
  const imgs = files.split(',')
  if(imgs.length === 1){
    return(
      <img
          src={`${BASE_URL}/p2ptemp-files/${imgs[0]}`}
          className="mt-1 cursor-pointer"
          onClick={()=>openImage(imgs)}
          style={{
            borderRadius: "13px",
            borderBottomLeftRadius: me ? "13px" : "0px",
            borderBottomRightRadius: me ? "0px" : "13px",
            width: '120px',
            height: '120px'
          }}
        />
    )
  }
  if(imgs.length === 2){
    return(
      <div className="flex mt-1"
      style={{
        borderRadius: "13px",
        borderBottomLeftRadius: me ? "13px" : "0px",
        borderBottomRightRadius: me ? "0px" : "13px",
      }}
      >
        <img
          onClick={()=>openImage(imgs)}
        src={`${BASE_URL}/p2ptemp-files/${imgs[0]}`}
        className="mr-1 w-20 h-20"
      />
      <img
        onClick={()=>openImage(imgs)}
        src={`${BASE_URL}/p2ptemp-files/${imgs[1]}`}
        className="mr-1 w-20 h-20"
      />
      </div>
    )
  }
  if(imgs.length === 3){
    return(
      <div className="mt-1"
      style={{
        borderRadius: "13px",
        borderBottomLeftRadius: me ? "13px" : "0px",
        borderBottomRightRadius: me ? "0px" : "13px",
      }}
      >
        <div className="flex">
        <img
          onClick={()=>openImage(imgs)}
        src={`${BASE_URL}/p2ptemp-files/${imgs[0]}`}
        className="mr-1 w-20 h-20"
      />
      <img
        onClick={()=>openImage(imgs)}
        src={`${BASE_URL}/p2ptemp-files/${imgs[1]}`}
        className="mr-1 w-20 h-20"
      />
      </div>
      <img
        onClick={()=>openImage(imgs)}
        src={`${BASE_URL}/p2ptemp-files/${imgs[2]}`}
        className="mr-1 w-20 h-20 mt-1"
      />
      </div>
    )
  }
  if(imgs.length > 3){
    return(
      <div className="mt-1"
      style={{
        borderRadius: "13px",
        borderBottomLeftRadius: me ? "13px" : "0px",
        borderBottomRightRadius: me ? "0px" : "13px",
      }}
      >
        <div className="flex">
        <img
          onClick={()=>openImage(imgs)}
        src={`${BASE_URL}/p2ptemp-files/${imgs[0]}`}
        className="mr-1 w-20 h-20"
      />
      <img
        onClick={()=>openImage(imgs)}
        src={`${BASE_URL}/p2ptemp-files/${imgs[1]}`}
        className="mr-1 w-20 h-20"
      />
      </div>
      <div className="flex">
      <img
        onClick={()=>openImage(imgs)}
        src={`${BASE_URL}/p2ptemp-files/${imgs[2]}`}
        className="mr-1 w-20 h-20 mt-1"
      />
      <button onClick={()=>openImage(imgs)} className="w-20 h-20 flex justify-center items-center bg-gray3 mt-1 text-white text-xs">See More</button>
      </div>
      </div>
    )
  }

}
