import React, { useEffect, Fragment } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";

function MyTradesHistory({ data, getTrades, headings, loading, onNext }) {
  useEffect(() => {
    // if (data.length < 1) {
      getTrades(data.length > 0);
    // }
  }, []);

// const onHitBottom = (e) => {
//   console.log("event", e.scrollTop)
// }
  return (
    <table className="w-full">
      <thead style={{ display: "block" }}>
        <tr className="border-b text-left border-primary text-secondaryGray text-xs flex">
          <td className="py-2 w-full">Date</td>
          <td className="py-2 w-full">Pair</td>
          <td className="py-2 w-full">Type</td>
          <td className="py-2 w-full">Price</td>
          <td className="py-2 w-full">Amount</td>
          <td className="py-2 w-full">Total</td>
        </tr>
      </thead>
      <tbody
        style={{ height: "235px" }}
        className="customBar flex flex-col items-center justify-between overflow-y-scroll w-full"
        // onScroll={onHitBottom}
      >
        {data.map((item: any, index) => (
            <tr key={index} className="text-gray4 text-xxs md:text-xs text-left flex w-full mb-3">
              <td className="w-full">
                {moment(item.date).format("lll")}
              </td>
              <td className="w-full">{item.pair}</td>
              <td className={`w-full ${item.type==='BUY'?'text-green':'text-red'}`}>{item.type}</td>
              <td className={`w-full ${item.type==='BUY'?'text-green':'text-red'}`}>{item.price}</td>
              <td className="w-full">{item.amount}</td>
              <td className="w-full">{item.total}</td>
            </tr>
          ))}
        {!loading && data.length < 1 && (
          <tr className="flex justify-center items-center h-full">
            <td colSpan={6} rowSpan={6}>
              <small className="text-gray4 text-xs">No Trades</small>
            </td>
          </tr>
        )}
        {loading && (
          <tr className="flex justify-center items-center h-full">
            <td colSpan={6} rowSpan={6}>
              <Loader type="Bars" color="#FFFF00" height={30} width={30} />
            </td>
          </tr>
        )}
        {data.length > 0 && !loading && (
          <tr className="flex justify-center items-center h-full">
            <td colSpan={6} rowSpan={6}>
            <button onClick={onNext} className="focus:outline-none border border-secondaryGray p-1 text-secondaryGray my-1">
              Load More
            </button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default MyTradesHistory;
