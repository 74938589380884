import { useState } from "react"
import { useDispatch } from "react-redux"
import { searchAddsAction } from "store/actions/P2pActions"

function useSearch(buy) {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        amount: "",
        loading: false,
        amountVal: ""
    })

    const submit = () => {
        if(state.amount === ""){
            setState(prev=>({...prev, amountVal: "This field is required."}))
            return
        }
        dispatch(searchAddsAction((buy?'sell':'buy'), state.amount))
    }
    
    const handleInputChange = (event) => {
        const {value} = event.target
        setState(prev=>({...prev, amount: value, amountVal: ""}))
    }
    return {...state, submit, handleInputChange}
}

export default useSearch;
