import { User, Document } from "store/models/User";

export const FETCH_PROFILE_PENDING = "FETCH_PROFILE_PENDING";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const ADD_USER_DATA = "ADD_USER_DATA";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const PHONE_VERIFIED = "PHONE_VERIFIED";
export const SET_INPUT_PROPERTY = "SET_INPUT_PROPERTY";
export const DELETE_KYC_PENDING = "DELETE_KYC_PENDING";
export const SET_PROFILE_PICTURE = "SET_PROFILE_PICTURE";

interface FetchProfileSuccess {
  type: typeof FETCH_PROFILE_SUCCESS;
  user: User;
  document: Document;
}

interface FetchProfileFailure {
  type: typeof FETCH_PROFILE_FAILURE;
  error: boolean
}
interface SetProfilePicture {
  type: typeof SET_PROFILE_PICTURE;
  photo: string
}
interface DeleteKycPending {
  type: typeof DELETE_KYC_PENDING;
}
interface SetInputProperty {
  type: typeof SET_INPUT_PROPERTY;
  key: string;
  value: string;
}
export interface FetchProfilePanding {
  type: typeof FETCH_PROFILE_PENDING;
  isFetching: boolean;
}
interface AddDocumentSuccess {
  type: typeof ADD_DOCUMENT_SUCCESS;
  payload: Document;
}
interface ClearProfile {
  type: typeof CLEAR_PROFILE;
}
interface EmailVerified {
  type: typeof EMAIL_VERIFIED;
  email: string;
}
interface PhoneVerified {
  type: typeof PHONE_VERIFIED;
  phone: string;
  country: string;
  dial: string;
  isCompany: boolean
}
interface AddUserData {
  type: typeof ADD_USER_DATA;
  payload: any;
}

export type GetProfileInfoType =
  | FetchProfileSuccess
  | FetchProfilePanding
  | AddDocumentSuccess
  | ClearProfile
  | AddUserData
  | DeleteKycPending
  | EmailVerified
  | FetchProfileFailure
  | SetInputProperty
  | SetProfilePicture
  | PhoneVerified;
