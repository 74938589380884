import { getChange } from "utils/AppConstants";
import {
  FETCH_MARKET_PENDING,
  GetMarketInfoType,
  SELECT_MARKET_COIN,
  ADD_TO_FAVOURITES,
  REMOVE_FROM_FAVOURITES,
  FETCH_TOP_BAR_SUCCESS,
  SEARCH_MARKET_DATA,
  REFRESH_MARKET_DATA,
  UPDATE_CLOSE_PRICE,
} from "../actions/MarketTypes";
import { MarketState, MarketInfo, CoinMarket } from "../models/MarketInfo";

const init:CoinMarket = {name: "", data:[]}
const initialState: MarketState = {
  markets: [],
  selected: init,
  search: "",
  loading: true,
  refresh: false,
  showLoading: true,
};

export function marketReducer(
  state = initialState,
  action: GetMarketInfoType
): MarketState {
  switch (action.type) {
      case REFRESH_MARKET_DATA:
        return {
          ...state,
          markets: action.market,
          selected: action.market[1],
          loading: false,
          refresh: action.reload,
          showLoading: action.reload,
        };
    case FETCH_MARKET_PENDING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CLOSE_PRICE:
      const markets = [...state.markets]
      const marketIndex = markets.findIndex((item)=> item.name === action.base)
      if(marketIndex !== -1){
        const marketDataIndex = markets[marketIndex].data.findIndex(item=>item.counter === action.counter)
        if(marketDataIndex !== -1){
          const close = markets[marketIndex].data[marketDataIndex].close
          markets[marketIndex].data[marketDataIndex].closePrev = close
          markets[marketIndex].data[marketDataIndex].change = getChange(action.price, close)
          markets[marketIndex].data[marketDataIndex].close = action.price
        }
      }
      return {
        ...state,
        markets: markets
      }
    case FETCH_TOP_BAR_SUCCESS:
      return {
        ...state,
        [action.currency]: action.data,
      };
    case SELECT_MARKET_COIN:
      return {
        ...state,
        selected: action.selected,
      };
    case SEARCH_MARKET_DATA:
      return {
        ...state,
        search: action.data,
      };
     case ADD_TO_FAVOURITES:
      let original = [...state.markets]
      for(var i=0;i<original.length;i++){
        const market = original[i]
        if(market.name === action.data.base){
          for (let j = 0; j < market.data.length; j++) {
            const element = market.data[j];
            if(element.base == action.data.base && element.counter == action.data.counter){
              original[i].data[j] = {...element, is_fav: true}
            }
          }
        }
      }
      let newSelected = {...state.selected}
      for (let k = 0; k < newSelected.data.length; k++) {
        const element = newSelected.data[k];
        if(element.base == action.data.base && element.counter == action.data.counter){
          newSelected.data[k] = {...element, is_fav: true}
        }
      }
      //updating storage
      const prevFav = JSON.parse(localStorage.getItem('fav_market')||'[]')
      prevFav.push(action.data.counter+"/"+action.data.base)
      localStorage.setItem('fav_market', JSON.stringify(prevFav))
      original[0] = {...original[0], data: [...original[0].data, action.data]}
      return {
        ...state,
        markets: original,
        selected: newSelected,
      };
    case REMOVE_FROM_FAVOURITES:
      let original2 = [...state.markets]
      for(var i=0;i<original2.length;i++){
        const market = original2[i]
        if(market.name === action.data.base){
          for (let j = 0; j < market.data.length; j++) {
            const element = market.data[j];
            if(element.base == action.data.base && element.counter == action.data.counter){
              original2[i].data[j] = {...element, is_fav: false}
            }
          }
        }
      }
      let newSelected2 = {...state.selected}
      for (let k = 0; k < newSelected2.data.length; k++) {
        const element = newSelected2.data[k];
        if(element.base == action.data.base && element.counter == action.data.counter){
          newSelected2.data[k] = {...element, is_fav: false}
        }
      }
      
      let fav:any = []
      original2[0].data.forEach(element => {
        if(element.base !== action.data.base || element.counter !== action.data.counter){
          fav.push(element)
        }
      });
      original2[0] = {...original2[0], data: fav}
      if(action.coin === "Favorite"){
      newSelected2 = {...newSelected2, data: fav}
      }
      //updating storage
      let prevFav2 = JSON.parse(localStorage.getItem('fav_market')||'[]')
      prevFav2 =prevFav2.filter((item)=>item !== action.data.counter+"/"+action.data.base)
      localStorage.setItem('fav_market', JSON.stringify(prevFav2))

      return {
        ...state,
        markets: original2,
        selected: newSelected2,
      };
    default:
      return state;
  }
}
