import * as React from "react";
import TradeTab from "./TradeTab";

function TradeTabs({ tabs, onSelect, selected }) {
  return (
    <div className="flex border-b border-primary mb-2 md:w-4/6 md:mx-auto lg:w-4/6 ld:mx-auto xl:w-4/6 xl:mx-auto">
      {tabs.map((tab, index) => (
        <TradeTab tab={tab} active={index===selected} onClick={()=>onSelect(index)} />
      ))}
    </div>
  );
}

export default TradeTabs;
