import { closeButton } from "assets/images";
import React from "react";
import Modal from "react-modal";
import Loader from "react-loader-spinner";
import { Button } from "components/global";
import { tradeConfirmMessageEnum } from "utils/AppConstants";

function ConfirmDispute({
  isOpen,
  close,
  onSelect,
  action
}) {
  const renderLoading = () => (
    <div className="flex justify-center items-center">
      <Loader type="Bars" color="#ffffff" height={20} width={20} />
    </div>
  );
  return (
    <Modal isOpen={isOpen} style={customStyle}>
      <div className="flex justify-between border-b border-secondaryGray pb-2 px-2">
        <h2 className="text-gray4 text-lg">Are you sure?</h2>
        <button onClick={close}>
          <img src={closeButton} className="w-6 h-6" />
        </button>
      </div>
      <p className="text-secondaryGray text-lg mt-4 mx-2">
        {tradeConfirmMessageEnum[action]}
      </p>
      <div className="flex w-full justify-between mt-4">
        <Button
          onClick={onSelect}
          styles={[
            "text-sm bg-green md:mr-2 lg:mr-2 xl:mr-2 py-4 mt-3 ml-2 w-full font-regular",
          ]}
        >
          Proceed
        </Button>
        <Button
          onClick={close}
          styles={[
            "text-sm bg-pink md:mr-2 lg:mr-2 xl:mr-2 py-4 mt-3 w-full font-regular",
          ]}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmDispute;

const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
    alignItems: "center",
  },
  content: {
    top: "100px",
    left: "auto",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    marginLeft: "20px",
    marginRight: "20px",
    maxWidth: "500px",
    width: "100%",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
