import * as React from "react";

export function TradeInfoChip({heading, value}) {
    return <div className="w-full border-b border-primary px-2 py-1 text-left">
    <label className="text-xs text-secondaryGray">
      {heading}
    </label>
    <h5 className="text-gray4 text-sm">
      {value}
    </h5>
  </div>
}