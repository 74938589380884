import * as React from "react";
import * as Images from 'assets/images/index';
import {lossGraphCard, sendArrowIcon, receiveArrowIcon, profitGraphCard } from "assets/images/images";
import { colors } from "styles";
import { theme } from "styles/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { stellarNetwork } from "utils/AppConstants";

export const TransactionsDataRow = (props:any) => {

  return (
      <div className="border-primary border-b flex text-white items-center py-3 overflow-auto">
        <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <img src = {images[props.status] ?? ((props.kind=="Received"||props.kind == "Deposit")? Images.receiveArrowIcon :Images.sendArrowIcon)} className={props.status== "pending_anchor"? styles.iconStyle: styles.iconStyle2}/>
              <div className="ml-4">
                <p style={{color:'white'}} className={styles.textStyle}>{props.kind} {props.cryptocurrency}</p>
                <p className={styles.DateStyle}>{props.date}, {props.time}</p>
              </div>
            </div>

            <div className="flex">
              <p className={styles.AmountStyle}>{props.amount} {props.currency?.toUpperCase()}</p>
              <p style={{color: colorsEnum[props.status] ?? ((props.kind=="Received"||props.kind == "Deposit")? theme.color.green :theme.color.accent)}} className={styles.DateStyle}> {props.cryptocurrency}</p>
              {props.stTrId && <button onClick={props.onCopy} className="mr-3 ml-8 focus:outline-none"><FontAwesomeIcon icon={faCopy} color="#ffffff" /></button>}
              {props.stTrId && <a href={`https://stellar.expert/explorer/${stellarNetwork}/tx/${props.stTrId}`} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} color="#ffffff" /></a>}
            </div>
        </div>
      </div>
  );
};

const styles={
  textStyle:"text-xxxs text-secondaryGray md:text-sm lg:text-lg xl:text-lg",
  DateStyle:"text-xxxs text-secondaryGray md:text-xs lg:text-xs xl:text-xs",
  AmountStyle:"mr-2 text-xxxs text-secondaryGray md:text-xs lg:text-xs xl:text-xs",
  iconStyle:"h-3 w-3 md:h-6 md:w-6 lg:h-6 lg:w-6 xl:h-6 xl:w-6",
  iconStyle2:"h-3 w-2 md:h-6 md:w-5 lg:h-6 lg:w-5 xl:h-6 xl:w-5",
}

const images = {
  pending_anchor: Images.progressIcon,
  pending_stellar: Images.progressIcon,
  pending_trust: Images.progressIcon,
  pending_user: Images.progressIcon,
  pending_user_transfer_start: Images.progressIcon,
  pending_external: Images.progressIcon,
  completed: null,
  incomplete: 'assets/Error.png',
  no_market: 'assets/Error.png',
  too_small: 'assets/Error.png',
  too_large: 'assets/Error.png',
  error: 'assets/Error.png',
}
const colorsEnum = {
  pending_anchor: theme.color.secondaryGray,
  pending_stellar: theme.color.secondaryGray,
  pending_trust: theme.color.secondaryGray,
  pending_user: theme.color.secondaryGray,
  pending_user_transfer_start: theme.color.secondaryGray,
  pending_external: theme.color.secondaryGray,
  completed: null,
  incomplete: theme.color.red,
  no_market: theme.color.red,
  too_small: theme.color.red,
  too_large: theme.color.red,
  error: theme.color.red,
}
