import Loader from "react-loader-spinner";
import DisputeMessage from "components/atomic/p2p/DisputeMessage";
import React, { useEffect, useRef } from "react";
import useP2pDispute from "screens/hooks/P2P/useP2pDispute";
import { BASE_URL, tradeStatusEnum } from "utils/AppConstants";
import ImageGallery from "./ImageGallery";
import StateAwareInput from "components/global/StateAwareInput";
import { getSelectedKeys } from "services/trannsactionServices";

function DisputeChat({
  tradeId,
  addAccount,
  tradeAccount,
  setMessages,
  chatMessages,
  tradeType,
  claiming,
  status,
  claimAmountRecivedSeller,
}) {
  const {
    loading,
    me,
    files,
    message,
    file,
    sending,
    isModalOpen,
    selectedImgs,
    setMessage,
    uploadFile,
    selectFile,
    removeUpload,
    openImage,
    closeModal,
    sendMessage,
  } = useP2pDispute(tradeId, setMessages, chatMessages);
  let chatRef = useRef<HTMLDivElement>(null);

  const getImage = () => {
    let response: any = null;
    if (message !== "") {
      response = "send";
    }
    if (files.length > 0) {
      response = "send";
    }
    if (response) {
      return response;
    }

    return "camera";
  };
  const renderSend = () => (
    <button
      type="submit"
      className="rounded-full flex my-1 mx-3 justify-center items-center h-10 w-10 bg-gray3"
    >
      <img src={`assets/send.svg`} />
    </button>
  );
  const renderUpload = () => (
    <button
      onClick={selectFile}
      type="button"
      className="rounded-full flex my-1 mx-3 justify-center items-center h-10 w-10 bg-gray3"
    >
      <img src={`assets/camera.svg`} />
    </button>
  );
  const renderLoading = () => (
    <div className="px-3 py-2">
      <Loader type="Oval" color="#00BFFF" height={30} width={30} />
    </div>
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (message !== "" || files.length > 0) {
      sendMessage();
    }
  };
  const shouldRenderResolve = () => {
    if (
      (tradeType === "buy" ? addAccount : tradeAccount) ===
        getSelectedKeys().public &&
      (status === tradeStatusEnum.Disputed ||
        status === tradeStatusEnum.FundsSent)
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (chatRef.current)
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
  });

  return (
    <div className="md:w-4/6 md:mx-auto lg:w-4/6 ld:mx-auto xl:w-4/6 xl:mx-auto mt-4">
      <div className="bg-primary w-full p-4 flex justify-between">
        <h1 className="text-white text-lg">Chat</h1>
        {shouldRenderResolve() && (
          <button
            onClick={claimAmountRecivedSeller}
            className={`focus:outline-none bg-green rounded-full ${
              claiming ? "px-10" : "px-2"
            } py-1 text-xs text-white`}
          >
            {!claiming && "I have received the amount"}
            {claiming && (
              <Loader type="Bars" color="#00BFFF" height={10} width={10} />
            )}
          </button>
        )}
      </div>
      <div className="border border-primary">
        <div
          className="p-5 overflow-y-auto nobar"
          ref={chatRef}
          style={{ height: "300px" }}
        >
          {loading && (
            <div className="flex justify-center items-center h-full w-full">
              <Loader type="Bars" color="#ffffff" height={30} width={30} />
            </div>
          )}
          {!loading &&
            chatMessages.map((message: any, index) => (
              <DisputeMessage
                index={index}
                name={message.kyc.firstName}
                photo={message.kyc.photo}
                role={me === message.accountId ? "me" : "Buyer"}
                message={message.message}
                files={message.fileNames}
                openImage={openImage}
              />
            ))}
        </div>
        <form
          onSubmit={handleSubmit}
          className="bg-primary rounded flex justify-between m-1"
        >
          <input
            type="file"
            name="file"
            value={file}
            onChange={uploadFile}
            id="p2pDisputeFile"
            className="inputfile"
          />
          <div className="w-full">
            <div className="flex">
              {files.map((file, index) => (
                <div key={index} className="h-16 relative my-2 mx-1">
                  <button
                    className="focus:outline-none bg-gray4 rounded-full absolute"
                    style={{ right: "3px", top: "3px" }}
                    type="button"
                    onClick={() => removeUpload(file)}
                  >
                    <img src="assets/cross.svg" />
                  </button>
                  <img
                    className="h-16 w-16 rounded-lg"
                    src={`${BASE_URL}/p2ptemp-files/${file}`}
                  />
                </div>
              ))}
              {files.length > 0 && (
                <button
                  onClick={selectFile}
                  type="button"
                  className="focus:outline-none h-16 w-16 border border-gray4 border-dashed rounded-lg my-2 mx-1 flex justify-center items-center"
                >
                  <img src="assets/plus-icon.svg" />
                </button>
              )}
            </div>
            <input
              placeholder="Please Enter Your Message!"
              style={{ resize: "none" }}
              value={message}
              id="textInput"
              onChange={setMessage}
              className="focus:outline-none bg-transparent w-full text-xs p-2 text-gray4"
            />
          </div>
          <div className="flex items-end">
            {getImage() === "send"
              ? sending
                ? renderLoading()
                : renderSend()
              : sending
              ? renderLoading()
              : renderUpload()}
          </div>
        </form>
      </div>
      <p className="text-secondaryGray text-xs mt-4">
        Please contact support in case you are unable to resolve the dispute.
      </p>
      <div className="flex justify-center">
        <text className="text-secondaryGray text-xs mb-4">Support Email:</text>
        <a
          href="mailto:support@gwalletglobal.com"
          className="text-accent text-xs mb-4 ml-2"
        >
          support@gwalletglobal.tech
        </a>
      </div>
      <ImageGallery
        isOpen={isModalOpen}
        files={selectedImgs}
        onClose={closeModal}
      />
    </div>
  );
}

export default DisputeChat;
