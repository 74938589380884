import { CionP2p } from "store/models/P2pState";

export const INIT_P2P = "INIT_P2P";
export const INIT_P2P_ERROR = "INIT_P2P_ERROR";
export const FETCH_ADDS_PENDING = "FETCH_ADDS_PENDING";
export const FETCH_ADDS_SUCCESS = "FETCH_ADDS_SUCCESS";
export const FETCH_ADDS_ERROR = "FETCH_ADDS_ERROR";
export const SELECT_COIN_NAME_PENDING = "SELECT_COIN_NAME_PENDING";
export const SELECT_COIN_NAME_ERROR = "SELECT_COIN_NAME_ERROR";
export const SELECT_COIN_NAME = "SELECT_COIN_NAME";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const FETCH_TRADES_PENDING = "FETCH_TRADES_PENDING";
export const FETCH_TRADES_SUCCESS = "FETCH_TRADES_SUCCESS";
export const FETCH_ACTIVE_TRADES_PENDING = "FETCH_ACTIVE_TRADES_PENDING";
export const FETCH_ACTIVE_TRADES_SUCCESS = "FETCH_ACTIVE_TRADES_SUCCESS";
export const APPEND_ADD = "APPEND_ADD";
export const APPEND_TRADE = "APPEND_TRADE";
export const UPDATE_TRADE = "UPDATE_TRADE";
export const FETCH_MY_ADDS_PENDING = "FETCH_MY_ADDS_PENDING";
export const FETCH_MY_ADDS_PENDING_WITH_TYPE = "FETCH_MY_ADDS_PENDING_WITH_TYPE";
export const FETCH_MY_ADDS_SUCCESS = "FETCH_MY_ADDS_SUCCESS";
export const FETCH_MY_NEXT_ADDS = "FETCH_MY_NEXT_ADDS";
export const FETCH_PRICES_PENDING = "FETCH_PRICES_PENDING";
export const FETCH_PRICES_SUCCESS = "FETCH_PRICES_SUCCESS";
export const CHANGE_ADD_STATUS = "CHANGE_ADD_STATUS";
export const UPDATE_ADD = "UPDATE_ADD";
export const REMOVE_ADD = "REMOVE_ADD";
export const REMOVE_ADD_NEW_STATUS = "REMOVE_ADD_NEW_STATUS";
export const RE_INIT_P2P = "RE_INIT_P2P";
export const LOAD_MORE_DATA = "LOAD_MORE_DATA";
export const SET_COIN_ACTION = "SET_COIN_ACTION";
export const SEARCHING_ADDS = "SEARCHING_ADDS";
export const RESTORE_ADDS = "RESTORE_ADDS";
export const SAVE_USD_RATES = "SAVE_USD_RATES";

export interface InitP2p {
  type: typeof INIT_P2P;
  coins: CionP2p[];
}
export interface SaveUsdRates {
  type: typeof SAVE_USD_RATES;
  rates: []
}
export interface SearchingAdds {
  type: typeof SEARCHING_ADDS;
}
export interface RestoreAdds {
  type: typeof RESTORE_ADDS;
}
export interface SetCoinAction {
  type: typeof SET_COIN_ACTION
  name: string;
  coin: CionP2p;
}

export interface InitP2pError {
  type: typeof INIT_P2P_ERROR;
  coins: CionP2p[];
}

export interface ReInitP2p {
  type: typeof RE_INIT_P2P;
}
export interface LoadMoreData {
  type: typeof LOAD_MORE_DATA;
}

export interface UpdateAdd {
  type: typeof UPDATE_ADD;
  add: any;
}

export interface RemoveAdd {
  type: typeof REMOVE_ADD;
  add: any;
}
export interface RemoveAddStatusChange {
  type: typeof REMOVE_ADD_NEW_STATUS;
  add: any;
}

interface FetchAddsSuccess {
  type: typeof FETCH_ADDS_SUCCESS;
  coin: CionP2p;
  active: number;
}

interface FetchTradesSuccess {
  type: typeof FETCH_TRADES_SUCCESS;
  trades: any;
  current: number;
  totalPages: number;
}
interface FetchActiveTradesSuccess {
  type: typeof FETCH_ACTIVE_TRADES_SUCCESS;
  trades: any;
  current: number;
  totalPages: number;
}

interface FetchPricesPending {
  type: typeof FETCH_PRICES_PENDING;
}

interface FetchPricesSuccess {
  type: typeof FETCH_PRICES_SUCCESS;
  buying: string;
  selling: string;
  coin: string;
}

export interface FetchTradesPanding {
  type: typeof FETCH_TRADES_PENDING;
  active: number;
}

export interface FetchActiveTradesPending {
  type: typeof FETCH_ACTIVE_TRADES_PENDING;
}

export interface FetchAddsPanding {
  type: typeof FETCH_ADDS_PENDING;
  active: number;
}
export interface FetchMyAddsPanding {
  type: typeof FETCH_MY_ADDS_PENDING;
  active: number;
}
export interface FetchMyAddsPandingWithType {
  type: typeof FETCH_MY_ADDS_PENDING_WITH_TYPE;
  active: number;
  category: string
}

export interface FetchMyAddsSuccess {
  type: typeof FETCH_MY_ADDS_SUCCESS;
  buyAdds: [];
  sellAdds: [];
  coin: string;
}
export interface FetchMyNextAdd {
  type: typeof FETCH_MY_NEXT_ADDS;
  adds: [];
  category: string;
  coin: string;
  page: number;
}

export interface FetchAddsError {
  type: typeof FETCH_ADDS_ERROR;
  error: string;
  active: number;
}

export interface SelectCoinName {
  type: typeof SELECT_COIN_NAME;
  name: string;
  coin: CionP2p;
}

export interface SelectCoinNamePending {
  type: typeof SELECT_COIN_NAME_PENDING;
  name: string;
}

export interface SelectCoinNameError {
  type: typeof SELECT_COIN_NAME_ERROR;
  name: string;
}

export interface AppendTrade {
  type: typeof APPEND_TRADE;
  trade: any;
  coin: string;
}

export interface SetSelectedTab {
  type: typeof SET_ACTIVE_TAB;
  tab: string;
}

export interface ChangeAddStatus {
  type: typeof CHANGE_ADD_STATUS;
  coin: string;
  id: number;
  side: string;
}

export interface UpdateTrade {
  type: typeof UPDATE_TRADE;
  trade: any;
}

export interface AppendAdd {
  type: typeof APPEND_ADD;
  add: any;
  code: string;
  side: string;
}

export type GetP2pActionsType =
  | FetchAddsSuccess
  | FetchAddsPanding
  | SelectCoinName
  | SetCoinAction
  | SetSelectedTab
  | InitP2p
  | FetchActiveTradesPending
  | FetchActiveTradesSuccess
  | LoadMoreData
  | FetchTradesPanding
  | FetchTradesSuccess
  | AppendAdd
  | RestoreAdds
  | AppendTrade
  | UpdateTrade
  | SaveUsdRates
  | ChangeAddStatus
  | FetchMyNextAdd
  | RemoveAddStatusChange
  | FetchMyAddsPandingWithType
  | SearchingAdds
  | FetchPricesPending
  | FetchMyAddsPanding
  | FetchPricesSuccess
  | FetchMyAddsSuccess
  | SelectCoinNamePending
  | SelectCoinNameError
  | UpdateAdd
  | InitP2pError
  | RemoveAdd
  | ReInitP2p
  | FetchAddsError;
