import * as React from "react";

export const LiquidityDataRow = (props: any) => {
  return (
    <tr className="text-left bg-primary cursor-pointer">
      <td className="flex flex-row">
        <div className="flex ml-2 items-center">
          <img src={props.coinIcon1} className={styles.iconStyle} />
          <div className="flex flex-col ml-2">
            <text
              style={{ color: "white" }}
              className={styles.textStyle + " " + "font-bold"}
            >
              {props.short1}
            </text>
            <small className="text-xxs" style={{ color: "white" }}>
              {props.fullName1}
            </small>
          </div>
        </div>
        <div className="text-white mx-3 self-center">+</div>
        <div className="flex items-center">
          <img src={props.coinIcon2} className={styles.iconStyle} />
          <div className="flex flex-col ml-2">
            <text
              style={{ color: "white" }}
              className={styles.textStyle + " " + "font-bold"}
            >
              {props.short2}
            </text>
            <small className="text-xxs" style={{ color: "white" }}>
              {props.fullName2}
            </small>
          </div>
        </div>
      </td>
      <td>
        <div className={styles.textStyle + " flex items-center"}>
          <text className="mr-1">{props.asset1_amount}</text>
          {props.short1}
        </div>
      </td>
      <td>
        <div className={styles.textStyle + " flex items-center"}>
          <text className="mr-1">{props.asset2_amount}</text>
          {props.short2}
        </div>
      </td>
      <td>
        <div className={styles.textStyle + " flex items-center"}>
          <text className={styles.textStyle}>{props?.totalShares}</text>
        </div>
      </td>
      <td>
        <text className={styles.textStyle + " flex items-center"}>
          {props.participants}
        </text>
      </td>
    </tr>
  );
};

const styles = {
  textStyle: "text-xxxs text-secondaryGray md:text-xs lg:text-sm xl:text-sm",
  iconStyle: "h-4 w-4 md:h-6 md:w-6 lg:h-6 lg:w-6 xl:h-6 xl:w-6",
};
