import React from "react";

function KycStatusTab({ trailing, active, bottom, bottomMargin }) {
  return (
    <div className="w-full relative">
      <div className={`w-full h-2 bg-${active?'green':'primary'}`}>
        <img
          src={trailing}
          className="absolute z-10 w-10 h-10"
          style={{ right: "-10px", top: "-15px" }}
        />
      </div>
      <p className={`${active?'text-white':'text-primary'} mt-2 text-xs absolute font-semibold`} style={{ right: bottomMargin, top: "25px" }}>{bottom}</p>
    </div>
  );
}

export default KycStatusTab;

export function KycStatusTab2({ leading, trailing, active, bottom, isCompany }) {
  return (
    <div className="w-full relative">
      <div className={`w-full h-2 bg-${active?'green':'primary'}`}>
        {leading && (
          <img
            src={leading}
            className="absolute z-10 w-10 h-10"
            style={{ left: "-10px", top: "-15px" }}
          />
        )}
        <img
          src={trailing}
          className="absolute z-10 w-10 h-10"
          style={{ right: "-10px", top: "-15px" }}
        />
      </div>
      <p className={`${active?'text-white':'text-primary'} mt-2 text-xs absolute font-semibold`} style={{ left: "-20px", top: "25px" }}>What is KYC?</p>
      <p className={`${active?'text-white':'text-primary'} mt-2 text-xs absolute font-semibold`} style={{ right: "-35px", top: "25px" }}>
        {isCompany ? "Company Information" : "User Information"}
        </p>
    </div>
  );
}

