import * as React from "react";
import { AdLeftCol } from "components/organisms/markets/P2P/CreateAd/AdLeftCol";

export const CreateAdScreen = () => {
  const [buy,setBuy] = React.useState(true);
  return (
    <div className="w-5/6 mx-auto">
      <div className="border-primary border-b pt-4 mb-2">
        <p className="text-white text-xl mb-4 font-regular">Create an Ad</p>
        <button 
          onClick={()=>setBuy(true)}
          className={`${buy ? "text-green border-b-2 border-green" : "text-gray4"} text-md pb-4 font-regular focus:outline-none`}
        >
          I want to Buy
        </button>
        <button 
            onClick={()=>setBuy(false)}
            className={`${!buy ? "text-pink border-b-2 border-pink" : "text-gray4"}  ml-4 text-md pb-4 font-regular focus:outline-none`}
          >
            I want to Sell
        </button>
      </div>
      <AdLeftCol buy={buy}/>
    </div>
  );
};
