import * as React from "react";
import { WalletCard } from "components/molecular/Wallet/WalletCard";
import {
  ActivationStatusCard,
  ActivationStatusCard2,
} from "components/molecular/Wallet/ActivationStatusCard";
import { lockIcon } from "assets/images/index";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import {
  setCoinsAction,
  transactionsHistoryAction,
  updateAssetsRates,
  updateCoinsAction,
} from "store/actions/TransactionActions";
import AddAssetModal from "components/molecular/Wallet/AddAssetModal";
import AddWalletModal from "components/molecular/Wallet/NewWalletModal";
import { useHistory } from "react-router-dom";
import {
  clearProfileAction,
  getProfileInfoAction,
} from "store/actions/ProfileActions";
import TabMenu from "components/molecular/Wallet/TabMenu";
import WalletTab from "components/molecular/Wallet/WalletTab";
import HistoryTab from "components/molecular/Wallet/HstoryTab";
import LiquidityTab from "components/molecular/Wallet/LiquidityTab";
import { getNoOfWallets } from "services/dbServices";
import { saveData } from "services/storageServices";
import { reInitP2p } from "store/actions/P2pActions";
import { coinsFromShort, convertAsset } from "utils/constants";
import { FloatingWidget } from "components/global";

export const WalletScreen = (props: any) => {
  let {
    transaction,
    assets,
    profile: { user },
  } = useSelector((state: RootState) => state);

  let resArr: any = [];
  
  for (let index = 0; index < transaction.coins.length; index++) {
    const firstEle = transaction.coins[index];
    let first = JSON.parse(JSON.stringify(firstEle))
    for (let index = 0; index < assets.assets.length; index++) {
      const secondEle = assets.assets[index];
      if (firstEle.short.toLowerCase() === secondEle.short.toLowerCase()) {
        first.currentRate = secondEle.currentRate
        resArr.push(first);
      }
    }
  }
  
  const uniqueCoins = resArr.reduce((acc, user) => {
      if (!acc.find(u => u.short.toLowerCase() === user.short.toLowerCase())) {
          acc.push(user);
      }
      return acc;
  }, [])

  const { memo } = useSelector((state: RootState) => state.generic);
  const { loadingTable } = useSelector((state: RootState) => state.generic);
  let dispatch = useDispatch();
  let history: any = useHistory();
  let locationState =
    history.location.state && history.location.state["addWallet"];
  const [addAssetOpen, setAddAsset] = React.useState(false);
  const [addWalletOpen, setAddWallet] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);

  let initialize = async () => {
    let no = await getNoOfWallets();
    if (no < 1) {
      history.replace("/newWallet");
    } else if (transaction.coins.length < 1 || locationState) {
      dispatch(setCoinsAction());
    }
  };
  React.useEffect(() => {
    initialize();
    if (!user.city) {
      dispatch(getProfileInfoAction());
    }
  }, []);

  return (
    <div>
      {memo && <FloatingWidget memo={memo} />}
      <div className="w-5/6 mx-auto">
        <div
          style={{
            display: false ? "block" : "none",
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.8)",
          }}
          className="h-screem"
        >
          <div
            style={{
              position: "absolute",
              height: "41vh",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              backgroundColor: "rgba(35,35,33,0.5)",
            }}
            className="w-4/6 m-auto"
          >
            <div className="w-full bg-primary h-20 mx-auto">
              <p className="text-accent xl:pt-3 md:pt-3 pt-8 text-xs font-regular font-bold md:text-title xl:text-title text-center">
                Wallet not active!
              </p>
            </div>
            <div className="mx-auto pt-2">
              <img
                src={lockIcon}
                className="h-10 w-13 md:h-20 md:w-22 xl:h-20 xl:w-22 mx-auto mt-4"
              />
              <p className="w-5/6 mx-auto text-center text-red font-regular pt-4 md:pt-3 text-xs md:text-lg lg:text-xl xl:text-xl">
                Minimum deposit of 5 XLM is required to activate the wallet.
                {/* This deposit will be swapped into LevelG coin automatically */}
              </p>
              <div className="flex">
                <button onClick={() => {}} className={styles.btnStyle}>
                  <p>Deposit Now</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 xl:justify-between xl:flex">
          <WalletCard onCreateWallet={() => setAddWallet(true)} />
          <ActivationStatusCard
            publicKey={transaction.selectedWallet.public}
            loading={transaction.loading}
            locked={transaction.locked}
          />
        </div>
        {/*<div className=" mt-8 mb-3">
          <text className="text-xs font-bold lg:text-xl xl:text-xl text-white">
            My Accounts
          </text>
        </div>
        <WalletDataTopRow />*/}

        <TabMenu
          tab={selectedTab}
          disabled={transaction.locked || transaction.lowXlm}
          onClick={setSelectedTab}
        />

        {selectedTab == 0 && (
          <WalletTab 
          transaction={transaction} 
          coinTableData={uniqueCoins} 
          
          setAddAsset={setAddAsset} />
        )}
        {selectedTab == 1 && (
          <LiquidityTab transaction={transaction} setAddAsset={setAddAsset} />
        )}
        {selectedTab == 2 && <HistoryTab />}

        {transaction.locked && !loadingTable && <LockedPage inActive={true} />}
        {!transaction.locked && transaction.lowXlm && (
          <LockedPage inActive={false} />
        )}
      </div>
      <AddAssetModal
        isOpen={addAssetOpen}
        onClose={() => setAddAsset(false)}
        type="none"
        onSuccess={(name, type) => {
          setAddAsset(false);
          //dispatch(selectCoin(index))
          history.push({
            pathname: "/walletDetailScreen",
            state: {
              screen: 0,
              coin: name.code,
              new: true,
              asset: convertAsset(name),
            },
          });
        }}
      />
      <AddWalletModal
        isOpen={addWalletOpen}
        onClose={() => setAddWallet(false)}
        onSelect={() => {
          saveData({ myToken: "" });
          dispatch(setCoinsAction());
          dispatch(transactionsHistoryAction(true));
          dispatch(clearProfileAction());
          dispatch(getProfileInfoAction());
          dispatch(reInitP2p());
        }}
      />
    </div>
  );
};

const styles = {
  btnStyle:
    "flex bg-accent font-regular uppercase mx-auto mt-5 text-xxs md:text-lg text-white p-3 md:p-5 xl:p-5 rounded text-center focus:outline-none",
};

function LockedPage({ inActive }) {
  let [clicked, click] = React.useState(false);
  return (
    <div
      style={{
        height: "41vh",
        backgroundColor: "rgba(35,35,33,0.5)",
      }}
      className="xl:w-4/6 m-auto"
    >
      <div className="w-full bg-primary h-20 mx-auto">
        <p className="text-accent xl:pt-3 md:pt-3 pt-8 text-xs font-regular font-bold md:text-title xl:text-title text-center">
          {inActive ? "Wallet not active!" : "Your balance is not enough."}
        </p>
      </div>
      <div className="mx-auto pt-2">
        <img src={lockIcon} className="h-10 w-13 mx-auto mt-4" />
        <p className="w-5/6 mx-auto text-center text-red font-regular pt-4 md:pt-3 text-xs md:text-lg lg:text-xl xl:text-xl">
          {inActive
            ? "Minimum deposit of 5 XLM is required to activate the wallet."
            : "You need minimum 1.5 XLM in your wallet."}
          {/* This deposit will be swapped into LevelG coin automatically */}
        </p>
        {/* {!clicked && <div className="flex">
        <button
          onClick={() => click(true)}
          className={styles.btnStyle}
        >
          <p>Deposit Now</p>
        </button>
      </div>} */}
        {/* {clicked && <div className="px-20"><ActivationStatusCard2 /></div>} */}

        <div className="px-4 md:px-8 lg:px-20 xl:px-20">
          <ActivationStatusCard2 />
        </div>
      </div>
    </div>
  );
}
