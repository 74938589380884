import * as React from "react";
export const HistoryListRow = (props: any) => {
  return (
    <tr className="mt-4">
      <td className="text-white md:text-xs lg:text-xs mt-2 mb-2 xl:text-xs text-xxxs font-regular flex-grow text-left">
         {props.friendEmail.slice(0, 4) + '***********'+ props.friendEmail.slice(-4)}
      </td>
      <td className="text-white md:text-xs lg:text-xs mt-2 mb-2 xl:text-xs text-xxxs font-regular w-3/6 text-center">{props.commission} {props.currency}</td>
      <td className="text-white md:text-xs lg:text-xs mt-2 mb-2 xl:text-xs text-xxxs font-regular text-right">{props.date} - {props.time} </td>
    </tr>
  );
};
