import React from "react";
import Modal from "react-modal";
import { closeButton } from "assets/images";
import { BASE_URL } from "utils/AppConstants"
import { getJWT } from "services/httpServices";
import axios from 'axios'

function ZakatTerms({ onClose, isOpen, isChecked, zakatPercentage }) {

  const changeZakatStatus = async () => {
    try {
      let body = {
        "status": isChecked
      }
      let token: any = await getJWT();
      let res = await axios.post(`${BASE_URL}/zakat-history/ChangeZakatStatus`, body, {
        headers: {
          Authorization: `Bearer ${token.data.token}`,
        },
      });
      
      if (res.status === 200) {
        onClose()
      }
    } catch (error) {
       console.log(error.response);
    }
  }


  return (
    <Modal isOpen={isOpen} style={customStyle}>
      <button onClick={onClose} className="float-right">
        <img src={closeButton} className="w-6 h-6" />
      </button>
      <div className="flex flex-col items-center mt-8">
        <h2 className="text-xl text-orange-500 font-semibold mb-8">Terms & Conditions</h2>
        <div className="mx-4 mb-8 mt-8 bg-sparkleGray  p-6 rounded text-white text-xs font-regular">
          <p>
          Your Zakat should amount to {zakatPercentage}% or the 40th portion of the cash and tradable assets you possess above the Nisab. It is to be paid after an entire Islamic year has passed since the acquisition.<br /><br />
            <br />
            We'll  deduct zakat each year in start of Islamic month Ramadan if you have agreed to pay Zakat. 
          </p>
        </div>
        <div className="flex justify-center">
          <button
            onClick={changeZakatStatus}
            className="bg-green font-regular text-xs text-white py-3 px-20 mt-2 mb-10 mr-3 focus:outline-none"
          >
            Accept
          </button>
          <button
            onClick={onClose}
            className="bg-accent font-regular text-xs text-white py-3 px-20 mt-2 mb-10 focus:outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ZakatTerms;

const customStyle = {
  overlay: {
    backgroundColor: "#00000090",
  },
  content: {
    top: "10%",
    left: "25%",
    right: "auto",
    bottom: "auto",
    width: "54.4%",
    margin: "0 auto",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
