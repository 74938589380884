import { store } from "App";
import { getAllTrades, getMarketData, lookForAllTrades } from "services/exchange";
import { updateMarket } from "store/actions/ExchangeActions";
import moment from "moment";
import {
  getMarketPending,
  refreshMarketData,
} from "store/actions/MarketActions";
import { fetchRawCoinPairs } from "services/httpServices";
import { CoinMarket, MarketInfo } from "store/models/MarketInfo";
import { Trade } from "store/models/Exchange";
import { count } from "console";
import { counter } from "@fortawesome/fontawesome-svg-core";

class MarketStream {
  static timerId: any;

  static startListening(): void {
    this.fetchMarket(true);
    // this.timerId = setInterval(() => {
    //   this.fetchMarket(false);
    // }, 300000);
  }

  static isListening(): boolean {
    return this.timerId && this.timerId !== 0;
  }

  static stopListening(): void {
    clearInterval(this.timerId);
    this.timerId = 0;
  }

  static getChange(closePrev, closeNow): number {
    return ((closeNow - closePrev) / closePrev) * 100
  }

  static convertTrades(trades): Trade[] {
    let result: Trade[] = []
    trades.records?.forEach((element) => {
      let pr: any = (1 / (element.price.n / element.price.d)).toFixed(8);
      const base =
        element.base_asset_type === "native"
          ? "XLM"
          : element.base_asset_code;
      const counter =
        element.counter_asset_type === "native"
          ? "XLM"
          : element.counter_asset_code;
      let object: Trade = {
        pair: counter + "/" + base,
        amount: element.counter_amount,
        profit: true,
        price: pr,
        date: element.ledger_close_time,
        total: (parseFloat(element.counter_amount) * pr).toFixed(8),
        type: element.base_is_seller ? "SELL" : "BUY",
      };
      result.push(object);
    });
    return result;
  }

  static async getFullData(data): Promise<any> {
    const oldData = store.getState().market.markets
    let markets: CoinMarket[] = [];
    // const favPrev:string[] = []
    // oldData[0].data.forEach((item)=>{
    //   favPrev.push(item.counter+"/"+item.base)
    // })

    markets.push({
      name: "Favorite",
      data: oldData[0].data,
    });

    let flags: any = [],
      headers: any = [],
      l = data.length,
      i;
    for (i = 0; i < l; i++) {
      if (flags[data[i].marketCoinCode]) continue;
      flags[data[i].marketCoinCode] = true;
      headers.push(data[i]);
    }

    for (var j = 0; j < headers.length; j++) {
      let newPairs: MarketInfo[] = [];
      const prevFav: String[] = JSON.parse(localStorage.getItem('fav_market') || '[]')
      let pairs = data.filter(
        (item: any) => item.marketCoinCode == headers[j].marketCoinCode
      );

      for (var k = 0; k < pairs.length; k++) {
        let baseAsset;
        let counterAsset;

        if (pairs[k].toCoinCode == "kishu") {
          baseAsset = {
            code: pairs[k].toCoinCode.toUpperCase(),
            issuer: pairs[k].toIssure,
          };
          counterAsset = {
            code: pairs[k].marketCoinCode.toUpperCase(),
            issuer: pairs[k].marketIssure,
          };
        } else {
          baseAsset = {
            code: pairs[k].marketCoinCode.toUpperCase(),
            issuer: pairs[k].marketIssure,
          };
          counterAsset = {
            code: pairs[k].toCoinCode.toUpperCase(),
            issuer: pairs[k].toIssure,
          };
        }

        let response = await getMarketData(
          counterAsset,
          baseAsset,
          moment().subtract(40, "days").unix() * 1000,
          Math.round(new Date().getTime()),
          86400000,
          0,
          50,
          'desc'
        );

        let object = response.records[0];
        let objectPrev = response.records[1];
        const isFav: boolean = prevFav.includes(counterAsset.code + "/" + baseAsset.code)
        const favObj: any = markets[0].data.find((item) => item.base === baseAsset.code && item.counter === counterAsset.code)
        const trades = await getAllTrades(baseAsset, counterAsset, 10);

        if (object) {
          lookForAllTrades(baseAsset, counterAsset)
          let close;
          const latestPrice = trades.records[0]
          if (latestPrice) {
            close = (1 / (latestPrice.price.n / latestPrice.price.d)).toFixed(7);
          } else {
            close = Number(object.close).toFixed(Number(object.close) > 999 ? 2 : 7)
          }
          newPairs.push({
            open: object.open,
            close: close,
            closePrev: objectPrev ? objectPrev.close : close,
            high: Number(object.high).toFixed(Number(object.high) > 999 ? 2 : 7),
            low: Number(object.low).toFixed(Number(object.low) > 999 ? 2 : 7),
            volume: Number(object.base_volume).toFixed(Number(object.base_volume) > 999 ? 2 : 7),
            base: baseAsset.code,
            baseIssuer: baseAsset.issuer,
            counterIssuer: counterAsset.issuer,
            counter: counterAsset.code,
            is_fav: isFav,
            showOnTop: pairs[k].showOnTop,
            orderId: pairs[k].orderId,
            change: objectPrev ? this.getChange(
              Number(objectPrev.close),
              Number(close)
            ).toFixed(6) : this.getChange(
              Number(object.open),
              Number(close)
            ).toFixed(6),

          });
          if (isFav && !favObj) {
            markets[0].data.push({
              open: object.open,
              close: close,
              closePrev: objectPrev ? objectPrev.close : close,
              high: Number(object.high).toFixed(Number(object.high) > 999 ? 2 : 7),
              low: Number(object.low).toFixed(Number(object.low) > 999 ? 2 : 7),
              volume: Number(object.base_volume).toFixed(Number(object.base_volume) > 999 ? 2 : 7),
              base: baseAsset.code,
              counter: counterAsset.code,
              baseIssuer: baseAsset.issuer,
              counterIssuer: counterAsset.issuer,
              is_fav: isFav,
              showOnTop: pairs[k].showOnTop,
              orderId: pairs[k].orderId,
              change: objectPrev ? this.getChange(
                Number(objectPrev.close),
                Number(close)
              ).toFixed(6) : this.getChange(
                Number(object.open),
                Number(close)
              ).toFixed(6),

            })
          }
        } else {
          newPairs.push({
            close: "-",
            closePrev: "-",
            open: "-",
            high: "-",
            low: "-",
            volume: "-",
            base: baseAsset.code,
            counter: counterAsset.code,
            baseIssuer: baseAsset.issuer,
            counterIssuer: counterAsset.issuer,
            showOnTop: pairs[k].showOnTop,
            orderId: pairs[k].orderId,
            is_fav: isFav,
            change: "-",

          });
          if (isFav && !favObj) {
            markets[0].data.push({
              close: "-",
              closePrev: "-",
              open: "-",
              high: "-",
              low: "-",
              volume: "-",
              base: baseAsset.code,
              counter: counterAsset.code,
              baseIssuer: baseAsset.issuer,
              counterIssuer: counterAsset.issuer,
              showOnTop: pairs[k].showOnTop,
              orderId: pairs[k].orderId,
              is_fav: isFav,
              change: "-",

            })
          }
        }

      }
      markets.push({
        name: headers[j].marketCoinCode.toUpperCase(),
        data: newPairs,
      });
    }

    // remove custom option temporary from exchange
    // markets.push({
    //   name: "Custom",
    //   data: [],
    // });

    // hide anchor coins
    const filteredArr = ['BTC', 'LTC', 'DOGE', 'DASH', 'ETH', 'TRX', 'KISHU', 'SHIB', 'SHIBA' , 'USDT', 'BOTH', 'AQUA'];
    markets = markets.filter(({ name }) => !filteredArr.includes(name));
    markets.forEach(el => {
      if (el.data.length !== 0) {
        el.data = el.data.filter(({ counter }) => !filteredArr.includes(counter));
      }
    });

    store.dispatch(refreshMarketData(markets, false));
  }

  static async fetchMarket(reload: boolean): Promise<void> {
    if (reload) store.dispatch(getMarketPending(true));
    let data: any = await fetchRawCoinPairs();

    if (reload) {
      this.getInitData(data, reload);
      this.getFullData(data);
    } else {
      this.getFullData(data);
    }
  }

  static async getInitData(data, reload): Promise<void> {
    let markets: CoinMarket[] = [];
    const prevFav: String[] = JSON.parse(localStorage.getItem('fav_market') || '[]')

    markets.push({
      name: "Favorite",
      data: [],
    });

    let flags: any = [],
      headers: any = [],
      l = data.length,
      i;
    for (i = 0; i < l; i++) {
      if (flags[data[i].marketCoinCode]) continue;
      flags[data[i].marketCoinCode] = true;
      headers.push(data[i]);
    }

    for (var j = 0; j < headers.length; j++) {
      let newPairs: MarketInfo[] = [];
      let pairs = data.filter(
        (item: any) => item.marketCoinCode == headers[j].marketCoinCode
      );
      for (var k = 0; k < pairs.length; k++) {
        let baseAsset;
        let counterAsset;

        if (pairs[k].toCoinCode == "kishu") {
          baseAsset = {
            code: pairs[k].toCoinCode.toUpperCase(),
            issuer: pairs[k].toIssure,
          };
          counterAsset = {
            code: pairs[k].marketCoinCode.toUpperCase(),
            issuer: pairs[k].marketIssure,
          };
        } else {
          baseAsset = {
            code: pairs[k].marketCoinCode.toUpperCase(),
            issuer: pairs[k].marketIssure,
          };
          counterAsset = {
            code: pairs[k].toCoinCode.toUpperCase(),
            issuer: pairs[k].toIssure,
          };
        }
        const isFav: boolean = prevFav.includes(counterAsset.code + "/" + baseAsset.code)
        const favObj: any = markets[0].data.find((item) => item.base === baseAsset.code && item.counter === counterAsset.code)

        newPairs.push({
          close: "-",
          closePrev: "-",
          open: "-",
          high: "-",
          low: "-",
          volume: "-",
          base: baseAsset.code,
          counter: counterAsset.code,
          baseIssuer: baseAsset.issuer,
          counterIssuer: counterAsset.issuer,
          showOnTop: pairs[k].showOnTop,
          orderId: pairs[k].orderId,
          is_fav: isFav,
          change: "-",

        });
        if (isFav && !favObj) {
          markets[0].data.push({
            close: "-",
            closePrev: "-",
            open: "-",
            high: "-",
            low: "-",
            volume: "-",
            base: baseAsset.code,
            counter: counterAsset.code,
            baseIssuer: baseAsset.issuer,
            counterIssuer: counterAsset.issuer,
            showOnTop: pairs[k].showOnTop,
            orderId: pairs[k].orderId,
            is_fav: isFav,
            change: "-",

          })
        }
      }

      markets.push({
        name: headers[j].marketCoinCode.toUpperCase(),
        data: newPairs,
      });
    }
    markets.push({
      name: "Custom",
      data: [],
    });
    store.dispatch(refreshMarketData(markets, reload));
  }
}

export default MarketStream;

