import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { updateWallet, getWalletById, getWallets } from "services/dbServices";
import { getSelectedKeys, setHomeDomain } from "services/trannsactionServices";
import { useDispatch } from "react-redux";
import { renameWallet } from "store/actions/TransactionActions";
import { isUrl } from "services/genericServices";
import axios from "axios";
import { fetchFederation } from "services/httpServices";
import { walletIcon } from "assets/images";
import { BASE_URL } from "utils/AppConstants";
import { getHomeDomain } from "services/AssetServices";

const useFederation = () => {
  let [state, setState] = useState({
    loading: false,
    loadingData: true,
    dmSubmitting: false,
    fdSubmitting: false,
    fedVal: "",
    domainVal: "",
    selected: 0,
    wallet: {
      federationName: "",
      federationNameBk: "",
      btcBalance: 0,
      id: "",
      name: "",
      nameBk: "",
      domain: "",
      domainBk: "",
      percent: 0,
      private: "",
      public: "",
      usdBalance: 0,
    },
  });

  let alert = useAlert();
  let dispatch = useDispatch();

  let submit = (e) => {
    e.preventDefault();

    if (state.loading) {
      return;
    }

    if (
      (state.wallet.domain !== "" && state.wallet.domainBk === "") ||
      (state.wallet.domainBk !== "" &&
        state.wallet.domain !== state.wallet.domainBk)
    ) {
      setDomain((setFed) => {
        rename();
        if (setFed) {
          setFederation();
        }
      });
    } else {
      rename();
      setFederation();
      setDomain((d) => {});
    }
  };

  let setFederation = async () => {
    const { domain, domainBk, federationName, federationNameBk } = state.wallet;
    if (
      (federationName === federationNameBk &&
        federationName !== "" &&
        federationNameBk !== "") ||
      federationName === ""
    ) {
      return;
    }
    if (
      (domain === "" && domainBk === "") ||
      (domain === "" && domainBk !== "")
    ) {
      alert.show("You need to set home domain first");
      return;
    }

    setState((prev) => ({ ...prev, fdSubmitting: true, loading: true }));
    const fedName = state.wallet.federationName.split("*")[0];
    let body = {
      name: fedName,
      accountId: state.wallet.public,
      domain: state.wallet.domain,
    };

    try {
      let url = BASE_URL + "/federation/setfederation";
      let data: any = await axios.post(url, body);

      if (data.Error) throw new Error(data.Error);

      /*if (state.wallet){
        updateWallet(state.wallet.public, {
          ...state.wallet,
          FEDERATION: data.data.stellar_address,
        });
      }*/
      alert.show(
        `Federation name ${data.data.stellar_address} set successfully.`,
        {
          type: "success",
        }
      );
      setState((prev) => ({
        ...prev,
        loading: prev.dmSubmitting,
        fdSubmitting: false,
        wallet: { ...prev.wallet, federationNameBk: prev.wallet.federationName },
      }));

      dispatch(renameWallet(state.wallet.name));

      if (!state.dmSubmitting) {
        alert.show("Changes Saved!", { type: "success" });
      }
    } catch (error) {
      if (error.response) {
        let msg = error.response.data.Error;
        alert.show(federationMsgs[msg] ? federationMsgs[msg] : msg);
      } else {
        alert.show("Error while setting federation name.");
      }
      setState((prev) => ({
        ...prev,
        loading: prev.dmSubmitting,
        fdSubmitting: false,
      }));
    }
  };

  let rename = async () => {
    if (state.wallet.name === "") {
      setState((prev) => ({
        ...prev,
        fedVal: "Name field can't be empty.",
      }));
      return;
    }
    if (state.wallet.name === state.wallet.nameBk) {
      return;
    }
    const wallets: any = await getWallets();
    let already = false;
    wallets.forEach((element) => {
      if (element.name === state.wallet.name) {
        already = true;
      }
    });
    if (already) {
      alert.show("This name already used.", { type: "error" });
      return;
    }
    updateWallet(state.wallet.public, state.wallet);
    dispatch(renameWallet(state.wallet.name));
    setState(prev=>({...prev, wallet:{...prev.wallet, nameBk: prev.wallet.name}}))
    if (!state.dmSubmitting && !state.fdSubmitting) {
      alert.show("Wallet name changed!", { type: "success" });
    }
  };

  let initialize = () => {
    let address = getSelectedKeys().public;
    setState((prev) => ({ ...prev, loadingData: true }));
    getWalletById(address).then((data: any) => {
      setState((prev) => ({
        ...prev,
        wallet: {
          ...prev.wallet,
          btcBalance: data.btcBalance || 0,
          id: data.id || "",
          name: data.name || "",
          nameBk: data.name || "",
          percent: data.percent || "",
          private: data.private || "",
          public: data.public || "",
          usdBalance: data.usdBalance || "",
        },
      }));
    });
    fetchFederation(address)
      .then((data: any) => {
        console.log(data);
        const name = data.federationName
          ? data.federationName.split("*")[0] || ""
          : "";
        setState((prev) => ({
          ...prev,
          wallet: {
            ...prev.wallet,
            federationName: name,
            federationNameBk: name,
          },
        }));
      })
      .catch((e) => {
        // console.log(e.response)
      })
      .finally(() => {
        setState((prev) => ({ ...prev, loadingData: false }));
      });
    getHomeDomain(address)
      .then((domain) => {
        setState((prev) => ({
          ...prev,
          wallet: { ...prev.wallet, domain: domain, domainBk: domain },
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    initialize();
  }, []);

  let getWalletsNames = () => {};

  let setDomain = async (callback) => {
    if (
      state.wallet.domain === "" ||
      !state.wallet.domain ||
      state.wallet.domainBk === state.wallet.domain
    ) {
      callback(false);
      return;
    }
    if (
      state.wallet.domain &&
      state.wallet.domain !== "" &&
      !isUrl(state.wallet.domain)
    ) {
      setState((prev) => ({
        ...prev,
        domainVal: "Domain field should be a url.",
      }));
      callback(false);
      return;
    }
    setState((prev) => ({ ...prev, dmSubmitting: true, loading: true }));

    try {
      let data = await setHomeDomain(state.wallet.domain);
      setState((prev) => ({
        ...prev,
        loading: prev.fdSubmitting,
        dmSubmitting: false,
        wallet: { ...prev.wallet, domainBk: prev.wallet.domain },
      }));

      if (!state.fdSubmitting) {
        alert.removeAll();
        alert.show("Home Domain Set!", { type: "success" });
      }

      updateWallet(state.wallet.public, state.wallet);
      callback(true);
    } catch (e) {
      alert.show("Error while setting home domain.");
      setState((prev) => ({
        ...prev,
        loading: prev.fdSubmitting,
        dmSubmitting: false,
      }));
    }
  };

  let handleInputChange = (e) => {
    let { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      wallet: { ...prev.wallet, [name]: value },
      fedVal: name === "name" && value !== "" ? "" : prev.fedVal,
      domainVal: name === "domain" && value !== "" ? "" : prev.domainVal,
    }));
  };

  return {
    ...state,
    submit,
    getWalletsNames,
    handleInputChange,
  };
};

export default useFederation;

let federationMsgs = {
  "Name already taken.": "This name for federation is already taken.",
  "Account already exist": "This wallet have already set a federation name.",
};
