import { useState, useEffect } from "react";
import { getSelectedKeys } from "services/trannsactionServices";
import moment from "moment";
import { sendConfirmCode, verifyCode } from "services/httpServices";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import {
  addUserActon,
  addProfileAction,
  verifyEmailAction,
  verifyPhoneAction,
  setInputProperty,
} from "store/actions/ProfileActions";
import { profileStatus } from "utils/constants";
import { User } from "store/models/User";

const useKYCProfile = (user: User, setTab, tab, isCompany) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  let [state, setState] = useState({
    submitting: false,
    isConfirmOpen: false,
    confirmMsgOpen: false,
    sendingEmail: false,
    sendingMsg: false,
    verifying: false,
  });
  let [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    file: "",
    otherName: "",
    email: "",
    phone: "",
    dob: new Date(),
    countryCode: "",
    countryDial: "",
    state: "",
    city: "",
    postalCode: "",
    address: "",
    status: "",
    fileSize: 0,
    firstNameVal: "",
    lastNameVal: "",
    otherNameVal: "",
    emailVal: "",
    phoneVal: "",
    dobVal: "",
    countryCodeVal: "",
    stateVal: "",
    cityVal: "",
    postalCodeVal: "",
    addressVal: "",
    fileVal: "",
    companyName: "",
    companyWebsite: "",
    businessName: "",
    companyNameVal: "",
    companyWebsiteVal: "",
  });

  useEffect(() => {
    initFromGlobal();
  }, []);

  let initFromGlobal = () => {
    if (isCompany) {
      setInputs((prev) => ({
        ...prev,
        address: user.address ?? "",
        countryCode: user.countryCode ?? "",
        city: user.city ?? "",
        email: user.email ?? "",
        phone: user.phone ?? "",
        postalCode: user.postalCode ?? "",
        state: user.state ?? "",
        status: user.status ?? "",
        countryDial: user.countryDial,
        companyName: user.companyName ?? "",
        companyWebsite: user.companyWebsite ?? "",
        businessName: user.businessName ?? "",
      }));
    } else {
      let date = new Date(user.dob ?? "2015-03-25");
      setInputs((prev) => ({
        ...prev,
        address: user.address ?? "",
        countryCode: user.countryCode ?? "AF",
        city: user.city ?? "",
        email: user.email ?? "",
        phone: user.phone ?? "",
        postalCode: user.postalCode ?? "",
        state: user.state ?? "",
        status: user.status ?? "",
        countryDial: user.countryDial,
        firstName: user.firstName ?? "",
        lastName: user.lastName ?? "",
        otherName: user.otherName ?? "",
        dob: date ?? new Date(),
        file: user.file ?? "",
      }));
    }
  };

  const submit = (e) => {
    e?.preventDefault();

    if (!isValidated()) {
      return;
    }

    const data = new FormData();
    data.append("account_id", getSelectedKeys().public);
    data.append("email_address", inputs.email);
    data.append("mobile_number", inputs.countryDial + inputs.phone);
    data.append("address_country_code", inputs.countryCode);
    data.append("state_or_province", inputs.state);
    data.append("city", inputs.city);
    data.append("postal_code", inputs.postalCode);
    data.append("address", inputs.address);
    if (isCompany) {
      let isCompanyKyc:any = true;
      data.append("companyName", inputs.companyName);
      data.append("companyWebsite", inputs.companyWebsite);
      data.append("businessName", inputs.businessName);
      data.append("isCompanyKyc", isCompanyKyc);
    } else {
      let date = moment(inputs.dob).calendar();
      data.append("first_name", inputs.firstName);
      data.append("last_name", inputs.lastName);
      data.append("additional_name", inputs.otherName);
      data.append("birth_date", date);
      data.append("file", inputs.file);
    }

    if (!user.isPhoneVerified) {
      alert.show("Please verify your phone number first.", { type: "error" });
    } else if (!user.isEmailVerified) {
      alert.show("Please verify your email address first.", { type: "error" });
    } else {
      setState((prev) => ({ ...prev, submitting: true }));
      dispatch(
        addProfileAction(data, (response) => {
          if (response || response === "") {
            setGlobal(response === "" ? user.file : response);
            alert.show("Successfully saved.", { type: "success" });
            setTab(tab + 1);
          } else {
            alert.show("Error while saving information.", { type: "error" });
          }
          setState((prev) => ({ ...prev, submitting: false }));
        })
      );
    }
  };

  const setGlobal = (file) => {
    if (isCompany) {
      dispatch(
        addUserActon({
          address: inputs.address,
          countryCode: inputs.countryCode,
          city: inputs.city,
          email: inputs.email,
          phone: inputs.phone,
          postalCode: inputs.postalCode,
          state: inputs.state,
          countryDial: inputs.countryDial,

          companyName: inputs.companyName,
          companyWebsite: inputs.companyWebsite,
          businessName: inputs.businessName,
          isCompanyKyc: true,
        })
      );
    } else {
      dispatch(
        addUserActon({
          address: inputs.address,
          countryCode: inputs.countryCode,
          city: inputs.city,
          email: inputs.email,
          phone: inputs.phone,
          postalCode: inputs.postalCode,
          state: inputs.state,
          countryDial: inputs.countryDial,

          file: file,
          firstName: inputs.firstName,
          lastName: inputs.lastName,
          otherName: inputs.otherName,
          dob: inputs.dob,
          isCompanyKyc: false,
        })
      );
    }
  };

  const isValidated = () => {
    let response = true;
    if (isCompany) {
      if (inputs.companyName == "") {
        response = false;
        setInputs((prev) => ({
          ...prev,
          companyNameVal: "Company name is required.",
        }));
      }
      if (inputs.companyWebsite == "") {
        response = false;
        setInputs((prev) => ({
          ...prev,
          companyWebsiteVal: "Company website is required.",
        }));
      }
    } else {
      if (inputs.firstName == "") {
        response = false;
        setInputs((prev) => ({
          ...prev,
          firstNameVal: "First name is required.",
        }));
      }
      if (inputs.lastName == "") {
        response = false;
        setInputs((prev) => ({
          ...prev,
          lastNameVal: "Last name is required.",
        }));
      }
      if (inputs.file == "") {
        response = false;
        setInputs((prev) => ({
          ...prev,
          fileVal: "Profile image is required.",
        }));
      }
      if (inputs.fileSize > 1000000) {
        response = false;
        setInputs((prev) => ({
          ...prev,
          fileVal: "Image can't be greater than 1MB.",
        }));
      }
      if (inputs.dob == new Date()) {
        response = false;
        setInputs((prev) => ({
          ...prev,
          dobVal: "Date of birth is required.",
        }));
      }
      if (moment(inputs.dob) > moment().subtract(15, "years")) {
        response = false;
        setInputs((prev) => ({
          ...prev,
          dobVal: "You can't be younger than 15.",
        }));
      }
    }

    if (inputs.email == "") {
      response = false;
      setInputs((prev) => ({ ...prev, emailVal: "Email field is required." }));
    }
    if (inputs.phone == "") {
      response = false;
      setInputs((prev) => ({ ...prev, phoneVal: "Phone field is required." }));
    }
    if (
      !inputs.countryCode ||
      inputs.countryCode == "" ||
      inputs.countryCode == "Select a Country"
    ) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        countryCodeVal: "Country Code field is required.",
      }));
    }
    if (inputs.postalCode == "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        postalCodeVal: "Postal Code field is required.",
      }));
    }
    if (inputs.city == "") {
      response = false;
      setInputs((prev) => ({ ...prev, cityVal: "City field is required." }));
    }
    if (inputs.address == "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        addressVal: "Address field is required.",
      }));
    }
    if (inputs.state == "") {
      response = false;
      setInputs((prev) => ({ ...prev, stateVal: "State field is required." }));
    }
    if (state.submitting) {
      response = false;
    }
    if (
      inputs.status === profileStatus.pending ||
      user.status === profileStatus.pending
    ) {
      response = false;
      showAlert("Already Submitted");
    }
    if (
      inputs.status === profileStatus.approved ||
      user.status === profileStatus.approved
    ) {
      response = false;
      showAlert("Already approved");
    }
    return response;
  };

  const sendConfirmEmail = () => {
    if (inputs.email === "") {
      setInputs((prev) => ({ ...prev, emailVal: "Email field is required" }));
      return;
    }
    if (!inputs.email.includes("@")) {
      setInputs((prev) => ({ ...prev, emailVal: "Email is not valid" }));
      return;
    }
    if (state.sendingEmail) {
      return;
    }
    if (user.isEmailVerified) {
      return;
    }

    setState((prev) => ({ ...prev, sendingEmail: true }));
    sendConfirmCode({ to: inputs.email, isCompanyKyc: isCompany }, "email")
      .then((data) => {
        openConfirm();
      })
      .catch((e) => {
        alert.show(e?.response?.data?.Error || "Request Failed", {
          type: "error",
        });
      })
      .finally(() => {
        setState((prev) => ({ ...prev, sendingEmail: false }));
      });
  };

  const sendConfirmMsg = () => {
    if (inputs.phone === "") {
      setInputs((prev) => ({ ...prev, phoneVal: "Phone field is required" }));
      return;
    }
    if (state.sendingMsg) {
      return;
    }
    if (user.isPhoneVerified) {
      return;
    }

    setState((prev) => ({ ...prev, sendingMsg: true }));
    sendConfirmCode(
      {
        to: inputs.countryDial + inputs.phone,
        address_country_code: inputs.countryCode,
        isCompanyKyc: isCompany,
      },
      "sms"
    )
      .then((data) => {
        console.log(data)
        openMsgConfirm();
      })
      .catch((e) => {
        // console.log(e);
        alert.show(e?.response?.data?.Error || "Request Failed", {
          type: "error",
        });
      })
      .finally(() => {
        setState((prev) => ({ ...prev, sendingMsg: false }));
      });
  };

  const verifyEmail = (code) => {
    setState((prev) => ({ ...prev, verifying: true }));

    verifyCode({ code: code }, "verifyEmail")
      .then((data) => {
        alert.show("Confirmation completed", { type: "success" });
        closeConfirm();
        dispatch(verifyEmailAction(inputs.email));
      })
      .catch((e) => {
        // console.log(e);
        alert.show(e?.response?.data?.Error || "Confirmation Failed", {
          type: "error",
        });
      })
      .finally(() => {
        setState((prev) => ({ ...prev, verifying: false }));
      });
  };
  const verifyPhone = (code) => {
    setState((prev) => ({ ...prev, verifying: true }));

    verifyCode({ code: code }, "verifyPhone")
      .then((data) => {
        alert.show("Confirmation completed", { type: "success" });
        closeMsgConfirm();
        dispatch(
          verifyPhoneAction(
            inputs.phone,
            inputs.countryCode,
            inputs.countryDial,
            isCompany
          )
        );
      })
      .catch((e) => {
        // console.log(e.response);
        alert.show(e?.response?.data?.Error || "Confirmation Failed", {
          type: "error",
        });
      })
      .finally(() => {
        setState((prev) => ({ ...prev, verifying: false }));
      });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (!inputValidate(name)) {
      return;
    }

    setInputs((prev) => ({ ...prev, [name]: value, [name + "Val"]: "" }));
  };
  const handleCountryChange = (e) => {
    let { value, name } = e.target;
    const value2 = JSON.parse(value);

    setInputs((prev) => ({
      ...prev,
      countryCode: value2.code,
      countryDial: value2.dial_code,
      countryCodeVal: "",
    }));
  };

  const inputValidate = (name) => {
    let res = true;
    if (
      inputs.status === profileStatus.pending ||
      user.status === profileStatus.pending
    ) {
      showAlert("Already submitted");
      res = false;
    }
    if (
      inputs.status === profileStatus.approved ||
      user.status === profileStatus.approved
    ) {
      res = false;
    }
    if (user.isEmailVerified && name === "email") {
      res = false;
    }
    if (user.isPhoneVerified && name === "phone") {
      res = false;
    }
    return res;
  };

  let showAlert = (message) => {
    alert.removeAll();
    alert.show(message, { type: "success" });
  };

  const setPhoto = (file, size) => setInputs((prev) => ({ ...prev, file: file, fileSize: size, fileVal: size>1000000?"File size can't be greater than 1MB":"" }));
  const openConfirm = () =>
    setState((prev) => ({ ...prev, isConfirmOpen: true }));
  const closeConfirm = () =>
    setState((prev) => ({ ...prev, isConfirmOpen: false }));
  const openMsgConfirm = () =>
    setState((prev) => ({ ...prev, confirmMsgOpen: true }));
  const closeMsgConfirm = () =>
    setState((prev) => ({ ...prev, confirmMsgOpen: false }));

  return {
    ...inputs,
    ...state,
    setPhoto,
    handleInputChange,
    submit,
    showAlert,
    closeConfirm,
    sendConfirmEmail,
    sendConfirmMsg,
    closeMsgConfirm,
    verifyEmail,
    verifyPhone,
    handleCountryChange,
  };
};

export default useKYCProfile;
