import {
  SET_COINS,
  GetTransactionsInfoType,
  SELECT_COIN,
  SET_COINS_PENDING,
  SET_COINS_ERROR,
  REMAME_WALLET,
  SET_SELECTED_WALLET,
  SET_COIN_DEPOSIT_ADDRESS,
  SET_COIN_LOCKED,
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_PENDING,
  ADD_NEW_WALLET,
  FETCH_NEXT_TRANSACTIONS_SUCCESS,
  FETCH_NEXT_TRANSACTIONS_PENDING,
  UPDATE_PAIR_BALANCE,
  INCREMENT_BALANCE, REMOVE_COINS, LOW_XLM
} from "store/actions/TransactionsActionsTypes";
import { TransactionState, Wallet } from "store/models/TransactionState";
import { getSelectedKeys } from "services/trannsactionServices";

const wallet: Wallet = {
  public: getSelectedKeys().public,
  btcBalance: 0,
  percent: 0,
  usdBalance: 0,
  private: "",
  name: "",
};

const initialState: TransactionState = {
  selected: 0,
  coins: [],
  transactions: [],
  selectedWallet: wallet,
  loading: true,
  loadingTrans: true,
  loadingNextTrans: false,
  stellarTransNextPage: "",
  apiTransPage: 1,
  liquidityData: [],
  locked: false,
  lowXlm: false,
  error: "",
};

export function transacionsReducer(
  state = initialState,
  action: GetTransactionsInfoType
): TransactionState {
  switch (action.type) {
    case SET_COINS:
      let totalUsd = 0;
      let totalBtc = 0;
      let totalPercent = 0;
      let xlmbalance=0
      
        action.payload.forEach((element) => {
          totalUsd =
            totalUsd === 0
              ? parseFloat(element.availableBalance.toString())
              : totalUsd + parseFloat(element.availableBalance.toString());
          totalBtc =
            totalUsd === 0
              ? parseFloat(element.btcValue.toString())
              : totalBtc + parseFloat(element.btcValue.toString());
          totalPercent = totalPercent + Number(element.percent);
          if(element.short.toUpperCase() === 'XLM'){
            xlmbalance = element.balance
          }
        });
      
      let percent = totalPercent / action.payload.length;

      return {
        ...state,
        coins: action.payload,
        locked: false,
        lowXlm: (xlmbalance<1.5),
        selectedWallet: {
          ...action.wallet,
          percent: percent ? percent : 0,
          btcBalance: Number(totalBtc).toFixed(5),
          usdBalance: action.showLoading? "Loading...": Number(totalUsd).toFixed(2),
        },
        loading: false,
        liquidityData: action.liquidityData,
      };
    case LOW_XLM:
      return {
        ...state,
        lowXlm: true
      }
    case SET_COINS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_COINS:
      return {
        ...state,
        coins: [],
    };
    case REMAME_WALLET:
      return {
        ...state,
        selectedWallet: { ...state.selectedWallet, name: action.name },
      };
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingTrans: false,
        transactions: action.payload,
        stellarTransNextPage: action.next,
        apiTransPage: action.apiNext,
      };
    case FETCH_TRANSACTIONS_PENDING:
      return {
        ...state,
        loadingTrans: true,
      };
    case FETCH_NEXT_TRANSACTIONS_SUCCESS:
      let data: any = [...state.transactions, ...action.payload];

      return {
        ...state,
        loadingNextTrans: false,
        transactions: data,
        stellarTransNextPage: action.next,
        apiTransPage: action.apiNext,
      };
    case FETCH_NEXT_TRANSACTIONS_PENDING:
      return {
        ...state,
        loadingNextTrans: true,
      };
    case SET_COINS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_COIN_LOCKED:
      return {
        ...state,
        loading: false,
        locked: action.payload,
        coins: []
      };
    case SELECT_COIN:
      return {
        ...state,
        selected: action.index,
      };
    case ADD_NEW_WALLET:
      return {
        ...state,
        selectedWallet: action.wallet,
      };
    case UPDATE_PAIR_BALANCE:
      let newCoins = [...state.coins];
      for (let i = 0; i < newCoins.length; i++) {
        if (
          newCoins[i].short.toUpperCase() === action.pair.baseCode.toUpperCase()
        ) {
          newCoins[i].totalBalance = action.pair.baseBalance;
        }
        if (
          newCoins[i].short.toUpperCase() ===
          action.pair.counterCode.toUpperCase()
        ) {
          newCoins[i].totalBalance = action.pair.counterBalance;
        }
      }
      return {
        ...state,
        coins: newCoins,
      };
    case INCREMENT_BALANCE:
      let newCoins2:any = [...state.coins];
      for (let i = 0; i < newCoins2.length; i++) {
        const element = newCoins2[i]
        if (
          newCoins2[i].short.toUpperCase() ===
          action.pair.baseCode.toUpperCase()
        ) {
          newCoins2[i].balance = (parseFloat(""+element.balance)- parseFloat(""+action.pair.baseBalance)).toFixed(8);
        }
        if (
          newCoins2[i].short.toUpperCase() ===
          action.pair.counterCode.toUpperCase()
        ) {
          newCoins2[i].balance = (parseFloat(""+element.balance) + parseFloat(""+action.pair.counterBalance)).toFixed(8);
        }
      }
      return {
        ...state,
        coins: newCoins2,
      };
    default:
      return state;
  }
}
