import React from 'react';
import { Redirect, Route } from "react-router-dom";
import { checkAuthState } from "services/authServces";
import { useSelector } from "react-redux";

const PublicRoute = ({ component: Component, ...rest }) => {
  let auth = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth && auth.password){
             return <Redirect to="/login" />
        }else{
            return <Component {...props} />
        }
      }}
    />
  );
};

export default PublicRoute;
