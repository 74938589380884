import React, {useEffect, useState} from "react";
import { TableHeading } from "components/molecular/markets/TableHeading";
import { TableRow } from "components/molecular/markets/TableRow";
import { useSelector, useDispatch } from "react-redux";
import {getMarketInfoAction, addToFavourites, removeFromFavourites} from "../../../store/actions/MarketActions";
import { RootState } from "store/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { MarketInfo } from "store/models/MarketInfo";
import { useHistory } from "react-router-dom";


export const FavTable = () => {
  const {search, selected, loading, markets} = useSelector((state:RootState) => state.market);
  const dispatch = useDispatch();
  const history = useHistory()

  let [data, setData] = useState(selected.data)
  
  useEffect(()=>{
    let newData = selected.data.filter((item) =>
      item.counter.includes(search.toUpperCase())
    );
    setData(newData);
  },[search]);

  useEffect(()=>{
    setData(selected.data);
  },[selected]);

  const handleFav = (action, item) => {
    if(action === 'SET_FAV'&& selected.name !== 'Favorite'){
      dispatch(addToFavourites({...item, is_fav: true}))
    } else {
      dispatch(removeFromFavourites(item, selected.name))
    }
  }

  const renderNoData=()=><div className="flex justify-center align-center w-full text-gray-600 mt-20"><h2 className="mt-20">No data to show.</h2></div>
  const renderLoading=()=><div className="flex justify-center align-center w-full text-gray-500 mt-20"><h2><FontAwesomeIcon icon={faSyncAlt} spin className="mt-20" /></h2></div>
  const handleNav = (base, counter) => history.push({ pathname: "/exchange", state: { base, counter }})
  return (
    <div style={{margin:'0',padding:'0'}}>
      
      <table style={{ tableLayout: "fixed", width: "100%"}} cellPadding="2" className="text-center">
      <TableHeading />
      {!loading && data.map((item:MarketInfo, index)=>{
      return <TableRow
        key={index}
        base={item.base}
        is_fav={item.is_fav}
        counter={item.counter}
        close={item.close}
        change={item.change}
        high={item.high}
        low={item.low}
        volume={item.volume}
        onFav={(a)=>{handleFav(a, item)}}
        onNav={()=>handleNav(item.base, item.counter)}
      />
      })}
      </table>
      {data.length < 1 && !loading && renderNoData()}
      {loading && renderLoading()}
    </div>
  );
};
