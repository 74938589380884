import * as React from "react";
import { eyeIcon } from "assets/images/images";
import { InputStyle, InputWidthfull } from "styles/CustomInputStyles";
import { genericFontStyle } from "styles/ButtonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
interface IDefaultsOptions {
  maxLength?: number
}

const CustomInput = (props: any) => {
  const { labelStyle, inputStyle = [""], name } = props;
  const [type, setType] = React.useState(props.type);
  let defaultsOptions:IDefaultsOptions = {}
  if(Boolean(props.maxLength)){
    defaultsOptions.maxLength = props.maxLength
  }
  return (
    <div
      className={`${InputWidthfull} ${inputStyle} ${
        props.bottom ? "border-b border-white" : ""
      }`}
    >
      <label
        className={genericFontStyle + " " + labelStyle}
        style={{
          display: props.label ? "flex" : "none",
          color: props.labelColor,
        }}
      >
        {props.label}
      </label>
      <div className="flex items-center w-full">
        {props.leading}
      <div
        className={`flex bg-primary w-full ${
          props.valMsg && "border border-red"
        }`}
        style={{
          marginTop: props.marginTop || props.marginVertical,
          marginBottom: props.marginBottom || props.marginVertical,
          paddingTop: props.paddingTop || props.paddingVertical,
          paddingBottom: props.paddingBottom || props.paddingVertical,
          backgroundColor: props.inputColor,
        }}
      >
        <input
          style={{ padding: props.padding ?? "1rem" }}
          className={`${InputStyle} text-xs`}
          placeholder={props.placeholder}
          name={name}
          type={type}
          value={props.value}
          onChange={props.onChange}
          autoComplete="off"
          disabled={props.disabled}
          {...defaultsOptions}
        ></input>
        {props.showIcon ? (
          <FontAwesomeIcon
            icon={type == "password" ? faEye : faEyeSlash}
            onClick={() => setType(type == "password" ? "text" : "password")}
            color="#ffffff"
            className={
              props.iconName
                ? " h-4 w-3 self-center mr-5"
                : "h-4 w-6 mr-5 self-center"
            }
          />
        ) : null}
      </div>
      {props.trailing}
      </div>
      <small className="text-red italic">{props.valMsg}</small>
    </div>
  );
};
export default CustomInput;
