import {
  GetTransactionsInfoType,
  SET_COINS,
  SELECT_COIN,
  REMAME_WALLET,
  SET_COINS_PENDING,
  SET_COINS_ERROR,
  SET_SELECTED_WALLET,
  SET_COIN_DEPOSIT_ADDRESS,
  SET_COIN_LOCKED,
  FETCH_TRANSACTIONS_PENDING,
  FETCH_TRANSACTIONS_SUCCESS,
  ADD_NEW_WALLET,
  FETCH_NEXT_TRANSACTIONS_SUCCESS,
  FETCH_NEXT_TRANSACTIONS_PENDING,
  UPDATE_PAIR_BALANCE,
  INCREMENT_BALANCE, REMOVE_COINS, LOW_XLM
} from "./TransactionsActionsTypes";
import { Coin, LiquidityData, Wallet } from "store/models/TransactionState";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { xlmIcon } from "assets/images/index";
import { getBalances, getSelectedKeys } from "services/trannsactionServices";
import { coinsFromShort } from "utils/constants";
import { getUsdBalance, getBtcBalance, getLiquidityData, readToml } from "services/AssetServices";
import {
  getAssetByName,
  getWalletById,
  saveInitial,
} from "services/dbServices";
import { getPreferences, getAllTransactions } from "services/storageServices";
import { BASE_URL } from "utils/AppConstants";
import { Asset } from "store/models/Assets";
import { appendAssetsSuccess, fetchAssetsSuccess, UpdateCustomAssets } from "./AssetsActions";
import {
  fetchInitial,
  fetchOverAllTransactions,
  getStellarTransactions,
} from "services/httpServices";
import { Transaction } from "store/models/Transaction";
import { filterOverAllRecords, processTransactions } from "utils/helpers";
import { PairBalance } from "store/models/Exchange";
import { getCoinMarketPrice } from "services/exchange";
import { setLoading } from "./GenericActions"
const Icon = require("../../assets/images/custom_asset_logo.png")
let { CURRENCY } = getPreferences();

export function setCoins(data: Coin[], wallet: any, showLoading: boolean, liquidityData: LiquidityData[]): GetTransactionsInfoType {
  return {
    type: SET_COINS,
    payload: data,
    wallet: wallet,
    showLoading,
    liquidityData,
  };
}

export const selectCoin = (index: number): GetTransactionsInfoType => {
  return { type: SELECT_COIN, index };
};
export const removeCoins = (): GetTransactionsInfoType => {
  return { type: REMOVE_COINS };
};
export const setCoinDepositAddress = (
  address: string
): GetTransactionsInfoType => {
  return { type: SET_COIN_DEPOSIT_ADDRESS, payload: address };
};
export const renameWallet = (name: string): GetTransactionsInfoType => {
  return { type: REMAME_WALLET, name };
};

export const setCoinPending = (pending: boolean): GetTransactionsInfoType => {
  return { type: SET_COINS_PENDING, pending };
};

export const setCoinError = (massege: string): GetTransactionsInfoType => {
  return { type: SET_COINS_ERROR, payload: massege };
};

export const setCoinLocked = (lock: boolean): GetTransactionsInfoType => {
  return { type: SET_COIN_LOCKED, payload: lock };
};
export const setCoinLowOnXlm = (lock: boolean): GetTransactionsInfoType => {
  return { type: LOW_XLM, lock: lock };
};

export const setSelectedWallet = (wallet: Wallet): GetTransactionsInfoType => {
  return { type: SET_SELECTED_WALLET, wallet: wallet };
};

export const updatePairBalance = (
  pair: PairBalance
): GetTransactionsInfoType => {
  return { type: UPDATE_PAIR_BALANCE, pair: pair };
};
export const incrementPairBalance = (
  pair: PairBalance
): GetTransactionsInfoType => {
  return { type: INCREMENT_BALANCE, pair: pair };
};

export const addNewWallet = (wallet: Wallet): GetTransactionsInfoType => {
  return { type: ADD_NEW_WALLET, wallet: wallet };
};

export const getNextTransactionsPending = (): GetTransactionsInfoType => {
  return { type: FETCH_NEXT_TRANSACTIONS_PENDING };
};

export const getNextTransactionsSuccess = (
  transactions: Transaction[],
  next: string,
  apiNext: number
): GetTransactionsInfoType => {
  return {
    type: FETCH_NEXT_TRANSACTIONS_SUCCESS,
    payload: transactions,
    next,
    apiNext,
  };
};

export const getTransactionsPending = (): GetTransactionsInfoType => {
  return { type: FETCH_TRANSACTIONS_PENDING };
};

export const getTransactionsSuccess = (
  transactions: Transaction[],
  next: string,
  apiNext: number
): GetTransactionsInfoType => {
  return {
    type: FETCH_TRANSACTIONS_SUCCESS,
    payload: transactions,
    next,
    apiNext,
  };
};


const getLiquidityArray = async (item) => {

  let liquiData = await getLiquidityData(item.liquidity_pool_id)

  let coinShort1 =
    liquiData.reserves[0].asset == "native" ? "XLM" : liquiData.reserves[0].asset.split(":")[0];

  let coinShort2 =
    liquiData.reserves[1].asset == "native" ? "XLM" : liquiData.reserves[1].asset.split(":")[0];

  return {
    participants: liquiData?.total_trustlines,
    totalShares: liquiData?.total_shares,
    asset1_amount: liquiData?.reserves[0]?.amount,
    asset2_amount: liquiData?.reserves[1]?.amount,
    short1: coinShort1,
    short2: coinShort2,
    fullName1:
      coinShort1.toLowerCase() == "XLM"
        ? "Lumens"
        : coinsFromShort[coinShort1.toUpperCase()]?.name || coinShort1,
    fullName2:
      coinShort2.toLowerCase() == "XLM"
        ? "Lumens"
        : coinsFromShort[coinShort2.toUpperCase()]?.name || coinShort2,
    coinIcon1:
      coinShort1.toLowerCase() == "XLM"
        ? xlmIcon
        : coinsFromShort[coinShort1.toUpperCase()]?.icon || Icon,
    coinIcon2:
      coinShort2.toLowerCase() == "XLM"
        ? xlmIcon
        : coinsFromShort[coinShort2.toUpperCase()]?.icon || Icon,
  }
}

let AssetIssue = async (assetName, assetIssuer, xlm) => {
  let tomlData = await readToml(assetIssuer, alert);

  let tomlAsset = tomlData.CURRENCIES?.find(
    (item) => item.code == assetName
  );
  /////getting data against particualr coin
  let data: any = null;
  let rates: any = null;
  let obj: any = null;
  let obj2: any = null;
  rates = await getCoinMarketPrice(xlm, {
    code: assetName,
    issuer: assetIssuer,
  });

  if (tomlAsset && tomlAsset.code == assetName.toUpperCase()) {
    obj = {
      image: tomlAsset && tomlAsset?.image ? tomlAsset?.image : Icon,
      btcRate: rates?.btcRate,
      code: tomlAsset ? tomlAsset?.code : assetName.toUpperCase(),
      currency: "USD",
      currentRate: rates?.currencyRate,
      desc: tomlAsset ? tomlAsset?.desc : data?.data?.short_desc,
      displayColor: "#f2a900",
      displayDecimals: tomlAsset?.display_decimals,
      distributionPublic: null,
      high24h: rates?.highConverted,
      homeDomainURL: null,
      infoLink: null,
      isAssetAnchored: 0,
      isErc20: 0,
      isGWalletAnchor: null,
      isTrc20: 0,
      issuer: assetIssuer,
      low24h: rates?.lowConverted,
      marketCap: "0",
      name: tomlAsset ? tomlAsset?.name : assetName.toUpperCase(),
      organization: tomlData?.ORG_NAME
        ? tomlData?.ORG_NAME
        : tomlData?.DOCUMENTATION?.ORG_NAME || "-",
      rateChangeAmount: Number(rates?.change.toFixed(5)),
      rateChangePercent: Number(rates?.changePercent.toFixed(5)),
      short: assetName.toLowerCase(),
      totalVolume: rates?.volume,
      manualIssued: true,
    };
    return obj;
  } else {
    obj2 = {
      image: Icon,
      btcRate: 0.00,
      code: assetName.toUpperCase(),
      currency: "USD",
      currentRate: "0.00",
      desc: "No description found",
      displayColor: "#f2a900",
      displayDecimals: 0,
      distributionPublic: null,
      high24h: "0.00",
      homeDomainURL: null,
      infoLink: null,
      isAssetAnchored: 0,
      isErc20: 0,
      isGWalletAnchor: null,
      isTrc20: 0,
      issuer: assetIssuer,
      low24h: "0.00",
      marketCap: "0",
      name: assetName.toUpperCase(),
      organization: "",
      rateChangeAmount: 0.00,
      rateChangePercent: "0.00",
      short: assetName.toLowerCase(),
      totalVolume: "0.00",
      manualIssued: true,
    }
    return obj2
  }
};

export const setCoinsAction = (): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => async (dispatch, getState) => {
  let state: any = getState();

  let assets = state.assets.assets;
  let marketRates = state.market.markets.find(item => item.name === "USDT")
  const showLoading = state.market.showLoading
  let { CURRENCY } = getPreferences();

  if (!assets[0]) {
    let [currencies, assets1] = await fetchInitial();
    assets = assets1;
    saveInitial(currencies, assets1);
  }
  let selectedId = getSelectedKeys().public;
  let selectedWallet: any = await getWalletById(selectedId);
  try {
    let balances: any[] = await getBalances(selectedId);
    // debugger
    let data: any[] = [];
    let liquidityData: any[] = []
    let AssetsInDb: any = []
    let StellarAssets: any = []
    let AssetsXlm: any = []
    AssetsXlm = assets.filter(val => val.short == "xlm")
    assets = assets.filter(val => val.short != "xlm")

    balances.forEach(async (val, i) => {
      let data1 = assets.find((item) => item?.code == val?.asset_code && item.issuer == val.asset_issuer)
      if (data1) {
        // let val = await AssetIssue(data1.code, data1.issuer, AssetsXlm[0])
        AssetsInDb.push(data1)
      } else {
        StellarAssets.push(val)
      }
    })

    let bool = false
    let sdexAssets: any = localStorage.getItem("sdex_assets");
    StellarAssets = StellarAssets.filter(val => val.asset_type != "native")
    StellarAssets = StellarAssets.filter(val => val.asset_type != "liquidity_pool_shares")
    if (sdexAssets) {
      sdexAssets = JSON.parse(sdexAssets)
      StellarAssets.forEach((val, i) => {
        let data1 = sdexAssets.find((item) => item.code.toUpperCase() == val.asset_code.toUpperCase() && item.issuer == val.asset_issuer)
        if (!data1) {
          bool = true;
        }
      })
    } else {
      bool = true;
    }
    if (bool) {
      StellarAssets = StellarAssets.filter(val => val.asset_type != "native")
      let NewAssetsData: any = []
      for (let i = 0; i < StellarAssets.length; i++) {
        let val = await AssetIssue(StellarAssets[i].asset_code, StellarAssets[i].asset_issuer, AssetsXlm[0])
        if (val) {
          NewAssetsData.push(val)
        }
      }
      assets = AssetsInDb.concat(NewAssetsData)
      assets = assets.concat(AssetsXlm)

      localStorage.setItem('sdex_assets', JSON.stringify(NewAssetsData));
    } else {
      StellarAssets = StellarAssets.filter(val => val.asset_type != "native")

      assets = AssetsInDb.concat(sdexAssets)
      assets = assets.concat(AssetsXlm)
    }

    for (let i = 0; i < balances.length; i++) {
      let item = balances[i];
      if (item.asset_type == "liquidity_pool_shares") {
        let val = await getLiquidityArray(item)
        liquidityData.push(val)
        continue;
      }

      let coinShort =
        item.asset_type === "native" ? "XLM" : item.asset_code.toUpperCase();
      let asset = assets.find((i) => i.short.toUpperCase() === coinShort && i.issuer == item.asset_issuer);
      if (item.asset_type === "native") {
        const balance = getAvailableBalance(item)
        if (Number(balance) < 1.5) {
          dispatch(setCoinLowOnXlm(true))
        }
      }
      if (asset) {
        if (CURRENCY === "USD" && marketRates) {
          const marketRate = marketRates.data.find(item => item.counter === coinShort)
          if (marketRate) {
            const close = marketRate.close === "-" ? 0 : Number(marketRate.close)
            if (close > 0) {
              asset.currentRate = close
            }
          }
        }

        data.push({
          profit: asset ? (asset.rateChangeAmount > 0 ? true : false) : false,
          currentRate: asset.currentRate ?? 0,
          displayColor: asset.displayColor,
          short: coinShort,
          issuer: asset.issuer,
          fullName:
            item.asset_type === "native"
              ? "Lumens"
              : asset?.name || coinsFromShort[item.asset_code.toUpperCase()]?.name,
          coinIcon:
            item.asset_type === "native"
              ? xlmIcon
              : asset?.image || coinsFromShort[item.asset_code.toUpperCase()]?.icon,
          totalBalance: parseFloat(item.balance).toFixed(7),
          balance: parseFloat("" + getAvailableBalance(item)).toFixed(7),
          availableBalance: getUsdBalance(asset, item).toFixed(2),
          btcValue: getBtcBalance(asset, item).toFixed(4),
          percent: asset?.rateChangePercent,
        });
      }
    }
    assets = assets.filter(val => val.isAssetAnchored == 0)
    assets = assets.filter(val => val.short != "xlm")
    assets = assets.concat(state.assets.assets)

    dispatch(fetchAssetsSuccess(assets, CURRENCY));
    dispatch(setCoins(data, selectedWallet, showLoading, liquidityData));
    dispatch(setLoading(false))

  } catch (e) {
    if (e.message == "Request failed with status code 404" || e.message === "Not Found") {

      dispatch(setCoinLocked(true));
      dispatch(
        addNewWallet({
          ...selectedWallet,
          btcBalance: 0,
          percent: 0,
          usdBalance: 0,
        })
      );
      dispatch(setLoading(false))
    } else {
      dispatch(setLoading(false))
      dispatch(setCoinError("Error while getting balances."));
    }
  }
};

export const transactionsHistoryAction = (
  isNew: boolean
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  let state: any = getState();
  let assets = state.assets.assets;

  if (isNew) {
    dispatch(getTransactionsPending());
  }
  Promise.all([getStellarTransactions(false, 20), fetchOverAllTransactions(1)])
    .then((results) => {
      let [trxRes, response] = results;
      let localTransactions =
        trxRes.status == 200
          ? filterOverAllRecords(trxRes.data._embedded.records, assets)
          : [];
      let transactionsLive =
        response.statusText == "OK"
          ? processTransactions(response.data.transactions)
          : [];
      let allTransactions = [...localTransactions, ...transactionsLive];
      let latestTransactions = allTransactions.sort((a, b) => {
        return (
          new Date(b.started_at).valueOf() - new Date(a.started_at).valueOf()
        );
      });

      dispatch(
        getTransactionsSuccess(
          latestTransactions,
          trxRes.data._links.next.href,
          1
        )
      );
    })
    .catch((e) => {
      dispatch(setLoading(false))
    });
};

export const transactionsNextAction = (
  url,
  apiPage
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  let state: any = getState();
  let assets = state.assets.assets;

  dispatch(getNextTransactionsPending());
  Promise.all([
    getStellarTransactions(url, 10),
    fetchOverAllTransactions(apiPage),
  ])
    .then((results) => {
      let [trxRes, response] = results;
      let localTransactions =
        trxRes.statusText == "OK"
          ? filterOverAllRecords(trxRes.data._embedded.records, assets)
          : [];
      let transactionsLive =
        response.statusText == "OK"
          ? processTransactions(response.data.transactions)
          : [];
      let allTransactions = [...localTransactions, ...transactionsLive];
      let latestTransactions = allTransactions.sort((a, b) => {
        return (
          new Date(b.started_at).valueOf() - new Date(a.started_at).valueOf()
        );
      });
      dispatch(
        getNextTransactionsSuccess(
          latestTransactions,
          trxRes.data._links.next.href,
          apiPage
        )
      );
    })
    .catch((e) => {
      dispatch(setCoinError("No Transactions found."));
    });
};

export const updateCoinsAction = (): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => async (dispatch, getState) => {

  let state: any = getState();
  let loadedCurrencies = state.assets.loadedNames;
  try {
    let { CURRENCY } = getPreferences();
    let assets: Asset[] = [];

    // if (loadedCurrencies.includes(CURRENCY)) {
    //   assets = state.assets.assets;
    // } else {
    let url = BASE_URL + "/coins/getAssets?currencyCode=" + CURRENCY;
    assets = await fetch(url).then((data) => data.json());
    dispatch(appendAssetsSuccess(assets, CURRENCY));
    // }

    let selectedId = getSelectedKeys().public;
    let balances: any[] = await getBalances(selectedId);
    let selectedWallet: any = await getWalletById(selectedId);
    let marketRates = state.market.markets.find(item => item.name === "USDT")
    const showLoading = state.market.showLoading
    let data: any[] = [];
    let liquidityData: any[] = [];

    for (let i = 0; i < balances.length; i++) {
      let item = balances[i];
      if (item.asset_type == "liquidity_pool_shares") {
        let val = await getLiquidityArray(item)
        liquidityData.push(val)
        continue;
      }
      let coinShort = item.asset_type === "native" ? "XLM" : item.asset_code;
      let asset: any = assets.find((i) => i.short.toUpperCase() === coinShort && i.issuer == item.asset_issuer);

      if (asset) {
        if (CURRENCY === "USD" && marketRates) {
          const marketRate = marketRates.data.find(item => item.counter === coinShort)
          if (marketRate) {
            const close = marketRate.close === "-" ? 0 : Number(marketRate.close)
            if (close > 0) {
              asset.currentRate = close
            }
          }
        }

        data.push({
          profit: asset ? (asset.rateChangeAmount > 0 ? true : false) : false,
          short: coinShort,
          fullName:
            item.asset_type === "native"
              ? "Lumens"
              : asset?.name || coinsFromShort[item.asset_code.toUpperCase()]?.name,
          coinIcon:
            item.asset_type === "native"
              ? xlmIcon
              : asset?.image || coinsFromShort[item.asset_code.toUpperCase()]?.icon,
          balance: Number(getAvailableBalance(item)).toFixed(7),
          availableBalance: getUsdBalance(asset, item).toFixed(2),
          issuer: asset.issuer,
          btcValue: getBtcBalance(asset, item).toFixed(5),
          totalBalance: Number(item.balance).toFixed(7),
          percent: asset?.rateChangePercent,
          address: "",
          currentRate: asset.currentRate ?? 0,
          displayColor: asset.displayColor,
        });
      }
    }

    dispatch(setCoins(data, selectedWallet, showLoading, liquidityData));
  } catch (e) {
    dispatch(setCoinError("Error while getting balances."));
  }
};

export const updateAssetsRates = (): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => async (dispatch, getState) => {
  const state: any = getState()
  const coins = state.transaction.coins
  const { CURRENCY } = getPreferences();
  const selectedId = getSelectedKeys().public
  let selectedWallet: any = await getWalletById(selectedId);
  const marketRates = state.market.markets.find(item => item.name === "USDT")
  const showLoading = state.market.showLoading
  let coinsNew: Coin[] = []

  coins.forEach((coin: any) => {
    let currentRate = coin.currentRate
    if (CURRENCY === "USD" && marketRates) {
      const marketRate = marketRates.data.find(item => item.counter === coin.short)
      if (marketRate) {
        const close = marketRate.close === "-" ? 0 : Number(marketRate.close)
        if (close > 0) {
          currentRate = close
        }
      }
    }
    coinsNew.push({
      ...coin,
      currentRate: currentRate,
      availableBalance: (coin.totalBalance * currentRate).toFixed(2)
    })
  })

  if (coins.length) {
    dispatch(setCoins(coinsNew, selectedWallet, showLoading, state.transaction.liquidityData));
  }
}

const getAvailableBalance = (item) => {
  return (
    Number(item.balance) - Number(item.selling_liabilities)
  );
};
