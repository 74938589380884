import * as React from "react";
import { favoriteIcon } from "assets/images/images";

export const TableHeading = () => {
  return (
    <tr className="mt-4 mb-4 w-full md:overflow-auto text-left md:w-full lg:overflow-auto lg:w-full xl:overflow-auto xl:w-full">
        <td><img src={favoriteIcon} className="h-3 w-3 md:h-6 md:w-6 lg:h-6 lg:w-6 xl:w-6 xl:h-6" /></td>
          <td className="ml-3 mr-3 py-5 text-secondaryGray font-regular table-cell text-xxxs sm:text-sm md:text-xs lg:text-sm xl:text-sm">Pair</td>
        
          {/* <td className="mr-3 text-secondaryGray font-regular table-cell text-xxxs sm:text-sm md:text-xs lg:text-sm xl:text-sm">Coin</td> */}
        
          <td className="mr-3 text-secondaryGray font-regular table-cell text-xxxs sm:text-sm md:text-xs lg:text-sm xl:text-sm">Last Price</td>
        
          <td className="mr-3 text-secondaryGray font-regular table-cell text-xxxs sm:text-sm md:text-xs lg:text-sm xl:text-sm">24h Changes</td>
        
          <td className="mr-3 text-secondaryGray font-regular table-cell text-xxxs sm:text-sm md:text-xs lg:text-sm xl:text-sm">24h High</td>
        
          <td className="mr-3 text-secondaryGray font-regular table-cell text-xxxs sm:text-sm md:text-xs lg:text-sm xl:text-sm">24h Low</td>
        
          {/* <td className="mr-3 text-secondaryGray font-regular table-cell text-xxxs sm:text-sm md:text-xs lg:text-sm xl:text-sm">Market Cap</td> */}

          <td className="mr-3 text-secondaryGray font-regular table-cell text-xxxs sm:text-sm md:text-xs lg:text-sm xl:text-sm">24h Volume</td>
   
    </tr>
  );
};
