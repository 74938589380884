import * as React from "react";
import { TransactionCol } from "components/molecular/P2P/transactionCol";
import { CustomerRating } from "components/molecular/P2P/CustomerRating";
import { StatusCol } from "components/molecular/P2P/statusCol";
import { getSelectedKeys } from "services/trannsactionServices";

export const TransactionsTableRow = (props: any) => {
  const { tradeKyc, addKyc, tradeAccount, add, isBuyer, fiatTradeAmount, sellerRatings, buyerRatings, cryptoAmountToBuyer, cryptoAmountFromSeller } = props;
  const myAccount = getSelectedKeys().public
  let rating = 0;
  let kyc;
  if(tradeAccount === myAccount){
    kyc = addKyc;
  }else{
    kyc = tradeKyc;
  }
  if(isBuyer){
    rating = sellerRatings
  }else{
    rating = buyerRatings
  }

  const getName = () => kyc.isCompanyKyc?kyc.companyName: kyc?.firstName+" "+kyc?.lastName
  return (
    <div
      onClick={props.onClick}
      className="px-3 cursor-pointer flex items-center justify-between"
    >
      <TransactionCol
        isBuyer={props.isBuyer}
        date={props.date}
        time={props.time}
        cryptoAmountFromSeller={cryptoAmountFromSeller}
        cryptoAmountToBuyer={cryptoAmountToBuyer}
        currency={props.currencyType}
        add={add}
        fiatTradeAmount={fiatTradeAmount}
      />
      <div className="xl:mr-6">
        <button onClick={props.onProfile}>
          <CustomerRating
            rating={rating}
            name={getName()}
          />
        </button>
      </div>
      <StatusCol
        status={props.status}
        timeFrame={props.timeFrame}
        tradeAccount={props.tradeAccount}
        startTime={props.startTime}
        type={props.type}
      />
    </div>
  );
};
