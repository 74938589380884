import { comingsoonImage } from "assets/images";
import React from "react";

export function ComingSoonScreen(props: any) {

  return (
    <form  >
      <p className="text-white py-3 text-xl md:text-title lg:text-title xl:text-title text-center mb-6">
        <div className="w-full bg-primary  mx-auto pt-16 pb-16 " style={{ width: '50%', marginTop: '100px' }}>
          <div className="flex justify-center">
            <img src={comingsoonImage} className="w-12 h-12" alt="Girl in a jacket" />
          </div>
          <p className=" xl:pt-3 md:pt-3 pt-8 text-lg text-white font-bold font-bold text-center" >
            G WALLET NEW FEATURES COMING SOON
              </p>

          <p className=" xl:pt-2 md:pt-3 pt-2 text-xs  text-gray-500 font-regular text-center" >
            Stay tuned for something amazing. You can contact us at
              </p>

          <p className="text-accent text-xs font-regular  text-center" >
            support@gwalletglobal.com
              </p>
        </div>
      </p>
    </form>
  );
}

