import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { RootState } from "store/reducers";
import { checkPath } from "services/AssetServices";
import { saveSwapHistory, sendStrictPathPayment } from "services/exchange";
import { swapErrors } from "utils/constants";
import { PairBalance } from "store/models/Exchange";
import { incrementPairBalance } from "store/actions/TransactionActions";
import { fetchFeeWithLevel } from "services/httpServices";
import { getSelectedKeys } from "services/trannsactionServices";

function useSwap() {
  let {
    assets: { assets },
    transaction: { coins },
    auth,
  } = useSelector((state: RootState) => state);
  const initialSell = assets.find((item) => item.short === "xlm");
  // change initial buy value only for native coins --> anchor coins temporary hide
  // const initialBuy = assets.find((item) => item.short === "btc");
  const initialBuy = assets.find((item) => item.short === "levelg");
  let init: any = undefined;
  const alert = useAlert();
  const dispatch = useDispatch();

  let [state, setState] = useState({
    sellling: initialSell,
    buying: initialBuy,
    path: init,
    loading: false,
    isOpen: false,
    type: typeEnum.BUY,
    amount: "",
    amountVal: "",
    errorMessage: "",
    finding: false,
    paying: false,
    fee: 0,
    feeAccount: "",
  });

  const onClose = () => setState((prev) => ({ ...prev, isOpen: false }));
  const handleCancel = () =>
    setState((prev) => ({ ...prev, path: undefined, paying: false }));
  const getAssetCode = (type, code) => (type === "native" ? "XLM" : code);
  const openSell = () =>
    setState((prev) => ({ ...prev, isOpen: true, type: typeEnum.SELL }));
  const openBuy = () =>
    setState((prev) => ({ ...prev, isOpen: true, type: typeEnum.BUY }));

  const replaceAsset = () => {
    setState((prev) => ({
      ...prev,
      sellling: prev.buying,
      buying: prev.sellling,
      path: undefined,
    }));
  };

  const onSuccess = (asset, type) => {
    if (type === typeEnum.SELL) {
      setState((prev) => ({
        ...prev,
        isOpen: false,
        sellling: asset,
        path: undefined,
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      isOpen: false,
      buying: asset,
      path: undefined,
    }));
  };

  const handleSubmit = async () => {
    if (!validated()) {
      return;
    }
    const source = {
      code: state.sellling?.short.toUpperCase(),
      issuer: state.sellling?.issuer,
    };
    const destination = {
      code: state.buying?.short.toUpperCase(),
      issuer: state.buying?.issuer,
    };
    setState((prev) => ({ ...prev, finding: true }));
    await getFee();
    checkPath({ source, destination, amount: state.amount }).then((data) => {
      let paths: any = [];
      data.records.forEach((record) => {
        if (
          (record.destination_asset_type === "native"
            ? "XLM"
            : record.destination_asset_code) ===
            state.buying?.short.toUpperCase() &&
          state.buying?.issuer == record.destination_asset_issuer
        ) {
          paths.push(record);
        }
      });

      if (paths[0]) {
        setState((prev) => ({
          ...prev,
          path: paths[0],
          errorMessage: "",
          finding: false,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          errorMessage: "No path found for this asset.",
          finding: false,
        }));
      }
    });
  };
  const getFee = async () => {
    await fetchFeeWithLevel(state.sellling?.short?.toUpperCase())
      .then((data) => {
        const {
          ExchangeFinalFeeAddPub,
          levelfee: { swapFeeFixed, swapFeePercent },
        } = data.data;
        const fee = calculateFee(swapFeePercent, swapFeeFixed);
        setState((prev) => ({
          ...prev,
          fee: fee,
          feeAccount: ExchangeFinalFeeAddPub,
        }));
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, loading: false }));
      });
  };
  const calculateFee = (feePercent, feeFixed) => {
    const percent = (feePercent / 100) * Number(state.amount);
    return feeFixed + percent;
  };

  const getBestPath = (paths) => {
    let maxValue = 0;
    let maxRecord: any = {};
    paths.forEach((path) => {
      if (parseFloat(path.destination_amount) > maxValue) {
        maxValue = parseFloat(path.destination_amount);
        maxRecord = path;
      }
    });
    return maxRecord;
  };

  const updateBalance = () => {
    const {
      source_asset_type,
      source_amount,
      destination_asset_type,
      destination_asset_code,
      destination_amount,
      source_asset_code,
    } = state.path;

    const obj: PairBalance = {
      baseCode: source_asset_type === "native" ? "XLM" : source_asset_code,
      baseBalance: source_amount,
      counterCode:
        destination_asset_type === "native" ? "XLM" : destination_asset_code,
      counterBalance: destination_amount,
    };

    dispatch(incrementPairBalance(obj));
  };

  const createPath = () => {
    const record = state.path;
    if (!record.source_asset_type) return;
    let path =
      getAssetCode(record.source_asset_type, record.source_asset_code) + " > ";
    record.path.forEach((item) => {
      path += getAssetCode(item.asset_type, item.asset_code) + " > ";
    });
    path += getAssetCode(
      record.destination_asset_type,
      record.destination_asset_code
    );
    return path;
  };

  const isTrusted = (asset) => {
    const coin = coins.find((coin) => coin.short.toUpperCase() === asset);
    if (coin) {
      return true;
    } else {
      return false;
    }
  };

  const sendTransaction = () => {
    if (state.paying) {
      return;
    }
    const sendAsset = {
      code: state.sellling?.short.toUpperCase(),
      issuer: state.sellling?.issuer,
    };
    const destAsset = {
      code: state.buying?.short.toUpperCase(),
      issuer: state.buying?.issuer,
    };
    const sendTrusted = isTrusted(sendAsset.code);
    const destTrusted = isTrusted(destAsset.code);
    if (!auth.password) {
      alert.show("You are not logged in.");
      return;
    } else if (!sendTrusted || !destTrusted) {
      let msg = "You need to trust ";
      if (!destTrusted && sendTrusted) msg += destAsset.code;
      if (destTrusted && !sendTrusted) msg += sendAsset.code;
      if (!destTrusted && !sendTrusted)
        msg += `${sendAsset.code} and ${destAsset.code}`;
      alert.show(msg);
      return;
    }
    const coin = coins.find(
      (item) => item.short.toUpperCase() === state.sellling?.short.toUpperCase()
    );
    if (coin && coin?.balance < Number(state.amount) + state.fee) {
      alert.show("Not enough balance.");
      return;
    }
    setState((prev) => ({ ...prev, paying: true }));
    sendStrictPathPayment({
      sendAsset: sendAsset,
      sendAmount: state.amount,
      destAsset: destAsset,
      destMin: state.path?.destination_amount, //(Number(state.amount) / 2).toFixed(5),
      path: state.path.path,
      fee: state.fee,
      feeAccount: state.feeAccount,
    })
      .then((data) => {
        updateBalance();
        alert.show("Swap completed successfully.", { type: "success" });
        saveSwapHistory({
          fromAmount: state.path.source_amount,
          fee: state.fee,
          fromAsset: state.sellling?.short.toUpperCase() || "",
          toAsset: state.buying?.short.toUpperCase() || "",
          toAmount: state.path.destination_amount,
          createdAt: new Date(),
          account: getSelectedKeys().public,
          trxId: data.hash,
        }).catch((e) => {
          console.log("couldn't save history", e);
        });
        setState((prev) => ({
          ...prev,
          paying: false,
          path: init,
          amount: "",
        }));
      })
      .catch((e) => {
        alert.show(getSwapMessage(e, "Unable to swap."), { type: "error" });
        setState((prev) => ({ ...prev, paying: false }));
      });
  };

  const getSwapMessage = (e, defMsg) => {
    let message;
    console.log(e);
    if (e.response?.status === 504) {
      return "Request timeout please try again";
    }
    try {
      message =
        swapErrors[e.response.data.extras.result_codes.operations[0]] || defMsg;
    } catch (err) {
      message = defMsg;
    }
    return message;
  };

  const validated = () => {
    let response = true;
    if (state.amount === "") {
      setState((prev) => ({ ...prev, amountVal: "Amount is required" }));
      response = false;
    }
    if (parseFloat("" + state.amount) <= 0) {
      setState((prev) => ({
        ...prev,
        amountVal: "Amount should be greater than 0",
      }));
      response = false;
    }
    if (parseFloat("" + state.amount) <= 0) {
      setState((prev) => ({
        ...prev,
        amountVal: "Amount should be greater than 0",
      }));
      response = false;
    }
    if (state.loading === true) {
      response = false;
    }
    if (state.finding === true) {
      response = false;
    }
    return response;
  };

  const setInput = (e) => {
    let { value } = e.target;
    setState((prev) => ({ ...prev, amount: value, amountVal: "", path: null }));
  };

  return {
    state,
    onClose,
    onSuccess,
    openSell,
    setInput,
    handleSubmit,
    openBuy,
    createPath,
    getAssetCode,
    replaceAsset,
    handleCancel,
    sendTransaction,
  };
}

export default useSwap;

let typeEnum = { SELL: "SELL", BUY: "BUY" };
