import { getData, getPreferences } from "./storageServices";
import { initWithToken } from "./p2pServives";
import { Transaction } from "stellar-sdk";
import { store } from "App";
import { STELLAR_URL, stellarNetworkPhrase, BASE_URL } from "utils/AppConstants";
import { decrypt } from "utils/helpers";
import { memoTypes } from "utils/constants";
import { getJWT } from "services/httpServices";
import Axios from "axios";
var StellarSdk = require("stellar-sdk");
var server = new StellarSdk.Server(STELLAR_URL);


let stellarFee = async () => {
  let token = await getJWT();
  let res = await Axios.get(BASE_URL + `/feesetting-levels/stellar-fee`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.data?.token}`,
    }
  });
  return res.data;
}

let getSelectedKeys = () => {
  let wallet = getData("selectedWallet");
  let walletObj = JSON.parse(wallet);
  let temp = wallet ? walletObj : { private: "", public: "" };
  return temp;
};

let getSelectedSecret = () => {
  let wallet = getData("selectedWallet");
  let walletObj = JSON.parse(wallet);
  let key = walletObj.private ? decrypt(walletObj.private) : "";
  return key;
};

// Transaction will hold a built transaction we can resubmit if the result is unknown.
var transaction;

const sendBalance = async ({ amount, address }) => {
  return new Promise(async (resolve, reject) => {
    if (address.includes("*")) {
      address = await getIdFromDomain(address);
    }

    var sourceKeys = StellarSdk.Keypair.fromSecret(getSelectedSecret());

    server
      .loadAccount(address)
      .catch(function (error) {
        if (error instanceof StellarSdk.NotFoundError) {
          throw new Error("The destination account does not exist!");
        } else return error;
      })
      .then(function () {
        return server.loadAccount(sourceKeys.publicKey());
      })
      .then(function (sourceAccount) {
        transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
          fee: "1000", //StellarSdk.BASE_FEE,
          networkPassphrase: stellarNetworkPhrase,
        })
          .addOperation(
            StellarSdk.Operation.payment({
              destination: address,
              asset: StellarSdk.Asset.native(),
              amount: Number(amount).toFixed(7),
            })
          )
          .addMemo(StellarSdk.Memo.text("Send Balance!"))
          .setTimeout(180)
          .build();
        transaction.sign(sourceKeys);
        return server.submitTransaction(transaction);
      })
      .then(function (result) {
        resolve(result);
      })
      .catch(function (error) {
        console.error("Something went wrong!", error);
        // debugger;
        // If the result is unknown (no response body, timeout etc.) we simply resubmit
        // already built transaction:
        // server.submitTransaction(transaction);
        reject(error);
      });
  });
};

const isAccountFunded = async (address) => {
  try {
    if (address.includes("*")) {
      address = await getIdFromDomain(address);
    }

    await server.loadAccount(address);

    return true;
  } catch (error) {
    if (
      error?.message === "Request failed with status code 404" ||
      error?.message === "Not Found"
    ) {
      return false;
    } else {
      return true;
    }
  }
};

const sendBalanceToUnFundedAccount = async ({
  amount,
  address,
  fee,
  feeAccount,
  memoIndex,
  memo,
}) => {
  return new Promise(async (resolve, reject) => {
    if (address.includes("*")) {
      address = await getIdFromDomain(address).catch((e) => {
        reject({
          customMessage:
            "Couldn't load federation address. Try using real address.",
        });
      });
    }

    var sourceKeys = StellarSdk.Keypair.fromSecret(getSelectedSecret());

    server
      .loadAccount(sourceKeys.publicKey())
      .then(function (sourceAccount) {
        if (Number(fee) > 0) {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: "1000", //StellarSdk.BASE_FEE,
            networkPassphrase: stellarNetworkPhrase,
          })
            .addOperation(
              StellarSdk.Operation.createAccount({
                destination: address,
                startingBalance: amount.toString(),
              })
            )
            .addOperation(
              StellarSdk.Operation.createAccount({
                destination: feeAccount,
                startingBalance: fee.toString(),
              })
            )
            .addMemo(
              StellarSdk.Memo[memoTypes[memoIndex]](memoIndex !== 0 && memo)
            )
            .setTimeout(180)
            .build();
        } else {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: "1000", //StellarSdk.BASE_FEE,
            networkPassphrase: stellarNetworkPhrase,
          })
            .addOperation(
              StellarSdk.Operation.createAccount({
                destination: address,
                startingBalance: amount.toString(),
              })
            )
            .addMemo(
              StellarSdk.Memo[memoTypes[memoIndex]](memoIndex !== 0 && memo)
            )
            .setTimeout(180)
            .build();
        }
        transaction.sign(sourceKeys);
        return server.submitTransaction(transaction);
      })
      .then(function (result) {
        resolve(result);
      })
      .catch(function (error) {
        console.error("Something went wrong!", error);
        console.error("response!", error.response);
        // debugger;
        // If the result is unknown (no response body, timeout etc.) we simply resubmit
        // already built transaction:
        // server.submitTransaction(transaction);
        reject(error);
      });
  });
};

const setHomeDomain = async (name) => {
  var issuingKeys = StellarSdk.Keypair.fromSecret(getSelectedSecret());
  // debugger;
  return new Promise((resolve, reject) => {
    server
      .loadAccount(issuingKeys.publicKey())
      .then(function (issuer) {
        var transaction = new StellarSdk.TransactionBuilder(issuer, {
          fee: 100,
          networkPassphrase: stellarNetworkPhrase,
        })
          .addOperation(
            StellarSdk.Operation.setOptions({
              homeDomain: name,
            })
          )
          // setTimeout is required for a transaction
          .setTimeout(100)
          .build();
        transaction.sign(issuingKeys);
        return server.submitTransaction(transaction);
      })
      .then((data) => {
        resolve("Success!");
      })
      .catch(function (error) {
        reject(error);
        console.error("Error!", error);
      });
  });
};

const updateAccount = async (publicKey) => {
  try {
    await server
      .accounts()
      .accountId(publicKey)
      .call()
      .then((account) => {});
  } catch (err) {
    // console.log(err);
  }
};

const getBalances = (selectedId) => {
  return new Promise((resolve, reject) => {
    if (selectedId) {
      server
        .loadAccount(selectedId)
        .then((data) => {
          let balances = data.balances;
          // debugger
          resolve(balances);
        })
        .catch((e) => {
          // debugger
          reject(e);
        });
    } else {
      reject("New User.");
    }
  });
};

const getTransactionsLive = (key) => {
  return new Promise((resolve, reject) => {
    server
      .loadAccount(key)
      .then((data) => {
        data
          .payments()
          .then((d) => {
            resolve(d);
          })
          .catch((e) => {
            reject(e);
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
const getTransactionFee = () => {
  return new Promise((resolve, reject) => {
    server
      .ledgers()
      .ledger("69858")
      .call()
      .then(function (ledgerResult) {
        resolve(ledgerResult.base_fee_in_stroops);
      })
      .catch(function (err) {
        reject(err);
      });
  });
};
var closeStream = () => {};
async function lookForPayments(accountId, callback) {
  let cursor = "now";
  try {
    await server.loadAccount(accountId);
  } catch (error) {
    if (error instanceof StellarSdk.NotFoundError) {
      cursor = undefined;
    }
  }
  closeStream();
  closeStream = server
    .payments()
    .forAccount(accountId)
    .cursor(cursor)
    .stream({
      onmessage: function (payment) {
        if (payment.to && payment.to !== accountId) {
          return;
        }
        if (payment.account && payment.account !== accountId) {
          return;
        }

        var asset;
        var coin;
        if (payment.asset_type === "native") {
          asset = "lumens";
          coin = "xlm";
        } else {
          asset = payment.asset_code;
          coin = payment.asset_code;
        }
        if (getPreferences().NOTIFICATION_SOUND) {
          playAlert();
        }

        if (payment.to) {
          callback("You have received: " + payment.amount + " " + asset);
        } else {
          initWithToken();
          callback("You have received starting balance.");
        }
      },

      onerror: function (error) {
        // console.log("Stream error", error);
      },
    });
}

let getAssetByShort = (short) => {
  let state = store.getState();
  let asset = state.assets.assets.find(
    (item) => item.short.toUpperCase() == short
  );
  return asset;
};

const playAlert = () => {
  let sound = new Audio();
  sound.src = "sounds/swiftly.mp3";
  sound.play();
};

const convertAsset = (assetCode, issuer) => {
  if (assetCode === "XLM") {
    return StellarSdk.Asset.native();
  } else {
    return new StellarSdk.Asset(assetCode, issuer);
  }
};

export const memoExistInStellar = async (memo, account) => {
  var transactions = await server
    .transactions()
    .forAccount(account)
    .limit(200)
    .order("desc")
    .call();

  for (let i = 0; i < transactions.records.length; i++) {
    const element = transactions.records[i];
    if (element.memo === memo) {

      return { exists: true, hash: element.hash };
    }
  }
  return { exists: false, hash: undefined };
};

const sendNonNativeBalance = (
  sourceSecretKey,
  destinationId,
  amount,
  memo,
  assetCode,
  issuer
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // var assetType = assetCode?StellarSdk.Asset.credit_alphanum12(): StellarSdk.Asset.native();

      let transaction;

      const sourceKeypair = StellarSdk.Keypair.fromSecret(sourceSecretKey);

      const sourcePublicKey = sourceKeypair.publicKey();

      server
        .loadAccount(destinationId)
        .catch((error) => {
          if (error instanceof StellarSdk.NotFoundError) {
            throw new Error("The destination account does not exist!");
          } else return error;
        })
        .then(() => {
          return server.loadAccount(sourcePublicKey);
        })
        .then((sourceAccount) => {
          transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
            fee: "1000", //StellarSdk.BASE_FEE,
            networkPassphrase: stellarNetworkPhrase,
          })
            .addOperation(
              StellarSdk.Operation.payment({
                destination: destinationId,
                asset: convertAsset(assetCode, issuer),
                amount: Number(amount).toFixed(7),
              })
            )
            .addMemo(StellarSdk.Memo.text(memo))
            .setTimeout(180)
            .build();

          transaction.sign(sourceKeypair);
          return server.submitTransaction(transaction);
        })
        .then(function (result) {
          resolve(result);
        })
        .catch(function (error) {
          reject(error);
        });
    } catch (err) {
      // console.log(err);
      reject(err);
    }
  });
};

const getIdFromDomain = async (domain) => {
  let federationRecord = await StellarSdk.FederationServer.resolve(domain);
  return federationRecord.account_id;
};

const sendAssets = (
  sourceSecretKey,
  destinationId,
  amount,
  asset,
  fee,
  feeAccount,
  memoIndex,
  memo,
  check
) => {
  return new Promise(async (resolve, reject) => {
    if (destinationId.includes("*")) {
      destinationId = await getIdFromDomain(destinationId).catch((e) => {
        reject({
          customMessage:
            "Couldn't load federation address. Try using real address.",
        });
      });
    }
    try {
      const stellarBaseFee = await stellarFee();
      var server = new StellarSdk.Server(STELLAR_URL);
      let transaction;
      const sourceKeypair = StellarSdk.Keypair.fromSecret(sourceSecretKey);
      const sourcePublicKey = sourceKeypair.publicKey();

      const assetStellar =
        asset.short === "xlm"
          ? StellarSdk.Asset.native()
          : new StellarSdk.Asset(asset.code.toUpperCase(), asset.issuer);
      server
        .loadAccount(destinationId)
        .catch((error) => {
          if (error instanceof StellarSdk.NotFoundError) {
            throw new Error("The destination account does not exist!");
          } else return error;
        })
        .then(() => {
          return server.loadAccount(sourcePublicKey);
        })
        .then((sourceAccount) => {
          if (fee == 0) {
            transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: `${stellarBaseFee}`, //StellarSdk.BASE_FEE,
              networkPassphrase: stellarNetworkPhrase,
            })
              .addOperation(
                StellarSdk.Operation.payment({
                  destination: destinationId,
                  asset: assetStellar,
                  amount: Number(amount).toFixed(7),
                })
              )
              .addMemo(StellarSdk.Memo.text(memo && memo))
              // .addMemo(
              //   StellarSdk.Memo[memoTypes[memoIndex]](memoIndex !== 0 && memo)
              // )
              .setTimeout(180)
              .build();
          } else {
            transaction = new StellarSdk.TransactionBuilder(sourceAccount, {
              fee: `${stellarBaseFee}`, //StellarSdk.BASE_FEE,
              networkPassphrase: stellarNetworkPhrase,
            })
              .addOperation(
                StellarSdk.Operation.payment({
                  destination: destinationId,
                  asset: assetStellar,
                  amount: Number(
                    check ? Number(amount) - Number(fee) : Number(amount)
                  ).toFixed(7),
                })
              )
              .addOperation(
                StellarSdk.Operation.payment({
                  destination: feeAccount,
                  asset: assetStellar,
                  amount: Number(fee).toFixed(7),
                })
              )
              .addMemo(StellarSdk.Memo.text(memo && memo))
              // .addMemo(
              //   StellarSdk.Memo[memoTypes[memoIndex]](memoIndex !== 0 && memo)
              // )
              .setTimeout(180)
              .build();
          }
          transaction.sign(sourceKeypair);
          return server.submitTransaction(transaction);
        })
        .then(function (result) {
          resolve(result);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    } catch (err) {
      reject(err);
    }
  });
};

const signTransaction = async (secret, { network_passphrase, transaction }) => {
  var sourceKeys = StellarSdk.Keypair.fromSecret(secret);
  const transactionInit = new Transaction(transaction, network_passphrase);
  await transactionInit.sign(sourceKeys);
  return transactionInit.toXDR();
};

export {
  sendBalance,
  updateAccount,
  getBalances,
  getTransactionFee,
  getSelectedKeys,
  lookForPayments,
  setHomeDomain,
  signTransaction,
  sendNonNativeBalance,
  sendAssets,
  isAccountFunded,
  sendBalanceToUnFundedAccount,
  getIdFromDomain,
  getSelectedSecret,
};
