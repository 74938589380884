import React, { useState } from "react";
import CustomInput from "components/global/CustomInput";
import { Button } from "components";
import useLogin from "./useLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Login = (props: any) => {
  const { styles = [" "] } = props;
  const {
    password,
    handleInputChange,
    handleSubmit,
    submitting,
    passwordValidation,
  } = useLogin();

  let renderBtn = () => {
    if (submitting) {
      return (
        <div>
          {" "}
          <FontAwesomeIcon icon={faSyncAlt} spin /> Submitting...
        </div>
      );
    }
    return "Login";
  };

  return (
    <form onSubmit={handleSubmit}>
      <p className="text-white py-3 text-xl md:text-title lg:text-title xl:text-title text-center mb-6">
        Login
      </p>
      <CustomInput
        showIcon={true}
        labelStyle={"mb-3"}
        placeholder="Enter your password"
        value={password}
        onChange={handleInputChange}
        valMsg={passwordValidation}
        name="password"
        type="password"
      />

      <Button
        type="submit"
        styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
      >
        {renderBtn()}
      </Button>

      <div className="flex justify-center">
        <Link to="/forgotPassword" className="text-accent mt-3 text-sm">
          Forgot Password ?{" "}
        </Link>
      </div>
    </form>
  );
};

export default Login;
