import Dexie from 'dexie';

const db: any = new Dexie('GWalletDB');

db.version(1).stores({
    wallets: `id, name, public, private, btcBalance, percent, usdBalance`,
    assets: `++id, issuer, infoLink, displayDecimals, desc, displayColor, coingeckoId, distributionPublic, currency, short, code, isAssetAnchored, organization, isGWalletAnchor, name, currentRate, btcRate, rateChangeAmount, rateChangePercent, marketCap, totalVolume, high24h, low24h`,
    currencies: `++id, code, name, symbol`
});

export default db;
