import * as React from "react";
import { ProfileLeftBlock } from "components/organisms/ProfileScreen/ProfileLeftBlock";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { KYCProfileTab } from "./ProfileScreen tabs/KYCProfileTab";
import { AppSettingsTab } from "./ProfileScreen tabs/AppSettingsTab";
import { WalletSecurityTab } from "./ProfileScreen tabs/WalletSecurityTab";
import { WalletSettingsTab } from "./ProfileScreen tabs/WalletSettingsTab";
import { ReviewsTab } from "./ProfileScreen tabs/ReviewsTab";
import { ReferFriendTab } from "./ProfileScreen tabs/ReferFriendTab";
import ZakatTab from "./ProfileScreen tabs/ZakatTab";
import FAQs from "./ProfileScreen tabs/FAQs";
import P2PBank from "./ProfileScreen tabs/P2PBank";
import P2pDetail from "./ProfileScreen tabs/P2pDetails";

export const ProfileScreen = () => {
  let history: any = useHistory();
  const [selected, setSelected] = React.useState(history.location.state&&history.location.state['page'] || 0);
  return (
    <div className="md:flex w-5/6 mt-10 mx-auto">
      <ProfileLeftBlock callback={(i)=>setSelected(i)} index={selected} />
      {selected === 0 && <KYCProfileTab />}
      {selected === 1 && <AppSettingsTab />}
      {selected === 2 && <WalletSettingsTab />}
      {selected === 3 && <WalletSecurityTab />}
      {selected === 4 && <ReviewsTab />}
      {selected === 5 && <FAQs />}
      {selected === 6 && <ReferFriendTab />}
      {selected === 7 && <ZakatTab />}
      {selected === 8 && <P2PBank />}
      {selected === 9 && <P2pDetail />}
    </div>
  );
};
