import { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";
import {
  getPreferences,
  setPrefrences,
} from "services/storageServices";
import { getSelectedKeys, getSelectedSecret } from "services/trannsactionServices";
import { startRepeating, stopRepeating } from "services/genericServices";

const useSecurity = () => {
  let [state, setState] = useState({
    password: "",
    privateKey: "",
    secretVisible: false,
  });
  let alert = useAlert();

  useEffect(() => {
    let {PASSWORD} = getPreferences();
    let key = getSelectedSecret();
    
    setState((prev) => ({
      ...prev,
      password: PASSWORD,
      privateKey: key,
    }));
  }, []);

  let saveChanges = (e) => {
    e.preventDefault();
    let prefrences: any = getPreferences();
    prefrences = { ...prefrences, PASSWORD: state.password };
    setPrefrences(prefrences);
    alert.removeAll();
    alert.show("Saved changes!", { type: "success" });
    
    if (state.password === "After 30 minutes") {
      startRepeating();
    } else {
      stopRepeating();
    }
  };

  let handleInputChange = (e) => {
    let { value } = e.target;
    setState((prev) => ({ ...prev, password: value }));
  };

  let toggleSecretVisibility = () => {
    setState((prev) => ({ ...prev, secretVisible: true }));
  };

  let copyToClip = () => {
    copy(state.privateKey);
    alert.removeAll();
    alert.show("Copied to clipboard!", { type: "success" });
  };

  return {
    ...state,
    handleInputChange,
    saveChanges,
    toggleSecretVisibility,
    copyToClip,
  };
};

export default useSecurity;
