import React from 'react';
import { NavLink } from 'react-router-dom';

function NoAuthOverlay() {
    return(
        <div className="absolute inset-0 flex flex-col justify-center text-sm items-center" style={{background: "rgba(0, 0, 0, 0.8)"}}>
            <NavLink to="/register" className="focus:outline-none py-2 rounded px-4 bg-accent text-white">Register</NavLink>
            <NavLink to="/login" className="focus:outline-none text-accent mt-2">Already have an account?</NavLink>
        </div>
    )
}

export default NoAuthOverlay
