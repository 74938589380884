import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { profileStatus } from "utils/constants";

export const AdvertisementFormTopRow = () => {
  const user = useSelector((state:RootState)=>state.profile.user)
  return (
    <div className="flex bg-primary items-center w-full justify-between p-2">
      <p className="text-white text-xs md:text-sm lg:text-sm xl:text-sm font-regular">
        Advertisement Details
      </p>
      {user.status !== profileStatus.approved&&<button className="bg-red text-xxxs md:text-xxs lg:text-xxs xl:text-xxs text-white py-1 px-3 rounded-full">
        Verify Your Account
      </button>}
    </div>
  );
};
