import * as React from "react";
import { AdsCol } from "components/molecular/P2P/AdsCol";
import { CustomerRating } from "components/molecular/P2P/CustomerRating";
import { colors } from "styles";
import Switch from "react-switch";

export const MyAdCard = ({
  payment,
  item,
  status,
  currency,
  onAction,
  maxamount,
  advertiser,
  isProfile,
  usdRate
}) => {
  return (
    <div className={`flex w-full p-2 ${!isProfile&&'border-primary border'} justify-between items-center`}>
      <AdsCol item={item} usdRate={usdRate} maxamount={maxamount} currency={currency} />
      <p className="flex text-secondaryGray text-xxxs md:text-xs lg:text-xs xl:text-xs font-regular">
        {payment}
      </p>
      {!isProfile&&<CustomerRating name={advertiser} />}

      <Switch
          onChange={()=>onAction((status === 'Active' ?'Paused':'Active'))}
          checked={status === 'Active'}
          checkedIcon={false}
          uncheckedIcon={false}
          className="react-switch"
        />
      {/* <button
        style={{ backgroundColor: status === 'Active' ? colors.pink : colors.green }}
        className="px-3 py-2 text-white text-xxxs md:text-sm lg:text-sm xl:text-sm font-regular rounded md:px-4 md:py-3 lg:px-4 lg:py-3 xl:px-4 xl:py-3"
        onClick={()=>onAction((status === 'Active' ?'Paused':'Active'))}
      >
        {status === 'Active' ? "Deactivate" : "Activate"}
      </button> */}
    </div>
  );
};
