import { fetchGraphInfo } from "services/httpServices"
import { useEffect } from "react"


let useAbout = () => {

    let initialize = () => {
        fetchGraphInfo('BTC').then(data=>{

        }).catch(e=>{

        })
    }

    useEffect(()=>{
        //initialize()
    },[])

    return {initialize}
}

export default useAbout