import * as React from "react";
import { RadioButton } from "components/molecular/ProfileScreen/RadioButton";
import { Button } from "components/global";
import "./roundedslider.css";
import useSettings from "./hooks/useSettings";
import { SelectOption } from "components/molecular/SelectOption";
import { fees } from "utils/constants";

export const AppSettingsTab = (props: any) => {
  let {
    feeValue,
    setFee,
    setCurrency,
    getCurrencies,
    selectedCurrency,
    sound,
    saveSound,
    showMemo,
    setMemo,
    submit
  } = useSettings();

  return (
    <div className="border border-primary mb-6 p-5 md:w-5/6 lg:w-5/6 xl:w-5/6 w-full">
      <p className="text-white font-regular text-lg">App Settings</p>
      <div className="flex justify-between">
        <div className="w-3/6 mr-1">
          <SelectOption
            border="none"
            label="Language"
            onChange={(e) => {}}
            data={["English"]}
          />
        </div>
        <div className="w-3/6 ml-1 ">
          <SelectOption
            border="none"
            label="Currency"
            value={selectedCurrency}
            onChange={setCurrency}
            data={getCurrencies()}
          />
        </div>
      </div>
      <div className="mt-10">
        <div className="mb-4">
          <p className="text-white font-regular text-lg">
            G Wallet Network Fee:{" "}
            <span className="text-accent">{fees[feeValue]}</span>
          </p>
          <p className="text-secondaryGray text-xs font-regular">
          You may choose maximum fee to enhance the transactions efficiency.
          </p>
        </div>
        <RadioButton
          label="Low"
          name="fee"
          value="fee_min"
          selected={feeValue === "fee_min"}
          onChange={setFee}
        />
        <RadioButton
          label="Medium"
          name="fee"
          value="fee_avg"
          selected={feeValue === "fee_avg"}
          onChange={setFee}
        />
        <RadioButton
          label="High"
          name="fee"
          value="fee_max"
          selected={feeValue === "fee_max"}
          onChange={setFee}
        />
      </div>
      <div className="border-t mt-4 border-primary border-b p-2">
        <div className="flex justify-between items-center">
          <p className="text-white font-regular text-sm md:text-lg lg:text-lg xl:text-lg">
            Play Notification Sound
          </p>
          <label className="switch">
            <input type="checkbox" onChange={saveSound} checked={sound} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <div className=" border-primary border-b p-2">
        <div className="flex justify-between items-center">
          <p className="text-white font-regular text-sm md:text-lg lg:text-lg xl:text-lg">
            Show Memo
          </p>
          <label className="switch">
            <input type="checkbox" checked={showMemo} onChange={setMemo} />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      <Button
        onClick={submit}
        styles={["bg-accent font-regular text-xs py-3 px-4 mt-10"]}
      >
        Save Changes
      </Button>
    </div>
  );
};
