import * as React from "react";
import { AdvertisementDetailsCard } from "components/organisms/markets/P2P/AdvertisementDetailsCard";
import { PurchaseForm } from "components/organisms/markets/P2P/PurcahseForm";
import useAddTrade from "./hooks/P2P/useAddTrade";
import { paymentMethodsEnum } from "./hooks/P2P/useAdd";

export const TransactionConfirmationScreen = (props: any) => {
  
  const {
    amount,
    amountVal,
    amountInCurrency,
    amountInCurrencyVal,
    handleInputChange,
    add,
    submit,
    submitting,
    onProfile,
    fee,
    feeLoading,
    calcAmountWSpread,
    bank_name,
    bank_account_number,
    bank_account_title,
    bank_nameVal,
    bank_account_numberVal,
    bank_account_titleVal,
    setBankConfirmed,
    bankConfirmed,
    showEmail, showBankInfo
  } = useAddTrade(props.buy);

  return (
    <div className="py-5">
      <PurchaseForm
        amount={amount}
        amountVal={amountVal}
        amountInCurrency={amountInCurrency}
        amountInCurrencyVal={amountInCurrencyVal}
        handleInputChange={handleInputChange}
        buy={props.buy}
        submit={submit}
        submitting={submitting}
        add={add}
        onProfile={onProfile}
        fee={fee}
        feeLoading={feeLoading}
        bank_name={bank_name}
        bank_account_number={bank_account_number}
        bank_account_title={bank_account_title}
        bank_nameVal={bank_nameVal}
        bank_account_numberVal={bank_account_numberVal}
        bank_account_titleVal={bank_account_titleVal}
        setBankConfirmed={setBankConfirmed}
        bankConfirmed={bankConfirmed}
        showBankInfo={showBankInfo}
        showEmail={showEmail}
      />
      <AdvertisementDetailsCard
        buy={props.buy}
        add={add}
        calcAmountWSpread={calcAmountWSpread}
      />
    </div>
  );
};
