import * as React from "react";
import * as images from "../../../assets/images/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";
import { getSelectedKeys } from "services/trannsactionServices";
import { useHistory } from "react-router-dom";
export const ActivationStatusCard = ({ publicKey, loading, locked }) => {
  let alert = useAlert();
  let history = useHistory();
  let copyToClip = () => {
    copy(getSelectedKeys().public);
    alert.removeAll();
    alert.show("Copied!", { type: "success" });
  };

  return (
    <div className="mt-3 xl:mt-0 xl:ml-2 border-dashed border-2 w-full border-primary relative flex justify-between items-center p-3 xl:p-5 lg:p-5">
      <div className="flex-1 flex items-center w-full">
        <img src={locked?images.lockIcon:'assets/lock-green.svg'} className="h-6 w-4 lg:h-10 lg:w-8 xl:h-10 xl:w-8" />
        <div className="flex-1 flex flex-col ml-2 xl:ml-3 lg:ml-3 mr-4">
          {locked && (
            <text className="text-white text-xs xl:text-xs font-regular">
              Please send some XLM to activate your account.
            </text>
          )}
          <button
            onClick={copyToClip}
            className="focus:outline-none bg-primary text-gray-600 rounded p-1 xl:p-2 lg:p-2 text-xs flex justify-between items-center"
            style={{maxWidth: "95%"}}
          >
            <p className="overflow-x-auto mr-1 text-xxxs lg:text-xs md:text-xs nobar">{publicKey ? publicKey : getSelectedKeys().public}</p>
            <FontAwesomeIcon icon={faCopy} />
          </button>
        </div>
      </div>
      {/* {locked && (
      <button
        onClick={() => {
          history.push({
            pathname: "/walletDetailScreen",
            state: {
              screen: 2,
              coin: "xlm",
            },
          });
        }}
        className={styles.btnStyle}
        disabled={loading}
      >
        Deposit Now
      </button>
    )} */}
    </div>
  );
};

export const ActivationStatusCard2 = () => {
  let alert = useAlert();
  let history = useHistory();
  let copyToClip = () => {
    copy(getSelectedKeys().public);
    alert.removeAll();
    alert.show("Copied!", { type: "success" });
  };

  return (
    <div className="mt-8 xl:mt-0 xl:ml-2 border-dashed border-2 border-primary flex justify-between items-center p-3 xl:p-5 lg:p-5">
      <div className="flex items-center">
        <img src={images.lockIcon} className="h-6 w-4 xl:h-10 xl:w-8" />
        <div className="flex flex-col ml-3 mr-4">
          <button
            onClick={copyToClip}
            className="focus:outline-none bg-primary text-gray-600 rounded p-2 text-xs"
          >
            <p className="mr-1 text-xxxs xl:text-xs lg:text-xs md:text-xs">{getSelectedKeys().public}</p>
          </button>
        </div>
      </div>
      <button
        onClick={copyToClip}
        className={styles.btnStyle}
      >
        Copy
      </button>
    </div>
  );
};

const styles = {
  btnStyle:
    "flex items-center bg-pink text-white py-1 px-2 md:py-1 md:px-4 lg:py-1 lg:px-4 xl:py-2 xl:px-4 whitespace-no-wrap focus:outline-none text-xxxs xl:text-xs lg:text-xs md:text-xs",
};
