import React, { useEffect } from "react";
import { twitterIcon } from "assets/images";
import { DataCard } from "components/organisms/markets/DataCard";
import Footer from "components/molecular/footer";
import { useAlert, positions } from "react-alert";
import { useDispatch } from "react-redux";
import { logoutUserAction } from "store/actions/AuthActions";
import { homePageMarketData } from "utils/constants";
import { NavLink } from "react-router-dom";
import OfferPopup from "./OfferPopup";
function LandingPage() {
  let alert = useAlert()
  let dispatch = useDispatch()

  useEffect(()=>{
    dispatch(logoutUserAction())
  },[])

  return (
    <div>
      <div className={style.banner} style={{backgroundImage: 'url(assets/Header@2x.jpg)', backgroundSize: "cover", backgroundRepeat: 'no-repeat', height: "400px"}}>
        <div className="absolute top-0 right-0 left-0 bottom-0 pl-6 lg:pl-20 md:pl-20 xl:pl-20">
          <h1 className="text-white text-4xl font-semibold mb-6 mt-20">
            Best Digital Currency Experience at a Glance
          </h1>
          <p className="text-secondaryGray mb-10 text-lg">
            The Complete Digital Wealth Solutions. One Place, Endless Services.
          </p>
          <div>
            <NavLink to="/login" className="focus:outline-none bg-green py-2 px-12 text-white rounded ml-3">
              Explore
            </NavLink>
          </div>
        </div>
      </div>
      <div className="xl:flex px-6 xl:px-20 md:px-20">
        <div className="flex-1">
          <div style={style.bottomHeading}>
            <h1 className="text-white text-2xl font-semibold mb-2">
              G Wallet Core Features
            </h1>
            <p className="text-secondaryGray mb-8 text-lg">
            It's not magic, it's GWallet privileges.
            </p>
          </div>
          <div className="flex flex-col">
            <div className="xl:flex lg:flex">
              <FeatureCard
                title="SECURE"
                description="The decentralized niche leads to
                 ultimate trust and freedom in managing your own digital wealth."
                imgUrl="assets/shield.png"
              />
              <FeatureCard
                title="MULTIPLE CURRENCIES"
                description="Stellar Protocol offers borderless currencies via GWallet customised anchors."
                imgUrl="assets/coins.png"
              />
            </div>
            <div className="xl:flex lg:flex">
              <FeatureCard
                title="STABLE COINS"
                description="Stellar makes it possible to create, send, and trade digital representations of all forms of money."
                imgUrl="assets/graph.png"
              />
              <FeatureCard
                title="SWAP ENGINE"
                description="Our dedicated AI feature known as GTracker to find best deal in SDEX."
                imgUrl="assets/exchange.png"
              />
            </div>
            <div className="xl:flex lg:flex">
              <FeatureCard
                title="P2P EXCHANGE"
                description="Your secured P2P escrow services for crypto to fiat exchange."
                imgUrl="assets/meeting.png"
              />
              <FeatureCard
                title="ZAKAT FEATURE"
                description='An option to whom are into the passion of contributing. “The More You Give, The More You Get.”'
                imgUrl="assets/Zakat@3x.png"
              />
            </div>
          </div>
        </div>
        <div className="xl:ml-10 xl:w-1/3">
          <div style={style.bottomHeading}>
            <h1 className="text-white text-2xl font-semibold mb-2">
              On Your Favorite Platform
            </h1>
            <p className="text-secondaryGray mb-8 text-lg">
              We are ushering self managed digital wealth via a DECENTRALIZED platform.
            </p>
          </div>
          <div className="sm:flex md:flex lg:flex xl:flex xl:flex-col justify-around">
            <div className="bg-darkBrown w-full py-16 flex m-2 flex-col items-center mt-4">
            <p className="text-secondaryGray text-lg mb-4">
                Available On Playstore
              </p>
              <a href="https://play.google.com/store/apps/details?id=gwallet.tech" target="_blank" className="mb-8">
              <img src="assets/Playstore.png" className="h-16" />
              </a>
            </div>
            <div className="bg-darkBrown w-full py-16 flex flex-col m-2 items-center mt-4 xl:mt-6">
              <p className="text-secondaryGray text-lg mb-4">
                Available On Appstore
              </p>
              <a href="https://apps.apple.com/be/app/gwallet-app/id1552665993" target="_blank" className="mb-8">
              <img src="assets/Apple.png" className="h-16" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center px-20">
          <h2 className="text-white text-2xl font-semibold">Recent Trends</h2>
          <DataCard  />
      </div>
      <div className="my-12" />
      <Footer marginTop="30px" />
      <OfferPopup />
    </div>
  );
}

export default LandingPage;

function FeatureCard({ title, description, imgUrl }) {
  return (
    <div className="bg-primary p-4 xl:p-8 md:p-6 lg:p-8 sm:p-4 xs:p-4 flex-1 flex m-4">
      <div>
        <img src={imgUrl} className="" />
      </div>
      <div className="flex-1 ml-8">
        <h3 className="text-white text-lg font-semibold mb-1">{title}</h3>
        <p className="text-secondaryGray">{description}</p>
      </div>
    </div>
  );
}

let style = {
  banner: "relative",
  bannerCss: { height: "350px" },
  bottomHeading: { marginTop: "130px" },
};
