import React, { useState, useEffect } from "react";
import { postP2pBank, fetchP2pBank } from "services/p2pServives";
import { useAlert } from "react-alert";
function useP2pBank() {
  const alert = useAlert();
  const [state, setState] = useState({
    IBAN: "",
    bankName: "",
    accountNumber: "",
    accountTitle: "",
    IBANVal: "",
    accountNumberVal: "",
    accountTitleVal: "",
    bankNameVal: "",
    submitting: false,
  });
  const bankResMsg = {
    "IBAN required.": "Bank account number is required",
  }

  const submit = () => {
    if (!validated()) return;
    const { IBAN, accountNumber, accountTitle, bankName } = state;

    setState((prev) => ({ ...prev, submitting: true }));
    postP2pBank({
      IBAN,
      accountNumber,
      accountTitle,
      bankName,
    })
      .then((data) => {
        alert.show("Information Saved.", { type: "success" });
      })
      .catch((e) => {
        // console.log(e.response);
        alert.show(e.response?.data?.Error||"Unable to save information.", { type: "error" });
      })
      .finally(() => {
        setState((prev) => ({ ...prev, submitting: false }));
      });
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const loadInfo = () => {
    fetchP2pBank()
      .then((data) => {
        setState((prev) => ({
          ...prev,
          IBAN: data.data.IBAN||"",
          accountNumber: data.data.accountNumber||"",
          accountTitle: data.data.accountTitle||"",
          bankName: data.data.bankName||""
        }));
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  const validated = () => {
    let response = true;
    if (state.bankName === "") {
      setState((prev) => ({ ...prev, bankNameVal: "This field is required" }));
      response = false;
    }
    // if (state.IBAN === "") {
    //   setState((prev) => ({ ...prev, IBANVal: "This field is required" }));
    //   response = false;
    // }
    if (state.accountNumber === "") {
      setState((prev) => ({
        ...prev,
        accountNumberVal: "This field is required",
      }));
      response = false;
    }
    if (state.accountTitle === "") {
      setState((prev) => ({
        ...prev,
        accountTitleVal: "This field is required",
      }));
      response = false;
    }
    return response;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prev) => ({ ...prev, [name]: value, [name + "Val"]: "" }));
  };

  return { ...state, submit, handleInputChange };
}

export default useP2pBank;
