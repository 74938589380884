export const eyeIcon =  require('../images/eye_icon.png');
export const logo = require('../images/logo.png');
export const profitGraphCard = require('../images/profit_graph_card.png');
export const lossGraphCard = require('../images/loss_graph_card.png');
export const favoriteIcon = require('../images/favorite_icon.png');
export const notFavIcon = require('../images/not_fav_icon.png');
export const btcIcon= require('../images/btc_icon.png');
export const crdIcon = require('../images/crd_icon.png');
export const ethIcon = require('../images/eth_icon.png');
export const levelgIcon = require('../images/level_g_icon.png');
export const ltcIcon = require('../images/ltc_icon.png');
export const sendArrowIcon = require('../images/send_arrow_icon.png');
export const receiveArrowIcon = require('../images/receive_arrow_icon.png');