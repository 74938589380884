import * as React from 'react';
import { colors } from 'styles';
import Timer from 'components/atomic/Timer';
import { tradeStatusEnum } from 'utils/AppConstants';
import { checkedIcon, clockIcon } from 'assets/images/index';
import { getSelectedKeys } from 'services/trannsactionServices';
import ReactTooltip from 'react-tooltip';

export const StatusCol = ({ status, startTime, timeFrame, tradeAccount, type }) => {
    const getExpired = () => {
        const difference = new Date().getTime()/(1000*60) - new Date(startTime).getTime()/(1000*60)
        if(difference === NaN){
            return false
        }
        if(timeFrame === null){
            return false
        }
        return (difference > 15)
    }
    const [expired, setExpired] = React.useState(getExpired())

    const paraStyle = 'text-white text-xs md:text-sm lg:text-sm xl:text-sm font-regular'

    const renderImage = () => {
        if(status === tradeStatusEnum.pending_user_transfer && tradeAccount === getSelectedKeys().public){
            return <img src = {clockIcon} className="md:ml-0 ml-5 w-3 md:w-5 mr-2"/>
        }
        if(status === tradeStatusEnum.Completed){
            return <img src = {checkedIcon} className="md:ml-0 ml-5 w-3 md:w-5 mr-2"/>
        }
        if(status === tradeStatusEnum.Started){
            return <img src = {clockIcon} className="md:ml-0 ml-5 w-3 md:w-5 mr-2"/>
        }
        if(status === tradeStatusEnum.FundsSent){
            return <img src = {clockIcon} className="md:ml-0 ml-5 w-3 md:w-5 mr-2"/>
        }
        if(status === tradeStatusEnum.Cancelled_With_Holded_Funds){
            return <img src ="assets/cancelled.svg" data-tip="This trade is cancelled funds will be sent back to the seller 24 hours after cancelling the trade" className="md:ml-0 ml-5 w-3 md:w-5 mr-2"/>
        }
        if(status === tradeStatusEnum.Cancelled){
            return <img src ="assets/cancelled.svg" data-tip="This trade is cancelled" className="md:ml-0 ml-5 w-3 md:w-5 mr-2"/>
        }
        if(status === tradeStatusEnum.Disputed){
            return <img src ="assets/exclaim.svg" className="md:ml-0 ml-5 w-3 md:w-5 mr-2"/>
        }
    }

    const renderText = () => {
        const me = getSelectedKeys().public
        if(status === tradeStatusEnum.pending_user_transfer && type === 'sell'){
            return <p style={{color:colors.accent}} className={paraStyle+" cursor-not-allowed"} data-tip="Waiting for seller to hold the balance in escrow.">New trade</p>
        }
        if(status === tradeStatusEnum.pending_user_transfer && tradeAccount !== me){
            return <div className="bg-accent rounded-sm cursor-pointer h-6 w-full flex justify-center items-center text-xxs sm:text-xs text-white">New trade</div>
        }
        if(status === tradeStatusEnum.pending_user_transfer && tradeAccount === me){
            return <p style={{color:colors.accent}} className={paraStyle}>Waiting</p>
        }
        if(status === tradeStatusEnum.Completed){
            return <p style={{color: colors.lightGreen}} className={paraStyle}>Successful</p>
        }
        if(status === tradeStatusEnum.Cancelled_With_Holded_Funds){
            return <p style={{color: colors.accent}} data-tip="This trade is cancelled funds will be sent back to the seller 24 hours after cancelling the trade" className={paraStyle}>Cancelled</p>
        }
        if(status === tradeStatusEnum.Cancelled){
            return <p style={{color: colors.red}} data-tip="This trade is cancelled" className={paraStyle}>Cancelled</p>
        }
        if(status === tradeStatusEnum.Disputed){
            return <p style={{color: colors.accent}} className={paraStyle}>Disputed</p>
        }
        if(getExpired()){
            return <p style={{color: colors.red}} className={paraStyle}>Expired</p>
        }
        
        return <p style={{color:colors.white}} className={paraStyle}>{<Timer startTime={startTime} onExpired={()=>setExpired(true)} timeFrame={timeFrame} />}</p>
    }

    return (
        <div className="md:flex items-center w-20 ml-6 md:ml-0 md:mr-4">
        {renderImage()}
        {renderText()}
        <ReactTooltip />
       </div>
    );
}