import { marketReducer } from '../reducers/MarketReducer'
import { authReducer } from '../reducers/AuthReducer'
import { combineReducers } from 'redux'
import { transacionsReducer } from './TransactionReducer'
import { assetsReducer } from './AssetsReduces'
import { genericReducer } from './GenericReducer'
import { profileReducer } from './ProfileReducer'
import { exchangeReducer } from './exchangeReducer'
import { p2pReducer } from './p2pReducer'

const rootReducer = combineReducers({
  market: marketReducer,
  auth: authReducer,
  transaction: transacionsReducer,
  assets: assetsReducer,
  generic: genericReducer,
  profile: profileReducer,
  exchange: exchangeReducer,
  p2p: p2pReducer
})
export { rootReducer }
export type RootState = ReturnType<typeof rootReducer>