import React from "react";
import { Button } from "components";
import { resetPassword } from "services/authServces";
import { useHistory } from "react-router-dom";

function ResetPassword() {
  let history = useHistory();
  let handleReset = () => {
    resetPassword();
    history.push({ pathname: "/register", state: { reset: true } });
  };
  let handleCancle = () => history.goBack();
  return (
    <div className="flex flex-col items-center text-gray-500 pt-20">
      <div>
        <h2 className="text-yellow-600 text-2xl mb-2">
          Caution: RESET PASSWORD
        </h2>
        <p className="mb-2">
          By resetting your password, you agree to reboot your account/s
          <br /> on this device.
        </p>
        <p className="mb-2">
          You will not lose your private wallet/s as long as you have your
          SECRET KEY.
        </p>
        <p>Kindly import your secret key again after resetting this session.</p>
        <div className="mt-4">
          <button
            onClick={handleReset}
            className="bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-4 px-12 rounded mr-4"
          >
            Reset
          </button>
          <button
            onClick={handleCancle}
            className="border border-gray-700 hover:border-gray-500 text-white font-semibold py-4 px-12 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
