import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { closeButton } from "assets/images/index";
import { Button } from "components";
import { useAlert } from "react-alert";
import { coinsFromShort } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { UpdateCustomAssets } from "../../../store/actions/AssetsActions";
// import { issueAsset } from "services/AssetServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCoinMarketPrice, issueAssets } from "../../../services/exchange";
import {
  getBtcBalance,
  getUsdBalance,
  readToml,
} from "../../../services/AssetServices";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { getBalances, getSelectedKeys } from "services/trannsactionServices";
import Axios from "axios";
import { getWalletById } from "services/dbServices";
import { setCoins } from "store/actions/TransactionActions";
import { stringify } from "querystring";
const Icon = require("../../../assets/images/custom_asset_logo.png");

function AddAssetModal({ isOpen, onClose, onSuccess, type }) {
  let [adable, setAdable] = useState();
  let [selected, setSelected] = useState(0);
  let [assetName, setAssetName] = useState("");
  let [assetIssuer, setAssetIssuer] = useState("");
  let [loading, setLoading] = useState(false);
  let alert = useAlert();
  let { transaction, assets, market } = useSelector(
    (state: RootState) => state
  );
  let dispatch = useDispatch();

  let addAsset = (item) => {
    if (selected === 0) return;
    let name = item.short.toUpperCase();
    let res = transaction.coins.filter(
      (itemm) => itemm.short === name && itemm.issuer == item.issuer
    );
    if (res.length > 0) {
      alert.removeAll();
      alert.show("Assets issued already!");
      return;
    }
    setAdable(item);
    onSuccess(item, type);
  };

  let selectAsset = (item) => {
    onSuccess(item, type);
  };

  let onOpen = () => {};

  let getDomain = (url: string) => {
    let temp = url.split("/");
    if (temp[2] && temp[2].includes("www")) {
      temp[2] = temp[2].substring(4, temp[2].length);
    }
    return temp[2];
  };

  let renderBtn = () => {
    return (
      <div>
        <FontAwesomeIcon icon={faSyncAlt} spin /> Please Wait...
      </div>
    );
  };

  let manualAssetIssue = async () => {
    if (assetName.length < 3 || assetIssuer.length != 56) {
      alert.error("Provide correct information");
      setLoading(false);
    }
    // let currency: any = localStorage.getItem("prefrences");
    try {
      let tomlData = await readToml(assetIssuer, alert);

      let tomlAsset = tomlData.CURRENCIES?.find(
        (item) => item.code == assetName
      );

      const xlm = assets.assets.find(
        (item) => item.short.toLowerCase() == "xlm"
      );
      /////getting data against particualr coin
      let data: any = null;
      const rates: any = await getCoinMarketPrice(xlm, {
        code: assetName,
        issuer: assetIssuer,
      });

      // ////getting currency here
      // currency = currency && JSON.parse(currency);
      // currency = currency?.CURRENCY;

      let obj = {
        image: tomlAsset && tomlAsset?.image ? tomlAsset?.image : Icon,
        btcRate: rates?.btcRate,
        code: tomlAsset ? tomlAsset?.code : assetName.toUpperCase(),
        currency: "USD",
        currentRate: rates?.currencyRate,
        desc: tomlAsset ? tomlAsset?.desc : data?.data?.short_desc,
        displayColor: "#f2a900",
        displayDecimals: tomlAsset?.display_decimals,
        distributionPublic: null,
        high24h: rates?.highConverted,
        homeDomainURL: null,
        infoLink: null,
        isAssetAnchored: 0,
        isErc20: 0,
        isGWalletAnchor: null,
        isTrc20: 0,
        issuer: assetIssuer,
        low24h: rates?.lowConverted,
        marketCap: "0",
        name: tomlAsset ? tomlAsset?.name : assetName.toUpperCase(),
        organization: tomlData?.ORG_NAME
          ? tomlData?.ORG_NAME
          : tomlData?.DOCUMENTATION?.ORG_NAME || "-",
        rateChangeAmount: Number(rates?.change.toFixed(5)),
        rateChangePercent: Number(rates?.changePercent.toFixed(5)),
        short: assetName.toLowerCase(),
        totalVolume: rates?.volume,
        manualIssued: true,
      };
      dispatch(UpdateCustomAssets(obj));
      addAsset(obj);
      setLoading(false);
    } catch (e) {
      alert.error("Asset cannot be issued try again later");
      setLoading(false);
    }
  };

  const sdexAssetRefresh = async () => {
    let Asset: any = [];
    let rates: any = null;
    let newAssets: any = [];

    const xlm = assets.assets.find((item) => item.short.toLowerCase() == "xlm");
    /////getting data against particualr coin

    let data: any = localStorage.getItem("sdex_assets");
    if (data) {
      data = JSON.parse(data);
      for (let i = 0; i < data.length; i++) {
        try {
          rates = await getCoinMarketPrice(xlm, {
            code: data[i].code,
            issuer: data[i].issuer,
          });
          if (rates) {
            Asset = data.find(
              (item) =>
                item.code.toLowerCase() == data[i].code.toLowerCase() &&
                item.issuer == data[i].issuer
            );
            Asset.btcRate = rates?.btcRate;
            Asset.currentRate = rates?.currencyRate;
            Asset.high24h = rates?.highConverted;
            Asset.low24h = rates?.lowConverted;
            Asset.rateChangeAmount = Number(rates?.change.toFixed(5));
            Asset.rateChangePercent = Number(rates?.changePercent.toFixed(5));
            Asset.totalVolume = rates?.volume;
            Asset.isAssetAnchored = 0;
            newAssets.push(Asset);
          } else {
            newAssets.push(data[i]);
          }
        } catch (error) {
          return;
        }
      }
      localStorage.setItem("sdex_assets", JSON.stringify(newAssets));
    }
  };

  useEffect(() => {
    setTimeout(sdexAssetRefresh, 30000);
  }, []);

  let renderGrid = () => {
    let data = assets.assets;

    if (selected === 0 && data[0]) {
      let myAssets: any = [];
      transaction.coins.forEach((item) => {
        let asset: any = data.filter(
          (item2) =>
            item2.short?.toUpperCase() === item.short?.toUpperCase() &&
            item2.issuer == item.issuer
        );
        myAssets.push({ ...item, organization: asset[0]?.organization });
      });

      data = myAssets;
    }
    if (selected === 1) {
      // remove anchor coins from add token modal
      const ANCHOR_COINS = ['BTC', 'LTC', 'DOGE', 'DASH', 'ETH', 'TRX', 'KISHU', 'SHIB', 'SHIBA' , 'USDT', 'BOTH', 'AQUA'];
      data = data.filter((el) => !ANCHOR_COINS.includes(el.code));
      data = data.reduce((acc: any, user) => {
        if (!acc.find(u => u.short.toLowerCase() === user.short.toLowerCase())) {
            acc.push(user);
        }
        return acc;
    }, [])
      // debugger;
      // const uniqueAddresses: any = Array.from(new Set(data.map((a) => a.short)))
      //   .map((short) => {
      //     // debugger;
      //     return data.find((a) => a.short.toLowerCase() == short.toLowerCase());
      //   })
      //   .filter((val: any) => val?.manualIssued != true);
      // data = uniqueAddresses;
      // comment due to hide anchor coins
      // data = data.filter((val: any) => val?.manualIssued != true);
    }
    if (selected === 2) {
      return (
        <div style={popupWidth}>
          <div
            style={{ width: window.innerWidth > 456 ? "300px" : "126px" }}
            className="flex flex-col border-b border-teal-500 py-2"
          >
            <p className="text-white text-sm">Asset Code</p>
            <input
              className="placeholder:text-gray-500 appearance-none bg-transparent border-none w-full text-gray-500 mr-3 py-1 focus:outline-none"
              type="text"
              placeholder="e.g. BTC"
              onChange={(e) => setAssetName(e.target.value)}
            />
          </div>
          <div
            style={{ width: window.innerWidth > 456 ? "300px" : "126px" }}
            className="flex flex-col border-b border-teal-500 py-2 mt-5"
          >
            <p className="text-white text-sm">Asset Issuer</p>
            <input
              className="placeholder:text-gray-500 appearance-none bg-transparent border-none w-full text-gray-500 mr-3 py-1 focus:outline-none"
              type="text"
              maxLength={56}
              placeholder="Enter Public Key Here"
              onChange={(e) => setAssetIssuer(e.target.value)}
            />
          </div>
          <Button
            onClick={() => {
              setLoading(true);
              manualAssetIssue();
            }}
            type="submit"
            styles={["bg-accent text-sm py-4 mt-8 w-full uppercase"]}
          >
            {loading ? renderBtn() : "Add Asset"}
          </Button>
        </div>
      );
    }
    return data.map((item: any, index) => {
      let short = item.short.toUpperCase();
      return (
        <div key={index} style={data.length == 1 ? popupWidth : {}}>
          <div
            onClick={() => {
              type === "none" ? addAsset(item) : selectAsset(item);
            }}
            style={{ width: window.innerWidth > 456 ? "170px" : "126px" }}
            className="border cursor-pointer border-gray-500 py-6 sm:py-10 flex flex-col items-center rounded my-2 mx-2 sm:mx-1 hover:bg-primaryLight hover:border-primaryLight relative"
          >
            <img
              src={
                item?.coinIcon
                  ? item?.coinIcon
                  : coinsFromShort[short]?.icon || Icon
              }
              className="w-10 h-10 sm:w-12 sm:h-12"
            />
            <h3 className="text-white text-lg mt-4">{short}</h3>
            <p className="text-white text-xs mt-2">
              {short === "XLM" ? "Native" : item.organization?.substring(0, 17)}
            </p>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        contentLabel="Minimal Modal Example"
        style={customStyle}
        onAfterOpen={onOpen}
      >
        <button onClick={onClose} className="float-right">
          <img src={closeButton} className="w-6 h-6" />
        </button>
        <div className="flex flex-col items-center">
          <h2 className="mx-auto text-lg text-white font-semibold mt-8">
            Select Assets
          </h2>
          <div className="flex w-full border-b border-gray-600 mt-8">
            <button
              onClick={() => {
                setSelected(0);
                onOpen();
              }}
              className={`px-6 py-4 focus:outline-none ${
                selected == 0
                  ? "border-b-2 border-yellow-600 text-white"
                  : "text-gray-500"
              }`}
            >
              <h3>My Assets</h3>
            </button>
            <button
              onClick={() => {
                setSelected(1);
                onOpen();
              }}
              className={`px-6 py-4 focus:outline-none ${
                selected == 1
                  ? "border-b-2 border-yellow-600 text-white"
                  : "text-gray-500"
              }`}
            >
              <h3>Our Picks</h3>
            </button>
            <button
              onClick={() => {
                setSelected(2);
                onOpen();
              }}
              className={`px-6 py-4 focus:outline-none ${
                selected == 2
                  ? "border-b-2 border-yellow-600 text-white"
                  : "text-gray-500"
              }`}
            >
              <h3>Manual</h3>
            </button>
          </div>
          <div
            className="flex flex-wrap mt-4 customBar"
            style={{ height: "60vh", overflow: "auto" }}
          >
            {renderGrid()}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddAssetModal;
let width = window.innerWidth;
const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    display: "flex",
    justifyContent: "center",
    zIndex: 100,
    alignItems: "center",
  },
  content: {
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    marginLeft: "20px",
    marginRight: "20px",
    maxWidth: "790px",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};

let popupWidth = { marginLeft: "15vw", marginRight: "15vw" };
