import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

function CustomPicker2({ label, left, startDate, setStartDate, validation }) {
  return (
    <div className="flex-1" style={{ marginTop: "2px" }}>
      <p className="text-secondaryGray mb-1 font-regular text-xs">{label}</p>

      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        customInput={<ExampleCustomInput />}
        wrapperClassName="w-full"
        // scrollableYearDropdown={true}
        showYearDropdown
      yearDropdownItemNumber={40}
      scrollableYearDropdown
      />

      <p className="text-red text-sm italic">{validation}</p>
    </div>
  );
}

export default CustomPicker2;

const ExampleCustomInput = (props: any) => (
  <button className="flex bg-primary px-2 py-2 w-full bg-transparent flex justify-between items-center focus:outline-none text-secondaryGray text-base"
      type="button"
      onClick={props.onClick}
  >
    <p>
      {props.value}
    </p>
    <FontAwesomeIcon icon={faCalendar} color="#ffffff" />
  </button>
);
