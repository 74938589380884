import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
} from "@fortawesome/free-solid-svg-icons";
import { progressIcon, closeButton, fileIcon } from "assets/images";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "styles/inputFile.css";
import Loader from "react-loader-spinner";
import { uploadFile, getJWT } from "services/httpServices";

function Upload({ text, label, onSuccess, id, validation }) {
  let [upload, setUpload] = useState({
    name: "Document.png",
    file: "",
    uploading: false,
    visible: false,
    localVal: ""
  });

  let selectFile = () => {
    let input = document.getElementById(id);
    input?.click();
  };

  let uploadData = (token, data) => {
    uploadFile(data, token)
      .then((data: any) => {
        setUpload((prev) => ({ ...prev, uploading: false }));
        onSuccess(data.data.filename);
      })
      .catch((e) => {
        setUpload((prev) => ({ ...prev, uploading: false }));
      });
  };

  let handleChange = (e) => {
    let { files } = e.target;
    if(!files[0]) return
    if(files[0].size > 1000000){
      setUpload(prev=>({...prev, localVal: "File size can't be more than 1 MB"}))
      return
    }
    setUpload(prev=>({
      ...prev,
      file: files[0],
      name: files[0].name,
      visible: true,
      uploading: true,
      localVal: ""
    }));
    getJWT()
      .then((token) => {
        uploadData(token.data.token, files[0]);
      })
      .catch((e) => {

      });
  };

  return (
    <div>
      <p className="text-xs ml-2 my-2 font-regular text-gray-400">{label}</p>
      <input
        type="file"
        name="file"
        onChange={handleChange}
        id={id}
        className="inputfile"
        accept=".pdf, .jpg, .jpeg, .png, .DOC, DOCX"
      />

      <button
        type="button"
        className="focus:outline-none h-10 w-full border border-primary flex items-center"
        onClick={selectFile}
      >
        <div className="bg-primary px-3 h-full flex items-center">
          <FontAwesomeIcon icon={faCloudUploadAlt} color="#ffffff" />
        </div>
        {!upload.visible && <p className="text-xs text-secondaryGray ml-2">{text}</p>}

        {upload.visible && (
        <div className="flex mb-2 items-center p-1">
          {upload.uploading && (
            <Loader type="Oval" color="#00BFFF" height={14} width={14} />
          )}
          {!upload.uploading && <img src={fileIcon} className="h-4 w-4" />}
          <p className="text-xs ml-2 font-regular text-white">{upload.name}</p>
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation()
              setUpload((prev) => ({ ...prev, visible: false }))
            }}
          >
            <img src={closeButton} className="ml-2 w-3 h-3" />
          </button>
        </div>
      )}
      </button>

      <p className="text-xs text-red italics">{upload.localVal===""?validation:upload.localVal}</p>
    </div>
  );
}

export default Upload;
