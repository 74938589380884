import { TableHeadingRow } from "components/molecular/P2P/TableHeadingRow";
import moment from "moment";
import React, { Fragment, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSelectedKeys } from "services/trannsactionServices";
import { fetchActiveTradesAction, fetchTradesAction } from "store/actions/P2pActions";
import { RootState } from "store/reducers";
import { tradeStatusEnum } from "utils/AppConstants";
import { TransactionsTableRow } from "./TransactionsTableRow";
import Loader from "react-loader-spinner";
import TradesTabs from "components/atomic/p2p/TradesTab";

function Trades() {
  const {
    coins,
    active,
    activeTrades,
    loadingActiveTrades,
    activeTradesPagination,
  } = useSelector((state: RootState) => state.p2p);
  const dispatch = useDispatch();
  const history = useHistory();
  const { trades, tradePagination, name, loadingTrades } = coins[active];
  const [tabIndex, setTabIndex] = useState(0);

  const activeTradesUnique = useMemo(() => {
    const unique = activeTrades.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
    let newTrades:any = []
    unique.forEach(item => {
      if (
        item.status === tradeStatusEnum.pending_user_transfer &&
        item.type === "sell"
      ){
        return
      }
      newTrades.push(item)
    });
    return newTrades
  }, [activeTrades])

  const detailScreen = (item) => {
    if (
      item.status === tradeStatusEnum.Cancelled ||
      item.status === tradeStatusEnum.Cancelled_With_Holded_Funds
    ) {
      return;
    }
    if (
      item.status === tradeStatusEnum.pending_user_transfer &&
      item.type === "sell"
    ) {
      return;
    }
    history.push({
      pathname: "/transactionProgressScreen",
      state: { trade: item },
    });
  };
  const onProfile = (e, tradeAccount, addAccount) => {
    e.stopPropagation();
    const account =
      tradeAccount === getSelectedKeys().public ? addAccount : tradeAccount;
    history.push({ pathname: "/p2pProfile", state: { account: account } });
  };
  const renderLoading = () => (
    <div className="flex justify-center items-center my-4 mb-10 h-full">
      <Loader type="Bars" color="#FFFF00" height={30} width={30} />
    </div>
  );
  const isBuyer = (type, tradeAccount, addAccount) =>
    (type === "buy" ? tradeAccount : addAccount) === getSelectedKeys().public
      ? true
      : false;

  const checkDisputeType = (
    status,
    p2pdispute,
    addType,
    tradeAccount,
    addAccount
  ) => {
    let accountIdSeller = addType == "sell" ? addAccount : tradeAccount;
    let accountIdBuyer = addType == "sell" ? tradeAccount : addAccount;
    if (status === tradeStatusEnum.Disputed) {
      if (p2pdispute && p2pdispute.status === "Resolved") {
        if (p2pdispute.winByAccountId === accountIdBuyer) {
          return tradeStatusEnum.Completed;
        }
        if (p2pdispute.winByAccountId === accountIdSeller) {
          return tradeStatusEnum.Cancelled;
        }
      }
    }
    return status;
  };

  const renderRows = () => {
    const iterable = tabIndex === 0 ? activeTradesUnique: trades;
    
    return iterable.map((item) => {
      // if(item.status === tradeStatusEnum.pending_user_transfer && item.type === 'sell'){
      //   return <div/>
      // }
      return (
        <TransactionsTableRow
          type={item.type}
          date={moment(item.updatedAt).format("LL")}
          status={checkDisputeType(
            item.status,
            item.p2pdispute,
            item.p2pads.type,
            item.account,
            item.p2pads.account
          )}
          time={item.startTime ? moment(item.startTime).format("H:mm A") : ""}
          cryptoAmountToBuyer={item.cryptoAmountToBuyer}
          cryptoAmountFromSeller={item.cryptoAmountFromSeller}
          currencyType={item.p2pads?.assetCode?.toUpperCase()}
          tradeAccount={item.account}
          tradeKyc={item.kyc}
          addKyc={item.p2pads.kyc}
          add={item.p2pads}
          startTime={item.startTime}
          timeFrame={item.p2pads.timeFrame}
          onClick={() => detailScreen(item)}
          onProfile={(e) => onProfile(e, item.account, item.p2pads.account)}
          isBuyer={isBuyer(item.type, item.account, item.p2pads?.account)}
          p2previews={item.p2previews}
          sellerRatings={item.sellerRatings}
          buyerRatings={item.buyerRatings}
          fiatTradeAmount={item.fiatTradeAmount}
        />
      );
    });
  };
  const handleNextPage = () => {
    const { current, totalPages } = tradePagination;
    //if(current < totalPages){
    dispatch(fetchTradesAction(name, current + 1));
    //}
  };
  const handleActiveNextPage = () => {
    const { current, totalPages } = activeTradesPagination;
    //if(current < totalPages){
    dispatch(fetchActiveTradesAction(current + 1));
    //}
  };

  return (
    <Fragment>
      <TradesTabs index={tabIndex} onSelect={setTabIndex} name={name} />
      <div className="border-primary border ">
        <TableHeadingRow />
        {tabIndex === 1 && (
          <Fragment>
            {renderRows()}
            {loadingTrades && renderLoading()}
            {!loadingTrades && trades.length > 0 && (
              <div className="flex justify-center">
                <button
                  onClick={handleNextPage}
                  className="focus:outline-none border border-secondaryGray p-2 text-secondaryGray my-4"
                >
                  Load More
                </button>
              </div>
            )}
          </Fragment>
        )}
        {tabIndex === 0 && (
          <Fragment>
            {renderRows()}
            {loadingActiveTrades && renderLoading()}
            {!loadingActiveTrades && activeTradesUnique.length > 0 && (
              <div className="flex justify-center">
                <button
                  onClick={handleActiveNextPage}
                  className="focus:outline-none border border-secondaryGray p-2 text-secondaryGray my-4"
                >
                  Load More
                </button>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default Trades;
