import React from "react";
import { logo } from "assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function Footer({ marginTop }) {
  return (
    <div
      className="bg-primary mt-6 px-20 py-6"
      style={{ marginTop: marginTop }}
    >
      <div className="sm:flex mb-12">
        <div className="w-full flex-col">
          <div className="flex items-center">
            <img className="w-6 h-8" src="assets/G Wallet Icon@2x.png" />
            <text className="text-white font-bold ml-5 md:text-md lg:text-md xl:text-md  ">
              G WALLET
            </text>
          </div>
          <div className="flex mt-6">
            <FontAwesomeIcon icon={faLocationArrow} color="#FFA500" />
            <p className="text-secondaryGray text-xs pr-16 ml-1">
              Harju County, Tallinn, City Center District, Maakri Street 25,
              10145
            </p>
          </div>
        </div>
        <div className="w-full">
          <h3 className="text-white font-semibold">Company</h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/support">Support</NavLink>
          </h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/faqs">FAQs</NavLink>
          </h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/fees">Fees</NavLink>
          </h3>
        </div>
        <div className="w-full">
          <h3 className="text-white font-semibold">Navigation</h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/market">Market</NavLink>
          </h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/exchange">Exchange</NavLink>
          </h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/p2p">P2P</NavLink>
          </h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/swap">Swap</NavLink>
          </h3>
        </div>
        <div className="w-full">
          <h3 className="text-white font-semibold">Policies</h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/terms-of-use">Term of usage</NavLink>
          </h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          </h3>
          <h3 className="text-sm text-secondaryGray py-2">
            <NavLink to="/disclaimer">Disclaimer</NavLink>
          </h3>
        </div>
      </div>
      <div className="border-b border-gray-800" />
      <p className="text-gray-600 text-xs my-6">
        GWallet. Copyrights All Rights Reserved.
      </p>
    </div>
  );
}

export default Footer;
