import * as React from "react";
import { profitGraphCard, lossGraphCard } from "assets/images/images";
import { colors } from "styles"

export const Card = (props: any) => {
  return (
    <div className={styles.container} onClick={props.onClick}>
      <text className={styles.topRow}>{props.cryptoCurrency} / {props.cryptoAlias}</text>
      <div className={styles.graphRow}>
        <text className= {styles.amountStyle} style={{color:props.profit?colors.green :colors.pink}}>
          {props.getFps(props.amountCrypto)} {/*<span className={styles.amountinCurrencyStyle}>/ {props.currency}{props.amountDollar}</span>*/}
        </text>
        {/* <img src={props.profit? profitGraphCard:lossGraphCard} className="w-1.5/6 h-2/6 mt-5 sm:h-20 w-14 md:h-25 w-20"/> */}
        <img src={props.profit? profitGraphCard:lossGraphCard} className="w-1/6 h-2/6"/>
      </div>
        <text className={styles.percentStyle} style={{color:props.profit? colors.green:colors.pink}}>
          {props.percent} %<span className={styles.volumeStyle}>Volume:{props.volume} {props.cryptoAlias}</span>
        </text>
    </div>
  );
};

const styles={
  container:"mt-5 mb-5 m-1 bg-primary px-3 py-2 w-full cursor-pointer",
  topRow:"text-gray4 font-regular text-xs sm:text-xs md:text-xs lg:text-xs xl:text-sm",
  graphRow:"flex justify-between items-center",
  amountStyle:"text-lg sm:text-md md:text-lg lg:text-2xl xl:text-2xl font-semibold",
  amountinCurrencyStyle:"text-secondaryGray text-xs sm:text-xs md:text-xs lg:text-sm xl:text-md font-regular",
  percentStyle:"text-xs sm:text-xs md:text-xs lg:text-sm xl:text-sm",
  volumeStyle:"text-xs sm:text-xs md:text-xs lg:text-sm xl:text-sm text-secondaryGray ml-3 font-regular"
}
