import { personImage2 } from "assets/images";
import React, { useEffect, useState } from "react";
import StarRatingComponent from "react-star-rating-component";
import { getP2pProfile } from "services/p2pServives";
import Loader from "react-loader-spinner";
import { profileStDetail } from "utils/constants";
import { countries } from "utils/countries";
import moment from "moment";
import { BASE_URL } from "utils/AppConstants";
import { personProfile } from "assets/images/index";

function P2pBasicUserInfo({ hasImage, account }) {
  const init: any = {};
  const [state, setState] = useState({ loading: true, data: {
    kycStatus: '',
    country_code: '',
    total_successfull_trade: '',
    partners: '',
    total_fail_trade: '',
    accountCreatedDate: '',
    lastSeen: '',
    photo: '',
    first_name: '',
    last_name: '',
    rating: 0,
    companyName: "",
    isCompanyKyc: false
  } });
  useEffect(() => {
    initialize();
  }, []);
  const initialize = () => {
    getP2pProfile(account)
      .then((data) => {
        console.log(data)
        setState((prev) => ({
          ...prev,
          loading: false,
          data: data.data?.profile? data.data?.profile:prev.data,
        }));
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, loading: false }));
      });
  };
  const renderLoading = () => (
    <div className="flex justify-center items-center w-full border border-primary py-8">
      <Loader type="Bars" color="#FFFF00" height={20} width={20} />
    </div>
  );
  const getCountry = (code) =>
    countries.find((country) => country.code === code);

  if (state.loading) return renderLoading();

  const {
    kycStatus,
    country_code,
    total_successfull_trade,
    partners,
    total_fail_trade,
    accountCreatedDate,
    lastSeen
  } = state.data;
  return (
    <div className="border border-primary w-full">
      {hasImage && (
        <div className="flex flex-col py-8 items-center w-full border-b border-primary">
          <img src={BASE_URL+"/files/"+state.data.photo} className="w-16 h-16 rounded-lg" />
          <h1 className="text-white">{state.data.isCompanyKyc?state.data.companyName:state.data.first_name+" "+state.data.last_name}</h1>
          <StarRatingComponent name="" value={state.data.rating} />
        </div>
      )}
      <div className="flex justify-between w-full p-3 text-xs border-b border-primary">
        <h4 className="text-gray4">KYC Status</h4>
        <h4 className="text-green">{profileStDetail[kycStatus]}</h4>
      </div>
      <div className="flex justify-between p-3 text-xs border-b border-primary">
        <h4 className="text-gray4">Country of Origin</h4>
        <h4 className="text-white">{getCountry(country_code)?.name}</h4>
      </div>
      <div className="flex justify-between p-3 text-xs border-b border-primary">
        <h4 className="text-gray4">Number of successful Trades</h4>
        <h4 className="text-white">{total_successfull_trade}</h4>
      </div>
      <div className="flex justify-between p-3 text-xs border-b border-primary">
        <h4 className="text-gray4">Number of different Partners</h4>
        <h4 className="text-white">{partners}</h4>
      </div>
      <div className="flex justify-between p-3 text-xs border-b border-primary">
        <h4 className="text-gray4">Number of Failed Trades</h4>
        <h4 className="text-white">{total_fail_trade}</h4>
      </div>
      <div className="flex justify-between p-3 text-xs border-b border-primary">
        <h4 className="text-gray4">Account Created</h4>
        <h4 className="text-white">{moment(accountCreatedDate).fromNow()}</h4>
      </div>
      <div className="flex justify-between p-3 text-xs">
        <h4 className="text-gray4">Last Seen</h4>
        <h4 className={`${lastSeen==='online'?'text-green':'text-white'}`}>{lastSeen==='online'?'online':moment(lastSeen).fromNow()}</h4>
      </div>
    </div>
  );
}

export default P2pBasicUserInfo;
