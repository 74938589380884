import React from "react";
import useConfirmPassword from "./hooks/useConfirmPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import { closeButton } from "assets/images";
import CustomInput from "components/global/CustomInput";
import { Button } from "components";

const ConfirmPassword = ({ isOpen, onClose, onSuccess }) => {
  const [type, setType] = React.useState("password");
    const {
      password,
      handleInputChange,
      handleSubmit,
      submitting,
      passwordValidation,
    } = useConfirmPassword(() => {
      onClose();
      onSuccess();
    });
  
    let renderBtn = () => {
      if (submitting) {
        return (
          <div>
            <FontAwesomeIcon icon={faSyncAlt} spin /> Please Wait...
          </div>
        );
      }
      return "Confirm";
    };
    return (
      <Modal isOpen={isOpen} style={customStyle} onAfterOpen={() => {}}>
        <div className="flex justify-between items-center border-b pb-3 pt-1 mb-10">
        <h3 className="text-white font-semibold">
            Confirm Password
          </h3>
        <button onClick={onClose} className="">
          <img src={closeButton} className="w-6 h-6" />
        </button>
          </div>
        <form onSubmit={handleSubmit}>
          
          <div className="w-full bg-background">
          <div className="flex">
            <input type={type} placeholder="Enter your password" value={password} onChange={handleInputChange} name="password" className="block bg-background appearance-none w-full font-regular text-xs text-gray4 py-4 px-3 focus:outline-none" />
            <FontAwesomeIcon
            icon={type == "password" ? faEye : faEyeSlash}
            onClick={() => setType(type == "password" ? "text" : "password")}
            color="#ffffff"
            className="h-4 w-6 mr-5 self-center"
          />
          </div>
          </div>
          <text className="mt-2 text-red">{passwordValidation}</text>
          <div className="flex justify-center">
            <Button
              type="submit"
              styles={["bg-accent text-sm py-3 mt-6 w-full mt-12 uppercase"]}
            >
              {renderBtn()}
            </Button>
          </div>
        </form>
      </Modal>
    );
  };
export default ConfirmPassword  
  const customStyle = {
    overlay: {
      backgroundColor: "#00000080",
      display: "flex",
      justifyContent: "center",
      zIndex: 100,
      alignItems: "center",
    },
    content: {
      top: "100px",
      left: "auto",
      right: "auto",
      bottom: "auto",
      margin: "auto",
      marginLeft: "20px",
      marginRight: "20px",
      maxWidth: "500px",
      width: "100%",
      padding: "10px",
      backgroundColor: "#232321",
      border: "0 px",
    },
  };