import React from "react";
import CustomInput from "components/global/CustomInput";
import { colors } from "styles";
import useP2pBank from "./hooks/useP2pBank";
import Loader from "react-loader-spinner";

function P2PBank() {
  const {
    IBAN,
    bankName,
    submit,
    IBANVal,
    submitting,
    accountTitle,
    bankNameVal,
    accountNumber,
    accountTitleVal,
    accountNumberVal,
    handleInputChange,
  } = useP2pBank();

  return (
    <div className="border border-primary p-5 w-full">
      <h1 className="text-white text-lg">P2P Bank Account</h1>
      <div className="flex">
        <div className="mr-2 w-full">
          <CustomInput
            labelColor={colors.secondaryGray}
            label="Bank Name"
            name="bankName"
            value={bankName}
            onChange={handleInputChange}
            valMsg={bankNameVal}
            marginVertical="0.30rem"
            padding={"12px"}
            placeholder="Enter Bank Name"
          />
        </div>
        <div className="ml-2 w-full">
          <CustomInput
            labelColor={colors.secondaryGray}
            label="Account Holder Name(No third party account is allowed)"
            name="accountTitle"
            value={accountTitle}
            onChange={handleInputChange}
            valMsg={accountTitleVal}
            marginVertical="0.30rem"
            padding={"12px"}
            placeholder="Enter Account Holder Name"
          />
        </div>
      </div>
      <div className="flex">
        <div className="mr-2 w-full">
          <CustomInput
            labelColor={colors.secondaryGray}
            label="Account Number"
            type="number"
            name="accountNumber"
            value={accountNumber}
            onChange={handleInputChange}
            valMsg={accountNumberVal}
            marginVertical="0.30rem"
            padding={"12px"}
            placeholder="Enter Account Number"
          />
        </div>
        <div className="ml-2 w-full">
          <CustomInput
            labelColor={colors.secondaryGray}
            label="IBAN(Optional)"
            name="IBAN"
            type='number'
            value={IBAN}
            onChange={handleInputChange}
            valMsg={IBANVal}
            marginVertical="0.30rem"
            padding={"12px"}
            placeholder="Your International bank account number"
          />
        </div>
      </div>
      <button
        onClick={submit}
        className="focus:outline-none text-white bg-accent py-2 px-5 text-xs rounded mt-4"
      >
        {!submitting && "Save Changes"}
        {submitting && (
          <Loader type="Bars" color="#ffffff" height={15} width={15} />
        )}
      </button>
    </div>
  );
}

export default P2PBank;
