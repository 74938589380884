import {
  ExchangeTypes,
  SET_SELECTED_COIN_PAIR,
  UPDATE_BALANCE,
  UPDATE_Market,
  SELECT_OFFER,
  UPDATE_AVAILABLE_BALANCE,
  REFRESH_MY_OFFERS,
  UPDATE_BOTH_BALANCES,
  SET_EXCHANGE_PRICES,
  RESET_SELECTED_PAIR,
  PERSIST_SELECTED_PAIR
} from "./ExchangeActionsTypes";
import {
  CoinPair,
  PairBalance,
  PairMarket,
  Offer,
  PairPersist,
} from "store/models/Exchange";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "store/reducers";
import { getPairPrice } from "services/genericServices";
import { selectPairActions } from "utils/constants";

export function setSelectedPair(
  pair: CoinPair,
  refresh: boolean
): ExchangeTypes {
  return {
    type: SET_SELECTED_COIN_PAIR,
    pair: pair,
    refresh: refresh,
  };
}

export function resetSelectedPair(): ExchangeTypes {
  return {
    type: RESET_SELECTED_PAIR,
  };
}

export function updateBalance(asset: string, balance: number): ExchangeTypes {
  return {
    type: UPDATE_BALANCE,
    asset: asset,
    balance: balance,
  };
}
export function setExchangePrices(
  buyPrice: number,
  sellPrice: number,
  bidsLength: number,
  asksLength: number
): ExchangeTypes {
  return {
    type: SET_EXCHANGE_PRICES,
    buyPrice,
    sellPrice,
    bidsLength,
    asksLength,
  };
}
export function updateBothBalancesAction(
  baseBalance: number,
  counterBalance: number
): ExchangeTypes {
  return {
    type: UPDATE_BOTH_BALANCES,
    baseBalance,
    counterBalance,
  };
}
export function updateAvailableBalance(pair: PairBalance): ExchangeTypes {
  return {
    type: UPDATE_AVAILABLE_BALANCE,
    pair: pair,
  };
}

export function updateMarket(market: PairMarket): ExchangeTypes {
  return {
    type: UPDATE_Market,
    market: market,
  };
}

export function refreshMyOffersAction(): ExchangeTypes {
  return {
    type: REFRESH_MY_OFFERS,
  };
}

export function persistSelectedPair(payload: PairPersist): ExchangeTypes {
  return {
    type: PERSIST_SELECTED_PAIR,
    payload: payload
  };
}

export function selectOffer(offer: Offer): ExchangeTypes {
  return {
    type: SELECT_OFFER,
    offer: offer,
  };
}

export const setSelectedPairAction = (
  pair: any,
  defaultData,
  action: string
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  try {
    let state: any = getState();
    let coins = state.transaction.coins;
    let assets = state.assets.assets;
    const markets = state.market.markets;
    const selectedPair = state.exchange.selectedPair
    let refresh = action === selectPairActions.REFRESH_DATA ? false : true;

    if (
      action === selectPairActions.DEFAULT ||
      action === selectPairActions.FROM_MARKET ||
      action === selectPairActions.REFRESH_DATA
    ) {
      if (markets.length > 1) {
        let shouldBreak = false;
        for (let i = 0; i < markets.length; i++) {
          if (shouldBreak) break;
          const market = markets[i];
          for (let j = 0; j < market.data.length; j++) {
            const marketData = market.data[j];
            if (defaultData?.base && defaultData?.counter) {
              if (
                marketData.base === defaultData.base &&
                marketData.counter === defaultData.counter
              ) {
                pair = marketData;
                shouldBreak = true;
                break;
              }
            } else if (pair === null) {
              if (marketData) {
                pair = marketData;
                shouldBreak = true;
                break;
              }
            } else if (
              (pair.base || pair.marketCoinCode) === marketData.base &&
              (pair.counter || pair.toCoinCode) === marketData.counter
            ) {
              pair = marketData;
              shouldBreak = true;
              break;
            }
          }
        }
      }
    }
    const baseCode = pair.base || pair.marketCoinCode;
    const counterCode = pair.counter || pair.toCoinCode;
    let coin = coins.find((coin) => coin.short == baseCode && coin.issuer == pair.baseIssuer);
    let coin2 = coins.find((coin) => coin.short == counterCode && coin.issuer == pair.counterIssuer);
    let marketCoin = assets.find(
      (asset) => asset.short.toUpperCase() == baseCode && asset.issuer == pair.baseIssuer
    );
    let toCoin = assets.find(
      (asset) => asset.short.toUpperCase() == counterCode && asset.issuer == pair.counterIssuer
    );

    let price = getPairPrice({
      marketCoinPrice: marketCoin.currentRate,
      toCoinPrice: toCoin.currentRate,
    });

    const obj: any = {
      change: pair.change,
      open: pair.open,
      close: pair.close,
      high: pair.high,
      low: pair.low,
      volume: pair.volume,
      marketCoinCode: baseCode,
      toCoinCode: counterCode,
      balance: coin ? coin.balance : 0,
      lastPrice: price,
      marketCoinIssuer: marketCoin.issuer,
      toCoinIssuer: toCoin.issuer,
      closePrev: selectedPair.close,
      toCoinBalance: coin2 ? coin2.balance : 0,
      buyPrice: 0,
      sellPrice: 0,
      bidsLength: 0,
      asksLength: 0,
    };
    dispatch(setSelectedPair(obj, refresh));
  } catch (error) {
    console.log(error);
  }
};
