import React from "react";
import { colors } from "styles/theme";
import { lossGraphCard, profitGraphCard } from "assets/images";
import MyTradesHistory from "./MyTradesHistory";
import useTradeHistory from "screens/hooks/exchange/useTradeHistory";
import MyOffers from "./MyOffers";
import moment from "moment";
import ConfirmDelete from "./ConfirmDelete";
import NoAuthOverlay from "components/atomic/NoAuthOverlay";

export function renderHistoryRow(item: any) {
  return (
    <div className="overflow-auto flex py-1 pr-2 pl-2 w-full justify-between items-center">
      <div className="flex w-full justify-between">
        <div className="flex item-center">
          <text
            className="table-cell text-gray4 text-xxs md:text-xs"
            style={{ color: item.profit ? colors.green : colors.pink }}
          >
            {item.price}
          </text>
          <img
            className="ml-2 w-4 h-3"
            src={item.profit ? profitGraphCard : lossGraphCard}
          />
        </div>
        <text className="table-cell text-gray4 text-xxs md:text-xs">
          {item.amount}
        </text>
        <text className="table-cell text-gray4 text-xxs md:text-xs">
          {moment(item.date).format("DD-MM")}
        </text>
      </div>
    </div>
  );
}

export function renderTableHeading(headings) {
  return (
    <div className="overflow-auto flex pl-2 mt-1 mb-1 justify-between w-full items-center">
      {headings.map((heading: any, index) => {
        return (
          <text key={index} className="text-secondaryGray pr-2 table-cell text-xs md:text-sm">
            {heading}
          </text>
        );
      })}
    </div>
  );
}

export function TradeHistoryTable() {
  let {
    tab,
    auth,
    loading,
    setTab,
    allTrades,
    myTrades,
    offers,
    getMyTrades,
    getMyOffers,
    nextAllTrades,
    headings,
    onOfferDelete,
    deleteAllOffers,
    nextMyTrades
  } = useTradeHistory();

  return (
    <div className="w-full mt-2 mr-2 relative">
      <div className="w-full border pb-2 px-4 border-primary">
        <div className="flex w-full border-b border-primary">
          <div
            className={`${
              tab === 0 ? "border-b border-accent text-white":"text-secondaryGray"
            } cursor-pointer text-center px-6 py-3`}
            onClick={() => setTab(0)}
          >
            <p className="text-xs md:text-sm font-semibold">All Trades</p>
          </div>
          <div
            className={`${
              tab === 1 ? "border-b border-accent text-white":"text-secondaryGray"
            } cursor-pointer text-center px-6 py-3`}
            onClick={() => setTab(1)}
          >
            <p className="text-xs md:text-sm font-semibold">My Trades</p>
          </div>
          <div
            className={`${
              tab === 2 ? "border-b border-accent text-white":"text-secondaryGray"
            } cursor-pointer text-center px-6 py-3`}
            onClick={() => setTab(2)}
          >
            <p className="text-xs md:text-sm font-semibold">My Orders</p>
          </div>
        </div>
        {tab === 0 && (
          <MyTradesHistory
            data={allTrades}
            loading={loading}
            getTrades={() => {}}
            onNext={nextAllTrades}
            headings={headings}
          />
        )}
        {tab === 1 && (
          <MyTradesHistory
            data={myTrades}
            loading={loading}
            getTrades={getMyTrades}
            onNext={nextMyTrades}
            headings={headings}
          />
        )}
        {tab === 2 && (
          <MyOffers
            data={offers}
            height="235px"
            showHeader={true}
            my={true}
            loading={loading}
            getOffers={getMyOffers}
            onOfferDelete={onOfferDelete}
            deleteAllOffers={deleteAllOffers}
          />
        )}
      </div>
      {!auth.password && <NoAuthOverlay />}
    </div>
  );
}
