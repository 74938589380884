import React, {  } from 'react';
import ConfirmPassword from './ConfirmPassword';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/reducers';
import { toggleConfirmModal } from 'store/actions/GenericActions';

function ConfirmGlobal() {
    let confirmOpen = useSelector((state:RootState)=>state.generic.confirmOpen);
    let dispatch = useDispatch();
    let onClose = ()=>{
        dispatch(toggleConfirmModal(false));
    }
    return(<ConfirmPassword isOpen={confirmOpen} onClose={(onClose)} onSuccess={onClose} />)
}

export default ConfirmGlobal