import React, { Fragment, useEffect, useState } from "react";

function Timer({ startTime, timeFrame, onExpired }) {
  const [time, setTime] = useState("00:00:00");

  const renderNumber = (num) => num <10 ? '0'+num:num
  const intialize = () => {
    let seconds = (new Date().getTime() - new Date(startTime).getTime()) / 1000;
    let remainingSeconds = (timeFrame*60) - seconds

    if(remainingSeconds < 0) {
      onExpired()
      return
    }
    let x = setInterval(function () {
      let minutes = Math.floor(remainingSeconds / 60)
      let secondsrm =Math.floor(remainingSeconds - minutes * 60);
      if(minutes <0){
        minutes = 0;
        secondsrm = 0
      }
      setTime(`00:${renderNumber(minutes)}:${renderNumber(secondsrm)}`);

      if (remainingSeconds < 0) {
        clearInterval(x);
        onExpired()
      }
      remainingSeconds--;
    }, 1000);
  };
  useEffect(() => {intialize()}, []);

  return <Fragment>{time}</Fragment>;
}

export default Timer;
