import React,{ useEffect, useState } from "react";
import { favoriteIcon, notFavIcon } from "assets/images/images";
import { colors } from "styles/theme";


export const TableRow = (props:any) => {
    const [img,setImg] = useState(props.is_fav ? favoriteIcon :notFavIcon);
    let handleFavourite =(e)=>{
      e.stopPropagation()
      if(!props.is_fav){
        setImg(favoriteIcon)
        props.onFav('SET_FAV')
      }else{
        setImg(notFavIcon)
        props.onFav('UNSET_FAV')
      }
    }
    
  return (
    <tr onClick={props.onNav} className="overflow-auto text-left cursor-pointer border-b border-gray3 py-4 w-full md:overflow-auto md:w-full lg:overflow-auto lg:w-full xl:overflow-auto xl:w-full">
   
      <td><img onClick={handleFavourite} src={props.is_fav ? favoriteIcon :notFavIcon} className="h-3 w-3 md:h-6 md:w-6 lg:h-6 lg:w-6 xl:w-6 xl:h-6" /></td>
      <td className="ml-3 mr-3 text-white text-xxxs sm:text-xs lg:text-sm xl:text-sm font-regular">{props.counter}<span className="text-secondaryGray"> / {props.base}</span></td>
      {/* <td className="mr-3 py-5 text-white text-xxxs sm:text-xs lg:text-sm xl:text-sm font-regular">{props.base}</td> */}
      <td className="mr-3 py-5 text-white text-xxxs sm:text-xs lg:text-sm xl:text-sm font-regular">{props.close}<span className="text-secondaryGray"> </span></td>
      <td className="mr-3 text-white text-xxxs sm:text-xs lg:text-sm xl:text-sm font-regular" style={{color:props.change > 0?colors.green:colors.pink}}>{props.change ==="-"?"-":props.change+"%"}</td>
      <td className="mr-3 text-white text-xxxs md:ml-5 sm:text-xs lg:text-sm xl:text-sm font-regular">{props.high}</td>
      <td className="mr-3 text-xxxs sm:text-xs md:ml-3 lg:text-sm xl:text-sm font-regular text-white">{props.low}</td>{/*/ {props.priceinCurrency}*/}
      {/* <td className="mr-3 text-white text-xxxs sm:text-xs lg:text-sm xl:text-sm font-regular">{'props.marketCap'}</td> */}
      <td className="mr-3 text-white text-xxxs sm:text-xs lg:text-sm xl:text-sm font-regular">{props.volume}</td>
    </tr>
  );
};
