import { useState, useEffect, useRef } from "react";
import { setSelectedPairAction } from "store/actions/ExchangeActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import {
  selectMarketCoin,
  addToFavourites,
  removeFromFavourites,
} from "store/actions/MarketActions";
import { useLocation } from "react-router-dom";
import { selectPairActions } from "utils/constants";
import { getMarketData, getRateChange } from "services/exchange";
import { persistSelectedPair } from "../../../store/actions/ExchangeActions"
import moment from "moment";
import { useAlert } from "react-alert";

const useCoinPair = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const {
    market: { markets, selected },
    exchange: { selectedPair },
  } = useSelector((state: RootState) => state);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false)
  const init: any = [];
  const initialRender = useRef(true);
  const [state, setState] = useState({
    tab: "",
    headerStart: [],
    headerLast: [],
    list: init,
    listBk: [],
    loading: true,
    search: "",
  });

  const handleSelect = async (base, counter) => {
    setLoader(true)
    try {
      let response: any = await getMarketData(
        { code: counter?.short?.toUpperCase(), issuer: counter?.issuer },
        { code: base?.short?.toUpperCase(), issuer: base?.issuer },
        moment().subtract(40, "days").unix() * 1000,
        Math.round(new Date().getTime()),
        86400000,
        0,
        50,
        'desc'
      )
      if (response?.records && response?.records[0]) {
        const record = response.records[0];
        const prev = response.records[1];
        const prevClose = prev ? prev.close : record.close
        const obj = {
          base: base.short.toUpperCase(),
          baseIssuer: base.issuer,
          change: Number(getRateChange(Number(prevClose), Number(record.close)).toFixed(7)),
          close: Number(record.close).toFixed(Number(record.close) > 999 ? 2 : 7),
          closePrev: prevClose,
          counter: counter.short.toUpperCase(),
          counterIssuer: counter.issuer,
          high: Number(record.high).toFixed(Number(record.high) > 999 ? 2 : 7),
          is_fav: false,
          low: Number(record.low).toFixed(Number(record.low) > 999 ? 2 : 7),
          open: Number(record.open).toFixed(Number(record.open) > 999 ? 2 : 7),
          orderId: 0,
          showOnTop: 0,
          volume: Number(record.base_volume).toFixed(Number(record.base_volume) > 999 ? 2 : 7),
        };
        setLoader(false)
        setShow(false);
        dispatch(persistSelectedPair(obj))
        dispatch(
          setSelectedPairAction(obj, location.state, selectPairActions.SELECT_PAIR)
        );
      } else {
        alert.error("No Response Found...")
        setLoader(false)
      }
    } catch (error) {
      console.log({ error })
    }

  };

  const handlePredefinedSelect = async (item) => {
    setShow(false);
    // if (item.counter == "KISHU") {
    //   item.base = "KISHU"
    //   item.counter = "XLM"
    //   item["baseIssuer"] = item.counterIssuer
    //   item.counterIssuer = null

    //   try {
    //     let response: any = await getMarketData(
    //       { code: item.counter?.toUpperCase(), issuer: item?.counterIssuer },
    //       { code: item.base?.toUpperCase(), issuer: item?.baseIssuer },
    //       moment().subtract(40, "days").unix() * 1000,
    //       Math.round(new Date().getTime()),
    //       86400000,
    //       0,
    //       50,
    //       'desc'
    //     )
    //     if (response?.records && response?.records[0]) {
    //       const record = response.records[0];
    //       const prev = response.records[1];
    //       const prevClose = prev ? prev.close : record.close
    //       const obj = {
    //         base: item.base.toUpperCase(),
    //         baseIssuer: item?.baseIssuer,
    //         change: Number(getRateChange(Number(prevClose), Number(record.close)).toFixed(7)),
    //         close: Number(record.close).toFixed(Number(record.close) > 999 ? 2 : 7),
    //         closePrev: prevClose,
    //         counter: item.counter.toUpperCase(),
    //         counterIssuer: item?.counterIssuer,
    //         high: Number(record.high).toFixed(Number(record.high) > 999 ? 2 : 7),
    //         is_fav: false,
    //         low: Number(record.low).toFixed(Number(record.low) > 999 ? 2 : 7),
    //         open: Number(record.open).toFixed(Number(record.open) > 999 ? 2 : 7),
    //         orderId: 0,
    //         showOnTop: 0,
    //         volume: Number(record.base_volume).toFixed(Number(record.base_volume) > 999 ? 2 : 7),
    //       };
    //       dispatch(persistSelectedPair(obj))
    //       dispatch(
    //         setSelectedPairAction(obj, location.state, selectPairActions.SELECT_PAIR)
    //       );
    //     } else {
    //       alert.error("No Response Found...")
    //       setLoader(false)
    //     }
    //   } catch (error) {
    //     console.log({ error })
    //   }
    // } else {
    dispatch(persistSelectedPair(item))
    dispatch(
      setSelectedPairAction(item, location.state, selectPairActions.SELECT_PAIR)
    );
    // }
  };

  const getFirst = () => {
    let temp: any = [];
    for (let i = 0; i < 3; i++) {
      const element = markets[i];
      if (element) {
        temp.push(element.name);
      }
    }

    return temp;
  };

  const getLastData = () => {
    let temp: any = [];
    let test = [];
    for (let i = 3; i < markets.length; i++) {
      const element = markets[i];
      if (element) {
        temp.push(element.name);
      }
    }
    return temp;
  };

  useEffect(() => {
    if (markets.length > 0) {
      setState((prev) => ({
        ...prev,
        headerStart: getFirst(),
        headerLast: getLastData(),
      }));
    }
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (markets.length > 1) {
        dispatch(
          setSelectedPairAction(
            selectedPair,
            location.state,
            selectPairActions.REFRESH_DATA
          )
        );
      }
    }

  }, [markets]);

  useEffect(() => {
    if (markets.length > 1) {
      dispatch(
        setSelectedPairAction(
          null,
          location.state,
          location.state?.base
            ? selectPairActions.FROM_MARKET
            : selectPairActions.DEFAULT
        )
      );
    }
  }, []);

  useEffect(() => {
    if (markets.length > 0) {
      setState((prev) => ({
        ...prev,
        list: selected.data,
        tab: selected.name,
      }));
    }
  }, [selected]);

  let setTab = ({ marketCoinCode }) => {
    let selected = markets.find((item) => item.name === marketCoinCode);
    if (selected) {
      dispatch(selectMarketCoin(selected));
    }
  };

  const handleFav = (action, item) => {
    if (action === "SET_FAV" && selected.name !== "Favorite") {
      dispatch(addToFavourites({ ...item, is_fav: true }));
    } else {
      dispatch(removeFromFavourites(item, selected.name));
    }
  };

  const handelChange = (event) => {
    let { value } = event.target;
    let newData = selected.data.filter((item) =>
      item.counter.includes(value.toUpperCase())
    );
    setState((prev) => ({ ...prev, search: value, list: newData }));
  };

  return {
    ...state,
    setTab,
    handleSelect,
    handlePredefinedSelect,
    handelChange,
    handleFav,
    show,
    setShow,
    loader,
    setLoader
  };
};

export default useCoinPair;
