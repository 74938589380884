import { Keypair } from "stellar-sdk";
import { stretchPincode } from "services/argon2";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { encrypt } from "services/tweetnacl";
import { saveData } from "services/storageServices";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateAccount, getSelectedKeys, lookForPayments } from "services/trannsactionServices";
import { useAlert, positions } from "react-alert";
import { postOptions, BASE_URL } from "utils/AppConstants";
import { addWallet, getNoOfWallets, addAsset } from "services/dbServices";
import { removeCoins, setCoinsAction, transactionsHistoryAction } from "store/actions/TransactionActions";
import { clearProfileAction } from "store/actions/ProfileActions";
import { encryptText } from "utils/helpers";
import { setLoading } from "../../store/actions/GenericActions"

let useNewWallet = () => {
  let { auth } = useSelector((state: RootState) => state);
  let history = useHistory();
  let [key, setKey] = useState("");
  let [validation, setValidation] = useState("");
  let [submitting, setSubmitting] = useState(false);
  const [createAgreed, setCreateAgreed] = useState(false);
  const [importAgreed, setImportAgreed] = useState(false);
  const [showAgreed, setShow] = useState(false)
  const [privateKey, setPrivate] = useState("")
  let alert = useAlert()
  let dispatch = useDispatch()

  const agreeToTerms = (create) => {
    setShow(false)
    if (create) {
      setCreateAgreed(true)
    } else {
      dispatch(setLoading(true))
      setImportAgreed(true)
    }
  }
  useEffect(() => {
    if (createAgreed) {
      createWallet()
    }
  }, [createAgreed])
  useEffect(() => {
    if (importAgreed) {
      handleImportWallet(null)
    }
  }, [importAgreed])

  let createWallet = async () => {
    try {
      setSubmitting(true);
      let keypair;
      if (!createAgreed) {
        keypair = Keypair.random();
        setPrivate(keypair.secret())
        setShow(true)
        return
      } else {
        keypair = Keypair.fromSecret(privateKey)
      }
      // const pincode_stretched = await stretchPincode(
      //   auth.password,
      //   keypair.publicKey()
      // );

      // const { cipher, nonce } = encrypt(
      //   keypair.rawSecretKey(),
      //   keypair.rawPublicKey(),
      //   pincode_stretched.hash
      // );
      // console.log('history.location.state', history.location.state)
      //await fetch(`https://friendbot.stellar.org?addr=${keypair.publicKey()}`);
      let data = await fetch(
        BASE_URL + "/accounts/create",
        postOptions({
          publicKey: keypair.publicKey(),
          referral: history.location.state ? history.location.state : "",
        })
      ).then((res) => res.json());

      // let account = {
      //   publicKey: keypair.publicKey(),
      //   cipher,
      //   nonce,
      // };
      updateAccount(keypair.publicKey());

      let no = await getNoOfWallets();
      let secretEnc = encryptText(keypair.secret(), auth.password);

      let walletId = await addWallet({
        name: "New Wallet " + (no + 1),
        id: keypair.publicKey(),
        public: keypair.publicKey(),
        private: secretEnc,
        btcBalance: 0,
        percent: 0,
        usdBalance: 0,
        FEDERATION: "",
      });

      saveData({ myToken: '' })
      saveData({
        selectedWallet: JSON.stringify({
          public: keypair.publicKey(),
          private: secretEnc,
          walletId: walletId,
        }),
      });

      // saveData({ "WALLET[keystore]": btoa(JSON.stringify(account)) });

      lookForPayments(keypair.publicKey(), (message) => {
        alert.show(message, { type: "success" });
        dispatch(setCoinsAction());
        dispatch(transactionsHistoryAction(false))
      });
      dispatch(clearProfileAction())
      dispatch(removeCoins())
      setSubmitting(false);
      if (!getSelectedKeys() || !getSelectedKeys().public) {
        history.push("/walletScreen");
      } else {
        history.push({
          pathname: "/walletScreen",
          state: {
            addWallet: true,
          },
        });
      }
    } catch (e) {
      // console.log(e.response)
      setSubmitting(false)
    }
  };

  let setValue = (e) => {
    setKey(e.target.value);
  };

  let handleImportWallet = async (e) => {
    e?.preventDefault();
    if (key === "") {
      setValidation("Private key is required.")
      return;
    }

    setSubmitting(true)
    try {
      let keypair;
      if (!importAgreed) {
        keypair = Keypair.fromSecret(key)
        setPrivate(keypair.secret())
        setShow(true)
        return
      } else {
        keypair = Keypair.fromSecret(privateKey)
      }

      let data = await fetch(
        BASE_URL + "/accounts/create",
        postOptions({
          publicKey: keypair.publicKey(),
          referral: history.location.state ? history.location.state : "",
        })
      ).then((res) => res.json());

      // const pincode_stretched = await stretchPincode(
      //   auth.password,
      //   keypair.publicKey()
      // );

      // const { cipher, nonce } = encrypt(
      //   keypair.rawSecretKey(),
      //   keypair.rawPublicKey(),
      //   pincode_stretched.hash
      // );
      // let account = {
      //   publicKey: keypair.publicKey(),
      //   cipher,
      //   nonce,
      // };

      // saveData({ "WALLET[keystore]": btoa(JSON.stringify(account)) });

      let no = await getNoOfWallets();
      let secretEnc = encryptText(keypair.secret(), auth.password);

      let walletId = await addWallet({
        name: "New Wallet " + (no + 1),
        id: keypair.publicKey(),
        public: keypair.publicKey(),
        private: secretEnc,
        btcBalance: 0,
        percent: 0,
        usdBalance: 0,
        FEDERATION: "",
      });
      saveData({ myToken: '' })
      saveData({
        selectedWallet: JSON.stringify({
          public: keypair.publicKey(),
          private: secretEnc,
          walletId: keypair.publicKey(),
        }),
      });

      setSubmitting(false)
      alert.show("Wallet imported successfully!", { type: "success" });

      lookForPayments(keypair.publicKey(), (message) => {
        alert.show(message, { type: "success", position: positions.TOP_RIGHT });
        dispatch(setCoinsAction());
        dispatch(transactionsHistoryAction(false))
      });
      dispatch(clearProfileAction())
      dispatch(removeCoins())
      if (!getSelectedKeys() || !getSelectedKeys().public) {
        history.push("/walletScreen");
      } else {
        history.push({
          pathname: "/walletScreen",
          state: {
            addWallet: true,
          },
        });
      }
    } catch (e) {
      setSubmitting(false)
      alert.show("Error occurred please try again.", { type: "error" })
    }
  };

  return { createWallet, handleImportWallet, key, setValue, submitting, validation, showAgreed, agreeToTerms, privateKey };
};

export default useNewWallet;
