import Footer from "components/molecular/footer";
import React from "react";

function TermOfUse() {
  return (
    <div>
      <div
        className="text-center text-sm"
        style={{ paddingLeft: "20vw", paddingRight: "20vw" }}
      >
        <h1 className="text-2xl font-semibold text-white mt-16">
          TERMS OF USE – GWallet
        </h1>
        <h5 className="text-white text-base font-semibold my-2">
          www.gwallet.tech
        </h5>
        <h5 className="text-secondaryGray text-base">
          Effective date: 30 November 2020
        </h5>
        <div className="text-left text-white ml-12 mt-12 mb-4">
          <text>1.</text>
          <text className="text-base font-semibold ml-2">Introduction</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
          Welcome to the GWallet website which is located on www.gwallet.tech
          and the GWallet Mobile Applications (“Site”).
        </p>
        <p className="text-secondaryGray text-left mt-2">
          This website is owned and operated by GWalletGlobal OU (registry code
          14843966) from Roseni 12-85, Tallinn 10111, Estonia. Throughout the
          Site, the terms “we”, “us”, “platform”, “GWallet” and “our” refer to
          GWalletGlobal OU.
        </p>
        <p className="text-secondaryGray text-left mt-2">
          By visiting our Site, App and/ or using our features, you engage in
          our “Service” and agree to be bound by the following Terms of
          Use/Terms and Conditions (“Terms”), including those additional terms
          and conditions and policies referenced herein and/or available by
          hyperlink. These Terms apply to all users of the site and the GWallet
          mobile applications located on Google PlayStore and Apple App Store
          including without limitation users who are users, browsers, customers,
          merchants, and/ or contributors of content. Level G is the Token(coin)
          which acts as a battery to use the Gwallet platform.
        </p>
        <p className="text-white text-left font-semibold mt-2">
          PLEASE CAREFULLY READ THE FOLLOWING TERMS, DISCLAIMERS AND OUR OTHER
          POLICIES POSTED BEFORE USING THE SERVICES.
        </p>
        <p className="text-secondaryGray text-left mt-2">
          By accessing or using our Services, you agree to these Terms,
          conditions, and all applicable laws. If you do not agree with these
          Terms, our Privacy Policy, or any other of our policy, you should not
          use the Services.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>2.</text>
          <text className="text-base font-semibold ml-2">
            GWallet – General Information
          </text>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              <text className="text-white font-semibold mr-2">What we do.</text>
              Gwallet is a hybrid platform which offers a set of competitive
              experience in managing digital assets using Stellar decentralized
              protocol. We believe users are looking for a holistic platform and
              have full control of their digital assets. We want to grow with
              you and you must be amazed by experiencing our Decentralized
              Exchange with private wallet compatibility, Peer to Peer feature
              with escrow service, multi anchors as the result of AI capability
              and fastest transaction speed. We are beyond a service provider,
              we are engaging people with the same dream and passion. To find
              out more information about what we do, please refer to our
              website.
            </p>
          </div>
        </div>
        <div className="flex mt-4">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              <text className="text-white font-semibold mr-2">
                Sole discretion.{" "}
              </text>
              We reserve the right to add/discontinue any product or service
              anytime at our sole discretion.
            </p>
          </div>
        </div>
        <div className="flex mt-4">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              <text className="text-white font-semibold mr-2">
                No consideration.
              </text>
              Please note that we do not collect monetary consideration/money
              from users on this platform. We only provide a
              platform/marketplace as an “Escrow” service for users to connect
              and trade with each other and charge them for each transaction in
              accordance with the terms and conditions provided here.
            </p>
          </div>
        </div>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>3.</text>
          <text className="text-base font-semibold ml-2">Eligibility</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
          The use of our services is strictly limited to parties who can
          lawfully enter into and form contracts on the Internet and above 18
          years of age.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>4.</text>
          <text className="text-base font-semibold ml-2">No endorsements</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
          Please note that the Asset listings on GWallet wallet are not
          endorsements. GWallet may not carry out any independent
          investigation/research or analysis of any of the Stellar Network's
          assets. Stellar is an open system which means that there can be scams
          and market manipulators.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>5.</text>
          <text className="text-base font-semibold ml-2">
            Risks involved with Digital Currencies/Cryptocurrencies{" "}
          </text>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              You understand and accept that, at your own risk, you will access
              and use the Services. The probability of loss can be important in
              the trading of Digital Currencies/Cryptocurrencies . Therefore you
              should carefully consider whether in view of your circumstances
              and financial capital, such trading is acceptable for you.
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              You agree and acknowledge that GWallet has no authority over the
              Stellar Network and will not be liable for any loss/harm arising
              from such threats, including but not limited to the inability to
              reverse a transaction and any losses related to it due to
              deceptive or fraudulent acts.
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              You agree and understand that GWallet will not be responsible for
              any communication failures, disruptions, errors, distortions or
              delays you may experience when using the Services.
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              You agree and understand that the risks also include but are not
              limited to failure of hardware, software and internet connections,
              the risk of failure to achieve a certain market value/price for
              any cryptoasset issued on the Stellar Network, the risk of
              malicious software introduction, and the risk that third parties
              may obtain unauthorized access to information stored within your
              GWallet, including, but not limited to your public and secret
              keys.
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              The probability of losses from the use of XLM and cryptoasset
              services can be large and losses may occur over a limited period
              of time. Subject to substantial fluctuations in the price and
              liquidity of XLM and other cryptoassets provided by the Stellar
              Network, past performance is not representative of future outcomes
              and you are solely responsible for your acts on the Stellar
              Network. Before investing in cryproasset trading, you must do your
              own research and use caution.
            </p>
          </div>
        </div>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>6.</text>
          <text className="text-base font-semibold ml-2">General Terms</text>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              Identity Verification. You hereby authorize GWallet to directly,
              or through third parties, to make any inquires necessary to verify
              your identity and to take steps necessary to protect against fraud
              and other illegal activity based on the results of such inquiries.
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              Account Information. You hereby authorize GWallet to store your
              account information. You also authorize GWallet to use your
              account information to help protect against fraud and other
              illegal activity.
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              Termination or Suspension of Account. GWallet may terminate or
              suspend access to its Services at any time, without prior notice
              or liability, for any reason whatsoever, including without
              limitation, if you breach the Terms or circumventing GWallet’s
              controls. All provisions of the Terms, which by their nature
              survive termination, shall survive termination, including, without
              limitation, ownership provision, warranty disclaimers, indemnity
              and limitation of liability.
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              Authorized Users. Only you may use your GWallet account. You must
              keep your account information, including private keys confidential
              and not authorize any third party to access or use the Service on
              your behalf. You must contact GWallet immediately if you suspect
              misuse of your account or any security breach in the Service at
              admin@gwalletglobal.com
            </p>
          </div>
        </div>
        <div className="flex mt-2">
          <div>
            <div className="p-1 bg-white rounded-full mt-2" />
          </div>
          <div>
            <p className="text-secondaryGray text-left ml-4">
              Taxes. You are responsible for complying with all applicable tax
              laws.
            </p>
          </div>
        </div>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>7.</text>
          <text className="text-base font-semibold ml-2">Exchange/P2P</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        Users on GWallet can trade BTC through exchange and can also conver to Fiat Currency.  In order to trade with other users have to use the p2p service in the marketplace.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>8.</text>
          <text className="text-base font-semibold ml-2">Payment</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        All payments must be made as per the payment method displayed on our Site. Users can view the transaction fees, service charges or any other fee we may charge on our platform.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>9.</text>
          <text className="text-base font-semibold ml-2">Withdrawals</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        Users are permitted to make a withdrawal of maximum 10 BTC or equivalent in one day (24 hours).
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>10.</text>
          <text className="text-base font-semibold ml-2">Zakat Feature</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        GWallet provides a unique Zakat feature on the platform through which muslim users are permitted to pay Zakat.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>11.</text>
          <text className="text-base font-semibold ml-2">Permitted use</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        You agree to use the Site and the Services only for purposes that are permitted by these Terms of Use and in compliance with all applicable laws, regulations, and generally accepted practices or guidelines in the relevant jurisdictions. You may only use the Site and Services for your non-commercial, non-exclusive, non-assignable, non-transferable, and limited personal use, and no other purposes.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>12.</text>
          <text className="text-base font-semibold ml-2">Limited License and Site Access; Acceptable Use</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        You may not: (a) resell or make any commercial use of this Site or any of the contents of this Site; (b) modify, adapt, translate, reverse engineer, decompile, disassemble or convert any of the contents of this Site not intended to be so read; (c) copy, imitate, mirror, reproduce, distribute, publish, download, display, perform, post or transmit any of the contents of this Site in any form or by any means; or (d) use any data mining, bots, spiders, automated tools or similar data gathering and extraction methods on the contents of the Site or to collect any information from the Site or any other user of the Site.
        </p>
        <p className="text-secondaryGray text-left mt-2">
        You use this Site at your own risk. You agree that you will be personally responsible for your use of this Site and all of your communication and activity on this Site. If we determine, in our sole discretion, that you engaged in prohibited activities, were not respectful of other users, or otherwise violated the Terms and Conditions, we may deny you access to this Site on a temporary or permanent basis and any decision to do so is final.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>13.</text>
          <text className="text-base font-semibold ml-2">Intellectual Property Rights</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        Your use of the Site and its contents grants no rights to you concerning any copyright, designs, and trademarks and all other intellectual property and material rights mentioned, displayed, or relating to the Content (defined below) on the Site.  All Content, including third party trademarks, designs, and related intellectual property rights mentioned or displayed on this Site, are protected by national intellectual property and other laws. Any unauthorized reproduction, redistribution or other use of the Content is prohibited and may result in civil and criminal penalties. You may use the Content only with our prior written and express authorization. To inquire about obtaining authorization to use the Content, please contact us at <a className="text-blue">admin@gwalletglobal.com</a>. 
        </p>
        <p className="text-secondaryGray text-left mt-2">
        In addition to the intellectual property rights mentioned above, "Content" is defined as any graphics, photographs, including all image rights, sounds, music, video, audio, or text on the Site.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>14.</text>
          <text className="text-base font-semibold ml-2">Third-party products and services</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        In using the Services, you may view content or services provided by third parties, including links to web pages and services of such parties ("Third Party Services"). These Third Party Services are provided solely as a convenience to you. We do not control, endorse or adopt any Third Party Services and have no responsibility for Third Party Services. Your use of such Third Party Services is governed by that party's terms and conditions or privacy policies.
        </p>
        <p className="text-secondaryGray text-left mt-2">
        It is your responsibility to read the terms and conditions and privacy policy of any Third Party Services that you visit or use. You agree and understand that we are not responsible or liable for any loss or damage of any sort incurred as a result of any such dealings and your use of Third Party Services is at your own risk.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>15.</text>
          <text className="text-base font-semibold ml-2">Monitoring Activity</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        GWALLET has no obligation to monitor this Site or any portion thereof. However, we reserve the right to review any posted content and remove, delete, redact or otherwise modify such content, in our sole discretion, at any time and from time to time, without notice or further obligation to you. GWALLET has no obligation to display or post any content. GWALLET, subject to the Privacy Policy reserves the right to disclose, at any time and from time to time, any information or posted the content that it deems necessary or appropriate, including without limitation to satisfy any applicable, law, regulation, contractual obligation, legal, dispute process, or governmental request. GWALLET shall have no liability in connection with any content or content submitted to, transmitted via, or displayed or posted on this Site, regardless of whether provided by GWALLET or any other party.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>16.</text>
          <text className="text-base font-semibold ml-2">Digital Millennium Copyright Act</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        GWallet expects all users to respect the intellectual property rights of others. GWallet may remove material that appears in its sole discretion to infringe upon the intellectual property rights of others and we will terminate the access rights of any repeat infringer. If you are a copyright owner or an agent thereof and believe that any Content infringes upon your copyrights, you may notify us at admin@gwalletglobal.com .  The notification must include the following information: physical or electronic signature of the owner or authorized agent of the owner of the allegedly infringed work; identification of the allegedly infringed work; identification of the material that is claimed to be infringing and reasonably sufficient information for GWallet to locate the material; contact information of the notifying party, such as an address, telephone number, and email; a statement that the notifying party has a good faith belief that the use of the material in the manner complained of is not authorized by the owner of the allegedly infringed work, its agent or the law; and a statement, under penalty of perjury that the information in the notification is accurate and the notifying party is the owner or authorized agent of the allegedly infringed work.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>17.</text>
          <text className="text-base font-semibold ml-2">Disclaimer</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, GWALLET EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND REPRESENTATIONS, EXPRESS OR IMPLIED, INCLUDING ANY (A) WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE AS TO THE SITE AND ITS CONTENT, INCLUDING THE INFORMATION, DATA, SOFTWARE, OR PRODUCTS CONTAINED THEREIN, OR THE RESULTS OBTAINED BY THEIR USE OR AS TO THE PERFORMANCE THEREOF, (B) WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF DEALING, AND (C) WARRANTIES OR CONDITIONS OF UNINTERRUPTED OR ERROR-FREE ACCESS OR USE. THE SITE AND ALL CONTENTS THEREIN AND COMPONENTS THEREOF ARE PROVIDED ON AN “AS IS” BASIS AND YOUR USE OF THE SITE IS AT YOUR OWN RISK.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>18.</text>
          <text className="text-base font-semibold ml-2">Limitation of Liability</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        You agree that in no event shall GWallet be liable to you, or any third party, for any lost profits, incidental, consequential, punitive, special, or indirect damages arising out of or in connection with the Site or the Terms and Conditions, even if advised as to the possibility of such damages, regardless of whether the claim for such damages is based in contract, tort, strict liability or otherwise. This limitation on liability includes, but is not limited to, any (i) errors, mistakes, or inaccuracies in any Content or for any loss or damage of any kind incurred by you as a result of your use of or reliance on the Content; (ii) the transmission of any bugs, viruses, Trojan horses or the like which may infect your equipment, failure of mechanical or electronic equipment; (iii) unauthorized access to or use of the Site or GWallet' secure servers and/or any personal information and/or financial information stored therein; or (iv) theft, operator errors, strikes or other labor problems or any force majeure.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>19.</text>
          <text className="text-base font-semibold ml-2">Indemnification</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        You agree to indemnify and hold GWallet and its subsidiaries, affiliates, officers, directors, agents, and employees, harmless from and against any suit, action, claim, demand, penalty or loss, including reasonable attorneys’ fees, made by or resulting from any third party due to or arising out of your use of the Site, breach of the Terms and Conditions or the materials it incorporates by reference, or your violation of any law, regulation, order or other legal mandates, or the rights of a third party.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>20.</text>
          <text className="text-base font-semibold ml-2">Responsibility to comply with Law</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        You are solely responsible, in your jurisdiction, for ensuring your own compliance with laws and taxes. Crypto-currencies can be illegal within your country. You consent to proceed in compliance with any and all applicable laws and be legally bound by them by entering into this User Agreement. GWallet is under no obligation to control your compliance with any law.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>21.</text>
          <text className="text-base font-semibold ml-2">Dispute Resolution & Governing Laws</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        In the event of a dispute arising out of or in connection with these terms or any contract between you and us, then you agree to attempt to settle the dispute by engaging in good faith with us in a process of mediation before commencing arbitration or litigation.
These Terms and Conditions shall be governed by and construed in accordance with the laws of Estonia and you hereby submit to the exclusive jurisdiction of the Estonian courts.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>22.</text>
          <text className="text-base font-semibold ml-2">Children</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        You may not use or engage with our website if you are under 18 years of age.  If you use or engage with the website and are under 18 years of age, you must have your parent’s or legal guardian’s permission to do so.  By using or engaging with the website, you also acknowledge and agree that you are permitted by your jurisdiction’s applicable law to use and/or engage with the website.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>23.</text>
          <text className="text-base font-semibold ml-2">Privacy & Cookies</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        For more information on how we collect your information and cookies, please refer to our Privacy Policy and Cookie Policy.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>24.</text>
          <text className="text-base font-semibold ml-2">Changes</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        We reserve the right to update and revise these Terms and Conditions at any time. You will know if these Terms and Conditions have been revised since your last visit to the website by referring to the "Effective Date of Current Policy" date at the top of this page. Your use of our Site constitutes your acceptance of these Terms and Conditions as amended or revised by us from time to time, and you should, therefore, review these Terms and Conditions regularly.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>25.</text>
          <text className="text-base font-semibold ml-2">Electronic Communications</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        When you visit the Site or send us e-mails, you are communicating with us electronically. In so doing, you consent to receive communications from us electronically. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communication is in writing.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>26.</text>
          <text className="text-base font-semibold ml-2">Severability</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        If any of these Terms and Conditions shall be deemed invalid, void, or for any reason unenforceable, that term shall be deemed severable and shall not affect the validity and enforceability of any remaining terms or conditions.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>27.</text>
          <text className="text-base font-semibold ml-2">Assignment</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        We shall be permitted to assign, transfer, or subcontract our rights and obligations under these terms without your consent or any notice to you. You shall not be permitted to assign, transfer, or subcontract any of your rights and obligations under this agreement.
        </p>
        <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>28.</text>
          <text className="text-base font-semibold ml-2">Force Majeure</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        GWallet is not liable for any delays caused by circumstances beyond GWallet’s control, e.g. general labor dispute, extreme weather, acts of war, fire, lightning, terrorist attacks, changed governmental orders, technical problems, defects in power- /tele-/computer communications or other communication and defects or delays in the service by sub-suppliers due to circumstances set forth above. Such circumstances shall result in relief from damages and other measures. If any such situation should arise, GWallet shall inform the Customer accordingly both at the beginning and the end of the period for the current situation. If the situation has lasted for more than two months, both the Customer and GWallet are entitled to terminate the purchase with immediate effect.
         </p>
         <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>29.</text>
          <text className="text-base font-semibold ml-2">Entire Agreement</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        These Terms and Conditions set forth the entire understanding and agreement between you and GWallet concerning the subject matter herein and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written concerning the Site. A printed version of these Terms and Conditions and any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms and Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. Any rights not expressly granted herein are reserved. You may not assign the Terms and Conditions, or assign, transfer or sublicense your rights therein. A failure to act concerning a breach by you or others does not waive GWallet's right to act concerning subsequent or similar breaches.
         </p>
         <div className="text-left text-white ml-12 mt-8 mb-4">
          <text>30.</text>
          <text className="text-base font-semibold ml-2">Term and Termination</text>
        </div>
        <p className="text-secondaryGray text-left mt-2">
        These Terms and Conditions set forth the entire understanding and agreement between you and GWallet concerning the subject matter herein and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written concerning the Site. A printed version of these Terms and Conditions and any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms and Conditions to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. Any rights not expressly granted herein are reserved. You may not assign the Terms and Conditions, or assign, transfer or sublicense your rights therein. A failure to act concerning a breach by you or others does not waive GWallet's right to act concerning subsequent or similar breaches.
         </p>

      </div>
      <Footer marginTop="100px" />
    </div>
  );
}

export default TermOfUse;
