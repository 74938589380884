import React, { useEffect, useState } from "react";
import { Prev } from "react-bootstrap/PageItem";
import {
  getDisputeMessages,
  resolveDispute,
  sendDisputMessage,
  socket,
  uploadP2pDisputeFile,
} from "services/p2pServives";
import { getSelectedKeys } from "services/trannsactionServices";

function useP2pDispute(tradeId, setMessages, chatMessages) {
  const init: any = [];
  const [state, setState] = useState({
    loading: false,
    message: "",
    me: getSelectedKeys().public,
    sending: false,
    files: init,
    uploading: false,
    file: "",
    selectedImgs: [],
    isModalOpen: false,
  });
  useEffect(() => {
    if (chatMessages.length < 1) {
      initialize();
    }

    socket.on("onChatNewMessage", updateMessage);
    return ()=>{
      socket.removeListener("onChatNewMessage");
    }
  }, []);

  const updateMessage = (message) => {
    const { messageObj } = message;
    setMessages(messageObj, true);
  };

  const initialize = () => {
    setState((prev)=>({...prev, loading: true }))
      getDisputeMessages({ tradeId: tradeId, skip: 0, take: 5 })
        .then((data) => {
          setMessages(data.data?.chat?.reverse(), false);
          setState((prev) => ({
            ...prev,
            loading: false,
          }));
        })
        .catch((e) => {
          // console.log(e);
          setState((prev) => ({ ...prev, loading: false }));
        });
  };

  const getFiles = () => {
    let files = "";
    state.files.forEach((file) => {
      files === "" ? (files = file) : (files += `,${file}`);
    });
    return files;
  };

  const sendMessage = () => {
    if (state.sending) return;
    if (state.message === "" && state.files.length === 0) return;
    setState((prev) => ({ ...prev, sending: true }));

    sendDisputMessage({
      tradeId: tradeId,
      message: state.message,
      fileNames: getFiles(),
    })
      .then((data) => {
        setState((prev) => ({
          ...prev,
          sending: false,
          files: [],
          message: "",
        }));
      })
      .catch((e) => {
        // console.log(e.response);
        setState((prev) => ({ ...prev, sending: false }));
      });
  };

  const setMessage = (e) => {
    const { value } = e.target;
    setState((prev) => ({ ...prev, message: value }));
  };

  const uploadFile = (e) => {
    let { files } = e.target;
    if (!files[0]) return;
    setState((prev) => ({ ...prev, sending: true }));
    uploadP2pDisputeFile(files[0])
      .then((data) => {
        let inputElement = document.getElementById("textInput");
        inputElement?.focus();
        setState((prev) => ({
          ...prev,
          sending: false,
          files: [...prev.files, data.data.filename],
        }));
      })
      .catch((e) => {
        // console.log(e.response);
        setState((prev) => ({ ...prev, sending: false }));
      });
  };

  const removeUpload = (file) => {
    const files = [...state.files];
    let newFiles = files.filter((item) => item === file);
    if (files.length === 1 && files[0] === file) {
      newFiles = [];
    }
    setState((prev) => ({ ...prev, files: newFiles }));
  };

  const selectFile = () => {
    const input = document.getElementById("p2pDisputeFile");
    input?.click();
  };

  const openImage = (files) =>
    setState((prev) => ({ ...prev, selectedImgs: files, isModalOpen: true }));

  const closeModal = () =>
    setState((prev) => ({ ...prev, isModalOpen: false }));

  return {
    ...state,
    setMessage,
    uploadFile,
    selectFile,
    sendMessage,
    openImage,
    closeModal,
    removeUpload,
  };
}

export default useP2pDispute;
