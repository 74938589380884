import * as React from "react";
import { PriceForm } from "./PriceForm";
import { LimitationsFom } from "./LimitationsForm";
import Loader from "react-loader-spinner";
import { PaymentInformationForm } from "./PaymentInformationForm";
import useAdd from "screens/hooks/P2P/useAdd";
import SelectCoin from "components/molecular/P2P/CreateAd/SelectCoin";
import { Button } from "components/global";

export const AdLeftCol = (props: any) => {
  const {
    unitPrice,
    unitPriceVal,
    minQty,
    maxQty,
    total_amount,
    total_amountVal,
    minQtyVal,
    maxQtyVal,
    assetCode,
    location,
    locationVal,
    priceInCurrency,
    bank_name,
    bank_account_number,
    priceWithFee,
    accountTitle,
    submitting,
    bank_nameVal,
    accountTitleVal,
    bank_account_numberVal,
    typeIndex,
    currency,
    handleInputChange,
    SelectCoinName,
    submit,
    setAddType,
    bitStampRate,
    loadingRates,
    balanceVal,
    bankInfo,
    bankConfirmed,
    bankConfirmVal,
    email,
    emailVal,
    payment_method,
    paymentMethods,
  } = useAdd(props.buy);
  const renderSubmitting = () => (
    <div className="flex justify-center items-center">
      <Loader type="Bars" color="#ffffff" height={15} width={15} />
    </div>
  );
  const addTypeButton = (text, onClick, index) => {
    const active = typeIndex === index;
    return (
      <button
        onClick={() => onClick(index)}
        className={`focus:outline-none ${index===1&&'ml-4'} text-xs flex items-center ${
          active ? "text-white" : "text-gray4"
        }`}
      >
        <div
          className={`p-1 rounded-full mr-1 ${active ? "bg-accent" : "border"}`}
        ></div>
        {text}
      </button>
    );
  };

  return (
    <div>
      <h1 className="text-white font-base mt-6">
        Create Ad
      </h1>

      <div className="flex mb-6">
        {addTypeButton("Basic Ad", setAddType, 0)}
        {addTypeButton("Detailed Ad", setAddType, 1)}
      </div>
      <div className="w-full lg:w-1/2 xl:w-1/2">
        <SelectCoin
          short={assetCode}
          outline={false}
          onSelect={SelectCoinName}
        />
      </div>
      <PaymentInformationForm
        bankDetail={{
        buy: props.buy,
        bank_name,
        bank_nameVal,
        bank_account_number,
        accountTitle,
        accountTitleVal,
        bank_account_numberVal,
        bankInfo,
        bankConfirmed,
        bankConfirmVal
      }}
      handleInputChange={handleInputChange}
      paymentMethods={paymentMethods}
      payment_method={payment_method}
      email={email}
      emailVal={emailVal}
      />
      <PriceForm
        buy={props.buy}
        unitPrice={unitPrice}
        unitPriceVal={unitPriceVal}
        handleInputChange={handleInputChange}
        assetCode={assetCode}
        currency={currency}
        priceInCurrency={priceInCurrency}
        priceWithFee={priceWithFee}
        bitStampRate={bitStampRate}
        loadingRates={loadingRates}
        total_amount={total_amount}
        typeIndex={typeIndex}
        balanceVal={balanceVal}
      />
      {typeIndex===1&&<LimitationsFom
        buy={props.buy}
        minQty={minQty}
        maxQty={maxQty}
        total_amount={total_amount}
        total_amountVal={total_amountVal}
        minQtyVal={minQtyVal}
        maxQtyVal={maxQtyVal}
        handleInputChange={handleInputChange}
        location={location}
        locationVal={locationVal}
        assetCode={assetCode}
        balanceVal={balanceVal}
      />}
     
      <Button
        onClick={submit}
        styles={["bg-accent text-xs py-2 mt-8 mb-8 w-20 relative"]}
      >
        {!submitting && "Create Ad"}
        {submitting && renderSubmitting()}
        {!bankConfirmed&&<div className="absolute inset-0 rounded bg-white cursor-not-allowed" style={{opacity: 0.3}}></div>}
      </Button>
    </div>
  );
};
