import * as React from "react";
import SmallNavButton from "components/atomic/TopNavigationBar/SmallNavButton";

const SmallNavButtons = ({ hamburger, onNav, auth }) => {
  return (
    <div style={{ display: hamburger ? "block" : "none" }}>
      <div className="absolute navCollapsed left-0 bg-primary z-40 p-5 lg:hidden xl:hidden block w-full">
        {!auth && <SmallNavButton to="/login" title="Login" onNav={onNav} />}
        {!auth && (
          <SmallNavButton to="/register" title="Register" onNav={onNav} />
        )}
        <SmallNavButton to="/market" title="Markets" onNav={onNav} />
        <SmallNavButton to="/exchange" title="Exchange" onNav={onNav} />
        <SmallNavButton to="/p2p" title="P2P" onNav={onNav} />
        <SmallNavButton to="/swap" title="Swap" onNav={onNav} />
        <SmallNavButton to="/walletScreen" title="Wallets" onNav={onNav} />
        <SmallNavButton to="/profileScreen" title="Profile" onNav={onNav} />
      </div>
    </div>
  );
};

export default SmallNavButtons;
