import * as React from 'react';

export const RadioButton = (props:any) =>{
    return(
        <div className="flex items-center">
            <input className=" mr-2 mt-2 mb-2 h-3 w-3" type = "radio" value={props.value} name={props.name} checked={props.selected} onChange={props.onChange} />
            <label className="text-white text-xs font-regular">{props.label}
            </label>
          </div>
    );
}