import * as React from "react";

export const DataCol = (props: any) => {
  return (
    <div>
      <p className="text-secondaryGray text-sm font-regular">{props.heading}</p>
      <p className="text-white text-sm font-regular">{props.text}</p>
    </div>
  );
};
