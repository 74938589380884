import * as React from "react";
import { fileIcon, closeButton } from "assets/images/index";
import { useAlert } from "react-alert";
import { profileStatus } from "utils/constants";
import "styles/inputFile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

export const SelfieBlock = ({
  onUpload,
  onSelect,
  status,
  label,
  showAlert,
  valMsg,
}) => {
  let [file, setFile] = React.useState(false);
  let [visible, setVisible] = React.useState(false);
  const [validation, setValidation] = React.useState(false)
  const { user }:any = useSelector((state:RootState)=>state.profile)
  const alert = useAlert();
  let handleChange = (e) => {
    let { files } = e.target;
    const preview = document.getElementById("profileImg");
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        // convert image file to base64 string
        if (preview) preview["src"] = reader.result;
      },
      false
    );

    if (files[0]) {
      onSelect(files[0], files[0].size);
      reader.readAsDataURL(files[0]);
      setFile(true);
      setVisible(files[0].name);
    }
  };
  React.useEffect(()=>{
    setVisible(user.file)
  },[])

  let selectFile = (e) => {
    if (status === profileStatus.pending) {
      showAlert("Already Submitted");
      return;
    }
    let input = document.getElementById("file1");
    input?.click();
  };

  let handleUpload = () => {
    if (status === "pending") {
      alert.removeAll();
      alert.show("Status Pending! Can't edit document.");
      return;
    }
    onUpload();
  };

  return (
    <div className="w-full">
      <input
        type="file"
        name="file"
        onChange={handleChange}
        id="file1"
        className="inputfile"
        accept="image/x-png,image/gif,image/jpeg"
      />
      <p className="text-secondaryGray text-sm mb-1">{label}</p>
      <button
        type="button"
        className="focus:outline-none h-10 w-full border border-primary flex items-center"
        onClick={selectFile}
      >
        <div className="bg-primary px-3 h-full flex items-center">
          <FontAwesomeIcon icon={faCloudUploadAlt} color="#ffffff" />
        </div>
        {!visible && (
          <p className="text-xs text-secondaryGray ml-2">Upload your Photo</p>
        )}
        {visible && (
          <div className="flex mb-2 items-center p-1">
            <img src={fileIcon} className="h-4 w-4" />
            <p className="text-xs ml-2 font-regular text-white">{visible}</p>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setVisible(false);
              }}
            >
              <img src={closeButton} className="ml-2 w-3 h-3" />
            </button>
          </div>
        )}
      </button>
      <small className="text-red italic">{valMsg}</small>
      {/* <div className="text-white font-regular text-xs md:text-sm lg:text-sm xl:text-sm ml-4">
                <p>Your Selfie</p>
                <div className="flex items-center">
                <button className="focus:outline-none mt-5 bg-blue text-xxs md:text-xs lg:text-xs xl:text-xs flex items-center p-2">
                    <img src={Images.cameraIcon} className="mr-2 h-3 w-4"/>
                    Take Photo
                </button>

                <button onClick={selectFile} className="focus:outline-none mt-5 bg-green ml-2 text-xxs md:text-xs lg:text-xs xl:text-xs flex items-center px-5 p-2">
                    <img src={uploadIcon} className="mr-2 h-4 w-3"/>
                    Upload
                </button>
                </div>
            </div> */}
    </div>
  );
};
