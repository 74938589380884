import db from "config/DbConfig";

const getWallets = async () => {
  return new Promise((resolve, reject) => {
    try{db.wallets.toArray((wallets) => {
      resolve(wallets);
    });}catch(e){}
  });
};

const getWalletById = async (wallet) => {
  return new Promise((resolve, reject) => {
    db.wallets.get(wallet, (coin) => {
      resolve(coin);
    });
  });
};


const removeWallet = async (id) => {
 return new Promise((resolve, reject)=>{
  db.wallets.delete(id).then(data=>{
    
    resolve("Success")
  }).catch(e=>{
    
    reject('error')
  });
 })
};

const addWallet = async (wallet) => {
  try {
    let id = await db.wallets.add(wallet);
    return id;
  } catch (error) {
    // console.log(error);
  }
};

const updateWallet = async (id, wallet) => {
  try {
    
    let updated = await db.wallets.update(id, wallet);
  } catch (error) {
    
    // console.log(error);
  }
};

const encryptWalletSecret = async (secret, encrypted) => {
  try {
    await db.wallets.where("private").equals(secret).modify({private: encrypted});
  } catch (error) {
    // console.log(error);
  }
};

const getNoOfWallets = async () => {
  try {
    return await db.wallets.count();
  } catch (error) {
    // console.log(error);
  }
};

const addAsset = async (coin) => {
  try {

    return await db.coins.add(coin);
    
  } catch (error) {
    // console.log(error);
  }
};

const getAssetsByWallet = async (wallet) => {
  return new Promise((resolve, reject) => {
    db.coins.get({ walletId: wallet }, (wallets) => {
      resolve(wallets);
    });
  });
};

const getAssetByName = async (asset) => {
  return new Promise((resolve, reject) => {
    db.coins.get({ short: asset }, (coin) => {
      resolve(coin);
    });
  });
};

const saveInitial = async (currencies, assets) => {
  try {
    if (assets && assets.length > 0) {
      let newAssets = removeId(assets);
      db.assets.clear()
      db.assets.bulkAdd(newAssets);
    }
    if (currencies && currencies.length > 0) {
      let newCurrencies = removeId(currencies);
      db.currencies.clear()
      db.currencies.bulkAdd(newCurrencies);
    }
  } catch (error) {
    // console.log(error);
  }
};

const getInitialData = async () => {
  return new Promise((resolve, reject) => {
    try {
      db.currencies.toArray((currencies) => {
        db.assets.toArray((assets) => {
            resolve({ currencies, assets });
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const clearWallets = async () => {
  return new Promise((resolve, reject) => {
    try {
      db.wallets.clear()
      resolve("SUCCESS")
    } catch (error) {
      reject(error);
    }
  });
};

let removeId = (data) => {
  let newData: any = [];
  data.forEach((element) => {
    if (element.id) {
      delete element["id"];
    }
    newData.push(element);
  });
  return newData;
};

export {
  getWallets,
  addWallet,
  getNoOfWallets,
  updateWallet,
  addAsset,
  getAssetsByWallet,
  getAssetByName,
  saveInitial,
  getInitialData,
  getWalletById,
  removeWallet,
  encryptWalletSecret
};
