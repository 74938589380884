import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addZakatEntry, fetchZakatPayment } from "services/httpServices";
import {
  getSelectedKeys,
  getSelectedSecret,
  sendNonNativeBalance,
} from "services/trannsactionServices";
import { RootState } from "store/reducers";
import { sendBalanceErrors } from "utils/constants";
import { useAlert } from "react-alert";
import { zakatHistoryStatus } from "store/models/Generic";
import moment from "moment";

interface IAsset {
  image: string;
  short: string;
  balance: number;
  zakat: string;
  currentRate: number;
  issuer: string;
}
let assetInit: IAsset[] = [];
const useManualZakat = (callback, setPercentage) => {
  const coins = useSelector((state: RootState) => state.transaction.coins);
  const alert = useAlert();
  const [state, setState] = useState({
    assets: assetInit,
    loading: true,
    submitting: false,
    address: "",
    percentage: 0,
    shouldClose: false,
  });

  useEffect(() => {
    initialize();
  }, []);

  const handleClose = () => {
    setState(prev=>({...prev, shouldClose: true}))
    callback()
  }

  const initialize = () => {
    fetchZakatPayment()
      .then((data) => {
        let temp: IAsset[] = [];
        console.log(data);
        coins.forEach((coin: any) => {
          const zakat = (data.data.percentage / 100) * Number(coin.balance);
          let unpayed = true;
          const history = data.data.history.find(
            (record) => record.coinCode === coin.short?.toUpperCase()
          );
          if (history) {
            unpayed = false;
          }

          if (coin.balance > 0 && unpayed) {
            temp.push({
              image: coin.coinIcon,
              short: coin.short?.toUpperCase(),
              balance: coin.balance,
              zakat: zakat.toFixed(7),
              currentRate: coin.currentRate,
              issuer: coin.issuer,
            });
          }
        });
        setPercentage(data.data.percentage)
        setState((prev) => ({
          ...prev,
          assets: temp,
          address: data.data.zakatAddress,
          percentage: data.data.percentage,
          loading: false,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const payZakat = async () => {
    if (state.submitting) {
      return;
    }
    setState((prev) => ({ ...prev, submitting: true }));
    const secret = getSelectedSecret();
    const publicKey = getSelectedKeys().public;
    const assetsAll = [...state.assets];
    for (let i = 0; i < assetsAll.length; i++) {
      if(state.shouldClose) break;
      const element = assetsAll[i];
      if (element.zakat !== "" && Number(element.zakat) > 0) {
        await sendNonNativeBalance(
          secret,
          state.address,
          element.zakat,
          "Manual zakat",
          element.short,
          element.issuer
        )
          .then((res) => {
            alert.show(element.short + ": Zakat has been paid successfully", { type: "success" });
            setState((prev) => {
              const assets = [...prev.assets];
              const index = assets.findIndex(item=>item.short === element.short)
              assets.splice(index, 1);
              return { ...prev, assets: assets };
            });
            addZakatEntry({
              account: publicKey,
              distAccount: state.address,
              totalAmount: element.balance,
              zakatAmount: Number(element.zakat),
              zakatPercentage: (Number(element.zakat)/element.balance)*100,
              stellarTxId: res.id,
              memo: "Manual Zakat",
              year: moment().year(),
              coinCode: element.short,
              status: zakatHistoryStatus.unpaid,
              new: true,
            }).catch((e) => {
              console.log(e.response);
            });
          })
          .catch((e) => {
            console.log(e.message);
            alert.show(
              getSendMessage(e, `Unable to pay zakat`, element.short),
              { type: "error" }
            );
          });
      }
    }
    setState((prev) => ({ ...prev, submitting: false }));
    callback();
  };
  const getSendMessage = (e, defMsg, coin) => {
    let message = coin + ": ";
    if (e.response?.status === 504) {
      return coin + ": Request timeout please try again";
    }
    if (e.message === "The destination account does not exist!") {
      return coin + ": Destination account does not exist!";
    }
    try {
      message +=
        sendBalanceErrors[
          e?.response?.data?.extras?.result_codes?.operations[0]
        ] || defMsg;
    } catch (err) {
      message += defMsg;
    }
    return message;
  };

  const setTotal = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, totalAmount: value, payableAmount: value }));
  };
  const setAmount = (e, index) => {
    const { value } = e.target;
    setState((prev) => {
      let assets = [...prev.assets];
      assets[index].zakat = value;
      return {
        ...prev,
        assets: assets,
      };
    });
  };
  return { ...state, setTotal, setAmount, payZakat, handleClose };
};

export default useManualZakat;
