import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { GenericBtnStyle } from "styles";
import useSendPayment from "./hooks/useSendPayment";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import ConfirmPassword from "./ConfirmPassword";
import { fetchFeeWithLevel, fetchInternalFee } from "services/httpServices";
import { getPreferences } from "services/storageServices";
import Loader from "react-loader-spinner";
import { useHistory, useLocation } from "react-router-dom";
import { memoTypes } from "utils/constants";

export const SendScreen = () => {
  let location = useLocation();
  let { state }: any = location;
  let { transaction, assets } = useSelector((state: RootState) => state);
  let asset = assets.assets.filter(
    (item) => item.short == state.asset.short.toLowerCase() && item.issuer == state.asset.issuer
  );

  const {
    handleSubmit,
    handleInputChange,
    sendPayment,
    closeModal,
    state: {
      submitting,
      isConfirmOpen,
      isUserAgreed,
      isTargetFunded,
      memoIndex,
    },
    inputs: { address, amount, addressVal, amountVal, fundVal, memo, memoVal },
    agree,
    calculateFee,
    setMemoIndex,
  } = useSendPayment(transaction.coins[transaction.selected].balance, asset);

  const btnRender = (text, index) => (
    <small
      onClick={() => setMemoIndex(index)}
      className={`rounded ${
        index === memoIndex
          ? "text-white bg-accent"
          : "border border-secondaryGray text-accent"
      } cursor-pointer w-12 h-6 flex justify-center items-center mr-2`}
    >
      {text}
    </small>
  );

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit}
        className="border border-primary md:block lg:block lg:w-4/6 xl:w-4/6 h-screen p-5"
      >
        <div className="w-full md:w-4/6 lg:w-4/6 xl:w-4/6">
          <input
            className={styles.InputStyle + ["border-primary border-b"]}
            type="text"
            placeholder="Recipient's Address"
            name="address"
            value={address}
            onChange={handleInputChange}
            autoComplete="off"
          ></input>

          <text className={styles.textStyle5}>{addressVal}</text>

          <div className="flex border-primary border-b items-center justify-between w-full">
            <input
              className={styles.InputStyle2}
              type="number"
              placeholder="Amount"
              name="amount"
              value={amount}
              step="any"
              onChange={handleInputChange}
            ></input>
            <text className="flex items-center text-secondaryGray text-xxxs md:text-xs lg:text-xs xl:text-xs mt-2">
              Available Balance
              <span className="text-accent ml-1 text-xxxs md:text-xs lg:text-xs xl:text-xs">
                {transaction.coins[transaction.selected].balance}{" "}
                {transaction.coins[transaction.selected].short}
              </span>
            </text>
          </div>

          {amountVal ? (
            <text className={styles.textStyle5}>{amountVal}</text>
          ) : (
            <text className={styles.textStyle2}>
              Minimum Withdrawal: 0.00100000{" "}
              {transaction.coins[transaction.selected].short}
            </text>
          )}
          <div className="mt-4">
            <div className="flex font-semibold text-sm">
              {btnRender('None', 0)}
              {btnRender('Text', 1)}
              {btnRender('Id', 2)}
              {btnRender('Hash', 3)}
            </div>
            {memoIndex !== 0 && <div>
            <input
            className={styles.InputStyle + ["border-primary border-b"]}
            type="text"
            placeholder={`Memo: ${memoTypes[memoIndex]}`}
            name="memo"
            value={memo}
            onChange={handleInputChange}
            autoComplete="off"
          ></input>
          <text className={styles.textStyle5}>{memoVal}</text>
          </div>}
          {memoIndex===0&&<div className="text-sm font-semibold text-accent mt-2">No Memo</div>}
          </div>
        </div>
        <div className="mt-4 w-3/6 md:w-4/6 lg:w-3/6 xl:w-2/6">
          <table>
            <tr>
              <td>
                <text className={styles.textStyle3}>Transaction Fee:</text>
              </td>
              <td>
                {true && (
                  <text className={styles.textStyle4}>
                    {calculateFee()} {asset[0].short.toUpperCase()}
                  </text>
                )}
                {false && (
                  <div className="mt-1 ml-4">
                    <Loader
                      type="Oval"
                      color="#00BFFF"
                      height={14}
                      width={14}
                    />
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="text-right">
                <text className={styles.textStyle3}>Recipient Will Get:</text>
              </td>
              <td>
                {true && (
                  <text className={styles.textStyle4}>
                    {parseFloat("" + amount || "0") - calculateFee()}{" "}
                    {transaction.coins[transaction.selected].short}
                  </text>
                )}
                {false && (
                  <div className="mt-1 ml-4">
                    <Loader
                      type="Oval"
                      color="#00BFFF"
                      height={14}
                      width={14}
                    />
                  </div>
                )}
              </td>
            </tr>
          </table>
        </div>
        {!isTargetFunded && (
          <Alert proceed={agree} fundVal={fundVal} isAgreed={isUserAgreed} />
        )}
        <button
          type="submit"
          className={`${GenericBtnStyle} w-2/6 bg-accent text-xs py-2 mt-8 md:w-1/6 lg:w-1/6 xl:w-1/6 uppercase`}
        >
          {submitting ? (
            <div>
              {" "}
              <FontAwesomeIcon icon={faSyncAlt} color="#ffffff" spin />{" "}
              {" Sending..."}
            </div>
          ) : (
            "Send"
          )}
        </button>
      </form>

      <ConfirmPassword
        isOpen={isConfirmOpen}
        onSuccess={sendPayment}
        onClose={closeModal}
      />
    </React.Fragment>
  );
};

function Alert({ proceed, isAgreed, fundVal }) {
  return (
    <div
      className="bg-primary border-l-4 border-accent text-white p-4 mt-8"
      role="alert"
    >
      <p className="font-bold">Destination is unfunded!</p>
      <p className="text-secondaryGray">
        1.5 XLM will be deducted from your wallet to activate the destination
        wallet
      </p>
      <div className="flex-initial flex flex-col">
        <div className="flex items-center">
          <input type="checkbox" onClick={proceed} checked={isAgreed} />
          <p
            onClick={proceed}
            className="cursor-default text-sm text-secondaryGray ml-2"
          >
            Proceed sending balance.
          </p>
        </div>
        <p className="text-red text-xs italics">{fundVal}</p>
      </div>
    </div>
  );
}

const styles = {
  textStyle: "ml-4 text-xxxs md:text-sm lg:text-lg xl:text-lg",
  textStyle3: "text-secondaryGray text-xxxs md:text-sm lg:text-sm xl:text-sm",
  textStyle2:
    "text-secondaryGray text-xxxs md:text-xs lg:text-xs xl:text-xs mt-2",
  textStyle5: "text-red text-xxxs md:text-xs lg:text-xs xl:text-xs mt-2",
  textStyle4: "text-white ml-4 text-xxxs md:text-xs lg:text-xs xl:text-xs",
  linkStyle: "text-secondaryGray p-4 hover:bg-accent hover:text-white",
  InputStyle:
    "text-xxxs md:text-lg xl:text-lg py-3 w-full bg-transparent placeholder-primary text-secondaryGray  font-Muli leading-tight focus:outline-none ",
  InputStyle2:
    "text-xxxs md:text-lg xl:text-lg py-3 bg-transparent placeholder-primary text-secondaryGray  font-Muli leading-tight focus:outline-none ",
};
