import React, { useState, useEffect } from "react";
import { DropDown } from "./DropdownMenu";
import CustomInput from "components/global/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import {
  searchMarket,
  selectMarketCoin,
} from "../../../store/actions/MarketActions";
import { RootState } from "store/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

export const MenuBar = () => {
  const { markets, selected } = useSelector((state: RootState) => state.market);
  const dispatch = useDispatch();
  let [input, setInput] = useState();

  let select = (index) => (selected.name === index ? " border-accent" : "");
  let selectDrop = () =>
    getSlicedData().includes(selected.name) ? " border-accent" : "";

  let setSelected = (e, coin) => {
    e?.preventDefault();
    let selected = markets.find((item) => item.name === coin);
    if (selected) {
      dispatch(selectMarketCoin(selected));
    }
  };

  let handleChange = (e) => {
    let value = e.target.value;
    setInput(value);
    dispatch(searchMarket(value));
  };
  const getFirst = () => {
    let temp: any = [];
    for (let i = 0; i < 3; i++) {
      const element = markets[i];
      if (element) {
        temp.push(element.name);
      }
    }
    return temp;
  };

  const getSlicedData = () => {
    let temp: any = [];
    let test = [];
    for (let i = 3; i < markets.length; i++) {
      const element = markets[i];
      if (element) {
        temp.push(element.name);
      }
    }
    return temp;
  };

  return (
    <div className="block flex border-gray3 border-b-2 items-center justify-between">
      <div>
        <ul className="flex items-center">
          {getFirst().map((item, index) => (
            <li key={index} className={styles.linkStyle + select(item)}>
              <a href="#" onClick={(e) => setSelected(e, item)}>
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <div>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FontAwesomeIcon
                icon={faSearch}
                className="text-gray-500 text-xs"
              />
            </div>
            <input
              type="text"
              className="bg-transparent border border-secondaryGray pl-8 text-xxxs lg:text-xs xl:text-xs p-2 placeholder-secondaryGray text-secondaryGray focus:outline-none"
              placeholder="Search coin name"
              value={input}
              onChange={handleChange}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
};
const styles = {
  linkStyle:
    "mr-4 text-xxxs font-bold md:text-base hover:text-accent lg:text-base xl:text-base md:mr-20 lg:mr-20 xl:mr-20 py-3 hover:border-accent border-transparent border-b-2 text-white",
};
