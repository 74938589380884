import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  getData,
  updateTransaction,
  getCurrencyExchange,
} from "services/check-out-api";
import { useAlert } from "react-alert";
import { setMemo } from "store/actions/GenericActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { getSelectedKeys } from "services/trannsactionServices";
import { getWalletById } from "services/dbServices";
import ConfirmPassword from "./ConfirmPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import useSendPayment from "./hooks/useSendPayment";
const check_out_Icon = require("../assets/images/check-out-image-1.png");

export const CheckoutScreen = (props: any) => {
  const { auth, transaction, assets }: any = useSelector(
    (state: RootState) => state
  );
  let alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();

  const [res, setRes]: any = useState({});
  const [showScreen, setShowScreen] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [confirmOpen, setOpen] = useState(false);
  const [cancelLoading, setcancelLoading] = useState(false);
  const [asset, setAsset]: any = useState(
    assets.assets.filter((item) => item.short == "usdt")
  );
  const [userBalance, setUserBalance]: any = useState(
    transaction.coins.filter((item) => item.short.toLowerCase() == "usdt")
  );
  const [code, setCode]: any = useState("USDT");
  const [price, setPrice]: any = useState(0);
  const [gpayFee, setGpayFee]: any = useState(0);
  const [netPrice, setNetPrice]: any = useState(0);
  const [transactionPrice, setTransactionPrice]: any = useState(0);
  const [paymentMethod, setPaymentMethod]: any = useState("instant");

  const findUSDT = assets.assets.find((coin) => coin.short === "usdt");

  const setAssetFromDropdown = async (value) => {
    let codeData = value[0].toLowerCase();
    let issuerData = value[1];

    // write function here to get currency vs currency
    let assestResponse: any;
    if (codeData == "xlm") {
      assestResponse = assets.assets.filter((item) => item.short == codeData);
    } else {
      assestResponse = assets.assets.filter(
        (item) => item.short == codeData && item.issuer == issuerData
      );
    }

    let resData: any = await getCurrencyExchange(assestResponse[0].coingeckoId);

    setPrice(
      (
        (1 / resData.data[assestResponse[0].coingeckoId].usd) *
        res?.payableAmount
      ).toFixed(7)
    );

    setGpayFee(
      (
        (1 / resData.data[assestResponse[0].coingeckoId].usd) *
        res?.transactionFee
      ).toFixed(7)
    );

    setNetPrice(
      (
        (1 / resData.data[assestResponse[0].coingeckoId].usd) *
        res?.totalAmount
      ).toFixed(7)
    );

    setTransactionPrice(
      (
        res.transactionFee / resData.data[assestResponse[0].coingeckoId].usd
      ).toFixed(7)
    );

    setCode(codeData);

    if (codeData && issuerData) {
      let assetData = assets.assets.filter(
        (item) => item.short == codeData && item.issuer == issuerData
      );
      setAsset(assetData);
      let userBalanceData = transaction.coins.filter(
        (item) =>
          item.short.toLowerCase() == codeData && item.issuer == issuerData
      );
      setUserBalance(userBalanceData);
    } else {
      let assetData = assets.assets.filter((item) => item.short == "xlm");
      setAsset(assetData);
      let userBalanceData = transaction.coins.filter(
        (item) => item.short.toLowerCase() == "xlm"
      );
      setUserBalance(userBalanceData);
    }
  };

  const {
    sendPayment,
    setInputs,
    inputs: { creating, transactionResponse },
  } = useSendPayment(userBalance[0]?.balance, asset);

  useEffect(() => {
    if (params) {
      getMemoData(params?.memo);
    }
  }, [params]);

  const storeParamInRedux = (params) => {
    dispatch(setMemo(params));
  };

  const NotLoggedInError = () => {
    alert.error("Please login first to proceed with payment");
  };

  const getMemoData = async (memo: any) => {
    let response = await getData(memo);
    if (response && response?.data?.length <= 0) {
      history.replace({ pathname: `/walletScreen` });
    } else if (response?.data[0].status == "Pending") {
      setShowScreen(true);
      storeParamInRedux(params?.memo);
      setRes(response?.data[0]);
      let selectedId = getSelectedKeys().public;
      let selectedWallet: any = await getWalletById(selectedId);
      setWallet(selectedWallet?.public);
    } else {
      alert.error("Payment Declined!");
      storeParamInRedux("");
      history.replace({ pathname: `/walletScreen` });
    }
  };

  const CancelTransaction = async (params, data) => {
    let response: any = await updateTransaction(params, data);
    if (response) {
      setcancelLoading(false);
      storeParamInRedux("");
      history.replace({ pathname: `/` });
    } else {
      setcancelLoading(false);
      storeParamInRedux("");
      history.replace({ pathname: `/` });
    }
  };

  const CreatePayment = async () => {
    setInputs((prev) => ({
      ...prev,
      creating: true,
      amount: price,
      transactionFee: Number(transactionPrice) * 0,
      memo: res?.memo,
      address:
        paymentMethod == "instant"
          ? "GDHON6OXLN7WL3ZKH2AEJ6UUPPLWH2MXIZAE65FWVF7LAHRIV4MZOJ3X"
          : "GDHON6OXLN7WL3ZKH2AEJ6UUPPLWH2MXIZAE65FWVF7LAHRIV4MZOJ3X",
    }));
  };

  useEffect(() => {
    if (creating) {
      sendPayment();
    }
  }, [creating]);

  useEffect(() => {
    if (transactionResponse) {
      updateTrx(transactionResponse);
    }
  }, [transactionResponse]);

  const currencyConvertor = async () => {
    // write function here to get currency vs currency
    // let currencyResponse: any = await getCurrencyExchange("stellar");

    setPrice(res?.payableAmount?.toFixed(7));
    setGpayFee(res?.transactionFee?.toFixed(7));
    setNetPrice(res?.totalAmount?.toFixed(7));
    setTransactionPrice(res?.transactionFee?.toFixed(7));
  };

  useEffect(() => {
    if (res) {
      currencyConvertor();
      if (res.paymentType == "instant" || res.paymentType == "escrow") {
        setPaymentMethod(res.paymentType);
      }
    }
  }, [res]);

  const updateTrx = async (transactionResponse) => {
    let data = {
      status: "Completed",
      orderStatus: "Pending",
      customerPublicKey: transactionResponse?.source_account,
      transactionHash: transactionResponse?.hash,
      paymentDetail: { payedIn: code, amountPayed: price },
      isInstant: paymentMethod == "instant" ? true : false,
    };
    let res: any = await updateTransaction(params, data);
    if (res && res.data != "Invalid Status") {
      storeParamInRedux("");
      history.replace({ pathname: `/walletScreen` });
    }
  };

  return (
    <>
      {showScreen && (
        <div
          // style={{ height: "83vh" }}
          className="flex flex-col text-white lg:flex-row pt-20 lg:-pt-20"
        >
          <div className="flex flex-col flex: 1 1 auto w-full lg:w-1/3 xl:w-1/2 items-center justify-center ">
            <img src={check_out_Icon} className="h-3/6 w-3/6" />
            <h1 className="font-semibold text-center tracking-wide text-lg mt-2">
              G Wallet is the safer, easier<br></br> way to pay
            </h1>
            <p className="text-center text-sm mt-1 color: rgb(112 112 112)">
              No matter were you shop, we keep your <br></br> financial
              information secure
            </p>
          </div>
          <div className="flex flex-col flex: 1 1 xl:auto w-full lg:w-2/3 xl:w-1/2 justify-center items-center lg:items-start mt-10 ml-2 lg:-mt-12">
            <h1 className="text-2xl font-semibold tracking-wider mb-2">
              Checkout
            </h1>
            <div
              // style={{ minWidth: "35rem" }}
              className="flex flex-col px-8 pt-6 pb-8 w-5/6 rounded-md bg-gray-900 bg-opacity-50"
            >
              <h1 className="text-yellow-600 text-lg mb-2 font-semibold">
                Summary
              </h1>

              <div className="flex flex-row border border-gray-700 px-3 py-5 rounded">
                <div className="flex flex-col">
                  <div className="flex flex-col sm:flex-row mb-4 lg:mb-2 2xl:mb-4">
                    <h1 style={{ minWidth: "10rem" }} className="font-bold">
                      Order#
                    </h1>
                    <h1 className="text-sm">{res?.orderNumber}</h1>
                  </div>
                  <div className="flex flex-col sm:flex-row lg:mb-2 2xl:mb-4">
                    <h1
                      style={{ minWidth: "10rem" }}
                      className="font-bold whitespace-nowrap"
                    >
                      Wallet Address
                    </h1>
                    <h1 className="text-sm break-all">{wallet}</h1>
                  </div>
                  <div className="flex flex-col sm:flex-row lg:mb-2 2xl:mb-4">
                    <h1
                      style={{ minWidth: "10rem" }}
                      className="font-bold whitespace-nowrap"
                    >
                      Service
                    </h1>
                    <h1 className="text-sm">{res?.service}</h1>
                  </div>
                  <div className="flex flex-col sm:flex-row lg:mb-2 2xl:mb-4 w-full">
                    <h1 style={{ minWidth: "10rem" }} className="font-bold">
                      Description
                    </h1>
                    <h1 className="text-sm text-clip">{res?.description}</h1>
                  </div>
                  <div className="flex flex-col sm:flex-row lg:mb-2 2xl:mb-4">
                    <h1 style={{ minWidth: "10rem" }} className="font-bold">
                      Net Price
                    </h1>
                    <h1 className="text-sm">
                      {(+netPrice)?.toFixed(4)} <b>{code?.toUpperCase()}</b>
                    </h1>
                  </div>
                  <div className="flex flex-col sm:flex-row lg:mb-2 2xl:mb-4">
                    <h1 style={{ minWidth: "10rem" }} className="font-bold">
                      GPay Processing Fee
                    </h1>
                    <h1 className="text-sm">
                      {(+gpayFee)?.toFixed(4)} <b>{code?.toUpperCase()}</b>
                    </h1>
                  </div>
                  <div className="flex flex-col sm:flex-row">
                    <h1 style={{ minWidth: "10rem" }} className="font-bold">
                      Gross Total
                    </h1>
                    <h1 className="text-sm">
                      {(+price)?.toFixed(4)} <b>{code?.toUpperCase()}</b>
                    </h1>
                  </div>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row justify-around">
                <div
                  className="flex"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "20px",
                  }}
                >
                  <h1 className="text-yellow-600 text-sm font-semibold">
                    Select Coin:
                  </h1>
                  <select
                    id="dropdown"
                    style={{
                      backgroundColor: "black",
                      marginLeft: "10px",
                      width: "100px",
                      outline: "none",
                      borderRadius: "5px",
                      padding: "2px",
                    }}
                    defaultValue={[
                      findUSDT.short.toUpperCase(),
                      findUSDT.issuer,
                    ]}
                    onChange={(e) =>
                      setAssetFromDropdown(e.target.value.split(","))
                    }
                  >
                    {assets.assets.map((val) => {
                      return (
                        val.manualIssued != true && (
                          <option value={[val.short.toUpperCase(), val.issuer]}>
                            {val.short.toUpperCase()}
                          </option>
                        )
                      );
                    })}
                  </select>
                </div>
                <div
                  className="flex"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "20px",
                  }}
                >
                  <h1 className="text-yellow-600 text-sm font-semibold">
                    Select Method:
                  </h1>
                  <select
                    id="dropdown"
                    style={{
                      backgroundColor: "black",
                      marginLeft: "10px",
                      width: "100px",
                      outline: "none",
                      borderRadius: "5px",
                      padding: "2px",
                    }}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  >
                    {(res?.paymentType === "instant" ||
                      res?.paymentType === "both") && (
                      <option value={"instant"}>Instant</option>
                    )}
                    {(res?.paymentType === "escrow" ||
                      res?.paymentType === "both") && (
                      <option value={"escrow"}>Escrow</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div
              // style={{ minWidth: "35rem" }}
              className="flex flex-col w-5/6 mb-5 lg:mb-0"
            >
              <h1 className="text-sm my-3">
                View{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="text-yellow-600"
                >
                  G Wallet Policies
                </a>{" "}
                & your payment method rights
              </h1>
              <div className="flex flex-row">
                {creating ? (
                  <button className="focus:outline-none mr-2 py-2 px-6 w-full bg-accent rounded text-white">
                    <FontAwesomeIcon icon={faSyncAlt} color="#ffffff" spin />
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      auth?.password == "" ? NotLoggedInError() : setOpen(true)
                    }
                    className="focus:outline-none mr-2 py-2 px-6 w-full bg-accent rounded text-white"
                  >
                    PAY NOW
                  </button>
                )}
                {cancelLoading ? (
                  <button className="focus:outline-none ml-2 py-2 px-6 w-full bg-red rounded text-white">
                    <FontAwesomeIcon icon={faSyncAlt} color="#ffffff" spin />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setcancelLoading(true);
                      CancelTransaction(params, { status: "Declined" });
                    }}
                    className="focus:outline-none ml-2 py-2 px-6 w-full bg-red rounded text-white"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
          <ConfirmPassword
            isOpen={confirmOpen}
            onClose={() => setOpen(false)}
            onSuccess={CreatePayment}
          />
        </div>
      )}
    </>
  );
};
