import { useState, useEffect } from "react";
import {
  addBuyOffer,
  addFeeEntry,
  addSellOffer,
  refundExchangeFee,
  setOfferId,
} from "services/exchange";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import {
  refreshMyOffersAction,
  updateBothBalancesAction,
} from "store/actions/ExchangeActions";
import { useAlert } from "react-alert";
import { setCoinsAction } from "store/actions/TransactionActions";
import { fetchFeeWithLevel } from "services/httpServices";
import { getSelectedKeys } from "services/trannsactionServices";

const useAddOffer = ({ buy }) => {
  const [inputs, setInputs] = useState({
    price: "",
    amount: "",
    total: "",
    priceVal: "",
    amountVal: "",
    totalVal: "",
    feeFixed: 0,
    feePercent: 0,
    feeAccount: "",
  });

  const dispatch = useDispatch();
  const [state, setState] = useState({ submitting: false, percent: 0 });
  const {
    transaction: { coins },
    exchange: { selectedPair, refresh, selectedOffer },
  } = useSelector((state: RootState) => state);

  const alert = useAlert();

  const submit = (e) => {
    e.preventDefault();
    if (!validated()) {
      return;
    }

    if (buy) {
      addMyBuyOffer();
    } else {
      addMySellOffer();
    }
  };
  const createFeeEntry = async () => {
    const fee = calculateFee(inputs.feePercent, inputs.feeFixed);
    // if (fee === 0) {
    //   return false;
    // }
    const response = await addFeeEntry({
      fromAddress: getSelectedKeys().public,
      feeReceivingAddress: inputs.feeAccount,
      asset: buy ? selectedPair.marketCoinCode : selectedPair.toCoinCode,
      totalAmount: inputs.amount,
      totalFee: fee,
    })
    return response
  };

  const getFee = () => {
    if (selectedPair.marketCoinCode === "" || selectedPair.toCoinCode === "")
      return;

    fetchFeeWithLevel(
      buy ? selectedPair.marketCoinCode : selectedPair.toCoinCode
    )
      .then((data) => {
        const {
          assetFeeCollectionAddress,
          levelfee: { exchangeSellerFeeFixed, exchangeSellerFeePercent },
        } = data.data;

        setInputs((prev) => ({
          ...prev,
          feeFixed: exchangeSellerFeeFixed,
          feePercent: exchangeSellerFeePercent,
          feeAccount: assetFeeCollectionAddress,
        }));
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, loading: false }));
      });
  };
  const calculateFee = (feePercent, feeFixed) => {
    if (inputs.amount === "" || inputs.amount == "0") return 0;
    const percent = (feePercent / 100) * Number(inputs.amount);
    const total = feeFixed + percent;
    return total;
  };

  useEffect(() => {
    let temp = false;
    if (buy) temp = true;

    if (temp !== selectedOffer.buy) {
      setInputs((prev) => ({
        ...prev,
        price: Number(selectedOffer.price).toFixed(7),
        amount: Number(selectedOffer.amount).toFixed(7),
        total: Number(selectedOffer.total).toFixed(7),
        priceVal: "",
        amountVal: "",
        totalVal: "",
      }));
    }
  }, [selectedOffer]);

  useEffect(()=>{
    setPrice({target:{value: selectedPair.close}})
  },[selectedPair.close])

  useEffect(() => {
    setInputs((prev) => ({
      ...prev,
      price:
        (selectedPair.close && selectedPair.close !== "-")? selectedPair.close
          : selectedPair.lastPrice.toString(),
      amount: "",
      total: "",
    }));
  }, [refresh]);

  useEffect(() => {
    if (selectedPair.marketCoinCode === "" || selectedPair.toCoinCode === "")
      return;
    const base = coins.find(
      (item) => item.short == selectedPair.marketCoinCode.toUpperCase()
    );
    const counter = coins.find(
      (item) => item.short == selectedPair.toCoinCode.toUpperCase()
    );
    dispatch(
      updateBothBalancesAction(
        base ? base.balance : 0,
        counter ? counter.balance : 0
      )
    );
  }, [coins]);

  useEffect(() => {
    //if (inputs.amount !== "" && inputs.amount !== "0") {
    getFee();
    //  }
  }, [refresh]);

  const addMyBuyOffer = async () => {
    let coin1 = coins.find(
      (item) => item.short == selectedPair.marketCoinCode.toUpperCase()
    );
    let coin2 = coins.find(
      (item) => item.short == selectedPair.toCoinCode.toUpperCase()
    );
    
    if (!coin1) {
      alert.show(
        "You need to trust " +
          selectedPair.marketCoinCode.toUpperCase() +
          " in order to proceed."
      );
      return;
    }
    if (!coin2) {
      alert.show(
        "You need to trust " +
          selectedPair.toCoinCode.toUpperCase() +
          " in order to proceed."
      );
      return;
    }
    setState((prev) => ({ ...prev, submitting: true }));
    let asset1 = {
      code: coin1.short,
      issuer: coin1.issuer,
    };
    let asset2 = {
      code: coin2.short,
      issuer: coin2.issuer,
    };
    
    try {
    let fee = calculateFee(inputs.feePercent, inputs.feeFixed);
    const entry:any = await createFeeEntry()
    const payment:any = await addBuyOffer({
        asset1,
        asset2,
        amount: inputs.amount,
        price: inputs.price,
        offerId: 0,
        fee: fee,
        feeAccount: inputs.feeAccount,
        memo: entry.data.memo,
      })

      const result = await setOfferId({
        memo: entry.data.memo,
        asset: buy ? selectedPair.marketCoinCode : selectedPair.toCoinCode,
        offerId: payment.offerResults[0]?.currentOffer?.offerId ||
        payment.offerResults[0]?.offersClaimed[0]?.offerId
      })
      
      setState((prev) => ({ ...prev, submitting: false }));
      alert.show("Buy Offer Added.", { type: "success" });
      dispatch(setCoinsAction());
      dispatch(refreshMyOffersAction());
      setInputs((prev) => ({
        ...prev,
        price:
        (selectedPair.close && selectedPair.close !== "-")? selectedPair.close
            : selectedPair.lastPrice.toString(),
        amount: "",
        total: "",
      }));
      } catch(e) {
        console.log(e.response);
        setState((prev) => ({ ...prev, submitting: false }));
        alert.show(isString(e) ? e : "Unable to add offer", { type: "error" });
    }
  };
  const isString = (myVar) =>
    typeof myVar === "string" || myVar instanceof String;

  const addMySellOffer = async () => {
    let coin1 = coins.find(
      (item) => item.short == selectedPair.marketCoinCode.toUpperCase()
    );
    let coin2 = coins.find(
      (item) => item.short == selectedPair.toCoinCode.toUpperCase()
    );
    if (!coin1) {
      alert.show(
        "You need to trust " +
          selectedPair.marketCoinCode.toUpperCase() +
          " in order to proceed."
      );
      return;
    }
    if (!coin2) {
      alert.show(
        "You need to trust " +
          selectedPair.toCoinCode.toUpperCase() +
          " in order to proceed."
      );
      return;
    }
    setState((prev) => ({ ...prev, submitting: true }));
    let asset1 = {
      code: coin1.short,
      issuer: coin1.issuer,
    };
    let asset2 = {
      code: coin2.short,
      issuer: coin2.issuer,
    };
    try{
    const fee = calculateFee(inputs.feePercent, inputs.feeFixed);
    const entry:any = await createFeeEntry()
    const payment:any = await addSellOffer({
      asset1,
      asset2,
      amount: inputs.amount,
      price: inputs.price,
      offerId: 0,
      fee: fee,
      feeAccount: inputs.feeAccount,
      memo: entry.data.memo,
    })
    const result = await setOfferId({
      memo: entry.data.memo,
      asset: buy ? selectedPair.marketCoinCode : selectedPair.toCoinCode,
      offerId: payment.offerResults[0]?.currentOffer?.offerId ||
      payment.offerResults[0]?.offersClaimed[0]?.offerId})
    
      setState((prev) => ({ ...prev, submitting: false }));
      alert.show("Sell Offer Added.", { type: "success" });
      dispatch(setCoinsAction());
      dispatch(refreshMyOffersAction());
      setInputs((prev) => ({
        ...prev,
        price:
        (selectedPair.close && selectedPair.close !== "-")? selectedPair.close
            : selectedPair.lastPrice.toString(),
        amount: "",
        total: "",
      }));

    } catch (e) {
        setState((prev) => ({ ...prev, submitting: false }));
        alert.show(isString(e) ? e : "Unable to add offer", { type: "error" });
    }
  };

  let validated = () => {
    let response = true;
    const fee = calculateFee(inputs.feePercent, inputs.feeFixed);
    if (state.submitting) {
      return false;
    }
    if (buy) {
      if (fee >= Number(inputs.total)) {
        response = false;
        setInputs((prev) => ({
          ...prev,
          totalVal: "This amount should be greater than fee",
        }));
      }
    } else {
      if (fee >= Number(inputs.amount)) {
        response = false;
        setInputs((prev) => ({
          ...prev,
          amountVal: "This amount should be greater than your fee",
        }));
      }
    }
    if (inputs.price == "") {
      response = false;
      setInputs((prev) => ({ ...prev, priceVal: "Price field is required." }));
    }
    if (inputs.amount == "") {
      response = false;
      setInputs((prev) => ({
        ...prev,
        amountVal: "Amount field is required.",
      }));
    } else if (buy && Number(inputs.total) > selectedPair.balance) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        amountVal: "Your balance is not enough.",
      }));
    } else if (!buy && Number(inputs.amount) > selectedPair.toCoinBalance) {
      response = false;
      setInputs((prev) => ({
        ...prev,
        amountVal: "Your balance is not enough.",
      }));
    }
    
    return response;
  };

  const getPercentage = (percent) => {
    if (buy) {
      getBuyPercentage(percent);
    } else {
      getSellPercentage(percent);
    }
  };

  const getSellPercentage = (percent) => {
    let balance = selectedPair.toCoinBalance ?? 0;
    let amount = (balance * (percent / 100)).toFixed(5);

    let amountValue = percent == 100 ? balance.toString() : amount;

    setAmount({ target: { value: amountValue } });
    setState((prev) => ({ ...prev, percent: percent }));
  };

  const getBuyPercentage = (percent) => {
    let balance = selectedPair.balance ?? 0;
    let amount = (balance * (percent / 100)).toFixed(5);

    let amountValue = percent == 100 ? selectedPair.balance.toString() : amount;

    setTotal({ target: { value: amountValue } });
    setState((prev) => ({ ...prev, percent: percent }));
  };

  const setAmount = (event) => {
    let { value } = event.target;
    setInputs((prev) => ({
      ...prev,
      amount: value,
      total:
        prev.price != "" && value != ""
          ? (Number(prev.price) * Number(value)).toFixed(8).toString()
          : "",
      amountVal: "",
      totalVal: ""
    }));
  };

  const setTotal = (event) => {
    let { value } = event.target;
    setInputs((prev) => ({
      ...prev,
      total: value,
      amount:
        prev.price != "" && value != ""
          ? (Number(value) / Number(prev.price)).toFixed(8)
          : "",
      amountVal: "",
      totalVal: ""
    }));
  };

  const setPrice = (event) => {
    let { value } = event.target;
    setInputs((prev) => ({
      ...prev,
      price: value,
      total:
        prev.amount != "" && value != ""
          ? (Number(prev.amount) * Number(value)).toFixed(8).toString()
          : prev.total,
      amountVal: "",
      totalVal: ""
    }));
  };

  return {
    ...inputs,
    ...state,
    selectedPair: selectedPair,
    setAmount,
    setPrice,
    submit,
    getPercentage,
    setTotal,
    getBuyPercentage,
  };
};

export default useAddOffer;
