import React, { useState, useEffect } from "react";
import "./roundedslider.css";
import { Button } from "components";
import moment from "moment";
import ZakatTerms from "./ZakatTerms";
import { BASE_URL } from "utils/AppConstants";
import { getJWT } from "services/httpServices";
import axios from "axios";
import Loader from "react-loader-spinner";
import ManualZakat from "./ManualZakat";

function ZakatTab() {
  let [isModalOpen, setIsOpen] = useState(false);
  let [isManualOpen, setManualOpen] = useState(false);
  let [loading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [zakatPercentage, setZakatPercentage] = useState(0);
  const [zakatHistory, setZakatHistory] = useState([]);

  const handleClose = () => {
    setManualOpen(false)
    getZakatHistory();
  }

  const handleChange = (e) => {
    setIsChecked(e.target.checked);
  };
  useEffect(() => {
    getZakatHistory();
  }, []);
  // const handleChange =(e) => {
  //   setIsChecked(e.target.checked)
  // }

  const getZakatHistory = async () => {
    try {
      let token: any = await getJWT();

      let res = await axios.get(
        `${BASE_URL}/zakat-history/getHistoryByAccountId?take=100&skip=0`,
        {
          headers: {
            Authorization: `Bearer ${token.data.token}`,
          },
        }
      );

      if (res.status === 200) {
        setLoading(false);
        setIsChecked(res.data.isZakatEnabled);
        setZakatHistory(res.data.data);
      }
    } catch (error) {
      setLoading(false);
      // console.log(error.response);
    }
  };

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center w-full">
          <Loader type="Bars" color="#FFFF00" height={30} width={30} />
        </div>
      )}
      {!loading && (
        <React.Fragment>
          <div className="border border-primary p-5 mb-6 w-full">
            <p className="text-white font-regular text-lg border-b border-primary py-5">
              Zakat
            </p>
            {isChecked && (
              <button
                onClick={() => setManualOpen(true)}
                className="focus:outline-none w-full text-white font-regular text-md border-b border-primary py-3 pr-4 flex justify-between"
              >
                <p>Pay zakat manually</p>
                <img src="assets/arrow-right.svg" />
              </button>
            )}
            <div className="text-white font-regular text-md border-b border-primary py-3 pr-4 flex justify-between">
              <p>Do you want to pay zakat from your account?</p>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => handleChange(e)}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="pb-8">
              <Button
                onClick={() => setIsOpen(true)}
                styles={["bg-accent font-regular text-xs py-3 px-4 mt-10"]}
              >
                Save Changes
              </Button>
            </div>
            <div className="border-b border-primary">
              <button className="focus:outline-none text-white py-2 border-b border-accent px-1">
                History
              </button>
            </div>
            <table className="separated-row"
            style={{ width: "100%" }}>
              <tr className="text-secondaryGray md:text-xs lg:text-xs xl:text-xs text-xxxs font-regular py-2">
                <td>Date & Time</td>
                <td>Zakat Amount</td>
                <td>Percentage</td>
                <td>Year</td>
                <td>Status</td>
              </tr>
              {zakatHistory.map((item: any, index) => (
                <tr
                  key={index}
                  className="text-white md:text-xs lg:text-xs mt-2 mb-2 xl:text-xs text-xxxs font-regular py-2"
                >
                  <td>{moment(item.createdAt).format("L") +
                          " - " +
                          moment(item.createdAt).format("LTS")}</td>
                  <td>{item.zakatAmount} {item.coinCode}</td>
                  <td>{item.zakatPercentage}%</td>
                  <td>{item.year}</td>
                  <td>{item.status}</td>
                </tr>
              ))}
            </table>
          </div>
          <ZakatTerms
            isChecked={isChecked}
            isOpen={isModalOpen}
            onClose={() => setIsOpen(false)}
            zakatPercentage={zakatPercentage}
          />
          <ManualZakat
            isOpen={isManualOpen}
            onClose={handleClose}
            setPercentage={setZakatPercentage}
          />
        </React.Fragment>
      )}
    </>
  );
}

export default ZakatTab;

let time = moment().format("L") + " - " + moment().format("LTS");
