import { getData, saveData } from "./storageServices";
import { store } from "App";
import { toggleConfirmModal } from "store/actions/GenericActions";
import { exchangeErrors } from "utils/constants";

let stopRepeating = () => {
  let repeaterId = getData("repeaterId") || "";
  if (repeaterId !== "") {
    clearInterval(parseFloat(repeaterId));
    saveData({ repeaterId: "" });
  }
};

let toggleModal = () => {
  store.dispatch(toggleConfirmModal(true));
};

const getExchangeMessage = (e, defMsg) => {
  let message;
  if(e.response?.status === 504){
    return 'Request timeout please try again'
  }
  try{
    message = exchangeErrors[e.response.data.extras.result_codes.operations[0]]||defMsg
  }catch(err){
    message = defMsg
  }
  return message
}

let startRepeating = () => {
  let repeaterId = getData("repeaterId") || "";
  let newId;
  if (repeaterId !== "") {
    clearInterval(parseFloat(repeaterId));
    newId = setInterval(() => {
      toggleModal();
    }, 1000 * 3600);
  } else {
    newId = setInterval(() => {
      toggleModal();
    }, 1000 * 3600);
  }
  saveData({ repeaterId: newId });
};

let isUrl = (str) => {
  let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
};

const getPairPrice = ({marketCoinPrice, toCoinPrice}) => {
  let result = (1/marketCoinPrice)*toCoinPrice;
  return result.toFixed(8);
}

const getPairCurrentPrice = ({marketCoinPrice, toCoinPrice}) => {
  let result = Number(marketCoinPrice*toCoinPrice);
  return result.toFixed(8);
}

const getBtcPrice = ({marketCoinPrice, toCoinPrice}) => {
  let result = (1/marketCoinPrice)*toCoinPrice;
  return result.toFixed(8);
}


export { startRepeating, stopRepeating, isUrl, getPairPrice, getExchangeMessage, getPairCurrentPrice, getBtcPrice };
