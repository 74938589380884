import {
  GetAssetsInfoType,
  FETCH_ASSETS_SUCCESS,
  FETCH_ASSETS_ERROR,
  FETCH_ASSETS_PENDING,
  APPEND_ASSETS_SUCCESS,
  APPEND_CUSTOM_ASSETS_SUCCESS,
} from "./AssetsActionsTypes";
import { Asset } from "store/models/Assets";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { fetchCurrenciesSuccess } from "./GenericActions";
import { BASE_URL } from "utils/AppConstants";
import { Currency } from "store/models/GenericState";
import { getPreferences } from "services/storageServices";

export function fetchAssetsSuccess(
  data: Asset[],
  currency: string
): GetAssetsInfoType {
  return {
    type: FETCH_ASSETS_SUCCESS,
    payload: data,
    currency: currency,
  };
}

export function appendAssetsSuccess(
  data: Asset[],
  currency: string
): GetAssetsInfoType {
  return {
    type: APPEND_ASSETS_SUCCESS,
    payload: data,
    currency: currency,
  };
}

export const fetchAssetsPending = (payload: boolean): GetAssetsInfoType => {
  return { type: FETCH_ASSETS_PENDING, payload };
};

export const fetchAssetsError = (massege: string): GetAssetsInfoType => {
  return { type: FETCH_ASSETS_ERROR, payload: massege };
};

export const fetchAssetsAction = (
  callback
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch) => {
  let url = BASE_URL + "/coins/currenciesList";
  let { CURRENCY } = getPreferences();
  try {
    let res: Currency[] = await fetch(url).then((data) => data.json());
    dispatch(fetchCurrenciesSuccess(res));
    let url2 = BASE_URL + "/coins/getAssets?currencyCode=" + CURRENCY;
    let res2 = await fetch(url2).then((data) => data.json());

    dispatch(fetchAssetsSuccess(res2, CURRENCY));
    callback(res2);
  } catch (e) {
    // console.log(e);
  }
};

export const fetchAssetsByCurrencyAction = (
  currency: number
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  try {
    let { CURRENCY } = getPreferences();

    let state: any = getState();
    let url2 =
      BASE_URL +
      "/coins/getAssets?currencyCode=" +
      state.generic.currencies[currency];
    let res2 = await fetch(url2).then((data) => data.json());

    dispatch(fetchAssetsSuccess(res2, CURRENCY));
  } catch (e) {
    // console.log(e);
  }
};


export const UpdateCustomAssets = (payload: any): GetAssetsInfoType => {

  return { type: APPEND_CUSTOM_ASSETS_SUCCESS, payload };
};
