import * as React from "react";
import CustomInput from "components/global/CustomInput";
import { colors } from "styles";
import useSearch from "screens/hooks/P2P/useSearch";
import { closeButton } from "assets/images";
import { useDispatch } from "react-redux";
import { restoreAddsAction } from "store/actions/P2pActions";

export const TransactionForm = (props: any) => {
  const {amount, amountVal, loading, handleInputChange, submit} = useSearch(props.buy)
  const dispatch = useDispatch()
  const handleClose = () => {
    handleInputChange({target:{value:""}})
    dispatch(restoreAddsAction())
    props.closeForm()
  }
  
  return (
    <div
      className="bg-primary p-4 mt-4 w-full relative"
      style={{ display: props.display || "none" }}
    >
      
      <div className="flex">
        <CustomInput
          marginVertical={"0.45rem"}
          inputStyle=""
          inputColor="black"
          label={`Amount in ${props.assetCode}`}
          value={amount}
          onChange={handleInputChange}
          valMsg={amountVal}
        />
      </div>
      <p
        className="text-xs font-regular"
        style={{ color: props.buy ? colors.green : colors.pink }}
      >
        {props.buy ?props.priceSell:props.priceBuy} {props.currency}
      </p>
      <button
        onClick={submit}
        style={{ backgroundColor: props.buy ? colors.green : colors.pink }}
        className="text-white font-regular w-full p-4 rounded mt-5"
      >
        {props.buy ? "Buy Now" : "Sell Now"}
      </button>
      <button onClick={props.toggle} className="text-white text-sm font-regular mt-4 mb-2">
        Looking to {props.buy ? "sell" : "buy"} {props.assetCode}?
      </button>
      <button onClick={handleClose}>
        <img src={closeButton} className="w-6 h-6 absolute" style={{right: '10px', top: '10px'}} />
      </button>
    </div>
  );
};
