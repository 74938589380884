import * as React from "react";
import { TransactionCard } from "components/molecular/P2P/TransactionCard";
import { TransactionForm } from "components/molecular/P2P/TransactionForm";
import { TableHeadingRow } from "components/molecular/P2P/TableHeadingRow";
import { TransactionsTableRow } from "./TransactionsTableRow";
import SelectCoin from "components/molecular/P2P/CreateAd/SelectCoin";
import { useDispatch, useSelector } from "react-redux";
import { selectCoinAction } from "store/actions/P2pActions";
import { RootState } from "store/reducers";
import Trades from "./Trades";

export const LeftCol = ({}) => {
  const {
    p2p: { coins, active },
    generic: { myCountry, activeCountries },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const coin = coins[active];
  const [buy, setBuy] = React.useState(false);
  const [displayForm, setDisplayForm] = React.useState("none");
  const country = activeCountries.find(
    (country) => country.code === myCountry.code
  );
  const currency = country?.currency;
  const handleSelect = (name) => dispatch(selectCoinAction(name));
  return (
    <div className="w-full xl:w-3/6">
      <SelectCoin short={coin.name} outline={true} onSelect={handleSelect} />

      <div
        className="sm:flex"
        style={{ display: displayForm == "block" ? "none" : "flex" }}
      >
        <TransactionCard
          onClick={() => {
            setBuy(true);
            setDisplayForm("block");
          }}
          marginRight="1rem"
          price={coin.priceSell}
          currency={currency}
          buy
        />
        <TransactionCard
          onClick={() => {
            setBuy(false);
            setDisplayForm("block");
          }}
          price={coin.priceBuy}
          currency={currency}
        />
      </div>
      <p className="text-secondaryGray text-left text-sm mt-4">
        This is a market place where Buyers and Sellers liquidate their digital
        wealth. GWallet will act as an ESCROW in assisting a secured
        transaction.
      </p>
      <TransactionForm
        display={displayForm}
        currency={currency}
        assetCode={coin.name}
        priceBuy={Number(coin.priceBuy).toFixed(2)}
        priceSell={Number(coin.priceSell).toFixed(2)}
        toggle={() => setBuy(!buy)}
        buy={buy}
        closeForm={() => {
          setDisplayForm("none");
        }}
      />
      <Trades />
    </div>
  );
};
