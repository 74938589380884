import * as React from "react";
import * as Images from "assets/images/index";
import { MenuButton } from "components/global/MenuButton";
import { NavLink, useLocation } from "react-router-dom";

export const ProfileScreenMenu = ({ callback, index }) => {
  let location = useLocation();

  return (
    <div className="w-full mt-4">
      <MenuButton
        activeImage={Images.profileIconWhite}
        inActiveImage={Images.defaultProfileIcon}
        menuName="KYC Profile"
        isActive={index === 0}
        onClick={() => callback(0)}
      />

      {/* <MenuButton
        activeImage={Images.supportIcon}
        inActiveImage={Images.supportIcon}
        isActive={index === 5}
        menuName="FAQs"
        onClick={() => callback(5)}
      /> */}

      <MenuButton
        activeImage="assets/zakat-white.svg"
        inActiveImage="assets/zakat-gray.svg"
        isActive={index === 7}
        menuName="Zakat"
        onClick={() => callback(7)}
      />

      {/* <MenuButton
        activeImage={Images.bankWhite}
        inActiveImage={Images.bankGrey}
        isActive={index === 8}
        menuName="P2P Bank Account"
        onClick={() => callback(8)}
      /> */}

      <MenuButton
        activeImage={Images.bankWhite}
        inActiveImage={Images.bankGrey}
        isActive={index === 9}
        menuName="P2P Profile"
        onClick={() => callback(9)}
      />

      <MenuButton
        activeImage={Images.referIconWhite}
        inActiveImage={Images.referIconDefault}
        isActive={index === 6}
        menuName="Refer A Friend"
        onClick={() => callback(6)}
      />

      <MenuButton
        activeImage={Images.lockIconWhite}
        inActiveImage={Images.lockIconTransparent}
        isActive={index === 3}
        menuName="Wallet Security"
        onClick={() => callback(3)}
      />

      <MenuButton
        activeImage={Images.walletIconWhite}
        inActiveImage={Images.walletIconTransparent}
        isActive={index === 2}
        menuName="Wallet Settings"
        onClick={() => callback(2)}
      />

      <MenuButton
        activeImage={Images.settingsIconWhite}
        inActiveImage={Images.settingsIcon}
        isActive={index === 1}
        menuName="App Setting"
        onClick={() => callback(1)}
      />
      <MenuButton
        activeImage={Images.supportIcon}
        inActiveImage={Images.supportIcon}
        isActive={index === 4}
        menuName="Support"
        onClick={() => callback(4)}
      />

      <button
        onClick={() => {
          window.location.reload();
          localStorage.removeItem("custom_pair");
        }}
        className="bg-transparent flex text-gray4 font-regular text-xs items-center  p-4 w-full focus:outline-none"
      >
        <img src={Images.logoutIcon} className="h-5 w-5" />
        <p className="text-pink font-regular ml-2">Logout</p>
      </button>
    </div>
  );
};
