import React from "react";
import StateAwareInput from "components/global/StateAwareInput";
import { colors } from "styles/theme";
import useAddOffer from "screens/hooks/exchange/useAddOffer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

export function TradeForm(props: any) {
  const color = props.buy ? "green" : "pink";
  let {
    amount,
    price,
    total,
    totalVal,
    priceVal,
    amountVal,
    percent,
    getPercentage,
    submit,
    submitting,
    selectedPair,
    setAmount,
    setPrice,
    setTotal,
    feeFixed,
    feePercent
  } = useAddOffer({ buy: props.buy });

  const renderBalance = () => {
    return props.buy
      ? selectedPair.balance + " " + selectedPair.marketCoinCode.toUpperCase()
      : selectedPair.toCoinBalance +
          " " +
          selectedPair.toCoinCode.toUpperCase();
  };
  const percentRender = (percentValue) => (
    <small
      onClick={() => getPercentage(percentValue)}
      className={`rounded ${
        percent === percentValue
          ? "text-white bg-" + color
          : "border border-secondaryGray " + "text-" + color
      } cursor-pointer py-1 px-2 mr-1`}
    >
      {percentValue}%
    </small>
  );

  return (
    <form className="mx-auto" onSubmit={submit}>
      <div className="flex justify-end">
        {/* {<p className="text-white">
          {props.buy ? "BUY" : "SELL"} {selectedPair.toCoinCode.toUpperCase()}
        </p>} */}
        <small className="text-gray4">Available -- {renderBalance()}</small>
      </div>
      {!props.market && <StateAwareInput
        placeholder="Price"
        activeColor={(color === "pink" && "red") || color}
        value={price}
        name="price"
        onChange={setPrice}
        trialing={selectedPair.marketCoinCode.toUpperCase()}
      />}
      <small className="text-red italic text-xxs">{priceVal}</small>
      <StateAwareInput
        placeholder="Amount"
        activeColor={(color === "pink" && "red") || color}
        value={amount}
        name="amount"
        onChange={setAmount}
        trialing={selectedPair.toCoinCode.toUpperCase()}
      />
      <small className="text-red italic text-xs">{amountVal}</small>

      <StateAwareInput
        placeholder="Total"
        activeColor={(color === "pink" && "red") || color}
        value={total}
        name="amount"
        onChange={setTotal}
        trialing={selectedPair.marketCoinCode.toUpperCase()}
      />
      <small className="text-red italic text-xs">{totalVal}</small>

      <div className="flex mt-2">
        {percentRender(10)}
        {percentRender(50)}
        {percentRender(100)}
      </div>
        <div className="flex mt-1">
          <h3 className="text-gray4 text-xs">Fee:</h3>
          <h3 className="text-white text-xs ml-1">{feeFixed+" "+(props.buy?selectedPair.marketCoinCode:selectedPair.toCoinCode)} + {feePercent}%</h3>
        </div>
      <button
        type="submit"
        style={{ backgroundColor: props.buy ? colors.green : colors.pink }}
        className="focus:outline-none text-white w-full text-xs md:text-sm lg:text-sm xl:text-sm px-4 py-2 rounded mt-2 mb-2"
      >
        {!submitting &&
          (props.buy ? "Buy " : "Sell ") +
            selectedPair.toCoinCode.toUpperCase()}
        {submitting && (
          <div className="flex items-center justify-center">
            <h4 className="mr-2">Please wait</h4>
            <FontAwesomeIcon icon={faSync} spin color="#ffffff" />
          </div>
        )}
      </button>
    </form>
  );
}
