import * as React from "react";

function TradeTab({ tab, active, onClick }) {
  return (
    <button
      className={`focus:outline-none py-2 px-3 text-base ${
        active ? "border-b-2 border-accent text-white" : "text-secondaryGray"
      }`}
      onClick={onClick}
    >
      {tab}
    </button>
  );
}

export default TradeTab;
