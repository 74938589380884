import * as React from 'react';


function Fees() {
    return (
        <div className="w-full">
            <img src="/assets/FEES.JPEG" className="w-full" />
        </div>
    )
}

export default Fees