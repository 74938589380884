import React, { useState, useEffect } from "react";
import * as images from "../../../assets/images/index";
import { colors } from "styles";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { getPreferences } from "services/storageServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { currenciesEmun, getSymbol, getSymbolConditional } from "utils/constants";

export const WalletCard = (props: any) => {
  let {
    transaction: { selectedWallet },
    generic,
  } = useSelector((state: RootState) => state);
  let history = useHistory();

  return (
    <div className="xl:mr-2 bg-primary w-full flex justify-between items-center p-5">
      <div className="flex items-center">
        <img
          src={images.walletIcon}
          className="self-start h-3 w-3 xl:h-8 xl:w-8"
        />
        <div>
          <text className="text-white text-sm md:text-base lg:text-base xl:text-h1 ml-3 mr-5">
            {selectedWallet.name}
          </text>
          <text
            className="text-white  text-xxs xl:text-xs"
            style={{
              color: selectedWallet.percent > 0 ? colors.green : colors.red,
            }}
          >
            {selectedWallet.percent > 0 && "+"}
            {selectedWallet.percent.toFixed(5)}%
          </text>
          <div>
            <text className="text-white text-xs xl:text-h1 ml-3">
              {getSymbolConditional(selectedWallet.usdBalance)}
              {selectedWallet.usdBalance}
            </text>
            <text className="text-white text-xxs lg:text-xs md:text-xs xl:text-base ml-3 mr-2 text-secondaryGray">
              {selectedWallet.btcBalance} BTC
            </text>
          </div>
        </div>
      </div>

      <div className="flex justify-around items-center">
        {props.showGraph ? (
          <div className="flex">
            <img
              src={
                props.percent > 0
                  ? images.profitGraphCard
                  : images.lossGraphCard
              }
              className={styles.graphStyle}
            />
            <text
              style={{ color: props.percent > 0 ? colors.green : colors.red }}
              className={styles.percentStyle}
            >
              {props.percent > 0 && "+"}
              {parseFloat(props.percent).toFixed(2)}
            </text>
          </div>
        ) : null}

        {!props?.removeButtons &&
          <button onClick={props.onCreateWallet} className={styles.btnStyle}>
            My Wallets
        </button>}

        {!props?.removeButtons &&
          <button
            onClick={() => { history.push({ pathname: '/profileScreen', state: { page: 2 } }) }}
            style={{ backgroundColor: "rgba(151,151,151,0.2)" }}
            className="w-8 h-8 flex justify-center items-center rounded-full"
          >
            <FontAwesomeIcon
              icon={faCog}
              color="#ffffff"
            />
          </button>}
      </div>
    </div>
  );
};

const styles = {
  percentStyle:
    "self-center mr-2 text-xxxs text-white md:text-sm lg:text-sm xl:text-sm",
  graphStyle:
    "mr-2 md:ml-4 xl:ml-0 lg:ml-0 h-8 w-8 md:h-10 md:w-15 lg:h-10 lg:w-15 xl:h-14 xl:w-20",
  btnStyle:
    "bg-yellow-700 hover:bg-yellow-600 rounded mr-4 text-white py-1 px-2 md:py-1 md:px-4 lg:py-1 lg:px-4 xl:py-2 xl:px-4 whitespace-no-wrap focus:outline-none text-xxxs xl:text-xs",
};
