import bcrypt from "bcryptjs";
import { getWallets, removeWallet, getWalletById, clearWallets } from "./dbServices";
import { getSelectedKeys } from "./trannsactionServices";
import { saveData } from "./storageServices";
import { store } from "App";
import { setSelectedWallet } from "store/actions/TransactionActions";

const authenticate = (pass) => {
  let hash = localStorage.getItem("bcrypt_hash");
  let accounts = localStorage.getItem("selectedWallet");
  let res;
  
  if (hash && hash !== "") {
    let match = bcrypt.compareSync(pass, hash);
    if (match && accounts && accounts.length > 3) res = "LOCAL_LOGIN";
    if (match && (!accounts || accounts.length < 3)) res = "CREATE_WALLET";
    if (!match) res = "TRY_AGAIN";
  } else {
    res = "CREATE_ACCOUNT";
  }
  return res;
};

const checkAuthState = (pass) => {
  let hash = localStorage.getItem("bcrypt_hash");
  let accounts = localStorage.getItem("WALLET[keystore]");
  if (hash && accounts && accounts !== "" && !pass) return "LOGIN_SCREEN";
  if (hash && !pass) return "LOGIN_SCREEN";
  if (hash && pass) return "PASS";
  return "REGISTER";
};

const resetPassword = () => {
  localStorage.clear()
  clearWallets()
};

const confirmPassword = (pass) => {
  let hash = localStorage.getItem("bcrypt_hash");
  let match = bcrypt.compareSync(pass, hash);
  if (match) {
    return true;
  } else {
    return false;
  }
};

const removeAccount = async (address, callback) => {
  try{
  let wallet = await getWalletById(address);
  if(!wallet){
    callback("ERROR")
    return;
  }
  let selected = getSelectedKeys().public;
  let res = await removeWallet(address);
  let walletsNew: any = await getWallets();
  if (walletsNew.length > 0) {
    
    if (address === selected) {
      //store.dispatch(setSelectedWallet(wallets[wallets.length - 1]));
      saveData({
        selectedWallet: JSON.stringify({
          public: walletsNew[walletsNew.length - 1].public,
          private: walletsNew[walletsNew.length - 1].private,
          walletId: walletsNew[walletsNew.length - 1].public,
        }),
      });
    }
    callback("WALLETS")
  } else {
    localStorage.removeItem("WALLET[keystore]");
    localStorage.removeItem("wallets");
    callback("CREATE_WALLET");
  }
  }catch(e){
    callback("ERROR")
  }
};

const incryptPassword = (password) => {
  let hash = bcrypt.hashSync(password, 8);
  return hash;
};

export {
  authenticate,
  incryptPassword,
  checkAuthState,
  resetPassword,
  confirmPassword,
  removeAccount,
};
