import {
  FETCH_CURRENCIES_PENDING,
  FETCH_CURRENCIES_SUCCESS,
  GetGenericActionsType,
  TOGGLE_CONFIRM_MODAL,
  FETCH_INITIAL_DATA,
  SET_MY_COUNTRY,
  SET_ACTIVE_COUNTRY,
  PUSH_P2P_NOTIFICATION,
  SET_NOTIFICATIONS,
  APPEND_NOTIFICATION,
  SET_SOCKET_STATUS,
  SET_MEMO,
  SET_LOADING_TABLE
} from "./GenericActionsTypes";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import axios from "axios";
import { BASE_URL } from "utils/AppConstants";
import { Country, Currency, Notification } from "store/models/GenericState";
import { getPreferences } from "services/storageServices";
import { fetchAssetsSuccess } from "./AssetsActions";
import { Asset } from "store/models/Assets";
import { saveInitial, getInitialData } from "services/dbServices";
import { fetchInitial } from "services/httpServices";
import { store } from "App";
import Axios from "axios";
import { getSelectedKeys } from "services/trannsactionServices";

export function fetchCurrenciesSuccess(
  data: Currency[]
): GetGenericActionsType {
  return {
    type: FETCH_CURRENCIES_SUCCESS,
    payload: data,
  };
}

export function toggleConfirmModal(visible: boolean): GetGenericActionsType {
  return {
    type: TOGGLE_CONFIRM_MODAL,
    visible: visible,
  };
}
export function setSocketStatus(connected: boolean): GetGenericActionsType {
  return {
    type: SET_SOCKET_STATUS,
    connected,
  };
}
export function pushP2pNotification(message: string): GetGenericActionsType {
  return {
    type: PUSH_P2P_NOTIFICATION,
    message,
  };
}
export function setActiveCountries(
  countries: Country[]
): GetGenericActionsType {
  return {
    type: SET_ACTIVE_COUNTRY,
    countries,
  };
}
export function setNotifications(
  notifications: Notification[]
): GetGenericActionsType {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  };
}
export function appendNotification(
  notification: Notification
): GetGenericActionsType {
  return {
    type: APPEND_NOTIFICATION,
    notification,
  };
}
export function fetchInitialData(data: {}): GetGenericActionsType {
  return {
    type: FETCH_INITIAL_DATA,
    data: data,
  };
}
export function setMyCountry(country: any): GetGenericActionsType {
  return {
    type: SET_MY_COUNTRY,
    country: country,
  };
}

export const fetchCurrenciesPending = (
  isFetching: boolean
): GetGenericActionsType => {
  return { type: FETCH_CURRENCIES_PENDING, isFetching };
};

export function setMemo(memo: string): GetGenericActionsType {
  return {
    type: SET_MEMO,
    memo,
  };
}

export function setLoading(setLoading: boolean): GetGenericActionsType {
  return {
    type: SET_LOADING_TABLE,
    setLoading,
  };
}

export const fetchCurrenciesAction = (): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => (dispatch) => {
  dispatch(fetchCurrenciesPending(true));

  let url = BASE_URL + "/coins/currenciesList";
  axios
    .get(url)
    .then((data) => {
      if (data.status === 200) {
        dispatch(fetchCurrenciesSuccess(data.data));
      }
    })
    .catch((e) => {
      // console.log(e);
    });
};

export const fetchInitialDataAction = (
  callback
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch) => {
  try {
    let [currencies, assets] = await fetchInitial();

    saveInitial(currencies, assets);
    callback("SUCCESS");
  } catch (e) {
    // console.log(e);
    callback("ERROR");
  }
};

export const loadCacheAction = (
  callback
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch) => {
  try {
    let { currencies, assets }: any = await getInitialData();
    let { CURRENCY } = getPreferences();
    if (
      !(currencies && currencies.length > 0) ||
      !(assets && assets.length > 0)
    ) {
      callback("NO_DATA");
      return;
    }

    dispatch(fetchCurrenciesSuccess(currencies));

    dispatch(fetchAssetsSuccess(assets, CURRENCY));

    callback("SUCCESS");
  } catch (e) {
    callback("ERROR");
  }
};

export const initNotificationsAction = async () => {
  const notificationsRaw = localStorage.getItem("notifications") || "[]";
  let notifications = JSON.parse(notificationsRaw);
  await Axios.get(BASE_URL + `/notifications/getNotifications?address=${getSelectedKeys().public}`).then((data) => {
    if (data.data.length > 0) {
      notifications = data.data
      localStorage.setItem("notifications", JSON.stringify(notifications))
    }
  }).catch((e) => { console.log(e) })
  store.dispatch(setNotifications(notifications));
};

export const appendNotificationsAction = (title: string, message: string, url: string, objectId: number) => {
  const notificationsRaw = localStorage.getItem("notifications") || "[]";
  const notifications: Notification[] = JSON.parse(notificationsRaw);
  const notification = {
    title: title,
    notification: message,
    new: true,
    date: new Date(),
    path: url,
    objectId: objectId
  };

  if (notifications.length > 15) {
    notifications.splice(notifications.length - 1, 1);
  }
  const newNotifications = [notification, ...notifications];
  localStorage.setItem("notifications", JSON.stringify(newNotifications));
  store.dispatch(setNotifications(newNotifications));
};

export const viewNotificationsAction = () => {
  const notificationsRaw = localStorage.getItem("notifications") || "[]";
  const notifications: Notification[] = JSON.parse(notificationsRaw);
  for (let i = 0; i < notifications.length; i++) {
    if (notifications[i].new) {
      notifications[i].new = false;
    }
  }
  localStorage.setItem("notifications", JSON.stringify(notifications));
  changeNotificationsStatus()
  store.dispatch(setNotifications(notifications));
};

const changeNotificationsStatus = () => {
  const address = getSelectedKeys().public;
  Axios.get(BASE_URL + `/notifications/updateNotification?address=${address}`).catch(
    (e) => {
      console.log(e);
    }
  );
};
