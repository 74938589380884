import * as React from "react";
import { LeftCol } from "components/organisms/markets/P2P/LeftCol";
import { RightCol } from "components/organisms/markets/P2P/RightCol";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { initP2pAction, restoreAddsAction } from "store/actions/P2pActions";

export const P2PScreen = (props: any) => {
  const { loading, coins } = useSelector((state:RootState)=>state.p2p)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if(coins.length < 1){
      dispatch(initP2pAction())
    }else{
      dispatch(restoreAddsAction())
    }
  }, []);
  
  const renderLoading = () => (
    <div className="flex justify-center items-center h-full" style={{height: '60vh'}}>
      <Loader type="Bars" color="#FFFF00" height={30} width={30} />
    </div>
  );

  if(loading) return renderLoading()

  return (
    <div className="xl:flex w-5/6 mx-auto mt-6">
      <LeftCol />
      <RightCol />
    </div>
  );
};
