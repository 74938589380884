import * as React from "react";
import { NavLink, useHistory } from "react-router-dom";
import * as Images from "assets/images/index";
import Adds from "./Adds";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/reducers";
import { setActiveTabAction } from "store/actions/P2pActions";
import { useAlert } from "react-alert";
import MyAdds from "./MyAdds";
import { profileStatus } from "utils/constants";
import Axios from "axios";
import { BASE_URL } from "utils/AppConstants";

export const RightCol = ({}) => {
  const {
    p2p: { coins, active, activeTab, usdRates },
    auth,
  } = useSelector((state: RootState) => state);


  const {
    buyAdds,
    sellAdds,
    myBuyAdds,
    mySellAdds,
    name,
    loading,
    loadingMyBuyAdds,
    loadingMySellAdds,
    myBuyAddsPage,
    mySellAddsPage,
    buyPage,
    sellPage,
  } = coins[active];
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();

  const setActiveTab = (type) => dispatch(setActiveTabAction(type));

  const handleOnCreate = () => {
    history.push({ pathname: "/createAdScreen", state: { asset: name } });
  };

  return (
    <div className="xl:w-3/6 xl:ml-5">
      <div className="flex justify-between pt-4 mb-4 border-primary border-b">
        <div>
          <button
            onClick={() => setActiveTab("buy")}
            className={`${
              activeTab === "buy"
                ? "text-white border-accent border-b-2"
                : "text-secondaryGray"
            } pb-4 font-regular focus:outline-none`}
          >
            Buyers List
          </button>
          <button
            onClick={() => setActiveTab("sell")}
            className={`${
              activeTab === "sell"
                ? "text-white border-accent border-b-2"
                : "text-secondaryGray"
            } pb-4 font-regular focus:outline-none ml-8`}
          >
            Sellers List
          </button>
          {auth.password && (
            <button
              onClick={() => setActiveTab("my")}
              className={`${
                activeTab === "my"
                  ? "text-white border-accent border-b-2"
                  : "text-secondaryGray"
              } pb-4 font-regular focus:outline-none ml-8`}
            >
              My Ads
            </button>
          )}
        </div>
        <button onClick={handleOnCreate} className="items-center ml-5 hidden sm:flex">
          <img src={Images.createAdbtn} className="h-4 w-4" />
          <p className="text-white text-xs ml-1 font-regular">Create Ad</p>
        </button>
      </div>
      {activeTab === "buy" && (
        <Adds
          data={buyAdds}
          coinName={name}
          activeTab={activeTab}
          loading={loading}
          page={buyPage}
          usdRates={usdRates}
        />
      )}
      {activeTab === "sell" && (
        <Adds
          data={sellAdds}
          coinName={name}
          activeTab={activeTab}
          loading={loading}
          page={sellPage}
          usdRates={usdRates}
        />
      )}
      {activeTab === "my" && auth.password && (
        <MyAdds
          myBuyAdds={myBuyAdds}
          coin={name}
          mySellAdds={mySellAdds}
          loadingBuy={loadingMyBuyAdds}
          loadingSell={loadingMySellAdds}
          myBuyAddsPage={myBuyAddsPage}
          mySellAddsPage={mySellAddsPage}
          usdRates={usdRates}
        />
      )}
    </div>
  );
};
