import React, { useState } from "react";
import KycStatusTab, { KycStatusTab2 } from "components/atomic/KycStatusTab";
import { profileStatus } from "utils/constants";

function KycStatusbar({tab ,isCompany}) {
  // console.log('isCompany',isCompany)
  const getQuestionImage = () =>
    tab === 0 ? "QuestionGray.svg" : "QuestionGreen.svg";
  const getExclamImg = () => {
    let img = "";
    if (tab < 1) {
      img = "ExclamGray.svg";
    }
    if (tab === 1) {
      img = "ExclamWhite.svg";
    }
    if (tab > 1) {
      img = "ExclamGreen.svg";
    }
    return img
  };
  const getDocImg = () => {
    let img = "";
    if (tab < 2) {
      img = "DocGray.svg";
    }
    if (tab === 2) {
      img = "DocWhite.svg";
    }
    if (tab > 2) {
      img = "DocGreen.svg";
    }
    return img
  };
  const getVerificImg = () => {
    let img = "";
    if (tab < 3) {
      img = "verificGray.svg";
    }
    if (tab === 3) {
      img = "verificWhite.svg";
    }
    if (tab > 3) {
      img = "verificGreen.svg";
    }
    return img
  };
  return (
    <div className="w-full flex items-center px-8 mb-6">
      <KycStatusTab2 isCompany={isCompany}
        active={tab > 0}
        leading={`assets/${getQuestionImage()}`}
        trailing={`assets/${getExclamImg()}`}
        bottom={"text"}
      />
      <KycStatusTab
        active={tab >= 2}
        trailing={`assets/${getDocImg()}`}
        bottom={"ID Documentation"}
        bottomMargin="-35px"
      />
      <KycStatusTab
        active={tab >= 3}
        trailing={`assets/${getVerificImg()}`}
        bottom={"Verification"}
        bottomMargin="-20px"
      />
    </div>
  );
}

export default KycStatusbar;
