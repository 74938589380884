import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

function IframeChart() {
  const { selectedPair } = useSelector((state: RootState) => state.exchange);
  return (
    <div className="w-full">
      <div style={{ height: "450px" }} className="relative">
        <div style={{ height: "450px" }} className="w-full h-full">
          <iframe
            src={`https://charts.gwallet.tech/?baseIssuer=${selectedPair.marketCoinIssuer}&baseCode=${selectedPair.marketCoinCode}&counterIssuer=${selectedPair.toCoinIssuer}&counterCode=${selectedPair.toCoinCode}`}
            height="100%"
            width="98.4%"
          ></iframe>
        </div>

        {/* {loading && (
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            <Loader type="Bars" color="#FFFF00" height={30} width={30} />
          </div>
        )} */}
      </div>
    </div>
  );
}

export default IframeChart;

function ChartTab({ title, index, active, img, onClick }) {
  return (
    <button
      className={`focus:outline-none flex pb-1 ml-4 px-2 ${
        active ? "border-b border-accent" : ""
      }`}
      onClick={() => onClick(index)}
    >
      <img src={`assets/${img}${active ? "-white" : ""}.svg`} />
      <text className={`text-sm text-${active ? "white" : "secondaryGray"}`}>
        {title}
      </text>
    </button>
  );
}
