import React, { Fragment, useState } from 'react';
import {
  addBuyOffer,
  getOfferById,
  refundExchangeFee,
} from 'services/exchange';
import Loader from 'react-loader-spinner';

function DeleteOffer({ selectedOffer, onOfferDelete }) {
  const [deleting, setDeleting] = useState(false);
  const deleteOffer = () => {
    if (deleting) return;

    let asset1 = getAssetObject(selectedOffer.selling);
    let asset2 = getAssetObject(selectedOffer.buying);
    setDeleting(true);
    getOfferById(selectedOffer.id)
      .then(data => {
        return Promise.all([
          addBuyOffer({
            asset1,
            asset2,
            amount: '0',
            price: selectedOffer.price,
            offerId: selectedOffer.id,
            fee: 0,
            feeAccount: '',
            memo: '',
          }),
          Number(data.amount),
        ]);
      })
      .then((result) => {
        const [data, amount] = result
        return refundExchange(
          selectedOffer.id,
          data.hash,
          amount,
        );
      })
      .then(data => {
        setDeleting(false);
        onOfferDelete(selectedOffer, true);
      })
      .catch(e => {
        setDeleting(false);
        onOfferDelete(selectedOffer, false);
      });
  };

  const refundExchange = (tradeId, trxId, remaining) => {
    const { asset_type, asset_code } = selectedOffer.selling;
    return refundExchangeFee({
      tradeId: tradeId,
      assetsCode: asset_type === 'native' ? 'XLM' : asset_code,
      trxId: trxId,
      remaining,
    });
  };

  const getAssetObject = asset => {
    if (asset.asset_type === 'native') {
      return { code: 'XLM' };
    } else {
      return { code: asset.asset_code, issuer: asset.asset_issuer };
    }
  };
  return (
    <Fragment>
      {deleting ? (
        <div className="w-full text-right flex justify-end">
          <div className="py-2 px-2">
            <Loader type="Bars" color="#FFFF00" height={15} width={15} />
          </div>
        </div>
      ) : (
        <div className="w-full text-right">
          <button
            onClick={() => deleteOffer()}
            className="focus:outline-none text-gray4 text-xxs bg-primary py-1 px-2"
          >
            Cancel
          </button>
        </div>
      )}
    </Fragment>
  );
}

export default DeleteOffer;
