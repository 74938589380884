import * as React from "react";
import { Button } from "components/global";
import { RadioButton } from "components/molecular/ProfileScreen/RadioButton";
import { RemoveAccountForm } from "components/organisms/ProfileScreen/RemoveAccountForm";
import useSecurity from "./hooks/useSecurity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Multisig from "screens/Multisig";
import ConfirmPassword from "screens/ConfirmPassword";

export const WalletSecurityTab = (props: any) => {
  const [remove, setRemove] = React.useState(false);
  let [isVisible, setVisible] = React.useState(false);
  let [confirmOpen, setConfirm] = React.useState(false);

  let {
    password,
    privateKey,
    handleInputChange,
    saveChanges,
    toggleSecretVisibility,
    secretVisible,
    copyToClip,
  } = useSecurity();

  return (
   <React.Fragment> <div
      className="border border-primary mb-6 p-5 md:w-5/6 lg:w-5/6 xl:w-5/6 w-full"
    >
      <p className="text-white font-regular text-lg">Wallet Security</p>
      <div className="border-t mt-5 md:w-5/6 lg:w-5/6 xl:w-5/6 w-full border-primary">
        <p className="text-white font-regular text-sm">
        I want my Session Password to be active.
        </p>
        <div className="mt-5 mb-5">
          <RadioButton
            label="Every transaction"
            value="Every transaction"
            name="password"
            selected={password === "Every transaction"}
            onChange={handleInputChange}
          />
          <RadioButton
            label="After 30 minutes"
            value="After 30 minutes"
            name="password"
            selected={password === "After 30 minutes"}
            onChange={handleInputChange}
          />
          <RadioButton
            label="Once for Log In"
            value="Once for Log In"
            name="password"
            selected={password === "Once for Log In"}
            onChange={handleInputChange}
          />
        </div>
        {/*<div className="border-t mt-4 border-primary border-b p-2">
          <div className="flex justify-between items-center">
            <button onClick={()=>setVisible(true)} className="text-white font-regular text-sm md:text-md lg:text-md xl:text-md">
              Multisig (Multiple Signatures) Security
            </button>
          </div>
        </div>*/}
        <div className=" border-primary border-b p-2">
          <div className="flex flex-col justify-between items-start">
            <button
              onClick={()=>setConfirm(true)}
              className="mb-2 focus:outline-none text-white font-regular text-sm md:text-md lg:text-md xl:text-md"
            >
              View Secret Keys
            </button>
            {secretVisible && (
              <button
                onClick={copyToClip}
                className="focus:outline-none flex items-center text-xs text-white p-1 bg-primary text-gray-600 rounded"
              >
                <p className="mr-1">{privateKey}</p>
                <FontAwesomeIcon icon={faCopy} />
              </button>
            )}
          </div>
        </div>
        <div className=" border-primary border-b p-2">
          <div className="flex justify-between items-center">
            <button
              onClick={() => setRemove(true)}
              className="focus:outline-none text-pink font-regular text-sm md:text-md lg:text-md xl:text-md"
            >
              Remove Account
            </button>
          </div>
        </div>
      </div>
      <Button onClick={saveChanges} styles={["bg-accent text-xs p-4 mt-4 font-regular"]}>
        Save Changes
      </Button>
    </div>
    <RemoveAccountForm onClose={() => setRemove(false)} isOpen={remove} />
    <Multisig isOpen={isVisible} onClose={()=>setVisible(false)} />
    <ConfirmPassword
        isOpen={confirmOpen}
        onClose={() => setConfirm(false)}
        onSuccess={toggleSecretVisibility}
      />
    </React.Fragment>
  );
};

