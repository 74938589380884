import React, { useState, Fragment } from "react";


{
  /* <div className="flex w-full items-center justify-end pr-2 mt-1 mb-1">
        <p className="text-accent text-xxs">Spread: 2.63%</p>
      </div> */
}

export function BuyPriceTable({ data, loading, floatingPoints, onSelect }) {
  return (
    <Fragment>
      {!loading &&
        data.map((item: any, index: number) => {
          return <BuyOfferRow key={index} floatingPoints={floatingPoints} onSelect={onSelect} item={item} />;
        })}
      {!loading && data.length < 1 && (
        <tr className="flex justify-center items-center h-full">
          <td colSpan={3} rowSpan={10}>
            <small className="text-gray4 text-xs">No Trades</small>
          </td>
        </tr>
      )}
    </Fragment>
  );
}

export function BuyOfferRow({ item, onSelect, floatingPoints }) {
  const setFps = (value) => {
    if(value&&value !== "-"){
      return Number(value).toFixed(Number(value)>999?2:7)
    } else {
      return value
    }
  }
  return (
    <tr
      className={item.price !== "-" ?"cursor-pointer":""}
      onClick={()=>onSelect({...item, buy: true})}
      style={{background: `linear-gradient(to left, #00C05340 ${item.depth}%, transparent ${item.depth}%)`}}
    >
      <td className="pl-4">
        <p className="text-green text-xs">{setFps(item.price)}</p>
      </td>
      <td>
        <p className="text-gray4 text-xs">{setFps(item.amount)}</p>
      </td>
      <td className="text-right pr-4">
        <p className="text-gray4 text-xs">{setFps(item.total)}</p>
      </td>
    </tr>
  );
}