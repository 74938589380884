import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAlert } from "react-alert";
import {
  cancelTrade,
  claimAmountRecived,
  claimAmountTransferByBuyer,
  disputeAmountNotRecived,
  disputeTradeExpired,
  confirmEscrowPayment,
  refreshTradeStatus,
} from "services/p2pServives";
import {
  getSelectedKeys,
  getSelectedSecret,
  memoExistInStellar,
  sendNonNativeBalance,
} from "services/trannsactionServices";
import {
  tradeActionButtonEnum,
  tradeActionIndexEnum,
  tradeStatusEnum,
} from "utils/AppConstants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { setCoinsAction } from "store/actions/TransactionActions";
import { p2pTradeMessagePaymentName, sendBalanceErrors } from "utils/constants";
const init: any = [];
const useTradesProgress = () => {
  const {
    p2p: { coins, tradeUpdate, activeTrades },
    assets: { assets },
  } = useSelector((state: RootState) => state);
  const location: any = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();
  const {
    id,
    type,
    p2pads: { assetCode },
  } = location.state.trade;

  const [state, setState] = useState({
    loading: true,
    claiming: false,
    cancelling: false,
    sendingBalance: false,
    claimingReceived: false,
    disputingNotRec: false,
    disputingExpSeller: false,
    disputingExpBuyer: false,
    trade: location.state.trade,
    action: "",
    cancel: "",
    message: "",
    type: "",
    rating: 0,
    isConfirmOpen: false,
    disabledActions: [tradeActionIndexEnum.NON],
    tradeScreenType: 0,
    chatMessages: init,
    selectedAction: "",
  });

  const closeDisputeConfirm = () =>
    setState((prev) => ({ ...prev, isConfirmOpen: false }));
  const setTradeScreenType = (index) =>
    setState((prev) => ({ ...prev, tradeScreenType: index }));
  const setMessages = (chat, append) => {
    if (append) {
      setState((prev) => ({
        ...prev,
        chatMessages: [...prev.chatMessages, chat],
      }));
    } else {
      setState((prev) => ({ ...prev, chatMessages: chat }));
    }
  };

  const initialize = () => {
    let trade: any = activeTrades.find((trade) => trade.id === id);
    if (!trade) {
      const coin = coins.find((coin) => coin.name === assetCode.toUpperCase());
      trade = coin?.trades.find((trade) => trade.id === id);
    }
    let {
      status,
      account,
      p2pads,
      type,
      buyerRatings,
      sellerRatings,
      p2pdispute,
    } = trade || state.trade;
    console.log("trade", trade);

    status = checkDisputeType(
      status,
      p2pdispute,
      p2pads.type,
      account,
      p2pads.account
    );

    const redirect = checkBuyerCompleted(status, {
      tradeAccount: account,
      addAccount: p2pads.account,
      type: type,
    });
    if (redirect) return;
    const cancelled = checkCancelled(status);
    if (cancelled) return;

    const [action, cancel, message] = setOptionalData({
      status: status,
      tradeAccount: account,
      addAccount: p2pads.account,
      type: type,
    });
    const isBuyer =
      (type === "buy" ? account : p2pads.account) === getSelectedKeys().public
        ? true
        : false;
    setState((prev) => ({
      ...prev,
      trade: trade || prev.trade,
      loading: false,
      action: action,
      cancel: cancel,
      message: message,
      type: type,
      rating: isBuyer ? sellerRatings : buyerRatings,
    }));
  };

  const checkDisputeType = (
    status,
    p2pdispute,
    addType,
    tradeAccount,
    addAccount
  ) => {
    let accountIdSeller = addType == "sell" ? addAccount : tradeAccount;
    let accountIdBuyer = addType == "sell" ? tradeAccount : addAccount;
    if (status === tradeStatusEnum.Disputed) {
      if (p2pdispute && p2pdispute.status === "Resolved") {
        if (p2pdispute.winByAccountId === accountIdBuyer) {
          return tradeStatusEnum.Completed;
        }
        if (p2pdispute.winByAccountId === accountIdSeller) {
          return tradeStatusEnum.Cancelled;
        }
      }
    }
    return status;
  };

  const checkBuyerCompleted = (status, params) => {
    if (status === tradeStatusEnum.Completed) {
      const type =
        params[idBuyType(params.type) ? "addAccount" : "tradeAccount"] ===
        getSelectedKeys().public
          ? "Seller"
          : "Buyer";

      history.replace({
        pathname: "reviewScreen",
        state: { trade: state.trade.id, type, tradeInfo: state.trade },
      });
      return true;
    }
    return false;
  };

  const checkCancelled = (status) => {
    if (
      status === tradeStatusEnum.Cancelled ||
      status === tradeStatusEnum.Cancelled_With_Holded_Funds
    ) {
      history.replace("/p2p");
      return true;
    }
    return false;
  };

  const setOptionalData = (params) => {
    let responseAction = tradeActionButtonEnum.NON;
    let responseCancel = tradeActionButtonEnum.NON;
    const myAccount = getSelectedKeys().public;
    const paymentMethod = state.trade.p2pads?.payment_method
    let message = "";
    if (params.status === tradeStatusEnum.pending_user_transfer) {
      if (params.addAccount === myAccount) {
        responseAction = tradeActionButtonEnum.HOLD_BALANCE;
        responseCancel = tradeActionButtonEnum.REJECT;
        message = `If you accept this. The trade will start and we will hold your ${
          Number(state.trade?.cryptoAmountFromSeller).toFixed(7)
        } ${state.trade?.p2pads?.assetCode?.toUpperCase()} in escrow.`;
      }
      if (params.tradeAccount === myAccount) {
        responseAction = tradeActionButtonEnum.NON;
        responseCancel = tradeActionButtonEnum.CANCEL;
        message = "We are waiting for seller to accept and start the trade.";
      }
    }
    if (params.status === tradeStatusEnum.Started) {
      if (
        params[idBuyType(params.type) ? "addAccount" : "tradeAccount"] ===
        myAccount
      ) {
        responseAction = tradeActionButtonEnum.NON;
        responseCancel = tradeActionButtonEnum.DISPUTE_EXPIRED_SELLER;
        message = `Waiting for payment from buyer. You will receive ${Number(
          state.trade?.fiatTradeAmount
        )?.toFixed(2)} ${state.trade?.p2pads?.currency} and will pay ${Number(
          Number(state.trade?.cryptoAmountFromSeller).toFixed(7)
        ).toFixed(7)} ${state.trade?.p2pads?.assetCode} in this trade.`;
      }
      if (
        params[idBuyType(params.type) ? "tradeAccount" : "addAccount"] ===
        myAccount
      ) {
        responseAction = tradeActionButtonEnum.I_HAVE_PAID;
        responseCancel = tradeActionButtonEnum.CANCEL;
        message = `Transfer the total amount to seller's given ${p2pTradeMessagePaymentName[paymentMethod]} using this reference number ${state.trade.id}. \n If payment time went out then dispute will be open and we will send funds back to seller.`;
      }
    }
    if (params.status === tradeStatusEnum.FundsSent) {
      if (
        params[idBuyType(params.type) ? "addAccount" : "tradeAccount"] ===
        myAccount
      ) {
        responseAction = tradeActionButtonEnum.I_HAVE_RECEIVED;
        responseCancel = tradeActionButtonEnum.I_HAVE_NOT_RECEIVED;
        message =
          "Please verify your payment. In case you didn't receive you can open dispute.";
      }
      if (
        params[idBuyType(params.type) ? "tradeAccount" : "addAccount"] ===
        myAccount
      ) {
        responseAction = tradeActionButtonEnum.NON;
        responseCancel = tradeActionButtonEnum.DISPUTE_EXPIRED_BUYER;
        message = "Waiting for seller to verify the payment.";
      }
    }
    if (params.status === tradeStatusEnum.Disputed) {
      responseAction = tradeActionButtonEnum.NON;
      responseCancel = tradeActionButtonEnum.NON;
      message = "This trade is in disputed state.";
    }
    return [responseAction, responseCancel, message];
  };

  const idBuyType = (type) => {
    return type === "buy" ? true : false;
  };

  useEffect(() => {
    refreshTradeStatus(id)
  },[])

  useEffect(() => {
    initialize();
  }, [tradeUpdate]);

  const onProfile = () => {
    const { p2pads, account } = state.trade;
    const acc = getSelectedKeys().public === account ? p2pads.account : account;
    history.push({ pathname: "/p2pProfile", state: { account: acc } });
  };

  const onUserAffirm = () => {
    switch (state.selectedAction) {
      case tradeActionButtonEnum.I_HAVE_PAID:
        claimPaid();
        break;
      case tradeActionButtonEnum.CANCEL:
        cancelMyTrade();
        break;
      case tradeActionButtonEnum.I_HAVE_RECEIVED:
        claimAmountRecivedSeller();
        break;
      case tradeActionButtonEnum.I_HAVE_NOT_RECEIVED:
        claimAmountNotRecivedSeller();
        break;
      case tradeActionButtonEnum.DISPUTE_EXPIRED_SELLER:
        claimTradeExpiredSeller();
        break;
      case tradeActionButtonEnum.DISPUTE_EXPIRED_BUYER:
        claimTradeExpiredBuyer();
        break;
      case tradeActionButtonEnum.HOLD_BALANCE:
        holdBalance();
        break;
      default:
        break;
    }
  };

  const claimPaid = () => {
    if (
      state.disabledActions.includes(tradeActionIndexEnum.I_HAVE_PAID) ||
      state.claiming
    ) {
      return;
    }
    if (!state.isConfirmOpen) {
      setState((prev) => ({
        ...prev,
        isConfirmOpen: true,
        selectedAction: tradeActionButtonEnum.I_HAVE_PAID,
      }));
      return;
    }
    setState((prev) => ({ ...prev, claiming: true, isConfirmOpen: false }));
    claimAmountTransferByBuyer(id)
      .then((data) => {
        setState((prev) => ({
          ...prev,
          claiming: false,
          selectedAction: "",
          disabledActions: [
            ...prev.disabledActions,
            tradeActionIndexEnum.I_HAVE_PAID,
          ],
        }));
      })
      .catch((e) => {
        // console.log(e.response.data.Error);
        alert.show(
          e?.response?.data?.Error || "Request failed please try again",
          { type: "error" }
        );
        setState((prev) => ({ ...prev, claiming: false }));
      });
  };

  const cancelMyTrade = () => {
    if (
      state.cancelling ||
      state.disabledActions.includes(tradeActionIndexEnum.CANCEL)
    ) {
      return;
    }
    if (!state.isConfirmOpen) {
      setState((prev) => ({
        ...prev,
        isConfirmOpen: true,
        selectedAction: tradeActionButtonEnum.CANCEL,
      }));
      return;
    }
    if (
      type === "buy" &&
      state.trade.p2pads.account === getSelectedKeys().public
    ) {
      cancelSellTrade();
    } else {
      cancelBuyTrade();
    }
  };

  const cancelBuyTrade = () => {
    setState((prev) => ({ ...prev, cancelling: true, isConfirmOpen: false }));
    cancelTrade(id, "Buyer")
      .then((data) => {
        setState((prev) => ({
          ...prev,
          cancelling: false,
          selectedAction: "",
          disabledActions: [
            ...prev.disabledActions,
            tradeActionIndexEnum.CANCEL,
          ],
        }));
      })
      .catch((e) => {
        console.log(e);
        setState((prev) => ({ ...prev, cancelling: false }));
        alert.show(
          e?.response?.data?.Error || "Request failed please try again",
          { type: "error" }
        );
      });
  };

  const cancelSellTrade = () => {
    setState((prev) => ({ ...prev, cancelling: true, isConfirmOpen: false }));
    cancelTrade(id, "Seller")
      .then((data) => {
        setState((prev) => ({
          ...prev,
          cancelling: false,
          selectedAction: "",
          disabledActions: [
            ...prev.disabledActions,
            tradeActionIndexEnum.CANCEL,
          ],
        }));
      })
      .catch((e) => {
        console.log(e);
        setState((prev) => ({ ...prev, cancelling: false }));
        alert.show(
          e?.response?.data?.Error || "Request failed please try again",
          { type: "error" }
        );
      });
  };

  const claimAmountRecivedSeller = () => {
    if (state.disabledActions.includes(tradeActionIndexEnum.I_HAVE_RECEIVED)) {
      return;
    }
    if (state.claimingReceived) {
      return;
    }
    if (!state.isConfirmOpen) {
      setState((prev) => ({
        ...prev,
        isConfirmOpen: true,
        selectedAction: tradeActionButtonEnum.I_HAVE_RECEIVED,
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      claimingReceived: true,
      isConfirmOpen: false,
    }));
    claimAmountRecived(id)
      .then((data) => {
        setState((prev) => ({
          ...prev,
          claimingReceived: false,
          selectedAction: "",
          disabledActions: [
            ...prev.disabledActions,
            tradeActionIndexEnum.I_HAVE_RECEIVED,
          ],
        }));
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        alert.show(
          e?.response?.data?.Error || "Request failed please try again",
          { type: "error" }
        );
        setState((prev) => ({ ...prev, claimingReceived: false }));
      });
  };

  const claimAmountNotRecivedSeller = () => {
    if (
      state.disputingNotRec ||
      state.disabledActions.includes(tradeActionIndexEnum.I_HAVE_NOT_RECEIVED)
    ) {
      return;
    }
    if (!state.isConfirmOpen) {
      setState((prev) => ({
        ...prev,
        isConfirmOpen: true,
        selectedAction: tradeActionButtonEnum.I_HAVE_NOT_RECEIVED,
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      disputingNotRec: true,
      isConfirmOpen: false,
    }));
    disputeAmountNotRecived(id)
      .then((data) => {
        setState((prev) => ({
          ...prev,
          disputingNotRec: false,
          selectedAction: "",
          disabledActions: [
            ...prev.disabledActions,
            tradeActionIndexEnum.I_HAVE_NOT_RECEIVED,
          ],
        }));
      })
      .catch((e) => {
        console.log(e.response);
        setState((prev) => ({ ...prev, disputingNotRec: false }));
      });
  };

  const claimTradeExpiredSeller = () => {
    if (
      state.disputingExpSeller ||
      state.disabledActions.includes(
        tradeActionIndexEnum.DISPUTE_EXPIRED_SELLER
      )
    ) {
      return;
    }
    if (!state.isConfirmOpen) {
      setState((prev) => ({
        ...prev,
        isConfirmOpen: true,
        selectedAction: tradeActionButtonEnum.DISPUTE_EXPIRED_SELLER,
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      disputingExpSeller: true,
      isConfirmOpen: false,
    }));
    disputeTradeExpired(id, "Seller")
      .then((data) => {
        setState((prev) => ({
          ...prev,
          disputingExpSeller: false,
          selectedAction: "",
          disabledActions: [
            ...prev.disabledActions,
            tradeActionIndexEnum.DISPUTE_EXPIRED_SELLER,
          ],
        }));
      })
      .catch((e) => {
        console.log(e.response);
        alert.show(
          e.response?.data?.Error || "Something went wrong try again.",
          { type: "error" }
        );
        setState((prev) => ({ ...prev, disputingExpSeller: false }));
      });
  };

  const claimTradeExpiredBuyer = () => {
    if (
      state.disputingExpBuyer ||
      state.disabledActions.includes(tradeActionIndexEnum.DISPUTE_EXPIRED_BUYER)
    ) {
      return;
    }
    if (!state.isConfirmOpen) {
      setState((prev) => ({
        ...prev,
        isConfirmOpen: true,
        selectedAction: tradeActionButtonEnum.DISPUTE_EXPIRED_BUYER,
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      disputingExpBuyer: true,
      isConfirmOpen: false,
    }));
    disputeTradeExpired(id, "Buyer")
      .then((data) => {
        setState((prev) => ({
          ...prev,
          disputingExpBuyer: false,
          selectedAction: "",
          disabledActions: [
            ...prev.disabledActions,
            tradeActionIndexEnum.DISPUTE_EXPIRED_BUYER,
          ],
        }));
      })
      .catch((e) => {
        console.log(e.response);
        setState((prev) => ({ ...prev, disputingExpBuyer: false }));
      });
  };

  const holdBalance = async () => {
    if (state.sendingBalance) {
      return;
    }
    if (state.disabledActions.includes(tradeActionIndexEnum.HOLD_BALANCE)) {
      return;
    }
    
    if (!state.isConfirmOpen) {
      setState((prev) => ({
        ...prev,
        isConfirmOpen: true,
        selectedAction: tradeActionButtonEnum.HOLD_BALANCE,
      }));
      return;
    }
    setState((prev) => ({
      ...prev,
      sendingBalance: true,
      isConfirmOpen: false,
    }));
    const {
      escrowAccountId,
      cryptoAmountFromSeller,
      sellerStellarMemo,
      p2pads,
    } = state.trade;
    const coin = assets.find(
      (coin) => coin.short.toUpperCase() === p2pads.assetCode.toUpperCase()
    );
    if (tradeStatusEnum.pending_user_transfer && state.trade.type === "sell") {
      return;
    }

    let errorOccured = false;
    const existsInStellar:any = await memoExistInStellar(sellerStellarMemo, getSelectedKeys().public).catch((e) => {
      setState((prev) => ({
        ...prev,
        sendingBalance: false,
      }));
      alert.show("Network error Please try again", {type:"error"})
      errorOccured = true;
    })
    if(errorOccured){
      return;
    }

    if(existsInStellar.exists){
      // alert.show("You have already started this trade.")
      confirmPayment({hash: existsInStellar.hash})
      return;
    }
    
    sendNonNativeBalance(
      getSelectedSecret(),
      escrowAccountId,
      cryptoAmountFromSeller,
      sellerStellarMemo,
      p2pads.assetCode.toUpperCase(),
      coin?.issuer
    )
      .then(confirmPayment)
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        alert.show(getSendMessage(e, "Error while starting trade"), {
          type: "error",
        });
        setState((prev) => ({ ...prev, sendingBalance: false }));
      });
  };

  const confirmPayment = (data) => {
    confirmEscrowPayment(data.hash)
      .then((result) => {
        dispatch(setCoinsAction());
        alert.show("Accepted", { type: "success" });
        setState((prev) => ({
          ...prev,
          sendingBalance: false,
          selectedAction: "",
          disabledActions: [
            ...prev.disabledActions,
            tradeActionIndexEnum.HOLD_BALANCE,
          ],
        }));
      })
      .catch((e) => {
        alert.show(e.response?.data?.Error||"Transaction confirmation failed contact support", {
          type: "error",
        });
        setState((prev) => ({ ...prev, sendingBalance: false }));
    });
  }

  const getSendMessage = (e, defMsg) => {
    let message;
    if (e.response?.status === 504) {
      return "Request timeout please try again";
    }
    try {
      message =
        sendBalanceErrors[
          e?.response?.data?.extras?.result_codes?.operations[0]
        ] || defMsg;
    } catch (err) {
      message = defMsg;
    }
    return message;
  };

  return {
    ...state,
    claimPaid,
    cancelMyTrade,
    holdBalance,
    onProfile,
    claimAmountRecivedSeller,
    claimAmountNotRecivedSeller,
    closeDisputeConfirm,
    claimTradeExpiredSeller,
    setTradeScreenType,
    setMessages,
    onUserAffirm,
    claimTradeExpiredBuyer,
  };
};

export default useTradesProgress;
