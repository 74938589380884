import * as React from "react";
import { WalletCard } from "../Wallet/WalletCard";
import { BottomNav } from "screens/ProfileScreen tabs/KYCProfileTab";
import "../../../styles/inputFile.css";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useAlert } from "react-alert";
export const GeneralInfoBlock = ({
  tab,
  setTab,
  status,
  setIsCompany,
  isCompany,
}) => {
  const coins: any = useSelector((state: RootState) => state.transaction.coins);
  const alert = useAlert();

  const onSelectKYCType = (e) => {
    // console.log(e.target.value)
    if (e.target.value === "company") {
      setIsCompany(true);
    } else {
      setIsCompany(false);
    }
  };
  const handleNext = () => {
    if (coins && coins.length > 0) {
      setTab(tab + 1);
    } else {
      alert.show("You need to activate your wallet before proceeding.");
    }
  };
  return (
    <div className="flex-1 flex flex-col justify-between">
      <div className="">
        <h1 className="text-white text-base mt-12">
          Know Your Customers Profile
        </h1>
        {!coins ||
          (coins.length === 0 && (
            <div className="flex items-center mt-4">
              <img src="assets/important-message.svg" className="w-4 h-4" />
              <label className="text-red font-regular text-sm ml-2">
                Activate your wallet before proceeding to KYC
              </label>
            </div>
          ))}
        <p
          className="text-secondaryGray font-regular text-xs"
          style={{ marginTop: "16px" }}
        >
          Know Your Customer (KYC) refers to the process of verifying you or
          your company identity.
        </p>
        <p className="text-secondaryGray font-regular mt-2 text-xs">
          KYC on this platform is for the most part optional. Except when
          required by certain financial service providers.
        </p>
        <p className="text-secondaryGray font-regular mt-2 text-xs">
          All KYC info is stored encrypted on your local device with encrypted
          backups on a remote server. We keep remote encrypted backups in order
          to synchronize your KYC info across all of your devices.
        </p>
        <p className="text-secondaryGray font-regular mt-2 text-xs">
          Your KYC is encrypted with your secret key, so even we do not have
          access to your data.
        </p>
        <h1
          className="text-white text-base mt-12"
          style={{ marginTop: "20px" }}
        >
          KYC Type
        </h1>
        <p
          className="text-secondaryGray font-regular text-xs"
          style={{ marginTop: "12px" }}
        >
          You can verify your identity as an individual or a company. Select
          below KYC type.
        </p>
        <div className="flex items-center">
          <input
            type="radio"
            id="individual"
            name="type"
            value="individual"
            checked={!isCompany}
            className="mr-3 mt-1"
            onChange={(e) => onSelectKYCType(e)}
          />
          <p
            className="text-white text-xs mt-2 cursor-pointer"
            onClick={() => onSelectKYCType({ target: { value: "Individual" } })}
          >
            Individual
          </p>
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            id="company"
            name="type"
            value="company"
            checked={isCompany}
            className="mr-3 mt-1"
            onChange={(e) => onSelectKYCType(e)}
          />{" "}
          <p
            className="text-white text-xs mt-2 cursor-pointer"
            onClick={() => onSelectKYCType({ target: { value: "company" } })}
          >
            Company
          </p>
        </div>
      </div>

      <BottomNav
        tab={tab}
        setTab={setTab}
        edit={false}
        submit={handleNext}
        loading={false}
        status={status}
      />
    </div>
  );
};
