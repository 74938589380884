import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faExternalLinkAlt,
  faSync,
} from '@fortawesome/free-solid-svg-icons';
import Loader from 'react-loader-spinner';
import useWithdrawal from './hooks/useWithdrawal';
import ToggleButton from 'components/atomic/ToggleButton';
import ConfirmPassword from './ConfirmPassword';
import moment from 'moment';
import { TransactionsDataRow } from 'components/molecular/markets/Transactions/TransactionsDataRow';
import { capitalize } from 'utils/AppConstants';
import NetworkType from 'components/atomic/NetworkType';

function Withdraw() {
  let {
    handleSubmit,
    setPercentAmount,
    handleInputChange,
    inputs: { address, addressVal, amount, amountVal },
    state: {
      submitting,
      balance,
      index,
      coinName,
      percent,
      isConfirmOpen,
      fee,
      chainType,
    },
    setIndex,
    setMemo,
    withdraw,
    closeModal,
    transactions,
    loading,
    sendBalance,
    onCopy,
    nextPage,
    nextLoading,
    setChainType,
    showDropdown
  } = useWithdrawal();

  let renderNext = () => (
    <div className="flex justify-center my-6">
      <button
        onClick={nextPage}
        className="focus:outline-none py-2 px-3 border border-gray4 text-gray4"
      >
        Show more
      </button>
    </div>
  );

  return (
    <Fragment>
      <div className="w-full">
        {index === 0 && (
          <WithdrawalInit
            address={address}
            addressVal={addressVal}
            amount={amount}
            amountVal={amountVal}
            balance={balance}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            coinName={coinName}
            setPercentAmount={setPercentAmount}
            percent={percent}
            submitting={submitting}
            setChainType={setChainType}
            chainType={chainType}
            showDropdown={showDropdown}
          />
        )}

        {index === 1 && (
          <WithdrawalConfirm
            done={() => setIndex(0)}
            destination={address}
            amount={amount}
            fee={fee}
            proceed={sendBalance}
            submitting={submitting}
            coinName={coinName}
          />
        )}
        <div className="border border-primary p-4 w-full">
          <h2 className="text-gray-600 text-xl font-bold mb-4">
            Recent Withdrawals
          </h2>
          <div>
            {transactions.map((item, index) => (
              <TransactionsDataRow
                key={index}
                status={item.status}
                kind={capitalize(item.kind)}
                currency={coinName}
                amount={item.finalAmount ?? 0.0}
                cryptocurrency={''}
                date={moment(item.started_at).format('LL')}
                time={moment(item.started_at).format('LTS')}
                stTrId={item.stellar_transaction_id}
                onCopy={() => onCopy(item.stellar_transaction_id)}
              />
            ))}
          </div>
          {!loading && transactions.length < 1 && (
            <div className="flex justify-center text-white p-16">
              <h3>No History found.</h3>
            </div>
          )}
          {!nextLoading && transactions.length > 0 && renderNext()}
          {loading && (
            <div className="flex justify-center p-16">
              <Loader type="Bars" color="#FFFF00" height={30} width={30} />
            </div>
          )}
          {nextLoading && (
            <div className="flex justify-center p-16">
              <Loader type="Bars" color="#FFFF00" height={30} width={30} />
            </div>
          )}
        </div>
      </div>
      <ConfirmPassword
        isOpen={isConfirmOpen}
        onSuccess={withdraw}
        onClose={closeModal}
      />
    </Fragment>
  );
}

export default Withdraw;

function Chip({ data, coinName, onCopy }) {
  return (
    <li>
      <div className="flex justify-between py-2 w-full border-b border-gray-700">
        <div>
          <h3 className="text-gray-400 text-md font-semibold">
            {coinName} Wthdrawal
          </h3>
          <p className="text-gray-700 text-xs">
            {moment(data.started_at).format('LL')},{' '}
            {moment(data.started_at).format('LTS')}
          </p>
        </div>
        <div className="flex items-center">
          {/*<p className="text-gray-600 mr-16">0 USD</p>*/}
          <p className="text-accent mr-16">
            {data.finalAmount ?? 0} {coinName}
          </p>
          <div className="text-gray-500 flex items-center">
            {data.stellar_transaction_id && (
              <button
                onClick={() => onCopy(data.stellar_transaction_id)}
                className="mr-3 ml-8 focus:outline-none"
              >
                <FontAwesomeIcon icon={faCopy} color="#ffffff" />
              </button>
            )}
            {data.stellar_transaction_id && (
              <a
                href={`https://stellar.expert/explorer/testnet/tx/${data.stellar_transaction_id}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} color="#ffffff" />
              </a>
            )}
          </div>
        </div>
      </div>
    </li>
  );
}

function WithdrawalConfirm({
  done,
  destination,
  amount,
  fee,
  proceed,
  submitting,
  coinName,
}) {
  return (
    <div className="w-full">
      <div className="border border-primary p-5 mb-6 w-full">
        <h2 className="text-white text-md mb-4">
          Please confirm below form to complete withdraw request.
        </h2>
        <div className="border-b border-primary py-4 pr-4 flex justify-between break-all">
          <p className="text-gray-600 text-sm">Destination</p>
          <p className="text-white text-sm">{destination}</p>
        </div>
        <div className="border-b border-primary py-4 pr-4 flex justify-between">
          <p className="text-gray-600 text-sm">Amount</p>
          <p className="text-white text-sm">{amount}</p>
        </div>
        <div className="border-b border-primary py-4 pr-4 flex justify-between">
          <p className="text-gray-600 text-sm">Fee</p>
          <p className="text-white text-sm">
            {parseFloat(fee || '0').toFixed(5)}
          </p>
        </div>
        <div className="border-b border-primary py-4 pr-4 flex justify-between">
          <p className="text-gray-600 text-sm">Total</p>
          <p className="text-white text-sm">
            {(parseFloat(amount) + parseFloat(fee || '0'))?.toFixed(5)}
          </p>
        </div>
        <div className="py-2 pr-4">
          <p className="text-gray-600 text-sm">
            Total Fees inclusive of GWALLET Service fee and {coinName}{' '}
            Blockchain fee
          </p>
        </div>
        <div className="flex mt-4">
          <button
            onClick={proceed}
            className="focus:outline-none text-white py-2 mr-3 bg-accent w-full mt-5 mb-4 rounded"
          >
            {submitting ? (
              <div>
                <FontAwesomeIcon icon={faSync} color="#ffffff" spin /> Please
                wait...
              </div>
            ) : (
              'Withdraw'
            )}
          </button>
          <button
            onClick={done}
            className="focus:outline-none text-white py-2 ml-3 bg-pink w-full mt-5 mb-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

function WithdrawalInit({
  address,
  addressVal,
  amount,
  amountVal,
  balance,
  handleInputChange,
  handleSubmit,
  coinName,
  setPercentAmount,
  percent,
  submitting,
  setChainType,
  chainType,
  showDropdown
}) {
  const getWithdrawMessage = () => {
    if(showDropdown) {
      return `The selected network is ${chainType}. Make sure withdraw address supports this network.`;
    } else if(coinName === "KISHU" || coinName === "SHIB") {
      return `Sending coin or token other than ${chainType} ${coinName} to this address may result in loss of your funds.`
    } else {
      return 'Please complete below form to complete withdraw request.'
    }
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="border border-primary p-5 mb-6 w-full"
    >
      <div className="text-white font-md text-md border-b border-primary py-3 pr-4 flex justify-between">
        <p>Available</p>
        <p className="text-accent">
          {balance} {coinName}
        </p>
      </div>
      <div className="pt-6 pb-4 border-b border-primary">
        <h2 className="text-white">
          {getWithdrawMessage()}
        </h2>
      </div>
      <div className="text-white font-md text-md border-b border-primary pr-4 flex justify-between mt-2">
        {showDropdown && <NetworkType onSelect={setChainType} selected={chainType} />}
        <input
          className={input + ' ml-2'}
          placeholder="Recepient address"
          value={address}
          name="address"
          onChange={handleInputChange}
        />
      </div>
      <p className="text-red italics text-xs">{addressVal}</p>
      <div className="text-white font-md text-md border-b border-primary pr-4 flex justify-between mt-2">
        <input
          className={input}
          placeholder="Amount"
          value={amount}
          name="amount"
          onChange={handleInputChange}
          type="number"
          step="any"
        />
        <div className="hidden sm:block">
          <ToggleButton
            value={10}
            setPercentAmount={setPercentAmount}
            percent={percent}
          />
          <ToggleButton
            value={50}
            setPercentAmount={setPercentAmount}
            percent={percent}
          />
          <ToggleButton
            value={100}
            setPercentAmount={setPercentAmount}
            percent={percent}
          />
        </div>
      </div>
      <p className="text-red italics text-xs">{amountVal}</p>
      
      <div className="flex justify-center sm:justify-end">
        <button
          type="submit"
          className="focus:outline-none text-white py-2 bg-accent px-20 mt-5 mb-4 rounded"
        >
          {submitting && (
            <div>
              <FontAwesomeIcon icon={faSync} color="#ffffff" spin /> Please
              wait...
            </div>
          )}
          {!submitting && 'Withdraw'}
        </button>
      </div>
    </form>
  );
}

let input =
  'text-xxxs md:text-lg xl:text-lg py-3 flex-1 bg-transparent placeholder-primary text-secondaryGray font-Muli leading-tight focus:outline-none';
