import * as React from 'react';

export const FormDataRow = (props:any)=>{
    return(
        <div className="flex justify-between mt-4">
          <p style={{color:props.leftTextColor}} className="text-secondaryGray text-sm mt-2 mb-2 font-regular">
            {props.textleft}
          </p>
          <p style={{color:props.rightTextColor}} className="text-white text-sm mt-2 mb-2 font-regular">
            {props.textRight}
          </p>
        </div>
    );
}