import React, { useState, useEffect } from "react";
import { walletIcon } from "assets/images";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { getSymbol } from "utils/constants";
import { colors } from "styles";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TradeSummary } from "./TradeSummary";
import { PairPriceTable } from "components/organisms/Exchange/PairPriceTable";
import { getSelectedKeys } from "services/trannsactionServices";
import copy from "copy-to-clipboard";
import { useAlert } from "react-alert";
import { lookForAggregate } from "services/exchange";
import moment from "moment";

function WalletCardMin() {
  let {
    transaction: { selectedWallet },
    auth,
  } = useSelector((state: RootState) => state);
  const [key, setKey] = useState(getSelectedKeys().public);
  let alert = useAlert();
  let copyToClip = () => {
    copy(key);
    alert.removeAll();
    alert.show("Copied!", { type: "success" });
  };

  return (
    <div className="xl:flex lg:flex border border-primary">
      <div className="flex">
        {auth.password && (
          <div className="flex justify-between w-full items-center py-2 pl-2 whitespace-no-wrap">
            <div className="flex items-center">
              <img
                src={walletIcon}
                className="self-start h-3 w-3 xl:h-5 xl:w-4"
              />
              <text className="text-white text-sm md:text-base lg:text-base xl:text-lg font-semibold ml-3 mr-5">
                {selectedWallet.name || "WALLET #1"}
              </text>
            </div>
            <text className="text-white hidden sm:block text-xs xl:text-xs mx-4">
              {getSymbol()}
              {parseFloat(selectedWallet.usdBalance.toString()).toFixed(2)}
            </text>
            <text className="text-white hidden sm:block text-xxs lg:text-xs md:text-xs xl:text-xs ml-4 mr-3 text-secondaryGray">
              {parseFloat(selectedWallet.btcBalance.toString()).toFixed(5)} BTC
            </text>
            <text
              style={{ color: colors.green }}
              className="self-center mr-2 hidden sm:block text-xxxs text-white md:text-sm lg:text-sm xl:text-xs"
            >
              +3.65%
            </text>
          </div>
        )}
        {auth.password && (
          <button
            onClick={copyToClip}
            className="focus:outline-none bg-primary text-gray-600 p-1 xl:p-2 lg:p-2 text-xs flex justify-between items-center"
          >
            <p className="overflow-x-auto mr-2 text-xxxs lg:text-xs md:text-xs nobar">
              {key?.substring(0, 2) +
                "..." +
                key?.substring(key.length, key.length - 3)}
            </p>
            <FontAwesomeIcon icon={faCopy} />
          </button>
        )}
        <PairPriceTable />
      </div>
      <TradeSummary />
    </div>
  );
}

export default WalletCardMin;
