export const CREATE_ACCOUNT_PENDING = "CREATE_ACCOUNT_PENDING";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR";
export const SAVE_PASSWORD = "SAVE_PASSWORD";
export const LOGOUT_USER = "LOGOUT_USER";

interface CreateAccountSuccess {
  type: typeof CREATE_ACCOUNT_SUCCESS;
  payload: {};
}
export interface CreateAccountPanding {
  type: typeof CREATE_ACCOUNT_PENDING;
  isFetching: boolean;
}

export interface SavePassword {
  type: typeof SAVE_PASSWORD;
  password: string;
}

export interface LogoutUser {
  type: typeof LOGOUT_USER;
  payload: false;
}

export type GetAuthActionsType =
  | CreateAccountPanding
  | CreateAccountSuccess
  | SavePassword
  | LogoutUser;
