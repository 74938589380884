import * as React from 'react';
const Hamburger = ({hamburger, onPress}) => {
    return (
        <button style={{ marginTop: -15, left: 15 }} id="hamBuger" className="absolute hamBuger items-center text-white focus:outline-none hover:text-white hover:border-white" onClick={(e)=>{e.stopPropagation(); onPress()}}>
            {!hamburger&&<svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>}
            {hamburger&&<svg
                  className=" h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>}
        </button>
    )
}

export default Hamburger;