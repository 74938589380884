import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

function FAQs() {
  return (
    <div className="border border-gray-600 p-4 w-full">
      <ul>
        <li>
          <div className="px-2 py-2">
            <h3 className="text-xl text-white mt-1 mb-3">
              Frequently Asked Questions(FAQs)
            </h3>
            <p className="text-gray-700 text-sm">
              We always appreciate if you took a minute and look at the most
              common questions.
            </p>
          </div>
        </li>
        <FaqTile
          title="What is a Keystore Wallet File?"
          description="A keystore wallet file is a file that stores our secret key in encrypted form. You get to choose a password to encrypt the file with. This way, if a hacker gets your file, they would still need the password that you chose in order to access your wallet. 
          This means, that if you choose a strong password, you can more safely back the file up in the cloud without worrying about hacks. When you login to Gwallet, you will be asked for your keystore file AND your password. You should back up both of these pieces of information (as GWallet does NOT store your keystore wallet file or your password). 
          If you are worried about accessing your private key if you use this method, don't worry. You can see your secret key once you log in at Wallet > Profile > Advanced. However, please be sure you really want to access your secret key. Understand, you already have your secret key in your keystore file but in a third-party audited, securely encrypted form. Once you have your secret key, you will have to protect it!"
        />
        <FaqTile
          title="How do I buy XLM (Stellar Lumens)?"
          description="Purchasing XLM via Gwallet is super easy. You can buy XLM in a minute by following these 3 easy steps:
          Log in to your wallet (create one if you don't already have one yet). 
          Go to the Deposit page and deposit one of the many deposit options available.
          Trade the newly deposited asset for XLM on the exchange page.
          That's it. Crypto is easy!"
        />
        <FaqTile
          title="How do I get my/our token listed on Gwallet?"
          description={`Gwallet is a decentralized trading client. This means that the Gwallet portal allows users to interact with the Stellar decentralized exchange.
          One of the fundamental properties of a decentralized exchange is that nobody controls the exchange. This means that there is nobody who determines what is or is not listed on the exchange. Instead, anybody can list a token on the exchange for trade.
          To list a token on Gwallet, you need to do several things.
          The first thing is list your token on the Stellar decentralized exchange (DEX). The how to's of listing a token on the DEX is beyond the scope of this article. For more information regarding listing a token on the Stellar DEX see this guide and this guide. However, please be sure NOT TO LOCK YOUR ISSUING ACCOUNT until you finished all the steps in this article.
          Once you have listed a token on the DEX, it is time to add a home domain to your issuing account. Adding a home domain is a standard by which you as a token issuer broadcast to the rest of the network where they can find more information regarding you and your token. You can learn more about a home domain here.
          Once you have added your home domain, you need to publish additional information at that domain so that client applications like Gwallet can search that domain and verify that you, as the token issuer, truly own that domain - as well as additional information on your token like an image, description etc. There is an existing standard on how to publish this kind of information called a stellar.toml. To learn more about the stellar.toml file see here.
          OPTIONAL - You may want your asset to have a "Known" status on Gwallet. If so, there is a separate Known status application process that you must go to. To begin this separate process, send an email to listings@Gwallet.io.
          That is it. You should be able to navigate to Gwallet and see your token listed complete with its image and description. If it doesn't show up right away, give it an hour or so.
          We do NOT allow standard assets to deauthorize trustlines. If we find your standard asset is deauthorizing trustlines, your asset will be blacklisted. ONLY authorized assets may authorize and deauthorize trustlines.`}
        />
        <FaqTile
          title="What is the difference between a market order and a limit order?"
          description="Limit orders allow you to set a maximum purchase price for your buy order, or a minimum sale price for your sell orders. So long as no counter-offers exist to match your limit price, your order will not be executed.
          Market orders, in contast, will fill the moment there is any counter-offer whatsoever, even partially, and at any price."
        />
        <FaqTile
          title="Why was my market order filled so far from the last trade price?"
          description={`Stellar offers trading in any pair of assets, no matter how illiquid that pair. In an illiquid market, i.e. a market (pair of assets to trade) in which there may not be enough existing counter-offers to fill your order at a "reasonable" price, a market order will simply fill against whatever counter-offers are available, no matter how "unreasonably" far they are from the last executed price.
          In illiquid markets (markets without a lot of orders), it is a good practice to avoid market orders entirely and just use limit orders.`}
        />
        <FaqTile
          title="How do I deposit BTC, ETH, XRP or any other asset to my Gwallet wallet?"
          description="Depositing Crypto to your Gwallet wallet is super easy. To deposit, simply login to your wallet and click on the deposit option in the nav bar.
          Follow the instructions to deposit your cryptocurrency of choice. Then, simply wait for the deposit to confirm and that is it. Simple as that.
          See this step by step tutorial for more information on how to deposit to your Stellar wallet using Gwallet.
          After you have deposited, you can trade BTC, ETH, XRP and LTC for XLM easily using the Gwallet exchange screen."
        />
        <FaqTile
          title="How do I deposit fiat currency, such as U.S. dollars?"
          description="Gwallet is not yet licensed to offer deposits or withdrawals of physical assets such as US Dollars, other fiat currencies or securities. We will be enabling deposits and withdrawals of certain assets soon. We will announce these as Please check back frequently.
          For the  time being, if you would like to deposit USD or some other fiat currency for the purchase of XLM or some other Stellar asset, you will need to use a two step process.
          Deposit USD (or your fiat of choice) to some exchange that accepts fiat (like Coinbase, Bitstamp etc.) and buy BTC or ETH
          Deposit BTC or ETH in your Gwallet wallet using this guide and use the deposited funds  to purchase XLM (or your Stellar asset of choice)."
        />
        <FaqTile
          title="How do I withdraw assets from Stellar?"
          description="How you withdraw from your Gwallet wallet depends on what you mean by withdraw. If you would like to withdraw to another stellar account, then all you need to do is send a payment for some amount of that asset to the other stellar account. 
          If you would like to withdraw to some external account (e.g. you would like to withdraw BTC to a bitcoin wallet):
          Login to your wallet
          Go to the withdrawal tab
          Follow the instructions there to withdraw assets"
        />
        
      </ul>
    </div>
  );
}

export default FAQs;

export function FaqTile({ title, description }) {
  let [isOpen, setOpen] = useState(false);
  return (
    <li>
      <div className="border-b border-gray-700 px-2 py-6">
        <button
          className="focus:outline-none flex items-center"
          onClick={() => setOpen(!isOpen)}
        >
          <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} color="#008000" />
          <h3 className="text-gray-600 ml-3">{title}</h3>
        </button>
        {isOpen && (
          <p style={style} className="p-2 rounded text-gray-600 text-sm mt-5">
            {description}
          </p>
        )}
      </div>
    </li>
  );
}

let style = { backgroundColor: "rgba(255,159,0,0.15)" };
let descriptionGlobal =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.";
