import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { calcAmountWSpread, changeMyAddStatus } from "services/p2pServives";
import { changeAddStatusAction, getNextMyAddsAction } from "store/actions/P2pActions";
import { MyAdCard } from "./MyAdCard";
import { useAlert } from "react-alert";
import { getPaymentText } from "utils/constants";

function MyAdds({ loadingBuy, loadingSell, usdRates, myBuyAdds, mySellAdds, coin, myBuyAddsPage, mySellAddsPage }) {
  const alert = useAlert()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    submitting: false,
  });
  const getAssetRate = (asset) => {
    const assetRates = usdRates.find(
      (item) => item.short.toUpperCase() === asset.toUpperCase()
    );
    return assetRates?.currentRate||0;
  }

  const renderRows = (data) =>
    data.map((item, index) => (
      <div className="mt-2" key={index}>
        <MyAdCard
          status={item.status}
          maxamount={item.maxQty}
          currency={item.currency}
          payment={getPaymentText(item)}
          advertiser={"Alfred Curry"}
          onAction={(status) => changeAddStatus({ id: item.id, status, availableAmount: calcAmountWSpread(item), minAmount: item.minQty })}
          isProfile={true}
          item={item}
          usdRate={getAssetRate(item.assetCode)}
        />
      </div>
    ));

  const changeAddStatus = ({id, status, availableAmount, minAmount}) => {
    if(status === "Active" && availableAmount < minAmount){
      alert.show("Not enough balance in your ad.")
      return
    }
    setState((prev) => ({ ...prev, submitting: true }));
    changeMyAddStatus({id, status})
      .then((data) => {
        const { type } = data.data.ad
        setState((prev) => ({ ...prev, submitting: false }));
        dispatch(changeAddStatusAction(coin, id, type==='buy'?'myBuyAdds':'mySellAdds'))
      })
      .catch((e) => {
        setState((prev) => ({ ...prev, submitting: false }));
      });
  };
  const handleOnNext = (type) => {
    if(type == 'buy'){
      dispatch(getNextMyAddsAction(coin, type, myBuyAddsPage+1))
    }else{
      dispatch(getNextMyAddsAction(coin, type, mySellAddsPage+1))
    }
  }

  const renderLoading = () => (
    <div className="flex justify-center items-center my-4 h-full">
      <Loader type="Bars" color="#FFFF00" height={30} width={30} />
    </div>
  );

  const renderNoData = () => (
    <div className="flex justify-center items-center my-10 h-full text-gray4 text-xs">
      <h3>No Ads</h3>
    </div>
  );

  const renderLoadMore = (type) => (
    <div className="flex justify-center">
      <button
        onClick={() => handleOnNext(type)}
        className="focus:outline-none border border-secondaryGray p-2 text-secondaryGray my-4"
      >
        Load More
      </button>
    </div>
  );

  return (
    <div>
      <div>
        <h2 className="text-white text-lg">Your Buying Ads</h2>
        {renderRows(myBuyAdds)}
        {!loadingBuy && myBuyAdds.length < 1 && renderNoData()}
        {loadingBuy && renderLoading()}
        {!loadingBuy && myBuyAdds.length > 0 && renderLoadMore('buy')}
      </div>
      <div>
        <h2 className="text-white /text-lg">Your Selling Ads</h2>
        {renderRows(mySellAdds)}
        {!loadingSell && mySellAdds.length < 1 && renderNoData()}
        {loadingSell && renderLoading()}
        {!loadingSell && mySellAdds.length > 0 && renderLoadMore('sell')}
      </div>
    </div>
  );
}

export default MyAdds;
