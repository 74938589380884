import React, { useState, useEffect, Fragment } from "react";
import SmallNavButtons from "./TopNavigationBar/SmallNavButtons";
import MainNavButtons from "./TopNavigationBar/MainNavButtons";
import Hamburger from "components/atomic/TopNavigationBar/HamBurger";
import PrimaryButton from "components/atomic/primaryButton";
import { DropDown } from "components/global";
import { logoImageStyle } from "styles/ButtonStyles";
import { walletIcon, selfieIcon, menuDropdownIcon } from "assets/images";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
import { RootState } from "store/reducers";
import { getData, getPreferences } from "services/storageServices";
import LangCurrencySet from "./LangCurrencySet";
import { getWallets } from "services/dbServices";
import { useAlert } from "react-alert";
import { BASE_URL } from "utils/AppConstants";
import NotificationDrop from "./NotificationsDrop";
import { updateAssetsRates } from "store/actions/TransactionActions";
const logo = require("../../assets/images/G Wallet Icon@2x.png");

const Header = (props: any) => {
  const [hamburger, setHamburger] = useState(false);
  let location = useLocation();
  let {
    auth,
    generic: { notification },
    market: { markets },
  } = useSelector((state: RootState) => state);
  const user = useSelector((state: RootState) => state.profile.user);
  let history = useHistory();
  let [isOpen, setOpen] = useState(false);
  const alert = useAlert();
  const [firstLoad, setFirst] = React.useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (notification !== "") {
      alert.show(notification, { type: "success" });
    }
  }, [notification]);

  useEffect(() => {
    window.onclick = function (event) {
      let btn = document.getElementsByClassName("hamBuger")[0];
      let cntnt = document.getElementsByClassName("navCollapsed")[0];
      if (!btn?.contains(event.target) && !cntnt?.contains(event.target)) {
        setHamburger(false);
      }
    };
  }, []);

  useEffect(() => {
    history.listen((locationNew, action) => {
      ReactGA.set({ page: locationNew.pathname });
      ReactGA.pageview(locationNew.pathname);
    });
  }, []);

  React.useEffect(() => {
    if (!firstLoad) {
      dispatch(updateAssetsRates());
    }
    setFirst(false);
  }, [markets]);

  const getText = () => {
    let { CURRENCY, LANGUAGE } = getPreferences();
    return LANGUAGE + " / " + CURRENCY;
  };

  const isWallet = (callback) => {
    getWallets().then((wallets: any) => {
      if (wallets.length > 0) {
        callback(true);
      } else {
        callback(false);
      }
    });
  };

  if (location.pathname == "/privacy-policy") return <div></div>;

  return (
    <Fragment>
      <div className="flex bg-primary px-2 items-center justify-center xl:justify-between lg:justify-between sticky top-0 z-40">
        <div className="lg:hidden xl:hidden">
          <Hamburger
            onPress={() => setHamburger(!hamburger)}
            hamburger={hamburger}
          />
        </div>

        <div className="flex items-center my-3 xl:my-0 lg:my-0 md:my-0 cursor-pointer">
          <img className={logoImageStyle} src={logo} />
          <NavLink
            className="text-white font-bold ml-5 md:text-md lg:text-md xl:text-md"
            to="/"
          >
            G WALLET
          </NavLink>
          <MainNavButtons auth={auth.password ? true : false} />
        </div>

        <div className="hidden md:hidden sm:hidden xl:flex lg:flex">
          {auth.password && auth.password !== "" ? (
            <div className="flex items-center">
              <button
                onClick={(e) => {
                  isWallet((res) => {
                    if (res) {
                      history.push("/walletScreen");
                    }
                  });
                }}
                className="focus:outline-none"
              >
                <div className="flex items-center">
                  <img className="h-6 w-7 mr-2" src={walletIcon} />
                  <p className="font-regular text-white text-md">Wallets</p>
                </div>
              </button>
              <NotificationDrop />
              {user?.file ? (
                <button
                  className="focus:outline-none p-0 mx-6 bg-secondaryGray rounded-full"
                  onClick={(e) => {
                    isWallet((res) => {
                      if (res) {
                        history.push("/profileScreen");
                      }
                    });
                  }}
                >
                  <img
                    className="h-12 w-12 rounded-full"
                    src={BASE_URL + "/files/" + user?.file}
                  />
                </button>
              ) : (
                <button
                  className="focus:outline-none p-3 mx-6 bg-secondaryGray rounded-full"
                  onClick={(e) => {
                    isWallet((res) => {
                      if (res) {
                        history.push("/profileScreen");
                      }
                    });
                  }}
                >
                  <img className="h-5 w-5" src={selfieIcon} />
                </button>
              )}
              <div className="w-1 h-8 border-r border-secondaryGray" />
              <div className="flex items-center cursor-pointer mr-10">
                <button
                  onClick={() => setOpen(true)}
                  className="text-white font-regular ml-2 w-14 px-4 py-1 pr-8 text-md bg-primary"
                >
                  {getText()}
                </button>
              </div>
            </div>
          ) : (
            <>
              <NavLink to="/login">
                <div
                  className={
                    location.pathname !== "/" ? "border-b border-accent" : ""
                  }
                >
                  <PrimaryButton isActive title="Login" color="bg-primary" />
                </div>
              </NavLink>
              {!getData("bcrypt_hash") && (
                <NavLink to="/register">
                  <PrimaryButton
                    title="Register"
                    mx=" mx-5"
                    color=" bg-accent"
                  />
                </NavLink>
              )}
              <div className="flex items-center cursor-pointer mr-10">
                <button
                  onClick={() => setOpen(true)}
                  className="focus:outline-none text-white font-regular ml-2 w-14 px-4 py-1 pr-8 text-md bg-primary"
                >
                  {getText()}
                </button>
              </div>
            </>
          )}
        </div>
        <LangCurrencySet isOpen={isOpen} onClose={() => setOpen(false)} />
      </div>
      <SmallNavButtons
        hamburger={hamburger}
        onNav={() => setHamburger(false)}
        auth={auth.password}
      />
    </Fragment>
  );
};

export default Header;
