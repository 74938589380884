import * as React from "react";
import { SwapCard } from "components/molecular/Swap/SwapCard";
import { SwapForm } from "components/molecular/Swap/SwapForm";
import useSwap from "./hooks/useSwap";
import AddAssetModal from "components/molecular/Wallet/AddAssetModal";

export const SwapScreen = () => {
  let {
    state: {
      isOpen,
      type,
      path,
      sellling,
      finding,
      buying,
      amount,
      amountVal,
      errorMessage,
      paying,
      fee,
    },
    setInput,
    handleSubmit,
    onClose,
    openSell,
    openBuy,
    onSuccess,
    createPath,
    getAssetCode,
    replaceAsset,
    handleCancel,
    sendTransaction,
  } = useSwap();

  return (
    <div className="w-5/6 md:w-4/6 lg:w-4/6 xl:w-4/6 mx-auto">
      <p className="font-regular text-center text-white text-xl mt-4 mb-6">
        Swap
      </p>
      <SwapCard
        swapFrom={sellling}
        swapTo={buying}
        setSell={openSell}
        setBuy={openBuy}
        handleSubmit={replaceAsset}
      />
      <SwapForm
        amount={amount}
        amountVal={amountVal}
        setInput={setInput}
        path={path}
        createPath={createPath}
        getAssetCode={getAssetCode}
        errorMessage={errorMessage}
        submit={handleSubmit}
        finding={finding}
        handleCancel={handleCancel}
        sendTransaction={sendTransaction}
        paying={paying}
        sellingCoin={sellling?.short?.toUpperCase()}
        fee={fee}
        swapFrom={sellling}
        swapTo={buying}
      />
      <AddAssetModal
        isOpen={isOpen}
        onClose={onClose}
        type={type}
        onSuccess={onSuccess}
      />
    </div>
  );
};
