import * as React from "react";
import * as Images from 'assets/images/index';
export const TransactionInfoBlock = (props: any) => {
  return (
    <div>
      <div className="flex items-center">
        <p className="font-regular text-xs md:text-sm lg:text-sm xl:text-sm text-secondaryGray mb-1">{props.transType}</p>
        <img src={props.transType=="Send"? Images.sendArrowIcon : props.transType =="Best Path"? Images.bestPathIcon: Images.receiveArrowIcon} className="ml-1 h-3 w-2" />
      </div>
      <p className="font-regular text-xs md:text-xs lg:text-xs xl:text-xs text-smokyWhite">{props.transType=="Receive"? "≈" :null} {props.amount || props.bestPath}</p>
    </div>
  );
};
