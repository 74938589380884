import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const NoticeAlert = () => {
  const [closed, setClosed] = React.useState(false);
  const handleClose = () => {
    setClosed(true);
  };

  if (true) {
    return <div></div>;
  }
  return (
    <div className="w-full bg-pink py-2 px-4 text-white fixed bottom-0 z-50">
      <button
        className="focus:outline-none absolute"
        style={{ right: "10px" }}
        onClick={handleClose}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <h4 className="font-semibold">Dear GWALLET Users,</h4>
      <p>
        Due to routine security maintenance, GWallet will perform security
        updates from 18:00 to 24:00 (UTC) on February 24, 2021. During the
        maintenance period, users are advised not to do any transaction to avoid
        future issues.
      </p>
      <p>
        We apologize for any inconvenience this may cause, and thank you for
        your patience.
      </p>
      <p>Thanks for your support!</p>
    </div>
  );
};

export default NoticeAlert;
