import { getSelectedKeys } from "services/trannsactionServices";
import { saveData, getData } from "services/storageServices";
import { encryptWalletSecret } from "services/dbServices";
import { store } from "App";

var CryptoJS = require("crypto-js");

const filterOverAllRecords = (records, assets) => {
    let wallet = getSelectedKeys().public

    let trx:any = [];
    records.forEach((record) => {
      let coin = record.asset_code ?? (record.asset_type == "native" ? "XLM" : "XLM");
      if(!existInLive(record, assets) || record.type == "create_account"){
          let data = {
            kind: record.type == "payment" ? record.to == wallet ? "Received":"Sent": record.type==="path_payment_strict_send"?"Received": record.source_account===wallet?"Sent":"Received",
            status: "completed",
            finalAmount: record.amount ?? record.starting_balance,
            started_at: record.created_at,
            wallet,
            coin: coin,
            stellar_transaction_id: record.transaction_hash
          };
          trx.push(data)
      }
    });
    return trx
  }

  const processTransactions = (records:[]) => {
    let wallet = getSelectedKeys().public
    let response:any = [];
    records.forEach((record:any)=>{
      let data = {
          kind: record.kind,
          status: record.status,
          finalAmount: record.finalAmount,
          started_at: record.started_at,
          wallet,
          coin: record.asset_code,
          stellar_transaction_id: record.stellar_transaction_id
        };
        response.push(data)
    })
    return response
  }
  
  const existInLive = (record, assets:[]) => {
    let response = false;
    assets.forEach((asset:any)=>{
      if(record.from == asset?.distributionPublic || record.to == asset?.distributionPublic){
        response = true;
      }
    })
    return response;
  }

  const encryptText = (text, password) => {
    if(text.length == 56){
      return CryptoJS.AES.encrypt(text, password).toString();
    }else{
      return text;
    }
  }

  const decrypt = (text) => {
    const {password} = store.getState().auth
    
    if(text.length == 56){
      encryptOld(text, password)
      return text
    } else {
      let bytes = CryptoJS.AES.decrypt(text, password);
      let key;
      try{
        key = bytes.toString(CryptoJS.enc.Utf8);
      }catch(e){
        if(e.message === "Malformed UTF-8 data"){
          key = ""
        }
      }
      
      if(key === ""){
        let bytes = CryptoJS.AES.decrypt(text, "SuperSecret");
        return bytes.toString(CryptoJS.enc.Utf8);
      }else{
        return key
      }
    }
  }

const encryptOld = (text, password) => {
  let selected:any = JSON.parse(getData("selectedWallet")??'{}');
  let encrypted = encryptText(text, password)
  
  saveData({
    selectedWallet: JSON.stringify({
      public: selected.public,
      private: encrypted,
      walletId: selected.walletId,
    }),
  });
  encryptWalletSecret(text, encrypted)
}

const getTransactionType = (record, wallet) => {
  return record.type == "payment" ? record.to == wallet ? "Received":"Sent": record.type==="path_payment_strict_send"?"Received": record.source_account===wallet?"Sent":"Received"
}

export {
    filterOverAllRecords,
    processTransactions,
    getTransactionType,
    encryptText,
    decrypt
}