import {
    SAVE_PASSWORD, GetAuthActionsType, LOGOUT_USER
  } from "../actions/AuthActionType";
  import { AuthState } from "../models/AuthState";
  
  const initialState: AuthState = {
    password: '',
  };
  
  export function authReducer(
    state = initialState,
    action: GetAuthActionsType
  ): AuthState {
    switch (action.type) {
      case SAVE_PASSWORD:
        return {
          ...state,
          password: action.password,
        };
        case LOGOUT_USER:
          return {
            ...state,
            password: action.payload,
          };
      default:
        return state;
    }
  }
  