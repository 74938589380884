import { useState, useEffect } from "react";
import {useAlert} from "react-alert";
import { authenticate } from "services/authServces";
import { useHistory } from "react-router-dom";
import { getData } from "services/storageServices";
import { useDispatch } from "react-redux";
import { savePassword } from "store/actions/AuthActions";
import { lookForPayments, getSelectedKeys } from "services/trannsactionServices";
import { setCoinsAction, transactionsHistoryAction } from "store/actions/TransactionActions";
import { initWithToken } from "services/p2pServives";
import { getProfileInfoAction } from "store/actions/ProfileActions";
import { reInitP2p } from "store/actions/P2pActions";
import ZakatManager from "utils/ZakatManager";


let useLogin = () => {
  let [inputs, setInputs] = useState({
    password: "",
    passwordValidation: ""
  });
  let [submitting, setSubmitting] = useState(false)
  let alert = useAlert();
  let history = useHistory();
  const dispatch = useDispatch();

  let handleInputChange = (e) => {
    let { value } = e.target;
    
    setInputs((prev) => ({ password: value, passwordValidation: "" }));
  };
  
  let handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    setSubmitting(true);
    let url = getData('url');
    let res = authenticate(inputs.password);
    if(res === 'LOCAL_LOGIN'){
      dispatch(savePassword(inputs.password))
      //dispatch(getProfileInfoAction())
      dispatch(reInitP2p())
      lookForPayments(getSelectedKeys().public, (message) => {
        alert.show(message, {type: "success"});
        dispatch(setCoinsAction());
        dispatch(transactionsHistoryAction(true))
      });
      initWithToken()
      ZakatManager.checkZakat()

      history.replace('/walletScreen');
    }
    if(res === 'CREATE_WALLET'){
      dispatch(savePassword(inputs.password))
        history.push('/newWallet');
    }
    if(res === 'TRY_AGAIN'){
      alert.removeAll();
      alert.show('Incorrect password please try again.', {type: 'error'});
    }
    if(res === 'CREATE_ACCOUNT'){
      alert.removeAll();
      alert.show('Create new password.', {type: 'error'});
      history.push('/register')
    }
    setSubmitting(false)
  };

  let isValidated = () => {
    let response = true;

    if (!inputs.password) {
      response = false;
      setInputs((prev) => ({ ...prev, passwordValidation: "Password is required." }));
    }

    if(submitting){
      response = false;
    }

    return response;
  };
  
  return { ...inputs, handleInputChange, handleSubmit, submitting };
};

export default useLogin;


