import * as React from "react";
import { AdvertisementFormTopRow } from "components/molecular/P2P/AdvertisementForm/advertisementFormTopRow";
import { AdvertiserCol } from "components/molecular/P2P/AdvertisementForm/AdvertiserCol";
import { DataCol } from "components/molecular/P2P/AdvertisementForm/DataCol";
import { paymentMethodsEnum, paymentMethodsTextEnum } from "screens/hooks/P2P/useAdd";
import { paymentTypeIconsEnum } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLandmark } from "@fortawesome/free-solid-svg-icons";

export const AdvertisementDetailsCard = (props: any) => {
  const {
    unitPrice,
    currency,
    maxQty,
    minQty,
    payment_method,
    bank_name,
    location,
    timeFrame,
    assetCode,
    bank_account_number,
    available_amount,
    type,
    sellerUSDRate,
    buyerUSDRate,
    payoneer_email,
    skrill_email,
    isCompanyKyc,
    companyName,
    first_name,
    last_name,
    isLocal,
  } = props.add;
  const avAmount = props.calcAmountWSpread()

  const paymentInfo = () => {
    if(payment_method === paymentMethodsEnum.Payoneer){
      return payoneer_email
    }
    if(payment_method === paymentMethodsEnum.Skrill){
      return skrill_email
    }
    return bank_account_number
  }
  const paymentInfoTitle = () => {
    if(payment_method === paymentMethodsEnum.Payoneer){
      return "Payoneer Email Address"
    }
    if(payment_method === paymentMethodsEnum.Skrill){
      return "Skrill Email Address"
    }
    return "Bank account number"
  }
  const paymentTypeIcon = () => {
    if(payment_method === paymentMethodsEnum.Payoneer || payment_method === paymentMethodsEnum.Skrill){
      return <img src={`/assets/${paymentTypeIconsEnum[payment_method]}.svg`} className="w-16 my-1" />
    }

    return <div>
      <FontAwesomeIcon icon={faLandmark} className="mr-2" />
      Local Bank Transfer
    </div>
  }

  return (
    <div className="w-4/6 mx-auto">
      <div className="border-primary border mt-5">
        <AdvertisementFormTopRow />
        <div className="lg:flex lg:justify-between items-center mt-2 border-primary border-b mx-4">
          <div className="w-full lg:w-2/3 xl:w-2/3">
            <AdvertiserCol buy={props.buy} first_name={isCompanyKyc?companyName:first_name} last_name={isCompanyKyc?"":last_name} />
          </div>
          <div className="w-full lg:w-1/3 xl:w-1/3">
            <DataCol heading="Price" text={`${type==='buy'?sellerUSDRate:buyerUSDRate} ${currency}/${assetCode==="usdt"?"":assetCode}USD`} />
          </div>
        </div>
        <div className="lg:flex lg:justify-between items-center mt-2 border-primary border-b mx-4">
          <div className="w-full lg:w-2/3 xl:w-2/3">
            <DataCol
              heading="Amount limits"
              text={`${minQty} ${assetCode?.toUpperCase()} - ${Number(Number(maxQty)>avAmount?avAmount:maxQty).toFixed(7)} ${assetCode?.toUpperCase()}`}
              // text={`${minQty} ${assetCode?.toUpperCase()} - ${Number(maxQty).toFixed(7)} ${assetCode?.toUpperCase()}`}
            />
          </div>
          <div className="w-full lg:w-1/3 xl:w-1/3">
            <DataCol
              heading="Payment method"
              text={paymentTypeIcon()}
            />
          </div>
        </div>
        <div className="lg:flex lg:justify-between items-center mt-2 border-primary border-b mx-4">
          <div className="w-full lg:w-2/3 xl:w-2/3">
            <DataCol heading="Location" text={isLocal?location:"International"} />
          </div>
          <div className="w-full lg:w-1/3 xl:w-1/3">
            <DataCol heading="Payment window" text={`${timeFrame} Minutes`} />
          </div>
        </div>
        {!props.buy&&<div className="lg:flex lg:justify-between items-center mt-2 border-primary border-b mx-4">
          <div className="w-full lg:w-2/3 xl:w-2/3">
            <DataCol heading={paymentInfoTitle()} text={paymentInfo()} />
          </div>
          <div className="w-full lg:w-1/3 xl:w-1/3">
            <DataCol heading="Available amount" text={`${Number(avAmount).toFixed(7)} ${assetCode?.toUpperCase()}`} />
            {/* <DataCol heading="Available amount" text={`${Number(props.add.available_amount).toFixed(7)} ${assetCode?.toUpperCase()}`} /> */}
          </div>
        </div>}
      </div>
    </div>
  );
};
