import Axios from "axios";
import { BASE_URL, postOptions, STELLAR_URL } from "utils/AppConstants";
import {
  getSelectedKeys,
  signTransaction,
  getSelectedSecret,
} from "./trannsactionServices";
import { JWTRes, ZakatEntry } from "store/models/Generic";
import { getData, getPreferences, saveData } from "./storageServices";
import { store } from "App";
import { fetchCurrenciesSuccess } from "store/actions/GenericActions";
import { Currency } from "store/models/GenericState";
import { Asset } from "store/models/Assets";
import { fetchAssetsSuccess } from "store/actions/AssetsActions";
import toml from "toml";
import moment from "moment";

const uploadFile = async (file, token) => {
  return new Promise((resolve, rejects) => {
    const data = new FormData();
    data.append("file", file);
    // debugger;
    Axios.post(BASE_URL + "/kyc/uploadDocument", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        // console.log(e);
        rejects(e);
      });
  });
};
export const updateProfilePic = async (file, token, id) => {
  return new Promise((resolve, rejects) => {
    const data = new FormData();
    data.append("file", file);
    data.append("id", id);

    Axios.post(BASE_URL + "/kyc/uploadSelfie", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        // console.log(e);
        rejects(e);
      });
  });
};

const getJWT = async () => {
  const auth = store.getState().auth;
  if (!auth.password) {
    return { data: { token: "" } };
  }
  const token = getData("myToken");
  if (token && token !== "") {
    return { data: { token: token } };
  }
  let account_id = getSelectedKeys().public;
  let account_secret = getSelectedSecret();

  try {
    let data: JWTRes = await Axios.get(
      BASE_URL + `/anchor/authentication/?account=${account_id}`
    );

    if (data.statusText === "OK") {
      let trx = await signTransaction(account_secret, data.data);
      let token: any = await submitSignedTrx(trx);
      saveData({ myToken: token.data.token });
      return token;
    }
  } catch (e) {
    console.log(e, e.response);
    return e;
  }
};

const submitSignedTrx = async (trx) => {
  return new Promise((resolve, rejects) => {
    Axios.post(BASE_URL + "/anchor/authentication", { transaction: trx })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        // console.log(e);
        rejects(e);
      });
  });
};

const fetchInitial = async () => {
  // debugger
  let url = BASE_URL + "/coins/currenciesList";
  let { CURRENCY } = getPreferences();
  let currencies: Currency[] = await fetch(url).then((data) => data.json());
  store.dispatch(fetchCurrenciesSuccess(currencies));

  let url2 = BASE_URL + "/coins/getAssets?currencyCode=" + CURRENCY;
  let assets: Asset[] = await fetch(url2).then((data) => data.json());

  store.dispatch(fetchAssetsSuccess(assets, CURRENCY));
  return [currencies, assets];
};

const fetchTransactions = async (coin, kind, page) => {
  let url =
    BASE_URL +
    `/sep24/transactions?asset_code=${coin}&kind=${kind}&paging_id=${page}`;

  let token = await getJWT();

  let res = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  if (res.status === 200) {
    return res.data;
  }
};

const fetchOverAllTransactions = async (page) => {
  let url = BASE_URL + "/sep24/overalltransactions?paging_id=" + page;
  let token = await getJWT();

  let res = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return res;
};

const fetchRawCoinPairs = async () => {
  let url = BASE_URL + "/coins/getMarketData?currencyCode=USD";
  let res = await Axios.get(url);

  if (res.status === 200) {
    return res.data;
  }
};

const fetchFederation = async (address) => {
  let url = BASE_URL + `/federation?q=${address}&type=id`;
  let token = await getJWT();
  let res = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  if (res.status === 200) {
    return {
      federationName: res.data?.stellar_address,
      domain: res.data?.domain,
    };
  }
};
export const fetchZakatPayment = async () => {
  let url =
    BASE_URL + `/zakat-history/getZakatPaymentInfo?year=${moment().year()}`;

  let token = await getJWT();
  let res = await Axios.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return res;
};
export const addZakatEntry = async (body: ZakatEntry) => {
  let url = BASE_URL + `/zakat-history/addZakatHistory`;
  let token = await getJWT();
  let res = await Axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return res;
};
const fetchDeposit = async (coin, type) => {
  let url = BASE_URL + `/sep24/transactions/deposit/interactive`;
  let urlHistory =
    BASE_URL +
    `/sep24/transactions?asset_code=${coin}&kind=deposit&paging_id=1`;

  let token = await getJWT();
  let deposit = await Axios.post(
    url,
    {
      asset_code: coin,
      account: getSelectedKeys().public,
      chainshort: type,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );

  let history = await Axios.get(urlHistory, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return [deposit, history];
};

const withdrawRequest = async (body) => {
  let url = BASE_URL + "/sep24/transactions/withdraw/interactive";
  let token = await getJWT();
  let res = await Axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return res;
};

const getStellarTransactions = async (path, limit) => {
  let url;
  if (path) {
    url = path;
  } else {
    url = `${STELLAR_URL}/accounts/${
      getSelectedKeys().public
    }/payments?limit=${limit}&order=desc`;
  }
  let res = await Axios.get(url);
  console.log(res);
  return res;
};

const fetchToml = async () => {
  let res = await Axios.get("/.well-known/stellar.toml");
  if (res.status == 200) {
    let data = toml.parse(res.data);
    return data;
  }
};

const fetchInternalFee = async (coin) => {
  let res = await Axios.get(BASE_URL + "/sep24/internalFee?asset_code=" + coin);
  return res;
};

export const getMyCountry = async () => {
  // const res = await Axios.get(`https://geolocation-db.com/json/`);
  const res = await Axios.get(`https://extreme-ip-lookup.com/json/?key=PFP805M6JsQ7XxntPeYm`);
  return res;
};
export const getActiveCountries = async () => {
  const res = await Axios.get(BASE_URL + "/p2pcountry/getActive");
  return res;
};

const fetchTotalFee = async (coin, amount) => {
  let res = await Axios.get(
    BASE_URL + `/sep24/withdrawFee?code=${coin}&amount=${amount}`
  );
  return res;
};

export const fetchFeeWithLevel = async (asset) => {
  const token = await getJWT();
  const res = await Axios.get(
    BASE_URL + `/sep24/feeForFrontend?asset_code=${asset}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.data.token}`,
      },
    }
  );
  return res;
};

const fetchGraph = async (coin) => {
  let from = new Date();
  from.setHours(from.getHours() - 3);

  let fromUnix = (from.getTime() / 1000).toFixed(0);
  let toUnix = (new Date().getTime() / 1000).toFixed(0);

  let res = await Axios.get(
    `https://api.coingecko.com/api/v3/coins/${coin}/market_chart/range?vs_currency=usd&from=${fromUnix}&to=${toUnix}`
  );
  return res;
};

const fetchGraphInfo = async (coin) => {
  let url = "https://sandbox-api.coinmarketcap.com/v1/cryptocurrency/map";
  let config = {
    headers: { "X-CMC_PRO_API_KEY": "ded42db1-c61f-46c2-8fc3-ef9df4360211" },
    params: {
      symbol: coin,
    },
  };
  let res = await Axios.get(url, config);
  return res;
};

const sendConfirmCode = async (body, type) => {
  const token = await getJWT();
  const url = `${BASE_URL}/${type}/sendCode`;

  const res = await Axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return res;
};

const verifyCode = async (body, type) => {
  const token = await getJWT();
  const url = `${BASE_URL}/kyc/${type}`;
  // debugger
  const res = await Axios.post(url, body, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.data.token}`,
    },
  });

  return res;
};

export {
  uploadFile,
  getJWT,
  fetchInitial,
  fetchTransactions,
  fetchInternalFee,
  fetchFederation,
  fetchDeposit,
  withdrawRequest,
  fetchToml,
  fetchGraphInfo,
  fetchOverAllTransactions,
  fetchTotalFee,
  getStellarTransactions,
  fetchGraph,
  fetchRawCoinPairs,
  sendConfirmCode,
  verifyCode,
};
