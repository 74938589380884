import * as React from "react";
import { colors } from "styles";
import { noteArrowIcon } from "assets/images";
import { fetchToml } from "services/httpServices";
import { getSelectedKeys } from "services/trannsactionServices";
import { issueAsset, readToml } from "services/AssetServices";

export const AssetDataContainer = ({ state, assets, setIssuer }) => {
  let [data, setData] = React.useState({
    ORG_DESCRIPTION: description,
    ORG_LOGO: "",
    ORG_NAME: "",
    ORG_OFFICIAL_EMAIL: "",
    ORG_TWITTER: "",
    ORG_URL: "",
    ISSUER: "stellar.org",
  });
  // let initialize = () => {

  //   fetchToml().then(data=>{
  //     console.log("toml",data)
  //     let {DOCUMENTATION} = data;
  //     if(DOCUMENTATION){
  //     setData({
  //       ORG_DESCRIPTION: DOCUMENTATION.ORG_DESCRIPTION,
  //       ORG_LOGO: DOCUMENTATION.ORG_LOGO,
  //       ORG_NAME: DOCUMENTATION.ORG_NAME,
  //       ORG_OFFICIAL_EMAIL: DOCUMENTATION.ORG_OFFICIAL_EMAIL,
  //       ORG_TWITTER: DOCUMENTATION.ORG_TWITTER,
  //       ORG_URL: DOCUMENTATION.ORG_URL

  //     })
  //   }
  //   }).catch(e=>console.log(e.response))
  // }

  let initData = async () => {
    if (state.coin?.toUpperCase() == "XLM") {
      return false;
    }

    try {
      let asset = assets.assets.find(
        (item) =>
          item.code?.toUpperCase() == state.coin?.toUpperCase() &&
          item.issuer == state?.asset?.issuer
      );
      if (!asset) {
        asset = state.asset;
      }
      let toml = await readToml(asset.issuer, "any");

      let tomlAsset = toml.CURRENCIES?.find((item) => item.code == asset.code);

      setData({
        ORG_DESCRIPTION: toml?.ORG_DESCRIPTION
          ? toml?.ORG_DESCRIPTION
          : toml?.DOCUMENTATION?.ORG_DESCRIPTION
          ? toml?.DOCUMENTATION?.ORG_DESCRIPTION
          : null,
        ORG_LOGO: toml?.DOCUMENTATION?.ORG_LOGO
          ? toml?.DOCUMENTATION?.ORG_LOGO
          : toml?.ORG_LOGO,
        ORG_NAME: toml?.DOCUMENTATION?.ORG_NAME
          ? toml?.DOCUMENTATION?.ORG_NAME
          : toml?.ORG_NAME,
        ORG_OFFICIAL_EMAIL: toml?.DOCUMENTATION?.ORG_OFFICIAL_EMAIL
          ? toml?.DOCUMENTATION?.ORG_OFFICIAL_EMAIL
          : null,
        ORG_TWITTER: toml?.DOCUMENTATION?.ORG_TWITTER
          ? toml?.DOCUMENTATION?.ORG_TWITTER
          : null,
        ORG_URL: toml?.DOCUMENTATION?.ORG_URL
          ? toml?.DOCUMENTATION?.ORG_URL
          : toml?.ORG_URL,
        ISSUER: asset.issuer,
      });

      setIssuer({
        issuer: asset.issuer,
        code: asset?.code ? asset?.code : asset?.short,
        loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    initData();
  }, []);
  return (
    <div
      className="w-full border border-primary mb-2"
      style={{ paddingLeft: 10, paddingRight: 10 }}
    >
      <DataRow textAlign="right" title="Asset Name" value={state.coin} />
      <DataRow textAlign="right" title="Asset Issuer" value={data.ISSUER} />
      {data.ORG_URL != "" && (
        <DataRow textAlign="right" title="Asset Website" value={data.ORG_URL} />
      )}
      {data.ORG_NAME != "" && (
        <DataRow textAlign="right" title="Organization" value={data.ORG_NAME} />
      )}
      {data.ORG_OFFICIAL_EMAIL != "" && (
        <DataRow
          textAlign="right"
          title="Organization E-mail"
          value={data.ORG_OFFICIAL_EMAIL}
        />
      )}
      {data.ORG_TWITTER != "" && (
        <DataRow textAlign="right" title="Twitter" value={data.ORG_TWITTER} />
      )}
      <DataRow
        textAlign="right"
        title="Asset Conditions"
        value="Withdrawal fees apply"
      />
      <DataRow
        textAlign="left"
        title="Description"
        value={data.ORG_DESCRIPTION}
      />
    </div>
  );
  function renderRow(item: any) {
    return (
      <div className="border-primary border-b flex text-white items-center py-3 overflow-auto">
        <div className="flex items-center justify-between w-full">
          <div className="flex">
            <div className="ml-2">
              <p style={{ color: "white" }} className={styles.textStyle}>
                {item.title}
              </p>
            </div>
          </div>
          <div className="flex">
            <p
              style={{ color: colors.secondaryGray, textAlign: "right" }}
              className={styles.AmountStyle}
            >
              {item.value}
            </p>
          </div>
        </div>
      </div>
    );
  }
};

function DataRow({ title, value, textAlign }) {
  return (
    <div className="border-primary border-b flex text-white items-center py-3 overflow-auto">
      <div
        className={`flex ${
          textAlign !== "left" && "items-center"
        } justify-between w-full`}
      >
        <div className="flex">
          <div className="ml-2">
            <p style={{ color: "white" }} className={styles.textStyle}>
              {title}
            </p>
          </div>
        </div>
        <div className={textAlign === "left" ? "ml-6" : "flex"}>
          <p
            style={{ color: colors.secondaryGray, textAlign: textAlign }}
            className={styles.AmountStyle}
          >
            {value}
          </p>
        </div>
      </div>
    </div>
  );
}

const styles = {
  textStyle: "text-xxxs text-secondaryGray md:text-sm lg:text-sm xl:text-sm",
  AmountStyle:
    "mr-2 text-xxxs text-secondaryGray md:text-xs lg:text-xs xl:text-xs",
};

let description =
  "Stellar is an open-source, distributed payments infrastructure. It is a leapfrog technology that connects people, payment systems, and banks with a focus on the developing world. It lets you facilitate multi-currency and asset transactions quickly, reliably, and for a fraction of a penny by using a crypto-asset called Lumens (XLM) as a bridge.";
