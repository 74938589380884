import * as React from "react";
import { PersonInfoBlock } from "components/molecular/ProfileScreen/PersonInfoBlock";
import { ProfileScreenMenu } from "components/molecular/ProfileScreen/ProfileScreenMenu";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { KYCProfileTab } from "screens/ProfileScreen tabs/KYCProfileTab";
import { AppSettingsTab } from "screens/ProfileScreen tabs/AppSettingsTab";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { BASE_URL } from "utils/AppConstants";
import { personProfile } from "assets/images/index";

export const ProfileLeftBlock = ({callback, index}) => {
  const user = useSelector((state:RootState)=>state.profile.user)

  const getProfileName = () => {
    if(user?.firstName){
      let firstName = user?.firstName
      let lastName = user?.lastName
      if(firstName.length > 20){
        firstName = firstName.substring(0, 20)+"..."
      }
      if(lastName.length > 20){
        lastName = lastName.substring(0, 20)+"..."
      }
      return firstName+" "+lastName
    }
    if(user?.companyName?.length > 22){
      return user?.companyName.substring(0, 22)+"..."
    }
    return user?.companyName
  }

  return (
    <div className="w-full md:w-2/4 lg:w-2/6 pr-4">
      {(user.city === "" || user.city == undefined) && <PersonInfoBlock
        profileImage={personProfile}
        rating={0}
        reviews={0}
        contact=""
        email=""
        ratingClicked={()=>{}}
      />}

      {user?.city !== "" && user?.city != undefined && <PersonInfoBlock
        profileImage={user?.file?(BASE_URL+'/files/'+user?.file):personProfile}
        alt={personProfile}
        personName={getProfileName()}
        rating={user.rating}
        reviews={user.reviews}
        contact={user?.phone}
        email={user?.email}
        ratingClicked={() => callback(9)}
      />}
      <ProfileScreenMenu callback={callback} index={index} />
    </div>
  );
};
