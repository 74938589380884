import * as React from "react";
import { getJWT, updateProfilePic } from "services/httpServices";
import Loader from 'react-loader-spinner'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { setProfilePicture } from "store/actions/ProfileActions";

function EditPicture({ profileImage }) {
  const {id} = useSelector((state:RootState)=>state.profile.user)
  let [upload, setUpload] = React.useState({
    name: "Document.png",
    file: "",
    uploading: false,
    visible: false,
    localVal: "",
  });
  const dispatch = useDispatch()
  let selectFile = () => {
    let input = document.getElementById("editInput");
    input?.click();
  };

  let uploadData = (token, data) => {
    updateProfilePic(data, token, id)
      .then((data: any) => {
        
        dispatch(setProfilePicture(data.data))
        setUpload((prev) => ({ ...prev, uploading: false }));
      })
      .catch((e) => {
        setUpload((prev) => ({ ...prev, uploading: false }));
      });
  };

  let handleChange = (e) => {
    let { files } = e.target;
    if (!files[0]) return;
    if (files[0].size > 1000000) {
      setUpload((prev) => ({
        ...prev,
        localVal: "File size can't be more than 1 MB",
      }));
      return;
    }
    setUpload((prev) => ({
      ...prev,
      file: files[0],
      name: files[0].name,
      visible: true,
      uploading: true,
      localVal: "",
    }));
    getJWT()
      .then((token) => {
        uploadData(token.data.token, files[0]);
      })
      .catch((e) => {});
  };

  return (
    <div className="relative">
      <img src={profileImage} className="h-20 w-20" />
      <div onClick={selectFile} className="absolute bg-transparent flex justify-center items-center inset-0 hover:bg-black opacity-50 cursor-pointer text-transparent hover:text-white">
        {!upload.uploading&&'Edit'}
        {upload.uploading && (
            <Loader type="Oval" color="#00BFFF" height={20} width={20} />
          )}
      </div>
      <input
        type="file"
        name="file"
        onChange={handleChange}
        id="editInput"
        className="inputfile"
        accept=".jpg, .jpeg, .png"
      />
    </div>
  );
}

export default EditPicture;
