import * as React from "react";
import * as Images from "assets/images/index";
export const AdvertiserCol = (props: any) => {
  return (
    <div>
      <p className="text-secondaryGray text-sm font-regular">{props.buy?"Selling to":"Buying from"}</p>
      <div className="flex items-center mt-1">
        {/* <img src={props.file} className="h-4 w-4" /> */}
        <p className="text-white ml-2 text-sm font-regular">{props?.first_name+" "+props?.last_name}</p>
      </div>
    </div>
  );
};
