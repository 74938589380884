import * as React from "react";
import { MenuBar } from "./MenuBar";

export const MarketTopNavigation = () => {
  return (
    <div>
        <MenuBar/>
    </div>
  );
};
