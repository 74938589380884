import * as React from 'react';
import StarRatingComponent from 'react-star-rating-component';

export const CustomerRating = (props:any)=>{
    return(
        <div className="mt-2 md:w-auto w-20">
          <p className="text-white text-xs md:text-sm lg:text-sm xl:text-sm font-regular ml-1">{props.name}</p>
          <div className="text-xs md:text-lg">
            <StarRatingComponent name="" value={props.rating}/>
          </div>
        </div>
    );
}