import * as React from "react";
import * as Images from "assets/images/index";
import { RootState } from "store/reducers";
import { useSelector } from "react-redux";
import { getPreferences } from "services/storageServices";
import { currenciesEmun } from "utils/constants";

export const CryptoTopRow = (props: any) => {
  let {
    transaction: { selectedWallet },
    generic,
  } = useSelector((state: RootState) => state);

  let getSymbol = () => {
    let { CURRENCY } = getPreferences();
    return currenciesEmun[CURRENCY];
  };

  return (
    <div
      style={{ backgroundColor: props.bgColor }}
      className="flex items-center  w-full justify-between p-4 border-primary border"
    >
      <div className="flex items-center">
        <div className="flex items-center mr-8">
          {props.icon ? (
            <img
              src={props.icon}
              className="h-5 w-5 md:h-10 md:w-10 lg:w-10 lg:h-10 xl:h-10 xl:w-10"
            />
          ) : null}
          {props.name && (
            <p className="font-regular font-medium text-white text-xs md:text-lg lg:text-lg xl:text-lg ml-4">
              {props.name}
            </p>
          )}
          {
            <div className="flex">
              <div>
                <p className="font-regular font-medium text-white text-xs md:text-lg lg:text-lg xl:text-lg ml-4">
                  {getSymbol() + props.dollarPrice}
                </p>
                <p className="font-regular text-gray4 text-xxs md:text-xxs lg:text-xs xl:text-xs ml-4">
                  {props.btcPrice}
                </p>
              </div>
            </div>
          }
        </div>
        <div className="flex items-center">
          {props.showInsights && props.percentValue && (
            <div className="flex items-center pr-5">
              <img
                src={
                  props.isLoss ? Images.lossGraphCard : Images.profitGraphCard
                }
                className="w-15 h-10"
              />
              <p
                className={`${
                  props.isLoss ? "text-pink" : "text-green"
                } ml-2 text-xs`}
              >
                {(props.isLoss ? "" : "+") + props.percentValue}
              </p>
            </div>
          )}
          {/*<button onClick={props.onClick} style={{backgroundColor:props.btnColor}} className={`focus:outline-none bg-primary ${props.showInsights && "ml-6"} rounded-full py-3 px-3`}>
          <img src={Images.menuDropdownIcon} className="h-2 w-3"/>
        </button>*/}
        </div>
      </div>
      <div
        className="flex text-xl text-white font-semibold"
        style={{ width: "220px" }}
      >
        <h3>
          {selectedWallet.name} {selectedWallet.public.substring(0, 5)}...
        </h3>
      </div>
    </div>
  );
};
