import React, { useState } from "react";
import Modal from "react-modal";
import { BASE_URL } from "utils/AppConstants";

function ImageGallery({ isOpen, onClose, files }) {
  const [index, setIndex] = useState(0)
  return (
    <Modal isOpen={isOpen} style={customStyle}>
      <div>
        <button
          className="rounded-full bg-gray4 w-6 h-6 p-1 absolute"
          style={{ top: "10px", right: "10px" }}
          onClick={onClose}
        >
          <img src="assets/cross.svg" />
        </button>
        <img
          src={`${BASE_URL}/p2ptemp-files/${files[index]}`}
          className="w-full h-full"
        />
        <div className="w-full flex mt-4">
          {files.map((file, index) => (
            <img
              src={`${BASE_URL}/p2ptemp-files/${file}`}
              onClick={()=>setIndex(index)}
              className="w-20 h-20 ml-1 cursor-pointer"
              key={index}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default ImageGallery;

const customStyle = {
  overlay: {
    backgroundColor: "#00000080",
    zIndex: 100,
  },
  content: {
    top: "10%",
    left: "25%",
    right: "auto",
    bottom: "auto",
    width: "54.4%",
    height: "80%",
    zIndex: "1000",
    margin: "0 auto",
    padding: "10px",
    backgroundColor: "#232321",
    border: "0 px",
  },
};
