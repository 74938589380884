import * as React from "react";
import CustomInput from "components/global/CustomInput";
import { colors } from "styles";
import { FormDataRow } from "components/molecular/P2P/CreateAd/FormDataRow";
import { DropDown } from "components/molecular/P2P/CreateAd/DropDown";
import { coinsFromShort } from "utils/constants";

export const PriceForm = ({
  buy,
  unitPrice,
  unitPriceVal,
  handleInputChange,
  currency,
  assetCode,
  priceInCurrency,
  priceWithFee,
  bitStampRate,
  total_amount,
  loadingRates,
  typeIndex,
  balanceVal
}) => {
  return (
    <div>
      <p className="text-white text-lg mt-4 mb-4 font-regular">Price</p>

      <PriceRow
        leading={`${assetCode==="USDT"?"":assetCode.toLowerCase()}USD Price`}
        value={unitPrice}
        postValue={`${currency} / ${assetCode==="USDT"?"":assetCode?.toLowerCase()}USD`}
        validation={unitPriceVal}
        changable={true}
        onChange={(value)=>handleInputChange({target:{ name: 'unitPrice', value: value}})}
        description={`Price of 1 ${assetCode==="USDT"?"":assetCode.toLowerCase()}USD in currency`}
      />
      <PriceRow
        leading={`${coinsFromShort[assetCode].name} ${assetCode} Price that you ${buy ? "pay" : "receive"}`}
        value={(loadingRates?"Loading...":`${priceInCurrency} ${currency} / ${assetCode}`)}
        postValue=""
        validation=""
        changable={false}
        onChange={()=>{}}
        description={`This is the ${coinsFromShort[assetCode].name} price at current Bitstamp ${assetCode} rate (${bitStampRate}). The
        price will fluctuate when Bitstamp ${assetCode} rate changes.`}
      />
      <PriceRow
        leading={`${coinsFromShort[assetCode].name} ${assetCode} Price that ${buy ? "sellers" : "buyers"} see`}
        value={(loadingRates?"Loading...":`${priceWithFee} ${currency} / ${assetCode}`)}
        postValue=""
        validation=""
        changable={false}
        onChange={()=>{}}
        description="The price has been accounted with trading fee (seller pay the fee)."
      />
      <PriceRow
        leading={`Currency`}
        value={currency}
        postValue=""
        validation=""
        changable={false}
        onChange={(value)=>handleInputChange({target:{name:"currency", value}})}
        description={`Currency in exchange for ${assetCode}`}
      />
      {typeIndex===0&&<PriceRow
        leading={`Amount`}
        value={total_amount}
        postValue=""
        validation={balanceVal}
        changable={false}
        onChange={(value)=>handleInputChange({target:{name:"currency", value}})}
        description={`Amount you are willing to ${buy?'buy':'sell'}`}
      />}
      {/* <DropDown
          label="Bank Name"
          name="bank_name"
          value={currency}
          onChange={handleInputChange}
          data={banks}
        />
       <PriceRow
        leading={`${buy ? "Maximum" : "Minimum"} bitcoin price`}
        value={unitPriceMinMax}
        changable={true}
        onChange={(value)=>handleInputChange({target:{name:"unitPriceMinMax", value}})}
        description="The minimum price you are willing to sell 1 btc for"
      /> 
       <div className="flex justify-between">
        <div className="xl:w-3/6">
          <DropDown label="Reference Exchange" data={["bitstamp_BTCUSD"]} />
        </div>
        <p className="text-secondaryGray text-left text-xxxs w-2/6 xl:text-xs mt-4 mb-4 font-regular">
          Exchange that will be used to update your advertisement price
        </p>
      </div>
      <div className="flex justify-between">
        <div className="xl:w-3/6">
          <DropDown label="Reference Exchange" data={["bitstamp_BTCUSD"]} />
        </div>
        <p className="text-secondaryGray text-left text-xxxs w-2/6 xl:text-xs mt-4 mb-4 font-regular">
          The maximum price you are willing to pay for 1 Bitcoin (base on PKR)
        </p>
      </div> */}
    </div>
  );
};

function PriceRow({ leading, value, description, changable, onChange, postValue, validation }) {
  const [edit, setEdit] = React.useState(false);
  const handleChange = (event) => {
    onChange(event.target.value);
  };
  return (
    <div className="md:flex items-center">
      <div className="w-full">
        <div className="flex justify-between items-center w-full">
        {edit && (
          <CustomInput
            labelColor={colors.secondaryGray}
            label={leading}
            marginVertical="0.45rem"
            padding={"12px"}
            placeholder={leading}
            value={value}
            valMsg={validation}
            type="number"
            onChange={handleChange}
          />
        )}
        {!edit && (
          <p className="text-secondaryGray text-sm mt-4 mb-4 font-regular">
            {leading}
          </p>
        )}
        {!edit && (
          <p className="text-white text-sm mt-4 mb-4 font-regular">{value} {postValue}</p>
        )}
        </div>
        {!edit && <p className="text-red italic text-sm">{validation}</p>}
      </div>
      
      <div className="w-full flex">
        {!edit && changable && (
        <button
          onClick={() => setEdit(true)}
          className="focus:outline-none text-accent text-sm ml-2"
        >
          Change
        </button>
      )}
      <p className={`text-secondaryGray italic ${(!edit && changable) ?'md:ml-2':'md:ml-16'} text-left text-xxs xl:text-xs font-regular`}>
        {description}
      </p>
      </div>
    </div>
  );
}
