
import * as React from 'react';
import { CustomButton } from 'styles/atomic/CustomButtonStyle'
import { Button } from 'components';

const PrimaryButton = (props: any) => {
    return (
        <Button styles={["text-sm p-5 uppercase " + props.mx + [props.color ?? " bg-white"]]}>
            {props.title}
        </Button>
    )
}

export default PrimaryButton 