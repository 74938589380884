import React from "react";

function ToggleButton({ setPercentAmount, percent, value }) {
  return (
    <button
      onClick={() => setPercentAmount(value)}
      type="button"
      className={`focus:outline-none py-1 ${
        percent == value ? "bg-accent text-white" : "border border-gray-700 text-accent"
      } px-1 ml-1 rounded`}
    >
      {value}%
    </button>
  );
}

export default ToggleButton