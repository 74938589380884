import * as React from 'react';

function GenericTab({ tabs, index, onSelect }) {
  return (
    <div className="flex">
      {tabs.map((tab, i) => (
        <button
          key={i}
          onClick={() => onSelect(i)}
          className={`${
            index === i ? 'text-white bg-accent':'text-accent'
          } px-4 py-2 rounded focus:outline-none`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
}

export default GenericTab;
