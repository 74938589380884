import * as React from "react";
import { colors } from "styles";

export const TransactionCard = (props: any) => {
  return (
    <div style={{marginLeft:props.marginLeft,marginRight:props.marginRight}} className="bg-primary p-4 mt-4 w-full">
      <p className="text-gray4 text-sm font-regular">
        {props.buy ? "Selling" : "Buying"} Price:
      </p>
      <p className="text-white  text-sm md:text-lg lg:text-lg xl:text-lg font-regular mt-2">{props.price} {props.currency}</p>
      <button
        onClick={props.onClick}
        style={{ backgroundColor: props.buy ? colors.green : colors.pink }}
        className="text-white  text-xs md:text-sm lg:text-sm xl:text-sm font-regular px-4 py-2 rounded mt-5"
      >
        {props.buy ? "Buy Now" : "Sell Now"}
      </button>
    </div>
  );
};
