import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  addBuySellTrade,
  confirmEscrowPayment,
  fetchP2pBank,
  fetchP2pFee,
  getTradeById,
} from "services/p2pServives";
import {
  getSelectedSecret,
  sendNonNativeBalance,
} from "services/trannsactionServices";
import { useAlert } from "react-alert";
import { RootState } from "store/reducers";
import { setCoinsAction } from "store/actions/TransactionActions";
import { BASE_URL, isTestNet, MIN_BALANCE_COIN } from "utils/AppConstants";
import Axios from "axios";
import { paymentMethodsEnum } from "./useAdd";

function useAddTrade(buy) {
  const {
    assets: { assets },
    transaction: { coins },
    profile: { user },
  } = useSelector((state: RootState) => state);
  const location: any = useLocation();
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const init: any = {};
  const [state, setState] = useState({
    amount: "",
    amountInCurrency: "",
    bank_name: "",
    bank_account_number: "",
    bank_account_title: user.isCompanyKyc
      ? user.companyName
      : user.firstName + " " + user.lastName,
    bank_nameVal: "",
    bank_account_numberVal: "",
    bank_account_titleVal: "",
    amountVal: "",
    amountInCurrencyVal: "",
    bankConfirmed: false,
    submitting: false,
    add: init,
    fee: 0,
    email: "",
    emailVal: "",
    feeLoading: false,
    minBatteryAmount: 0,
    bitStampRate: 0,
  });

  const [showEmail, showBankInfo] = useMemo(() => {
    const { add } = location.state
    const showEmail = add.type === 'buy' &&
      (add.payment_method === paymentMethodsEnum.Payoneer || add.payment_method === paymentMethodsEnum.Skrill)
    const showBankInfo = add.type === 'buy' &&
      (add.payment_method === paymentMethodsEnum.Local_Bank || add.payment_method === "Bank Transfer")
    return [showEmail, showBankInfo]
  }, [location.state])

  const submit = () => {
    if (!validated()) {
      return;
    }

    if (state.add.type === "sell") {
      addTradeBuy();
    } else {
      addTradeSell();
    }
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, add: location?.state?.add }));
    loadInfo(location?.state?.add?.assetCode);
    getCurrencyRates(MIN_BALANCE_COIN)
      .then((data) => {
        const balance = coins.find(
          (item) => item.short.toUpperCase() === MIN_BALANCE_COIN
        );
        const result = data?.currentRate * Number(balance?.balance || 0);
        setState((prev) => ({
          ...prev,
          minBatteryAmount: result,
          bank_name: location.state?.add?.bank_name,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (
      state.amount !== "" &&
      Number(state.amount) > 0 &&
      state.add.type === "sell"
    ) {
      setState((prev) => ({ ...prev, feeLoading: true }));
      fetchP2pFee(state.add.assetCode, state.amount, "fee")
        .then((data) => {
          const { p2pBuyerFeeFixed, p2pBuyerFeePercent } = data.data.fee;
          const fee = calculateFee(
            p2pBuyerFeePercent,
            p2pBuyerFeeFixed,
            state.amount
          );
          setState((prev) => ({
            ...prev,
            fee: Number(state.amount) - fee,
            feeLoading: false,
          }));
        })
        .catch((e) => {
          // console.log(e);
          setState((prev) => ({ ...prev, feeLoading: false }));
        });
    } else {
      setState((prev) => ({ ...prev, fee: 0 }));
    }
  }, [state.amount]);

  const setBankConfirmed = () =>
    setState((prev) => ({ ...prev, bankConfirmed: !prev.bankConfirmed }));

  const calculateFee = (feePercent, feeFixed, amount) => {
    const percent = (feePercent / 100) * Number(amount);
    return feeFixed + percent;
  };

  const getCurrencyRates = async (asset) => {
    const response = await Axios.get(
      BASE_URL + "/coins/getAssets?currencyCode=USD"
    );

    const assetRates = response.data.find(
      (item) => item.short.toUpperCase() === asset
    );
    return assetRates;
  };

  const loadInfo = (asset) => {
    getCurrencyRates(asset?.toUpperCase())
      .then((data) => {
        setState((prev) => ({ ...prev, bitStampRate: data.currentRate }));
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  const addTradeBuy = () => {
    setState((prev) => ({ ...prev, submitting: true }));
    addBuySellTrade(
      {
        p2padsId: state.add?.id,
        amount: Number(state.amount),
        // amount_in_currency: state.amountInCurrency,
        coinUSDRate: state.bitStampRate,
        bank_account_number: state.add.bank_account_number,
        bank_name: state.add.bank_name,
        payoneer_email: state.add.payment_method === paymentMethodsEnum.Payoneer ? state.email : "",
        skrill_email: state.add.payment_method === paymentMethodsEnum.Skrill ? state.email : "",
      },
      "addBuyTrade"
    )
      .then((data) => {
        history.replace({
          pathname: "/transactionProgressScreen",
          state: { trade: data.data.trade },
        });
        setState((prev) => ({ ...prev, submitting: false }));
      })
      .catch((e) => {
        console.log(e.response);
        alert.show(e?.response?.data?.Error || "Error", { type: "error" });
        setState((prev) => ({ ...prev, submitting: false }));
      });
  };

  const addTradeSell = async () => {
    setState((prev) => ({ ...prev, submitting: true }));

    try {
      const data = await addBuySellTrade(
        {
          p2padsId: state.add?.id,
          amount: Number(state.amount),
          // amount_in_currency: state.amountInCurrency,
          // unitPrice: state.add?.unitPrice,
          coinUSDRate: state.bitStampRate,
          bank_account_number: state.bank_account_number,
          bank_name: state.bank_name,
          bank_account_title: state.bank_account_title,
          payoneer_email: state.add.payment_method === paymentMethodsEnum.Payoneer ? state.email : "",
          skrill_email: state.add.payment_method === paymentMethodsEnum.Skrill ? state.email : "",
        },
        "addSellTrade"
      );

      const {
        escrowAccountId,
        cryptoAmountFromSeller,
        sellerStellarMemo,
        p2pads,
      } = data.data.trade;
      const coin = assets.find(
        (coin) => coin.short.toUpperCase() === p2pads.assetCode.toUpperCase()
      );

      const result = await sendNonNativeBalance(
        getSelectedSecret(),
        escrowAccountId,
        cryptoAmountFromSeller,
        sellerStellarMemo,
        p2pads.assetCode.toUpperCase(),
        coin?.issuer
      );

      await confirmEscrowPayment(result.hash)

      setState((prev) => ({ ...prev, submitting: false }));

      dispatch(setCoinsAction());
      history.replace({
        pathname: "/transactionProgressScreen",
        state: { trade: data.data.trade },
      });
    } catch (e) {
      setState((prev) => ({ ...prev, submitting: false }));
      console.log(e, e?.response);
      alert.show(e?.response?.data?.Error || "Error", { type: "error" });
    }
  };

  const validated = () => {
    let validated = true;
    if (state.submitting) {
      return false;
    }
    if (state.amount === "") {
      setState((prev) => ({ ...prev, amountVal: "This Field is Required" }));
      validated = false;
    }
    if (state.amount === "") {
      setState((prev) => ({
        ...prev,
        amountInCurrencyVal: "This Field is Required",
      }));
      validated = false;
    }
    if (showBankInfo) {
      if (state.bank_account_number === "") {
        setState((prev) => ({
          ...prev,
          bank_account_numberVal: "This Field is Required",
        }));
        validated = false;
      }
      if (state.bank_account_title === "") {
        setState((prev) => ({
          ...prev,
          bank_account_titleVal: "This Field is Required",
        }));
        validated = false;
      }
      if (!state.bankConfirmed) {
        validated = false;
      }
    }
    if (showEmail) {
      if (state.email === "") {
        setState((prev) => ({
          ...prev,
          emailVal: "Email address is required",
        }));
        validated = false;
      }
    }

    if (Number(state.amount) > calcAmountWSpread()) {
      setState((prev) => ({
        ...prev,
        amountVal: "Amount can't be greater than total amount in the Ad",
      }));
      validated = false;
    }
    if (!isTestNet) {
      if (!state.minBatteryAmount || state.minBatteryAmount < 5) {
        validated = false;
        alert.show(
          "You need at least five dollars worth of LEVELG to start trade."
        );
      }
    }

    const coin = coins.find(
      (item) => item.short.toUpperCase() === state.add?.assetCode?.toUpperCase()
    );
    if (
      (!coin || coin.balance < Number(state.amount)) &&
      state.add.type === "buy"
    ) {
      alert.show("You do not have enough balance", { type: "error" });
      validated = false;
    }
    return validated;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "amountInCurrency") return;
    const coin = coins.find(
      (item) => item.short.toUpperCase() === state.add?.assetCode?.toUpperCase()
    );
    let validation = "";
    if (Number(value) > calcAmountWSpread() && name === "amount") {
      validation = "Amount can't be greater than available amount in the Ad";
    }
    if (
      (!coin || coin.balance < Number(value)) &&
      name === "amount" &&
      state.add.type === "buy"
    ) {
      validation = "Amount can't be greater than your balance";
    }
    handleCurrency(name, value);
    setState((prev) => ({
      ...prev,
      [name]: value,
      [name + "Val"]: validation,
    }));
  };
  // const calcPriceWSpread = () => {
  //   const priceForSell = Number(state.add.sellerUSDRate)+((state.add.priceSpread/100)*Number(state.add.sellerUSDRate))
  //   const temp = state.add.type==="sell"?priceForSell:Number(state.add.sellerUSDRate)
  //   return temp
  // }
  const calcAmountWSpread = () => {
    const { type, available_amount, priceSpread } = state.add;
    // const amountForSell =
    //   Number(available_amount) - (priceSpread / 100) * Number(available_amount);
    // const amountForBuy =
    //   Number(available_amount) + (priceSpread / 100) * Number(available_amount);
    const amountForSell = Number(available_amount);
    const amountForBuy = Number(available_amount);
    const temp = type === "sell" ? amountForSell : amountForBuy;
    return temp;
  };

  const handleCurrency = (name, value) => {
    if (name === "amount") {
      const { type, sellerUSDRate, buyerUSDRate } = state.add;
      setState((prev) => {
        const val = calculateCryptForFiat(
          prev.bitStampRate,
          Number(value),
          type === "buy" ? sellerUSDRate : buyerUSDRate
        );
        return {
          ...prev,
          amountInCurrency: val,
        };
      });
    }
  };
  const calculateCryptForFiat = (
    cryptoRate: number,
    cryptoQuantity: number,
    localUSDRate: number
  ) => {
    let totalUSDAmount = cryptoQuantity * cryptoRate;
    let totalAmountInLocal = totalUSDAmount * localUSDRate;
    return totalAmountInLocal.toFixed(2);
  };

  const onProfile = () => {
    const account = state.add?.account;
    history.push({ pathname: "/p2pProfile", state: { account: account } });
  };

  return {
    ...state,
    showEmail, showBankInfo,
    handleInputChange,
    submit,
    onProfile,
    calcAmountWSpread,
    setBankConfirmed,
  };
}

export default useAddTrade;
