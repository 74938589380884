import * as React from 'react';

export const TableHeadingRow = ()=>{
    return(
        <div className="flex justify-between border-primary border-b w-full px-4 py-2">
         <p className="text-secondaryGray text-sm ml-6" >Amount</p>
         <div className="xl:ml-4">
            <p className="text-secondaryGray text-sm" >Seller/Buyer</p>
         </div>
         <p className="text-secondaryGray text-sm" >Status</p>
       </div>
    );
}