import { chatIcon, emailIcon, twitterIcon } from "assets/images";
import CustomInput from "components/global/CustomInput";
import Footer from "components/molecular/footer";
import { SelectOption } from "components/molecular/SelectOption";
import React from "react";
import { colors } from "styles/theme";
import Faqscreen from "./Faqscreen";

function Support() {
  return (
    <div>
      <div
        className="text-center text-sm"
        style={{ paddingLeft: "20vw", paddingRight: "20vw" }}
      >
        <h1 className="text-2xl font-semibold text-white mt-16">Support</h1>
        <h5 className="text-secondaryGray text-sm mt-8">
          Having trouble? We are always here to help. Feel free to contact us
          anytime.
        </h5>

        <div className="flex justify-around mt-16">
          {/* <div className="bg-primary rounded flex-1 flex flex-col items-center p-6 mx-8">
            <img src={chatIcon} className="w-10 h-10 mr-4" />
            <h3 className="text-lg text-white">Chat</h3>
            <p className="text-xs text-gray-600">
              Our agents are available 24/7
            </p>
          </div> */}
          <a href="mailto:support@gwalletglobal.com" className="bg-primary rounded flex flex-col items-center flex-1 p-6 cursor-pointer">
            <img src={emailIcon} className="w-10 h-10 mr-4" />
            <h3 className="text-lg text-white">Email</h3>
            <p className="text-xs text-gray-600">support@gwalletglobal.com</p>
          </a>

          <a
            className="bg-primary rounded flex flex-col items-center flex-1 p-6 mx-8"
            target="_blank"
            href="https://twitter.com/gwalletofficial?s=09"
          >
            <img src={twitterIcon} className="w-10 h-10 mr-4" />
            <h3 className="text-lg text-white">Twitter</h3>
            <p className="text-xs text-gray-600">https://twitter.com</p>
          </a>
        </div>
        
        {/*<h1 className="text-2xl font-semibold text-white mt-16">
          Or Fill Out This Form, We'll Quickly Get Back To You
        </h1>
         <div>
          <div className="flex">
            <div className="w-full text-left">
              <SelectOption
                border="none"
                label="How can we help?"
                value="BTC"
                onChange={() => {}}
                data={["Select a subject", "non"]}
              />
            </div>
            <div className="w-full" />
          </div>
          <div className="flex mt-8">
          <div className="w-full xl:mr-2">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="First Name"
                name="firstName"
                value={""}
                onChange={()=>{}}
                valMsg={""}
                marginVertical="0.30rem"
                padding={"12px"}
                placeholder="Enter your first name"
              />
            </div>
            <div className="w-full">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="Last Name"
                name="lastName"
                value={""}
                onChange={()=>{}}
                valMsg={""}
                marginVertical="0.30rem"
                padding={"12px"}
                placeholder="Enter your last name"
              />
            </div>
          </div>
          <div className="flex mt-4">
          <div className="w-full xl:mr-2">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="Email Address"
                name="emailAddress"
                value={""}
                onChange={()=>{}}
                valMsg={""}
                marginVertical="0.30rem"
                padding={"12px"}
                placeholder="Enter your email address"
              />
            </div>
            <div className="w-full">
              <CustomInput
                labelColor={colors.secondaryGray}
                label="Phone Number"
                name="phoneNumber"
                value={""}
                onChange={()=>{}}
                valMsg={""}
                marginVertical="0.30rem"
                padding={"12px"}
                placeholder="Enter your phone number"
              />
            </div>
          </div>
          <div className="text-left mt-4">
            <label className="text-secondaryGray">Message</label>
            <textarea className="bg-primary w-full p-2 text-white focus:outline-none mt-1" rows={8} placeholder="Enter your message"></textarea>
          </div>
          <button className="bg-accent text-white focus:outline-none rounded py-2 px-8 mt-12">Submit</button>
        </div> */}
      </div>
      <Footer marginTop="100px" />
    </div>
  );
}

export default Support;
