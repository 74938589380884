import * as React from "react";
import { AdsCol } from "components/molecular/P2P/AdsCol";
import { CustomerRating } from "components/molecular/P2P/CustomerRating";
import { colors } from "styles";
import { getPaymentText } from "utils/constants";

export const AdCard = (props: any) => {
  return (
    <div
      className={`flex w-full p-2 ${
        !props.inProfile && "border-primary border"
      } justify-between items-center`}
    >
      <AdsCol
        title={props.title}
        maxamount={props.maxamount}
        currency={props.currency}
        assetCode={props.assetCode}
        item={props.item}
        usdRate={props.usdRate}
      />
     <p className="flex text-secondaryGray text-xxxs md:text-xs lg:text-xs xl:text-xs font-regular">
        {getPaymentText(props.item)}
      </p>
      {!props.inProfile && <button onClick={props.onProfile}><CustomerRating name={props.advertiser} rating={props.rating} /></button>}

      <button
        style={{ backgroundColor: props.buy ? colors.pink : colors.green }}
        className={`${props.isMyProfile?'px-1 md:px-4 lg:px-4 xl:px-4':'px-3 md:px-8 lg:px-8 xl:px-8'} text-white text-xxxs md:text-sm lg:text-sm xl:text-sm font-regular rounded ${
          props.inProfile
            ? "py-1 xl:py-2 lg:py-2 md:py-2"
            : "py-2 xl:py-3 lg:py-3 md:py-3"
        }`}
        onClick={props.onAction}
      >
        {props.isMyProfile?props.status==='Active'?'Deactivate':'Activate':props.buy ? "SELL" : "BUY"}
      </button>
    </div>
  );
};
