export const eyeIcon = require("../images/eye_icon.png");
export const logo = require("../images/logo.png");
export const logo2 = require("../images/logo2.png");
export const profitGraphCard = require("../images/profit_graph_card.png");
export const lossGraphCard = require("../images/loss_graph_card.png");
export const favoriteIcon = require("../images/favorite_icon.png");
export const notFavIcon = require("../images/not_fav_icon.png");
export const btcIcon = require("../images/btc_icon.png");
export const crdIcon = require("../images/crd_icon.png");
export const ethIcon = require("../images/eth_icon.png");
export const levelgIcon = require("../images/level_g_icon.png");
export const ltcIcon = require("../images/ltc_icon.png");
export const sendArrowIcon = require("../images/send_arrow_icon.png");
export const receiveArrowIcon = require("../images/receive_arrow_icon.png");
export const walletIcon = require("../images/wallet_icon.png");
export const lockIcon = require("../images/lock_icon.png");
export const usdtIcon = require("../images/usdt_icon.png");
export const menuDropdownIcon = require("../images/menu_drop_arrow.png");
export const qrCodeIcon = require('../images/qr_code.png');
export const copyIcon = require('../images/copy_icon.png');
export const downloadIcon = require('../images/download_icon.png');
export const checkedIcon = require('../images/checked_icon.png');
export const newWallet = require("./new_wallet.png");
export const newWallet2x = require("./new_wallet@2x.png");
export const progressIcon = require("./inprogress_icon.png");
export const swapBtn = require('./swap_btn.png');
export const sendIconWhite = require('./send_icon_white.png');
export const sendIconGray = require('./send_icon_gray.png');
export const receiveIconWhite = require('./receive_icon_white.png');
export const receiveIconGray = require('./receive_icon_gray.png');
export const clockIcon = require('./clock_icon.png');
export const personImage = require('./person_image.png');
export const noteArrowIcon = require('./note_arrow_icon.png');
export const sendArrowRed = require('./send_arrow_red.png');
export const bestPathIcon = require('./best_path_icon.png');
export const createAdbtn = require('./create_ad_btn.png')
export const clockIconRed = require('./clock_icon_red.png');
export const personImage2 = require('./person_image_2.png');
export const transactionSuccessfullImg = require('./transaction_successfull.png');
export const cameraIcon = require('./camera_icon.png');
export const defaultProfileIcon = require('./default_profile.png');
export const documentIcon = require('./document_icon.png');
export const informationIcon = require('./information_icon.png');
export const lockIconTransparent = require('./lock_icon_transparent.png');
export const lockIconWhite =  require('./lock_icon_white.png');
export const logoutIcon  = require('./logout_icon.png');
export const personImage3 = require('./person_image_3.png');
export const profileIconWhite = require('./profile_white.png');
export const referIconDefault = require('./refer_icon_default.png');
export const referIconWhite = require('./refer_icon_white.png');
export const selfieIcon  = require('./selfie_icon.png');
export const settingsIcon = require('./settings_default.png');
export const settingsIconWhite = require('./settings_white.png');
export const supportIcon = require('./support_icon_default.png');
export const uploadIcon = require('./upload_icon.png');
export const walletIconWhite = require('./wallet_icon_white.png');
export const rightArrow = require('./right_icon.png');
export const walletIconTransparent = require('./wallet_transparent.png');
export const fileIcon = require('./file_icon.png');
export const closeButton = require('./close_button.png');
export const verifiedIcon = require('./identity_verified.png');
export const xlmIcon = require('./Stellar@2x.png');
export const dogeIcon = require('./DogeCoin@3x.png');
export const bchIcon = require('./BCH@3x.png');
export const dashIcon = require('./Dashcoin@2x.png');
export const moonIcon = require('./moon.png');
export const chatIcon = require('./Chat@3x.png');
export const emailIcon = require('./Email@3x.png');
export const phoneIcon = require('./Phone@3x.png');
export const personProfile = require('./data.jpeg');
export const comingsoonImage = require('./comingsoon.png');
export const twitterIcon = require('./twitter.png');
export const bankWhite = require('./bank-white.svg');
export const bankGrey = require('./bank-grey.svg');
// export const holding = require('./holding.png');
