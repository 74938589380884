import {
  GetP2pActionsType,
  FETCH_ADDS_PENDING,
  FETCH_ADDS_SUCCESS,
  FETCH_ADDS_ERROR,
  SET_ACTIVE_TAB,
  SELECT_COIN_NAME,
  INIT_P2P,
  APPEND_ADD,
  FETCH_TRADES_SUCCESS,
  FETCH_MY_ADDS_SUCCESS,
  APPEND_TRADE,
  UPDATE_TRADE,
  FETCH_PRICES_PENDING,
  FETCH_PRICES_SUCCESS,
  CHANGE_ADD_STATUS,
  UPDATE_ADD,
  REMOVE_ADD,
  RE_INIT_P2P,
  INIT_P2P_ERROR,
  SELECT_COIN_NAME_PENDING,
  SELECT_COIN_NAME_ERROR,
  REMOVE_ADD_NEW_STATUS,
  FETCH_MY_ADDS_PENDING,
  FETCH_MY_NEXT_ADDS,
  FETCH_TRADES_PENDING,
  FETCH_MY_ADDS_PENDING_WITH_TYPE,
  SET_COIN_ACTION,
  SEARCHING_ADDS,
  RESTORE_ADDS,
  SAVE_USD_RATES,
  FETCH_ACTIVE_TRADES_PENDING,
  FETCH_ACTIVE_TRADES_SUCCESS,
} from "./P2pActionsTypes";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  fetchInitAdds,
  searchAdds,
  fetchTrades,
  getAddsByAccount,
  getExchangeRates,
  fetchActiveTrades,
} from "services/p2pServives";
import { CionP2p } from "store/models/P2pState";
import { Asset } from "store/models/Assets";
import { getSelectedKeys } from "services/trannsactionServices";
import { RootState } from "store/reducers";
import Axios from "axios";
import { BASE_URL } from "utils/AppConstants";
import { store } from "App";

export const initP2p = (coins: CionP2p[]): GetP2pActionsType => {
  return { type: INIT_P2P, coins };
};
export const initP2pError = (coins: CionP2p[]): GetP2pActionsType => {
  return { type: INIT_P2P_ERROR, coins };
};

export const getAddsPending = (active: number): GetP2pActionsType => {
  return { type: FETCH_ADDS_PENDING, active };
};

export const reInitP2p = (): GetP2pActionsType => {
  return { type: RE_INIT_P2P };
};

export const restoreAdds = (): GetP2pActionsType => {
  return { type: RESTORE_ADDS };
};

export const getAddsError = (
  error: string,
  active: number
): GetP2pActionsType => {
  return { type: FETCH_ADDS_ERROR, error, active };
};

export const getAddsSuccess = (
  data: CionP2p,
  active: number
): GetP2pActionsType => {
  return { type: FETCH_ADDS_SUCCESS, coin: data, active };
};

export const setActiveTab = (tab: string): GetP2pActionsType => {
  return { type: SET_ACTIVE_TAB, tab: tab };
};
export const searchingAdds = (): GetP2pActionsType => {
  return { type: SEARCHING_ADDS };
};

export const selectCoinName = (
  name: string,
  coin: CionP2p
): GetP2pActionsType => {
  return { type: SELECT_COIN_NAME, name, coin };
};
export const setCoinAction = (
  name: string,
  coin: CionP2p
): GetP2pActionsType => {
  return { type: SET_COIN_ACTION, name, coin };
};

export const selectCoinNamePending = (name: string): GetP2pActionsType => {
  return { type: SELECT_COIN_NAME_PENDING, name };
};

export const selectCoinNameError = (name: string): GetP2pActionsType => {
  return { type: SELECT_COIN_NAME_ERROR, name };
};

export const appendTrade = (trade: any, coin: string): GetP2pActionsType => {
  return { type: APPEND_TRADE, trade, coin };
};

export const updateAdd = (add: any): GetP2pActionsType => {
  return { type: UPDATE_ADD, add };
};

export const removeAdd = (add: any): GetP2pActionsType => {
  return { type: REMOVE_ADD, add };
};

export const removeAddNewStatus = (add: any): GetP2pActionsType => {
  return { type: REMOVE_ADD_NEW_STATUS, add };
};

export const fetchMyAddsPending = (active: number): GetP2pActionsType => {
  return { type: FETCH_MY_ADDS_PENDING, active };
};

export const fetchMyAddsPendingWithType = (
  active: number,
  category: string
): GetP2pActionsType => {
  return { type: FETCH_MY_ADDS_PENDING_WITH_TYPE, active, category };
};

export const fetchTradesPending = (active: number): GetP2pActionsType => {
  return { type: FETCH_TRADES_PENDING, active };
};

export const fetchActiveTradesPending = (): GetP2pActionsType => {
  return { type: FETCH_ACTIVE_TRADES_PENDING };
};

export const updateTrade = (trade: any): GetP2pActionsType => {
  return { type: UPDATE_TRADE, trade };
};

export const changeAddStatusAction = (
  coin: string,
  id: number,
  side: string
): GetP2pActionsType => {
  return { type: CHANGE_ADD_STATUS, coin, id, side };
};

export const fetchPricesPending = (trade: any): GetP2pActionsType => {
  return { type: FETCH_PRICES_PENDING };
};

export const fetchPricesSuccess = (
  buying: string,
  selling: string,
  coin: string
): GetP2pActionsType => {
  return { type: FETCH_PRICES_SUCCESS, buying, selling, coin };
};

export const fetchMyAddsSuccess = (
  buyAdds: [],
  sellAdds: [],
  coin: string
): GetP2pActionsType => {
  return { type: FETCH_MY_ADDS_SUCCESS, buyAdds, sellAdds, coin };
};

export const fetchMyNextAdds = (
  adds: [],
  category: string,
  coin: string,
  page: number
): GetP2pActionsType => {
  return { type: FETCH_MY_NEXT_ADDS, adds, category, coin, page };
};

export const appendAdd = (
  add: any,
  code: string,
  side: string
): GetP2pActionsType => {
  return { type: APPEND_ADD, add, code, side };
};
export const saveUsdRates = (rates: []): GetP2pActionsType => {
  return { type: SAVE_USD_RATES, rates };
};

export const fetchTradesSuccess = (
  trades: any,
  totalPages: number,
  current: number
): GetP2pActionsType => {
  return { type: FETCH_TRADES_SUCCESS, trades, totalPages, current };
};

export const fetchActiveTradesSuccess = (
  trades: any,
  totalPages: number,
  current: number
): GetP2pActionsType => {
  return { type: FETCH_ACTIVE_TRADES_SUCCESS, trades, totalPages, current };
};

export const restoreAddsAction = (): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => async (dispatch, getState) => {
  const state: any = getState();
  const { active, coins } = state.p2p;
  coins[active].buyAdds = coins[active].buyAddsBk;
  coins[active].sellAdds = coins[active].sellAddsBk;
  dispatch(initP2p(coins));
};

export const initP2pAction = (): ThunkAction<
  void,
  {},
  unknown,
  AnyAction
> => async (dispatch, getState) => {
  const state: any = getState();
  let coins: any = state.assets.assets;
  coins = coins.filter((val) => val?.manualIssued != true)
  const country = state.generic.myCountry;
  const countryItem = state.generic.activeCountries.find(
    (item) => item.code === country.code
  );
  let coinsNew: any = [];
  let active = state.p2p.active;
  coins.forEach((coin) => {
    coinsNew.push({
      name: coin.short.toUpperCase(),
      buyAdds: [],
      buyAddsBk: [],
      sellAdds: [],
      sellAddsBk: [],
      myBuyAdds: [],
      mySellAdds: [],
      buyPage: 1,
      myBuyAddsPage: 1,
      mySellAddsPage: 1,
      sellPage: 1,
      amount: 0,
      trades: [],
      priceBuy: "0",
      priceSell: "0",
    });
  });
  let coin: CionP2p = coinsNew[active];

  try {
    const data = await fetchInitAdds({
      assetCode: coin.name,
      type: "buy",
      page: 1,
      noOlderThan: "2020-07-22",
      location: state.generic?.myCountry?.code,
    });
    const priceRes = await getExchangeRates(coin.name, country.code);
    coin.buyAdds = data.data.ads;
    coin.buyAddsBk = data.data.ads;
    let { buyPrice, sellPrice } = priceRes.data?.exchange_rate;

    // if (buyPrice === sellPrice) {
    //   const newBuy = Number(buyPrice) - (1 / 100) * Number(buyPrice);
    //   coin.priceBuy = Number(newBuy).toFixed(5);
    //   coin.priceSell = Number(sellPrice).toFixed(5);
    // } else {
    coin.priceBuy = Number(buyPrice).toFixed(2);
    coin.priceSell = Number(sellPrice).toFixed(2);
    // }
    coinsNew[active] = coin;
    const rates = await getCurrencyRates();
    dispatch(saveUsdRates(rates));
    dispatch(initP2p(coinsNew));
    dispatch(fetchTradesAction(coin.name, 1));
    dispatch(fetchActiveTradesAction(1));
  } catch (e) {
    coin.buyAdds = [];
    coin.buyAddsBk = [];
    coin.priceBuy = "0.00";
    coin.priceSell = "0.00";
    coinsNew[active] = coin;
    dispatch(initP2pError(coinsNew));
  }
};
const getCurrencyRates = async () => {
  const response = await Axios.get(
    BASE_URL + "/coins/getAssets?currencyCode=USD"
  );

  return response.data;
};

export const refreshExchangeRates = async (asset, location) => {
  const state: any = store.getState();
  let coin = state.p2p.coins.find((item) => item.name === asset.toUpperCase());
  if (coin) {
    const priceRes = await getExchangeRates(asset.toUpperCase(), location);
    const { buyPrice, sellPrice } = priceRes.data?.exchange_rate;

    // if (buyPrice === sellPrice) {
    //   const newBuy = Number(buyPrice) - (1 / 100) * Number(buyPrice);
    //   coin.priceBuy = Number(newBuy).toFixed(5);
    //   coin.priceSell = Number(sellPrice).toFixed(5);
    // } else {
    coin.priceBuy = Number(buyPrice).toFixed(2);
    coin.priceSell = Number(sellPrice).toFixed(2);
    // }
    coin.isPriceEmpty = false;
    store.dispatch(setCoinAction(asset.toUpperCase(), coin));
  }
};

// const getCurrencyRates = async (asset, currency) => {
//   const response = await Axios.get(
//     BASE_URL + "/coins/getAssets?currencyCode=USD"
//   );
//   const ratesRes = await Axios.get("https://api.exchangeratesapi.io/latest?base=USD")

//   console.log('rates', ratesRes)
//   const rates = ratesRes.data.rates
//   const price = rates[currency]||"0"
//   const assetRates = response.data.find(
//     (item) => item.short.toUpperCase() === asset
//   );
//   const currentRate = assetRates.currentRate*price;
//   return currentRate;
// };

export const getAddsInfoAction = (
  type,
  asset,
  page
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  const state: any = getState();
  let coin: any = state.p2p.coins[state.p2p.active];
  dispatch(getAddsPending(state.p2p.active));

  fetchInitAdds({
    assetCode: asset,
    type: type,
    page: page,
    noOlderThan: "2020-07-22",
    location: state.generic?.myCountry?.code,
  })
    .then((data) => {
      const name = type === "buy" ? "buyAdds" : "sellAdds";
      coin[name] = [...coin[name], ...data.data.ads];
      coin[name + "Bk"] = [...coin[name], ...data.data.ads];
      coin[type + "Page"] = page;
      coin.loading = false;
      dispatch(getAddsSuccess(coin, state.p2p.active));
    })
    .catch((e) => {
      dispatch(getAddsError("Unable to load", state.p2p.active));
    });
};

export const getMyAddsInfoAction = (
  asset
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  const state: any = getState();
  try {
    dispatch(fetchMyAddsPending(state.p2p.active));
    let coin: CionP2p = state.p2p.coins[state.p2p.active];
    const [buyData, sellData] = await loadMyAds({
      asset,
      buyPage: coin.myBuyAddsPage,
      sellPage: coin.mySellAddsPage,
    });
    dispatch(fetchMyAddsSuccess(buyData.data.data, sellData.data.data, asset));
  } catch (e) {
    dispatch(getAddsError("Unable to load", state.p2p.active));
  }
};

const loadMyAds = async ({ asset, buyPage, sellPage }) => {
  const buyData = await getAddsByAccount(
    asset,
    buyPage,
    "buy",
    getSelectedKeys().public
  );
  const sellData = await getAddsByAccount(
    asset,
    sellPage,
    "sell",
    getSelectedKeys().public
  );
  return [buyData, sellData];
};

export const getNextMyAddsAction = (
  asset,
  type,
  page
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  const state: any = getState();
  try {
    dispatch(fetchMyAddsPendingWithType(state.p2p.active, type));
    let coin: CionP2p = state.p2p.coins[state.p2p.active];

    const data = await getAddsByAccount(
      asset,
      page,
      type,
      getSelectedKeys().public
    );

    dispatch(fetchMyNextAdds(data.data.data, type, asset, page));
  } catch (e) {
    dispatch(getAddsError("Unable to load", state.p2p.active));
  }
};

export const searchAddsAction = (
  type,
  amount
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  const state: any = getState();
  let coin: CionP2p = state.p2p.coins[state.p2p.active];
  dispatch(searchingAdds());
  dispatch(setActiveTab(type));
  searchAdds({
    assetCode: coin.name,
    type: type,
    amount: amount,
    page: type === "buy" ? coin.buyPage : coin.sellPage,
    noOlderThan: "2020-07-22",
    location: state.generic?.myCountry?.code,
  })
    .then((data) => {
      const name = type === "buy" ? "buyAdds" : "sellAdds";
      coin[name] = data.data.ads;
      coin.loading = false;
      dispatch(getAddsSuccess(coin, state.p2p.active));
    })
    .catch((e) => {
      dispatch(getAddsError("Unable to load", state.p2p.active));
    });
};

export const selectCoinAction = (
  name
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  const state: any = getState();
  const country = state.generic.myCountry;
  const countryItem = state.generic.activeCountries.find(
    (item) => item.code === country.code
  );
  const index = state.p2p.coins.findIndex((coin) => coin.name === name);
  try {
    let coin = state.p2p.coins[index];
    if (coin.buyAdds.length < 1) {
      dispatch(selectCoinNamePending(name));
      dispatch(getAddsPending(index));
      const data = await fetchInitAdds({
        assetCode: name,
        type: "buy",
        page: coin.buyPage,
        noOlderThan: "2020-07-22",
        location: state?.generic?.myCountry?.code,
      });
      const priceRes = await getExchangeRates(coin.name, country.code);
      let { buyPrice, sellPrice } = priceRes.data?.exchange_rate;

      // if (buyPrice === sellPrice) {
      //   const newBuy = Number(buyPrice) - (1 / 100) * Number(buyPrice);
      //   coin.priceBuy = Number(newBuy).toFixed(5);
      //   coin.priceSell = Number(sellPrice).toFixed(5);
      // } else {
      coin.priceBuy = Number(buyPrice).toFixed(2);
      coin.priceSell = Number(sellPrice).toFixed(2);
      // }
      coin.buyAdds = data.data.ads;
      coin.loading = false;
    } else {
      refreshExchangeRates(name, country?.code)
    }
    dispatch(selectCoinName(name, coin));
    if (coin.trades.length < 1) {
      dispatch(fetchTradesAction(name, 1));
    }
  } catch (e) {
    dispatch(selectCoinNameError(name));
  }
};

export const setActiveTabAction = (
  type
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  const state: any = getState();
  let coin: CionP2p = state.p2p.coins[state.p2p.active];

  dispatch(setActiveTab(type));
  if (type === "buy" && coin.buyAdds.length > 0) {
    return;
  }
  if (type === "sell" && coin.sellAdds.length > 0) {
    return;
  }
  if (
    type === "my" &&
    coin.myBuyAdds.length > 0 &&
    coin.mySellAdds.length > 0
  ) {
    return;
  }

  if (type === "my") {
    dispatch(getMyAddsInfoAction(coin.name));
  } else {
    dispatch(getAddsInfoAction(type, coin.name, 1));
  }
};

export const setNewAdd = (add, code) => async (dispatch, getState) => {
  const { coins, activeTab, active } = getState()?.p2p;
  let coin = coins.find((item) => item.name === code);
  if (add.account === getSelectedKeys().public) {
    const name = add?.type === "buy" ? "myBuyAdds" : "mySellAdds";
    if ((coin && coin[name]?.length) || activeTab === "my") {
      dispatch(appendAdd(add, code, name));
    }
  } else {
    const name = add?.type === "buy" ? "buyAdds" : "sellAdds";
    if (
      (coin && coin[name]?.length) ||
      (name === "buyAdds" && activeTab === "buy" && coin?.name === coins[active].name) ||
      (name === "sellAdds" && activeTab === "sell" && coin?.name === coins[active].name)
    ) {
      dispatch(appendAdd(add, code, name));
    }
  }
};

export const fetchTradesAction = (
  asset,
  page
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  const state: any = getState();
  if (!state.auth.password) {
    return;
  }
  const index = state.p2p.coins.findIndex((coin) => coin.name === asset);
  dispatch(fetchTradesPending(index));
  let offset = 10 * page - 10;
  fetchTrades({ assetCode: asset, limit: 10, offset })
    .then((result) => {
      const { count, data } = result.data;
      const totalPage = Math.floor((count + 10 - 1) / 10);
      dispatch(fetchTradesSuccess(data, totalPage, page));
    })
    .catch((e) => {
      dispatch(getAddsError("Unable to load", state.p2p.active));
    });
};
export const fetchActiveTradesAction = (
  page
): ThunkAction<void, {}, unknown, AnyAction> => async (dispatch, getState) => {
  const state: any = getState();
  if (!state.auth.password) {
    return;
  }

  dispatch(fetchActiveTradesPending());
  let offset = 10 * page - 10;
  fetchActiveTrades({ limit: 10, offset })
    .then((result) => {
      const { count, data } = result.data;
      const totalPage = Math.floor((count + 10 - 1) / 10);
      dispatch(fetchActiveTradesSuccess(data, totalPage, page));
    })
    .catch((e) => {
      dispatch(getAddsError("Unable to load", state.p2p.active));
    });
};
