import { Coin, LiquidityData, Wallet } from "store/models/TransactionState";
import { Transaction } from "store/models/Transaction";
import { PairBalance } from "store/models/Exchange";
export const SET_COINS_PENDING = "SET_COINS_PENDING";
export const SET_COINS = "SET_COINS";
export const SET_COINS_ERROR = "SET_COINS_ERROR";
export const SELECT_COIN = "SELECT_COIN";
export const FETCH_TRANSACTIONS_ERROR = "FETCH_TRANSACTIONS_ERROR";
export const SET_SELECTED_WALLET = "SET_SELECTED_WALLET";
export const REMAME_WALLET = "REMAME_WALLET";
export const SET_COIN_DEPOSIT_ADDRESS = "SET_COIN_DEPOSIT_ADDRESS";
export const SET_COIN_LOCKED = "SET_COIN_LOCKED";
export const FETCH_TRANSACTIONS_PENDING = "FETCH_TRANSACTIONS_PENDING";
export const FETCH_TRANSACTIONS_SUCCESS = "FETCH_TRANSACTIONS_SUCCESS";
export const ADD_NEW_WALLET = "ADD_NEW_WALLET";
export const FETCH_NEXT_TRANSACTIONS_PENDING = "FETCH_NEXT_TRANSACTIONS_PENDING";
export const FETCH_NEXT_TRANSACTIONS_SUCCESS = "FETCH_NEXT_TRANSACTIONS_SUCCESS";
export const UPDATE_PAIR_BALANCE = "UPDATE_PAIR_BALANCE";
export const INCREMENT_BALANCE = "INCREMENT_BALANCE";
export const REMOVE_COINS = "REMOVE_COINS";
export const LOW_XLM = "LOW_XLM";

interface SetCoins {
  type: typeof SET_COINS;
  payload: Coin[];
  wallet: any;
  showLoading: boolean;
  liquidityData: LiquidityData[];
}

interface SetLowXlm {
  type: typeof LOW_XLM;
  lock: boolean;
}
interface SetCoinDepositAddress {
  type: typeof SET_COIN_DEPOSIT_ADDRESS;
  payload: string;
}
interface SetCoinsError {
  type: typeof SET_COINS_ERROR;
  payload: string;
}
interface RemoveError {
  type: typeof REMOVE_COINS;
}
interface SelectCoin {
  type: typeof SELECT_COIN;
  index: number;
}
interface RenameWallet {
  type: typeof REMAME_WALLET;
  name: string;
}
interface SetCoinLocked {
  type: typeof SET_COIN_LOCKED;
  payload: boolean;
}
interface SetCoiPendingn {
  type: typeof SET_COINS_PENDING;
  pending: boolean;
}
interface SetSelectedWallet {
  type: typeof SET_SELECTED_WALLET;
  wallet: Wallet;
}
interface AddNewWallet {
  type: typeof ADD_NEW_WALLET;
  wallet: Wallet;
}
interface FetchTransactionsPending {
  type: typeof FETCH_TRANSACTIONS_PENDING;
}
interface FetchTransactionsSuccess {
  type: typeof FETCH_TRANSACTIONS_SUCCESS;
  payload: Transaction[];
  next: string,
  apiNext: number
}
interface FetchNextTransactionsPending {
  type: typeof FETCH_NEXT_TRANSACTIONS_PENDING;
}
interface UpdatePairBalance {
  type: typeof UPDATE_PAIR_BALANCE;
  pair: PairBalance
}
interface IncrementBalance {
  type: typeof INCREMENT_BALANCE;
  pair: PairBalance
}
interface FetchNextTransactionsSuccess {
  type: typeof FETCH_NEXT_TRANSACTIONS_SUCCESS;
  payload: Transaction[];
  next: string,
  apiNext: number
}

export type GetTransactionsInfoType =
  | SetCoins
  | SelectCoin
  | SetCoiPendingn
  | SetCoinsError
  | SetSelectedWallet
  | RenameWallet
  | SetCoinDepositAddress
  | SetCoinLocked
  | FetchTransactionsPending
  | FetchTransactionsSuccess
  | AddNewWallet
  | FetchNextTransactionsPending
  | UpdatePairBalance
  | FetchNextTransactionsSuccess
  | SetLowXlm
  | RemoveError
  | IncrementBalance;
