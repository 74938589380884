import * as React from "react";
import { swapBtn } from "assets/images";
import { coinsFromShort } from "utils/constants";

export const SwapCard = ({
  swapFrom,
  swapTo,
  setSell,
  setBuy,
  handleSubmit,
}) => {
  return (
    <div className="flex bg-primary p-4 md:p-8 lg:p-8 xl:p-8 mx-auto">
      <div className="flex w-5/6 justify-between items-center mx-auto">
        <button
          onClick={setSell}
          className="focus:outline-none flex flex-col justify-center items-center"
        >
          <img
            className="h-10 w-10 md:h-20 md:w-20 xl:h-20 xl:w-20"
            src={
              swapFrom.image || swapFrom.coinIcon
                ? swapFrom?.image || swapFrom?.coinIcon
                : coinsFromShort[swapFrom.short.toUpperCase()].icon
            }
          />
          <h3 className="text-white text-2xl mt-2 font-semibold">
            {swapFrom.short.toUpperCase()}
          </h3>
          <p className="text-center text-white text-regular text-xxxs md:text-xs lg:text-xs xl:text-xs">
            {swapFrom.short === "XLM" ? "Native" : swapFrom.organization}
          </p>
        </button>
        <button onClick={handleSubmit} className="focus:outline-none">
          <img
            className="h-8 w-8 md:h-10 md:w-10 xl:h-16 xl:w-16"
            src={swapBtn}
          />
        </button>
        <button
          onClick={setBuy}
          className="focus:outline-none flex flex-col justify-center items-center"
        >
          <img
            className="ml-2 md:ml-0 lg:ml-0 xl:ml-0 h-10 w-10 md:h-20 md:w-20 xl:h-20 xl:w-20"
            src={
              swapTo.image || swapTo.coinIcon
                ? swapTo?.image || swapTo?.coinIcon
                : coinsFromShort[swapTo.short.toUpperCase()].icon
            }
          />
          <h3 className="text-white text-2xl mt-2 font-semibold">
            {swapTo.short.toUpperCase()}
          </h3>
          <p className="text-center text-white text-regular text-xxxs md:text-xs lg:text-xs xl:text-xs">
            {swapTo.organization}
          </p>
        </button>
      </div>
    </div>
  );
};
