import { createAdbtn } from "assets/images";
import * as React from "react";
import { useHistory } from "react-router-dom";

function TradesTabs({onSelect, index, name}) {
  const history = useHistory()
  const handleOnCreate = () => {
    history.push({ pathname: "/createAdScreen", state: { asset: name } });
  };

  return (
    <div className="flex">
      <TradeTab
        text="Active Trades"
        active={index === 0}
        onSelect={() => onSelect(0)}
      />
      <TradeTab
        text="History"
        active={index === 1}
        onSelect={() => onSelect(1)}
      />
      <button onClick={handleOnCreate} className="items-center ml-5 flex sm:hidden">
          <img src={createAdbtn} className="h-4 w-4" />
          <p className="text-white text-xs ml-1 font-regular">Create Ad</p>
        </button>
    </div>
  );
}

export default TradesTabs;

function TradeTab({ text, active, onSelect }) {
  return (
    <p
      onClick={onSelect}
      className={`${
        active ? "text-white border-b border-accent" : "text-secondaryGray"
      } font-regular mt-4 py-3 px-3 cursor-pointer`}
    >
      {text}
    </p>
  );
}
