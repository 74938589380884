import * as React from "react";
import { Button } from "components/global";
import * as Images from "assets/images/index";
import { NavLink } from "react-router-dom";
import Timer from "components/atomic/Timer";
import Loader from "react-loader-spinner";
import {
  tradeActionButtonEnum,
  tradeActionIndexEnum,
  tradeStatusEnum,
} from "utils/AppConstants";

export const InProgressCard = ({
  startTime,
  timeFrame,
  claimPaid,
  onCancel,
  cancelling,
  claiming,
  sendingBalance,
  holdBalance,
  status,
  action,
  cancel,
  message,
  claimAmountRecivedSeller,
  claimingReceived,
  disabledActions,
  claimTradeExpiredSeller,
  disputingExpSeller,
  claimTradeExpiredBuyer,
  disputingExpBuyer,
  claimAmountNotRecivedSeller
}) => {
  const [expired, setExpired] = React.useState(false)
  const [expiredExtend, setExpiredExtend] = React.useState(false)
  const renderLoading = () => (
    <div className="flex justify-center items-center">
      <Loader type="Bars" color="#ffffff" height={20} width={20} />
    </div>
  );

  const renderAction = () => {
    if(status === tradeStatusEnum.Started && expiredExtend){
      return
    }
    if (action === tradeActionButtonEnum.HOLD_BALANCE) {
      return renderSellerAction();
    }
    if (action === tradeActionButtonEnum.I_HAVE_PAID) {
      return renderBuyerAction();
    }
    if (action === tradeActionButtonEnum.I_HAVE_RECEIVED) {
      return renderIHaveRecieved();
    }
  };

  const renderSubAction = () => {
    if(status === tradeStatusEnum.Started && expiredExtend){
      return
    }
    if (cancel === tradeActionButtonEnum.I_HAVE_NOT_RECEIVED) {
      return renderIHaveNotReceived();
    }
    if (cancel === tradeActionButtonEnum.CANCEL) {
      return renderCancel();
    }
    if (cancel === tradeActionButtonEnum.CANCEL_DISABLE) {
      return renderCancelDisabled();
    }
    if (cancel === tradeActionButtonEnum.REJECT) {
      return renderReject();
    }
    if (cancel === tradeActionButtonEnum.DISPUTE_EXPIRED_SELLER) {
      return //renderDisputeExp();
    }
    if (cancel === tradeActionButtonEnum.DISPUTE_EXPIRED_BUYER) {
      return renderDisputeExpBuyer();
    }
  };

  const renderBuyerAction = () => (
    <Button
      onClick={claimPaid}
      styles={[
        "text-sm bg-green md:mr-2 lg:mr-2 xl:mr-2 py-4 mt-3 w-full font-regular relative",
      ]}
    >
      {!claiming && "I have paid the seller"}
      {claiming && renderLoading()}
      {disabledActions.includes(tradeActionIndexEnum.I_HAVE_PAID) && (
        <div
          className="absolute inset-0 rounded bg-white cursor-not-allowed"
          style={{ opacity: 0.3 }}
        ></div>
      )}
    </Button>
  );

  const renderSellerAction = () => (
    <Button
      onClick={holdBalance}
      styles={[
        "text-sm bg-green md:mr-2 lg:mr-2 xl:mr-2 py-4 mt-3 w-full font-regular relative",
      ]}
    >
      {!sendingBalance && "Accept"}
      {sendingBalance && renderLoading()}
      {disabledActions.includes(tradeActionIndexEnum.HOLD_BALANCE) && (
        <div
          className="absolute inset-0 rounded bg-white cursor-not-allowed"
          style={{ opacity: 0.3 }}
        ></div>
      )}
    </Button>
  );
  
  const renderIHaveRecieved = () => (
    <Button
      onClick={claimAmountRecivedSeller}
      styles={[
        "text-sm bg-green md:mr-2 lg:mr-2 xl:mr-2 py-4 mt-3 w-full font-regular relative",
      ]}
    >
      {!claimingReceived && "I have received payment"}
      {claimingReceived && renderLoading()}
      {disabledActions.includes(tradeActionIndexEnum.I_HAVE_RECEIVED) && (
        <div
          className="absolute inset-0 rounded bg-white cursor-not-allowed"
          style={{ opacity: 0.3 }}
        ></div>
      )}
    </Button>
  );
  const renderIHaveNotReceived = () => (
    <Button
      onClick={claimAmountNotRecivedSeller}
      styles={[
        "text-sm bg-pink md:ml-2 lg:ml-2 xl:ml-2 py-4 mt-3 w-full font-regular relative",
      ]}
    >
      I have not received payment
      {disabledActions.includes(tradeActionIndexEnum.I_HAVE_NOT_RECEIVED) && (
        <div
          className="absolute inset-0 rounded bg-white cursor-not-allowed"
          style={{ opacity: 0.3 }}
        ></div>
      )}
    </Button>
  );
  const renderCancel = () => (
    <Button
      onClick={onCancel}
      styles={[
        "text-sm bg-pink md:ml-2 lg:ml-2 xl:ml-2 py-4 mt-3 w-full font-regular relative",
      ]}
    >
      {!cancelling && "Cancel Trade"}
      {cancelling && renderLoading()}
      {disabledActions.includes(tradeActionIndexEnum.CANCEL) && (
        <div
          className="absolute inset-0 rounded bg-white cursor-not-allowed"
          style={{ opacity: 0.3 }}
        ></div>
      )}
    </Button>
  );
  const renderDisputeExp = () => {
    if (expired) {
      return (
        <Button
          onClick={claimTradeExpiredSeller}
          styles={[
            "text-sm bg-pink md:ml-2 lg:ml-2 xl:ml-2 py-4 mt-3 w-full font-regular relative",
          ]}
        >
          {!disputingExpSeller && "Start dispute"}
          {disputingExpSeller && renderLoading()}
          {disabledActions.includes(tradeActionIndexEnum.DISPUTE_EXPIRED_SELLER) && (
            <div
              className="absolute inset-0 rounded bg-white cursor-not-allowed"
              style={{ opacity: 0.3 }}
            ></div>
          )}
        </Button>
      );
    }
  };
  const renderDisputeExpBuyer = () => {
    if (expired) {
      return (
        <Button
          onClick={claimTradeExpiredBuyer}
          styles={[
            "text-sm bg-pink md:ml-2 lg:ml-2 xl:ml-2 py-4 mt-3 w-full font-regular relative",
          ]}
        >
          {!disputingExpBuyer && "Start dispute"}
          {disputingExpBuyer && renderLoading()}
          {disabledActions.includes(tradeActionIndexEnum.DISPUTE_EXPIRED_BUYER) && (
            <div
              className="absolute inset-0 rounded bg-white cursor-not-allowed"
              style={{ opacity: 0.3 }}
            ></div>
          )}
        </Button>
      );
    }
  };
  const renderCancelDisabled = () => (
    <div className="text-sm bg-pink md:ml-2 lg:ml-2 xl:ml-2 py-4 mt-3 w-full font-regular text-white relative cursor-pointer">
      Cancel Trade
      <div
        className="absolute inset-0 z-10"
        style={{ backgroundColor: "#ffffff", opacity: 0.3 }}
      ></div>
    </div>
  );
  const renderReject = () => (
    <Button
      onClick={onCancel}
      styles={[
        "text-sm bg-pink md:ml-2 lg:ml-2 xl:ml-2 py-4 mt-3 w-full font-regular relative",
      ]}
    >
      {!cancelling && "Reject"}
      {cancelling && renderLoading()}
      {disabledActions.includes(tradeActionIndexEnum.CANCEL) && (
        <div
          className="absolute inset-0 rounded bg-white cursor-not-allowed"
          style={{ opacity: 0.3 }}
        ></div>
      )}
    </Button>
  );
  const getTopText = () => {
    return "Remaining time for this trade";
  };
  const getTimer = () => {
    if (status === tradeStatusEnum.pending_user_transfer) {
      return "00:15:00";
    }
    if(status === tradeStatusEnum.Started && expiredExtend){
      return "Trade Expired"
    }
    return (
      <Timer startTime={startTime} onExpired={() => setExpired(true)} timeFrame={timeFrame} />
    );
  };

  const renderTimeout = () => (
    <div className="flex items-center justify-center mt-4">
      <img src="assets/important-message.svg" className="w-4 h-4" />
      <label className="text-red font-regular text-sm ml-2">
        Trade timeout your trade will be cancelled after 
        <text className="mx-1 text-accent"><Timer startTime={startTime} onExpired={() => {console.log('expired');setExpiredExtend(true)}} timeFrame={18} /> </text>
        minutes.
      </label>
    </div>
  )

  return (
    <div className="bg-primary md:w-4/6 relative md:mx-auto lg:w-4/6 ld:mx-auto xl:w-4/6 xl:mx-auto p-5">
      {!(expired && status === tradeStatusEnum.Started) && <div className="flex items-center">
        <div className="flex items-center mx-auto">
          <img src={Images.clockIconRed} className="h-4 w-4" />
          <p className="ml-1 text-white text-sm font-regular">{getTopText()}</p>
        </div>
      </div>}

      <p className="text-accent text-3xl md:text-6xl lg:text-6xl xl:text-6xl font-regular text-center mt-2">
        {getTimer()}
      </p>
      <div className="border-gray4 border-b w-full p-1" />
      <p className="text-secondaryGray px-6 sm:px-10 text-sm md:text-lg lg:text-lg xl:text-lg font-regular text-center mt-4 mb-4">
        {(status === tradeStatusEnum.Started && expiredExtend) ?"This trade is expired due to timeout": message}
      </p>
      <div className="md:flex lg:flex xl:flex">
        {renderAction()}
        {renderSubAction()}
      </div>

      {expired && !expiredExtend && status === tradeStatusEnum.Started && renderTimeout()}
    </div>
  );
};
